import XLSX, {ColInfo} from 'xlsx'
import CalendarUtil from './CalendarUtil'
import IntervalTree from '@flatten-js/interval-tree'
import Helpers from './Helpers'
import AppointmentModel from "../models/AppointmentModel";
import {FacilityBundledObject} from "../types/Facility";
import {Provider, ProviderDictionary} from "../types/Provider";
import {RoomDictionary} from "../types/Room";

export type ProviderParams = ProviderDictionary | Array<Provider>

/**
 * Export appointments into 3 tables inside an excel file using sheetjs
 * - Provider Schedule
 * - Facility Schedule
 * - Activity Schedule
 */
export default class ExportToExcelSheet{
    static type = {
        PROVIDER: 0,
        OCCUPANCY: 1,
        ACTIVITY: 2
    }

    /**
     * Export appointments into 3 tables inside an Excel file using sheetjs
     */
    static exportExcel = function(title: string, data: AppointmentModel[], providersUnknownType: ProviderParams, facility: FacilityBundledObject, anchorDate: Date, showEmptyTimes: boolean = true){
        //gather all appointments into an IntervalTree to easily search for any at the times we are currently at
        const tree = new IntervalTree();
        data.forEach((appointment)=>{
            tree.insert([appointment.start.getTime()+1, appointment.end.getTime()-1], appointment)
        })
        let providers: ProviderDictionary = {}
        //convert the provider list to a map if needed
        if(Array.isArray(providersUnknownType)){
            let oldProviderList = providersUnknownType
            providers = {}
            oldProviderList.forEach((provider)=>{
                providers[provider.id] = provider
            })
        }
        else{
            providers = providersUnknownType as ProviderDictionary
        }
        
        //get our time blocks, in 15 minute intervals
        let timeBlocks = ExportToExcelSheet.getTimeSlots(facility, anchorDate)

        //We want to filter out any time blocks that contain no appointments at all, if the user wants to
        let trim = !showEmptyTimes
        
        //create our tables.
        let providerTable = ExportToExcelSheet.getTable(tree, providers, facility, anchorDate, timeBlocks, ExportToExcelSheet.type.PROVIDER, trim)
        let facilitySchedule = ExportToExcelSheet.getTable(tree, providers, facility, anchorDate, timeBlocks, ExportToExcelSheet.type.OCCUPANCY, trim)
        let activitiesSchedule = ExportToExcelSheet.getTable(tree, providers, facility, anchorDate, timeBlocks, ExportToExcelSheet.type.ACTIVITY, trim)

        //build new workbook
        const wb = XLSX.utils.book_new();

        //Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(wb, providerTable, "Provider Schedule");
        XLSX.utils.book_append_sheet(wb, facilitySchedule, "Facility Schedule");
        XLSX.utils.book_append_sheet(wb, activitiesSchedule, "Activity Schedule");

        //Write file. This will download the file automatically
        XLSX.writeFile(wb, `${title}.xlsx`);
    }

    static getTable = function(
        tree: IntervalTree<AppointmentModel>,
        providers: ProviderDictionary,
        facility: FacilityBundledObject,
        anchorDate: Date,
        timeBlocks: number[],
        type: number,
        trim: boolean = false)
    {
        const columns: string[] = [];
        const columnNameLookup: {[key: string]: string} = {};
        const roomNameLookup: RoomDictionary = facility.rooms.reduce((map, room) => {
            if (!room.occupancyId) return map
            map[room.occupancyId] = room
            return map
        }, {} as RoomDictionary);
        let data = tree.values //need to use the array from the tree...

        //Generate our columns before we create our rows
        if(type === ExportToExcelSheet.type.ACTIVITY){
            columnNameLookup['activity'] = "Activity"
            columns.push('activity') //only need one column
        }
        
        data.forEach((appointment)=>{
            let name
            switch(type){
                case ExportToExcelSheet.type.OCCUPANCY:
                    for (const occupant of appointment.occupants) {
                        let id = occupant.id
                        if(!id) return
                        if(!columns.includes(id)){
                            name = `${occupant.roomName} - ${occupant.name}`
                            columnNameLookup[id] = name
                            columns.push(id)
                        }
                    }
                    break
                case ExportToExcelSheet.type.PROVIDER:
                    if(!appointment.providers) return
                    for (const provider of appointment.providers) {
                        let providerId = provider.id
                        if(!providerId) return
                        if(!columns.includes(providerId)){
                            name = `${providers[providerId].firstName} ${providers[providerId].lastName[0]}`
                            columnNameLookup[providerId] = name
                            columns.push(providerId)
                        }
                    }
                    break
            }
        })

        columns.sort((a, b)=>{
            switch(type){
                case ExportToExcelSheet.type.OCCUPANCY:
                    let roomA = Helpers.getRoomNumber(roomNameLookup[a]);
                    let roomB = Helpers.getRoomNumber(roomNameLookup[b]);
                    return roomA.localeCompare(roomB, 'en', { numeric: true })
                case ExportToExcelSheet.type.PROVIDER:
                    let providerATitle = providers[a].title
                    let providerBTitle = providers[b].title
                    if(providerATitle === providerBTitle){
                        let providerNameA = columnNameLookup[a]
                        let providerNameB = columnNameLookup[b]
                        return providerNameA > providerNameB ? 1 : -1
                    }
                    return providerATitle > providerBTitle ? 1 : -1
            }
            return 1
        })

        const table = []; //data is formatted as arrays inside of an array.
        let rows = [];
        rows[0] = "Time" //first column is time
        rows = rows.concat(columns.map((id)=>{
            return columnNameLookup[id]
        })) //then append the rest of the columns
        
        table.push([new Date(anchorDate)]) //column #1 (DATE)
        table.push(rows) //column #2 (TIME | NAME | NAME | NAME)
        let maxWidth = 30
        let row = table.length
        timeBlocks.forEach((time)=>{
            //create row of empty cells, and append to the time
            let itemRow = columns.map(()=>{
                return ""
            })
            itemRow = [CalendarUtil.getShortTime(time)].concat(itemRow)

            //search for appointments in this time block, and display them in their assigned column
            let foundAppointments = tree.search([time, time+15*60*1000])
            foundAppointments.forEach((appointment: AppointmentModel)=>{
                let location;
                let sections;
                let service = Helpers.getService(appointment.serviceId, facility.services)
                if(type === ExportToExcelSheet.type.OCCUPANCY){
                    for (const occupant of appointment.occupants) {
                        if(facility.service2ferId && appointment.serviceId === facility.service2ferId) return //filter out 2fers from occupancy schedule
                        if(!appointment.occupants || appointment.occupants.length === 0) return //must have an occupant
                        //render as TITLE, PROVIDERS, LOCATION, LINKED SERVICES
                        let index = columns.indexOf(occupant.id)+1
                        sections = [];
                        sections.push(appointment.title)
                        if(appointment.providers && appointment.providers.length > 0){
                            let names: string[] = []
                            appointment.providers.forEach((provider)=>{
                                let providerName = `${providers[provider.id].firstName} ${providers[provider.id].lastName[0]}`
                                names.push(providerName)
                            })
                            sections.push(names.join(","))
                        }
                        if(Helpers.shouldShowSingleOccupantAsLocation(appointment, service)){
                            sections.push(occupant.roomName)
                        }
                        else if(service.pickLocation && service.locationId){
                            location = facility.locations[service.locationId];
                            sections.push(location.name)
                        }
                        let linkedServices = ExportToExcelSheet.getLinkedServiceNames(appointment, facility)
                        if(linkedServices){
                            sections.push(linkedServices.join(", "))
                        }
                        itemRow[index] = sections.join(", ") //push label to row cell
                    }
                }
                else if(type === ExportToExcelSheet.type.PROVIDER){
                    if(!appointment.providers) return //must have a provider
                    appointment.providers.forEach((provider)=>{
                        let providerId = provider.id
                        //render as TITLE, OCCUPANCY, LOCATION, LINKED SERVICES
                        let index = columns.indexOf(providerId)+1
                        sections = []
                        sections.push(appointment.title)
                        for (const occupant of appointment.occupants) {
                            sections.push(occupant.name)
                        }
                        if(appointment.occupants && appointment.occupants.length === 1 && Helpers.shouldShowSingleOccupantAsLocation(appointment, service)){
                            sections.push(appointment.occupants[0].roomName)
                        }
                        else if(service.pickLocation && service.locationId){
                            let location = facility.locations[service.locationId];
                            sections.push(location.name)
                        }
                        let linkedServices = ExportToExcelSheet.getLinkedServiceNames(appointment, facility)
                        if(linkedServices){
                            sections.push(linkedServices.join(", "))
                        }
                        itemRow[index] = sections.join(", ") //push label to row cell
                    })
                }
                else{ //Activities
                    if(appointment.serviceId !== facility.activityServiceId) return //must be an activity
                    //Render as TITLE, LOCATION
                    sections = [];
                    let index = 1
                    sections.push(appointment.title)
                    for (const locationOfAppt of appointment.locations) {
                        location = facility.locations[locationOfAppt.id];
                        if(location)
                            sections.push(location.name)
                    }
                    itemRow[index] = sections.join(", ") //push label to row cell
                }
            })
            row++
            //if we are trimming, and there are no appointments, don't add the row
            if(trim && itemRow.slice(1).every((cell)=>{return cell === ""})){
                return
            }
            table.push(itemRow)
        })

        /* convert AOA to worksheet */
        const ws = XLSX.utils.aoa_to_sheet(table);
        const merge = {s: {r: 0, c: 0}, e: {r: 0, c: rows.length - 1}}; //merge the columns on the top row
        ws['!merges'] = []
        ws['!merges'].push(merge)

        //set the column width of every column except the first
        ws['!cols'] = [] 
        rows.forEach((item, index)=>{
            let data: ColInfo = {wch:maxWidth}
            if(index === 0){
                return
            }
            ws['!cols']?.push(data)
        })
        return ws
    }

    /**
     * Get our linked appointments' service names as an array
     * @param appointment Appointment
     * @param facility Facility
     * @returns array of service names if there are any linked appointments. Only returns results for the parent appointment
     */
    static getLinkedServiceNames(appointment: AppointmentModel, facility: FacilityBundledObject){
        if (!appointment.linkedAppointments || appointment.linkedAppointments.length === 0) return null
        let serviceNameLookup: {[key: string]: string} = {}
        facility.services.forEach((service)=>{
            serviceNameLookup[service.id] = service.name
        })
        const services: string[] = [];
        appointment.linkedAppointments.forEach(({ServiceId})=>{
            services.push(serviceNameLookup[ServiceId])
        })
        if(services.length === 0) return null
        console.log(services)
        return services
    }

    static getTimeSlots(facility: FacilityBundledObject, date: Date): number[]{

        //fallback open/close time range of 6AM - 8PM
        let startTotal = 6 * 60 * 60 * 1000;
        let endTotal = 20 * 60 * 60 * 1000;

        if(facility.operatingTimes){
            startTotal = facility.operatingTimes.start
            endTotal = facility.operatingTimes.end
        }

        startTotal = startTotal / 60 / 1000
        const startHours = Math.floor(startTotal / 60);
        const startMinutes = startTotal % 60;

        endTotal = endTotal / 60 / 1000
        const endHours = Math.floor(endTotal / 60);
        const endMinutes = endTotal % 60;

        const start = new Date(date);
        start.setHours(startHours)
        start.setMinutes(startMinutes)
        start.setSeconds(0)
        start.setMilliseconds(0)

        const end = new Date(date);
        end.setHours(endHours)
        end.setMinutes(endMinutes)
        end.setSeconds(0)
        end.setMilliseconds(0)

        let ms = start.getTime();

        const dates = [];

        //every increment of i is 15 minutes
        for(let i = start.getHours()*4; i < end.getHours()*4 + 1; i++){
            const newDate = new Date(ms).getTime();
            dates.push(newDate)
            ms += 15 * 60 * 1000
        }

        return dates
    }
}