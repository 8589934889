import React from "react"
import WindowUtil from "../../util/WindowUtil";
import styles from "./LoginContainer.module.css";
import mobileStyles from "./LoginContainer-mobile.module.css";

export default class LoginContainer extends React.Component{
    render(){
        let isMobile = WindowUtil.isMobile()
        let style = isMobile ? mobileStyles : styles
        return <div class={style.loginContainer}>
            {this.props.children? (
                <div class={style.loginLogoContainer}>
                <img class={style.loginLogo} src={require("../../img/Icons/logo-dark.svg")}/>
            </div>
            ) : null}
            {this.props.children}
        </div>
    }
}