import React from 'react'
import './navigationHeader.css'

export default class NavigationHeader extends React.Component{

    static HeaderHeight = 40 //Note: this value exists in css, so changing this does not change anything in this class. Referenced by other views though

    render(){
        var elements = []

        if(this.props.navigationBackTitle){
            elements.push(
                <button onClick={this.props.onNavigateBack} className='navigation-header-back-button'>{`< ${this.props.navigationBackTitle}`}</button>
            )
        }

        if(this.props.navigationForwardTitle){
            elements.push(
                <button onClick={this.props.onNavigateForward} className='navigation-header-forward-button'>{`${this.props.navigationForwardTitle} >`}</button>
            )
        }

        if(this.props.navigationOptionsTitles){
            var options = this.props.navigationOptionsTitles.map((option)=>{
                var disabled = this.props.disableOptions?.includes(option)
                return <button disabled={disabled} id={options} className={`navigation-header-option navigation-header-option-${option}${disabled?" disabled":""}`} onClick={()=>{this.props.onNewOption(option)}}>{option}</button>
            })
            elements.push(
                <div className='navigation-header-options-container'>
                    {options}
                </div>
            )
        }

        if(this.props.title && !this.props.children){
            elements.push(
                <div align='center' className='navigation-header-title'>
                    {this.props.title}
                </div>
            )
        }
        
        if(this.props.children)
            elements.push(this.props.children)
        
        return <div className='navigation-header-container' style={this.props.style}>
            {elements}
        </div>
    }
}