import React from 'react';
import CalendarUtil from '../../util/CalendarUtil'
import AppointmentCalendar from '../../Calendars/AppointmentCalendar'
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager';
import LoadingSpinner from '../../util/LoadingSpinner'
import Tracker from '../../managers/Tracker'
import NavigationHeader from '../../ui/NavigationHeader';
import SchedulingCalendar from '../../Calendars/SchedulingCalendar';
import {AppointmentRepository} from "../../repositories/AppointmentRepository";
import {ScheduleColumnImpl} from "../../models/ScheduleColumn";
import Helpers from "../../util/Helpers";
import {toast} from "react-toastify";
import Queries from "../../managers/Queries";
import {ScheduleTimeSlot} from "../../models/ScheduleAppointmentHolder";

export default class SelectTimePage extends React.Component{

  /**
   * @type {AppointmentRepository}
   */
  repo
  constructor(props){
    super(props)
    Tracker.logScreenView('schedule_appointment_select_time')
    this.repo = new AppointmentRepository({facilityId: props.facility.id})
    let anchorDate = new Date()
    let appointment = this.props.appointment
    if(appointment.time && appointment.time.startTime){
      anchorDate = new Date(appointment.time.startTime)
    }
    else if(appointment.start){
      anchorDate = appointment.start.getTime()
    }

    this.state={
      conflicts: [],
      loading: true,
      anchorDate: anchorDate,
      defaultDuration: SchedulingCalendar.defaultFallbackDuration,
      scheduleColumns: this.generateScheduleColumns(props)
    }
    console.log(`this.state.anchorDate=${JSON.stringify(this.state.anchorDate)}`)
  }

    onSelectTime = (time) => {
      this.props.machine.send("NEXT")
      this.props.onSelectTime(time)
    }

    componentDidMount(){
      void this.getTimes(this.state.anchorDate)
    }

    loadPrevious = () => {
      const newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() - 1));
      this.loadDate(newAnchor)
    }
  
    loadNext = () => {
      const newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() + 1));
      this.loadDate(newAnchor)
    }

    loadDate = (newAnchor) => {
      this.setState({
        anchorDate: newAnchor
      }, ()=> {
        void this.getTimes(newAnchor)
        this.closePopupIfActive()
      })
    }

    closePopupIfActive = () => {
      const state = this.props.machine.state.value["ScheduleAppointment"] ?? this.props.machine.state.value["ScheduleGroupAppointment"];
      if(state.toLowerCase().includes("popup")){
          this.props.machine.send("CANCEL")
      }
    }

    generateScheduleColumns = (props) => {
      const appointment = props.appointment
      /**
       *
       * @type {ScheduleDictionary}
       */
      const scheduleColumns = []
      let occupancies = appointment.occupancies && appointment.occupancies.length > 0 ? appointment.occupancies : [appointment.occupancy]
      for(let occupancy of occupancies){
        if(!occupancy.identifier) continue //there is a chance that the single occupancy we used does not exist
        scheduleColumns[occupancy.id] = new ScheduleColumnImpl(occupancy.id, occupancy.identifier)
      }
      for(let provider of appointment.providers){
        scheduleColumns[provider] = new ScheduleColumnImpl(provider, Helpers.getProviderNameFromId(provider, this.props.facility))
      }
      return scheduleColumns
    }

    /**
     * @description Add appointment data to their corresponding conflicted time slots
     * @param {{available: boolean, start: number, end: number}[]} conflicts Array of conflicts to add appointments to
     * @param {{[key: string]: AppointmentModel[]}} appointments a dictionary/map of appointment objects
     * @param dateRange {{start: number, end: number}}
     * @param {Array<string>}owners
     */
    addConflictedAppointmentData = (conflicts, appointments,  owners, dateRange) => {
      //fallback open/close time range of 6AM - 8PM
      let startTotal = 6 * 60 * 60 * 1000;
      let endTotal = 20 * 60 * 60 * 1000;

      if(this.props.facility.operatingTimes){
        startTotal = this.props.facility.operatingTimes.start
        endTotal = this.props.facility.operatingTimes.end
      }

      startTotal = startTotal / 60 / 1000
      const startHours = Math.floor(startTotal / 60);
      const startMinutes = startTotal % 60;

      endTotal = endTotal / 60 / 1000
      const endHours = Math.floor(endTotal / 60);
      const endMinutes = endTotal % 60;

      const startDate = new Date(dateRange.start);
      startDate.setHours(startHours)
      startDate.setMinutes(startMinutes)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      const endDate = new Date(dateRange.end);
      endDate.setHours(endHours)
      endDate.setMinutes(endMinutes)
      endDate.setSeconds(0)
      endDate.setMilliseconds(0)

      let start = startDate.getTime()
      let end = endDate.getTime()

      conflicts.sort((a, b) => {
        const aStart = new Date(a.start.getTime?.() ?? a.start).getTime()
        const bStart = new Date(b.start.getTime?.() ?? b.start).getTime()
        return aStart > bStart ? 1 : -1
      })

      /**
       *
       * @type {{start: number, end: number, available: boolean}[]}
       */
      const timeSlots = [];

      conflicts.forEach(e => {
        const cStart = new Date(e.start.getTime?.() ?? e.start).getTime()
        const cEnd = new Date(e.end.getTime?.() ?? e.end).getTime()
        if(start < cStart){
          timeSlots.push(
              {
                start: start,
                end: cStart,
                available: true
              }
          )
          // console.log(`pushed available timeslot of ${new Date(start)} to ${new Date(cStart)}`)
          timeSlots.push({
            start: cStart,
            end: cEnd,
            available: false
          })
          // console.log(`marked ${new Date(cStart)} to ${new Date(cEnd)} as unavailable`)
        }
        start = cEnd
      })

      //last time slot
      timeSlots.push({
        start: start,
        end: end,
        available: true
      })
      // console.log(`pushed last available timeslot of ${new Date(start)} to ${new Date(end)}`)
      /**
       * @type {ScheduleDictionary}
       */
      let columns = this.state.scheduleColumns

      for (const columnId of Object.keys(columns)) {
        for (const timeSlot of timeSlots) {
          columns[columnId].push(
              new ScheduleTimeSlot(
                  timeSlot.start,
                  timeSlot.end,
                  timeSlot.available,
                  columnId,
                  columns[columnId].label
              )
          )
        }
      }
      for (const conflict of conflicts) { //iterate through all conflicts
        const conflictStart = new Date(conflict.start.getTime?.() ?? conflict.start).getTime()
        const conflictEnd = new Date(conflict.end.getTime?.() ?? conflict.end).getTime()
        Object.keys(appointments).forEach(element => { //iterate through appointments dictionary
          const appointmentsArr = appointments[element]; //appointments for specific person/provider
          for (const item of appointmentsArr) { //iterate through appointments for provider/person
            /**
             * @type {AppointmentModel}
             */
            const appointment = AppointmentRepository.convertFields(item);

            if(!appointment) continue
            if(appointment.start.getTime() >= conflictStart && appointment.end.getTime() <= conflictEnd){ //make sure times are within the conflict
              if(appointment.occupants.length > 0 || appointment.providers.length > 0){
                //duplicate the appointment for each occupant in the list of owners so we can display one for each column

                const occupants = appointment.occupants
                const providers = appointment.providers

                /**
                 * @type {Assignee[]}
                 */
                const assignees = [...providers, ...occupants] // combine them since we don't care about type
                assignees.forEach(assignee => {
                  const id = assignee.roomId ?? assignee.id
                  if(!owners.includes(id)) return
                  if(!columns[id]){
                    toast(`Unable to find appointments for ${id} ${assignee.name}`)
                    return
                  }
                  /**
                   * @type {ScheduleColumn}
                   */
                  let column = columns[id]
                  column.pushAppointmentModel(appointment)
                })
              }
              else{
                //breakfast,lunch,supper,etc
                for (const columnId of Object.keys(columns)) {
                  columns[columnId].pushAppointmentModel(appointment)
                }
              }
            }
            else{
              console.log('outside of time range!')
            }
          }
        })
      }
      return columns
    }
    
    async getTimes(date){
      this.setState({
        loading:true,
        scheduleColumns: this.generateScheduleColumns(this.props)
      })


      let owners = []
      if(this.props.appointment.occupancies){
        this.props.appointment.occupancies.forEach(occupancy=>owners.push(occupancy.id))
      }
      else{
        owners.push(this.props.appointment.occupancy.id) //occupancy does not exist!
      }

      this.props.appointment.providers.forEach(providerId => {
        if (providerId !== this.props.facility.offsiteProviderId) { //make sure we don't push offsite id
          owners.push(providerId)
        }
      });

      if(!this.state.defaultDurationFetched){
        if(this.props.appointment.occupancies){
          this.setState({defaultDurationFetched: true}) //we can't use this during group appointments
        }
        else{
          const promise = new Promise((resolve)=>{
            LegacyAppointmentManager.getDefaultDuration(this.props.appointment.occupancy.occupancyId, this.props.appointment.service.id, (data)=>{
              resolve(data)
            })
          })
          const duration = await promise
          this.setState({defaultDuration: duration.value, defaultDurationFetched: true})
        }
      }

      const facilityId = this.props.facility.id;
      const timeRange = CalendarUtil.getDayTimeRange(date);
      const showBlockedTimes = !this.props.appointment.service.ignoreTimeRestrictions && !this.isOffsite();
      const _repo = this.repo
      const activityAppointments = await new Promise((resolve)=>{
        /**
         * @type {AppointmentQuery}
         */
        let activityQuery = {
          service: this.props.facility.activityServiceId,
          start: timeRange.start,
          end: timeRange.end
        }

        _repo.getAppointments(activityQuery).then(data => {
          if(data.error){
            toast('Failed to fetch activities')
            resolve([])
            return
          }
          resolve(data.appointments)
        })
      })


      let compareAppointmentQuery = Queries.getCompareAppointmentParams(
          timeRange, facilityId, owners, true, showBlockedTimes
      )
      let data = await _repo.compareAppointments(compareAppointmentQuery)
      if(!data.appointments || data.error){
        toast('Unable to fetch appointments')
        this.setState({
          scheduleColumnsSortedArray: [],
          activities: [],
          loading: false
        })
        return
      }

      if(data.conflicts == null) data.conflicts = []
      let scheduleColumns = this.addConflictedAppointmentData(data.conflicts, data.appointments, owners, timeRange)
      if(activityAppointments && activityAppointments.length > 0){
        scheduleColumns['activities'] = new ScheduleColumnImpl('activities', "Activities")
        activityAppointments.forEach(a => scheduleColumns['activities'].pushAppointmentModel(a))
      }
      let providers = []
      let occupants = []
      //We want activities first, then providers, then occupants
      for(let column of Object.values(scheduleColumns)){
        if(column.label.toLowerCase().startsWith('room ')){ //safe bet that this is a room
          occupants.push(column)
        }
        else{
          providers.push(column)
        }
      }
      this.setState({
          scheduleColumnsSortedArray: [...providers, ...occupants],
          scheduleColumns: scheduleColumns,
          activities: activityAppointments,
          loading: false
      })
    }

    isOffsite(){
      const providerIds = this.props.appointment.providers;
      //only do offsite check if only one provider
      return providerIds.length === 1 && providerIds[0] === this.props.facility.offsiteProviderId
    }

    getColumnCount(){
      return this.state.scheduleColumnsSortedArray.length
    }

    render(){
      if(this.state.loading) return <LoadingSpinner/>
      let elements = []
      if(this.props.header){
        let header = Object.assign({},this.props.header)
        header.props = Object.assign({},header.props)
        header.props.title = ""
        elements.push(header)
      }
      elements.push(
        <AppointmentCalendar 
          marginTop={this.props.header?NavigationHeader.HeaderHeight:0}
          defaultDuration={this.state.defaultDuration}
          editing={this.props.editing}
          fullDay={this.props.fullDay} 
          facility={this.props.facility} 
          hideBackArrow={CalendarUtil.areDatesTheSameDay(this.state.anchorDate, new Date())} 
          anchorDate={this.state.anchorDate}
          loadPrevious={this.loadPrevious} 
          loadNext={this.loadNext} 
          loadDate={this.loadDate}
          onSelectTime={this.onSelectTime} 
          appointment={this.props.appointment} 
          machine={this.props.machine} 
          columns={this.getColumnCount()} 
          activities={this.state.activities}
          schedule={this.state.scheduleColumnsSortedArray}/>
      )
      return elements
    }
  }
