import hopcroftKarp from '../util/hopcroftKarp'
import LegacyAppointmentManager from './LegacyAppointmentManager';
import CalendarUtil from '../util/CalendarUtil';
import FacilityManager from './FacilityManager';
import App from '../App'
import ProviderManager from './ProviderManager';
import { ConsoleLogger } from '@aws-amplify/core';
import Helpers from '../util/Helpers';
import Appointment from '../models/Appointment';

export default class AutoScheduler {
    static roomOccupancyIdLookup = {}
    static roomIdLookup = {}
    static serviceIdLookup = {}
    static provider2ferLookup = {}
    static serviceId2fer = null
    static providerLookup = {}
    static date

    static run = async(facility, date, onComplete, onError) => {
        AutoScheduler.date = date
        AutoScheduler.roomOccupancyIdLookup = {}
        AutoScheduler.roomIdLookup = {}
        AutoScheduler.serviceIdLookup = {}
        AutoScheduler.provider2ferLookup = {}
        AutoScheduler.providerLookup = facility.providers
        var providerIds = []
        var occupancyIds = []
        var roomIds = []

        //TODO could probably use a better name. Used to only send a toast for messages that are not duplicates. 
        //Also so the scheduler does not process known bad data
        var noProviderFoundMap = [] 

        var configs = await AutoScheduler.collectConfigs(facility)
        configs.sort((a, b) => (a.duration < b.duration) ? 1 : -1)
        var rooms = await AutoScheduler.collectRooms(facility, roomIds)

        rooms.forEach(r => {
            AutoScheduler.roomOccupancyIdLookup[r.occupancyId] = r
            AutoScheduler.roomIdLookup[r.id] = r
        })
        facility.services.forEach(s => {
            AutoScheduler.serviceIdLookup[s.id] = s
        })

        var configLookup = {}

        configs.forEach(c => {

            var o = c.occupancyId
            var s = c.serviceId

            if(configLookup[o] == null) configLookup[o] = {}
            if(configLookup[o][s] == null) configLookup[o][s] = []

            configLookup[o][s].push(c)
            if(configLookup[o][s].length == 2){
                //If we have 2 blocks of the same type - link the two configs to make sure
                //they are scheduled in different time blocks (before/after lunch)
                configLookup[o][s][0].splitConfig = configLookup[o][s][1].configId
                configLookup[o][s][1].splitConfig = configLookup[o][s][0].configId
            }
        })

        await AutoScheduler.handle2ferConfigs(facility, configs) //handle 2fers and adding specific 2fer configs. Do after mapping so it does not break anything
        
        configs.forEach(c => {
            if(!occupancyIds.includes(c.occupancyId)) {
                occupancyIds.push(c.occupancyId)
            }
            var room = AutoScheduler.roomOccupancyIdLookup[c.occupancyId]
            c.roomId = room.id
            c.occupancyIdentifier = room.identifier
            c.name = room.name    
            var service = AutoScheduler.serviceIdLookup[c.serviceId]
            c.serviceName = service.name
            roomIds.push(room.id)
        })

        configs.forEach(c => {
            if(noProviderFoundMap[c.roomId] || noProviderFoundMap[`${c.roomId}-${c.serviceId}`]){
                c.failed = true //mark failed since we probably have a bad setup
                return
            }
            var room = AutoScheduler.roomIdLookup[c.roomId]
            if(!room.providers){
                noProviderFoundMap.push(c.roomId)
                c.failed = true
                onError(`Unable to schedule appointments for ${Helpers.getRoomName(room)}. No assigned providers`)
                return
            }

            if(!c.is2Fer){
                c.providers = room.providers[c.serviceId]
                if(!c.providers){
                    noProviderFoundMap.push(`${c.roomId}-${c.serviceId}`)
                    c.failed = true
                    onError(`Unable to schedule appointments for ${Helpers.getRoomName(room)}. No assigned providers for ${this.serviceIdLookup[c.serviceId].name} (${c.duration} minutes)`)
                    return
                }
            }
            else{ //config is 2fer, so we will use 2fers for the providers
                c.providers = Object.keys(AutoScheduler.provider2ferLookup)
                //just so we use provider 0 as well, force it to requirePrimary, even though this is 2fer
                c.requirePrimary = true
            }

            //which provider should we try to schedule?
            //default to index 0 -> primary
            c.providerIndex = 0

            //if we don't require primary, start at first fallback
            if(!c.requirePrimary && c.providers.length > 1) c.providerIndex = 1

            c.providers.forEach(p => {
                if(!providerIds.includes(p)) providerIds.push(p)
            })
        })

        //TODO - only get first batch of providers.
        //No need to get fallback provider schedules unless needed
        var providers = await AutoScheduler.collectAppointments(facility, providerIds)
        var occupanciesList = await AutoScheduler.collectAppointments(facility, occupancyIds)
        
        var occupancies = {} //Put occupancies in dictionary for faster lookup
        occupanciesList.forEach(apptGroup => {
            occupancies[apptGroup.id] = apptGroup
        })

        //mark configs done if appointments are detected from the config
        var configLookup = {}
        configs.forEach((c)=>{
            configLookup[c.configId] = c
        })
        var maybeMarkDone = (appointment)=>{
            var config = configLookup[appointment.configId]
            if(config){
                console.log(`Skip appointment ${config.configId}`)
                config.scheduled = true
                config.finished = true
                if(config.splitConfig){
                    config.staged = true //split appointment.
                    config.splitStart = appointment.start
                    config.splitEnd = appointment.end
                }
                if(config.require2fer){
                    var config2fer = configLookup[AutoScheduler.get2ferConfigId(config.configId)]
                    if(config2fer){
                        config2fer.start = appointment.start
                        config2fer.end = appointment.end
                        config2fer.linkedAppointmentId = appointment.id
                    }
                }
            }
        }

        providers.forEach(provider=>{
            provider.appointments.forEach((appointment)=> {
                maybeMarkDone(appointment)
            })
        })
        occupanciesList.forEach(occupancy=>{
            occupancy.appointments.forEach((appointment)=> {
                maybeMarkDone(appointment)
            })
        })

        var allDone = false

        var failedConfigs = []
        
        while(!allDone){
            AutoScheduler.calculateAppointments(configs, providers, occupancies)

            await AutoScheduler.scheduleConfigs(facility, configs, providers)

            allDone = true

            configs.forEach(c => {
      
                if(!c.scheduled){

                    if(c.scheduleNextRound && !c.failed){
                        if(c.is2Fer && failedConfigs.indexOf(this.getIdFrom2ferConfigId(c.configId)) != -1){
                            //our master appointment was not successful. Mark this as failed as well
                            c.scheduleNextRound = false
                            if(!c.failed)
                                onError(`Failed to schedule 2fer for Room ${c.name} since main appointment failed`)
                            c.failed = true
                            // console.log(`Marking as ${c.configId} failed since master failed using service ${this.serviceIdLookup[c.serviceId].name}`)
                        }
                        else{
                            console.log("not all done. Schedule again")
                            allDone = false
                        }
                        return
                    }
                    
                    if(!c.providers || c.providerIndex >= c.providers.length){
                        console.log(`failed at ${c.providerIndex} in ${c.configId} using service ${this.serviceIdLookup[c.serviceId].name}`)
                        if(!c.failed)
                            onError(`Failed to schedule appointment for Room ${c.name} using service ${this.serviceIdLookup[c.serviceId].name} (${c.duration} minutes)`)
                        failedConfigs.push(c.configId)
                        AutoScheduler.logObject(c)
                        c.failed = true //Mark as failed. No slot could be found for this config.
                    }else{
                        console.log("not all done")
                        c.providerIndex++
                        allDone = false
                    }
                }
                else{
                    c.finished = true
                }
            })
        }

        onComplete(configs)
    }

    static logObject = (obj) => {
        console.log(JSON.stringify(Object.assign({}, obj)))
    }

    static get2ferConfigId = (configId)=> {
        return `${configId}2fer`
    }

    static getIdFrom2ferConfigId = (config2ferId)=> {
        return config2ferId.split("2fer")[0]
    }

    static handle2ferConfigs = async(facility, configs) => {
        AutoScheduler.provider2ferLookup = await AutoScheduler.collect2Fers(facility, configs) //collect 2fers
        if(AutoScheduler.provider2ferLookup){
            var configsWith2fers = []
            configs.forEach((config)=>{ //scan through all configs and check for require2fer
                if(config.require2fer){ 
                    configsWith2fers.push(config)
                }
            })
            //create new fake configs that are specifically to handle 2fer scheduling after the first few passes
            configsWith2fers.forEach((config) => {
                var newConfig = Object.assign({}, config)
                newConfig.configId = AutoScheduler.get2ferConfigId(config.configId) //change it slightly so it can't conflict with the main config
                newConfig.serviceId = AutoScheduler.serviceId2fer
                newConfig.require2fer = false
                newConfig.splitConfig = null
                newConfig.splitStart = null
                newConfig.is2Fer = true
                newConfig.duration = config.duration2fer
                configs.push(newConfig)
            })
        }
    }

    /**
     * Collect 2fers for the facility, if needed. We currently will have to assume the service that has 2fers
     * @returns null if not needed, or no 2fers found
     * @returns map of providers (where id is key, and value is provider data) if 2fers needed
     */
    static collect2Fers = async(facility, configs) => {
        //first check to see if we need to grab 2fers.
        var needs2FerAppointments = false
        configs.forEach((config)=>{ //scan through all configs and check for require2fer
            if(config.require2fer){ 
                needs2FerAppointments = true //we have one. Set it to true
            }
        })

        if(!needs2FerAppointments) return null //exit with null if no 2fers needed
        
        //Find the service with the 2fers
        AutoScheduler.serviceId2fer = null
        facility.services.forEach((service) => { //scan through to find a service that contains '2fer'. TODO replace with facility setting
            if(service.name.indexOf('2fer') > -1){
                AutoScheduler.serviceId2fer = service.id //found one
            }
        })

        if(!AutoScheduler.serviceId2fer) return null //exit with null, no 2fer service found

        //now grab the providers from the back-end with a specific service. We need this since cache has no info for this currently
        var provider2ferArray = null
        ProviderManager.listProviders(facility.id, AutoScheduler.serviceId2fer, true, false, (result) => {
            if(!result.error) //check for an error
                provider2ferArray = result
            else
                provider2ferArray = [] //if there was an error, set to empty
        })
        await App.until(_ => provider2ferArray != null) //wait for web call to finish

        if(provider2ferArray.length == 0) return null //No providers found, or error occurred. Return null
        
        //convert to map
        var provider2ferMap = {}
        provider2ferArray.forEach((provider) => {
            provider2ferMap[provider.id] = provider
        })

        //return our map
        return provider2ferMap
    }

    static collectConfigs = async(facility) => {
        var configs = null
        FacilityManager.listOccupancyServiceConfigs(facility.id, data => {
            configs = data
        })

        await App.until(_ => configs != null)
        return configs
    }

    static collectRooms = async(facility, roomIds) => {
        var rooms = null
        FacilityManager.listRooms(facility.id, true, null, data => {
            rooms = data
        })

        await App.until(_ => rooms != null)

        var filteredRooms = []
        rooms.forEach(r => {
            /*if(roomIds.includes(r.id))*/ filteredRooms.push(r)
        })

        return filteredRooms
    }

    static collectAppointments = async(facility, ids) => {

        var appointments = []

        ids.forEach(e => {
            LegacyAppointmentManager.listAppointments(
                e,
                facility.id,
                null,
                null,
                CalendarUtil.getDayTimeRange(new Date(AutoScheduler.date)), 
                (appts) => {
                    appointments.push({
                        id: e,
                        appointments: appts
                    })
                }
            )
        })

        await App.until(_ => appointments.length == ids.length)
        return appointments
    }


    static scheduleConfigs = async(facility, configs, providers) => {
 
        var numScheduled = 0
        var numToSchedule = 0

        providers.forEach(p => {

            p.appointments.forEach(a => {
                configs.forEach(c => {
                    if(a.config == c) {
                        c.scheduled = true
                        if(!c.finished)
                            numToSchedule++
                    }
                })
            })

            p.appointments.forEach(a => {
                if(a.config != null){
                    if(a.config.finished) return
                    var data = new Appointment(
                        a.config.providers[a.config.providerIndex],
                        facility.id,
                        a.config.serviceId,
                        a.start,
                        a.end,
                        [a.config.providers[a.config.providerIndex]],
                        a.config.roomId,
                        a.config.roomId,
                        a.config.occupancyId,
                        a.config.occupancyIdentifier,
                        a.config.serviceName,
                        null,
                        null,
                        "Room " + a.config.name,
                        null, 
                        null
                    )
                    if(a.config.linkedAppointmentId){
                        data.linkedAppointmentId = a.config.linkedAppointmentId
                    }
                    data.configId = a.config.configId

                    LegacyAppointmentManager.createAppointmentWithObject(data, (result) => {
                        if(result.id){
                            a.config.createdAppointmentId = result.id
                            if(a.config.require2fer){
                                configs.forEach(c => {
                                    if(c.configId === AutoScheduler.get2ferConfigId(a.config.configId)){
                                        c.linkedAppointmentId = a.config.createdAppointmentId
                                        console.log(`ADD ${a.config.createdAppointmentId} to ${c.configId}`)
                                    }
                                })
                            }
                        }
                        else{
                            console.error(result)
                            console.error(new Error("Appointment failed to be created. Check logs for more info"))
                        }
                        numScheduled++
                    })
                }
            })
        })

        await App.until(_ => numScheduled == numToSchedule)
      }

      static getProviderAvailable(providerId){
        var providerAvailable = true
        var provider = AutoScheduler.providerLookup[providerId]
        if(provider && provider.available == false) //explicitly check for false, as we want undefined and null to make this value true
            providerAvailable = false
        //TODO also check for available times that the provider has when that is hooked up
        return providerAvailable
      }

      static calculateAppointments(configs, providers, occupancies){
            configs.forEach(c => {
                if(c.failed) return
                c.scheduleNextRound = false
                if(c.splitConfig && !c.staged){
                    configs.forEach(split => {
                        if(split.configId == c.splitConfig){
                            //Schedule the split config
                            //in the next batch of appointments
                            split.scheduleNextRound = true
                        }
                    })
                }
                if(c.is2Fer && !c.start && !c.end && !c.linkedAppointmentId){
                    c.scheduleNextRound = true
                }
            })
          providers.forEach(p => {
              //If provider is not available, move on to next provider
              if(!AutoScheduler.getProviderAvailable(p.id)) return //Breaks out of current loop, not the function
              var duration = -1

              var slots = []

              //batch configs by duration - longest first
              configs.forEach(c => {
                    if(c.failed || c.finished || c.scheduleNextRound) return
                    if(c.providers[c.providerIndex] != p.id) return
                    //console.log(`Config process ${c.configId}`)
                    //default range 6 AM to 7 PM
                    var startMs = 6 * 60 * 60 * 1000
                    var endMs = 19 * 60 * 60 * 1000
                    duration = c.duration

                    if(c.splitStart){
                        var h = new Date(c.splitStart).getHours()

                        //If split config is before lunch,
                        //this config needs to be after lunch
                        if(h < 12) startMs = 12 * 60 * 60 * 1000
                        
                        //If split config is after lunch,
                        //this config needs to be before lunch
                        else endMs = 11 * 60 * 60 * 1000
                    }
                    if(c.is2Fer){ //if this config block is a 2fer, set allowed times to start of master appointent, and end of master appointment
                        var hStart = new Date(c.start).getHours()
                        var mStart = new Date(c.start).getMinutes()
                        startMs = hStart * 60 * 60 * 1000 + mStart * 60 * 1000

                        var hEnd = new Date(c.end).getHours()
                        var mEnd = new Date(c.end).getMinutes()
                        endMs = hEnd * 60 * 60 * 1000 + mEnd * 60 * 1000
                    }

                    var startTotal = startMs / 60 / 1000
                    var startHours = Math.floor(startTotal / 60)
                    var startMinutes = startTotal % 60

                    var start = new Date(AutoScheduler.date)
                    start.setHours(startHours)
                    start.setMinutes(startMinutes)
                    start.setSeconds(0)
                    start.setMilliseconds(0)
                
                    var endTotal = endMs / 60 / 1000
                    var endHours = Math.floor(endTotal / 60)
                    var endMinutes = endTotal % 60

                    var end = new Date(AutoScheduler.date)
                    end.setHours(endHours)
                    end.setMinutes(endMinutes)
                    end.setSeconds(0)
                    end.setMilliseconds(0)
                
                    var range = {
                    start: start.getTime(),
                    end: end.getTime()
                    }

                    
                    // console.log(`Restrict times: ${JSON.stringify(range)}`)

                    AutoScheduler.stageAppointments(p, occupancies, slots, configs)

                    //get available provider slots with this duration

                    var times = AutoScheduler.listAvailableTimes(p.appointments, c, range)

                    slots = AutoScheduler.listAvailableSlots(times, duration)

                    //TODO - try next provider if this one is unavailable
                    //TODO - skip to c.providers[1] first if 'require primary' is not checked
                    slots.forEach(s => {
                        var o = occupancies[c.occupancyId]
                        var conflicts = false
                        //filter out times that we cannot use. slot start and end must be all the way inside the range.
                        conflicts = conflicts || !CalendarUtil.isTimeRangeFullyInside(s.start, s.end, range.start, range.end) 
                        if(!c.is2Fer) {
                            o.appointments.forEach(a => {
                                conflicts = conflicts || CalendarUtil.doTimeRangesOverlap(a.start, a.end, s.start, s.end)
                            })
                        }
                        if(!conflicts) s.availableConfigs.push(c)
                    })
              })

              //push remaining appts
              AutoScheduler.stageAppointments(p, occupancies, slots, configs)
          })
      }

      static stageAppointments(provider, occupancies, slots, configs){
        var graph = {}
        slots.forEach(s => {
            s.availableConfigs.forEach(c => {
                if(graph[c.configId] == null) graph[c.configId] = []
                graph[c.configId].push(s.start)
            })
        })
        if(Object.keys(graph) == 0){
            console.log("Skipping pass. Graph has nothing to check")
            return
        }

        var karp = hopcroftKarp(graph)
        if(Object.keys(karp).length == 0){
            console.log("nothing to process")
            return
        }

        var configLookup = {} //build a config lookup table TODO save it like this to begin with
        configs.forEach((config)=>{
            configLookup[config.configId] = config
        })

        //calculate the final appointments
        Object.keys(karp).forEach(k => {
            var start = karp[k]
            console.log(`karp: ${k}`)

            slots.forEach(s => {
                if(s.start == start) {
                    var availableConfigLookup = {}
                    s.availableConfigs.forEach(c => {
                        availableConfigLookup[c.configId] = c
                    })

                    var config = availableConfigLookup[k]
                    config.staged = true
                    // console.log(`found config: ${k}`)

                    var splitConfig = config.splitConfig ? configLookup[config.splitConfig] : null
                    if(splitConfig){
                        // console.log(`found splitconfig: ${config.splitConfig}`)
                        splitConfig.splitStart = s.start.getTime()
                    }
                    var startTime = new Date(s.start).getTime()
                    var endTime = new Date(s.end).getTime()

                    var config2fer = config.require2fer ? configLookup[AutoScheduler.get2ferConfigId(config.configId)] : null
                    if(config2fer){
                        //Notify split config about this config's start time
                        //That will determine when the split config should be scheduled
                        // console.log(`found 2fer config: ${config2fer.configId}`)
                        config2fer.start = startTime
                        config2fer.end = endTime
                    }

                    var appt = {
                        start: startTime,
                        end: endTime,
                        config: config //keep track of config for later
                    }
                    // console.log(`scheduled for ${appt.start} to ${appt.end}`)
                    provider.appointments.push(appt) //block off staged appointment time
                    
                    var o = occupancies[config.occupancyId]
                    o.appointments.push(appt)
                }
            })
        })
      }

      //duration of slot in minutes
    static listAvailableSlots(availableTimes, duration){

        var slots = []

        availableTimes.forEach(e => {
            var start = e.start.getTime()

            while(start < e.end.getTime()){
                var end = start + (duration * 60 * 1000) 

                if(end <= e.end.getTime()){
                    slots.push({
                        start: new Date(start),
                        end: new Date(end),
                        availableConfigs: []
                    })
                }

                start = start + (15 * 60 * 1000) //get new slot every 15 minutes - TODO: test reprocussions. Uncomment next line if there are issues.
                //start = end
            }

        })

        return slots
    }


    static listAvailableTimes = (unavailableTimes, config, dateRange) => {

        if(!dateRange)
            dateRange = CalendarUtil.getOpenTimeRange(new Date(AutoScheduler.date))

        var openTimes = []
        var service = AutoScheduler.serviceIdLookup[config.serviceId]
        if(service.timeBlocks && service.timeBlocks.length > 0) {
            service.timeBlocks.forEach(tb => {

                var s = tb.start / 60 / 1000
                var sh = Math.floor(s / 60)
                var sm = s % 60
                var start = new Date(AutoScheduler.date)
                start.setHours(sh)
                start.setMinutes(sm)
                start.setSeconds(0)
                start.setMilliseconds(0)

                var e = tb.end / 60 / 1000
                var eh = Math.floor(e / 60)
                var em = e % 60
                var end = new Date(AutoScheduler.date)
                end.setHours(eh)
                end.setMinutes(em)
                end.setSeconds(0)
                end.setMilliseconds(0)

                openTimes.push(
                    {
                        start: start, 
                        end: end
                    }
                )
            })

            //check and restrict times to what open time is. Add it to unavailableTimes
            openTimes.sort((a, b) => (a.start > b.start) ? 1 : -1)
            var endTimeOpen = dateRange.start
            openTimes.forEach((time)=>{
                if(endTimeOpen < time.start)
                    unavailableTimes.push({
                        start: endTimeOpen,
                        end: time.start
                    })
                endTimeOpen = time.end
            })
            //now check against dateRange.end
            if(endTimeOpen < dateRange.end){
                unavailableTimes.push({
                    start: endTimeOpen,
                    end: dateRange.end
                })
                dateRange.end = endTimeOpen
            }
        }

        var start = dateRange.start

        unavailableTimes.sort((a, b) => (a.start > b.start) ? 1 : -1)

        var avail = []

        unavailableTimes.forEach(e => {

            if(start < e.start){
                avail.push({
                    start: new Date(start),
                    end: new Date(e.start)
                })
            }

            start = e.end
        })

        avail.push({
            start: new Date(start),
            end: new Date(dateRange.end)
        })

        return avail
    }
}