import React from 'react';
import {Button, Modal} from 'react-bootstrap'
import Colors from '../../util/Colors';
import Tracker from '../../managers/Tracker';
import SelectRoomPage from '../ScheduleAppointment/SelectRoomPage';
import { StringResources } from '../../strings/StringManager';

export default class MovePatientPage extends React.Component{

    constructor() {
      super()
      Tracker.logScreenView('manage_patients_move')
    }
  
    cancel = () => {
      this.props.machine.send("CANCEL")
    }

    onRoomSelected = (room) => {
        this.props.onMove(room.id) 
        this.props.machine.send("CONFIRM_MOVE")
    }
  
    render(){
      return(
        <>
          <SelectRoomPage 
            showLabels={true} 
            rooms={this.props.rooms} 
            machine={this.props.machine} 
            roomVisibility={["unoccupied"]} 
            unoccupiedLabelOverride={`Unoccupied Rooms. Click to move ${StringResources.patient()}.`}
            onRoomSelected={this.onRoomSelected}/>
          <Button variant="primary" onClick={this.cancel} style={{backgroundColor: Colors.Primary.Main, border: "0px", position:"fixed", bottom:60, right:80}}>Cancel</Button>
        </>
      )
    }
  }