import React from 'react'
import SDEditTextBox from '../../ui/SDEditTextBox'
import './Components/editRoleAccess.css'
import ColumnsRenderer from '../../ui/ColumnsRenderer'
import AccessOption from './Components/AccessOption'
import ConfigManager from '../../managers/ConfigManager'
import LoadingSpinner from '../../util/LoadingSpinner'
import SDSubNavBar from '../../ui/SDSubNavBar'
import HomeOptions from '../Home/HomeOptions'
import NavigationHeader from '../../ui/NavigationHeader'
import FacilityManager from '../../managers/FacilityManager'
import Helpers from '../../util/Helpers'

export default class EditRoleAccess extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            title: props.item.title,
            loading: false,
            selectedItems: []
        }
        if(!this.props.new){
            this.state.loading = true
            this.getRoleConfig()
        }
    }

    getRoleConfig(){
        ConfigManager.getHomeOptions(this.props.facility.id, this.props.item.id, (result)=>{
            console.log(result)
            this.setState({selectedItems: result, loading: false})
        })
    }

    getSaveText = () => {
        return this.props.new ? 'Create Role' : 'Save'
    }

    render() {
        if(this.state.loading) return <LoadingSpinner/>
        var items = this.buildOptionsList()
        var columns = this.getColumnCount()
        var title = (<div className="edit-role-access-title">Access</div>)
        var titleRowPlaceholder = (<div className="edit-role-access-title"></div>)
        return (
            <>
                <NavigationHeader navigationOptionsTitles={[/*'Delete',*/ 'Cancel', this.getSaveText()]} navigationBackTitle={'Manage User Roles'} onNewOption={this.onNewAction} onNavigateBack={this.onNavigateBack}/>
                <div className='edit-role-access-container'>
                    <div className='edit-role-name-container'>
                        <SDEditTextBox disabled={!this.props.item.deletable || this.props.item.id !== ''} label='NAME' title={this.state.title} onChange={this.onEditTitle}/>
                    </div>
                    <div className="edit-role-access-list-container">
                        <ColumnsRenderer columns={columns} titleElement={title} titlePlaceholderElement={titleRowPlaceholder}>
                            {items}
                        </ColumnsRenderer>
                    </div>
                </div>
            </>
        )
    }

    onNewAction = (action) => {
        switch(action){
            case 'Delete': 
                break
            case 'Cancel':
                this.onNavigateBack()
                break;
            case this.getSaveText():
                this.save()
                break;
        }
    }

    onNavigateBack = () => {
        this.props.onCancel()
    }

    onEditTitle = (title)=> {
        this.setState({ title: title });
    }

    getColumnCount = () => {
        return 3
    }

    buildOptionsList = () => {
        let accessList = HomeOptions.GetAccessList()
        return Object.keys(accessList).map((key)=>{
            var value = accessList[key]
            return <div className='edit-access-list-item'>
                <AccessOption id={key} title={value.title} icon={value.icon} selected={this.state.selectedItems.includes(key)} onClick={()=>{
                    var items = [...this.state.selectedItems]
                    if(this.state.selectedItems.includes(key)){
                        items.splice(items.indexOf(key), 1)
                    }
                    else{
                        items.push(key)
                    }
                    this.setState({selectedItems: items})
                }}/>
            </div>
        })
    }

    save = async()=> {
        this.setState({loading: true})
        var item = Object.assign({}, this.props.item)
        item.title = this.state.title
        item.id = Helpers.convertRoleNameToId(this.state.title)
        if(this.props.new){
            var result = await new Promise((resolve)=>{
                FacilityManager.addRole(this.props.facility.id, item.id, (result)=>{
                    resolve(result)
                })
            })
            if(result.statusCode == 403)
                return
        }
        ConfigManager.updateTargetedFacilityConfig(this.props.facility.id, item.id, 'home', this.state.selectedItems, (result)=>{
            this.onNavigateBack()
        })
    }
}