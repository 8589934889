import React from 'react';
import {Button} from 'react-bootstrap'
import Colors from '../../util/Colors'
import App from '../../App'
import HybridTimePicker from '../../util/HybridTimePicker';
import Helpers from '../../util/Helpers'
import { toast } from 'react-toastify';
import LoadingSpinner from '../../util/LoadingSpinner';
import AppointmentPopupTitleDropdown from "../ScheduleAppointment/AppointmentPopupTitleDropdown";

export default class SchedulePopup extends React.Component{

    stagingId

    constructor(props){
        super(props)
        
        let endTime
        if(props.blob.type === 1) endTime = new Date(props.blob.clickedTime).getTime() + props.defaultDurationInMinutes * 60 * 1000
        else endTime = props.blob.endTime

        /**
         * @type {AppointmentModel}
         */
        let appt = window.structuredClone(props.blob.appointment)

        let defaultProviders = Object.assign([],props.defaultProviders) ?? []
        let defaultLocation = null
        let locations = {}
        
        if(this.props.service && this.props.service.pickLocation){
            locations = Object.assign(locations, props.facility.locations)
        }
        
        // props.room can be an object or an array of objects
        // Let's only worry about it if there is only one object
        // since we do not support room selection when there are multiple rooms

        if(!this.props.isGroupAppointment && props.room) { //yes, a group appointment can have one occupant, but still be a group appointment, so just check for the flag
            let room
            if(Array.isArray(props.room) && props.room.length === 1) {
                room = props.room[0]
            } else {
                // old non-array format
                if (props.room.id){ 
                    room = props.room
                }
            }
            if (room != null) {
                locations[room.id] = {
                    id: room.id,
                    name: `${Helpers.getRoomName(room)} - ${room.identifier}`
                }
            }
        }

        let providers = props.providers ? Object.values(props.providers) : null

        if(props.service){
            if(appt.providers && appt.providers.length > 0){
                defaultProviders = []
                //do nothing...
            }
            else if(props.service.providers && props.service.pickProvider){
                let resetDefaultProviders = false
                if(props.service.providers.length !== defaultProviders.length){ 
                    //something changed that we are aware of. Clear our defaults back to normal
                    resetDefaultProviders = true
                }
                else{ //they are equal, but we need to do more checks
                    //now we check to make sure all providers selected still exist in the list of allowed providers
                    defaultProviders.forEach((providerId, index)=> {
                        let p = props.service.providers[index]
                        if(p.allowedProviders === "Any" && Array.isArray(providers) && !providers.includes(providerId))
                            resetDefaultProviders = true
                        else if(p.options && p.options.length > 0 && !p.options.includes(providerId))
                            resetDefaultProviders = true
                    })
                }
                if(resetDefaultProviders){
                    defaultProviders = []
                }

                if(defaultProviders.length === 0){
                    defaultProviders = this.getServiceProviderDefaults(props, providers)
                }
            }
            
            if(appt.occupants && appt.occupants.length && appt.locations.length === 0)
                appt.locationId = ''
            
            if((appt.locations && appt.locations.length > 0)){
                defaultLocation = appt.locations[0].id
            }
            else if(props.service.pickLocation){
                for(let i = 0; i < Object.keys(locations).length && !defaultLocation; i++){ //pick the first available room
                    let location = locations[Object.keys(locations)[i]]
                    if(!location.disabled){
                        defaultLocation = location.id
                        break
                    }
                }
            }
        }
        let startTime = props.blob.appointment.creatorId ? props.blob.startTime : props.blob.clickedTime
        this.state = {
            locations: locations,
            defaultWidth: 325,
            defaultHeight: 275,
            pendingDeletion: false,
            time:{
                start: startTime,
                end: endTime
            },
            providers: appt.providers && appt.providers.length > 0 ? appt.providers.map(p => p.id) : defaultProviders,
            location: defaultLocation,
            title: this.props.editTitle ? appt.title : 
                this.props.service ? this.props.service.name : ""
        }
    }

    getServiceProviderDefaults = (props, providers)=> {
        let defaultProvidersList = []
        props.service.providers.forEach((p, index) => {
            if(p.allowedProviders === "Any" && Array.isArray(providers) && providers.length > 0) defaultProvidersList.push(providers[0].id)
            else if(p.options && p.options.length > 0) defaultProvidersList.push(p.options[0])
            else if(p.options && p.options.length === 0) defaultProvidersList.push("null" + index)
        })
        return defaultProvidersList
    }

    drawLabel(label, fontSize, top, left, color = "#000000"){
        return <div style={{color: color, position:"absolute", fontSize:fontSize, top:top, left:left}}>{label}</div>
    }

    onChangeStartTime = (date) => {
        let time = this.state.time
        let duration = time.end - time.start

        time.start = date
        time.end = new Date(date).getTime() + duration
        this.setState({
            time: time
        }, () => this.props.editTime(time))
    }

    onChangeEndTime = (date) => {
        let time = this.state.time
        time.end = date

        this.setState({
            time: time
        }, () => this.props.editTime(time))
    }

    drawInputfield(top, left, id, enabled = true, valid = false, value){
        let invalidTimeIcon = valid ? null : <img style={{position:"absolute", top:top + 5, right: 2, height:16, width:16}} src={require('../../img/Icons/alert.png')}/>
        let onChange = id === "starttime" ? this.onChangeStartTime : this.onChangeEndTime

        return <>
        {invalidTimeIcon}
        <div style={{position:"absolute", top:top, left:left}}>
        <HybridTimePicker 
        value={value}
        onChange={onChange}
        listener={this}
        />
            {/* <input onClick={this.editTime} readonly="readonly" disabled={!enabled} id={id} style={{width:140, height:18, fontSize:12}}/> */}
            </div>
            </>
    }

    drawTextInput(top, left, width, height, id, value, onChange, onKeyDown){
        return <form autoComplete="off" style={{position:"absolute", top:top, left:left}}>
            <input onKeyDown={(e)=>{
                    if(onKeyDown) onKeyDown(e)
                    else this.blockSubmit(e)
                }} 
                onChange={onChange} 
                id={id} 
                value={value} 
                style={{width: width, height: height, fontSize:12}}/>
        </form>
    }

    blockSubmit = (e)=> {
        if (e.key !== 'Enter') return
        e.preventDefault();
        return false
    }

    drawNotesField(top, left, width, height, id){
        return <div style={{position:"absolute", top:top, left:left}}>
        <textarea id={id} style={{width: width, height: height, fontSize:12}}/>
        </div>
    }

    drawDropdown(top, left, id){
        return <div style={{position:"absolute", top:top, left:left}}>

        <select id={id} style={{width:140, fontSize:12}}>
            {this.renderOptions(this.state[id])}
        </select>

        </div>
    }

    renderOptions(items){
        return items.map((item, index) => {
            return <option value={item.id}>{item.firstName} {item.lastName}</option>
        })
      }


    discard = () => {
        this.props.machine.send("CLOSE")
        this.props.onClose()
    }

    delete = () => { 
        this.setState({
            pendingDeletion: true
        })
    }

    cancelDelete = () => {
        this.setState({
            pendingDeletion: false
        })
    }

    confirmDelete = () => {
        this.props.machine.send("CLOSE")
        this.props.onDelete(this.props.blob)
    }

    buildAppointment(){
        let notes = document.getElementById("notes").value
        let locationId = this.state.location
        if(locationId === '')
            locationId = null

        let appt = {
            facilityId: this.props.facility.id,
            serviceId: this.props.service.id,
            locationId: locationId,
            creatorId: this.props.user.creatorId,
            title: this.getTitle(),
            description: null,
            notes: notes,
            stagingId: this.stagingId,
            stagingTime: new Date().getTime(),
            id: this.props.blob.appointmentId,
            require2fer: this.props.blob?.appointment?.require2fer,
            locations: locationId ? [{id: locationId}] : [],
        }
        if(this.state.providers && this.state.providers.length > 0){
            appt.providers = this.state.providers.map(p => {
                return {
                    id: p,
                    name: this.getProviderName(p)
                }
            })
        }
        return appt
    }

    save = () => {
        let startTime = new Date(this.state.time.start)
        let endTime = new Date(this.state.time.end)

        let appt = this.buildAppointment()
        
        appt.start = startTime.getTime()
        appt.end = endTime.getTime()

        const {valid, reason} = this.props.validate ? this.props.validate(appt, this.state.providers) : {valid: true, reason: null}

        if(valid){
            appt.incomplete = undefined
            appt.conflicts = undefined
            appt.conflictingProviders = undefined
            appt.eTag = this.state.previousAppointment?.eTag
            this.props.onAppointmentStaged(appt, this.state.previousAppointment)
            this.props.machine.send("CLOSE")
            this.props.onClose()
        }
        else{
            toast(`Unable to save appointment. Reason: ${reason}`)
        }
    }

    componentWillMount(){
        this.setState({
            previousAppointment: this.props.blob.appointment
        })
    }

    componentDidMount(){
        this.stagingId = this.props.blob.startTime

        if(this.props.service && this.props.blob.appointment.notes != null) document.getElementById("notes").value = this.props.blob.appointment.notes
        this.props.onPopupMounted()
        this.props.editTime(this.state.time)
    }

    getDeleteConfirmText(){
        return "Delete appointment?"
    }

    drawOptions(){
        let deleteButton = <div style={{zoom:0.75, zIndex:100, position:"absolute", bottom:"10px", left:"20px"}}>
                            <Button variant="danger" onClick={this.delete} style={{width:"80px"}}>Delete</Button>
                           </div>

        if(this.props.blob.type === 1) deleteButton = null

        if(this.state.pendingDeletion){
            return(
                <div style={{zoom:0.75, zIndex:100, position:"absolute", bottom:"10px", right:"10px"}}>
                {this.getDeleteConfirmText()} &nbsp;
                <Button variant="danger" onClick={this.confirmDelete} style={{width:"80px", marginRight:10}}>Delete</Button>
                <Button variant="primary" onClick={this.cancelDelete} style={{width:"80px", marginRight:10, backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>Cancel</Button>
              </div>
            )
        }else{
            let isFullDay = this.props.service && this.props.service.fullDay
            let saveDisabled = !isFullDay && (
                !this.props.validStart || !this.props.validEnd
            )
            return(
                <>
                {deleteButton}
                <div style={{zoom:0.75, zIndex:100, position:"absolute", bottom:"10px", right:"10px"}}>
                <Button variant="link" onClick={this.discard} style={{color:Colors.Primary.Main, marginRight:10}}>Cancel</Button>
                <Button variant="primary" disabled={saveDisabled} onClick={this.save} style={{width:"80px", marginRight:10, backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>Save</Button>
              </div>
              </>
            )
        }
    }

    drawLocationDropdown(offset){
        let service = this.props.service
        if(!service.pickLocation) return null

        let top = 87.5 + offset + 10

        return (
            <>
            {this.drawLabel("Location", 14, top, 30)}
    
        <div style={{position:"absolute", top: top, left: 160}}>

        <select value={this.state.location} style={{width:140, fontSize:12}} onChange={(e) => this.setLocation(e.target.value)}>
            {
                this.renderLocationOptions()
            }
        </select>

        </div>
        </>
        )
    }

    renderLocationOptions(){
        let locationOptions = {}

        //need to get all names in a map
        Object.keys(this.state.locations).forEach(loc => {
            let locData = this.state.locations[loc]
            if(locData.disabled) return
            locationOptions[locData.name] = loc
        });

        //so we can sort them
        let locations = Object.keys(locationOptions)
        Helpers.sort(locations)

        //and then display them
        return locations.map(loc => {
            return <option value={locationOptions[loc]}>{loc}</option>
        })
    }

    setLocation(id){
        this.setState({location: id})
    }

    drawProviderDropdowns(){
        let service = this.props.service

        if(service.providers == null || !service.pickProvider) return null
        return service.providers.map((p, i) => {
            return this.drawProviderOptions(p, i)
        })
    }

    setProvider(id, index){
        let providers = this.state.providers
        providers[index] = id
        this.setState({providers: providers})
    }

    drawProviderOptions(provider, index){
        let top = 87.5 + index * 20
        let id = this.state.providers[index]
        let appointment = this.props.blob.appointment
        let color = undefined
        if(appointment.conflictingProviders && appointment.conflictingProviders.includes(id)){
            color = "red"
        }
        return (
            <>
            {this.drawLabel(provider.title, 14, top, 30, color)}
    
        <div style={{position:"absolute", top: top, left: 160}}>

        <select value={id} style={{width:140, fontSize:12, color: color}} onChange={(e) => this.setProvider(e.target.value, index)}>
            {this.fillSelect(provider)}
            <option value={"null" + index}>None assigned</option>
        </select>
        </div>
        </>
        )
    }

    fillSelect(provider){
        let options = {} //map with the name as the key, and the option as the value. Only good way to currently sort

        if(provider.allowedProviders === "Only"){
            provider.options.forEach(option => { //only select providers
                options[this.getProviderName(option)] = option
            })
        }
        else{
            Object.keys(this.props.providers).forEach(option => { //all providers in current service
                options[this.getProviderName(option)] = option
            })
        }

        //sort the keys
        let keys = Object.keys(options)
        Helpers.sort(keys)
        //return our options
        return keys.map((option) => {
            let appointment = this.props.blob.appointment
            let style = {}
            if(appointment.incomplete && appointment.conflictingProviders && appointment.conflictingProviders.includes(options[option])){
                style.color = "red"
            }
            else{
                style.color = '#000000'
            }
            return <option style={style} value={options[option]}>{option}</option>
        })
    }

    getProviderName(pId){
        let name = ""
        Object.keys(this.props.facility.providers).forEach(e => {
            if(e === pId){
                let provider = this.props.facility.providers[e]
                name = provider.firstName + " " + provider.lastName
                if(provider.role === "unused"){
                    name += " (Disabled)"
                }
            }
        })
        return name
    }

    /* Sort any array of strings to a case insensitve alphabetical order */
    sort(data){
        data.sort((a,b)=> {
            let aNoCase = a.toLowerCase()
            let bNoCase = b.toLowerCase()
            return (aNoCase > bNoCase) ? 1 : (aNoCase < bNoCase) ? -1 : 0
        })
    }

    getTitle = () => {
        if(this.props.editTitle){
            return this.state.title
        }
        return this.props.blob.appointment.title ?? this.props.title ?? this.props.service.name
    }

    renderTimePicker = () => {
        return this.props.service && this.props.service.fullDay ? this.drawLabel("All Day", 14, 50, this.labelLeft) : [
            this.drawLabel("Start Time", 12, 40, this.labelLeft),
            this.drawLabel("End Time", 12, 65, this.labelLeft),
            this.drawInputfield(37.5, this.inputLeft, "starttime", true, this.props.validStart, this.state.time.start),
            this.drawInputfield(62.5, this.inputLeft, "endtime", true, this.props.validEnd, this.state.time.end)
        ]
    }

    renderTitle = () => { //overriden in other objects, other objects use inputLeft
        if(this.props.editTitle){
            return [
                this.drawLabel("Name", 16, 10, this.labelLeft),
                this.drawTextInput(9, this.inputLeft, 113+this.totalOffset, 20, "title", this.getTitle(), ()=>{
                    this.setState({title: document.getElementById('title').value})
                })
            ]
        }
        else{
            let title = this.getTitle()
            return [
                <AppointmentPopupTitleDropdown enableDropdown={false} title={title}/>,
                this.drawLabel("Event Type", 16, 10, this.labelLeft)
            ]

        }
    }

    renderDropdowns = () => {
        let providersOffset = this.props.service.providers && this.props.service.pickProvider ? this.props.service.providers.length * 20 : 0
        let locationOffset = this.props.service.pickLocation ? 30 : 0
        let totalOffset = providersOffset + locationOffset
        return {
            offset: totalOffset,
            render: (<>
                {this.drawProviderDropdowns()}
                {this.drawLocationDropdown(providersOffset)}
            </>)
        }
    }

    renderNotes = () => {
        return [
            this.drawLabel("Notes", 16, 95 + this.totalOffset, this.labelLeft),
            this.drawLabel(App.placeholder, 12, 120 + this.totalOffset, this.labelLeft, "#808080"),
            this.drawNotesField(162 + this.totalOffset, this.labelLeft, this.w - 55, 60, "notes")
        ]
    }

    renderContent() {
        let dropdowns = this.renderDropdowns()
        let dropdownUI = null
        if(dropdowns){
            dropdownUI = dropdowns.render
            this.totalOffset += dropdowns.offset
        }

        return [
            this.renderTitle(),
            this.renderTimePicker(),
            dropdownUI,
            this.drawOptions(),
            this.renderNotes()
        ]
    }

    render(){
        this.labelLeft = 30
        this.inputLeft = 160
        this.w = this.state.defaultWidth
        this.h = this.state.defaultHeight
        this.totalOffset = 0

        const content = this.renderContent()

        const finalHeight = this.h + this.totalOffset

        let top = this.props.blob.clickedY - this.h/2
        if(this.props.blob.pageY+finalHeight > window.innerHeight-100){
            top = this.props.blob.clickedY - finalHeight - this.h/2
        }

        return(
            <div style={
                {
                    zIndex:100,
                    width:this.w,
                    height:finalHeight, 
                    backgroundColor:"#ffffffE6", 
                    position:"absolute", 
                    left:(this.props.offsetX ?? 0) + (window.innerWidth/2 - this.w/2),
                    top:top, 
                    border:"2px solid black"
                }
                }>            
                {content}
                {this.props.popupLoading ? ( //loading container that blocks input until we have loaded data
                    <div style={{position: 'absolute',zIndex:200, width:this.w, height:finalHeight, backgroundColor:"#ffffff50"}} onClick={()=>{}}>
                        <LoadingSpinner/>
                    </div>
                ):null}
            </div>
        )
    }
}