export const ScheduleAppointmentMachine = {
    initial: 'GetServices',
    states: {
      GetServices: {
        on: {
          RESOLVE: 'ShowServices'
        }
      },
      ShowServices: {
        on: {
          CANCEL: 'GetServices',
          NEXT: 'GetProviders'
        }
      },
      GetProviders:{
        on:{
          RESOLVE: 'ShowProviders',
          REJECT: 'ShowServices',
          CANCEL: 'ShowServices',
          PREVIOUS: 'ShowServices'
        }
      },
      ShowProviders:{
        on:{
          NEXT: 'GetOccupancies',
          CANCEL: 'ShowServices',
          RESET: 'ShowServices',
          PREVIOUS: 'ShowServices'
        }
      },
      GetOccupancies:{
        on:{
          RESOLVE: 'ShowOccupancies',
          REJECT: 'ShowProviders',
          CANCEL: 'ShowServices',
          PREVIOUS: 'GetProviders'
        }
      },
      ShowOccupancies:{
        on:{
          NEXT: 'GetTimes',
          CANCEL: 'ShowServices',
          PREVIOUS: 'GetProviders'
        }
      },
      GetTimes:{
        on:{
          RESOLVE: 'SelectTime',
          REJECT: 'ShowServices',
          CANCEL: 'ShowServices',
          PREVIOUS: 'GetOccupancies'
        }
      },
      SelectTime:{
        on:{
          NEXT: 'GetLocations',
          POPUP: 'AppointmentPopup',
          CANCEL: 'ShowServices',
          PREVIOUS: 'GetOccupancies'
        }
      },
      AppointmentPopup:{
        on:{
          CLOSE: 'SelectTime',
          NEXT: 'GetLocations',
          CANCEL: 'SelectTime',
        }
      },
      GetLocations:{
        on:{
          RESOLVE: 'SelectLocation',
          REJECT: 'ShowServices',
          CANCEL: 'ShowServices',
          PREVIOUS: 'GetTimes'
        }
      },
      SelectLocation:{
        on:{
          NEXT: 'Review',
          CANCEL: 'ShowServices',
          PREVIOUS: 'GetTimes'
        }
      },
      Review:{
        on:{
          EDIT_LOCATION: 'GetLocations',
          EDIT_TIMES: 'GetTimes',
          ANOTHER: 'GetOccupancies',
          FINISH: 'GetServices',
          CANCEL: 'ShowServices',
          PREVIOUS: 'GetLocations'
        }
      },
      stop: {}
    }
  };