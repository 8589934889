import React from 'react';
import {Button} from 'react-bootstrap'
import Colors from '../../util/Colors'
import HybridTimePicker from '../../util/HybridTimePicker';
import SchedulingCalendar from '../../Calendars/SchedulingCalendar';
import AppointmentPopupTitleDropdown from "./AppointmentPopupTitleDropdown";

export default class AppointmentPopup extends React.Component{

    constructor(props){
        super(props)
        const service = this.props.appointment.service;
        let defaultDuration = this.props.defaultDuration ?? SchedulingCalendar.defaultFallbackDuration;
        if(!this.props.appointment.occupancies){
            const occupancy = this.props.appointment.occupancy;
            const evalTime = occupancy && occupancy.services ? this.props.appointment.occupancy.services[service.id] : null;
            if(evalTime != null) defaultDuration = parseInt(evalTime)
        }

        const endTime = new Date(this.props.blob.clickedTime).getTime() + defaultDuration * 60 * 1000;

        this.state = {
            showDropdown: true,
            time:{
                start: props.blob.clickedTime,
                end: endTime
            }
        }
    }

    drawLabel(label, fontSize, top, left){
        return <div style={{position:"absolute", fontSize:fontSize, top:top, left:left}}>{label}</div>
    }

    onChangeStartTime = (date) => {
        const time = this.state.time;
        const duration = time.end - time.start;

        time.start = date
        time.end = new Date(date).getTime() + duration

        this.setState({
            time: time
        }, () => this.props.editTime(time))
    }

    onChangeEndTime = (date) => {
        const time = this.state.time;
        time.end = date

        this.setState({
            time: time
        }, () => this.props.editTime(time))
    }

    drawInputfield(top, left, id, enabled = true, valid = false, value){
        const invalidTimeIcon = valid ? null :
            <img style={{position: "absolute", top: top + 5, right: 2, height: 16, width: 16}}
                 src={require('../../img/Icons/alert.png')}/>;
        const onChange = id === "starttime"
            ? this.onChangeStartTime
            : this.onChangeEndTime;

        return <>
        {invalidTimeIcon}
        <div style={{position:"absolute", top:top, left:left}}>
        <HybridTimePicker 
        value={value}
        onChange={onChange}
        listener={this}
        />
            {/* <input onClick={this.editTime} readonly="readonly" disabled={!enabled} id={id} style={{width:140, height:18, fontSize:12}}/> */}
            </div>
            </>
    }

    discard = () => {
        this.props.machine.send("CANCEL")
        this.props.onClose()
    }

    save = () => {
        const startTime = new Date(this.state.time.start);
        const endTime = new Date(this.state.time.end);

        const time = {
            startTime: startTime.getTime(),
            endTime: endTime.getTime(),
        };

        this.props.machine.send("CLOSE")
        this.props.onSelectTime(time)
    }

    componentDidMount(){
        this.props.onPopupMounted()
        this.props.editTime(this.state.time)
    }

    render(){
        const labelLeft = 30;
        const inputLeft = 160;
        const w = 325;
        const h = 135;

        const isFullDay = this.props.appointment.service && this.props.appointment.service.fullDay;
        const saveDisabled = !isFullDay &&
            (!this.props.validStart || !this.props.validEnd);
        const timeRendering = isFullDay ? (
            <>
                {this.drawLabel("All Day", 12, 40, labelLeft)}
            </>
        ) : (
            <>
                {this.drawLabel("Start Time", 12, 40, labelLeft)}
                {this.drawLabel("End Time", 12, 65, labelLeft)}

                {this.drawInputfield(37.5, inputLeft, "starttime", true, this.props.validStart, this.state.time.start)}
                {this.drawInputfield(62.5, inputLeft, "endtime", true, this.props.validEnd, this.state.time.end)}
            </>
        );

        let top = this.props.blob.clickedY - h / 2;
        if(this.props.blob.pageY+220 > window.innerHeight){
            top = this.props.blob.clickedY - 220 - h/2 
        }
        return(
            <div style={{zIndex:100, width:w, height:h, backgroundColor:"white", position:"absolute", left:0, right:0, top:top, margin: "auto", border:"2px solid black"}}>


            {this.drawLabel("Event Type", 16, 10, labelLeft)}
            {timeRendering}

            {this.renderAppointmentTitle()}

            <div style={{zoom:0.75, zIndex:100, position:"absolute", bottom:"10px", right:"10px"}}>
              <Button variant="link" onClick={this.discard} style={{color:Colors.Primary.Main}}>Cancel</Button>
              <Button variant="primary" disabled={saveDisabled} onClick={this.save} style={{width:"80px", backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>Save</Button>
            </div>

            </div>
        )
    }

    renderAppointmentTitle() {
        let appt = this.props.appointment
        let title = appt.title ?? appt?.service?.name
        return <AppointmentPopupTitleDropdown enableDropdown={false} title={title}/>
    }
}