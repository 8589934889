import React from "react";
import { SignIn } from "aws-amplify-react";
import { Button } from "react-bootstrap";
import Colors from "../../util/Colors";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import LoadingSpinner from "../../util/LoadingSpinner";
import styles from "./CustomAuth.module.css";
import SDEditTextBox from "../../ui/SDEditTextBox";
import CircleBackground from "./CircleBackground";
import LoginContainer from "./LoginContainer";

export default class CustomAuthV2 extends SignIn {
  constructor() {
    super();
    
    this.state = {
      username: "",
      tempPassword: "",
      loading: false,
    };

    this.onClickForgotPassword = this.onClickForgotPassword.bind(this);
    this.onClickForgotPasswordCancel = this.onClickForgotPasswordCancel.bind(
      this
    );
    this.onClickSignIn = this.onClickSignIn.bind(this);
    this.onClickResetPassword = this.onClickResetPassword.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.forceUpdate();
  };

  render() {
    if (this.state.error != null) {
      this.setState({
        error: null,
      });
    }
    let state = this.props.machine.state.value["Login"]

    let body
    switch (state) {
        case "Transition": //use this to stop chrome autofill from staying attached to a field...
          this.props.machine.send("NEXT")
          body = null
          break
        case "SignIn":
          body = this.drawSignInPage()
          break
        case "ForgotPassword":
          body = this.drawForgotPasswordPage()
          break
        case "ResetPassword":
          body = this.drawVerificationForm()
          break
        case "ForceResetPassword":
          body = this.drawForceChangePasswordPage()
          break
    }
    return (
      <CircleBackground>
        <LoginContainer>{body}</LoginContainer>
      </CircleBackground>
    );
  }

  drawSignInPage(){
    return <>
        <SDEditTextBox rounded id="email-container" label="EMAIL" placeholder="Required Field" inputId="username" className={styles.editText}/>
        <SDEditTextBox rounded id="password-container" label="PASSWORD" type="password" placeholder="Required Field" inputId="password" className={styles.editText}/>
        <button disabled={this.state.loading} className={styles.loginButton} onClick={this.onClickSignIn}>
          {this.state.loading ? <LoadingSpinner size={30} color={Colors.Primary.Light} marginTop={0}/> : "SIGN IN"}
        </button>
        <div className={styles.forgotPasswordButton} onClick={this.onClickForgotPassword}>Forgot Password?</div>
    </>
  }

  drawForgotPasswordPage(){
    return <>
        <SDEditTextBox rounded id="forgot-container-email" label="EMAIL" placeholder="Required Field" inputId="username" className={styles.editText}/>
        <button disabled={this.state.loading} className={styles.loginButton} onClick={this.confirmForgotPassword}>
          {this.state.loading ? <LoadingSpinner size={30} color={Colors.Primary.Light} marginTop={0}/> : "RESET PASSWORD"}
        </button>
        <div className={styles.forgotPasswordButton} onClick={this.onClickForgotPasswordCancel}>Cancel</div>
    </>
  }

  drawVerificationForm(){
    return <>
        <SDEditTextBox rounded invisible id="forgot-container-email" label="EMAIL" placeholder="Required Field" inputId="username" className={styles.editText}/> {/*Hack to make chrome autofill not return the code as the username...*/}
        <SDEditTextBox rounded id="reset-container-code" label="CODE" placeholder="Required Field" inputId="verification" type="number" className={styles.editText}/>
        <SDEditTextBox rounded id="new-password-container" label="PASSWORD" placeholder="Required Field" inputId="newpassword" type="password" autoComplete="new-password" className={styles.editText}/>
        <button disabled={this.state.loading} className={styles.loginButton} onClick={this.onSubmitVerification}>
          {this.state.loading ? <LoadingSpinner size={30} color={Colors.Primary.Light} marginTop={0}/> : "SUBMIT"}
        </button>
    </>
  }

  drawForceChangePasswordPage(){
    return <>
        <SDEditTextBox rounded invisible id="email-container" label="EMAIL" placeholder="Required Field" inputId="username" className={styles.editText}/> {/*Hack to make chrome autofill not return the code as the username...*/}
        <SDEditTextBox rounded id="new-password-container" label="NEW PASSWORD" placeholder="Required Field" inputId="newpassword" type="password" autoComplete="new-password" className={styles.editText}/>
        <button disabled={this.state.loading} className={styles.loginButton} onClick={this.onSubmitPasswordChange}>
          {this.state.loading ? <LoadingSpinner size={30} color={Colors.Primary.Light} marginTop={0}/> : "SUBMIT"}
        </button>
    </>
  }

  onClickForgotPassword() {
    this.props.machine.send("FORGOT")
  }

  onClickForgotPasswordCancel() {
    this.props.machine.send("CANCEL")
  }

  onClickResetPassword() {
    this.props.machine.send("RESET")
  }

  onPasswordReset() {
    this.props.machine.send("NEXT")
  }

  confirmForgotPassword = () => {
    var username = document
      .getElementById("username")
      .value.toLowerCase()
      .trim();

    this.setState({
      loading: true,
      username: username,
    });

    Auth.forgotPassword(username)
      .then((data) => {
        this.setState({loading: false}, ()=>{
          this.onClickResetPassword()
        })
      })
      .catch((err) => {
        this.setState({loading: false})
        this.handleError(err)
      });
  };

  onSubmitVerification = () => {
    var verification = document.getElementById("verification").value;
    var newpassword = document.getElementById("newpassword").value;
    this.setState({loading: true})
    Auth.forgotPasswordSubmit(this.state.username, verification, newpassword)
      .then((data) => {
        this.setState({
          loading: false
        }, ()=> {
          this.onPasswordReset()
        })
      })
      .catch((err) => {
        this.setState({
          loading: false
        })
        this.handleError(err)
      });
  };

  onClickSignIn = () => {
    var username = document
      .getElementById("username")
      .value.toLowerCase()
      .trim();
    var password = document.getElementById("password").value;

    this.setState({
      loading: true,
    });

    var opts = {
      username: username,
      password: password,
      validationData: {
        url: window.location.host,
      },
    };
    Auth.signIn(opts)
      .then((data) => {
        if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.props.machine.send("RESET")
          this.setState({
            loading: false,
            username: username,
            tempPassword: password,
          });
          return;
        }

        this.props.machine.send("LOGIN");
        this.props.onLogin();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        this.handleError(err);
      });
  };

  onSubmitPasswordChange = () => {
    var newpassword = document.getElementById("newpassword").value;
    var opts = {
      username: this.state.username,
      password: this.state.tempPassword,
      validationData: {
        url: window.location.host,
      },
    };
    this.setState({loading: true})
    Auth.signIn(opts)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, newpassword)
            .then((user) => {
              opts.password = newpassword;
              Auth.signIn(opts).then((user) => {
                window.location.reload();
              });
            })
            .catch((err) => this.handleError(err));
        }
      })
      .catch((err) => this.handleError(err));
  };

  handleError(err) {
    this.setState({loading: false})
    if (err["message"] == null) toast(err);
    else toast(JSON.stringify(err["message"]).slice(1, -1));
  }
}
