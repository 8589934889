export const LoginMachine = {
    initial: 'SignIn',
    states: {
        SignIn: {
            on: {
                RESOLVE: 'stop',
                FORGOT: 'ForgotPassword',
                RESET: 'ForceResetPassword',
            }
        },
        ForgotPassword: {
            on: {
                RESET: 'ResetPassword',
                CANCEL: 'SignIn'
            }
        },
        ResetPassword: {
            on: {
                CANCEL: 'Transition',
                NEXT: 'Transition'
            }
        },
        Transition: {
            on: {
                NEXT: 'SignIn'
            }
        },
        ForceResetPassword: {
            on: {
                CANCEL: 'Transition',
                NEXT: 'Transition'
            }
        },
        stop: {}
    }
}