/**
 * Returns a maximum matching of a bipartite graph
 * @param {Object} graph
 */
export default (graph) => {
    const distance = [];
    const adjacency = createMatrix(graph);
    const partition = partitionMatrix(adjacency);
    const matches = defaultMatch(partition);
  
    function breadthFirstSearch() {
      const queue = [];
  
      for (const u in partition.U) {
        if (partition.U.hasOwnProperty(u)) {
          if (matches.U[u] === DUMMY_VERTEX) {
            distance[u] = 0;
            queue.push(u);
          } else distance[u] = Infinity;
        }
      }
  
      distance[DUMMY_VERTEX] = Infinity;
  
      while (queue.length > 0) {
        const u = queue.shift();
        if (distance[u] < distance[DUMMY_VERTEX]) {
          for (const v in adjacency[u]) {
            if (adjacency[u].hasOwnProperty(v)) {
              if (distance[matches.V[v]] === Infinity) {
                distance[matches.V[v]] = distance[u] + 1;
                queue.push(matches.V[v]);
              }
            }
          }
        }
      }
  
      return distance[DUMMY_VERTEX] !== Infinity;
    }
  
    function depthFirstSearch(u) {
      if (u !== DUMMY_VERTEX) {
        for (const v in adjacency[u]) {
          if (adjacency[u].hasOwnProperty(v)) {
            if (distance[matches.V[v]] === distance[u] + 1) {
              if (depthFirstSearch(matches.V[v])) {
                matches.V[v] = u;
                matches.U[u] = v;
                return true;
              }
            }
          }
        }
  
        distance[u] = Infinity;
        return false;
      }
  
      return true;
    }
  
    while (breadthFirstSearch()) {
      for (const u in partition.U) {
        if (partition.U.hasOwnProperty(u)) {
          if (matches.U[u] === DUMMY_VERTEX) {
            depthFirstSearch(u);
          }
        }
      }
    }
  
    return matches.U;
  }
  
  const DUMMY_VERTEX = null;
  
  /**
   * returns an object witch has identical values as its keys
   * @param  {Array} array
   * @return {Object}
   */
  function reflect(array) {
    return array.reduce(
        (b, v) => Object.assign(b, { [v]: v }), {}
    );
  }
  
  /**
   * Initialzes each of the potential matches with the dummy vertex
   * @param  {Object} partition
   * @return {Object}
   */
  function defaultMatch(partition) {
    return {
      U: Object.keys(partition.U)
        .reduce((a, v) => Object.assign(a, { [v]: DUMMY_VERTEX }), {}),
      V: Object.keys(partition.V)
        .reduce((a, v) => Object.assign(a, { [v]: DUMMY_VERTEX }), {}),
    };
  }
  
  /**
   * creates an adjacency matrix from the supplied graph
   * @param  {Object} graph
   * @return {Object}
   */
  function createMatrix(graph) {
    return Object.keys(graph).reduce(
        (a, key) => Object.assign(a, {
          [key]: reflect(graph[key]),
        }), {}
    );
  }
  
  /**
   * returns the two disjoint sets of vertices from the adjacency matrix
   * @param  {Object} matrix
   * @return {Object}
   */
  function partitionMatrix(matrix) {
    return {
      U: reflect(Object.keys(matrix)),
      V: reflect(Object.keys(matrix).reduce(
          (a, k) => a.concat(Object.keys(matrix[k])), [])
          .filter((v, i, array) => array.indexOf(v) === i)),
    };
  }
  