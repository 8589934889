import NetworkManager from './NetworkManager';
import { Endpoint } from '../util/Constants';

export default class ProviderManager {

    /**
     * 
     * @param {String} providerId 
     * @param {(data) => {}} onResult 
     */
    static getProvider(providerId, onResult) {
        let path = Endpoint.base + '/providers'

        let params = {
            id: providerId
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} facilityId 
     * @param {String} serviceId 
     * @param {Boolean} schedulable null to return schedulable and non-schedulable providers
     * @param {(data => {}} onResult 
     */
    static listProviders(facilityId, serviceId, schedulable, disabled, onResult) {
        let path = Endpoint.base + '/providers/list'

        let params = {
            facility: facilityId,
            service: serviceId
        }

        if(disabled != null){
            params.disabled = disabled
        }
        if(schedulable != null){
            params.schedulable = schedulable
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} firstName 
     * @param {String} lastName 
     * @param {String} email 
     * @param {String} title 
     * @param {Array<String>} services 
     * @param {(data) => {}} onResult 
     */
    static createProvider(firstName, lastName, email, title, services, onResult) {
        let params = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            title: title,
            services: services
        }

        new ProviderManager()._createProvider(params, onResult);
    }

    /**
     * 
     * @param {String} providerId 
     * @param {String} serviceId 
     * @param {(data) => {}} onResult 
     */
    static addService(providerId, serviceId, onResult) {
        let path = Endpoint.base + '/providers/add-service'

        let params = {
            providerId: providerId,
            serviceId: serviceId
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} providerId 
     * @param {String} serviceId 
     * @param {(data) => {}} onResult 
     */
    static removeService(providerId, serviceId, onResult) {
        let path = Endpoint.base + '/providers/remove-service'

        let params = {
            providerId: providerId,
            serviceId: serviceId
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * Check if a provider is disabled. This isn't the same as if they are unavailable
     * @see isProviderAvailable
     * @param {*} provider 
     * @returns true only if the user is disabled
     */
    static isProviderDisabled(provider){
        if(!provider) return true //null data. Just treat is as disabled user.
        return provider.role === 'unused' || 
            provider.role === 'disabled' || //legacy role name
            provider.disabled//legacy disabled flag
    }

    /**
     * Check if a provider is unavailable OR disabled
     * @param {*} provider Provider to check
     * @returns true if the user is unavailable OR disabled
     */
    static isProviderUnavailable(provider){
        if(!provider) return true //null data. Just treat is as disabled user.
        return this.isProviderDisabled(provider) ||
            provider.available === false //have to explicitly check for false since undefined or null is viewed as true
    }

    /**
     * Methods exposed for testing, so we can provide the id instead of generating random ones
     */

    _createProvider(params, onResult) {
        NetworkManager.post(Endpoint.base + '/providers', params, (result) => {
            onResult(result)
        })
    }
}