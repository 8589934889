import React from 'react';
import Colors from './Colors'
import CalendarUtil from './CalendarUtil'
export default class DateAndTime extends React.Component{

    constructor(){
      super()
      this.state = {
        ticks: 0
      }
    }
  
    tick(){
      this.setState({
        ticks: this.state.ticks + 1
      })
    }
  
    componentDidMount(){
      this.interval = setInterval(()=>this.tick(),1000)
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }
  
    render(){
  
      const now = new Date()
  
      const anchor = this.props.anchor
  
  
      var display = CalendarUtil.getDayOfWeekString(anchor.getDay()) + " " + anchor.getDate() + ", " + CalendarUtil.getMonthString(anchor.getMonth())
  
      if(now.getDate() === anchor.getDate() && !this.props.printMode && !this.props.dateOnly) display += " " +  CalendarUtil.getCurrentTimeString()
  
      var style = {color: this.props.printMode ? "#000000" : Colors.Primary.Dark}
      if(this.props.disabled){
        style.color = "#808080"
      }
      if(this.props.fontSize) style.fontSize = this.props.fontSize
      if(this.props.fontWeight) style.fontWeight = this.props.fontWeight
      return (
        <div style={style}>
        {display}
        </div>
      )}
  }