import React from 'react';
import {Button} from 'react-bootstrap'
import Colors from './Colors'

export default class ActionButtons extends React.Component{
    render(){
        var nextText = this.props.nextText ?? "Next"
        var cancelText = this.props.cancelText ?? "Cancel"
        var nextEnabled = this.props.nextEnabled ?? true
        return (
            <div style={{position:"fixed", bottom:"80px", right:"80px"}}>
                <Button variant="link" onClick={this.props.onCancel} style={{color:Colors.Primary.Main}}>
                    {cancelText}
                </Button>
                <Button disabled={!nextEnabled} variant="primary" onClick={this.props.onNext} style={{width:"80px", backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>
                    {nextText}
                </Button>        
            </div>
        )
    }
  }