import React from 'react';
import {Card, Button} from 'react-bootstrap'
import Colors from '../../util/Colors'
import Tracker from '../../managers/Tracker'
import ActionButtons from '../../util/ActionButtons';

export default class SelectOccupancyPage extends React.Component{

    constructor(props) {
      super(props)
      Tracker.logScreenView('schedule_appointment_select_room')
      this.state = {
        list: {}
      }
      this.enableMultiSelect = this.props.groupAppointment
      this.onOccupancyClicked=this.onOccupancyClicked.bind(this);
      this.onSubmit=this.onSubmit.bind(this);
      this.cancel=this.cancel.bind(this);
    }

    canContinue(){
      return Object.values(this.state.list).length > 0
    }

    onOccupancyClicked(occupancy){
      if(!this.enableMultiSelect){ //we are editing. Skip multi-select and just submit right away
        this.onSubmit(null, occupancy)
        return
      }
      const list = this.state.list;
      if(list[occupancy.identifier]){
        delete(list[occupancy.identifier])
      }
      else{
        list[occupancy.identifier] = occupancy
      }
      this.setState({list: list})
    }

    onSubmit(e, singleOccupancy){

      this.props.machine.send("NEXT")

        if(singleOccupancy !== undefined){
            this.props.onSelectOccupancy(singleOccupancy) //v1
        }
        else{
            const occupancies = Object.values(this.state.list)
            this.props.onSelectOccupancy(occupancies) //group appointment
        }
    }

    cancel(){
      this.setState({list: []})
    }

    renderOccupancyList() {
      //In case we don't get passed occupancies (provider only), lets go ahead and pass an empty occupancy
      if(!Array.isArray(this.props.occupancies)){
        const occupancy = { //pass in dummy data
          facilityId: this.props.facility.id
        }
        this.onOccupancyClicked(occupancy)
        return;
      }
      return this.props.occupancies.map((occupancy, index) => {
        return (
          <OccupancyButton 
            occupancy={occupancy}
            machine={this.props.machine}
            onSelectOccupancy={this.onOccupancyClicked}
            checked={this.state.list[occupancy.identifier] === occupancy}
          />
        )
      })
   }

   getSubmitStyle(){
    return{
      position:'fixed', 
      top:100, 
      right:60
    }
  }
  
  
  render(){
      if(this.props.editing) return null

      const roomListLabel = "List of rooms for " + this.props.serviceName + " services"
      
      const multiSelectButtons = this.enableMultiSelect ? (
        <ActionButtons nextEnabled={this.canContinue()} onNext={this.onSubmit} onCancel={this.cancel} cancelText="Reset"/>
      ) : null

      return(
        <div align="center" style={{marginTop:"30px"}}>
          {roomListLabel}
          {this.renderOccupancyList()}
          {multiSelectButtons}
        </div>
      )
    }
  }
  
  class OccupancyButton extends React.Component{
  
    constructor(){
      super()
  
      this.state = {
        hovering: false
      }
    }
  
    onClick = () => {
      this.props.onSelectOccupancy(this.props.occupancy)
    }

    getColor(){
      if(this.props.checked){
        return this.state.hovering ? Colors.Primary.Main : Colors.Primary.Dark
      }
      else if(this.state.hovering){
        return Colors.Highlight
      }
      else{
        return Colors.NavGray 
      }
    }
  
    onMouseOver = () => {
      this.setState({
        hovering: true
      })
    }
  
    onMouseLeave = () => {
      this.setState({
        hovering: false
      }) 
     }

     getStyle(){
      return{
        width:"300px",
        marginLeft:"auto",
        marginRight:"auto",
        backgroundColor:this.getColor()
      }
    }
  
  
    render(){
      return(
        <div class="container">
        <div class="row">
          <div class="col text-center">
            <Card style={this.getStyle()}>
              <a style={{ cursor: "pointer"}} onClick={this.onClick} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                <Card.Body>
                  <Card.Text variant="primary" style={{fontSize:"12px", textAlign:"center"}}><b>{this.props.occupancy.name} - {this.props.occupancy.identifier}</b></Card.Text>
                </Card.Body>
              </a>
            </Card>
          </div>
       </div>
      </div>
      )
    }
  }
