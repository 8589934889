import React from 'react'
import { Button } from 'react-bootstrap';
import LoadingSpinner from '../../../util/LoadingSpinner';
import './roleButtonList.css'

export default class RoleEditAccessList extends React.Component {
    render() {
        return ( 
            <div className='role-list-container'>
                {this.props.items.map((item)=>{
                    return <Button id={item.id} variant='light' className='role-list-button' onClick={()=>{this.props.onItemClicked(item)}}>{item.title}</Button>
                })}
            </div>
        );
    }
}