import React from 'react';
import { Auth } from 'aws-amplify';
import { Card, Form } from 'react-bootstrap';
import Tracker from '../../managers/Tracker';
import {Button} from 'react-bootstrap'
import LoadingSpinner from '../../util/LoadingSpinner'
import FacilityManager from '../../managers/FacilityManager';
import Colors from '../../util/Colors';
import ConfigManager from '../../managers/ConfigManager';
import NetworkManager from '../../managers/NetworkManager';
import { Endpoint } from '../../util/Constants';
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager';

export default class CreateFacilityPage extends React.Component{
    constructor(props) {
        super(props)
        
        this.state = {
            loading: false,
            facilityId: null,
            facility: {
                subdomain: "",
                name: "",
                address: "",
                city: "",
                state: "",
                zip: "",
                phone: "",
                timezone: "America/Chicago", //timezone, case sensitive
                version: 6
            },
            domain: {
                name: "",
                id: ""
            },
            services: [
                'sipme9', //meals service
                'ke0ctt' //activities service
            ],
            rooms: []
        }

        document.body.style = 'background:' + Colors.Secondary.Light + ';';

        Tracker.logScreenView("create_facility_panel")
        this.getRoomFields = this.getRoomFields.bind(this)
        this.addRoomField = this.addRoomField.bind(this)
        this.getFacilityFields = this.getFacilityFields.bind(this)
        this.createFacility = this.createFacility.bind(this)
        this.createDomainLink = this.createDomainLink.bind(this)
    }

    getRoomFields(){
        return this.state.rooms.map((room, index) => {
            let formName = `Room${index}`
            return <Form.Group className="mb-2">
                        <Form.Label>Room Name</Form.Label>
                        <Form.Control defaultValue={this.state.rooms[index]} type="room" placeholder="" id={formName} onChange={ (e) => {
                            this.state.rooms[index] = document.getElementById(formName).value
                            this.setState({rooms: this.state.rooms})
                        }}/>
                    </Form.Group>
        })
    }

    addRoomField(){
        this.state.rooms.push("")
        this.setState({rooms: this.state.rooms})
    }

    getFacilityFields(){
        return [
            <Form.Group className="mb-2">
                <Form.Label>Facility Name</Form.Label>
                <Form.Control defaultValue={this.state.facility.name} type="name" placeholder="" id="facilityName" onChange={ () => {
                    this.state.facility.name = document.getElementById("facilityName").value
                    this.setState({facility: this.state.facility})
                }}/>
            </Form.Group>,
            <Form.Group className="mb-2">
                <Form.Label>Address</Form.Label>
                <Form.Control defaultValue={this.state.facility.address} type="name" placeholder="" id="facilityAddress" onChange={ () => {
                    this.state.facility.address = document.getElementById("facilityAddress").value
                    this.setState({facility: this.state.facility})
                }}/>
            </Form.Group>,
            <Form.Group className="mb-2">
                <Form.Label>City</Form.Label>
                <Form.Control defaultValue={this.state.facility.city} type="name" placeholder="" id="facilityCity" onChange={ () => {
                    this.state.facility.city = document.getElementById("facilityCity").value
                    this.setState({facility: this.state.facility})
                }}/>
            </Form.Group>,
            <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control defaultValue={this.state.facility.state} type="name" placeholder="" id="facilityState" onChange={ () => {
                    this.state.facility.state = document.getElementById("facilityState").value
                    this.setState({facility: this.state.facility})
                }}/>
            </Form.Group>,
            <Form.Group className="mb-2">
                <Form.Label>Zip</Form.Label>
                <Form.Control defaultValue={this.state.facility.zip} type="name" placeholder="" id="facilityZip" onChange={ () => {
                    this.state.facility.zip = document.getElementById("facilityZip").value
                    this.setState({facility: this.state.facility})
                }}/>
            </Form.Group>,
            <Form.Group className="mb-2">
                <Form.Label>Phone</Form.Label>
                <Form.Control defaultValue={this.state.facility.phone} type="name" placeholder="" id="facilityPhone" onChange={ () => {
                    this.state.facility.phone = document.getElementById("facilityPhone").value
                    this.setState({facility: this.state.facility})
                }}/>
            </Form.Group>,
            <Form.Group className="mb-2">
                <Form.Label>Time Zone</Form.Label>
                <Form.Control defaultValue={this.state.facility.timezone} type="name" placeholder="" id="facilityTimezone" onChange={ () => {
                    this.state.facility.timezone = document.getElementById("facilityTimezone").value
                    this.setState({facility: this.state.facility})
                }}/>
            </Form.Group>,
            <Form.Group className="mb-2">
            <Form.Label>Subdomain</Form.Label>
            <Form.Control defaultValue={this.state.facility.subdomain} type="name" placeholder="" id="facilitySubdomain" onChange={ () => {
                this.state.facility.subdomain = document.getElementById("facilitySubdomain").value
                this.setState({facility: this.state.facility})
            }}/>
            </Form.Group>
        ]
    }

    async createFacility(){
        this.setState({loading: true})
        var facility = this.state.facility
        var rooms = this.state.rooms
        var services = this.state.services
        var user = await Auth.currentAuthenticatedUser()
        var facilityResponse = await this.createFacilityApi(facility)
        if(facilityResponse.statusCode === 200){
            console.log(facilityResponse)
            await this.addUserAsProvider(facilityResponse.id, user.username)
            await this.addUserAsProvider(facilityResponse.id, "fd52bdd0-dbff-4eb6-45fe-162aab9acebe") //offsite provider
            
            //now update the default configs
            await this.createConfig(facilityResponse.id)
            //update rooms if we have any
            await this.createRooms(facilityResponse.id, rooms)
            await this.addServices(facilityResponse.id, services)
            await this.createMeals(facilityResponse.id, this.state.facility.timezone)
            await this.createSubdomain(this.state.facility.subdomain, facilityResponse.id) //subdomain, facilityId
        }
        else{
            console.error(`Failed to publish facility: ${JSON.stringify(facilityResponse)}`)
        }
        this.setState({loading: false})
    }

    createDomainLink = async() => {
        console.log("createDomainLink")
        this.setState({loading: true})
        await this.createSubdomain(this.state.domain.name, this.state.domain.id)
        this.setState({loading: false})
    }

    async createConfig(facilityId){
        const configs = {
            'admin': [
                'print-patient-schedule', 'manage-patients',
                'schedule-appointment',
                'user-schedule',
                'patient-evaluation',
                'facility-schedule', 'provider-schedule', 'zero-minute-schedule',
                'manage-providers', 'manage-services', 'manage-facility', 'billing'
            ],
            'provider': [
                'schedule-appointment',
                'user-schedule',
                'patient-evaluation',
                'facility-schedule', 'provider-schedule', 'zero-minute-schedule'
            ],
            'read_only': [
                'facility-schedule', 'provider-schedule', 'zero-minute-schedule'
            ],
            'guest': [
                'none'
            ]
        }

        var keys = Object.keys(configs)
        var values = Object.values(configs)
        for(var i = 0; i < keys.length; i++){
            var role = keys[i]
            var configList = values[i]
            var result = await this.updateConfigApi(facilityId, role,"home", configList)
            if(result.code){
                console.error(`Error creating ${role} config ${JSON.stringify(result)}`)
            }
        }
    }

    createSubdomain = async(subdomain, facilityId) => {
        console.log("createSubdomain")
        let path = Endpoint.base + '/facilities/lookup';
        let params = {
            facilityId: facilityId,
            name: subdomain
        }
        return new Promise(function(resolve, reject) {
            NetworkManager.post(path, params, function(response) {
                console.log(response)
                resolve(response)
            })
        })
    }

    async addUserAsProvider(facilityId, providerId){
        return new Promise(function(resolve, reject) {
            FacilityManager.addProvider(facilityId, "admin", null, providerId, function(response) {
                resolve(response)
            })
        })
    }

    async createRooms(facilityId, rooms){
        if(rooms.length === 0){
            return
        }
        for(var i = 0; i < rooms.length; i++){
            let roomName = rooms[i]
            let result = await this.createRoomApi(facilityId, roomName)
            if(result.code)
                console.error(`Error creating room ${JSON.stringify(result)}`)
        }
    }

    async createMeals(facilityId, timezone) {
        var breakfastJSON = {"friday":{"end":32400000,"start":28800000},"monday":{"end":32400000,"start":28800000},"saturday":{"end":32400000,"start":28800000},"sunday":{"end":32400000,"start":28800000},"thursday":{"end":32400000,"start":28800000},"tuesday":{"end":32400000,"start":28800000},"wednesday":{"end":32400000,"start":28800000}}
        var lunchJSON = {"friday":{"end":46800000,"start":43200000},"monday":{"end":46800000,"start":43200000},"saturday":{"end":46800000,"start":43200000},"sunday":{"end":46800000,"start":43200000},"thursday":{"end":46800000,"start":43200000},"tuesday":{"end":46800000,"start":43200000},"wednesday":{"end":46800000,"start":43200000}}
        var supperJSON = {"friday":{"end":68400000,"start":64800000},"monday":{"end":68400000,"start":64800000},"saturday":{"end":68400000,"start":64800000},"sunday":{"end":68400000,"start":64800000},"thursday":{"end":68400000,"start":64800000},"tuesday":{"end":68400000,"start":64800000},"wednesday":{"end":68400000,"start":64800000}}
        await this.createRestrictionApi(facilityId, breakfastJSON, timezone, "Breakfast")
        await this.createRestrictionApi(facilityId, lunchJSON, timezone, "Lunch")
        await this.createRestrictionApi(facilityId, supperJSON, timezone, "Supper")
    }

    async addServices(facilityId, services){
        if(services.length === 0){
            return
        }
        for(var i = 0; i < services.length; i++){
            let service = services[i]
            let result = await this.addServiceApi(facilityId, service)
            if(result.code)
                console.error(`Error adding service ${JSON.stringify(result)}`)
        }
    }

    async createRestrictionApi(facilityId, schedule, timeZone, title){
        var params = LegacyAppointmentManager.getNewTimeRestrictionsModel(facilityId, schedule, timeZone, title)
        return new Promise(function(resolve, _) {
            LegacyAppointmentManager.postTimeRestrictions(params, (result)=>{
                resolve(result)
            })
        })
    }

    createFacilityApi = async(facility) => {
        return new Promise(function(resolve, reject) {
            FacilityManager.createFacility(facility.name, facility.phone, facility.address, facility.city, facility.state, facility.zip, function(response) {
                resolve(response)
            }, true /*dont cache, as this is not our facility*/)
        })
    }

    updateConfigApi = async(facilityId, role, target, config) => {
        return new Promise(function(resolve, reject) {
            ConfigManager.updateTargetedFacilityConfig(facilityId, role, target, config, function(response) {
                resolve(response)
            }, true /*dont cache, as this is not our facility*/)
        })
    }

    createRoomApi = async(facilityId, roomName) => {
        return new Promise(function(resolve, reject) {
            FacilityManager.createRoom(facilityId, roomName, function(response) {
                resolve(response)
            }, true /*dont cache, as this is not our facility*/)
        })
    }

    addServiceApi = async(facilityId, serviceId) => {
        return new Promise(function(resolve, reject) {
            FacilityManager.addService(facilityId, serviceId, function(response) {
                resolve(response)
            }, true /*dont cache, as this is not our facility*/)
        })
    }

    render(){
        return this.state.loading ? <LoadingSpinner/> : (
            <>
                <Card>
                    <Card.Header>
                        Add facility info
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            {this.getFacilityFields()}
                        </Form>
                    </Card.Body>
                </Card>,
                <Card>
                    <Card.Header>
                        Add rooms
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            {this.getRoomFields()}
                        </Form>
                        <Button onClick={this.addRoomField}>+</Button>
                    </Card.Body>
                </Card>,
                <Button variant="primary" type="button" onClick={() => this.createFacility()}>Create Facility</Button>
                <div style={{height: 100}}></div>,
                <Card>
                    <Card.Header>
                        Change domain link
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group className="mb-2">
                                <Form.Label>Facility Id</Form.Label>
                                <Form.Control defaultValue={this.state.domain.id} type="name" placeholder="" id="domainId" onChange={ () => {
                                    this.state.domain.id = document.getElementById("domainId").value
                                    this.setState({domain: this.state.domain})
                                }}/>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>Subdomain</Form.Label>
                                <Form.Control defaultValue={this.state.domain.name} type="name" placeholder="" id="domainName" onChange={ () => {
                                    this.state.domain.name = document.getElementById("domainName").value
                                    this.setState({domain: this.state.domain})
                                }}/>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>,
                <Button variant="primary" type="button" onClick={() => this.createDomainLink()}>Create link</Button>
            </>
        )
    }
}

/*

*/