import React from 'react'
import './accessOption.css'
import AgendaIcon from '../../../img/Icons/NewAgenda.png'
import TeamDirectoryIcon from '../../../img/Icons/ProviderNew.png'
import FacilityIcon from '../../../img/Icons/facility.png'
import ScheduleIcon from '../../../img/Icons/schedule.png'
import MapsIcon from '../../../img/Icons/Maps.png'
import EvaluationIcon from '../../../img/Icons/evaluation.png'
import GroupAgendaIcon from '../../../img/Icons/group-appt.svg'

export default class AccessOption extends React.Component {

    state={toggle: false}

    componentDidMount(){
        const img = new Image();
        img.src = this.getImage()

        img.onload = () => {
            this.setState({

            })
        }
    }

    render(){
        const label = this.props.title;
        const src = this.getImage();

        var img = new Image()
        img.src = src
        //TODO pull remaining code to handle refreshing state when image loads

        var state = this.props.selected ? '-selected' : ''
        var imageScale = this.props.imageScale ?? 1 
        return(
        <a style={{ cursor: "pointer" }} onClick={this.onClick}>
        <div className={`option-container${state}`}>
            <div align="center">
                <img src={src} className='option-image' style={{height:img.height * imageScale, width:img.width * imageScale}}/>
            </div>
            <div className={`option-title${state}`}>{label}</div>
        </div>
        </a>
        );
    }

    onClick = ()=> {
        this.props.onClick()
    }

    getImage = () => {
        switch(this.props.icon){
            case 'agenda':
                return AgendaIcon
            case 'provider':
                return TeamDirectoryIcon
            case 'schedule':
                return ScheduleIcon
            case 'facility':
                return FacilityIcon
            case 'maps':
                return MapsIcon
            case 'evaluation':
                return EvaluationIcon
            case 'group-agenda':
                return GroupAgendaIcon
            default:
                return this.props.icon //might not be a name at all. Could actually be an image
        }
    }
}