export default class Queries{
    static getAppointmentQueryParams(dateRange : DateRange|null, facilityId: string|undefined=undefined, ownerId : string, serviceId:string|undefined=undefined, urlCode:string|undefined=undefined, showBlockedTimes:boolean=true, showStashed:boolean|null|undefined=false) : AppointmentQuery{
        let params = {
            owner: ownerId,
            facility: facilityId,
            service: serviceId,
            urlCode: urlCode,
            stashed: showStashed,
            showBlockedTimes: showBlockedTimes,
            end: undefined,
            start: undefined,
        } as AppointmentQuery     
        if (dateRange) {
            params.start = dateRange.start
            params.end = dateRange.end
        }
        return params
    }

    static getCompareAppointmentParams(dateRange: DateRange|null, facilityId: string|undefined=undefined, owners: string[], sendAppointmentData: boolean, showBlockedTimes: boolean): CompareAppointmentQuery {
        let params = {
            owners: owners,
            facility: facilityId,
            showBlockedTimes: showBlockedTimes,
            end: undefined,
            appointmentData: sendAppointmentData,
            start: undefined,
        } as CompareAppointmentQuery
        if (dateRange) {
            params.start = dateRange.start
            params.end = dateRange.end
        }
        return params
    }
}

export class DateRange{
    start : number = 0;
    end : number = 0;
}

/**
 * @param {number} start filter by appointment start time. Will return all appointments that begin within the given date range. start or end can be null if desired
 * @param {number} end filter by appointment start time. Will return all appointments that begin within the given date range. start or end can be null if desired
 * @param {String} owner Query by a provider, room, occupancy, location. Can be null if facilityId is provided
 * @param {String} facility providers can belong to multiple facilities, provide this along with an ownerId to see only appointments at that facility. If ownerId is null, this will return all appointments at a facility
 * @param {String} service filter to only show a specific service. Can be null
 * @param {String} urlCode url code for patient. Can be null
 * @param {String} stashed whether or not to also return stashed appointments. True to return only stashed. False to filter out stashed (default). Null to ignore this filter
 * @param {String} showBlockedTimes whether or not to also return blocked times
 */
export interface AppointmentQuery{
    start?: number|undefined;
    end?: number|undefined;
    owner? : string;
    facility? : string;
    service?: string;
    urlCode?: string;
    stashed?: boolean;
    showBlockedTimes?: boolean;
}

/**
 * @param {number} start compare appointments starting at this time
 * @param {number} end compare appointments starting at this time
 * @param {String[]} owners check for appointments for a provider, room, occupancy, location, in the given time range
 * @param {String} facility providers can belong to multiple facilities, provide this along with an ownerId to see only appointments at that facility. If ownerId is null, this will return all appointments at a facility
 * @param {String} showBlockedTimes whether or not to also return blocked times
 */
export interface CompareAppointmentQuery{
    start?: number|undefined;
    end?: number|undefined;
    owners?: string[],
    facility?: string,
    showBlockedTimes?: boolean,
    appointmentData?: boolean
}