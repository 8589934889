export default class Colors {

    static NavGray = "#f2f2f2"
    static Highlight = "#00df71"
    static DarkGray = "#b3b3b3"
    static DarkGrayBorder = "#8e8e8e"

    static Red = "#f26868"
    static Green = "#5bd897"
    static GrayGreen = "#87C6A5"
    static Black = "#000000"

    static MiddleGray = "#808080"
    static DropShadow = "#00000028"
    static Steel = "#E2DFDF"
    static Gray201 = "#CAC9C9" //202,201,201

    static Primary = {
        Main: "#00c4d8",
        Light: "#64f7ff",
        Dark: "#0093a7"
    }

    static Secondary = {
        Main: "#e0e0e0",
        Light: "#ffffff",
        Dark: "#aeaeae"
    }
}