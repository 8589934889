import CalendarUtil from './CalendarUtil'
import { array } from 'prop-types';

export default class Helpers{
    static capitalize(text){
        var str = text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
        return str
    }

    static getBlobTextStyle(blobStyle){
        return {
            whiteSpace: 'pre-line',
            pointerEvents: "none",
            position: "absolute", 
            left: blobStyle.left, 
            top: blobStyle.top, 
            color: "white", 
            width: blobStyle.width - 5, 
            height: blobStyle.height - 5,
            zIndex: blobStyle.zIndex,
            marginLeft: 8,
            marginRight: 8, 
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 14
          }
    }

    static sortProviders(providerList, copy){
        var sortedList = copy ? [...providerList] : providerList
        sortedList.sort((a, b) => {
            var aName = Helpers.getProviderFullName(a).toLowerCase()
            var bName = Helpers.getProviderFullName(b).toLowerCase()
            return aName > bName ? 1 : -1
        });
        return sortedList
    }

    static sortServices(servicesList, copy, showDisabled=true, sortDisabledAtBottom=false, filterSchedulable=true){
        var sortedList = copy || !showDisabled ? [...servicesList] : servicesList
        sortedList.sort((a, b) => {
            var disabledA = a.disabled == true //could be undefined or null
            var disabledB = b.disabled == true //could be undefined or null
            var aName = a.name.toLowerCase()
            var bName = b.name.toLowerCase()
            if(disabledA == disabledB || !sortDisabledAtBottom)
                return aName > bName ? 1 : -1
            else
                return disabledA-disabledB
        });
        sortedList = sortedList.filter((service) => {
            if(!showDisabled && service.disabled) return false
            //allow this item if we are not filtering, or if it is schedulable
            if(filterSchedulable) return service.schedulable
            return true
        })
        return sortedList
    }

    /**
     *
     * @param roomsList {any}
     * @param [copy] {boolean}
     * @param [showDisabled] {boolean}
     * @returns {*[]|*}
     */
    static sortRooms(roomsList, copy=false, showDisabled=true){
        const sortedList = copy || !showDisabled ? [...roomsList] : roomsList;
        sortedList.sort((a, b) => {
            const disabledA = a.disabled === true; //could be undefined or null
            const disabledB = b.disabled === true; //could be undefined or null
            if(disabledA === disabledB)
                return a.name.localeCompare(b.name, 'en', { numeric: true })
            else
                return disabledA-disabledB
        });
        if(!showDisabled){
            for(let i = sortedList.length-1; i >= 0; i--){ //iterate backwards so we can splice as we go if needed
                if(sortedList[i].disabled === true) sortedList.splice(i, 1) //remove if disabled
            }
        }
        return sortedList
    }

    static sortLocations(locationsList, copy, showDisabled=true){
        if(!Array.isArray(locationsList)){
            throw new Error("Locations object is not an array")
        }
        var sortedList = copy || !showDisabled ? [...locationsList] : locationsList
        sortedList.sort((a, b)=>{
            var disabledA = a.disabled == true //could be undefined or null
            var disabledB = b.disabled == true //could be undefined or null
            if(disabledA == disabledB)
                return (a.name > b.name) ? 1 : -1
            else
                return disabledA-disabledB
        })
        if(!showDisabled){
            for(var i = sortedList.length-1; i >= 0; i--){ //iterate backwards so we can splice as we go if needed
                if(sortedList[i].disabled == true) sortedList.splice(i, 1) //remove if disabled
            }
        }
        return sortedList
    }

    static resolveRoles(rolesList){
        var newList = rolesList.map((role)=>{
            return {
                id: Helpers.getRoleNameFromId(role),
                name: role
            }
        })
        return newList
    }

    static getRoleNameFromId(role){
        if(role === 'unused')
            return "Disabled"
        return Helpers.capitalize(role.replace('_', ' '))
    }

    static convertRoleNameToId(role){
        if(role === "Disabled") return 'unused'
        return role.toLowerCase().replace(' ', '_')
    }

    static sortObjectByTimes(times){
        return times.sort((a, b) => (a.start > b.start) ? 1 : -1)
    }

    static getProviderNameFromId(providerId, facility){
        if(facility.providers && facility.providers[providerId]){
            return this.getProviderFullName(facility.providers[providerId])
        }
        return null
    }
    
    static getProviderFullName(provider){
        return `${provider.firstName} ${provider.lastName}`
    }

    static getRoomName(room){
        let roomName = room.name
        if(!roomName) return 'Room unknown'
        roomName = roomName.toLowerCase()
        if(roomName.includes('room '))
            return room.name
        else
            return `Room ${room.name}`
    }

    static getRoomNameFromId(facility, roomId){
        for (const room of facility.rooms) {
            if(room.id === roomId){
                return this.getRoomName(room);
            }
        }
        return null
    }

    static getRoomNumber(room){
        if(room.name.toLowerCase().includes('room'))
            return room.name.toLowerCase().replace('room ', '')
        else
            return `${room.name}`
    }
    /* Sort any array of strings to a case insensitve alphabetical order */
    static sort(data){
        data.sort((a,b)=> {
            var aNoCase = a.toLowerCase()
            var bNoCase = b.toLowerCase()
            return (aNoCase > bNoCase) ? 1 : (aNoCase < bNoCase) ? -1 : 0
        })
    }

    static fixMultiSelects(){
        Array.from(document.getElementsByClassName('multi-select')).forEach((el) => {
            el.getElementsByClassName('dropdown')[0].removeAttribute('tabindex');
        });
    }

    /**
     * Find any elements in `second` that are in `first`, and return a new array with only those elements
     * @param {*} first First array
     * @param {*} second Second array
     */
    static findMatching = (first, second) => {
        var output = []
        second.forEach(element => {
            if(first.indexOf(element) > -1)
                output.push(element)
        });
        return output
    }

    static hasSomeMatching = (first, second)=>{
        if(!second || !first) return false
        for(var i = 0; i < second.length; i++){
            if(first.indexOf(second[i]) > -1)
                return true
        }
        return false
    }

    /**
     * Find any elements in `second` that are NOT in `first`, and return a new array with only those elements
     * @param {*} first First array
     * @param {*} second Second array
     */
    static findNotMatching = (first, second) => {
        var output = []
        second.forEach(element => {
            if(first.indexOf(element) < 0)
                output.push(element)
        });
        return output
    }

    /**
     * @param service {any}
     * @param appointment {AppointmentModel}
     * @returns {boolean|false|boolean|*}
     */
    static shouldShowSingleOccupantAsLocation = (appointment, service) => {
        if(!appointment.occupants || appointment.occupants.length === 0 || appointment.occupants.length > 1) return false
        if(!appointment.locations || appointment.locations.length > 1) return false
        let occupant = appointment.occupants[0]
        let location = appointment.locations.length > 0 ? appointment.locations[0] : {}
        return location.id === occupant.roomId ||
            (
                !location.id &&
                service.pickLocation &&
                !service.providerOnly
            )
    }

    /**
     * Check if two arrays are pretty much the same. This will internally sort them, but not modify the original sets, and then check to make sure each value matches.
     * Good for simple arrays
     */
    static areArraysTheSame(array1, array2){
        if(!Array.isArray(array1) || !Array.isArray(array2) || array1.length !== array2.length) return false
        var array1Temp = array1.concat().sort()
        var array2Temp = array2.concat().sort()
        for(var i = 0; i < array1Temp.length; i++){
            if(array1Temp[i] !== array2Temp[i])
                return false
        }
        return true
    }

    static getService(serviceId, services){
        let returnedService = null
        services.forEach((service)=> {
            if(service.id === serviceId){
                returnedService = service
            }
        })
        return returnedService
    }

    /**
     * Return an array of times that are not shared by any of the provided times, in the given time range
     * @param {*} blockedTimes array of objects that contain a `start` and `end`
     * @param {*} start start of range
     * @param {*} end end of range
     * @returns array of times, in format {start: ms, end: ms}
     */
    static getOpenTimes(blockedTimes, start, end) {
        var openTimes = []
        var addOpenTimeBlock = (startToPush, endToPush) => {
            openTimes.push({ start: startToPush, end: endToPush })
        }
        var rollingStart = start

        var sortedBockedTimes = Object.assign([], blockedTimes)
        Helpers.sortObjectByTimes(sortedBockedTimes)

        let currentBlock

        sortedBockedTimes.forEach(blocker => {
            //only process times within our range, or if they slightly intersect with our range
            if (blocker.end < start) return
            if (blocker.start > end) return
            //in the case where the first appointment happens to overlap with our range, 
            //but starts before it, the rollingStart now becomes the end of that appointment
            if (blocker.start < rollingStart) {
                rollingStart = blocker.end
                return
            }

            if (!currentBlock) {
                currentBlock = Object.assign({}, blocker)
            }

            else if (currentBlock.end === blocker.start || blocker.start <= currentBlock.end) {
                if (blocker.end <= currentBlock.end) return //continue...
                currentBlock.end = blocker.end
            }

            else {
                if (rollingStart !== currentBlock.start)
                    addOpenTimeBlock(rollingStart, currentBlock.start)
                rollingStart = currentBlock.end
                currentBlock = Object.assign({}, blocker)
            }
        })

        if (currentBlock) {
            addOpenTimeBlock(rollingStart, currentBlock.start)
            rollingStart = currentBlock.end
            currentBlock = null
        }

        //last time to end of time range, if possible
        if (end > rollingStart)
            addOpenTimeBlock(rollingStart, end)

        return openTimes
    }

    static cloneObject(object, legacy=false){
        if(legacy) return JSON.parse(JSON.stringify(object))
        return window.structuredClone(object)
    }
}