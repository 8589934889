import React from 'react'
import RoleButtonList from './Components/RoleButtonList'
import LoadingSpinner from '../../util/LoadingSpinner'
import NavigationHeader from '../../ui/NavigationHeader'
import FacilityManager from '../../managers/FacilityManager'
import Helpers from '../../util/Helpers'

export default class EditRoleList extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            roles: null
        }
        this.getRoles()
    }

    async getRoles(){
        FacilityManager.getRoles(this.props.facility.id, (result)=> {
            this.setState({
                loading: false, 
                roles: result.map((role)=>{
                    return {
                        id: role,
                        title: Helpers.getRoleNameFromId(role),
                        deletable: !['admin', 'provider', 'read_only'].includes(role)
                    }
                })
            })
        })
    }

    render() { 
        if(this.state.loading) return <LoadingSpinner/>
        return [
            <NavigationHeader navigationOptionsTitles={["Create New Role"]} onNewOption={this.onNewOption}/>,
            <RoleButtonList items={this.state.roles} onItemClicked={this.props.onItemClicked}/>
        ]
    }

    onNewOption = (option) => {
        if(option === "Create New Role"){
            this.props.onNewItem({
                id: '',
                title: '',
                deletable: true
            })
        }
    }
}