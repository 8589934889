export const ManageUserRolesMachine = {
    initial: 'ListRoles',
    states: {
        ListRoles: {
            on: {
                CREATE: 'CreateRole',
                EDIT: 'EditRoles',
                CANCEL: 'stop'
            }
        },
        EditRoles: {
            on: {
                DELETE: 'ListRoles',
                CANCEL: 'ListRoles',
                SAVE: 'ListRoles'
            }
        },
        //Same as edit, but needs to be different to handle title differences
        CreateRole: {
            on: {
                DELETE: 'ListRoles',
                CANCEL: 'ListRoles',
                SAVE: 'ListRoles'
            }
        },
        stop: {}
    }
}