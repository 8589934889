export default class Appointment {
    facilityStartDate: string = ""
    creatorId: string = ""
    facilityId: string = ""
    start: number = 0
    end: number = 0
    title?: string|null = ""
    description?: string|null
    notes?: string|null
    createdAt?: number
    updatedAt?: number|null
    serviceId?: string|null
    providers?: Array<string>|null
    id?: string|null
    locationId?:string|null
    occupancyId?: string|null
    occupancyIdentifier?: string|null
    roomId?: string|null
    roomName?: string|null
    stashed?: string|null
    require2fer? : string|null
    incomplete?: string|null
    conflictingProviders?: Array<string>|null
    conflicts?: Array<string>|null
    linkedAppointmentId?: string|null
    linkedAppointmentServiceId?: string|null
    linkedAppointmentIds?: Map<string, string>|null
    recurrence?: Recurrence|null
    configId?: string|null

    constructor(creatorId: string, facilityId: string, serviceId: string, start: number, end: number, providerIds: Array<string> = Array<string>(), locationId?: string | null, roomId?: string | null, occupancyId?: string | null, occupancyIdentifier?: string | null, title?: string | null, description?: string | null, notes?: string | null, roomName?: string | null, recurrenceCount = 0, recurrenceFrequency = 0){
        this.facilityId = facilityId
        this.serviceId = serviceId
        this.providers = providerIds
        this.locationId = locationId
        this.roomId = roomId
        this.occupancyId = occupancyId
        this.occupancyIdentifier = occupancyIdentifier
        this.creatorId = creatorId
        this.start = start
        this.end = end
        this.title = title
        this.description = description
        this.notes = notes
        this.roomName = roomName
        if(recurrenceCount > 1 && recurrenceFrequency > 0){
            this.recurrence = new Recurrence(recurrenceCount, recurrenceFrequency)
        }
    }

    /**
     * Take any object and sanitize it to only contain the fields from above. Currently does not validate type though
     * @param data 
     */
    static convertFromObject(data: any): Appointment {
        var appointment = {} as any
        var appointmentTemplate = new Appointment("","","",0,0)
        Object.keys(appointmentTemplate).forEach((key) => {
            let variable = data[key]
            if (variable) {
                appointment[key] = variable
            }
        })
        return appointment as Appointment
    }
}

export class AppointmentV2 {
    createdAt?: number
    facilityStartDate: string = ""
    start: number = 0
    end: number = 0
    facilityId: string = ""
    creatorId: string = ""
    title?: string|null = ""
    description?: string|null
    notes?: string|null
    updatedAt?: number|null
    serviceId?: string|null
    id?: string|null
    stashed?: boolean|string|null
    require2fer? : boolean|string|null
    incomplete?: boolean|string|null
    conflicts?: Array<string>|null
    configId?: string|null

    constructor(creatorId: string,
                facilityId: string,
                serviceId: string,
                start: number,
                end: number,
                assignees: AppointmentAssignee[],
                title?: string | null,
                description?: string | null,
                notes?: string | null,){
        this.facilityId = facilityId
        this.serviceId = serviceId
        this.creatorId = creatorId
        this.start = start
        this.end = end
        this.title = title
        this.description = description
        this.notes = notes
    }

    /**
     * Take any object and sanitize it to only contain the fields from above. Currently does not validate type though
     * @param data
     */
    static convertFromObject(data: any): AppointmentV2 {
        var appointment = {} as any
        var appointmentTemplate = new Appointment("","","",0,0)
        Object.keys(appointmentTemplate).forEach((key) => {
            let variable = data[key]
            if (variable) {
                appointment[key] = variable
            }
        })
        return appointment as AppointmentV2
    }
}

export type AppointmentAssigneeType = 'provider'|'occupant'|'offsite'|'location'

export class AppointmentAssignee{
    facilityStartDate: string //startTime-appointmentId
    assigneeId: string
    appointmentId: string
    assigneeName?: string = ""
    assigneeType: AppointmentAssigneeType
    facilityId: string
    start: number = 0
    end: number = 0
    allowedToOverlap?: boolean = false //can this item be ignored when running conflict checking?
    owner?: boolean = false //can the appointment exist without this item?

    constructor(facilityStartDate: string, start: number, end: number, facilityId: string, appointmentId: string, assigneeId: string, assigneeType: AppointmentAssigneeType, owner?: boolean, assigneeName?: string, allowedToOverlap?: boolean) {
        this.facilityStartDate = facilityStartDate;
        this.start = start;
        this.end = end;
        this.facilityId = facilityId;
        this.appointmentId = appointmentId;
        this.assigneeId = assigneeId;
        this.assigneeType = assigneeType;
        this.assigneeName = assigneeName;
        this.allowedToOverlap = allowedToOverlap
        this.owner = owner
    }
}

export class OccupantAssignee extends AppointmentAssignee{
    roomId: string
    roomName: string

    constructor(facilityStartDate: string, start: number, end: number, facilityId: string, appointmentId: string, occupancyId: string, roomId: string, roomName: string, owner?: boolean, assigneeName?: string, allowedToOverlap?: boolean) {
        super(facilityStartDate, start, end, facilityId, appointmentId, occupancyId, 'occupant', owner, assigneeName, allowedToOverlap);
        this.roomId = roomId
        this.roomName = roomName
    }
}

export class LinkedAppointment{
    facilityId: string
    parentAppointmentId: string
    parentServiceId: string
    linkedAppointmentId: string
    linkedAppointmentServiceId: string

    constructor(facilityId: string, parentAppointmentId: string, parentServiceId: string, linkedAppointmentId: string, linkedAppointmentServiceId: string) {
        this.facilityId = facilityId;
        this.parentAppointmentId = parentAppointmentId;
        this.parentServiceId = parentServiceId;
        this.linkedAppointmentId = linkedAppointmentId;
        this.linkedAppointmentServiceId = linkedAppointmentServiceId;
    }
}

export class AppointmentV2DBItem extends AppointmentV2{
    ownerId?: string
    _linkedAppointments?: LinkedAppointment[]
    _assignees?: AppointmentAssignee[]
    _recurrence?: Recurrence|null

    static providerIds(appointment: AppointmentV2DBItem): string[] {
        return this.providers(appointment).map(provider => provider.assigneeId) ?? []
    }

    static providers(appointment: AppointmentV2DBItem): AppointmentAssignee[] {
        return appointment._assignees?.filter(assignee => assignee.assigneeType === 'provider') ?? []
    }

    static occupants(appointment: AppointmentV2DBItem): OccupantAssignee[] {
        return (appointment._assignees?.filter(assignee => assignee.assigneeType === 'occupant') ?? []) as OccupantAssignee[]
    }

    static locations(appointment: AppointmentV2DBItem): AppointmentAssignee[] {
        return appointment._assignees?.filter(assignee => assignee.assigneeType === 'location') ?? []
    }
}

const createAppointment = (appointmentOpts: AppointmentV2, appointmentAssignees: AppointmentAssignee[], linkedAppointments: LinkedAppointment[], recurrence: Recurrence) => {
    const ownerIds = appointmentAssignees.map(assignee => assignee.assigneeId)
    const appointmentObjects = ownerIds.map(ownerId => {
        const appt = Object.assign({}, appointmentOpts) as AppointmentV2DBItem
        appt.ownerId = ownerId
        appt._assignees = appointmentAssignees
        appt._linkedAppointments = linkedAppointments
        appt._recurrence = recurrence
    })
}

export class Recurrence{
    count: number = 0
    frequency: number = 0
    _id: string = ''
    constructor(count = 0, frequency = 0){
        this.count = count
        this.frequency = frequency
    }
}