import NetworkManager from './NetworkManager';
import { Endpoint } from '../util/Constants';

export default class ServiceManager {

    /**
     * 
     * @param {String} serviceId 
     * @param {(data) => {}} onResult 
     */
    static getService(serviceId, onResult) {
        let path = Endpoint.base + '/services'

        let params = {
            id: serviceId
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} facilityId 
     * @param {String} providerId (optional)
     * @param {Boolean} includeData (optional)
     * @param {(data) => {}} onResult 
     */
    static listServices(facilityId, providerId, includeData, onResult) {
        let path = Endpoint.base + '/services/list'

        let params = {
            facility: facilityId, 
            providerId: providerId,
            serviceData: includeData
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * Creates a global service type. This does not add the service to a facility.
     * Use FacilityManager.addService(facilityId, serviceId) to add it to the facility after.
     * 
     * @param {String} name 
     * @param {String} description 
     * @param {(data) => {}} onResult 
     */
    static createService(service, onResult) {
        let path = Endpoint.base + '/services'

        NetworkManager.post(path, service, (result) => {
            onResult(result)
        })
    }

}