import React from 'react';
import Colors from '../../util/Colors'
import {Table, Card, Container, Row, Col} from 'react-bootstrap'
import ProviderIcon from '../../img/Icons/no-pic.png'
import ProviderDisabledIcon from '../../img/Icons/ic_user_disabled.png'
import Tracker from '../../managers/Tracker'
import Helpers from '../../util/Helpers';
import NavigationHeader from '../../ui/NavigationHeader';
import SDCheckBox from '../../ui/SDCheckBox';
import SDEditTextBox from '../../ui/SDEditTextBox';
import ProviderManager from '../../managers/ProviderManager';

export default class SelectProviderPage extends React.Component{


    constructor(props) {
      super(props)
      Tracker.logScreenView('schedule_appointment_select_provider')

      let orderedProviders = Helpers.sortProviders(this.props.providers);

      orderedProviders.forEach(e => {
        if(e.id === this.props.user.creatorId) {
          orderedProviders.splice(orderedProviders.indexOf(e), 1)
          orderedProviders = [e, ...orderedProviders]
        }
      })

      this.state={
        list: this.buildListFromExisting(this.props.selection, this.props.providers) ?? {},
        allProviders: orderedProviders,
        visibleProviders: orderedProviders
      }

      this.onProviderClicked=this.onProviderClicked.bind(this);
      this.onSubmit=this.onSubmit.bind(this);
      this.cancel=this.clearProviders.bind(this);
    }

    componentDidMount(){
      window.addEventListener('resize', this.onResize)
    }
  
    componentWillUnmount(){
      window.removeEventListener('resize', this.onResize)
    }
  
    onResize = () => this.forceUpdate()

    buildListFromExisting = (providerIds, providers)=> {
      if(!providerIds || providerIds.length === 0) return null
      const providerDict = {};
      providers.forEach((provider)=>{
        providerDict[provider.id] = provider
      })
      const list = {};
      providerIds.forEach((providerId) => {
        let provider = providerDict[providerId]
        if(provider){
          list[providerId] = provider
        }
      })
      return list
    }
  
    renderProviderRow(i, rowSize, list){
      return list.slice(i, i + rowSize).map((provider, index) => {
        return (
          <th>
          <ProviderButton
            user={this.props.user}
            provider={provider}
            machine={this.props.machine}
            onSelectProvider={this.onProviderClicked}
            checked={this.state.list[provider.id] === provider}
            disabled={ProviderManager.isProviderDisabled(provider)}
          />
          </th>
        )
      })
    }
  
    renderProviderList() {
      let list = Object.assign([], this.state.visibleProviders);
      if(!this.props.showDisabled){
        list = list.filter((provider)=>{
          return !ProviderManager.isProviderDisabled(provider)
        })
      }

      let rowSize = 5;

      if(window.innerWidth < 1000) rowSize = 4
      if(window.innerWidth < 800) rowSize = 3
      if(window.innerWidth < 600) rowSize = 2
      if(window.innerWidth < 400) rowSize = 1

      const rowArray = [];

      for(let i = 0; i < list.length / rowSize; i++){
        rowArray.push(i)
      }

      return(
      <Table borderless={true}>
        <div align="center">
          <tr>
          {
            rowArray.map((val, index) => {
              return(
                <tr>
                  {this.renderProviderRow(val * rowSize, rowSize, list)}
                </tr>
              )
            })
        }
        </tr>
      </div>
    </Table>
      )
   }

    handleFilterChange(event) {
      const filter = event.toLowerCase();
      const visibleProviders = [];

      if (filter.length === 0) {
        this.setState({
          visibleProviders: this.state.allProviders
        })
      }
      else {
        for (let i = 0; i < this.state.allProviders.length; i++) {
          const provider = this.state.allProviders[i];

          if (provider.firstName.toLowerCase().startsWith(filter) || 
            provider.lastName.toLowerCase().startsWith(filter) || 
            Helpers.getProviderFullName(provider).toLowerCase().startsWith(filter) ||
            (provider.title && provider.title.toLowerCase().startsWith(filter))) {
  
            visibleProviders.push(provider);
          }
        }
        this.setState({
          visibleProviders: visibleProviders
        })
      }
    }

    canContinue(){
      return this.props.bypassLengthCheck || Object.values(this.state.list).length > 0
    }

    onProviderClicked(provider){
      if(this.props.isEdit){ //we are editing. Skip multi-select and just submit right away
        this.onSubmit(null, provider)
        return
      }
      const list = this.state.list;
      if(list[provider.id]){
        delete(list[provider.id])
      }
      else{
        list[provider.id] = provider
      }
      this.setState({list: list})
    }

    onSubmit(e, singleProvider){ //e is intentionally not used. That is class passed by the button
      let providers = null;
      if(singleProvider !== undefined){
        providers = [singleProvider]
      }
      else{
        providers = Object.keys(this.state.list)
      }
      this.props.onSelectProvider(providers)
    }

    clearProviders(){
      this.setState({list: []})
    }

    onNewAction = (option)=> {
      if(this.props.showNewProviderOption){
        this.onProviderClicked(null)  
      }
      else{
        let nextTitle = this.props.nextTitle ?? "Next"
        switch (option) {
          case nextTitle:
            this.onSubmit()
            this.props.onComplete ? this.props.onComplete() : this.props.machine.send("NEXT")
            break;
          case "Reset":
            this.clearProviders()
            break;
        }
      }
      
      
    }
    render(){
      console.log('render')
      if(this.props.editing) return null
      let nav;
      if(this.props.showNewProviderOption){
        nav = (
          <NavigationHeader 
            navigationOptionsTitles={['', 'Create New User']} 
            navigationBackTitle={null} 
            onNewOption={this.onNewAction} 
            onNavigateBack={this.onNavigateBack}/>
        )
      }
      else if(this.props.singleSelection){
        nav = (
          <NavigationHeader 
            title={this.props.title??"Select Provider"} 
            navigationBackTitle="Back" 
            onNavigateBack={()=>{this.props.onCancel ? this.props.onCancel() : this.props.machine.send("CANCEL")}}/>
        )
      }
      else{
        nav = (
          <NavigationHeader 
            title={this.props.title??"Select Provider"} 
            navigationBackTitle="Back" 
            onNavigateBack={()=>{this.props.onCancel ? this.props.onCancel() : this.props.machine.send("CANCEL")}} 
            navigationOptionsTitles={this.props.isEdit ? null : ["Reset", this.props.nextTitle ?? "Next"]}
            disableOptions={!this.canContinue()?[this.props.nextTitle ?? "Next"]: null}
            onNewOption={this.onNewAction}
          />
        )
      }

      const disabledToggle = this.props.onShowDisabledToggle ? (
          <Col md="auto">
            <div style={{marginTop: '1.5rem', marginBottom: '1.5rem'}}>
              <SDCheckBox
                  label="Show Disabled"
                  onCheckChanged={this.props.onShowDisabledToggle}
                  checked={this.props.showDisabled}/>
            </div>
          </Col>
      ) : null;

      return(
        <>
          {nav}

          <Container>
            <Row>
              <Col md="auto" className="mt-3 ml-lg-5 pl-lg-5">
                <SDEditTextBox 
                  style={{width: '300px !important'}}
                  defaultValue={this.filter} 
                  label="Search" 
                  onChange={this.handleFilterChange.bind(this)} 
                  title={this.filter}/>
              </Col>
              {disabledToggle}
            </Row>
            <Row>
              <Col>
                {this.renderProviderList()}
              </Col>
            </Row>
          </Container>
        </>
      )
    }
  }
  
  class ProviderButton extends React.Component{
  
    constructor(props){
      super(props)
      this.isLoggedInUser = this.props.provider.id === this.props.user.creatorId
      this.state = {
        hovering: false,
      }
    }

    getColor(){
      if(this.props.checked){
        return Colors.Primary.Main
      }
      else if(this.props.disabled){
        return Colors.Gray201
      }
      else{ // color by if user is the logged in user or not
        return this.isLoggedInUser ? Colors.Secondary.Light : Colors.NavGray
      }
    }
  
    onClick = () => {
      this.props.onSelectProvider(this.props.provider)
    }
  
    onMouseOver = () => {
      this.setState({
        hovering: true
      })
    }
  
    onMouseLeave = () => {
      this.setState({
        hovering: false
      }) 
     }
  
    getStyle(){
      const style = {
        width: "150px",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: this.getColor(),
        borderRadius: "2px",
        border: '1px solid #0000001F'
      };

      if(this.isLoggedInUser){
        style.boxShadow =  "0px 3px 6px #00000029"
        style.border = "1px solid #0000001F"
      }
      if(this.state.hovering){
        style.border = `1px solid ${Colors.Primary.Main}`
      }

      return style
    }
  
    getIcon(){
      const style = {
        height: "50%",
        width: "50%"
      };
      const icon = this.props.disabled ? ProviderDisabledIcon : ProviderIcon;

      return (
        <Card.Img src={icon} style={style}/>
      )
    }
  
    render(){
      return(
        <div class="container">
        <div class="row">
          <div class="col text-center">
            <Card style={{border: "none"}}>
              <a href={() => false} style={{ cursor: "pointer" }} onClick={this.onClick} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                <Card.Body style={this.getStyle()}>
                  {this.getIcon()}
                  <Card.Text variant="primary" style={{fontSize:"12px", textAlign:"center"}}>{this.props.provider.firstName} {this.props.provider.lastName}</Card.Text>
                  <Card.Text variant="primary" style={{fontSize:"12px", textAlign:"center"}}>{this.props.provider.title}</Card.Text>
                </Card.Body>
              </a>
            </Card>
          </div>
       </div>
      </div>
      )
    }
  }
