import React from 'react';
import NavigationHeader from '../../ui/NavigationHeader';
import Colors from '../../util/Colors';
import DatePicker from "../../react-datepicker/src";
import { Button } from 'react-bootstrap';
import "../../react-datepicker/src/stylesheets/datepicker.scss";
import LoadingSpinner from '../../util/LoadingSpinner';
import PrintAllAgendasPage from './PrintAllAgendasPage';
import DateSelectPage from '../../ui/DateSelectPage';

export default class PrintPatientSchedule extends React.Component{
    constructor(props){
        super(props)
        window.scrollTo(0, 0)
        document.body.style = 'background:' + Colors.Secondary.Light + ';';
        this.state = {
            selectedDate: new Date()
        }
    }

    render(){
        var state = this.props.machine.state.value["PrintPatientSchedule"]
        switch (state) {
            case "ShowDates":
                return <DateSelectPage date={this.state.selectedDate} title="Select a date to print" submitTitle="Print" onSubmit={this.onPrintDate} onCancel={this.onBackPressed}/>
            case "Load":
                return <LoadingSpinner/>
            case "ShowPrint":
                return <PrintAllAgendasPage {...this.props} anchorDate={this.state.selectedDate} showNotes={this.state.showNotes}/>
            default:
                break;
        }
    }

    onPrintDate = (date, showNotes) => {
        this.setState({
            selectedDate: date,
            showNotes: showNotes
        }, ()=>{
            this.props.machine.send("RESOLVE")
        })
    }

    onBackPressed = () => {
        this.props.machine.send("HOME")
    }
}