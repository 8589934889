import NetworkManager from './NetworkManager';
import { Endpoint } from '../util/Constants';

export default class SendManager {
    
    
    static sendEmail(email, url, onResult) {
        var path = Endpoint.base + '/send/email';

        var params = {
            to: email, 
            url: url
        };

        NetworkManager.post(path, params, (result) => {
            onResult(result);
        });
    }

    static sendSms(number, url, onResult) {
        var path = Endpoint.base + '/send/sms';

        var params = {
            to: number, 
            url: url
        };

        NetworkManager.post(path, params, (result) => {
            onResult(result);
        });
    }

    static subSms(facilityId, urlCode, number, onResult) {
        var path = Endpoint.base + '/rooms/subscribe-sms'
        var params = {
            smsNumber: number, 
            facilityId: facilityId,
            urlCode: urlCode
        };
        NetworkManager.post(path, params, (result) => {
            onResult(result);
        });
    }
}