import React from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';
import TimeBlockPicker from '../../ui/TimeBlockPicker';
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager';
import FacilityManager from '../../managers/FacilityManager';
import LoadingSpinner from '../../util/LoadingSpinner'
import DayPickerBar from './ConfigureBathsComponents/DayPickerBar';
import SDEditTextBox from '../../ui/SDEditTextBox';
import NavigationHeader from '../../ui/NavigationHeader';
import './ConfigureSchedulePage.css'
import '../../assets/clean-switch.css'
import './ZeroMinuteSchedules.css'
import HybridTimePicker from '../../util/HybridTimePicker';
import { components } from "react-select";
import MultiSelect from './Multiselect';
import { toast } from 'react-toastify';
import DateSwitcher from '../../ui/DateSwitcher';
import DateAndTime from '../../util/DateAndTime';
import CalendarUtil from '../../util/CalendarUtil';
import IntervalTree from '@flatten-js/interval-tree'
import Helpers from '../../util/Helpers';
import FrequencyPicker from '../../ui/FrequencyPicker';
import {DayDropdown} from '../../ui/DayDropdown';
import styles from './ConfigureSchedulePage.module.css'

export default class ConfigureSchedulePage extends React.Component {

    style = {width: 278}
    horizontalSpacer = <div style={{width: 16}}/>
    verticalSpacer = <div style={{height: 16}}/>

    constructor(props) {
        super(props);

        var providers = []
        Object.keys(props.providers).forEach(p => {
            providers.push(props.providers[p])
        })

        providers.sort((a, b) => {return a.firstName > b.firstName ? 1 : -1})

        props.groups.sort((a, b) => {return a.id > b.id ? 1 : -1})

        let selectedPatients = props.schedule.patients ? props.schedule.patients : [];

        //need to filter out any that no longer exist.
        selectedPatients = selectedPatients.filter((occupancyId)=>{
            let valid = false
            props.facility.rooms.forEach((room)=>{
                if(room.occupancyId === occupancyId){
                    valid = true
                }
            })
            return valid
        })

        var allowedServices = Helpers.sortServices(props.services, true, false, false, true).filter((service)=>{
            return service.id !== props.facility.service2ferId && service.pickProvider && !service.providerOnly
        })

        var date = new Date()
        date.setHours(0,0,0,0)
        this.state = {
            anchorDate: date,
            schedule: props.schedule,
            loading: false,
            job: {
                serviceId: this.props.bathServiceId,
                facilityId: this.props.facility.id
            },
            selectedGroups: [],
            selectedService: null,
            name: props.schedule.name,
            scheduleType: props.schedule.scheduleType,
            selectedPatients: selectedPatients,
            errorMessage: null,
            providers: providers,
            services: allowedServices,
            groups: props.groups ? props.groups : [],
            providersForService: []
        }

        allowedServices.forEach(service => {
            if(service.id === props.schedule.service) {
                this.state.selectedService = service
                this.state.providersForService = this.getProvidersForService(service)
                if(props.schedule.title && service.alternateTitles && service.alternateTitles.includes(props.schedule.title)){
                    this.state.selectedTitle = props.schedule.title
                }

                this.state.providersForService.forEach(p => {
                    if(p.id === props.schedule.provider){
                        this.state.selectedProvider = p
                    }
                })
            }
        })

        if(props.schedule.service && !this.state.selectedService){
            this.state.errorMessage = "The service that was assigned here is no longer available."
        }

        this.state.groups.forEach(group => {
            if(props.schedule.groups.includes(group.groupId)) this.state.selectedGroups.push(group.groupId)
        })



        this.onTimeBlockChange = this.onTimeBlockChange.bind(this);
        this.validator = TimeBlockPicker.getValidator(this.props.facility)
        this.validator.addValidationCheck(this.validateTimesForRestrictions)
    }

    getConfig(){

        LegacyAppointmentManager.getCronJob(this.state.job, (config) => {

            if(config.statusCode){
                this.setState({
                    loading: false
                })
                return
            }

            var tzo = new Date().getTimezoneOffset() / 60

            config.duration /= (60 * 1000)

            config.execution.time = {
                hour24: config.execution.hours - tzo,
                minute: config.execution.minutes
            }

            var convertedTimes = []
            config.timeBlocks.times.forEach(time => {
                var t = {
                    valid: true,
                    start:{
                        hour24: Math.floor(time.start) - tzo,
                        minute: (time.start % 1) * 60
                    },
                    end:{
                        hour24: Math.floor(time.end) - tzo,
                        minute: (time.end % 1) * 60
                    }
                }

                convertedTimes.push(t);
            })
            config.timeBlocks.times = convertedTimes
            if(!config.execution.scheduleDaysBefore) config.execution.scheduleDaysBefore = 0

            this.setState({
                loading: false,
                config: config
            })

        })
    }


    getProviderOptions(){
        return(Object.keys(this.state.providersForService).map((k) => {
            var v = this.state.providersForService[k]
            return v.firstName + " " + v.lastName
        }))
    }

    getTimeOptions(){
        return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90]
    }

    onGroupSelected = (g) => {
        this.state.groups.forEach(group => {
            if(group.id === g){
                this.setState({
                    selectedGroup: group
                })
            }
        })
    }

    getProvidersForService(service){
        if(service == null) return []
        var providers = []
        var providerIds = this.props.serviceProvidersDict[service.id]
        this.state.providers.forEach(p => {
            if(providerIds.includes(p.id)) providers.push(p)
        })
        return providers
    }

    onServiceSelected(service, title){
        this.setState({
            selectedService: service,
            selectedProvider: null,
            selectedTitle: title,
            providersForService: this.getProvidersForService(service)
        })
    }

    drawDropDown(title, noneSelected, defaultValue, items = [], onChange = () => {}){

        var options = []
        options.push(<option className="zms-dropdown-option" dir="rtl" value="">{noneSelected}</option>)
        for(var i = 0; i < items.length; i++){
            if(items[i] === defaultValue) options.push(<option selected className="zms-dropdown-option" dir="rtl" value={i}>{items[i]}</option>)
            else options.push(<option className="zms-dropdown-option" dir="rtl" value={i}>{items[i]}</option>)
        }

        return(
                <Container style={{padding:0, margin:0, width: 278}}>
                    <div className="zms-dropdown-title">{title}</div>
                    <select onChange={onChange} className="zms-dropdown">
                        {options}
                    </select>
                </Container>
        )
    }

    drawToggle(title, isChecked, onChange){
        return(
            <Container style={{border: "1px solid #00C4D8", borderRadius: 4, padding:0, margin: 0, width: 278, height: 42}}>
                <div style={{lineHeight: "42px", height: "42px"}} className="zms-dropdown-title">{title}</div>
                    <label className="cl-switch lightishBlue" style={{float: "right", marginTop: 7, marginRight: 8}}>
                        <input type="checkbox" disabled={this.props.disabled} checked={isChecked} onChange={onChange}/>
                        <span class="switcher"></span>
                    </label>
            </Container>
        )
    }

    onNameChange = (name) => {
        this.setState({
            name: name
        })
    }

    onNewAnchorDate = (newAnchorDate) => {
        this.setState({
            anchorDate: newAnchorDate
        })
    }

    onNext = () => {
        this.moveAnchorDate(1)
    }

    onPrevious = () => {
        this.moveAnchorDate(-1)
    }

    moveAnchorDate = (days) => {
        var newAnchor = new Date(this.state.anchorDate)
        newAnchor.setDate(this.state.anchorDate.getDate() + days)
        this.onNewAnchorDate(newAnchor)
    }

    drawSharedElements(){
        //collect all of the service names and alternate titles so we can easily look them up when a button is clicked
        let serviceLookup = {}
        let serviceOptions = [];
        this.state.services.forEach(s => {
            serviceLookup[s.name] = s
            serviceOptions.push(s.name)
            if(s.alternateTitles){
                s.alternateTitles.forEach((title)=>{
                    serviceLookup[title] = s
                    serviceOptions.push(title)
                })
            }
        })

        let nameBoxStyle = {
            width: 278
        };

        let prependStyle = {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4
        };

        let formStyle = {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4
        };

        return(
            <>
            <DateSwitcher
                onClick={null}
                label={<DateAndTime anchor={this.state.anchorDate} dateOnly={true}/>}
                anchorDate={this.state.anchorDate}
                onNext={this.onNext}
                onPrevious={this.onPrevious}
                onChange={this.onNewAnchorDate}
                previousEnabled={!CalendarUtil.areDatesTheSameDay(this.state.anchorDate, new Date())}
            />

            <div style={{height: 16}}/>

            <Form.Row>
                <SDEditTextBox defaultValue={this.state.name} formStyle={formStyle} prependStyle={prependStyle} style={nameBoxStyle} label="Name" onChange={this.onNameChange}/>
                {this.horizontalSpacer}
                {this.drawDropDown(
                    "Service",
                    "Select Service",
                    this.state.selectedTitle ?? this.state.selectedService?.name ?? null,
                    serviceOptions,
                    (e) => this.onServiceSelected(serviceLookup[serviceOptions[e.target.value]], serviceOptions[e.target.value])
                )}
            </Form.Row>

            <Form.Row>{this.verticalSpacer}</Form.Row>

            <Form.Row>
                {this.drawDropDown("Provider", "Select Provider", this.state.selectedProvider ? this.state.selectedProvider.firstName + " " + this.state.selectedProvider.lastName : null,  this.getProviderOptions(), this.onProviderChange)}
                {this.horizontalSpacer}
                {this.drawDropDown("Duration", "Select Duration", this.state.schedule.duration,  this.getTimeOptions(), this.onDurationChange)}
            </Form.Row>

            <Form.Row>{this.verticalSpacer}</Form.Row>
            </>
        )
    }

    onAutomaticToggleChange = (e) => {
        var schedule = this.state.schedule
        schedule.isAutomatic = !schedule.isAutomatic

        this.setState({
            schedule: schedule
        })
    }

    onGroupChange = (e) => {
        var group = this.state.groups[e.target.value]
        this.setState({
            selectedGroup: group
        })
    }

    drawAutoElements(){

        var groupOptions = []
        this.state.groups.forEach(g => {
            groupOptions.push(g.id)
        })

        if(this.state.scheduleType === "simple") return null
        return(
            <>
            <Form.Row>
                {this.drawMultiSelect()}
                {this.horizontalSpacer}
                {this.drawToggle("Enable automatic scheduling", this.state.schedule.isAutomatic, this.onAutomaticToggleChange)}

            </Form.Row>
            <Form.Row>{this.verticalSpacer}</Form.Row>
            </>
        )
    }

    drawMultiSelect(){

          const customStyles = {
            control: () => ({
              // none of react-select's styles are passed to <Control />
              border: '1px solid #00C4D8',
              borderRadius: 4,
              width: 278,
              height: 42
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';

              return { ...provided, opacity, transition };
            },
            multiValue: () => ({
                visibility: 'hidden'
            }),
            indicatorsContainer: () => ({
                position: 'relative',
                top: -40,
                height: 36
            }),
            placeholder: () => ({
                direction: 'ltr',
                width: 269,
                position: 'relative',
                top: 12,
                fontFamily: 'roboto',
                fontWeight: 'bold',
                fontSize: '10px'
            }),
            valueContainer: () => ({
                height: 42
            }),
            option: () => ({
                fontSize: '14px',
                height: '18px',
                color: "#808080",
                paddingRight: '4px'
            }),
            clearIndicator: () => ({
                visibility: 'hidden',
                height: 0,
                width: 0
            }),
            container: () => ({
                bottom: 42,
                direction: "rtl",
                position: 'relative'
            })

          }

          const Option = props => {
            return (
              <div>
                <components.Option {...props}>
                  <input
                    type="checkbox"
                    checked={this.state.selectedGroups.includes(props.value) || (props.value == "*" && this.state.selectedGroups.length == this.state.groups.length)}
                    onChange={() => null}
                  />{" "}
                  <label>{props.label}</label>
                </components.Option>
              </div>
            );
          };

          var options = []
          var value = []
          this.state.groups.forEach(g => {
              var item = {value: g.groupId, label: g.id}
              options.push(item)
              if(this.state.selectedGroups.includes(g.groupId)) value.push(item)
          })


        return(

            <div style={{height: 50}}>

            <div style={{paddingTop: 14, paddingLeft: 8, height: 42, position: 'relative', fontFamily: 'roboto', fontWeight: 'bold', fontSize: 10}}>
                Select Groups
            </div>
            <MultiSelect
                options={options}
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                onChange={this.onGroupSelectionsChanged}
                styles={customStyles}
                isRtl={true}
                isSearchable={false}
                allowSelectAll={true}
                placeholder={""}
                value={value}
          />

          </div>

        )
    }

    onGroupSelectionsChanged = (msg) => {

        var groups = []
        msg.forEach(g => {
            if(g.value != "*") groups.push(g.value) //don't keep track of 'select all' option
        })

        this.setState({
            selectedGroups: groups
        })
    }

    drawTimeBlockPicker(){
        return(
            <Form.Row>
                <Col style={{padding: 0}}>
                    <TimeBlockPicker 
                        validator={this.validator} 
                        facility={this.props.facility} 
                        roundBottom={this.state.schedule.scheduleType === "simple"} 
                        key={this.state.schedule.duration} 
                        duration={this.state.schedule.duration} 
                        valid={this.state.schedule.valid} 
                        days={this.state.schedule.execution.days} 
                        timeBlocks={this.state.schedule.timeBlocks} 
                        onTimeBlockChange={this.onTimeBlockChange} 
                        onValidateTimes={this.validateTimeBlocks}
                        service={this.state.selectedService}></TimeBlockPicker>
                </Col>
            </Form.Row>
        )
    }

    drawDayPicker(){
        const {schedule} = this.state
        var {execution} = schedule
        var date = new Date()
        date.setHours(execution.time.hour24)
        date.setMinutes(execution.time.minute)
        date.setSeconds(0)
        date.setMilliseconds(0)

        if(this.state.scheduleType === "simple") return null
        return(
            <>
            <div className="day-picker-window">
                <div className="day-picker-title">
                    <input //inline
                        type="radio"
                        name="day-picker-option"
                        onChange={(event)=>{
                            execution.days = [0,1,2,3,4,5,6]
                            execution.dayInterval = null
                            execution.month = null
                            execution.day = null
                            execution.year = null
                            this.setState({
                                execution: execution
                            })
                        }}
                        checked={execution.days.length > 0}
                        className="day-picker-radio-selector"/>
                    Days to schedule
                </div>
                <DayPickerBar disabled={execution.days.length === 0} days={execution.days} multiselect={true} onChange={this.onDayChange}/>
            </div>
            <div className="day-picker-intervals">
                <div className="day-picker-title">
                    <input //inline
                        type="radio"
                        name="day-picker-option"
                        onChange={(event)=>{
                            execution.days = []
                            execution.dayInterval = 1
                            var start = new Date()
                            start.setHours(0,0,0,0)
                            execution.scheduleFromDate = start.getTime()
                            this.setState({
                                execution: execution
                            })
                        }}
                        checked={execution.dayInterval}
                        className="day-picker-radio-selector"/>
                    Schedule runs every
                </div>

                <FrequencyPicker
                    align="center"
                    disabled={!execution.dayInterval}
                    frequency={this.getMonthInterval()}
                    startDate={this.getStartFromDate()}
                    onFrequencyChange={(frequency)=>{
                        execution.dayInterval = frequency
                        this.setState({
                            execution: execution
                        })
                    }}
                    onStartDateChange={(startDate)=>{
                        execution.scheduleFromDate = new Date(startDate).getTime()
                        this.setState({execution: execution})
                    }}/>
            </div>

            <div className="generate-time-window">
                <Form.Row style={{width: 262, marginLeft: "auto", marginRight: "auto"}}>
                    <div className="generate-time-title">Schedule generates at</div>
                    <HybridTimePicker value={date} listener={this} onChange={this.onExecutionTimeChange}/>
                </Form.Row>
                <Form.Row className={styles.runScheduleDaysBeforeRow}>
                    <DayDropdown
                        className={styles.runScheduleDaysBeforeDropdown}
                        maxSelectableDay={5}
                        startAt={0}
                        disabled={!schedule.isAutomatic}
                        value={schedule.execution.scheduleDaysBefore}
                        onFrequencyChange={(frequency)=>{
                            execution.scheduleDaysBefore = frequency
                            this.setState({execution: execution})
                        }}/>
                    <div className={styles.runScheduleDaysBeforeEndText}>days before</div>
                </Form.Row>
            </div>

            <div className="day-picker-preview">
                <div className="day-picker-title">Schedule preview</div>
                {
                    [0,1,2,3].map((i)=>{
                        var startOfWeek = this.getWeekStartDay(this.getStartFromDate(), i)
                        return <DayPickerBar days={this.getNextWeek(this.state.schedule.execution, i)} ignoreInteractions={true} label={`${startOfWeek.getMonth()+1}/${startOfWeek.getDate()}`}/>
                    })
                }
            </div>
        </>
        )
    }

    getStartFromDate(){
        if(this.state.schedule.execution.scheduleFromDate){
            return new Date(this.state.schedule.execution.scheduleFromDate)
        }
        else{
            return new Date()
        }
    }

    getMonthInterval(){
        return this.state.schedule.execution.dayInterval ?? 1
    }

    getWeekStartDay = (now, weeksAfter)=> {
        var nowDate = new Date(now)
        nowDate.setHours(0,0,0,0)
        var weekRange = CalendarUtil.getWeekTimeRange(nowDate)
        var start = new Date(weekRange.start)
        start.setDate(start.getDate()+(7*weeksAfter))
        return start
    }

    getNextWeek = (execution, week)=>{
        if(!execution.dayInterval){
            return execution.days
        }
        //need to generate times in sequence up to the week we are scanning
        var initialDay = new Date(execution.scheduleFromDate).getTime()
        var startOfWeek = this.getWeekStartDay(initialDay, week).getTime()
        var endOfWeek = startOfWeek + (7*24*60*60*1000) - 1 //add 7 days, minus one millisecond
        var interval = execution.dayInterval * 24*60*60*1000 //convert day to ms
        var days = []
        for(var day = initialDay; day < endOfWeek; day += interval){
            days.push(day)
        }

        return [0,1,2,3,4,5,6].filter((dow)=>{
            var newDate = new Date(startOfWeek)
            newDate.setDate(newDate.getDate()+dow)
            return days.includes(newDate.getTime())
        }) //Sun-Sat
    }

    onExecutionTimeChange = (e) => {
        var schedule = this.state.schedule
        schedule.execution.time.hour24 = e.getHours()
        schedule.execution.time.minute = e.getMinutes()

        this.setState({
            schedule: schedule
        })
    }

    drawPatientButton(room){
        if(!room.occupied) return null
        var state = this.state.selectedPatients.includes(room.occupancyId) ? "selected" : "unselected"
        /* Forms make buttons submit, so lets not use the button element here */
        return(
            <div onClick={() => this.toggleRoom(room)} className={"patient-button-" + state}>
            {room.identifier}
            </div>
        )
    }

    toggleRoom(room){
        var patients = this.state.selectedPatients
        if(patients.includes(room.occupancyId)) patients.splice(patients.indexOf(room.occupancyId), 1)
        else patients.push(room.occupancyId)
        this.setState({
            selectedPatients: patients
        })
    }

    drawPatientList(){
        if(this.state.scheduleType === "auto") return null;

        var rows = []

        rows.push(<Row style={{height: 24}}/>)

        for(var i = 0; i < this.props.rooms.length; i += 2){
            var left = this.drawPatientButton(this.props.rooms[i])
            var right = this.props.rooms.length - 1> i ? this.drawPatientButton(this.props.rooms[i + 1]) : null

            rows.push(
                <Row style={{marginLeft: '-8px'}}>
                    {left}
                    {right}
                </Row>
            )
            rows.push(<Row style={{height: 16}}/>)
        }

        return (
            <Container style={{padding: 0, margin: 0, marginLeft: -5}}>
            {rows}
            </Container>
        )

    }

    drawError(){
        if(this.state.errorMessage == null) return null
        return <div className="errorMessage">{this.state.errorMessage}</div>
    }

    drawForm(){
        this.getTimeOptions()
        return (
                <>
                    <Form onSubmit={()=>{}} style={{width: 572, marginBottom: 16}}>
                        {this.drawError()}
                        {this.drawSharedElements()}
                        {this.drawAutoElements()}
                        {this.drawTimeBlockPicker()}
                        {this.drawDayPicker()}
                        {this.drawPatientList()}
                    </Form>
                </>
        )
    }

    render() {

        if(this.state.loading) return <LoadingSpinner/>

        return(
            <>
                <NavigationHeader onNavigateBack={this.closeAndReload} navigationBackTitle={"Zero Minute Schedules"} navigationOptionsTitles={["Delete", "Save", "Save and Run"]} onNewOption={this.onNewAction}/>
                <Container className="mt-3">
                <Row className="justify-content-md-center">
                    <Col style={{width: 571, maxWidth: 'unset', flexGrow: 'unset', flexBasis: 'unset'}}>
                        {this.drawForm()}
                    </Col>
                </Row>
            </Container>
            </>
        )
    }

    saveSchedule(runAfterSave){
        var errorMessage = null
        if(this.state.name == null || this.state.name === "" || this.state.name.trim().length === 0) errorMessage = "Please enter a name"
        else if(this.state.selectedService == null) errorMessage = "Please select a service"
        else if(this.state.selectedProvider == null) errorMessage = "Please select a provider"
        else if(this.state.schedule.duration == null) errorMessage = "Please select a duration"
        else if(this.state.schedule.timeBlocks.times.length === 0) errorMessage = "Please enter at least one time block"
        else if(this.state.schedule.scheduleType === "auto"){

            var timeBlocksAreInvalid = false
            this.state.schedule.timeBlocks.times.forEach(e => {
                if(!e.valid) timeBlocksAreInvalid = true
            })

            if(this.state.selectedGroups.length === 0) errorMessage = "Please select at least one group"
            else if(timeBlocksAreInvalid) errorMessage = "Please fix invalid times"
            else if(this.state.schedule.execution.days.length === 0 && !this.state.schedule.execution.dayInterval) errorMessage = "Please select at least one day to run the schedule on"
        }

        if(errorMessage){
            this.setState({
                errorMessage: errorMessage
            })
            return
        }


        this.setState({
            loading: true,
            errorMessage: null
        })

        var s = this.state.schedule
        s.name = this.state.name
        s.service = this.state.selectedService ? this.state.selectedService.id : null
        s.provider = this.state.selectedProvider ? this.state.selectedProvider.id : null
        s.patients = this.state.selectedPatients
        s.groups = this.state.selectedGroups
        s.title = this.state.selectedTitle

        LegacyAppointmentManager.createScheduleCronJob(this.props.facility.id, s, (res) => {
            FacilityManager.postSchedule(this.props.facility.id, s, (result) => {
                if(runAfterSave){
                    LegacyAppointmentManager.runZeroMinuteSchedule(this.props.facility.id, s.id, this.state.anchorDate, (result2) => {
                        let success = this.handleScheduleResponse(result2)
                        if(success){
                            this.closeAndReload()
                        }
                        else{
                            this.props.onReload()
                        }
                    })
                }else{
                    this.setState({
                        loading: false,
                        schedule: s
                    })
                }
            })
        })

    }

    handleScheduleResponse = (result) => {
        let response = result.Payload ? JSON.parse(result.Payload) : null
        if(!response){
            toast("An error occurred when trying to schedule appointments")
            return false
        }
        if(response.statusCode === 200){
            let message = response.message ? `Schedule successful: ${response.message}` : "Schedule successful"
            toast(message)
            return true
        }
        if(response.failedOccupancyIds){
            let messageLines = [`The following rooms failed to be scheduled:`]
            Object.keys(response.failedOccupancyIds).forEach((occupancyId)=>{
                messageLines.push(<br/>)
                messageLines.push(`${this.getRoomName(occupancyId)}: ${response.failedOccupancyIds[occupancyId]}`)
            })
            toast(<div>{messageLines}</div>, {
                autoClose: false,
                closeOnClick: false,
                draggable: false,
            })
            return false
        }
        else if(response.message){
            toast(`Failed to schedule: ${response.message}`)
        }
        return false
    }

    closeAndReload = () => {
        this.props.onReload()
        this.props.machine.send("CANCEL")
    }

    onNewAction = (e) => {
        switch(e){
            case "Cancel":
                this.closeAndReload()
            break;
            case "Save":
                this.saveSchedule(false)
            break
            case "Save and Run":
                this.saveSchedule(true)
            break;
            case "Delete":
                this.deleteSchedule()
            break
        }
    }

    deleteSchedule(){
        this.setState({
            loading: true
        })

        FacilityManager.deleteSchedule(this.props.facility.id, this.state.schedule, (result) => {
            LegacyAppointmentManager.cancelScheduleCronJob(this.props.facility.id, this.state.schedule, (res) => {
                this.props.machine.send("CANCEL")
                this.props.onReload()
            })
        })
    }

    onDayChange = (val) => {
        var schedule = this.state.schedule
        var exec = schedule.execution
        
        exec.days = val
        schedule.execution = exec
        this.setState({
            schedule: schedule
        })
    }

    onProviderChange = (e) => {
        var provider = this.state.providersForService[e.target.value]

        this.setState({
            selectedProvider: provider
        })
    }

    onDurationChange = (e) =>{
        var schedule = this.state.schedule
        schedule.duration = this.getTimeOptions()[e.target.value];

        this.setState({
            schedule: schedule
        }, () => this.forceUpdate())
    }

    onTimeBlockChange(timeBlocks) {
        var schedule = this.state.schedule
        schedule.timeBlocks = timeBlocks;

        this.setState({
            schedule: schedule
        });
    }

    buildConfig(dict){

        var firstProvider = null
        var providers = []

        var config = {}
        var loading = false
        //this.getConfig()

        config = {
            'days': [ 0, 1, 2, 3, 4, 5, 6],
            'providerId': firstProvider,
            'duration': 15, // 15 minutes
            'timeBlocks': {
                'times': []
            },
            "execution": {
                'frequency': "daily",
                'time': { formatted: "4:00 am", hour: 4, minute: 0, meridiem: 'am', hour24: 4},
                'scheduleDaysBefore': 0
            }
        }
        
        this.setState({
            config: config,
            loading: false
        })
    }

    getRoomName = (occupancyId) => {
        let name
        this.props.facility.rooms.forEach((room)=>{
            if(room.occupancyId === occupancyId){
                name = room.identifier
            }
        })
        return name
    }

    validateTimesForRestrictions = (time, times, index) => {
        //validate time does not conflict with a restriction. Depending on if we use days or not, the behaviour may be different
        let schedule = this.state.schedule

        if (this.state.selectedService == null){
            return "Please select a service"
        }

        let service = Helpers.getService(this.state.selectedService.id, this.props.facility.services)
        if(service.ignoreTimeRestrictions) return null
        let restrictedTimesToCheck = new IntervalTree()
        this.props.timeRestrictions.forEach((timeRestriction)=>{ 
            //iterate through and select our times. 
            //Each restriction has a potentially different range of times for each day
            if(schedule.timeBlocks.days && schedule.timeBlocks.days.length > 0){ //if our schedule uses days
                schedule.timeBlocks.days.forEach((day)=>{ 
                    //add the time restriction from that day to the list of times to check
                    let time = timeRestriction.schedule[this.dayLookup[day]]
                    restrictedTimesToCheck.insert([time.start, time.end], time)
                })
            }
            else{
                //otherwise pick a day to use, since we have to choose one
                let time = timeRestriction.schedule[this.dayLookup[1]] //Grab Monday's restrictions TODO make it the current day's restrictions
                restrictedTimesToCheck.insert([time.start, time.end], time)
            }
        })
        let start = (time.start.hour24*60*60*1000) + (time.start.minute*60*1000)
        let end = (time.end.hour24*60*60*1000) + (time.end.minute*60*1000)
        let conflicts = restrictedTimesToCheck.search([start+1, end-1])
        if(conflicts.length > 0){
            return "This service can not be scheduled during a meal"
        }
        else return null
    }

    dayLookup = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
}