import React from 'react';
import {Button} from 'react-bootstrap'
import Colors from '../../util/Colors'
import DatePicker from "../../react-datepicker/src";
import "../../react-datepicker/src/stylesheets/datepicker.scss";
import CalendarUtil from '../../util/CalendarUtil';

export default class SetStartDatePage extends React.Component{

  constructor(props){
    super(props)

    this.state={
      selectedDate: props.startDate
    }

    this.onDateChange = this.onDateChange.bind(this)
    this.onKeyEvent = this.onKeyEvent.bind(this)
    this.next = this.next.bind(this)
  }

  onKeyEvent(e){
    if (e.which === 13) {
      this.next()
    }
  }
  
  componentDidMount(){
    document.addEventListener('keydown', this.onKeyEvent);
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.onKeyEvent)
  }

    onDateChange(date){
      this.setState({
        selectedDate: date
      })
    }

    next(){
      this.props.onSetStartDate(this.state.selectedDate)
    }
  
    cancel = () => {
      this.props.machine.send("CANCEL")
    }

    formatSelectedDate(){

      if(this.state.selectedDate == null) return

      var chunks = this.state.selectedDate.toString().split(/\s+/)

      var month = CalendarUtil.getLongMonth(chunks[1])
      var day = parseInt(chunks[2])
      var year = chunks[3]

      return month + " " + day + ", " + year
    }
  
    render(){
      return(
        <>
        
        <div align="center" style={{marginLeft:"auto", marginRight:"auto", marginTop:"8px", marginBottom:"8px", width:"180px"}}>
        <div align="left">
          {this.props.isStart?"Start" : "End"} Date
          </div>

          <div align="center">
          <input disabled value={this.formatSelectedDate()} style={{textAlign:"center"}}/>
          </div>


          <br/>
        </div>

        <div align="center">
        <DatePicker
            highlightSelected={true}
            inline
            minDate={this.props.isStart ? new Date(0) : new Date()}
            selected={this.state.selectedDate}
            highlightSelected={true}
            onChange={this.onDateChange}
        />
        </div>

            <div style={{position:"absolute", bottom:"80px", right:"80px"}}>
              <Button variant="link" onClick={this.cancel} style={{color:Colors.Primary.Main}}>Cancel</Button>
              <Button variant="primary" onClick={this.next} style={{width:"80px", backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>Next</Button>
            </div>
  </>

      )
    }
  }