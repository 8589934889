import React from 'react';
import Helpers from './Helpers';

export default class RoomOccupancyDropdown extends React.Component{

    constructor(props){
      super(props)

      var rooms = []
      var occupancyDict = this.props.rooms
      Object.keys(occupancyDict).forEach(e => {
        var occupancy = occupancyDict[e];
  
        if (occupancy.occupied) {
          rooms.push(occupancyDict[e])
        }
      })
  
      var vp = null
      Helpers.sortRooms(rooms)
      rooms.forEach((room)=>{
        if(room.occupancyId === this.props.viewingId) vp = room
      })
      if(!vp){
        vp = rooms[0] //should always work
      }

      if(!props.viewingId){
        this.props.onLoadNewConfig(vp.occupancyId) 
      }

      this.state = {
        rooms: rooms
      }
    }

    renderOptions(){
      var options = []
      this.state.rooms.forEach((room) => {
        options.push(<option value={room.occupancyId}>{room.name} - {room.identifier}</option>)
      })
      return options
    }
  
    onChange = (e) => {
      this.props.onLoadNewConfig(e.target.value)
    }
  
    render(){
      return (
        <div align="center">
          <select value={this.props.viewingId} style={{width:200, fontSize:12}} onChange={this.onChange}>
              {this.renderOptions()}
          </select>
        </div>
      )
    }
  
  }