import { withAuthenticator } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
// Get the aws resources configuration parameters
import awsconfig from './aws-exports'; // if you are using Amplify CLI

import { render } from 'react-dom';

import React from 'react';

import {Navbar, Nav, Button, Container, Row, Col} from 'react-bootstrap'

import './App.css';
import './util/Constants'
import ApiUtil from './util/ApiUtil'

import ManagePatientsPage from './pages/ManagePatients/ManagePatientsPage'
import ScheduleAppointmentPage from './pages/ScheduleAppointment/ScheduleAppointmentPage'
import AgendaPage from './pages/Agenda/AgendaPage'
import PatientEvaluationPage from './pages/PatientEvaluationPage'
import FacilitySchedulePage from './pages/FacilitySchedulePage'
import HomePage from './pages/Home/HomePage'
import { Auth } from 'aws-amplify';
import Path, { Endpoint } from './util/Constants'
import HomeMachine from './machines/HomeMachine'
import { interpret, State } from 'xstate';
import CalendarUtil from './util/CalendarUtil';
import Colors from './util/Colors'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacilityManager from './managers/FacilityManager';
import Tracker from './managers/Tracker';
import ConfigManager from './managers/ConfigManager'
import LoadingSpinner from './util/LoadingSpinner'
import AdminPanel from './pages/Admin/AdminPanel';
// import DataGen from './util/DataGen'
import { createBrowserHistory } from 'history';
import FacilityUtil from './util/FacilityUtil';
import CreateFacilityPage from './pages/Admin/CreateFacilityPage';
import SDNavBar from './ui/SDNavBar'
import SDSubNavBar from './ui/SDSubNavBar';
import Helpers from './util/Helpers';
import CacheManager from './managers/CacheManager';
import PrintAllAgendasPage from './pages/PrintPatientSchedule/PrintAllAgendasPage';
//TODO schedule by configuration
import ManageServices from './pages/ManageServices/ManageServices';
import ZeroMinuteSchedules from './pages/ZeroMinuteSchedules/ZeroMinuteSchedules';
import BillingPage from './pages/Admin/BillingPage';
import { GetTitleForState, GetSubTitleForState } from './managers/StateTitleManager';
import { ManageAnnouncementsMachine } from './machines/Manage/ManageAnnouncementsMachine';
import ManageAnnouncementsPage from './pages/ManageAnnouncementsPage';
import AlertsPage from './pages/AlertsPage';
import ManageUsersPage from './pages/ManageUsers/ManageUsersPage'
import StringManager from './strings/StringManager';
import ManageActivitiesPage from './pages/ManageActivities/ManageActivitiesPage';
import ScheduleForwardPage from './pages/ScheduleForward/ScheduleForwardPage';
import ManageGroupsPage from './pages/ManageGroups/ManageGroupsPage';
import PrintPatientSchedule from './pages/PrintPatientSchedule/PrintPatientSchedule';
import RoutingUtil from './util/RoutingUtil';
import ExportFacilitySchedules from './pages/ExportFacilitySchedules/ExportFacilitySchedules';
import CustomAuthV2 from './pages/CustomAuth/CustomAuthV2';


toast.configure(
  {
    autoClose: 5000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_CENTER
  }
)

class App extends React.Component{

  static placeholder = "Do not put confidential information in this section. Instead type in Dr., UIHC, PCI, etc.";

  static history = createBrowserHistory();

  constructor(){
    super()
    this.cacheManager = CacheManager.createInstance(this)

    App.history.listen((location, action) => {
      if(location.state) this.machine.send(location.state.stateName)
      else {
        this.machine.send("HOME")
      }
    });

    this.state = {
      navBarTitle: "", 
      subNavBarTitle: "", 
      loadingUrl: RoutingUtil.getQueryValue("agenda"),
      view: RoutingUtil.getQueryValue("view"),
      announcementsUrl: window.location.href.toLowerCase().includes("announcements"),
      scrollX: 0,
      editingAppointment: null,
      schedulingRoom: {},
      facility: {},
      user: {},
      services: {}, 
      providers: {},
      availableOptions: [],
      serviceProvidersDict: {}
    }

    window.addEventListener("unhandledrejection", function(promiseRejectionEvent) {
      // handle error here, for example log
      console.error(promiseRejectionEvent)
      //TODO maybe report this to backend
      toast('An unhandled error occurred. Please contact support. \n Click or close to reload', {type: 'error', autoClose: false, onClose: () => {
          window.location.reload()
      }})
      promiseRejectionEvent.preventDefault()
    });

    this.onCacheUpdated(this.cacheManager.getState())
    this.cacheManager.registerListener(this.onCacheUpdated)

    this.machine = interpret(HomeMachine).onTransition(state => {
      this.onNewState(state)
    })
    this.machine.start()

    this.init = this.init.bind(this)

    if(this.state.loadingUrl) {
      this.init(this.state.loadingUrl).then(()=>{
        this.loadAgendaFromLink()
      })
    }
    else if(this.state.announcementsUrl){
      this.init(this.state.loadingUrl).then(()=>{
        this.loadAnnouncementsOnly()
      })
    }
    else this.checkLogin()
  }

  onCacheUpdated = (cache) => {
    console.log(`onCacheUpdated ${new Date()}`)
    StringManager.injectBackendDictionary(cache.facility)
    return new Promise((resolve)=>{
      this.setState({
        loadedFromCache: true,
        availableOptions: cache.availableOptions,
        facility: cache.facility, 
        user: cache.user,
        services: cache.services,
        providers: cache.providers,
        serviceProvidersDict: cache.serviceProvidersDict,
        clearCacheOnReload: false
      }, resolve)
    })
  }

  static transition(stateName){
    switch(stateName){
      case "SELECT_FACILITY_SCHEDULE":
        this.pushHistory(stateName, "/?view=facilityschedule")
        break;
      case "SELECT_PROVIDER_SCHEDULE":
        this.pushHistory(stateName, "/?view=providerschedule")
        break;
      case "SELECT_AGENDA":
        this.pushHistory(stateName, "/?view=userschedule")
        break;
      default:
        this.pushHistory(stateName)
        break;
    }
  }

  static pushHistory(stateName, url = "/"){
    App.history.push(url, {
      stateName: stateName
    })
  }

  componentDidUpdate(){
    var state = this.machine.state.value
    if(state == "Home" && this.state.clearCacheOnReload){
      CacheManager.newSession(/*logout*/false)
    }
  }

  componentDidMount(){
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  }

  onScroll = () => {
      this.setState({ //TODO do we need this to always run? Makes unneeded page updates
          scrollX: window.scrollX
      })
    }

  onResize = () => this.forceUpdate()

  loadAnnouncementsOnly = () => {
    if(this.state.facility.id == null){
      console.log("setTimeout(this.loadAnnouncementsOnly, 500)")
      setTimeout(this.loadAnnouncementsOnly, 500)
    }else{
      this.state.announcementsUrl = false
      console.log("this.machine.send('ANNOUNCEMENTS')")
      this.machine.send("ANNOUNCEMENTS")
    }
  }

  loadAgendaFromLink = () => {
    if(this.state.facility.id == null){
      setTimeout(this.loadAgendaFromLink, 500)
    }else{
      var urlCode = this.state.loadingUrl
      this.state.urlCode = urlCode
      this.state.loadingUrl = false
      
      Tracker.logPatientLink(urlCode);

      this.machine.send("AGENDA")
    }
  }

  async checkLogin() {
    var info = await Auth.currentUserInfo()
    if(info != null) {
      this.machine.send("AUTHORIZED")
      this.init()
     }else{
       this.machine.send("UNAUTHORIZED")
     }
  }

  async init(urlCode) { //loadingUrl is our patient agenda urlCode
    let info = null
    var currentUser = await Auth.currentUserInfo()
    if(currentUser){
      var user = await Auth.currentSession()
    
      if(user)
        info = user.getIdToken().decodePayload()
    }
    Endpoint.setAuthenticated(info != null);

    if(this.state.loadedFromCache){ //TODO update user token info in cache
      this.machine.send("RESOLVE")
      return
    }

    var facilityId = undefined
    if(urlCode){ //we have a patient agenda. Get the facilityId from it
      var patientCode = urlCode
      var promise = new Promise(function(resolve, reject){
        FacilityManager.getRoom(null, null, patientCode, (data) => {
          if(data.facilityId){
            facilityId = data.facilityId
            resolve(facilityId)
          }
          else{
            console.error("Failed to load patient code...")
            resolve(null)
          }
        })
      })
      await promise
    }
    else{ //normal flow. Get facility id from first subdomain. This doesn't care if it is a subdomain of a subdomain
      var facilityLocation = window.location.host.split('.')[0];
      var response = await FacilityManager.getFacilityId(facilityLocation)
      if(response.statusCode == 200){
        facilityId = response.message.facilityId
      }
      else{
        console.error(`Unable to get facilityId from ${facilityLocation}. Status code was ${response.statusCode} with message of ${response.message}`)
        CacheManager.newSession(/*logout*/true)
        return;
      }
    }
    if(!facilityId && urlCode){
      this.state.loadingUrl = false
      this.machine.send("AGENDA_404")
      return
    }
    var cacheResult = await this.cacheManager.fetchSkilledDayData(facilityId, true, true, true)
    if(cacheResult){
      this.machine.send("RESOLVE")
      this.checkForViewQuery()
    }
    else{
      this.machine.send("REJECT")
      CacheManager.newSession(/*logout*/true)
    }
  }

  checkForViewQuery = () => {
    if(this.state.view){
      switch(this.state.view){
        case "facilityschedule":
          this.machine.send("SELECT_FACILITY_SCHEDULE")
          break;
        case "providerschedule":
          this.machine.send("SELECT_PROVIDER_SCHEDULE")
          break;
        case "userschedule":
          this.machine.send("SELECT_AGENDA")
          break;
      }
    }
  }

  onNewState(state){
    this.setState({
      navBarTitle: GetTitleForState(state.value, this.state),
      subNavBarTitle: GetSubTitleForState(state.value, this.state)
    })
  }

  /**
   *
   * @param appt {AppointmentModel}
   */
  onEditAppointment = (appt) => {
    console.log(`onEditAppointment ${JSON.stringify(appt)}`)
    appt.services = this.state.services

    this.setState({
      editingAppointment: appt
    }, () => {
      if(appt.serviceId === this.state.facility.activityServiceId){
        this.machine.send("SELECT_MANAGE_ACTIVITIES")
      }
      else{
        if(appt.isGroupAppointment)
          this.machine.send("SELECT_SCHEDULE_GROUP_APPOINTMENT")
        else
          this.machine.send("SELECT_SCHEDULE_APPOINTMENT")
      }
    })
  }

  clearEditAppointment = (navigateBackIfExists) => {
    if(navigateBackIfExists && this.state.editingAppointment && this.state.editingAppointment.previousState){
      this.navigateToState(this.state.editingAppointment.previousState)
    }
    this.setState({
      editingAppointment: null
    })
  }

  onOptionSelected = (option) => {
    console.log(`onOptionSelected ${JSON.stringify(option)}`)
    App.transition(option.event)
  }

  clearUrlCode = () => {
    this.setState({
      urlCode: null
    })
  }

  getPage(){
    var state = Object.keys(this.machine.state.value)[0]
    if(this.machine.state.value === "Announcements")
      state = "Announcements"
    else if(state == 0) 
      state = "Home"

    if(this.state.facility.id == "Admin") //Admin Facility Only
      return <CreateFacilityPage></CreateFacilityPage>
    switch(state){
      //Home
      case "Home": return <HomePage path={Path.HOME} facility={this.state.facility} availableOptions={this.state.availableOptions} machine={this.machine} onOptionSelected={this.onOptionSelected}/>
      case "Announcements": return (<AlertsPage isEdit={false} facilityId={this.state.facility.id}/>)
      //View
      case "Agenda": return <AgendaPage clearUrlCode={this.clearUrlCode} providers={this.state.providers} onEditAppointment={this.onEditAppointment} machine={this.machine} urlCode={this.state.urlCode} user={this.state.user} facility={this.state.facility} services={this.state.services}/>
      case "FacilitySchedule": return (
        <FacilitySchedulePage 
          anchorDate={this.state.anchorDate}
          clearAnchorDate={this.clearAnchorDate}
          serviceProvidersDict={this.state.serviceProvidersDict} 
          machine={this.machine} services={this.state.services} 
          facility={this.state.facility} 
          user={this.state.user}
          onEditAppointment={this.onEditAppointment}/>
      )
      case "FacilityProviderSchedule": return (
        <FacilitySchedulePage 
          anchorDate={this.state.anchorDate}
          clearAnchorDate={this.clearAnchorDate}
          serviceProvidersDict={this.state.serviceProvidersDict} 
          machine={this.machine} services={this.state.services} 
          facility={this.state.facility} 
          user={this.state.user}
          providerSwitch={true}
          onEditAppointment={this.onEditAppointment}/>
      )

      //Actions
      case "ExportFacilitySchedules": return <ExportFacilitySchedules providers={this.state.providers} machine={this.machine} facility={this.state.facility} services={this.state.services} user={this.state.user}/>
      case "PrintPatientSchedule": return <PrintPatientSchedule providers={this.state.providers} machine={this.machine} facility={this.state.facility} services={this.state.services} user={this.state.user}/>
      case "ScheduleAppointment": 
        return <ScheduleAppointmentPage 
          navigateToState={this.navigateToState} 
          user={this.state.user} 
          providers={this.state.providers} 
          serviceProvidersDict={this.state.serviceProvidersDict} 
          services={this.state.services} 
          clearEditAppointment={this.clearEditAppointment} 
          editingAppointment={this.state.editingAppointment} 
          machine={this.machine}
          facility={this.state.facility}/>
      case "ScheduleGroupAppointment": return <ScheduleAppointmentPage navigateToState={this.navigateToState} user={this.state.user} providers={this.state.providers} serviceProvidersDict={this.state.serviceProvidersDict} services={this.state.services} clearEditAppointment={this.clearEditAppointment} editingAppointment={this.state.editingAppointment} machine={this.machine} facility={this.state.facility} groupAppointment={true}/>
      case "ScheduleForward":
        return <ScheduleForwardPage 
          onScheduleFinished={this.onScheduleFinished} 
          navigateToState={this.navigateToState} 
          user={this.state.user} 
          providers={this.state.providers}
          services={this.state.services} 
          machine={this.machine}
          facility={this.state.facility}
          onEditAppointment={this.onEditAppointment}/>
      case "ScheduleByConfiguration": return null //TODO

      //Manage
      case "ManageActivities": return <ManageActivitiesPage onSchedulingRoom={this.onSchedulingRoom} services={this.state.services} machine={this.machine} facility={this.state.facility} user={this.state.user} editingAppointment={this.state.editingAppointment} clearEditAppointment={this.clearEditAppointment}/>
      case "ManagePatients": return <ManagePatientsPage onSchedulingRoom={this.onSchedulingRoom} services={this.state.services} machine={this.machine} providers={this.state.providers} facility={this.state.facility} user={this.state.user} onEditAppointment={this.onEditAppointment}/>
      case "ManageServices": return <ManageServices services={this.state.services} machine={this.machine} providers={this.state.providers} facility={this.state.facility} user={this.state.user}/>
      case "ManageUsers": return <ManageUsersPage facility={this.state.facility} serviceProvidersDict={this.state.serviceProvidersDict} machine={this.machine} user={this.state.user} providers={this.state.providers} services={this.state.services}/>
      case "ManageGroups": return <ManageGroupsPage facility={this.state.facility} machine={this.machine}/>
      case "ZeroMinuteSchedule": return <ZeroMinuteSchedules occupancies={this.state.facility.occupancies} rooms={this.state.facility.rooms} providers={this.state.providers} services={this.state.services} machine={this.machine} facility={this.state.facility}/>
      case "Billing": return <BillingPage facility={this.state.facility}/>
      case "PatientEvaluation": return <PatientEvaluationPage machine={this.machine} facility={this.state.facility}/>
      case "ManageAnnouncements": return <ManageAnnouncementsPage facility={this.state.facility}/>
      case "AdminPanel": return (
        <AdminPanel onScheduleFinished={this.onScheduleFinished}
                    machine={this.machine} 
                    services={this.state.services} 
                    facility={this.state.facility} 
                    user={this.state.user} 
                    serviceProvidersDict={this.state.serviceProvidersDict}/>
      )
      default:
        return <HomePage path={Path.HOME} facility={this.state.facility} availableOptions={this.state.availableOptions} machine={this.machine} onOptionSelected={this.onOptionSelected}/>
    }
  }

  navigateToState = (state)=> {
    if(state.data.urlCode){
      document.location.reload()
    }
    else{
      this.machine.send('HOME')
      state.name.split('.').forEach((stateStage)=>{
        this.machine.send(stateStage, state.data)
        console.log(this.machine)
      })
    }
  }

  clearAnchorDate = () => {
    this.setState({
      anchorDate: null
    })
  }

  onScheduleFinished = (date) => {
    this.setState({
      anchorDate: date
    }, () => {
      this.machine.send("FINISH")
      this.machine.send("HOME")
      this.machine.send("SELECT_PROVIDER_SCHEDULE")
    })
  }
  onSchedulingRoom = (selectedRoom) => {
    this.setState({
      schedulingRoom: selectedRoom
    })
  }

  convertLocations(facility){
    facility.locations = facility.locations.reduce(function(map, obj) {
      if(obj.name.toLowerCase() === "offsite") facility["offsiteLocationId"] = obj.id
      map[obj.id] = obj;
      return map;
    }, {});
  }

  static until(conditionFunction) {

    const poll = resolve => {
      if(conditionFunction()) resolve();
      else setTimeout(_ => poll(resolve), 400);
    }
  
    return new Promise(poll);
  }

  render() {

    var state = this.machine.state.value
    if(this.state.loadingUrl) return <LoadingSpinner/>

    if(state === "CheckAuthStatus") return <LoadingSpinner/>

    if(state === "Agenda404"){
      return <div align='center' style={{marginTop: 16}}>Agenda not found</div>
    }

    if(state.Login){
      return <CustomAuthV2 machine={this.machine} onLogin={this.init}/>
    }
    
    if(Object.keys(this.state.facility).length == 0) return <LoadingSpinner/>

    return (
      <div>
        <SDNavBar machine={this.machine} title={this.state.navBarTitle} user={this.state.user} scrollX={this.state.scrollX}/>
        <SDSubNavBar title={this.state.subNavBarTitle} scrollX={this.state.scrollX}/>
        {/*We want to render a text area somewhere that just logs out what state the machine is in, and its data, only when we need it*/}
        {/*<div>*/}
        {/*    <div style={{width: "20%", height: 200, position: 'absolute', right: 0, top: 0, bottom: 0}}>{JSON.stringify(this.machine.state.value)}</div>*/}
        {/*</div>*/}
        {this.getPage()}
        {/* <FeedbackButton/> */}
      </div>
    );
  }

  fetchNewToken = async() => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          console.log('session', err, session);
          const { idToken, refreshToken, accessToken } = session;
          // do whatever you want to do now :)
      });
    } catch (e) {
        console.log('Unable to refresh Token', e);
    }
  }
}

class FeedbackButton extends React.Component {
  positionY = 0

  componentDidMount(){
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  }

  onScroll = () => {
    this.positionY = (-1.0 * window.scrollY)
    this.forceUpdate()
  }

  onResize = () => this.forceUpdate()

  onFeedbackClicked() {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSePfkl_T3S7HFVPmGmMdke9L6jB5uPo9TRIvKOhok_zB9GkYw/viewform?usp=sf_link', '_blank');
  }

  render() {
    return (
      <Container fluid="true" style={{"position": "absolute", "bottom": this.positionY, "z-index": 5, width:160}} className="mb-3">
          <Row className="justify-content-md-start">
            <Col>
              <Button variant="secondary" size="sm" onClick={this.onFeedbackClicked}>Give Feedback</Button>
            </Col>
          </Row>
      </Container>
    )
  }
}
 
Amplify.configure(awsconfig);

export default App