import AppointmentModel from "./AppointmentModel";

export interface ScheduleAppointmentHolder{
    get(): AppointmentModel|null
    id: string,
    label: string
    start: number,
    end: number,
    isAppointment?: boolean,
    isOpenTime?: boolean
    providerIndex?: number,
    occupancyIndex?: number
    providerTitle?: string
}

export class ScheduleAppointmentHolderImpl implements ScheduleAppointmentHolder{
    private readonly _appointment: AppointmentModel
    id: string
    label: string
    providerIndex: number = -1
    occupancyIndex: number = -1
    providerTitle?: string
    isAppointment = true
    start: number
    end: number

    constructor(appointment: AppointmentModel, id: string, label: string) {
        this._appointment = appointment;
        this.start = appointment.start.getTime()
        this.end = appointment.end.getTime()
        this.label = label;
        this.id = id
    }

    get(): AppointmentModel{
        return this._appointment!
    }
}

export class ScheduleTimeSlot implements ScheduleAppointmentHolder{
    id: string
    label: string
    providerIndex: number = -1
    occupancyIndex: number = -1
    providerTitle?: string
    isAppointment = false
    isOpenTime?: boolean
    start: number
    end: number

    constructor(start: number, end: number, available: boolean, id: string, label: string) {
        this.isOpenTime = available
        this.start = start
        this.end = end
        this.label = label;
        this.id = id
    }

    get(): AppointmentModel|null{
        return null
    }
}