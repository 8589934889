export default class Timer {
    start: number = Date.now()

    constructor() {
        this.start = Date.now()
    }

    static start() {
        return new Timer()
    }

    getElapsed() {
        return ((Date.now()) - this.start) / 1000.0
    }
}