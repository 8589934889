import React from 'react';
import Colors from '../util/Colors'
import CalendarUtil from '../util/CalendarUtil'
import LoadingSpinner from '../util/LoadingSpinner';
import OccupancyFacilityCalendar from '../Calendars/OccupancyFacilityCalendar';
import ConfigManager from '../managers/ConfigManager';
import Tracker from '../managers/Tracker';
import FacilityServiceAgendaPage from './FacilityServiceAgendaPage';
import ProviderFacilityCalendar from '../Calendars/ProviderFacilityCalendar';
import { toast } from 'react-toastify';
import Queries from '../managers/Queries'
import Timer from '../util/Timer';
import {AppointmentRepository} from "../repositories/AppointmentRepository";
import {ScheduleAppointmentHolderImpl} from "../models/ScheduleAppointmentHolder";
import {ScheduleColumnImpl} from "../models/ScheduleColumn";
import LegacyAppointmentManager from "../managers/LegacyAppointmentManager";
import Helpers from "../util/Helpers";

export default class FacilitySchedulePage extends React.Component {

  constructor(props){
    super(props)
    Tracker.logScreenView('facility_schedule')
    this.repo = new AppointmentRepository({facilityId: props.facility.id})
    this.state = {
      columnWidth: window.innerWidth * 0.8 / 7,
      schedule:[], 
      blobs:{},
      
      //Go with the props anchor date, then try falling back to machine state. If neither work, today is our anchordate
      anchorDate: props.machine.state.event.anchorDate ?? props.anchorDate ?? new Date(), 
      
      columnIds: [],
      filteredItems: [],
      filteredServices: [],
      displayProviders: props.providerSwitch
    }

    document.body.style = 'background:' + Colors.Secondary.Light + ';';

    this.onServiceAgendaSelected = this.onServiceAgendaSelected.bind(this);
    this.onFacilityScheduleSelected = this.onFacilityScheduleSelected.bind(this);
    this.onAnchorDateChanged = this.onAnchorDateChanged.bind(this);

    this.props.clearAnchorDate()
  }


  getFacilitySchedule(date){
    this.setState({
      loading: true
    })
    //only get stashed appointments if we are displaying provider schedule
    const getStashedAppointments = this.state.displayProviders ? null : false;
    const appointmentQueryParams = Queries.getAppointmentQueryParams(CalendarUtil.getDayTimeRange(date), this.props.facility.id);
    appointmentQueryParams.stashed = getStashedAppointments
    appointmentQueryParams.showBlockedTimes = true

    this.repo.getAppointments(appointmentQueryParams)
        .then(appointments => {
          if(appointments.error){
            toast("Unable to fetch appointments")
            this.props.machine.send("HOME")
            return
          }
          this.parseAppointments(appointments.appointments)
        })
  }

  /**
   *
   * @param schedule {ScheduleDictionary}
   * @param appointment {AppointmentModel}
   * @param occupancyAssignee {OccupantAssignee}
   */
  addOccupancyToSchedule = (schedule, appointment, occupancyAssignee) => {
    const id = occupancyAssignee.roomId
    const label = `${occupancyAssignee.roomName} - ${occupancyAssignee.name}`
    if(!schedule[id]) schedule[id] = new ScheduleColumnImpl(id, label)
    let newAppointmentObject = new ScheduleAppointmentHolderImpl(appointment, id, label)
    newAppointmentObject.occupancyIndex = appointment.occupants.findIndex(o => o.roomId === id)
    schedule[id].push(newAppointmentObject)
  }

  /**
   *
   * @param schedule {ScheduleDictionary}
   * @param appointment {AppointmentModel}
   * @param provider {*}
   */
  addProviderToSchedule = (schedule, appointment, provider) => {
    if(!provider || (provider && provider.id && provider.id.includes("null"))) return
    const id = provider.id;
    // var id = `${appointment.serviceId}-${providerId}` //use this to split up each service (provider may have more than one column)
    //sorting without splitting up the provider works, but may not work correctly if a provider is in more than one service. The first service to appear would get the sort
    let providerName = provider.name !== provider.id ? provider.name : this.getProviderName(provider.id)
    if(!schedule[id]) schedule[id] = new ScheduleColumnImpl(id, providerName)

    let newAppointmentObject = new ScheduleAppointmentHolderImpl(appointment, id, providerName)
    let providerIndex = appointment.providers.findIndex(p => p.id === id)
    if(!appointment.providers[providerIndex].name || appointment.providers[providerIndex].name === id){
      appointment.providers[providerIndex].name = providerName
    }
    newAppointmentObject.providerIndex = providerIndex
    newAppointmentObject.providerTitle = this.getProviderTitle(id)

    schedule[id].push(newAppointmentObject)
  }

  /**
   *
   * @param data {AppointmentModel[]}
   */
  parseAppointments = (data)=> {
    if(data == null || data.length == null) return
    /**
     *
     * @type {ScheduleDictionary}
     */
    const schedule = {};

    /**
     * @type {AppointmentModel[]}
     */
    const meals = [];
    const activities = new ScheduleColumnImpl('activities', "activities");

    data.forEach((appointment) => {
      if(this.state.displayProviders){
        if(appointment.stashed){ //appointment is stashed. Give it its own column.
          //If an appointment is stashed AND incomplete, keep it here anyways, and mark it as not unassigned
          appointment.incomplete = false
          appointment.conflictingProviders = []
          this.generateUniqueAppointmentColumn(appointment, schedule, 'stashed', 'Stashed')
          return //don't process stashed farther
        }
        if(appointment.incomplete){ //appointment incomplete. Give it its own column
          this.generateUniqueAppointmentColumn(appointment, schedule, 'incomplete', '\!Unassigned')
          return //don't process incomplete farther
        }

        if(appointment.providers.length === 0) {
          if(!appointment.serviceId){
            appointment.serviceId = this.props.facility.mealServiceId
            meals.push(appointment)
          }
          if(appointment.serviceId === this.props.facility.activityServiceId){
            activities.pushAppointmentModel(appointment)
          }
          return
        }
        appointment.providers.forEach(provider => {
          this.addProviderToSchedule(schedule, appointment, provider)
        });
      }
      else{
        let occupants = appointment.occupants
        if(occupants.length === 0) {
          if(!appointment.serviceId)
            appointment.serviceId = this.props.facility.mealServiceId
          if(appointment.serviceId === this.props.facility.mealServiceId)
            meals.push(appointment)
          if(appointment.serviceId === this.props.facility.activityServiceId)
            activities.pushAppointmentModel(appointment)
          return
        }
        occupants.forEach(providerId => {
          this.addOccupancyToSchedule(schedule, appointment, providerId)
        });
      }
    })

    Object.keys(schedule).forEach(id => {
      meals.forEach(meal => {
        schedule[id].pushAppointmentModel(meal)
      })
    })

    if(activities.list().length > 0){
      schedule['activities'] = activities
    }

    /**
     * @type {string[]}
     */
    const sortedSchedule = Object.keys(schedule)
        .sort((a, b) => {
          //a lot of the sorting depends on the provider's first appointment. So store that
          let apptRefAHolder = schedule[a].list()[0]
          let apptRefBHolder = schedule[b].list()[0]
          if(!apptRefAHolder || !apptRefBHolder) return 0
          if(!apptRefAHolder.get || !apptRefBHolder.get){ //Note that if it hits here, it won't actually prevent a crash, but it would give us more details in the event of one
            console.error("Appointment reference is null", apptRefAHolder, apptRefBHolder)
            return 0
          }
          let apptRefA = apptRefAHolder.get()
          let apptRefB = apptRefBHolder.get()

          //sort by provider title first, then provider name
          let providerTitleA = apptRefAHolder.providerTitle
          let providerTitleB = apptRefBHolder.providerTitle

          let activityServiceId = this.props.facility.activityServiceId

          if (this.state.displayProviders) { //provider calendar
            /**
             * Intended sorting order
             * 1. Unassigned appointments
             * 2. Activities
             * 3. Regular appointments, grouped by service, sorted by provider name
             * 4. Stashed appointments
             */
            if (apptRefA.incomplete && !apptRefB.incomplete) {
              return -1
            } else if (!apptRefA.incomplete && apptRefB.incomplete) {
              return 1
            } else if (apptRefA.incomplete && apptRefB.incomplete) {
              return a > b ? 1 : -1 //sorting by unassigned column id, which increments
            }

            //Stashed appointments. Sort to back
            if (apptRefA.stashed && !apptRefB.stashed) {
              return 1
            } else if (!apptRefA.stashed && apptRefB.stashed) {
              return -1
            } else if (apptRefA.stashed && apptRefB.stashed) {
              return a > b ? -1 : 1 //sorting by unassigned column id, which increments
            }

            //Activities come before providers. Use the provider title field to accomplish this
            if (apptRefA.serviceId === activityServiceId) {
              providerTitleA = "!Activities"
            }
            if (apptRefB.serviceId === activityServiceId) {
              providerTitleB = "!Activities"
            }

            if (providerTitleA !== providerTitleB) {
              return providerTitleA > providerTitleB ? 1 : -1
            } else {
              let providerNameA = apptRefAHolder.providerIndex > -1 ? apptRefA.providers[apptRefAHolder.providerIndex].name : ''
              let providerNameB = apptRefAHolder.providerIndex > -1 ? apptRefB.providers[apptRefBHolder.providerIndex].name : ''
              if (providerNameA && providerNameB) {
                return providerNameA.toLowerCase() > providerNameB.toLowerCase() ? 1 : -1
              }
            }
          } else { //facility calendar (occupancies)
            /**
             * Intended sorting order
             * 1. Activities
             * 2. Regular appointments, sorted by room name
             */
            const scheduleA = apptRefAHolder.occupancyIndex > -1 ? apptRefA.occupants[apptRefAHolder.occupancyIndex].roomName : null;
            const scheduleB = apptRefBHolder.occupancyIndex > -1 ? apptRefB.occupants[apptRefBHolder.occupancyIndex].roomName : null;
            if (scheduleA && scheduleB) {
              const roomA = parseInt(scheduleA.split(/\s+/)[1]);
              const roomB = parseInt(scheduleB.split(/\s+/)[1]);
              return (roomA > roomB) ? 1 : -1
            }
            let activityServiceId = this.props.facility.activityServiceId
            return apptRefA.serviceId === activityServiceId ? -1 : 1
          }
        });

    /**
     * @type {ScheduleColumn[]}
     */
    const arr = [];

    sortedSchedule.forEach(scheduleKey => {
      arr.push(schedule[scheduleKey])
    })

    this.setState({
      unmodifiedAppointmentsArray: data,
      columnIds: sortedSchedule,
      schedule: arr,
      loading: false
    }, () => {
      this.props.machine.send("RESOLVE_SCHEDULE")
    })
  }

  /**
   * Appointments added to schedule using this will have their own set of columns. 
   * If an overlap exists, another column will be created
   * @param {AppointmentModel} appointment The appointment to add
   * @param {ScheduleDictionary} schedule The schedule object
   * @param {*} idPrefix prefix for the schedule column id
   * @param {*} label name of the column. Prepend with \! to make it sort to the beggining. \Z for it to sort to the end
   */
  generateUniqueAppointmentColumn = (appointment, schedule, idPrefix, label) => {
    let validTimes = true
    let incompleteIndex = 0 //Determines the "unavailable" column number. resets to 0 for every appointment
    do{
      validTimes = true// reset this every loop
      let idIncomplete = `${idPrefix}-${incompleteIndex}`
      if(!schedule[idIncomplete]) schedule[idIncomplete] = new ScheduleColumnImpl(idIncomplete, label)

      /**
       * @type {ScheduleAppointmentHolder}
       */
      let newAppointmentObject = new ScheduleAppointmentHolderImpl(appointment, idPrefix, label)
      
      //check for overlap in this column. Increase column if it does until valid
      for (const columnAppointment of schedule[idIncomplete].list()) {
        if(CalendarUtil.doAppointmentsOverlap(newAppointmentObject.get(), columnAppointment.get())){
          //overlap exists. Not valid
          validTimes = false
        }
      }
      if(validTimes){
        schedule[idIncomplete].push(newAppointmentObject)
      }
      else{
        //up counter for new column
        incompleteIndex++
      }
    }
    while(!validTimes);
  }

  getProviderName(id){
    return FacilitySchedulePage.getProviderName(id, this.props.facility)
  }

  getProviderTitle(id){
    const provider = this.props.facility.providers[id];
    if(provider)
      return provider.title
    else
      return ""
  }

  static getProviderName(id, facility, trimLastName=false){
    const provider = facility.providers[id];

    if(provider){
      let lastName = provider.lastName
      if(trimLastName)
        lastName = lastName[0]
      return `${provider.firstName} ${lastName}`
    }

    console.error(`Provider ${JSON.stringify(id)} not found in facility ${facility.id}`)

    return ""
  }

  getServiceName = (serviceId)=> {
    return FacilitySchedulePage.getServiceName(serviceId, this.props.facility)
  }

  static getServiceName = (serviceId, facility)=> {
    for (const service of facility.services) {
      if(service.id === serviceId){
        return service.name
      }
    }
    return null
  }

  componentDidMount(){
    this.loadData()
  }

  loadData = async() => {
    //get our preferences for filtering from backend
    if(this.state.displayProviders){
      let filteredProviders = await new Promise((resolve)=>{
        //get providers (false)
        ConfigManager.getProviderScheduleOptions(this.props.facility.id, this.props.user.creatorId, false, (data) => {
          if(!Array.isArray(data)) data = []
          resolve(data)
        })    
      })
      
      let filteredServices = await new Promise((resolve)=>{
        //get services (true)
        ConfigManager.getProviderScheduleOptions(this.props.facility.id, this.props.user.creatorId, true, (data) => {
          if(!Array.isArray(data)) data = []
          resolve(data)
        })
      })
      
      this.setState({
        filteredItems: filteredProviders,
        filteredServices: filteredServices
      })
    }
    else{
      let filteredRooms = await new Promise((resolve)=>{
        //get rooms
        ConfigManager.getFacilityScheduleOptions(this.props.facility.id, this.props.user.creatorId, (data) => {
          if(!Array.isArray(data)) data = []
          resolve(data)
        })    
      })
      this.setState({
        filteredItems: filteredRooms
      })
    }
    //OK, lets get the facility schedule now (will then load the calendar once completed)
    this.getFacilitySchedule(this.state.anchorDate)
  }

  loadDate = (newAnchor) => {
    this.refresh(newAnchor)
  }

  loadPrevious = () => {
    const newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() - 1));
    this.refresh(newAnchor)
  }

  loadNext = () => {
    const newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() + 1));
    this.refresh(newAnchor)
  }

  refresh = (anchorDate = this.state.anchorDate)=> {
    this.setState({loading: true})
    this.onAnchorDateChanged(anchorDate, ()=>{
      this.loadData()
    })
  }

  onAnchorDateChanged = (date, callback = null) => {
    this.setState({
      anchorDate: date
    }, ()=>{
      if(callback){
        callback()
      }
    })
  }

  onFacilityScheduleSelected = () => {

    this.setState({
      loading: true
    }, () => {
      this.props.machine.send("FACILITY_SCHEDULE_SELECTED")
      this.getFacilitySchedule(this.state.anchorDate)
    })
  }

  onServiceAgendaSelected = () => {
    this.setState({
      loading: true
    }, () => {
      this.props.machine.send("SERVICE_AGENDA_SELECTED")
    })

    let query = Queries.getAppointmentQueryParams(CalendarUtil.getDayTimeRange(this.state.anchorDate.getDate()), this.props.facility.id, this.props.facility.id)

    this.repo.getAppointments(query).then(data => {
      //TODO what is the point of this call? Just doing what was there before, with the new manager
      this.setState({
        loading: false
      }, () => {
        this.props.machine.send("RESOLVE_SERVICE_AGENDA")
      })
    })
  }

  onLoadSwapProviderView = (appointment)=> {
    this.setState({swapFrom: appointment.id})
    this.props.machine.send("SWAP")
    this.repo.getAvailableSlotsForSwap(appointment.id, appointment.requirePrimary)
        .then(result => {
          if(!result.error){
            this.parseAppointments(result.appointments)
            this.props.machine.send("NEXT")
          }
          else{
            console.log(result.error)
            toast("Failed to get appointments available to swap with")
            this.props.machine.send("CANCEL")
          }
        })
  }

  swapAppointmentWith = (fromAppointmentId, toAppointment) => {
    let to = toAppointment.id
    if(!to)
      to = JSON.stringify(toAppointment)
    this.setState({loading: true})
    let timer = Timer.start()
    LegacyAppointmentManager.swapAppointment(this.props.facility.id, fromAppointmentId, to, (result)=>{
      if(!result.Payload){
        toast("Failed to swap appointments")
        Tracker.logAppointmentSwap(timer.getElapsed(), false, toAppointment.type === "OPEN")
      }
      else{
        let swapResult = JSON.parse(result.Payload)
        if(swapResult.statusCode !== 200){
          toast("Failed to swap appointments")
          Tracker.logAppointmentSwap(timer.getElapsed(), false, toAppointment.type === "OPEN")
        }
        else if(swapResult.hasCancelledLinkedAppointments){
          toast("Due to reschedule, a 2fer appointment was cancelled")
        }
        Tracker.logAppointmentSwap(timer.getElapsed(), true, toAppointment.type === "OPEN")
      }
      
      this.setState({loading: false})
      this.props.machine.send("FINISH")
      this.refresh()
    })
  }

  swapColumns = (providersToFill, providersToPull, additionalInfo) => {
    this.setState({loading: true})
    let timer = Timer.start();

    LegacyAppointmentManager.swapColumns(this.props.facility.id, CalendarUtil.getDayTimeRange(this.state.anchorDate),providersToFill, providersToPull, additionalInfo, this.state.filteredServices, (result)=>{
      if(result.statusCode !== 200){
        toast("Unable to swap columns")
        Tracker.logSwapColumnAppointments(timer.getElapsed(), 0, false)
      }
      else{
        toast(`Swapped ${result.rescheduledAppointments} appointments`)
        Tracker.logSwapColumnAppointments(timer.getElapsed(), result.rescheduledAppointments, true)
      }
      this.setState({loading: false})
      this.refresh()
    })
  }

  onServiceSelectionsChanged = (filteredItems) => {
    this.setState({
      filteredServices: filteredItems
    })
  }

  render(){

    const state = this.props.machine.state.value[this.props.providerSwitch ? "FacilityProviderSchedule" : "FacilitySchedule"];

    if(state == null) return null

    if(this.state.loading) return <LoadingSpinner/>

    switch(state){
      case "RunSwapCalculations":
      case "load": return <LoadingSpinner/>
      case "ShowSwapView":
      case "ShowSchedule": return this.state.displayProviders ? (
          <ProviderFacilityCalendar
            machine={this.props.machine}
            onSelectServiceAgenda={this.onServiceAgendaSelected} 
            onEditAppointment={this.props.onEditAppointment}
            filteredItems={this.state.filteredItems} 
            filteredServices={this.state.filteredServices} 
            facility={this.props.facility} 
            user={this.props.user} 
            columns={Object.keys(this.state.schedule).length} 
            columnIds={this.state.columnIds}
            anchorDate={this.state.anchorDate} 
            schedule={this.state.schedule} 
            services={this.props.services} 
            refresh={this.refresh}
            loadNext={this.loadNext} 
            loadPrevious={this.loadPrevious}
            loadDate={this.loadDate}
            loadSwapProviderView={this.onLoadSwapProviderView}
            swapColumns={this.swapColumns}
            onServiceSelectionsChanged={this.onServiceSelectionsChanged}
            swapAppointmentWith={this.swapAppointmentWith}/>
        ) : (
          <OccupancyFacilityCalendar
            machine={this.props.machine}
            onSelectServiceAgenda={this.onServiceAgendaSelected} 
            onEditAppointment={this.props.onEditAppointment}
            filteredItems={this.state.filteredItems} 
            filteredServices={[]/*Don't use for now*/} 
            facility={this.props.facility} 
            user={this.props.user} 
            columns={Object.keys(this.state.schedule).length} 
            columnIds={this.state.columnIds}
            anchorDate={this.state.anchorDate} 
            schedule={this.state.schedule} 
            services={this.props.services} 
            refresh={this.refresh}
            loadNext={this.loadNext} 
            loadPrevious={this.loadPrevious}
            loadDate={this.loadDate}
            loadSwapProviderView={this.onLoadSwapProviderView}
            swapAppointmentWith={this.swapAppointmentWith}/>
        )
      case "ShowServiceAgenda":
      case "PrintServiceAgenda":
           return (
        <FacilityServiceAgendaPage //TODO convert to AppointmentModel
          user={this.props.user}
          machine={this.props.machine}
          onSelectFacilitySchedule={this.onFacilityScheduleSelected} 
          onEditAppointment={this.props.onEditAppointment}
          facility={this.props.facility} 
          services={this.props.services} 
          anchorDate={this.state.anchorDate} 
          onAnchorDateChanged={this.onAnchorDateChanged}/>
      )
      default: return null
    }
   }
}