import React from 'react';
import CalendarUtil from '../util/CalendarUtil'
import {Navbar} from 'react-bootstrap'

export default class NavDate extends React.Component{

    dateStyle = {
      color: "#ffffff", 
      fontSize: "16px",
    }
  
    render(){
      const shorten = window.innerWidth < 1000
      const hideDate = window.innerWidth < 600
      const now = CalendarUtil.getCurrentDate(shorten)
  
      if(hideDate) return(
        null
      )
      if(shorten) return(
        <Navbar.Text style={this.dateStyle}>{now.month}/{now.dayOfMonth}/{now.year}</Navbar.Text>
      )
      return(
        <>
        <Navbar.Text style={this.dateStyle}>{now.dayOfWeekString} {now.dayOfMonth} {now.monthString} {now.year}</Navbar.Text>
        </>
      )
    }
}