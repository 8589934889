import React from 'react';
import Colors from '../../util/Colors'
import {Dropdown} from 'react-bootstrap'


export default class RoomDropdown extends React.Component{

    rooms = []
  
    dropdownStyle = {
      marginTop: "20px",
      color: Colors.NavGray, 
      borderColor: Colors.Primary.Main
    }
    toggleStyle = {
      backgroundColor: Colors.NavGray,
      color: "#000000",
      borderColor: Colors.Primary.Main,
      width: "250px", 
      textAlign: "left"
    }
  
    renderOptions(){
      return this.props.rooms.map((room, index) => {
        return <Dropdown.Item eventKey={room.name}>{room.name}</Dropdown.Item>
      })
    }
  
    onSelect = (eventKey, event) => {
      this.props.rooms.forEach(room => {
        if(room.name === eventKey){
          this.props.onRoomSelected(room)
          return
        }
      });
    }
  
    getLabel(){
      return this.props.selectedRoom == null ? "Select a Room" : this.props.selectedRoom.name
    }
  
    render(){
      return(

  <div align={this.props.align}>
  <Dropdown style={this.dropdownStyle} onSelect={this.onSelect}>
    <Dropdown.Toggle style={this.toggleStyle}>
      {this.getLabel()}
    </Dropdown.Toggle>
  
    <Dropdown.Menu>
      {this.renderOptions()}
    </Dropdown.Menu>
  </Dropdown>
  </div>
      )
    }
  }