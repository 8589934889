import React from 'react';
import { Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import DaySelected from '../../../img/Icons/day-selected.png'
import DayUnselected from '../../../img/Icons/day-unselected.png'
import './DayPickerBar.css'

export default class DayPickerBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            multiselect: this.props.multiselect ? true : false
        };

        this.onDayChange = this.onDayChange.bind(this);
    }

    drawDayButton(day){
        var selected = this.props.days.indexOf(day) !== -1
        var className = selected ? "day-selected" : "day-unselected"
        if(this.props.disabled){
            className = "day-disabled"
        }
        var src = selected ? DaySelected : DayUnselected
        return(
            <div style={{marginLeft: 6, marginRight: 6}}>
            <img src={src} className={className} onClick={this.props.disabled ? null : () => this.onDayChange(day)}/>
            <div className={className + "-text"}>{['S', 'M', 'T', 'W', 'T', 'F', 'S'][day]}</div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.props.label?(
                    <div className="day-picker-label">{this.props.label}</div>
                ):null}
                <Row className="justify-content-md-center mb-2">
                    {this.drawDayButton(0)}
                    {this.drawDayButton(1)}
                    {this.drawDayButton(2)}
                    {this.drawDayButton(3)}
                    {this.drawDayButton(4)}
                    {this.drawDayButton(5)}
                    {this.drawDayButton(6)}
                </Row>
            </div>
        );
    }

    onDayChange(day) {
        if(this.props.ignoreInteractions) return
        var val = this.props.days
        if(val.includes(day)) val.splice(val.indexOf(day), 1)
        else val.push(day)
        this.setState({
            days: val
        }, () => { this.props.onChange(this.state.days) });
    }

    getColor(index) {
        return (this.state.days.indexOf(index) === -1) ? "outline-secondary" : "secondary";
    }
}