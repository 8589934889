import React from 'react'

import SchedulePopup from "../pages/ManagePatients/SchedulePopup";
import {Form, Row} from 'react-bootstrap'

export default class RestrictionsPopup extends SchedulePopup{
    
    constructor(props){
        super(props)
        this.super = {}
        this.super.save = this.save
        this.super.confirmDelete = this.confirmDelete
        this.super.discard = this.discard
        this.save = ()=>{
            this.super.save()
            this.state.title = null
        }
    
        this.confirmDelete = ()=>{
            this.state.title = null
            this.super.confirmDelete()
        }
    
        this.discard = ()=>{
            this.state.title = null
            this.super.discard()
        }

        this.state.defaultHeight = 170
    }

    getDeleteConfirmText(){
        return "Delete blocked time?"
    }

    buildAppointment(){ //override
        var appt = {
            facilityId: this.props.facility.id,
            title: this.getTitle(),
            id: this.props.blob.appointmentId //clickedBlob could be a restriction
        }
        return appt
    }

    getTitle = () => {
        return this.state.title ? this.state.title : this.props.blob.appointment.title
    }

    renderTitle = () => {
        if(this.props.hideName) return null
        return [
            this.drawLabel("Name", 16, 10, this.labelLeft),
            this.drawTextInput(9, this.inputLeft-50, 148+this.totalOffset, 20, "restrictionTitle", this.getTitle(), ()=>{
                this.setState({title: document.getElementById('restrictionTitle').value})
            })
        ]
    }

    renderContent(){
        var content = super.renderContent()
        //render extra content here
        return content
    }

    renderDropdowns = ()=>{
        return null
    }

    renderNotes = ()=>{
        return null
    }
}