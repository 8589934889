import React from "react";
import DateSwitcher from "./DateSwitcher";
import DateAndTime from "../util/DateAndTime";
import {DayDropdown} from "./DayDropdown";

/**
 * Choose a frequency in days, and choose a start date.
 */
export default class FrequencyPicker extends React.Component {
    constructor(props){
        super(props)
    }

    render(){
        return (
            <div className={this.props.className} style={this.props.style}>
                <div align='center'>
                    <DayDropdown maxSelectableDay={30} startAt={1} disabled={this.props.disabled} value={this.props.frequency} onFrequencyChange={this.props.onFrequencyChange}/>
                    {" Days"}
                </div>
                <div className="day-picker-title">
                    Starting on
                </div>
                <DateSwitcher 
                    disabled={this.props.disabled}
                    onChange={(date)=>{
                        this.props.onStartDateChange(date)
                    }}
                    label={<DateAndTime disabled={this.props.disabled} anchor={this.props.startDate} dateOnly={true}/>}
                    anchorDate={this.props.startDate}
                    onNext={()=>{
                        var date = new Date(this.props.startDate)
                        date.setDate(date.getDate()+1)
                        this.props.onStartDateChange(date)
                    }}
                    onPrevious={()=>{
                        var date = new Date(this.props.startDate)
                        date.setDate(date.getDate()-1)
                        this.props.onStartDateChange(date)
                    }}
                />
            </div>
        )
    }
}