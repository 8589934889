import React from 'react'
import EditRoleList from './EditRoleList'
import EditRoleAccess from './EditRoleAccess'

export default class ManageRoles extends React.Component {
    constructor(props){
        super(props)
        this.state = {  }
    }

    render() { 
        const state = this.props.machine.state.value["AdminPanel"]["ListFacilityRoles"]
        switch(state){
            case 'ListRoles':
                return <EditRoleList facility={this.props.facility} items={this.state.roleItems} onItemClicked={this.onItemClicked} onNewItem={this.onNewItem}/>
            case 'EditRoles':
            case 'CreateRole':
                return <EditRoleAccess facility={this.props.facility} item={this.state.editItem} onDeleteItem={this.deleteItem} onCancel={this.cancelEdit} onSave={this.saveItem} new={this.state.new}/>
        }
        return (
            <div></div>
        )
    }

    onItemClicked = (item) => {
        this.setState({ editItem: item, new: false});
        this.props.machine.send('EDIT')
    }

    onNewItem = (item) => {
        this.setState({ editItem: item, new: true});
        this.props.machine.send('CREATE')
    }

    deleteItem = (item) => {
        //TODO not implemented yet
    }

    cancelEdit = (item) => {
        this.setState({ editItem: null });
        this.props.machine.send("CANCEL")
    }

    saveItem = async(item) => {
        //TODO save changes to config
        this.setState({ editItem: null });
    }
}