import React from 'react';
import {Button, Modal, Spinner, Form, Col} from 'react-bootstrap';
import SendManager from '../../managers/SendManager';
import { toast } from 'react-toastify';
import { StringResources } from '../../strings/StringManager';

export default class SharePatientLinkPopup extends React.Component {

    constructor(props) {
        super(props);
        this.recipientField = React.createRef(); 

        this.state = ({
            to: '',
            show: false,
            type: "Text Message",
            toTitle: "Phone Number",
            toType: "tel",
            showSpinner: false
        });

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSendLink = this.handleSendLink.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    handleClose() {
      this.setState({
        show: false
      });
    }

    handleShow() {
      this.setState({
        show: true
      });
    }

    handleSendLink() {
      this.setState({
        showSpinner: true
      });

      if (this.state.type === "Email") {
        SendManager.sendEmail(this.state.to, this.props.link, result => {
          this.setState({
            show:false,
            showSpinner: false
          });
        });
      }
      else {
        var text = this.state.to
        text = text.replace(/[^0-9]/g, '')
        SendManager.sendSms(text, this.props.link, result => {
          if(result.valid === false){
            this.setState({showSpinner: false, message: result.message, error: true})
          }
          else{
            toast('Message sent')
            SendManager.subSms(this.props.room.facilityId, this.props.room.urlCode, text, (result) => {
              if(result.statusCode === 500){
                toast("Failed to subscribe number to announcements")
              }
              else{
                toast('Number subscribed to announcements')
              }
              //do nothing
            })
            this.setState({
              show: false,
              showSpinner: false,
              error: false, 
              message: null
            });
          }
        });
      }
    }

    handleTypeChange(event) {
        var type = event.target.value;
        var title = "";
        var toType = "";

        if (type === "Text Message") {  
          title = "Phone Number";
          toType = "tel";
        }
        else if (type === "Email") { 
          title = "Email Address";
          toType = "email";
        }

        this.setState({
          to: ''
        })
        this.recipientField.current.value = "";

        this.setState({
            type: type,
            toTitle: title,
            toType: toType
        });
    }

    handleTextChange() {
      if(this.recipientField.current.value === this.state.to) return
      this.setState({
        error: false,
        to: this.recipientField.current.value
      })
    }

    componentWillMount() {
        
     }

    componentDidMount() {
        
    }

    render() {
        return (
            <>
              <Button className="ml-3" style={{"background-color": "rgb(0, 196, 216)", border: "0px"}} size="sm" variant="primary" onClick={this.handleShow}>
                Share Link
              </Button>
        
              <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Share {StringResources.patient()} Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Row className="mb-3">
                    <Form.Group as={Col} controlId="formShareType">
                      <Form.Label>Delivery Method</Form.Label>
                      <Form.Control as="select" onChange={this.handleTypeChange.bind(this)}>
                        <option>Text Message</option>
                        {/* <option>Email</option> */}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
      
                  <Form.Row>
                    
                    <Form.Group as={Col} controlId="formShareTo">
                      <Form.Label>{this.state.toTitle}</Form.Label>
                      <Form.Control title="Please use only numbers and dashes. E.g. 123-555-1234" type={this.state.toType} ref={this.recipientField} onChange={() => this.handleTextChange()}/>
                    </Form.Group>
                  </Form.Row>
                  {this.state.error ? [
                    <Form.Row>
                      <Form.Group as={Col} controlId="formError" style={{color: 'red'}}>
                        {this.state.message}
                      </Form.Group>
                    </Form.Row>,
                    <Form.Row>
                      <Form.Group as={Col} controlId="formErrorBottom">
                        Please use only numbers and dashes. E.g. 123-555-1234 OR 1-123-555-1234
                      </Form.Group>
                    </Form.Row>
                  ] : null}
                </Modal.Body>
                <Modal.Footer>
                  <Spinner show={this.state.showSpinner}></Spinner>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Cancel
                  </Button>
                  <Button variant="primary" style={{"background-color": "rgb(0, 196, 216)", border: "0px"}} onClick={this.handleSendLink}>
                    Send
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          );
    }
}