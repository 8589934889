export default class RoutingUtil{
    static getQueryValue(key){
        var queries = RoutingUtil.getQueries()
        return queries[key]
    }

    static getQueries(){
        var queries = {}
        var parts = window.location.search.split(/[?&]+/)
        parts.forEach((part)=>{
            if(!part) return
            var paramValueSet = part.split('=')
            queries[paramValueSet[0]] = paramValueSet.length === 2 ? paramValueSet[1] : true
        })
        return queries
    }
}