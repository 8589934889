import React from 'react'
import './sdCheckBox.css'

export default class SDCheckBox extends React.Component{
    render(){
        return (
            <span className={`sd-check-container${this.props.disabled?' disabled':''}`}>
                <input className="sd-check" type='checkbox' onChange={(e)=>{this.props.onCheckChanged(e.target.checked)}} checked={this.props.checked} disabled={this.props.disabled}/>
                <span className={
                    this.props.disabled ? `sd-check-label disabled` : `sd-check-label`
                }>{this.props.label}</span>
            </span>
        )
    }
}