import React from 'react';
import Colors from '../util/Colors';
import CalendarUtil from '../util/CalendarUtil';
import DatePicker from "../react-datepicker/src";
import './DateSwitcher.css'

export default class DateSwitcher extends React.Component{

    static MONTH = "month"
    static DAY = "day"
    static WEEK = "week"

    render(){
        var arrowSide = this.props.arrowSide != null ? this.props.arrowSide : 300
        var nextEnabled = this.props.nextEnabled != null ? this.props.nextEnabled : true

        var previousEnabled = this.props.previousEnabled != null ? this.props.previousEnabled : true
        var button = this.drawButton()


        return(
            <div style={{position: 'relative', top: 4, display: 'flex', justifyContent: 'center', marginBottom: 2}}>
            <div style={{visibility: previousEnabled ? "visible" : "hidden", position:"absolute", marginRight: arrowSide, top: -7, fontSize:24, color:this.props.disabled?"#808080":Colors.Primary.Main}}>
                <a onClick={this.props.disabled ? null : this.props.onPrevious} style={{cursor:"pointer", userSelect:"none"}}>
                &lt;
                </a>
            </div>

            <div style={{visibility: nextEnabled ? "visible" : "hidden", position:"absolute", marginLeft: arrowSide, top: -7, fontSize:24, color:this.props.disabled?"#808080":Colors.Primary.Main}}>
                <a onClick={this.props.disabled ? null : this.props.onNext} style={{cursor:"pointer", userSelect:"none"}}>
                &gt;
                </a>
            </div>

            <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{color: this.props.disabled?"#808080":Colors.Primary.Dark}}>
                {this.props.label}
            </div>
            {button}
            </div>
            </div>

        )
    }

    drawButton(){
        var marginLeft = 8

        return(
            <DatePicker
            disabled={this.props.disabled}
            highlightSelected={this.props.highlightSelected == null ? true : this.props.highlightSelected}
            highlightDates={this.props.highlightDates}
            selected={this.props.anchorDate}
            showMonthYearPicker={this.props.mode === DateSwitcher.MONTH}
            showFullMonthYearPicker={this.props.mode === DateSwitcher.MONTH}
            onChange={this.props.onChange}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            monthsShown={this.props.monthsShown}
            popperClassName="sd-picker"
            customInput={
                <div>
                    <img
                        style={{marginLeft: marginLeft, height: 20}}
                        value={this.props.anchorDate}
                        type="image"
                        src={require("../img/Icons/AgendaBlue.png")}
                    />
                </div>
            }
            />
        )
    }



}