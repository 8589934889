import React from 'react'
import { toast } from 'react-toastify';
import Queries from '../../managers/Queries';
import DateSelectPage from '../../ui/DateSelectPage';
import CalendarUtil from '../../util/CalendarUtil';
import Colors from '../../util/Colors';
import ExportToExcelSheet from '../../util/ExportToExcelSheet';
import LoadingSpinner from '../../util/LoadingSpinner';
import {AppointmentRepository} from "../../repositories/AppointmentRepository";

export default class ExportFacilitySchedules extends React.Component{
    /**
     * @type {AppointmentRepository}
     */
    repo = null
    constructor(props){
        super(props)
        window.scrollTo(0, 0)
        document.body.style = 'background:' + Colors.Secondary.Light + ';';
        this.repo = new AppointmentRepository(this.props.facility.id)
        this.state = {
            selectedDate: new Date()
        }
    }

    render(){
        if(this.state.loading){
            return <LoadingSpinner/>
        }
        return <DateSelectPage
            date={this.state.selectedDate}
            title="Select a date to export"
            canDisplayNotes={false}
            canDisplayEmptyTimes={true}
            submitTitle="Export"
            onSubmit={this.onExport}
            onCancel={this.onBackPressed}/>
    }

    onExport = (date, _, showEmptyTimes)=> {
        //showNotes is ignored, so is _
        this.setState({
            selectedDate: date,
            showEmptyTimes: showEmptyTimes
        }, ()=>{
            this.doExport()
        })
    }

    onBackPressed = () => {
        this.props.machine.send("HOME")
    }

    setStatePromisified = (state) => {
        return new Promise((resolve)=>{
            this.setState(state, () => {
                resolve()
            })
        })
    }
    
    async doExport(){
        await this.setStatePromisified({loading: true})
        try{
            let date = this.state.selectedDate
            let showEmptyTimes = this.state.showEmptyTimes
            let dateRange = CalendarUtil.getDayTimeRange(date)
            let appointmentQueryParams = Queries.getAppointmentQueryParams(dateRange, this.props.facility.id, this.props.facility.id)
            let appointmentsQueryResult = await this.repo.getAppointments(appointmentQueryParams)
            if(!appointmentsQueryResult.appointments){
                toast(`We couldn't find any appointments for ${date.toDateString()}. Please contact support@skilledday.com if you believe this is an error.`, {
                    autoClose: false
                })
            }
            let appointments = appointmentsQueryResult.appointments
            appointments = appointments.filter((appt)=>{
                return !appt.incomplete
            })
            let dateObject = CalendarUtil.getDate(date, false)
            let formatNumber = (num)=>{
                return num.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
            }
            let readableDate = `${dateObject.year}-${formatNumber(dateObject.month)}-${formatNumber(dateObject.dayOfMonth)}`
            let title = `SkilledDay-Schedule-${readableDate}`
            ExportToExcelSheet.exportExcel(title, appointments, this.props.facility.providers, this.props.facility, date, this.state.showEmptyTimes)
            toast(`Exported as ${title}.xlsx`)
        }
        catch(error){
            console.error(error)
            toast(`An error occurred while exporting`)
        }
        await this.setStatePromisified({loading: false})
    }
}