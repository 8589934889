import React from 'react';
import ReactDOM from 'react-dom'
import defaultStyles from './ErrorIndicator.module.css'
const alertIcon = require('../img/Icons/alert.png')


export default class ErrorIndicator extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            renderReason: false
        }
    }

    componentWillUnmount() {
        this.removeClickListener()
    }

    clickListener = (event) => {
        const domNode = ReactDOM.findDOMNode(this)
        if(!domNode || !domNode.contains(event.target)){
            this.setState({renderReason: false})
            this.removeClickListener()
        }
    }

    render(){
        let style = this.props.styles ?? defaultStyles
        var reasonDropdown = this.props.reason && (this.state.renderReason || this.state.renderReasonHover) ? (
            <div
                className={style.reasonDropdown}>
                {this.props.reason}
            </div>
        ) : null

        let className
        if(this.props.visible){
            className = this.props.reason ? style.alertIconSelectable : style.alertIcon
        }
        else{
            className = style.alertIconHidden
        }
        return (
            <div>
                <img
                className={className}
                src={alertIcon}
                onClick={this.props.reason?this.onClick:null}
                onMouseEnter={()=>{
                    this.setState({renderReasonHover: true})
                }}
                onMouseLeave={()=>{
                    this.setState({renderReasonHover: false})
                }}/>
                {reasonDropdown}
            </div>
        )
    }

    onClick = () => {
        this.removeClickListener()
        this.addClickListener()
        this.setState({renderReason: true})
    }

    addClickListener = ()=> {
        document.addEventListener('click', this.clickListener, true)
    }

    removeClickListener = ()=> {
        document.removeEventListener('click', this.clickListener, true)
    }
}