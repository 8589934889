export const ManageServicesMachine = {
    initial: 'Load',
    states: {
        Load: {
            on: {
                RESOLVE: 'ListServices',
                REJECT: 'stop'
            }
        },
        ListServices: {
            on: {
                CREATE: 'CreateService',
                EDIT: 'EditService',
                CANCEL: 'stop'
            }
        },
        CreateService: { //Same as edit service, but need to differentiate between them for the title
            on: {
                DELETE: 'ListServices',
                SAVE: 'ListServices',
                CANCEL: 'ListServices'
            }
        },
        EditService: {
            on: {
                EDIT_PRIMARIES: 'PickUsers',
                DELETE: 'ListServices',
                SAVE: 'ListServices',
                CANCEL: 'ListServices',
                EDIT_ALTERNATE_TITLES: 'EditTitles'
            }
        },
        EditTitles: {
            on: {
                CANCEL: 'EditService',
                NEXT: 'EditService'
            }
        },
        PickUsers: {
            on: {
                CANCEL: 'EditService',
                NEXT: 'EditService'
            }
        },
        stop: {}
    }
}