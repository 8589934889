import React from 'react';
import Colors from '../../util/Colors'
import {Table} from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import FacilityManager from './../../managers/FacilityManager'
import Tracker from '../../managers/Tracker'
import LoadingSpinner from '../../util/LoadingSpinner';
import Helpers from '../../util/Helpers';

export default class EvaluationForm extends React.Component {
    constructor(props) {
        super(props)
        Tracker.logScreenView('patient_evaluation_duration_form')

        const services = []
        Helpers.sortServices(props.facility.services, true, false).forEach(e => {
            if (e.evaluation) services.push(e)
        })

        this.state = {
            loading: false,
            services: services,
            durationDict: {}
        }
    }

    cancel = () => {
        this.props.machine.send("CANCEL")
    }

    submit = () => {

        this.setState({
            loading: true
        })

        FacilityManager.updateOccupancyEvaluation(this.props.facility.id, this.props.selectedRoom.id, this.state.durationDict, data => {
            this.props.onSubmit()
            this.props.machine.send("CANCEL")
        })

    }

    componentDidMount() {

        const durationDict = {}

        this.state.services.forEach(e => {
            const dur = this.props.selectedRoom.services[e.id]
            if (dur) {
                document.getElementById(e.name).value = dur
                durationDict[e.id] = dur
            }
        })

        this.setState({
            durationDict: durationDict
        })
    }

    drawServiceRows() {
        return this.state.services.map(e => {
            return <>
                <tr><ServiceRow onChange={this.onChange} label={e.name}/></tr>
                <tr>
                    <div style={{height: "1px"}}/>
                </tr>
            </>
        })
    }

    onChange = (label, duration) => {

        this.state.services.forEach(e => {
            if (e.name === label) {
                const durationDict = this.state.durationDict
                durationDict[e.id] = duration
                this.setState({
                    durationDict: durationDict
                })
            }
        })

    }

    render() {

        if (this.state.loading) return <LoadingSpinner/>
        return (
            <div align="center">

                <div align="center"
                     style={{backgroundColor: Colors.NavGray, width: "250px", padding: "10px", marginTop: "25px"}}>
                    <div>{this.props.selectedRoom.name} - {this.props.selectedRoom.identifier}</div>
                </div>

                <div align="center" style={{textAlign: "left", width: "500px", margin: "auto"}}>
                    <Table borderless={true}>
                        <tr style={{fontSize: "12px"}}>
                            <th>Service Type</th>
                            <th>Duration</th>
                        </tr>

                        {this.drawServiceRows()}

                    </Table>
                </div>

                <div style={{position: "absolute", bottom: "80px", right: "80px"}}>
                    <Button variant="link" onClick={this.cancel} style={{color: Colors.Primary.Main}}>Cancel</Button>
                    <Button variant="primary" onClick={this.submit}
                            style={{backgroundColor: Colors.Primary.Main, border: "0px"}}>Submit</Button>
                </div>
            </div>
        )
    }

}

class ServiceRow extends React.Component {
    render() {
        return (
            <>
                <td style={{paddingTop: "18px", backgroundColor: Colors.NavGray, width: "300px", height: "60px"}}>
                    {this.props.label}
                </td>

                <td style={{paddingTop: "18px", width: "73px", height: "60px"}}>

                    <input onChange={(e) => this.props.onChange(this.props.label, e.target.value)} id={this.props.label}
                           placeHolder="Minutes" style={{
                        fontSize: "12px",
                        textAlign: "center",
                        float: "left",
                        backgroundColor: Colors.NavGray,
                        width: "80px",
                        height: "40px"
                    }}>

                    </input>


                    <br/>
                </td>
            </>
        )
    }
}
  