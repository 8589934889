export const AgendaMachine = {
    initial: 'Load',
    states: {
        Load: {
            on: {
                RESOLVE: 'ShowAgenda'
            }
        },
        ShowAgenda: {
            on: {
                PRINT: 'PrintAgenda',
                SELECT_PROVIDER: 'SelectProvider'
            }
        },
        SelectProvider: {
            on: {
                CANCEL: 'ShowAgenda',
                NEXT: 'ShowAgenda'
            }
        },
        PrintAgenda: {
            on: {
                FINISH: 'ShowAgenda'
            }
        },
        stop: {}
    }
}