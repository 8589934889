import React from 'react';
import LoadingSpinner from '../../util/LoadingSpinner';
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager';
import TimeRestrictionCalendar from '../../Calendars/TimeRestrictionCalendar';
import {ScheduleColumnImpl} from "../../models/ScheduleColumn";
import {ScheduleDictionaryHelpers} from "../../models/ScheduleDictionary";

export default class TimeRestrictionsPage extends React.Component {
    
    constructor(props){
        
        super(props)

        this.state = {
            loading: true,
            times: [],
            timeZone: props.facility.timeZone,
            errors: [],
            days: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
            removedRestrictions: []
        }
        this.getRestrictedTimes()
    }

    getRestrictedTimes(){
        this.setState({loading: true})
        LegacyAppointmentManager.getTimeRestrictions(this.props.facility.id, undefined, undefined, (response)=>{
            this.setState({loading: false, times: response})
        })
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>
        return this.renderEditTimeRestriction()
    }

    renderEditTimeRestriction = () => {
        var d = new Date()
        d.setDate((d.getDate() - d.getDay())+7)
        const schedule = this.buildSchedule(this.state.times, d);

        return (<TimeRestrictionCalendar 
            timeRestrictionPicker="true"
            machine={this.props.machine} 
            anchorDate={d} 
            facility={this.props.facility} 
            hideBackArrow={true} 
            hideForwardArrow={true}
            hideDateTime={true}
            stagedDeletions={[]} //Not using. Page refreshes when deleted
            unstagedAppointments={[]} //Not using. Page refreshes when deleted
            stagedAppointments={[]} //Not using. Page refreshes when added
            appointment={{}}
            columns={7}
            schedule={schedule}
            stateMachineName={"AdminPanel"}
            onAppointmentStaged={this.onAppointmentStaged}
            onDelete={this.onDelete}/>)
    }

    getDayIndex = (dayOfWeekString) => {
        return this.state.days.indexOf(dayOfWeekString)
    }

    buildSchedule = (times, anchorDate) => {

        /**
         * @type {ScheduleDictionary}
         */
        const scheduleDict = {}

        Object.keys(times).forEach((key)=>{
            const timeBlock = this.state.times[key];
            this.state.days.forEach(day => {
                let dayIndex = this.getDayIndex(day)
                let scheduleItem = scheduleDict[dayIndex] ?? new ScheduleColumnImpl(dayIndex, day)
                let appt = this.generateAppointment(timeBlock, anchorDate, day, key)
                scheduleItem.pushAppointmentModel(appt)
                scheduleDict[dayIndex] = scheduleItem
            })
        })

        /**
         * @type {ScheduleColumn[]}
         */
        const schedule = ScheduleDictionaryHelpers.getSortedScheduleColumns(scheduleDict);
        console.log(schedule)
        return schedule
    }
    /**
     * Generate an appointment model for the calendar
     * @param timeBlock
     * @param anchorDate
     * @param day
     * @param key
     * @returns {AppointmentModel}
     */
    generateAppointment = (timeBlock, anchorDate, day, key) => {
        const singleTimeSlot = timeBlock.schedule[day];
        //convert times to dates: anchorDate+day+time
        const startDate = this.convertTime(new Date(singleTimeSlot.start), anchorDate, day);
        const endDate = this.convertTime(new Date(singleTimeSlot.end), anchorDate, day);
        console.log(`generateAppointment start: ${singleTimeSlot.start}=>${startDate}`)

        console.log(startDate)
        // we know that data is missing, but we are not using it
        // eslint-disable-next-line no-unused-vars
        return {
            id: timeBlock.id, //restriction id
            key: key, //index
            day: day, //restriction day, in the specific ID. eg. sunday, monday, tuesday
            title: timeBlock.title,
            start: startDate,
            end: endDate,
        }
    }

    /**
     * Convert time from a day based time to an epoch time
     */
    convertTime = (date, anchorDate, dayOfWeek) => {
        //{date} is seen as UTC, but is actually in the local timezone, so we need to treat UTC as local time for this date

        const newDate = new Date(anchorDate);
        newDate.setHours(date.getUTCHours()) 
        newDate.setMinutes(date.getUTCMinutes())
        newDate.setDate(newDate.getDate()+this.getDayIndex(dayOfWeek))
        return newDate
    }
    
    onAppointmentStaged = (data) => {
        let timeToRemove;
        if(data.id){
            for (const time of this.state.times) {
                if (time.id !== data.id) {
                    continue;
                }
                timeToRemove = time;
                break;
            }
            this.state.times.splice(this.state.times.indexOf(timeToRemove), 1)
        }
        this.addRestriction(data)
    }

    onDelete = (data) => {
        let timeToRemove;
        for (const time of this.state.times) {
            if (time.id !== data.appointmentId) {
                continue;
            }
            timeToRemove = time;
            break;
        }

        if(timeToRemove){
            this.state.removedRestrictions.push(timeToRemove)
            this.state.times.splice(this.state.times.indexOf(timeToRemove), 1)
        }
        this.onSubmit()
    }

    addRestriction = (data) => {
        var startTime = new Date(data.start)
        var endTime = new Date(data.end)

        var startHours = startTime.getHours()
        var startMinutes = startTime.getMinutes()

        var endHours = endTime.getHours()
        var endMinutes = endTime.getMinutes()

        var dailyStartTime = startHours * 60 * 60 * 1000 + startMinutes * 60 * 1000
        var dailyEndTime = endHours * 60 * 60 * 1000 + endMinutes * 60 * 1000
        
        var schedule = {}
        this.state.days.forEach(e => {
            schedule[e] = {}
            schedule[e].start = dailyStartTime
            schedule[e].end = dailyEndTime
        })

        var newRestriction = {
            id: data.id,
            facilityId: this.props.facility.id,
            schedule: schedule,
            timeZone: this.props.facility.timezone,
            title: data.title
        }

        var times = this.state.times
        times.push(newRestriction)

        this.setState({
            times: times
        }, ()=>{this.onSubmit()})
    }

    onSubmit = () => {
        this.setState({
            loading: true
        })

        LegacyAppointmentManager.updateTimeRestrictions(this.props.facility.id, this.state.times, this.state.removedRestrictions, (data) => {
            this.setState({
                loading: false
            }, () => this.getRestrictedTimes())
        })
    }
}