import React from 'react';
import { Table, Container, Row, Col, Button } from 'react-bootstrap'
import Colors from './Colors'
import CalendarUtil from './CalendarUtil'
import UserImg from '../img/Icons/no-pic.png'
import FacilityManager from '../managers/FacilityManager';
import FacilityUtil from './FacilityUtil';
import { StringFlags, StringResources } from '../strings/StringManager';
import Helpers from './Helpers';
import AppointmentUtil from "./AppointmentUtil";

export default class AgendaCard extends React.Component {

    static style = {
        title: 12,
        locationName: 16,
        providers: 12,
        providerTitle: 12,
        occupant: 12,
        occupantTitle: 12,
        notes: 12,
        time: 12,
        appointmentName: 12,
        marginTop: 50,
        timesMarginTop: 18
    }

    /**
     * Get the current style of most elements. This is safe to modify as is, as it will copy our current settings
     */
    static getStyle(){
        return Object.assign({}, AgendaCard.style)
    }

    /**
     *
     * @param props {{[key: string]:any, item: AppointmentModel}}
     */
    constructor(props) {
        super(props)
        const start = props.item.start.getTime();
        const midnightDate = new Date(props.anchor);
        midnightDate.setHours(0, 0, 0, 0);
        const isAllDay = new Date(start).getTime() === midnightDate.getTime();
        this.state = {
            isAllDay: isAllDay
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    onResize = () => this.forceUpdate()

    getCurrentStyle = () => {
        return this.props.overrideStyle ? this.props.overrideStyle : AgendaCard.style
    }

    getStartY() {
        var extraOffset = this.props.showDropDown ? 30 : 0
        extraOffset += this.props.patient != null ? 48 : 0
        return 143 + extraOffset
    }

    renderLine() {

        if (this.props.printMode || this.props.index === this.props.size - 1) return null

        const w = 1.5;

        const lineStyle = {
            borderLeft: w + "px solid " + Colors.Primary.Main,
            height: this.props.yOffset,
            left: window.innerWidth / 2 - 290 - w / 2,
            top: this.getStartY() + this.props.yCoord,
            position: "absolute"
        }

        return <div style={lineStyle} />
    }

    renderDoneMarker() {
        if (this.props.printMode) return null

        const now = new Date()

        const then = new Date(this.props.item.start)

        const style = {
            position: "absolute",
            top: this.getStartY() + this.props.yCoord,
            left: window.innerWidth / 2 - 300,
            width: "20px",
            height: "20px",
            borderRadius: "25px",
            background: now > then ? Colors.Primary.Main : "#ffffff",
            border: "1px solid " + Colors.Primary.Main
        };

        return (
            <div style={style} />
        )
    }

    renderTimes() {
        /**
         * @type {AppointmentModel}
         */
        const item = this.props.item
        const start = item.start.getTime();
        const end = item.end.getTime();

        const duration = Math.round((end - start) / 60 / 1000);
        const time = CalendarUtil.getShortTime(new Date(start));

        return this.state.isAllDay ? (
            <>
                <Row className="justify-content-md-center">
                    <span style={{ fontSize: this.getCurrentStyle().time }}>All Day</span>
                </Row>
            </>
        ) : (
                <>
                    <Row className="justify-content-md-center">

                        <span style={{ fontSize: this.getCurrentStyle().time }}>{time}</span>

                    </Row>
                    <Row className="justify-content-md-center">
                        <span style={{ fontSize: this.getCurrentStyle().time }}>{duration} min</span>
                    </Row>
                </>
            )
    }

    renderUser() {
        if (this.props.item.user == null) return null

        return (
            <div style={{ float: "right", paddingRight: "20px", paddingTop: "5px", fontSize: 12 }}>
                <img src={UserImg} height="25px" width="25px" />
                <br />
                {this.props.item.user.name}
            </div>
        )
    }

    getCardStyle(color) {
        return {
            width: this.props.printMode ? 800 : 400,
            background: this.props.printMode ? "#ffffff" : color,
            align: "center"
        }
    }

    renderBody() {

        let color = Colors.NavGray;
        /**
         * @type {AppointmentModel}
         */
        const item = this.props.item
        const sid = item.serviceId; //could be undefined

        for (const e of this.props.services) {
            if (e.id === sid && e.color != null) {
                color = e.color
                break;
            }
        }

        const container = this.props.pendingCancellation ? (
            <Container className="p-2" style={this.getCardStyle(color)}>
                <Col xs="auto" sm="auto" md="auto">
                    <div>
                        Delete appointment?
                        <Button variant="danger" onClick={() => this.props.onConfirmDelete(this.props.item.id)}
                                style={{marginLeft: 10, marginRight: 10}}>Delete</Button>
                        <Button variant="primary" onClick={() => this.props.onCancelDelete(this.props.item.id)}
                                style={{backgroundColor: Colors.Primary.Main, border: "0px"}}>Cancel</Button>
                    </div>
                </Col>
            </Container>

        ) : (
            <Container className="p-2" style={this.getCardStyle(color)}>
                <Row>
                    <Col>
                        {this.renderTitle()}
                        {this.renderNames()}
                        {this.renderNotes()}
                        {this.renderLinkedAppointmentInfo()}
                    </Col>
                    {this.renderCardIndicators()}
                    {this.renderCardIcons()}
                </Row>
            </Container>
        );
        return (
            <Container>
                <Row>
                    <Col xs="2" style={{ marginTop: this.getCurrentStyle().timesMarginTop }}>
                        {this.renderTimes()}
                    </Col>
                    <Col xs="10">
                        {container}
                    </Col>
                </Row>
            </Container>
        )
    }

    onEditClicked = (appt) => {
        this.props.showLoadingSpinner()

        FacilityManager.getRoom(this.props.facility.id, appt.roomId, this.props.urlCode, roomData => {
            
            if (roomData == {} || roomData == null || Object.keys(roomData).length == 0) return
            
            appt.roomData = roomData["services"]
            this.props.machine.send("HOME")
            this.props.onEditAppointment(appt)
        })
    }

    renderNotes() {
        if (!this.props.item.notes) return null

        return (
            <Row>
                <Col xs="auto" sm="auto" md="auto">
                    <span style={{ fontSize: this.getCurrentStyle().notes, textAlign: "left" }}>{this.props.item.notes}</span>
                </Col>
            </Row>
        )
    }

    renderLinkedAppointmentInfo(){
        /**
         * @type {AppointmentModel}
         */
        let appt = this.props.item
        let linkedInfo = AppointmentUtil.getLinkedAppointmentInfo(appt, this.props.serviceLookup)
        if (!linkedInfo) {
            // no linked appointment
            return null
        }

        let services = linkedInfo.services
        return (
            <Row className="justify-content-md-left">
                <Col xs="auto" sm="auto" md="auto">
                    <span style={{ "fontSize": this.getCurrentStyle().occupantTitle, "fontWeight": "bold" }}>Linked Appointments: </span>
                    <span style={{ "fontSize": this.getCurrentStyle().occupant, "fontWeight": "normal" }}>
                        {services.join(", ")}
                    </span>
                </Col>
            </Row>
        )
    }

    renderCardIndicators() {
        //render in the bottom right corner of the card

        return <div style={{position: "absolute", bottom: 8, right: -16, display: "flex", gap: "8px", zIndex: 20}}>
            <div>
                {this.props.item.require2fer && <img title="Requires a 2fer" alt="Requires a 2fer" src={require('../img/Icons/2fer-white.svg')}/>}
                {this.props.item.isGroupAppointment && <img title="Group Appointment" alt="Group Appointment" src={require('../img/Icons/group-appt-white.svg')}/>}
            </div>
        </div>
    }

    renderCardIcons() {

        var start = new Date(this.props.item.start)
        var now = new Date()
        var isPastAppointment = (start.getTime() < now.getTime() && !CalendarUtil.areDatesTheSameDay(start, now))

        // Meal or the appointment has already passed
        if (!this.props.item.serviceId || isPastAppointment) return null

        //see if this specific service can be edited normally
        var canEditService
        this.props.facility.services.forEach((service)=>{
            if(service.id === this.props.item.serviceId){
                //if service does not have patient or providers
                canEditService = !(service.providerOnly && !service.pickProvider)
            }
        })
        if(!canEditService) return null

        var icons = []
        if (FacilityUtil.canEditAppointments(this.props.user, this.props.facility, this.props.item)) {
            icons.push(
                <img onClick={() => this.props.onDeleteClicked(this.props.item.id)} src={require('../img/Icons/delete.png')} style={{ height: "18px", "cursor": "pointer" }} className="mr-3" alt="Delete" />
            )
            icons.push(
                <img onClick={() => this.onEditClicked(this.props.item)} src={require('../img/Icons/edit.png')} style={{ height: "18px", "cursor": "pointer" }} alt="Edit" />
            )
        }

        return <Col xs="auto" sm="auto" md="auto">
            <div>
                {icons}
            </div>
        </Col>
    }

    renderNames() {
        /**
         * @type {AppointmentModel}
         */
        const item = this.props.item
        if (!item.serviceId) return null;

        const columns = [];

        // If there is no urlCode, the authorized user is looking at their own schedule, so show occupants but not the provider
        if (!this.props.urlCode && item.occupants.length > 0) { //null check on occupancy. Maybe this appointment does not have one
            columns.push(
                <Col xs="auto" sm="auto" md="auto">
                    <span style={{ "fontSize": this.getCurrentStyle().occupantTitle, "fontWeight": "bold" }}>Occupant: </span>
                    <span style={{ "fontSize": this.getCurrentStyle().occupant, "fontWeight": "normal" }}>{AgendaCard.getRoomName(item, true, true)}</span>
                </Col>
            );
        }
        else {
            if (item.providers) {
                const rows = [];
                item.providers.forEach(p => {
                  if(p.id !== this.props.facility.offsiteProviderId) {
                      const provider = this.props.facility.providers[p.id];
                      if(provider)
                        rows.push(`${provider.firstName} ${provider.lastName[0]}.`)
                    //else ignore
                  }
                });
                if(rows.length > 0) {
                  columns.push(
                    <Col xs="auto" sm="auto" md="auto">
                      <span style={{"fontSize": this.getCurrentStyle().providerTitle, "fontWeight": "bold"}}>Providers: </span>
                      <span style={{"fontSize": this.getCurrentStyle().providers, "fontWeight": "normal"}}>{rows.join(", ")}</span>
                    </Col>
                  )
                }
            }
        }

        return (
            <Row className="justify-content-md-left">
                {columns}
            </Row>
        )
    }

    /**
     *
     * @param appointment {AppointmentModel}
     * @param includeIdentifier
     * @param allowMultiple
     * @returns {string|null}
     */
    static getRoomName(appointment, includeIdentifier = true, allowMultiple = false) {
        if(appointment.occupants.length === 0) return null
        let locationName
        for (const occupant of appointment.occupants) {
            if(occupant.roomName && occupant.name){
                if(locationName) locationName += ', '
                else locationName = ""
                locationName += occupant.roomName
                if(includeIdentifier){
                    locationName += " - " + occupant.name
                }
            }
            if(!allowMultiple) break
        }

        if(!locationName){
            if(appointment.occupants.length === 1){
                locationName = `${StringResources.patient(StringFlags.Capitalize)} Room`
            }
            else{
                locationName = `Group Appointment`
            }
        }
        return locationName
    }

    renderTitle() {
        /**
         * @type {AppointmentModel}
         */
        const item = this.props.item

        //For now, we will only support the first location, even though the model allows multiple locations
        let locationAssignee = item.locations.length > 0 ? item.locations[0] : {};

        let locationId = locationAssignee.id
        let locationName = locationAssignee.name;
        const isOffsite = item.isOffsite || (this.props.facility.offsiteLocationId && locationId === this.props.facility.offsiteLocationId);

        let service = Helpers.getService(item.serviceId, this.props.facility.services)
        if(service){
            if (Helpers.shouldShowSingleOccupantAsLocation(item, service)){
                if(this.props.printMode){
                    locationName = null
                }
                else{
                    locationName = AgendaCard.getRoomName(item)
                }
            }
            else if(item.occupants.length > 2){

            }
            else {
                const location = this.props.facility.locations[locationId];
                if (location) locationName = `${location.name}`;
            }
        }
        return <>
            <Row>
                <Col xs="auto" sm="auto" md="auto">
                    <span style={{ fontWeight: "bold", fontSize: this.getCurrentStyle().appointmentName}}>{item.title}{locationName && !isOffsite ? ", " : ""}</span>
                    <span style={{ fontStyle: "italic", fontSize: this.getCurrentStyle().locationName}}>{locationName && !isOffsite ? locationName : ""}</span>
                </Col>
            </Row>
            {item.occupants.length > 1 && <Row>
                <Col xs="auto" sm="auto" md="auto">
                    <span style={{fontWeight: "bold", fontSize: this.getCurrentStyle().providers}}>Group Appointment</span>
                    {item.occupants.map(occupant =>
                        (
                            <span style={{fontSize: this.getCurrentStyle().providers}}>
                                {`, ${occupant.name}`}
                            </span>
                        )
                    )}
                </Col>
            </Row>}
        </>
    }

    render() {
        const lineElements = this.state.isAllDay ? null : (
            <>
                {this.renderLine()}
                {this.renderDoneMarker()}
            </>
        );
        let tableStyle = undefined;
        if(this.props.printMode) tableStyle = {margin: 0}
        return (
            <>
                {lineElements}
                <Table id={this.props.index} borderless={true} style={tableStyle}>
                    <div align="center">
                        <td>{this.renderBody()}</td>
                    </div>
                </Table>
            </>
        )
    }
}