import React from 'react';

import {Button, Modal, Form, Col, Spinner} from 'react-bootstrap'
import ApiUtil from '../../util/ApiUtil'
import Colors from '../../util/Colors'
import RoomDropdown from './RoomDropdown'
import DischargePatientPage from './DischargePatientPage'
import SetStartDatePage from './SetStartDatePage'
import SetIdPage from './SetIdPage'
import FacilityManager from '../../managers/FacilityManager'
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager'
import CalendarUtil from '../../util/CalendarUtil'
import SelectRoomPage from '../ScheduleAppointment/SelectRoomPage';
import LoadingSpinner from '../../util/LoadingSpinner';
import Tracker from '../../managers/Tracker';
import SharePatientLinkPopup from './SharePatientLinkPopup';
import MovePatientPage from './MovePatientPage';
import WeekSchedulePage from './WeekSchedulePage';
import PrimaryProviderSelection from './PrimaryProviderSelection';
import Helpers from '../../util/Helpers';
import { StringFlags, StringResources } from '../../strings/StringManager';
import { toast } from 'react-toastify';
import FacilityUtil from '../../util/FacilityUtil';
import ProviderManager from '../../managers/ProviderManager';
import SelectProviderPage from '../ScheduleAppointment/SelectProviderPage';
// import RenamePatientPage from "./RenamePatientPage"; //TODO skd-771 fix issues with this


export default class ManagePatientsPage extends React.Component {

  constructor(){
    Tracker.logScreenView('manage_patients_room_list')

    super()
    this.state = {
      ready: false, 
      rooms: {},
      selectedRoom: null,
      id: "",
      startDate: null,
      stagedAppointments: [], 
      unstagedAppointments: [],
      stagedDeletions: [],
      newId: null
    }
    this.onRoomSelected = this.onRoomSelected.bind(this)
    this.reload = this.reload.bind(this)

    document.body.style = 'background:' + Colors.Secondary.Light + ';';
  }

  onRoomSelected(room){
    this.setState({
      selectedRoom: room,
      newId: null,
      showShareLinkForm: false
    }, ()=>{
      if(room.occupied) {
        this.props.onSchedulingRoom(room)
        this.props.machine.send("SELECT_OCCUPIED_ROOM")
      }
      else {
        this.props.machine.send("SELECT_EMPTY_ROOM")
      }
    })
  }

  getPatients(){
    //We are getting the rooms directly from the backend in case something changed, instead of grabbing cached data
    FacilityManager.listRooms(this.props.facility.id, null, null, (data) => {
      if(data == null || data.statusCode != null || !Array.isArray(data)) return

      //sort and rename data how we want to use it
      Helpers.sortRooms(data)
      data.forEach(e => {
        e.name = Helpers.getRoomName(e)
      })

      //now set the data to the state, and check if the state machine wants us to go to a specific room
      this.setState({
        rooms: data,
      }, ()=>{
        const roomId = this.props.machine.state.event?.roomId //grab this before we resolve since it will be gone after

        this.props.machine.send("RESOLVE") //get rooms was resolved, next state: ShowRooms

        //now check if we need to go to a specific room
        const selectedRoomFromState = roomId ? data.find(r => {
          return r.id === roomId
        }): undefined
        if(selectedRoomFromState) {
          this.onRoomSelected(selectedRoomFromState) //Go to the room that was selected
        }
        //otherwise onto the list like usual
      })
    })
  }

  //#region Schedule appointment
  onServiceSelected = (service, title) => {
    this.setState({service: service, appointmentTitle: title, selectedProviders: null}, ()=>{
     this.getProviders()
    })
  }

  getProviders(){
    let service = this.state.service

    if(!service.pickProvider || (service.providers && service.providers.length > 0)){ //if no provider can be picked, move on to next screen
      this.props.machine.send("RESOLVE")
      this.props.machine.send("NEXT")
      this.onProviderSelected([]) //send an empty provider array
      return
    }

    var serviceId = service.id

    //if other service - pass all providers
    if(serviceId == this.props.facility.otherServiceId) {
      var providers = []

      Object.keys(this.props.providers).forEach(e => {
        var provider = this.props.providers[e]
        if(ProviderManager.isProviderDisabled(provider)) return //don't add users that have been deactivated
        providers.push(provider)
      })
      serviceId = null
      this.setState({
        providers: providers
      })
      this.props.machine.send("RESOLVE")
      return
    }

    FacilityUtil.getProviderServices(this.props.facility.id, serviceId, true).then((data)=>{
      var providers = []

      Object.keys(this.props.providers).forEach(e => {
        var provider = this.props.providers[e]
        if(ProviderManager.isProviderDisabled(provider)) return //don't add users that have been deactivated
        if(data.providers.includes(provider.id)) providers.push(provider)
      })

      this.setState({
        providers: providers
      })
      this.props.machine.send("RESOLVE")
    }).catch((error)=>{
      this.props.machine.send("REJECT")
      toast(error.message)
    })
  }

  onProviderSelected = (providers)=> {
    this.setState({selectedProviders: providers})

    //We want to create an AppointmentModel object and pass it to the schedule page

    //create a fake appointment object, 30 minutes long
    let start = new Date(Date.now() - 15 * 60 * 1000)
    let end = new Date(Date.now() + 15 * 60 * 1000)
    // id we want a random 8 character string
    let id = Math.random().toString(36).substring(2, 10)

    //this.state.selectedRoom, this.state.service, this.state.title, providers
    /**
     * @type {AppointmentModel}
     */
    let appointment = {
      facilityId: this.state.selectedRoom.facilityId,
      serviceId: this.state.service.id,
      id: id,
      providers: [
        //id, name
        ...providers.map(pId => {
          return {
            id: pId,
            name: pId //let the server resolve the name
          }
        })
      ],
      //just make something up for creatorId
      creatorId: 'fake-creator-id',
      start: start,
      end: end,
      title: this.state.appointmentTitle,
      description: "",
      notes: "",
      locations: [],
      occupants: [
        //id, name, roomId, roomName
        {
          id: this.state.selectedRoom.occupancyId,
          name: this.state.selectedRoom.identifier,
          roomId: this.state.selectedRoom.id,
          roomName: this.state.selectedRoom.name
        }
      ],
      isGroupAppointment: false,
      linkedAppointments: [],
      isRecurring: false,
      recurrence: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      //all done!
    }

    //attach the current state to the appointment
    // noinspection JSUndefinedPropertyAssignment
    appointment.previousState = {
      name: "SELECT_MANAGE_PATIENTS",
      data: {
        roomId: this.state.selectedRoom.id
      }
    }

    // now we submit the fake appointment that has the data it needs to navigate to the proper schedule page
    //First we need to call the state machine to get us back home
    this.props.machine.send("HOME")

    //App.js onEditAppointment
    this.props.onEditAppointment(appointment)
  }
  //#endregion
  
  onIdSet=(id)=>{
    var roomWithId = this.state.selectedRoom
    roomWithId.identifier = id
    this.props.onSchedulingRoom(roomWithId)
    this.setState({
      selectedRoom: roomWithId,
      id: id
    })
  }

  onSetStartDate=(startDate)=>{
    console.log("qqq1 " + startDate);
    this.setState({
      startDate: startDate,
      loading: true
    }, () => {
      FacilityManager.addRoomOccupancy(this.props.facility.id, this.state.selectedRoom.id, this.state.id, {}, startDate.getTime(), (data) =>{
        console.log("qqq2 " + data);
        console.log("qqq3 " + JSON.stringify(data));
        this.getPatients()
        this.props.machine.send("OCCUPANCY_ADDED")
        this.setState({
          loading: false
        }, ()=>{
          this.setState({
            newId: this.state.selectedRoom.id,
          })
        })
      })
    })
  }

  onSetDischargeDate=(endDate)=>{
    //endDate already in UTC
    endDate = new Date(CalendarUtil.getDayTimeRange(endDate).start)
    this.setState({
      endDate: endDate
    }, ()=>{
      this.props.machine.send("NEXT")
    })
  }

  componentDidMount(){
    this.reload()
    this.setState({
      showShareLinkForm: false
    })
  }

  reload(){
    this.getPatients()
  }
  
  cancel = () => {
    this.props.machine.send("CANCEL")
  }

  dischargePatient = () => {
    this.setState({loading: true})
    FacilityManager.removeRoomOccupancy(this.props.facility.id, this.state.selectedRoom.id, this.state.endDate, (data) =>{
      this.props.machine.send("CONFIRM_DISCHARGE")
      this.setState({loading: false}, ()=>{
        this.reload()
      })
    })
  }

  movePatient = (newRoomId) => {
    FacilityManager.moveRoomOccupancy(this.props.facility.id, this.state.selectedRoom.id, newRoomId, (data) =>{
      this.setState({newId: newRoomId})
      this.reload()
    })
  }

  /* TODO skd-771 fix issues with this
  renamePatient = (newName) => {
    this.props.machine.send("CONFIRM") //Sets the state as loading during the call, and then regardless of the result refresh the page
    FacilityManager.renameOccupant(this.state.selectedRoom, newName, (data) =>{
      this.reload()
    }, (error) => {
      this.reload()
    })
  }*/

  render(){
    const state = this.props.machine.state.value["ManagePatients"]
    
    if(this.state.loading) return <LoadingSpinner/>

    switch(state){
      case "Load": 
      return <LoadingSpinner/>

      case "ShowRooms":
      return(
        <SelectRoomPage services={this.props.services} newId={this.state.newId} showLabels={true} rooms={this.state.rooms} machine={this.props.machine} onRoomSelected={this.onRoomSelected}/>
      )

      case "SelectOccupiedRoom":
      return(
        <div>
          <OccupiedRoomOptions onServiceSelected={this.onServiceSelected} services={this.props.services} machine={this.props.machine} selectedRoom={this.state.selectedRoom} onEditAppointment={this.props.onEditAppointment}/>
        </div>
      )

      case "NewPatient":
      return(
        <div>
          <SetIdPage machine={this.props.machine} onIdSet={this.onIdSet}/>
        </div>
      )

      case "SetStartDate":

      var startDate = new Date()
      if(this.state.selectedRoom.startDate != null) startDate = new Date(this.state.selectedRoom.startDate)
      return(
        <div>
          <SetStartDatePage machine={this.props.machine} onSetStartDate={this.onSetStartDate} startDate={startDate} isStart={true}/>
        </div>
      )

      case "SetEndDate": 

      var endDate = new Date()
      return(
        <div>
          <SetStartDatePage machine={this.props.machine} onSetStartDate={this.onSetDischargeDate} startDate={endDate} isStart={false}/>
        </div>
      )

      case "Schedule":
      case "SchedulePopup":
        //deprecated behavior, make sure the user knows they were not supposed to see this screen and to report it
        return <div>
          <div style={{textAlign: "center", marginTop: "20px"}} onClick={()=>{
            window.location.href = "/"
          }}>
            <img src="/favicon.ico" style={{width: "50px"}}/>
            <div>Go Home</div>
          </div>
          <div style={{color: Colors.Primary.Dark, fontSize: "20px", marginTop: "20px", textAlign: "center"}}>404 Page Not Found (ManagePatients/{state}): Please report this screen and steps you took to reach this</div>
        </div>
      case "GetProviders":
        return <LoadingSpinner/>
      case "ShowProviders":
        return <SelectProviderPage user={this.props.user} editing={false} machine={this.props.machine} providers={this.state.providers} onSelectProvider={this.onProviderSelected} onCancel={()=>{this.props.machine.send("REJECT")}}/>
      case "AssignProviders":
      case "SelectProvidersForService":
        return (<PrimaryProviderSelection services={this.props.services} machine={this.props.machine} selectedRoom={this.state.selectedRoom} facility={this.props.facility}/>)

      case "DischargePatient":
        return(
          <DischargePatientPage machine={this.props.machine} onDischarge={this.dischargePatient} endDate={this.state.endDate}/>
        )

      case "MovePatient":
        return(
          <MovePatientPage machine={this.props.machine} rooms={this.state.rooms} onMove={this.movePatient}/>
        )

      /*
      TODO skd-771 fix issues with this
        case "RenameOccupancy":
        return(
          <RenamePatientPage identifier={this.state.selectedRoom.identifier} machine={this.props.machine} onRename={this.renamePatient} onCancel={()=>this.props.machine.send('CANCEL')}/>
        )*/

      default:
        return <LoadingSpinner/>
    }
    return null
   }
}
//#region Occupied Room Options
class OccupiedRoomOptions extends React.Component{

  constructor() {
    super()
    Tracker.logScreenView('manage_patients_occupied_room_options')
  }

  scheduleService = (service, title) => {
    this.props.onServiceSelected(service, title)
    this.props.machine.send("SCHEDULE")
    // next states that will happen:
    //  - GetProviders (handled by parent)
    //  - ShowProviders (handled by parent) => onProviderSelected
    //  - Then the user will select a provider and we will go to the schedule page in ScheduleAppointmentPage
    //    via props.onEditAppointment of the parent object
  }

  assignProviders = () => {
    this.props.machine.send("ASSIGN_PROVIDERS")
  }

  discharge = () => {
    this.props.machine.send("DISCHARGE")
  }

  move = () => {
    this.props.machine.send("MOVE")
  }

  /*
  TODO skd-771 fix issues with this
  provideRenameField = () => {
    this.props.machine.send("RENAME_OCCUPANT")
  }*/

  cancel = () => {
    this.props.machine.send("CANCEL")
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render(){
    var baseUrl = "app.skilledday.com"

    var agendaUrl = "/?agenda=" + this.props.selectedRoom.urlCode

    var w = 300
    var m = 15

    return(
      <div align="center">

      <div style={{backgroundColor: Colors.NavGray, width:"500px", padding:"10px", marginTop:"25px"}}>
        <div>{this.props.selectedRoom.name} - {this.props.selectedRoom.identifier}</div>
        <a href={agendaUrl}>{baseUrl + agendaUrl}</a>
        <SharePatientLinkPopup link={baseUrl + agendaUrl} room={this.props.selectedRoom}></SharePatientLinkPopup>
        {this.renderExtraInfo()}
      </div>

      <div style={{width: w, marginTop:"50px"}}>
        {this.renderServiceList()}
        <br/><br/><div style={{background: 'black', width: 250, height: 2}}/>
        
        <Button variant="light" onClick={this.assignProviders} style={{borderColor:"#000000", width: w, marginTop: m}}>Assign Providers</Button><br/>

        <Button variant="light" onClick={this.discharge} style={{borderColor:"#000000", width: w, marginTop: m}}>Discharge {StringResources.patient(StringFlags.Capitalize)}</Button><br/>
        <Button variant="light" onClick={this.move} style={{borderColor:"#000000", width: w, marginTop: m}}>Move {StringResources.patient(StringFlags.Capitalize)}</Button><br/>
        {/*TODO skd-771 fix issues with this <Button variant="light" onClick={this.provideRenameField} style={{borderColor:"#000000", width: w, marginTop: m}}>Rename {StringResources.patient(StringFlags.Capitalize)}</Button><br/>*/}
      </div>
      
      <Button variant="primary" onClick={this.cancel} style={{backgroundColor: Colors.Primary.Main, border: "0px", position:"fixed", bottom:60, right:80}}>Cancel</Button>

      </div>
    )
  }

  renderExtraInfo = () => {
    let notes = []
    if(this.props.selectedRoom.endDate){
      let d = CalendarUtil.getDate(new Date(this.props.selectedRoom.endDate))
      notes.push(
        (
          <div>
            {StringResources.patient(StringFlags.Capitalize)} discharge on {d.dayOfWeekString} {d.dayOfMonth}, {d.monthString} {d.year}
          </div>
        )
      )
    }
    return notes
  }
  
  /**
   * 
   * @returns list of buttons to schedule services
   */
  renderServiceList() {

    var w = 300
    var m = 15

    // this.props.services is
    return Helpers.sortServices(this.props.services, true, false).map((service) => {

      if(!service.schedulable || service.scheduleStyle !== "Week" || service.providerOnly) return null

      let titles = [service.name]
      if(service.alternateTitles){
        service.alternateTitles.forEach((title)=>{
          titles.push(title)
        })
      }

      return titles.map((title)=>{
        return (
            <row>
              <Button variant="light" onClick={() => this.scheduleService(service, title)} style={{borderColor:"#000000", width: w, marginTop: m}}>Schedule {title}</Button>
            </row>
        )
      })
    })
  }

}
//#endregion

class EmptyRoomOptions extends React.Component{

  constructor() {
    super()
    Tracker.logScreenView('manage_patients_empty_room_options')
  }

  add = () =>{
    this.props.machine.send("ADD_NEW_PATIENT")
  }

  render(){
    return(
      <>
      <div align="center" style={{color:Colors.Primary.Dark, paddingTop:"10px", fontSize:"12px"}}>
        No {StringResources.patient()} in selected room
      </div>
      <div align="center" style={{paddingTop:"200px"}}>
       <Button style={{borderColor:"#000000"}} variant="light" onClick={this.add}>ADD</Button>
      </div>
      <div align="center" style={{fontSize:"12px", paddingTop: "10px"}}>
        Click to add a new {StringResources.patient()}
      </div>
        </>

    )
  }
}