export const ManagePatientsMachine = {
    initial: 'Load',
    states: {
        Load: {
            on: {
                RESOLVE: 'ShowRooms'
            }
        },
        ShowRooms: {
            on: {
                SELECT_EMPTY_ROOM: 'NewPatient',
                SELECT_OCCUPIED_ROOM: 'SelectOccupiedRoom'
            }
        },
        SelectEmptyRoom: {
            on: {
                SELECT_EMPTY_ROOM: 'SelectEmptyRoom',
                SELECT_OCCUPIED_ROOM: 'SelectOccupiedRoom',
                ADD_NEW_PATIENT: 'NewPatient'
            }
        },
        NewPatient: {
            on: {
                NEXT: 'SetStartDate',
                CANCEL: 'ShowRooms'
            }
        },
        SelectOccupiedRoom: {
            on: {
                SELECT_EMPTY_ROOM: 'SelectEmptyRoom',
                SELECT_OCCUPIED_ROOM: 'SelectOccupiedRoom',
                ASSIGN_PROVIDERS: 'AssignProviders',
                RENAME_OCCUPANT: 'RenameOccupancy',
                DISCHARGE: 'SetEndDate',
                MOVE: 'MovePatient',
                SCHEDULE: 'GetProviders',
                CANCEL: "ShowRooms"
            }
        },
        RenameOccupancy: {
            on: {
                CONFIRM: 'Load',
                CANCEL: 'SelectOccupiedRoom'
            }
        },
        AssignProviders: {
            on: {
                CONFIRM: 'SelectOccupiedRoom',
                CANCEL: 'SelectOccupiedRoom'
            }
        },
        SetEndDate: {
            on: {
                NEXT: 'DischargePatient',
                CANCEL: 'SelectOccupiedRoom'
            }
        },
        DischargePatient: {
            on: {
                CONFIRM_DISCHARGE: 'Load',
                CANCEL: 'SelectOccupiedRoom'
            }
        },
        MovePatient: {
            on: {
                CONFIRM_MOVE: 'Load',
                CANCEL: 'SelectOccupiedRoom'
            }
        },
        SetStartDate: {
            on: {
                OCCUPANCY_ADDED: 'ShowRooms',
                CANCEL: 'ShowRooms'
            }
        },
        GetProviders: {
            on: {
                RESOLVE: 'ShowProviders',
                REJECT: 'SelectOccupiedRoom'
            }
        },
        ShowProviders: {
            on: {
                NEXT: 'Schedule',
                REJECT: 'SelectOccupiedRoom'
            }
        },
        Schedule: {
            on: {
                FINISH: 'SelectOccupiedRoom',
                CANCEL: 'SelectOccupiedRoom',
                POPUP: 'SchedulePopup'
            }
        },
        SchedulePopup: {
            on: {
                CLOSE: 'Schedule'
            }
        },
        stop: {}
    }
};