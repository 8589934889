import React from 'react';
import FacilityManager from '../managers/FacilityManager';
import Switch from './Switch';
import SDEditTextBox from './SDEditTextBox';
import NavigationHeader from './NavigationHeader';
import './editItem.css'

export default class EditItem extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            isNew: !this.props.item.id,
            errors: [],
            item: Object.assign({}, this.props.item)
        }
    }

    render(){ 
        var disabledField = (
            <div className="switch-container">
                <div className="switch-text">Enable {this.props.title}</div>
                <Switch disabled={!this.props.canDisable} checked={!this.state.item.disabled} onChange={()=>{
                    var item = this.state.item
                    item.disabled = !item.disabled
                    this.setState({item: item})
                }}/>
            </div>
        )
        return(
            <div>
                <NavigationHeader navigationBackTitle={this.props.navigationBackTitle} navigationOptionsTitles={["Cancel", this.getSaveTitle()]} onNavigateBack={this.onCancel} onNewOption={this.onNavOptionClicked}/>
                <div align='center' className="edit-item-container">
                    <SDEditTextBox disabled={this.state.item.disabled} label="NAME" title={this.state.item.name} onChange={(value)=>{
                        var item = this.state.item
                        item.name = value
                        this.setState({item: item})
                    }}/>
                    {this.state.errors.map((error)=>{
                        return (<div align='center' className='error'>{error}</div> )
                    })}
                    {disabledField}
                    <div className='child-container'>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

    drawHeader(){
        if(this.state.isNew) return <>New {this.props.title}</>
        else return <>Edit an existing {this.props.title}</>
    }

    validate(item){
        return FacilityManager.validateObject(item, ["name"])
    }

    onNavOptionClicked = (option)=> {
        switch(option){
            case 'Cancel':
                this.onCancel()
                break
            case this.getSaveTitle(): 
                this.onSubmit()
                break
        }
    }

    onSubmit = () => {
        var item = this.state.item
        var validation = this.validate(item)
        if(validation.success){
            var result = this.validateNameAvailable(item)
            if(!result){
                validation.success = false
                validation.errors.push("An item with this name already exists")
                this.setState({errors: validation.errors})
                return //exit the function
            }
            this.setState({loading: true, errors: []})
            this.props.onSubmit(item)
        }
        else{
            this.setState({errors: validation.errors})
        }
    }

    onCancel = () => {
        this.props.onCancel()
    }

    validateNameAvailable = (newItem) => {
        var exists = false
        this.props.items.forEach(item => {
            if(item.id === newItem.id) return //next loop
            if(item.name.toLowerCase() === newItem.name.toLowerCase()){
                exists = true
                console.log(item.name)
                return //breaks out of foreach, not function
            }
        });
        return !exists
    }

    getSaveTitle = () => {
        return this.state.isNew ? `Create ${this.props.title}` : "Save"
    }
}