import React from 'react';
import Colors from '../../util/Colors'
import CalendarUtil from '../../util/CalendarUtil'
import LoadingSpinner from '../../util/LoadingSpinner'
import Tracker from '../../managers/Tracker';
import Helpers from '../../util/Helpers';
import FacilityManager from '../../managers/FacilityManager';
import PrintedAgendaPage from '../Agenda/PrintedAgendaPage';
import {AppointmentRepository} from "../../repositories/AppointmentRepository";
import Queries from "../../managers/Queries";
import {toast} from "react-toastify";

export default class PrintAllAgendasPage extends React.Component{
    /**
     * @type {AppointmentRepository}
     */
    repo
    constructor(props){
        super(props)
        this.repo = new AppointmentRepository(this.props.facility.id)
        Tracker.logScreenView('printAgendas')
        this.state={
            roomLookup: {},
            loading: true,
            anchorDate: this.props.anchorDate ? this.props.anchorDate : new Date(),
            patient: null,
            printMode: true,
            agendas: {} //agendas['occupancyId'] = (agendas array)
        }
    
        window.scrollTo(0, 0)
        document.body.style = 'background:' + Colors.Secondary.Light + ';';
        this.getPatients().then(async()=>{
            await this.getAllAgendas()
        })
    }

    getPatients = async() => {
        return new Promise((resolve)=>{
            FacilityManager.listRooms(this.props.facility.id, null, null, (data) => {
                if(data == null || data.statusCode != null || !Array.isArray(data)) return
            
                Helpers.sortRooms(data)
            
                data.forEach(e => {
                    e.name = Helpers.getRoomName(e)
                })
            
                var roomLookup = {}
                data.forEach(room => {
                    roomLookup[room.occupancyId] = room
                });
                this.setState({
                    rooms: data,
                    roomLookup: roomLookup
                }, ()=>{
                    resolve()
                })
            })
        })
    }

    getAllAgendas = async()=>{
        //`loading: true` was already set in constructor

        //retrieve facility schedule from backend, as a promise
        /**
         * @type {AppointmentModel[]}
         */
        const appointments = await this.getAgenda()
        const agendas = {};

        this.state.rooms.forEach((room)=>{ //loop through rooms
            if(!room.occupied) return
            appointments.forEach((appointment)=>{ //loop through appointments. Done multiple times to add meals for each patient
                let occupancyIds = appointment.occupants?.map(occupant => occupant.id) ?? []

                //allow it to add if occupancy IDs match, or appointment has no occupancy (meals/restrictions)
                if(occupancyIds.length > 0 && !occupancyIds.includes(room.occupancyId)) return

                //ignore appointments that do not belong to a room
                if(occupancyIds.length === 0 && appointment.providers && appointment.providers.length > 0) return
                if(this.props.facility.service2ferId && appointment.serviceId === this.props.facility.service2ferId) return //ignore 2fer appointments here
                if(!this.props.showNotes) appointment.notes = undefined
                if(!agendas[room.occupancyId])
                    agendas[room.occupancyId] = []
                agendas[room.occupancyId].push(appointment)
            })
        })

        this.setState(
            {loading: false, agendas: agendas} //set our data
        )
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>
        const state = this.props.machine.state.value["PrintPatientSchedule"];
        switch(state){
            case "ShowPrint":
                //render list of all agendas, which only appear for occupied rooms
                return Object.keys(this.state.agendas).map((occupancyId) => {
                    const agendaItems = this.state.agendas[occupancyId];
                    const patient = this.state.roomLookup[occupancyId];
                    return <PrintedAgendaPage
                        type={PrintedAgendaPage.Type.PATIENT}
                        viewingId={this.state.viewingId}
                        anchor={this.state.anchorDate}
                        roomData={patient}
                        urlCode={patient?.urlCode ?? null}
                        facility={this.props.facility}
                        agendaItems={agendaItems}
                        services={this.props.services}
                        onReady={this.printPages}/> //delay calling print() until the logo is loaded
                })
        }
    }

    async getAgenda(){
        let query = Queries.getAppointmentQueryParams(
            CalendarUtil.getDayTimeRange(this.state.anchorDate),
            this.props.facility.id,
            this.props.facility.id,
            null,
            this.state.urlCode
        )
        return this.repo.getAppointments(query)
            .then(data => {
                let appointments = data.appointments
                if(appointments === {} || appointments == null){
                    console.error("Error loading appointments", data)
                    toast('Error loading appointments', {type: 'error'})
                }

                if(data.code != null || data.error != null) {
                    console.error("Error loading appointments", data)
                    toast('Error loading appointments', {type: 'error'})
                    return
                }

                Helpers.sortObjectByTimes(appointments)
                return appointments
            })
    }

    printPages = ()=> {
        window.print() //open print UI
        //Send event to go back to select rooms page. Will only take effect once print dialog is closed
        this.props.machine.send("DONE")
    }
}