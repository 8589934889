import {ScheduleAppointmentHolder, ScheduleAppointmentHolderImpl} from "./ScheduleAppointmentHolder";
import AppointmentModel from "./AppointmentModel";

export interface ScheduleColumn {
    list(): ScheduleAppointmentHolder[],


    push(item: ScheduleAppointmentHolder): void
    pushAppointmentModel(item: AppointmentModel): void
    remove(item: ScheduleAppointmentHolder): void

    id: string,
    label: string,
}

export class ScheduleColumnImpl implements ScheduleColumn{
    private readonly rawItems: ScheduleAppointmentHolder[]
    id: string
    label: string

    constructor(id: string, label: string) {
        this.rawItems = [] as ScheduleAppointmentHolder[]
        this.id = id
        this.label = label
    }

    push(item: ScheduleAppointmentHolder){
        this.rawItems.push(item)
    }

    pushAppointmentModel(item: AppointmentModel){
        let scheduleItem = new ScheduleAppointmentHolderImpl(item, this.id, this.label)
        this.push(scheduleItem)
    }

    remove(item: ScheduleAppointmentHolder) {
        this.rawItems.splice(this.rawItems.indexOf(item), 1)
    }

    list(): ScheduleAppointmentHolder[]{
        return this.rawItems
    }
}