export const ManageBillingMachine = {
    initial: 'View',
    states: {
        View: {
            on: {
                NEXT: 'stop',
            }
        },
        stop: {

        }
    }
}