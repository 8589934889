import React from 'react';
import { Row, Col, Form, Button, Card, Alert, Modal } from 'react-bootstrap';
import MultiSelect from "@khanacademy/react-multi-select";
import AdminManager from '../../managers/AdminManager';
import LoadingSpinner from '../../util/LoadingSpinner';
import { StringResources } from '../../strings/StringManager';
import DateSwitcher from '../../ui/DateSwitcher';
import CalendarUtil from '../../util/CalendarUtil';

export default class BillingPage extends React.Component {

    constructor(props){
        
        super(props)

        var date = new Date()
        date.setDate(1)

        this.state = {
            today: new Date(),
            anchorDate: date,
            loading: true,
            data: null
        }

        this.loadBillingMonth()
    }

    loadBillingMonth(){
        var date = this.state.anchorDate
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDay.setUTCHours(0)
        firstDay.setUTCMinutes(0)
        firstDay.setUTCSeconds(0)
        firstDay.setUTCMilliseconds(0)

        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lastDay.setUTCHours(23)
        lastDay.setUTCMinutes(59)
        lastDay.setUTCSeconds(59)
        lastDay.setUTCMilliseconds(999)

        AdminManager.getBilling(this.props.facility.id, firstDay.getTime(), lastDay.getTime(), (data) => {
            this.setState({
                loading: false,
                data: data
            })
        })
    }
    
    nextMonth = () => { 
        this.getNewMonth(1)
    }

    prevMonth = () => {
        this.getNewMonth(-1)
    }

    getNewMonth(delta){
        var date = this.state.anchorDate
        date.setMonth(date.getMonth() + delta)
        this.setState({
            anchorDate: date,
            loading: true
        }, () => this.loadBillingMonth())
    }

    canGoForward(){
        let today = this.state.today
        let anchor = this.state.anchorDate
        return !(today.getMonth() == anchor.getMonth() && today.getFullYear() == anchor.getFullYear())
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>

        var style = {
            fontSize:32
        }

        var label = <>
                        {CalendarUtil.getMonthString(this.state.anchorDate.getMonth()) + " " + this.state.anchorDate.getFullYear()}
                    </>
        return (
            <>
            <DateSwitcher
                label={label}
                onChange={this.onChange}
                mode={DateSwitcher.MONTH}
                anchorDate={this.state.anchorDate}
                nextEnabled={this.canGoForward()}
                onNext={this.nextMonth}
                onPrevious={this.prevMonth}
                maxDate={new Date()}
                />

          <div align="center" style={{marginTop:24}}>
            <div style={style}>Rooms used: {this.state.data.uniqueRooms}</div> 
            <div style={style}>{StringResources.patient()} days: {this.state.data.billableDays}</div>
            </div>
            </>
        )
    }

    onChange = (e) => {
        this.setState({
            anchorDate: e,
            loading: true
        }, () => this.loadBillingMonth()) 
    }

}