
import React from 'react';
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager';
import CalendarUtil from '../../util/CalendarUtil';
import LoadingSpinner from '../../util/LoadingSpinner';
import OnboardingCalendar from '../../Calendars/OnboardingCalendar';
import {Button} from 'react-bootstrap'
import Colors from '../../util/Colors'
import Tracker from '../../managers/Tracker';
import Helpers from '../../util/Helpers';
import FacilityUtil from '../../util/FacilityUtil';
import { toast } from 'react-toastify';
import SchedulingCalendar from '../../Calendars/SchedulingCalendar';
import {AppointmentRepository} from "../../repositories/AppointmentRepository";
import Queries from "../../managers/Queries";
import {ScheduleColumnImpl} from "../../models/ScheduleColumn";

export default class WeekSchedulePage extends React.Component{

    constructor(props){
        super(props)
        const serviceLookup = {};
        this.repo = new AppointmentRepository(this.props.facility.id)
        props.facility.services.forEach(service => serviceLookup[service.id] = service);
        this.state={
          anchorDate: new Date(),
          loading: true,
          stagedAppointments: [],
          unstagedAppointments: [],
          stagedDeletions: [],
          services: serviceLookup,
          defaultDuration: SchedulingCalendar.defaultFallbackDuration
        }
        Tracker.logScreenView('manage_patients_schedule_care_conference')
      }

      submit = () => {
        if(this.props.service.fullDay){
          //modify times since the page thinks it is just rendering 30 minutes
          /**
           * @param appointment {AppointmentModel}
           * @returns {*}
           */
          const updateDates = (appointment) => {
            appointment = Object.assign({}, appointment)
            CalendarUtil.getFullDayAppointmentTimes(appointment.start.getTime(), /*out*/ appointment)
            return appointment
          }

          const stagedAppointments = []
          const stagedDeletions = []
          this.state.stagedAppointments.forEach(appointment => {
            stagedAppointments.push(updateDates(appointment))
          });

          this.state.stagedDeletions.forEach(appointment => {
            stagedDeletions.push(updateDates(appointment))
          });

          this.props.onFinish(stagedAppointments, stagedDeletions)
        }
        else{
          this.props.onFinish(this.state.stagedAppointments, this.state.stagedDeletions)
        }
      }

      cancel = () => {
        this.props.onCancel()
      }

    /**
     * @param appt {AppointmentModel}
     * @param prev {AppointmentModel}
     */
      stageAppointment = (appt, prev) => {
      /**
       * @type {AppointmentModel[]}
       */
        const unstaged = this.state.unstagedAppointments
        unstaged.push(prev)

      /**
       * @type {AppointmentModel[]}
       */
        const staged = this.state.stagedAppointments
        if(staged.includes(prev)) staged.splice(staged.indexOf(prev), 1)



        let providerIds = (appt.providers && appt.providers.map(p => p.id)) ?? []
        let startTime = appt.start.getTime?.() ?? appt.start
        let endTime = appt.end.getTime?.() ?? appt.end

        appt.start = new Date(startTime)
        appt.end = new Date(endTime)
        appt.providers = appt.providers ?? []

        staged.push(appt)

        this.setState({
            defaultProviders: providerIds,
            defaultDuration: (endTime-startTime)/60000, //to minutes from milliseconds (1000ms*60s)
            loading: true,
            stagedAppointments: staged,
            unstagedAppointments: unstaged
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    getStateMachineName(){
      return this.props.stateMachineName || "ManagePatients"
    }

    hasSelectedProviders = (providers) => {
      if(!this.props.selectedProviders) return false
      return Helpers.hasSomeMatching(providers, this.props.selectedProviders)
    }

      render(){
        if(this.state.loading) return <LoadingSpinner/>
        const state = this.props.machine.state.value[this.getStateMachineName()]
        let elements = []
        if(this.props.header){
          let header = Object.assign({},this.props.header)
          header.props = Object.assign({},header.props)
          header.props.title = ""
          elements.push(header)
        }
        elements.push(
          <div>
            <OnboardingCalendar
              defaultDuration={this.state.defaultDuration}
              defaultProviders={this.state.defaultProviders}
              stateMachineName={this.getStateMachineName()}
              parent={this}
              title={this.props.title}
              service={this.props.service}
              columns={7}
              anchorDate={this.state.anchorDate}
              services={this.props.services}
              onDelete={this.onDelete}
              providers={this.state.providers} //pass in our providers, filtered by service
              machine={this.props.machine}
              schedule={this.state.schedule}
              selectedRoom={this.props.selectedRoom}
              selectedProviders={this.props.selectedProviders}
              facility={this.props.facility}
              user={this.props.user}
              validate={this.validate}
              loading={this.props.loading}
              stagedDeletions={this.state.stagedDeletions}
              unstagedAppointments={this.state.unstagedAppointments}
              stagedAppointments={this.state.stagedAppointments}
              onAppointmentStaged={this.stageAppointment}
              loadNext={this.loadNext}
              loadPrevious={this.loadPrevious}
              isGroupAppointment={this.props.isGroupAppointment}
              loadDate={this.loadDate}/>
            <div style={{position:"fixed", bottom:0, backgroundColor:"white", width:"100%", height:"54px", zIndex:1}}>
            <div style={{position:"fixed", bottom:"8px", right:"80px"}}>
                <Button variant="link" disabled={state.toLowerCase().includes('popup')} onClick={this.cancel} style={{color:Colors.Primary.Main}}>Cancel</Button>
                <Button variant="primary" disabled={state.toLowerCase().includes('popup')} onClick={this.submit} style={{width:"80px", backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>Submit</Button>
              </div>
              </div>
          </div>
        )
        return elements
      }

      onDelete = (e) => {

        for(let i = 0; i < this.state.stagedAppointments.length; i++){
          let appt = this.state.stagedAppointments[i]
          if(e.startTime === (appt.start.getTime?.() ?? appt.start)){
            this.state.stagedAppointments.splice(i, 1)
            this.setState({
              loading: true,
            }, () => {
                this.setState({
                      loading: false
                })
            })

            return null
          }
        }

        this.state.stagedDeletions.push(e)
        this.setState({
          loading: true,
        }, () => {
            this.setState({
                loading: false
            })
        })
      }

      componentDidMount(){
        const d = this.props.anchorDate ? new Date(this.props.anchorDate) : new Date()
        this.setState({anchorDate: new Date(d.setDate(d.getDate() - d.getDay()))}, ()=>{
            void this.getSchedule(this.state.anchorDate)
        })
      }

      loadPrevious = () => {
        let newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() - 7))
        this.loadDate(newAnchor)
      }

      loadNext = () => {
        let newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() + 7))
        this.loadDate(newAnchor)
      }

      loadDate = (newAnchor) => {
        let weekStart = new Date(CalendarUtil.getWeekTimeRange(newAnchor).start)
        this.setState({
          anchorDate: weekStart
        })
        void this.getSchedule(weekStart)
        this.closePopupIfActive()
      }

      closePopupIfActive = () => {
        const state = this.props.machine.state.value[this.getStateMachineName()]
        if(state.toLowerCase().includes("popup")){
          this.props.machine.send("CLOSE")
        }
      }

      validate = (appt, providers) => {
        let valid = true
        let reason

        //check for duplicates
        const providerIds = []
        if(providers){
          providers.forEach((providerId)=>{
            if(providerIds.includes(providerId)){
              valid = false
              reason = "Appointment cannot have duplicate providers"
            }
            else{
              providerIds.push(providerId)
            }
          })
        }

        if(appt.incomplete){
          if(appt.conflictingProviders){ //If we have a conflicting providers array
            if(providers){ //and we have providers
              console.log(appt.conflictingProviders)
              //make sure none of the new providers are one of the conflicts
              for(let providerIndex = 0; providerIndex < providers.length; providerIndex++){
                let provider = providers[providerIndex]
                console.log(provider)
                if(appt.conflictingProviders.includes(provider)){ //provider overlap
                  valid = false
                  reason = 'Provider conflict'
                }
              }
            }
          }
          else if(appt.conflicts){
            //TODO validate that it changed somehow. For now, don't do anything
            // valid = false
            // reason = 'Appointment conflict'
          }
        }
        return {valid: valid, reason: reason}
      }

      //#region filter appointments
      /**
       *
       * @param appt {AppointmentModel}
       * @returns {boolean|boolean|false}
       */
      shouldRenderAppointment = (appt) => {
        let appointmentService = this.state.services[appt.serviceId]
        let service = this.props.service
        //special case for activity service
        if(appt.serviceId === service.id && appt.serviceId === this.props.facility.activityServiceId){
          return true
        }

        // ignore unassigned appointments
        if (appt.incomplete) return false

        let providers = appt.providers?.map(p => p.id) ?? []
        let occupancyIds = appt.occupants?.map(o => o.id) ?? []

        //provider only, and has provider, and does not have attendee roles
        if(service.providerOnly && service.pickProvider && (!service.providers || service.providers.length === 0)) {
          let allow = false;
          providers.forEach((providerId)=>{
            if(this.props.selectedProviders.includes(providerId)){
              allow = true
            }
          })
          if(appt.serviceId === service.id && service.conflicts){
            allow = true
          }
          if(allow) return true
        }
        if(occupancyIds.length === 0){
          if(!(service.ignoreTimeRestrictions || (
            appointmentService && appointmentService.providerOnly
          )))
          return true
        }
        let hasOccupant = false
        let selectedOccupancyIds = (Array.isArray(this.props.selectedRoom) ? this.props.selectedRoom : [this.props.selectedRoom]).map(room => room.occupancyId)
        occupancyIds.forEach(occupancyId => {
          if(selectedOccupancyIds.includes(occupancyId))
            hasOccupant = true
        })
        return hasOccupant
          || this.hasSelectedProviders(providers)
          || (appt.serviceId === service.id && service.conflicts)
      }
      //#endregion

      getSchedule = async(date) =>{
        this.setState({
          loading: true
        })

        let stateUpdate = {}

        //Fetch providers for just this service from the backend. The dropdowns require this
        let serviceProviders = []
        let serviceProvidersDict = {} //build a dictionary of providers to match the default format
        if(this.props.service.pickProvider){
          serviceProviders = await FacilityUtil.getProviderServices(this.props.facility.id, this.props.service.id, false)
          serviceProviders.providers.forEach((provider)=>{
            serviceProvidersDict[provider.id] = provider
          })
        }

        if(this.props.service.fullDay){ //full day will not pull any appointments. Just set to empty
          this.setState({
            schedule: [],
            loading: false
          })
          return;
        }
        if(!this.state.defaultDurationFetched){
          if(Array.isArray(this.props.selectedRoom)){ //this could be an array
            stateUpdate = Object.assign(stateUpdate, {defaultDuration: 30, defaultDurationFetched: true})
          }
          else{
            let promise = new Promise((resolve)=>{
              LegacyAppointmentManager.getDefaultDuration(this.props.selectedRoom.occupancyId, this.props.service.id, (result)=>{
                resolve(result)
              })
            })
            let duration = await promise
            if(duration.statusCode === 500 || !duration.value){
              toast("Unable to get default duration")
            }
            else{
              stateUpdate = Object.assign(stateUpdate, {defaultDuration: duration.value, defaultDurationFetched: true})
            }
          }
        }

        if(!Array.isArray(this.props.selectedRoom) && !this.props.service.providerOnly && this.props.service.providers && this.props.service.providers.length > 0 && !this.state.defaultProvidersFetched){
          let promise = new Promise((resolve)=>{
            LegacyAppointmentManager.getLastCreatedAppointment(this.props.selectedRoom.occupancyId, this.props.service.id, (result)=>{
              resolve(result)
            })
          })
          let result = await promise

          if(result.statusCode === 404){
            stateUpdate = Object.assign(stateUpdate, {defaultProviders: [], defaultProvidersFetched: true})
          }
          else if(result.error || result.statusCode === 500){
            toast("Unable to get default providers")
          }
          else{
            stateUpdate = Object.assign(stateUpdate, {defaultProviders: result.data.providers, defaultProvidersFetched: true})
          }
        }
        let weekTimeRange = CalendarUtil.getWeekTimeRange(date)
        let listAppointmentQuery = Queries.getAppointmentQueryParams(
            weekTimeRange,
            this.props.facility.id,
            this.props.facility.id
        )
        //#region get appointments
        await this.repo.getAppointments(listAppointmentQuery)
            .then((appointments)=> {
                let data = appointments.appointments
                let schedule = []
                if (Array.isArray(data)) {
                    data.forEach(e => {
                        if (this.shouldRenderAppointment(e))
                            schedule.push(e)
                    });
                }
                let constructedSchedule = this.constructSchedule(schedule, weekTimeRange)
                this.setState(state => {
                    state.providers = serviceProvidersDict
                    state.schedule = constructedSchedule
                    state.loading = false
                    return state
                })
            })
        //#endregion
      }

      /**
       * @param timeRange {{start: number, end: number}}
       * @param appointments {AppointmentModel[]}
       * @returns {(ScheduleColumn)[]}
       */
      constructSchedule(appointments, timeRange){
        let getDOW = (i) => {
          // eslint-disable-next-line default-case
          switch (i){
            case 0:
              return 'SUN'
            case 1:
              return 'MON'
            case 2:
              return 'TUE'
            case 3:
              return 'WED'
            case 4:
              return 'THU'
            case 5:
              return 'FRI'
            case 6:
              return 'SAT'
          }
        }
        /**
         * Generate a schedule dictionary, with our key being days of week
         * @type {ScheduleDictionary}
         */
        const schedule = {};
        let sunday = new Date(timeRange.start).getDate() //day of month. We know this is a Sunday
        let saturday = new Date(timeRange.end).getDate() //day of month. We know this is a Saturday
        //create an array of IDs from sunday to saturday
        let dates = []
        for (let i = 0; i < 7; i++) {
          let date = new Date(timeRange.start)
          date.setDate(sunday+i) // do it like this, so we properly roll over to the next month
          dates.push(date.getDate())
          schedule[i] = new ScheduleColumnImpl(`dow-${date}`, `${getDOW(i)}\n${date.getDate()}`)
        }

        for (const appointment of appointments) {
          //if not between saturday and sunday, toast it and drop it
          //we cannot use day of month comparison, because we could be in a different month by Saturday
          if(appointment.start.getTime() < new Date(timeRange.start).getTime() || appointment.start.getTime() > new Date(timeRange.end).getTime()){
            console.error("Appointment not in range", appointment.start.getDate(), sunday, saturday, appointment)
            toast("Appointment not in range! Check logs for error details.")
            continue
          }
          let index = dates.indexOf(appointment.start.getDate())
          if(index === -1){
              console.error("Appointment not in range", appointment.start.getDate(), sunday, saturday, appointment)
              toast("Appointment not in range! Check logs for error details.")
              continue
          }
          schedule[index].pushAppointmentModel(appointment)
        }

        /**
         * @type {(ScheduleColumn)[]}
         */
        let scheduleArray = Object.values(schedule)
        scheduleArray.forEach(column => Helpers.sortObjectByTimes(column.list()))
        return scheduleArray
      }
}