import React from 'react';

import Colors from '../../util/Colors'
import SelectServicePage from './SelectServicePage'
import SelectProviderPage from './SelectProviderPage'
import SelectOccupancyPage from './SelectOccupancyPage'
import SelectTimePage from './SelectTimePage'
import SelectLocationPage from './SelectLocationPage'
import ReviewPage from './ReviewPage'
import ProviderManager from '../../managers/ProviderManager'
import { toast } from 'react-toastify';
import FacilityManager from '../../managers/FacilityManager'
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager'
import CalendarUtil from '../../util/CalendarUtil';
import LoadingSpinner from '../../util/LoadingSpinner';
import Tracker from '../../managers/Tracker';
import Timer from '../../util/Timer';
import FacilityUtil from '../../util/FacilityUtil';
import Helpers from '../../util/Helpers';
import WeekSchedulePage from '../ManagePatients/WeekSchedulePage';
import NavigationHeader from '../../ui/NavigationHeader';
import PromisifyCallback from '../../util/PromisifyCallback';
import {AppointmentRepository} from "../../repositories/AppointmentRepository";

export default class ScheduleAppointmentPage extends React.Component {

  createAppointmentTimer
  additionalAppointment = false
  /**
   * @type {AppointmentRepository}
   */
  repo

  /**
   * @type {{
   *  originalAppointment?: AppointmentModel,
   *  appointment?: AppointmentModel,
   *  services?: {[key: string]: any},
   *  providers?: {[key: string]: any},
   *  occupancies?: {[key: string]: any},
   *  locations?: {[key: string]: any},
   *  appointmentOccupants: OccupantAssignee[],
   *  appointmentProviders:ProviderAssignee[],
   *  appointmentLocations: LocationAssignee[],
   *  linkedAppointments?: LinkedAppointmentMetadata[],
   *  editing?: boolean,
   *  loading?: boolean,
   * }}
   */
  state

  constructor(props){
    super(props)
    this.createAppointmentTimer = Timer.start()

    Tracker.logScreenView('schedule_appointment')
    this.repo = new AppointmentRepository({facilityId: props.facility.id})
    /**
     * @type {null|AppointmentModel|NewAppointmentModel}
     */
    let appt = props.editingAppointment;
    /**
     * @type {NewAppointmentModel}
     */
    let originalAppointment = {};
    let editing = false;
    if(appt == null) appt = {}
    else {
      originalAppointment = appt
      appt = window.structuredClone(appt) //make sure we don't modify the original object
      editing = true
      this.props.clearEditAppointment()
    }

    this.state = {
      originalAppointment: originalAppointment,
      services:{},
      providers:{},
      occupancies:{},
      locations:{},
      appointment: appt,
      appointmentOccupants: [],
      appointmentProviders: [],
      appointmentLocations: [],
      linkedAppointments: [],
      editing: editing,
    }
    if(this.props.machine.state.event){
      if(this.props.machine.state.event.serviceId){
        this.state.serviceId = this.props.machine.state.event.serviceId
        this.state.providersForAppt = this.props.machine.state.event.providers
        this.state.previousState = this.props.machine.state.event.previousState
      }
      if(this.props.machine.state.event.anchorDate){
        this.state.appointment.start = new Date(this.props.machine.state.event.anchorDate)
      }
    }
    if(props.editingAppointment && props.editingAppointment.previousState && !this.state.previousState){ //could have already been in the appointment
      this.state.previousState = props.editingAppointment.previousState
    }

    document.body.style = 'background:' + Colors.Secondary.Light + ';';

    //bind callbacks
    this.onServiceSelected = this.onServiceSelected.bind(this)
    this.onProviderSelected = this.onProviderSelected.bind(this)
    this.onOccupancySelected = this.onOccupancySelected.bind(this)
    this.onTimeSelected = this.onTimeSelected.bind(this)
    this.onLocationSelected = this.onLocationSelected.bind(this)
    this.createAppointment = this.createAppointment.bind(this)

    this.reload = this.reload.bind(this)
    this.getProviders = this.getProviders.bind(this)
    this.getOccupancies = this.getOccupancies.bind(this)
    this.getLocations = this.getLocations.bind(this)
  }

  setStatePromise = (state)=> {
    return new Promise((resolve)=>{
      this.setState(state, ()=> {
        resolve()
      })
    })
  }

  getAppointments(){
    this.setState({
      services: this.props.facility.services
    })
    if(this.state.editing){
      this.props.machine.send("RESOLVE")
      this.props.machine.send("NEXT")

      let service = null
      this.state.appointment.services.forEach(e => {
        if(e.id === this.state.appointment.serviceId){
          service = e
        }
      })

      this.onServiceSelected(service, this.state.appointment.title)
      return
    }
    if(this.state.serviceId && !this.state.appointment.service){
      this.props.machine.send("RESOLVE")
      this.props.machine.send("NEXT")
      let service = null
      this.props.services.forEach(e => {
        if(e.id === this.state.serviceId){
          service = e
        }
      })
      this.onServiceSelected(service)
      return
    }
    this.props.machine.send("RESOLVE")
  }

  getProviders(){
    if(this.state.editing){
      this.props.machine.send("RESOLVE")
      this.props.machine.send("NEXT")
      this.onProviderSelected(this.state.appointment.providers.map(p => p.id))
      return
    }
    let service = this.state.appointment.service

    if(!service.pickProvider || (service.scheduleStyle === 'Week' && service.providers && service.providers.length > 0)){ //if no provider can be picked, move on to next screen
      this.props.machine.send("RESOLVE")
      this.props.machine.send("NEXT")
      this.onProviderSelected([]) //send an empty provider array
      return
    }
    if(this.state.providersForAppt && !this.state.appointment.providers){
      this.props.machine.send("RESOLVE")
      this.props.machine.send("NEXT")
      this.onProviderSelected(this.state.providersForAppt)
      return
    }

    let serviceId = service.id;
    const appt = this.state.appointment;
    appt.providers = []

    //if other service - pass all providers
    if(serviceId === this.props.facility.otherServiceId || serviceId === this.props.facility.unavailableServiceId)  {
      const providers = [];

      Object.keys(this.props.providers).forEach(e => {
        const provider = this.props.providers[e];
        if(ProviderManager.isProviderDisabled(provider)) return //don't add users that have been deactivated
        providers.push(provider)
      })
      serviceId = null
      this.setState({
        providers: providers,
        appointment: appt
      })
      this.props.machine.send("RESOLVE")
      return
    }

    FacilityUtil.getProviderServices(this.props.facility.id, serviceId, true).then((data)=>{
      const providers = [];

      Object.keys(this.props.providers).forEach(e => {
        const provider = this.props.providers[e];
        if(ProviderManager.isProviderDisabled(provider)) return //don't add users that have been deactivated
        if(data.providers.includes(provider.id)) providers.push(provider)
      })

      this.setState({
        providers: providers,
        appointment: appt,
        appointmentProviders: appt.providers
      })
      this.props.machine.send("RESOLVE")
    }).catch((error)=>{
      console.log('getProviders: error')
      console.error(error)
      this.props.machine.send("REJECT")
      this.props.machine.send("RETRY")
      toast(error.message)
    })
  }

  async getOccupancies(){
    let occupancy;
    window.scrollTo(0, 0)

    if(this.state.appointment.service.providerOnly){
      this.props.machine.send("RESOLVE")
      this.props.machine.send("NEXT")
      occupancy = {
        facilityId: this.props.facility.id
      };
      this.onOccupancySelected(occupancy)
      return
    }
    await new Promise(resolve => {
      FacilityManager.listRooms(this.props.facility.id, true, null, (data) => {
        Helpers.sortRooms(data)

        data.forEach(e => {
          e.name = Helpers.getRoomName(e)
        })

        this.setState({
          occupancies: data
        }, () => resolve())
      })
    })
    if(this.state.editing){
      this.props.machine.send("RESOLVE")
      this.props.machine.send("NEXT")
      this.setState({
        appointmentOccupants: this.state.appointment.occupants
      })
      const occupancyLookup = {}
      this.state.occupancies.forEach(occupancy => occupancyLookup[occupancy.occupancyId] = occupancy)
      this.onOccupancySelected(
          this.state.appointment.occupants.map(occupant => occupancyLookup[occupant.id])
      )
      return
    }
    this.props.machine.send("RESOLVE")
    this.setState({
      loading: false
    })
  }

  getTimes = () => {
    window.scrollTo(0, 0)
    this.props.machine.send("RESOLVE")
    if(!this.state.editingTimes && this.state.editing && this.state.appointment.service.scheduleStyle !== "Week"){
      this.props.machine.send("NEXT")
      this.onTimeSelected({startTime: this.state.appointment.start.getTime(), endTime: this.state.appointment.end.getTime()})
    }
  }

  getLocations(wasEditingTime = false){
    window.scrollTo(0, 0)
    this.setState({
      locations: this.props.facility.locations
    })
    this.props.machine.send("RESOLVE")

    if(!this.state.appointment.service.pickLocation){
      this.props.machine.send("NEXT")
      this.onLocationSelected(null)
    }
    else if((!this.state.editingLocation && this.state.editing) || (wasEditingTime && this.state.appointment.locations)){
      this.props.machine.send("NEXT")
      const locations = this.state.appointment.locations

      const locationAssignee = locations.length > 0 ? locations[0] : null //for now, we only support the display of one location
      if(locationAssignee){
        let location = this.props.facility.locations[locationAssignee.id] // try to find the location in the facility
        if(!location) { // otherwise, just use the location assignee directly, as it is probably a room
          location = locationAssignee
        }
        this.onLocationSelected(location)
        return
      }
      if(this.state.appointment.location){
        this.onLocationSelected(this.state.appointment.location)
      }
      else{
        if(Helpers.shouldShowSingleOccupantAsLocation(this.state.appointment, this.state.appointment.service)){
          this.onLocationSelected({name:this.state.appointment.occupancy.name + " - " + this.state.appointment.occupancy.identifier, id: this.state.appointment.occupancy.id})
        }
        else{
          this.onLocationSelected(this.props.facility.locations[this.state.appointment.locationId])
        }
      }
    }
  }

  async createAppointment(notes, scheduleAnother){
    this.setState({
      loading: true
    })

    if(this.createAppointmentTimer){
      Tracker.logCreateAppointment(this.createAppointmentTimer.getElapsed(), this.additionalAppointment, this.state.editing)
    }

    if(scheduleAnother) {
      this.createAppointmentTimer = Timer.start()
      this.additionalAppointment = true 
    } 
    else { 
      this.createAppointmentTimer = null
      this.additionalAppointment = false
    }

    const startUtcMs = this.state.appointment.time.startTime;
    const endUtcMs = this.state.appointment.time.endTime;

    let title = this.state.appointment.service.name;
    if(this.state.appointment.title){
      title = this.state.appointment.title
    }
    const timesHaveChanged = (
        this.state.appointment.time.setByUser
        || this.state.appointment.start.getTime() !== this.state.appointment.time.startTime
        || this.state.appointment.end.getTime() !== this.state.appointment.time.endTime
    );

    if(notes === "") notes = null
    const oldAppointmentObject = window.structuredClone(this.state.originalAppointment ?? {}) //we don't want to directly modify the object, if it has data already in it
    /**
     * @type {NewAppointmentModel|AppointmentModel}
     */
    let appointment

    appointment = Object.assign(oldAppointmentObject, { //Overwrite fields of the original if they exist
      facilityId: this.props.facility.id,
      serviceId: this.state.appointment.service.id,
      creatorId: this.props.user.creatorId,
      start: new Date(startUtcMs),
      end: new Date(endUtcMs),
      title: title,
      description: null,
      notes: notes,
      providers: [],
      occupants: [],
      locations: [],
      linkedAppointments: []
    })
    /**
     * @type {string[]}
     */
    const providers = this.state.appointment.providers
    providers.forEach(providerId => appointment.providers.push({
      name: Helpers.getProviderFullName(this.props.providers[providerId]),
      id: providerId,
    }))
    const occupancies = this.state.appointment.occupancies ?? [this.state.appointment.occupancy]
    occupancies.forEach(occupancy => {
      const roomId = occupancy.id
      const roomName = occupancy.name
      const occupancyId = occupancy.occupancyId
      const occupancyIdentifier = occupancy.identifier
      appointment.occupants.push({
        name: occupancyIdentifier,
        id: occupancyId,
        roomId: roomId,
        roomName: roomName
      })
    })
    if(this.state.appointment.location){
      appointment.locations.push({
        name: this.state.appointment.location.name,
        id: this.state.appointment.location.id,
        ignoreConflicts: true
      })
    }

    let archivedLinkedIds;
    if(timesHaveChanged){
      //Remove any fields that should no longer be here
      appointment.incomplete = undefined
      appointment.conflictingProviders = undefined
      archivedLinkedIds = appointment.linkedAppointments
      appointment.linkedAppointments = []
    }

    let previousState = this.state.previousState
    this.repo.saveAppointment(appointment)
        .then(async (data) => {
          if(data && (data.statusCode === 409 || data.message || data.error)){ //CONFLICT
            this.setState({loading: false},()=>{
              if(data.message) toast(`Error creating appointment: ${data.message}`, {autoClose: false})
              else toast("Unable to schedule appointment at selected time. Please select a new time for this appointment", {autoClose: false})
              this.editTime()
            })
            return
          }
          if(archivedLinkedIds && archivedLinkedIds.length > 0) await this.cancelLinkedAppointments(archivedLinkedIds)

          if(previousState && !scheduleAnother){
            this.props.navigateToState(previousState)
          }
          else{
            this.props.machine.send(scheduleAnother ? "ANOTHER" : "HOME")
            if(scheduleAnother) await this.getOccupancies()
            await this.reload(!scheduleAnother) //don't clear appointment if scheduling another
          }
        })
  }

  /**
   *
   * @param archivedLinkedIds {LinkedAppointmentMetadata[]}
   * @returns {Promise<void>}
   */
  cancelLinkedAppointments = async(archivedLinkedIds) => {
    let appointmentIds = archivedLinkedIds.map(linked => linked.AppointmentId)
    if(appointmentIds){
      let cancelAllPromise = new PromisifyCallback(LegacyAppointmentManager.cancelAppointments)
      let canceledResults = await cancelAllPromise.invoke(appointmentIds)
      console.log(canceledResults)
      if(canceledResults.length > 0) {
        const message = [];
        message.push(<div>Due to reschedule, one or more linked appointments were cancelled:</div>)
        Object.values(archivedLinkedIds).forEach((serviceId)=>{
          let serviceName = Helpers.getService(serviceId, this.props.facility.services).name
          message.push(<div>{serviceName}</div>)
        })
        toast(<div>{message}</div>)
      }
    }
  }

  componentDidMount(){
    this.reload()
  }

  async reload(clearAppointment = false){
    if(clearAppointment){
      await new Promise((resolve)=>{
        this.setState({
          appointment: {}
        }, ()=> {
          resolve()
        })    
      })
    }
    this.getAppointments()
  }

  onServiceSelected(service, title){
    let appt = this.state.appointment
    appt.service = service
    appt.title = title
    this.setState({appointment: appt})
    window.scrollTo(0, 0)
    this.getProviders()
  }

  onProviderSelected(providers){
    this.state.appointment.providers = providers
    this.props.machine.send("NEXT")
    this.getOccupancies()
  }

  onOccupancySelected(occupancy){
    if(Array.isArray(occupancy)){
      this.state.appointment.occupancies = occupancy
    }
    else{ //legacy
      this.state.appointment.occupancy = occupancy
    }
    this.getTimes()
  }

  onTimeSelected(time, userInput = false){
    if(this.state.appointment.service.fullDay){
      const newTime = CalendarUtil.getFullDayAppointmentTimes(time.startTime);
      time.startTime = newTime.start
      time.endTime = newTime.end
    }
    time.setByUser = time.setByUser || userInput
    this.state.appointment.time = time
    let wasEditingTime = this.state.editingTimes
    this.setState({
      editingTimes: false
    }, ()=> {
      this.getLocations(wasEditingTime)
    })
  }

  /**
   * @param location {{
   *     [description]: string,
   *     facilityId: string,
   *     id: string,
   *     name: string,
   *     [services]: *[]
   * }|null}
   */
  onLocationSelected(location){
    this.setState(state => {
        state.appointment.location = location
        state.editingLocation = false
        return state
    })
  }

  getProviderIds(providerObjects){
    const ids = [];
    providerObjects.forEach(provider => {
      ids.push(provider.id)
    });
    return ids
  }

  cancel = () => {
    if(this.state.previousState){
      this.props.navigateToState(this.state.previousState)
    }
    else{
      this.props.machine.send("CANCEL")
      this.reload(true)
    }
  }

  /**
   * @param stagedAppointments
   * @param stagedDeletions
   */
  scheduleWeeklyAppointments = async(stagedAppointments, stagedDeletions) => {
    window.scrollTo(0, 0)

    const promises = [];

    this.setState({
      loading: true
    })

    let providers;
    const selectedProviders = this.state.appointment.providers;
    if(this.state.appointment.service.pickProvider && selectedProviders && selectedProviders.length > 0)
      providers = selectedProviders
    else
      providers = this.state.appointment.providers

    var selectedOccupants = this.state.appointment.occupancies
    if (selectedOccupants == null){
      // use occupancy if valid
      if (this.state.appointment.occupancy.hasOwnProperty("id")){
        selectedOccupants = [this.state.appointment.occupancy]
      } else {
        selectedOccupants = []
      }
    }
    
    let appointments = []
    for(let i = 0; i < stagedAppointments.length; i++){
      const e = stagedAppointments[i];
      console.log(e)
      this.buildStagedAppointment(appointments, e, providers, selectedOccupants)
    }

    const errorCodes = [400, 401, 402, 403, 404, 500, 501]
    const conflictingErrors = [409]
    let failedAppointments = []
    let conflictingAppointments = []
    let successfulAppointments = 0
    const processPromise = (promiseResult) => {
      if(promiseResult.errorId || errorCodes.includes(promiseResult.statusCode)){
        failedAppointments.push(promiseResult)
      }
      else if(conflictingErrors.includes(promiseResult.statusCode)){
        conflictingAppointments.push(promiseResult)
      }
      else{
        successfulAppointments++
      }
    }

    for (const e of stagedDeletions) { // cancel appointments that we need to
      let promiseResult = await this.repo.cancelAppointment(e.appointmentId)
      processPromise(promiseResult)
    }

    // then allow the user to schedule the new appointments. This ensures that we don't have any conflicts
    let saveAppointmentPromises = this.repo.saveAppointments(appointments)
    for (const promise of saveAppointmentPromises) {
      let promiseResult = await promise
      processPromise(promiseResult)
    }

    const appointmentPromisesLength = [...saveAppointmentPromises, ...stagedAppointments].length

    this.setState({
      loading: false,
      errorText: successfulAppointments !== appointmentPromisesLength.length ? `Failed to schedule ${appointmentPromisesLength.length-successfulAppointments} of ${appointmentPromisesLength.length} appointments` : undefined,
    })
    if(failedAppointments.length > 0){
      this.reportAppointmentErrors(`The following appointments failed to be changed:`, failedAppointments)
      return
    }
    if(conflictingAppointments.length > 0){
      this.reportAppointmentErrors(`Unable to schedule one or more appointments at selected time. Please select new times for these appointments:`, conflictingAppointments)
      return
    }

    if(this.state.previousState){
      this.props.navigateToState(this.state.previousState)
    }
    else{
      this.cancel()
    }
  }

  reportAppointmentErrors = (message, appointmentData) => {
    let messageLines = [message]
    appointmentData.forEach((failedData)=>{
     messageLines.push(<br/>)
      if(failedData.type && failedData.appointment){
        messageLines.push(`${failedData.type} for ${failedData.appointment.occupancyIdentifier} at ${CalendarUtil.getReadableDateAndTime(failedData.appointment.start)} (${failedData.message ?? 'No extra details specified'})`)
      }
      else{
        messageLines.push(`${failedData.message}`)
      }
    })
    toast(<div>{messageLines}</div>, {
     autoClose: false,
     closeOnClick: false,
     draggable: false,
    })
  }
//

  render(){

    if(this.state.loading) return <LoadingSpinner/>
    
    const state = this.props.machine.state.value["ScheduleAppointment"] ?? this.props.machine.state.value["ScheduleGroupAppointment"]
    console.log(state)
    
    if(state == null) return null

    let header = this.renderNavigationHeader(state)
    return this.renderBody(state, header)
  }

  renderNavigationHeader = (state) => {
    let title = this.getSubTitleForState(state)
    let navigationOptions = []
    if(state !== "GetServices" && state !== "ShowServices") navigationOptions = ["Cancel"]
    return <NavigationHeader title={title} navigationBackTitle="Back" onNavigateBack={this.backPressed} navigationOptionsTitles={navigationOptions} onNewOption={this.navigationOptionClicked}/>
  }

  renderBody = (state, header) => {
    switch(state){
      case "GetServices":
      case "GetProviders":
      case "GetOccupancies":
      case "GetLocations":
      case "GetTimes":
      return <LoadingSpinner/>

      case "ShowServices":
      return[
        header,
        <SelectServicePage services={this.props.services} editing={this.state.editing} machine={this.props.machine} services={this.state.services} onSelectService={this.onServiceSelected} facility={this.props.facility}/>
      ]

      case "ShowProviders":
      return[
        <SelectProviderPage user={this.props.user} editing={this.state.editing} selection={this.state.appointment.providers} machine={this.props.machine} providers={this.state.providers} onSelectProvider={this.onProviderSelected} onComplete={()=>{/*Do nothing*/}} onCancel={this.backPressed}/>
      ]

      case "ShowOccupancies":
      return[
        header,
        <SelectOccupancyPage
            editing={this.state.editing}
            machine={this.props.machine}
            occupancies={this.state.occupancies}
            onSelectOccupancy={this.onOccupancySelected}
            service={this.state.appointment.service}
            serviceName={this.state.appointment.title ?? this.state.appointment.service.name}
            groupAppointment={this.props.groupAppointment}
            facility={this.props.facility}/>
      ]

      case "AppointmentPopup":
      case "SelectTime":
        if(this.state.appointment.service.scheduleStyle === 'Week'){
          //add the weekly calendar, with some spacing at the top to prevent issues interacting with the date arrows
          return[
            <WeekSchedulePage
                header={header}
                anchorDate={this.state.appointment.start}
                title={this.state.appointment.title ?? this.state.appointment.service.name}
                stateMachineName={this.props.groupAppointment ? "ScheduleGroupAppointment" : "ScheduleAppointment"}
                service={this.state.appointment.service}
                machine={this.props.machine}
                selectedRoom={this.state.appointment.occupancies ?? this.state.appointment.occupancy}
                selectedProviders={this.state.appointment.providers}
                facility={this.props.facility}
                user={this.props.user}
                onFinish={this.scheduleWeeklyAppointments}
                isGroupAppointment={this.props.groupAppointment}
                onCancel={this.cancel}/>
          ]
        }
        else{
          return(
            <SelectTimePage
                header={header}
                facility={this.props.facility}
                service={this.state.appointment.service}
                editing={this.state.editing}
                machine={this.props.machine}
                appointment={this.state.appointment}
                onSelectTime={(time)=>{this.onTimeSelected(time, /*userInput:*/true)}}
                conflicts={this.state.conflicts}
                fullDay={this.state.appointment.service.fullDay == true ? true : false}/> /*Might not exist, so do a check*/
          )
        }
      case "SelectLocation":
        let occupancy = this.state.appointment.occupancy
        if(this.state.appointment.occupancies != null) {
          // check if not group appointment
          if (this.state.appointment.occupancies.length === 1) {
            occupancy = this.state.appointment.occupancies[0]
          }
        }
        return[
          header,
          <SelectLocationPage 
            machine={this.props.machine} 
            occupancy={occupancy} 
            locations={this.state.locations} 
            onSelectLocation={this.onLocationSelected}
          />
        ]

      case "Review":
      return[
        header,
        <ReviewPage
            facility={this.props.facility}
            editing={this.state.editing}
            machine={this.props.machine}
            onFinish={this.createAppointment}
            cancel={this.cancel}
            appointment={this.state.appointment}
            onEditTime={this.editTime}
            onEditLocation={this.editLocation}/>
      ]
      default:
        return <LoadingSpinner/>
    }
  }

  editLocation = () => {
    this.props.machine.send("EDIT_LOCATION")
    this.setState({
      editingLocation: true
    },()=>{ //wait for update
      this.getLocations()
    })
  }

  editTime = () => {
    this.props.machine.send("EDIT_TIMES")
    this.setState({
      editingTimes: true
    }, ()=> { //wait for update
      this.getTimes()
    })
  }

  getSubTitleForState = (state) => {
    switch (state) {
      case "GetServices":
      case "ShowServices":
          return "Select Service"

      case "GetProviders":
      case "ShowProviders":
          return "Select Provider"

      case "GetOccupancies":
      case "ShowOccupancies":
          return "Select Room"

      case "AppointmentPopup":
      case "GetTimes":
      case "SelectTime":
          return ""

      case "GetLocations":
      case "SelectLocation":
          return "Select Location"

      case "Review":
          return "Review & Submit"

      default:
          return ""
    }
  }

  returnToPreviousPage = () => {
    if(this.state.previousState){
      this.props.navigateToState(this.state.previousState)
    }
    else{
        this.props.machine.send("HOME")
    }
  }

  backPressed = async() => {
    const service = this.state.appointment.service
    const state = this.props.machine.state.value["ScheduleAppointment"] ?? this.props.machine.state.value["ScheduleGroupAppointment"]
    switch (state) {
      case "GetServices":
      case "GetProviders":
      case "GetOccupancies":
      case "GetLocations":
      case "GetTimes":
        //do nothing. These are loading pages
        break;
      case "Review":
        this.props.machine.send("PREVIOUS")
        if(this.state.editing){
          this.returnToPreviousPage()
          break;
        }
        if(service.pickLocation && !this.state.editing){
          this.getLocations()
          break;
        }
        //else go to next switch statement... We are at GetLocation now
      case "SelectLocation":
        let wasEditingLocation = this.state.editingLocation
        await this.setStatePromise({editingLocation: false})
        if(this.state.editing || wasEditingLocation){
          this.props.machine.send("NEXT")
          break;
        }
        this.props.machine.send("PREVIOUS")
        this.getTimes()
        break;
      case "AppointmentPopup":
        this.props.machine.send("CLOSE")
        //Go to next switch statement. We are at SelectTime now, and still need to go back
      case "SelectTime":
        if(this.state.editing && this.state.appointment.service.scheduleStyle === 'Week'){
          this.returnToPreviousPage()
          break;
        }
        let wasEditingTime = this.state.editingTimes
        await this.setStatePromise({editingTimes: false})
        if(this.state.editing || wasEditingTime){
          //go back to whatever tried to edit. Not allowing it to go back more than this
          this.props.machine.send("NEXT")
          this.props.machine.send("RESOLVE")
          this.props.machine.send("NEXT")
          break;
        }
        this.props.machine.send("PREVIOUS")
        if(!service.providerOnly){
          this.getOccupancies()
          break;
        }
        //else go to next switch statement... We are at GetOccupancies now
      case "ShowOccupancies":
        this.props.machine.send("PREVIOUS")
        if(service.pickProvider && service.scheduleStyle !== "Week"){
          this.getProviders()
          break;
        }
      case "ShowProviders":
        this.props.machine.send("PREVIOUS")
        this.getAppointments()
        break;
        //else go to next switch statement... We are at GetServices now
      case "ShowServices":
        this.props.machine.send("HOME")
        break;
      default:
        this.props.machine.send("PREVIOUS")
        break;
    }
  }

  navigationOptionClicked=(option)=>{
    if(option === "Cancel"){
      if(!this.state.editing){
        this.cancel()
      }
      else{
        this.returnToPreviousPage()
      }
    }
  }

  buildStagedAppointment(appointments, appointmentData, providers, occupants) {
    const appointmentStoredProviders = appointmentData.providers
    let locationId = appointmentData.locationId
    let start = appointmentData.start
    let end = appointmentData.end
    appointmentData.locationId = undefined
    /**
     *
     * @type {NewAppointmentModel | AppointmentModel}
     */
    const appointment = window.structuredClone(appointmentData)
    appointment.start = new Date(start)
    appointment.end = new Date(end)
    appointment.providers = []
    appointment.locations = []
    appointment.occupants = []
    appointment.eTag = appointmentData.eTag
    if(appointmentStoredProviders && appointmentStoredProviders.length > 0){
      appointment.providers = appointmentStoredProviders
    }
    else{
      for (let provider of providers) {
        appointment.providers.push({
          id: provider,
          name: provider.hasOwnProperty('id') ? Helpers.getProviderFullName(provider) : Helpers.getProviderNameFromId(provider, this.props.facility),
        })
      }
    }
    for (let occupancy of occupants) {
      appointment.occupants.push({
        name: occupancy.identifier,
        id: occupancy.occupancyId,
        roomId: occupancy.id,
        roomName: occupancy.name
      })
    }
    if(locationId){
      console.log(locationId)
      const location = this.props.facility.locations[locationId]
      if(!location){
        for (const o of appointment.occupants) {
          if(o.roomId === locationId){
            appointment.locations.push({
              id: locationId,
              name: o.roomName,
              ignoreConflicts: true,
            })
            break
          }
        }
      }
      else{
        appointment.locations.push({
          id: locationId,
          name: location.name,
          ignoreConflicts: true,
        })
      }
    }
    appointments.push(appointment)
  }
}