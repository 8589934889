//we have a file at ../build-info.json that we need to parse and use in our app
import buildInfo from '../build-info.json';
import React from 'react';

export interface BuildInfo {
    updatedAt: Date,
    commit: string
    versionNumber: string
    notes?: string[]
}

const fromJson = (json: string): BuildInfo => {
    const obj = JSON.parse(json);
    return {
        updatedAt: new Date(obj.updatedAt),
        commit: obj.commit,
        versionNumber: obj.versionNumber,
        notes: obj.notes
    }
}

class VersionInfo {
    buildInfo: BuildInfo;
    constructor() {
        this.buildInfo = fromJson(JSON.stringify(buildInfo));
    }

    public static getVersion(): string {
        return buildInfo.versionNumber;
    }
    public static getCommit(): string {
        return buildInfo.commit;
    }
    public static getUpdatedAt(): Date {
        return new Date(buildInfo.updatedAt);
    }
    public static getNotes(): string[] {
        return buildInfo.notes || [];
    }
}

const VersionInfoUI = () => {
    const version = VersionInfo.getVersion();
    const commit = VersionInfo.getCommit();
    const updatedAt = VersionInfo.getUpdatedAt().toString();
    return (
        <div title={`Updated At: ${updatedAt}`}>
            <span>version {version}</span>
            <span hidden={true}>commit={commit}</span>
        </div>
    )
}

export {
    VersionInfoUI,
    VersionInfo
};