import React, {useEffect, useMemo, useState} from "react";
import styles from './MultiSelectAppointmentFixedToast.module.css'
const {default: FacilityCalendarOptions} = require('./FacilityCalendarOptions')
const {default: Helpers} = require('../util/Helpers')

interface Props extends React.ComponentProps<any> {
    clickedBlobs: any[],
    onShowOptions: () => void,
    on2ferChecked: (state: boolean) => void
    onDelete: () => void
    onStash: () => void
    onUnStash: () => void
    onUnassign: () => void
    onReload: () => void
    services: any[]
    isProviderView: boolean
}

interface VisibleActions{
    option_2fer: boolean
    option_providerUnassign: boolean
    option_providerSwap: boolean
    option_stash: boolean
    option_unstash: boolean
    toggle_2fer_value: boolean
}

const getVisibleActions = (clickedBlobs: any[], services: any[], isProviderView:boolean) : VisibleActions => {
    // Show only the available actions we can take for all items in the array.
    // Don't show 2fer toggle if they all do not match for now
    let options = {
        option_2fer: false,
        option_providerUnassign: true,
        option_providerSwap: false,
        option_stash: true,
        option_unstash: true,
        toggle_2fer_value: true,
    }

    for (const clickedBlob of clickedBlobs) {
        let appointment = clickedBlob.appointment
        let service = Helpers.getService(appointment.serviceId, services)
        let isScheduleForward = service.scheduleForward
        let isAfterNow = appointment.start > Date.now()
        let allowProviderUnassign = isAfterNow && isProviderView && isScheduleForward
        let allowStash = isAfterNow && isScheduleForward
        options.option_providerUnassign = options.option_providerUnassign && allowProviderUnassign
        options.option_unstash = options.option_unstash && allowStash && clickedBlob.appointment.stashed
        options.option_stash = options.option_stash && allowStash && !clickedBlob.appointment.stashed
    }
    return options
}

const MultiSelectAppointmentFixedToast = (
    {
        clickedBlobs,
        onShowOptions,
        on2ferChecked,
        onDelete,
        onStash,
        onUnStash,
        onUnassign,
        onReload,
        services,
        isProviderView,
        ...props
    }: Props) => {
    const [showMultiSelectOptions, setShowMultiSelectOptions] = useState(false)
    const [callbackAfterPrompt, setCallbackAfterPrompt] = useState<(() => void) | null>(null)
    const [promptText, setPromptText] = useState<string | null>(null)
    const handlePrompt = useMemo(()=>{
        return (promptText: string, callbackFunc: () => void) => {
            setPromptText(promptText)
            //Not sure why we need to wrap this callbackFunc, but if we don't it gets called immediately
            setCallbackAfterPrompt(()=>callbackFunc)
            setShowMultiSelectOptions(false)
        }
    }, [])
    let multiSelectOptions
    if (showMultiSelectOptions) {
        const visibleActions = getVisibleActions(clickedBlobs, services, isProviderView)
        multiSelectOptions = <div className={styles.multiSelectOptionsContainer}>
            <FacilityCalendarOptions
                refStyle={{}}
                onCancel={() => {
                    setShowMultiSelectOptions(false)
                }}
                loading={false}
                onSwapAppointment={() => {
                }}
                onDelete={() => {
                    handlePrompt(`Delete ${clickedBlobs.length} appointments?`, onDelete)
                }}
                onStash={() => {
                    handlePrompt(`Stash ${clickedBlobs.length} appointments?`, onStash)
                }}
                onUnstash={() => {
                    handlePrompt(`Unstash ${clickedBlobs.length} appointments?`, onUnStash)
                }}
                onUnassignProvider={() => {
                    handlePrompt(`Unassign ${clickedBlobs.length} appointments?`, onUnassign)
                }}
                onEdit={() => {
                }} //not handled
                on2ferSelected={on2ferChecked}
                onSelectOthers={() => {
                }} //Not handled
                require2fer={false}
                hideControls={false}
                allowEdit={false}
                allow2fer={visibleActions.option_2fer}
                allowProviderUnassign={visibleActions.option_providerUnassign}
                allowProviderSwap={visibleActions.option_providerSwap}
                allowStash={visibleActions.option_stash}
                allowUnstash={visibleActions.option_unstash}
                selectOthers={false}/>
        </div>
    }
    return <div className={`${styles.container} ${promptText ? styles.prompt : ''}`}>
        <>{multiSelectOptions}</>
        <div className={styles.mainText}>{promptText??`${clickedBlobs.length} appointments selected`}</div>

        {!promptText && <div className={styles.confirmationContainer}>
            <div className={styles.confirmationCancel} onClick={()=>{
                onReload()
            }}>
                Cancel
            </div>
            <div className={styles.confirmationConfirm} onClick={()=>setShowMultiSelectOptions(!showMultiSelectOptions)}>
                Options
            </div>
        </div>}
        {promptText && <div className={styles.confirmationContainer}>
            <div className={styles.confirmationCancel} onClick={()=>{
                setCallbackAfterPrompt(null)
                setPromptText(null)
                setShowMultiSelectOptions(true)
            }}>
                Cancel
            </div>
            <div className={styles.confirmationConfirm} onClick={()=>{
                setCallbackAfterPrompt(null)
                setPromptText(null)
                if(callbackAfterPrompt) callbackAfterPrompt()
                onReload()
            }}>
                Confirm
            </div>
        </div>}
    </div>
}

export {
    MultiSelectAppointmentFixedToast
}