import React from 'react';
import {Card} from 'react-bootstrap'
import Colors from '../../util/Colors'
import Tracker from '../../managers/Tracker'
import Helpers from '../../util/Helpers';
import SDButton from '../../ui/SDButton'

export default class SelectServicePage extends React.Component{

    constructor() {
      super()
      Tracker.logScreenView('schedule_appointment_select_service')
    }

    renderServiceList() {

      var sortedServices = Helpers.sortServices(this.props.services, true, false)

      return sortedServices.map((service) => {

        if(!service.schedulable) return null
        let titles = [service.name]
        if(service.alternateTitles){
            service.alternateTitles.forEach((title)=>{
                titles.push(title)
            })
        }

        return titles.map((title)=>{
            return (
                <row>
                    <SDButton item={service} title={title} subTitle={service.name !== title ? service.name : null} onSelectItem={()=>{
                        this.props.machine.send("NEXT")
                        this.props.onSelectService(service, title)
                    }}/>
                </row>
            )
        })
      })
   }
  
    render(){
      if(this.props.editing) return null

      return(
        <div align="center" style={{marginTop:"30px"}}>
          {this.renderServiceList()}
        </div>
      )
    }
  }