import React from 'react';
import {Button, Form} from 'react-bootstrap'
import Colors from '../../util/Colors'

export default class SetIdPage extends React.Component{

  constructor(){
    super()
    this.state={
      canContinue: false
    }
  }

    next = () => {
      var id = document.getElementById("input").value

      this.props.onIdSet(id)
      this.props.machine.send("NEXT")
    }
  
    cancel = () => {
      this.props.machine.send("CANCEL")
    }

    onChange = () => {
      var e = document.getElementById("input")
      this.setState({
        canContinue: (e !== null && e.value !== null && e.value !== "")
      })
    }

    render(){

      const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.next()
        }
    }

      return(
  
        <>
        <div align="center">
            <Form style={{width:"250px", textAlign:"left", paddingTop:"10px"}}>
            <Form.Group controlId="roomId">
              <Form.Label>Occupancy Identifier</Form.Label>
              <Form.Control onKeyDown={onKeyDown} onChange={this.onChange} autoFocus={true} id="input" placeholder="e.g. First Name"  style={{backgroundColor:Colors.NavGray, color:"#000000", textAlign:"center"}}/>
            </Form.Group>
            
            <div style={{position:"absolute", bottom:"80px", right:"80px"}}>
              <Button variant="link" onClick={this.cancel} style={{color:Colors.Primary.Main}}>Cancel</Button>
              <Button disabled={!this.state.canContinue} variant="primary" onClick={this.next} style={{width:"80px", backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>Next</Button>
            </div>
          </Form>
      </div>
  </>
      )
    }
  }