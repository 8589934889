import {AppointmentQuery, CompareAppointmentQuery} from "../managers/Queries";
import AppointmentModel, {NewAppointmentModel} from "../models/AppointmentModel";
import { AppointmentRepositoryOptions, SwapItem, AppointmentResult, AppointmentSaveResult, AppointmentSaveError, CompareResults, AppointmentGetSwapsResult, AppointmentResults } from "../types/AppointmentRepository";
import {AppointmentFetchManager} from "../managers/AppointmentFetchManager";

export class AppointmentRepository {
    options: AppointmentRepositoryOptions
    private fetchManager: AppointmentFetchManager;

    /**
     *
     * @param options options, or facilityId
     */
    constructor(options: AppointmentRepositoryOptions|string) {
        this.options = typeof options === 'string' ? {facilityId: options as string}: (options as AppointmentRepositoryOptions)
        if (!this.options.fetchManager) {
            this.options.fetchManager = new AppointmentFetchManager()
        }
        this.fetchManager = this.options.fetchManager
    }

    static convertToDate(time: any): Date{
        return new Date(time as unknown as number|string)
    }

    static convertFields(appt: AppointmentModel): AppointmentModel{
        appt.start = this.convertToDate(appt.start)
        appt.end = this.convertToDate(appt.end)
        if(appt.updatedAt) appt.updatedAt = this.convertToDate(appt.updatedAt)
        appt.createdAt = appt.createdAt ? this.convertToDate(appt.createdAt) : new Date()
        if(appt.occupants && appt.occupants.length > 0){
            for (let occupant of appt.occupants) {
                if(occupant.start) occupant.start = this.convertToDate(occupant.start)
                if(occupant.end) occupant.end = this.convertToDate(occupant.end)
            }
        }
        if (appt.providers && appt.providers.length > 0){
            for (let provider of appt.providers) {
                if(provider.start) provider.start = this.convertToDate(provider.start)
                if(provider.end) provider.end = this.convertToDate(provider.end)
            }
        }

        if (appt.locations && appt.locations.length > 0){
            for (let location of appt.locations) {
                if(location.start) location.start = this.convertToDate(location.start)
                if(location.end) location.end = this.convertToDate(location.end)
            }
        }
        return appt
    }

    async getAppointments(query: AppointmentQuery): Promise<AppointmentResults> {
        if (!query.facility) query.facility = this.options.facilityId
        return this.fetchManager.getAppointments(query)
            .then(appointments => {
                return {
                    appointments: appointments.map((appt: AppointmentModel) => AppointmentRepository.convertFields(appt))
                }
            })
            .catch(e => {
                console.log(e)
                return {
                    error: "Failed to fetch appointments",
                    statusCode: e.code ?? 500
                }
            })
    }

    async getAppointment(id: string): Promise<AppointmentResult> {
        return this.fetchManager.getAppointment(this.options.facilityId, id)
            .then(appointment => {
                return {
                    appointment: AppointmentRepository.convertFields(appointment as AppointmentModel)
                }
            })
            .catch(e => {
                console.log(e)
                return {
                    error: "Failed to fetch appointment",
                    statusCode: e.code ?? 500
                }
            })
    }

    async getAvailableSlotsForSwap(id: string, requirePrimary: boolean): Promise<AppointmentGetSwapsResult>{
        return this.fetchManager.getAvailableSlotsForSwap(this.options.facilityId, id, requirePrimary)
            .then(appointments => {
                let appointmentsNew: SwapItem[] = appointments.map((appt: SwapItem) => {
                    let apptNew = AppointmentRepository.convertFields(appt) as SwapItem
                    apptNew.startDate = new Date(apptNew.startDate)
                    apptNew.endDate = new Date(apptNew.endDate)
                    return apptNew
                })
                return {
                    appointments: appointmentsNew
                }
            })
            .catch(e => {
                console.log(e)
                return {
                    error: e.message ?? "Failed to fetch appointments",
                    statusCode: 500
                }
            })
    }

    async compareAppointments(query: CompareAppointmentQuery): Promise<CompareResults> {
        if (!query.facility) query.facility = this.options.facilityId
        return this.fetchManager.compareAppointments(query)
            .then((result) => {
                console.log(result)
                const appointmentsArray: any[][] = Object.values(result.appointments);

                // Use map to call a convertFields on each item of the array
                const updatedAppointmentsArray = appointmentsArray.map(appointmentArray => {
                    return appointmentArray.map((appt) => AppointmentRepository.convertFields(appt));
                });

                // Convert the array of arrays back to the original object structure
                const updatedAppointmentsObject = Object.fromEntries(
                    Object.keys(result.appointments).map((key, index) => [key, updatedAppointmentsArray[index]])
                );
                return {
                    appointments: updatedAppointmentsObject,
                    conflicts: result.conflicts
                } as CompareResults
            })
            .catch(e => {
                console.log(e)
                return {
                    error: "Failed to fetch appointments",
                    statusCode: e.code ?? 500
                }
            })
    }

    async saveAppointment(data: NewAppointmentModel | AppointmentModel, oldAppointment?: AppointmentModel): Promise<AppointmentSaveResult> {
        return this.fetchManager.saveAppointment(data)
            .then(appointment => {
                if(appointment.statusCode && appointment.statusCode !== 200){
                    return appointment as AppointmentSaveError
                }
                return {
                    appointment: AppointmentRepository.convertFields(appointment as AppointmentModel)
                } as AppointmentResult
            })
            .catch(e => {
                console.log('error saving appointment', e)
                return {
                    message: e.message ?? "unknown error",
                    statusCode: e.statusCode ?? 500
                } as AppointmentResult
            })
    }

    saveAppointments(appointments: (NewAppointmentModel|AppointmentModel)[]): Promise<AppointmentResult>[] {
        let results: Promise<AppointmentResult>[] = []
        for (const appointment of appointments) {
            // note that we are not awaiting. We are returning the unresolved promises upwards
            // so that the implementing class can await all how it sees fit
            let result = this.fetchManager.saveAppointment(appointment)
                .then(appointment => {
                    if(appointment.statusCode && appointment.statusCode !== 200) throw new Error((appointment as AppointmentSaveError).message)
                    return {
                        appointment: AppointmentRepository.convertFields(appointment as AppointmentModel)
                    }
                })
                .catch(e => {
                    console.error(e)
                    return {
                        error: "Failed to fetch appointment: " + e.message,
                        statusCode: e.code ?? 500
                    }
                })
            results.push(result)
        }
        return results
    }

    async cancelAppointment(id: string): Promise<{ success: boolean, message?: string }> {
        return this.fetchManager.cancelAppointment(id)
            .then(_ => {
                return {success: true}
            })
            .catch(e => {
                console.log(e.message)
                return {success: false, message: e.message}
            })
    }
}