export const PatientEvaluationMachine = {
    initial: 'Load',
    states: {
        Load: {
            on: {
                RESOLVE: 'SelectRoom'
            }
        },
        SelectRoom: {
            on: {
                ROOM_SELECTED: 'ShowEvaluation'
            }
        },
        ShowEvaluation: {
            on: {
                CANCEL: 'SelectRoom'
            }
        },
        stop: {}
    }
}