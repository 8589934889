import React from "react";
import columnSwapStepOne from '../img/Icons/column-swap-step1.svg'
import columnSwapStepTwo from '../img/Icons/column-swap-step2.svg'
import styles from './columnSwapperUI.module.css'

export default class ColumnSwapperUI extends React.Component {
    static STEP_FROM = 0
    static STEP_TO = 1

    constructor(props){
        super(props)
    }

    render(){
        var buttons = this.renderButtons()
        var title
        var img
        if(this.props.step === ColumnSwapperUI.STEP_FROM){
            title = "Select columns to move appointments from"
            img = columnSwapStepOne
        }
        else{
            title = "Select columns to move appointments to"
            img = columnSwapStepTwo
        }

        return (
            <div class={styles.container}>
                <div class={styles.message}>{title}</div>
                <img src={img} class={styles.image}/>
                {buttons}
            </div>
        )    
    }

    renderButtons(){
        var cancelButton = <div class={styles.cancelButton} onClick={this.props.onCancel}>
            Cancel
        </div>
        var nextButton = <div class={styles.nextButton} onClick={this.props.onNext}>
            {this.props.step === ColumnSwapperUI.STEP_FROM ? "Next" : "Swap"}
        </div>

        return <div class={styles.buttonContainer}>
            {cancelButton}
            {nextButton}
        </div>
    }
}