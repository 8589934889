import React from 'react';
import ProviderManager from '../managers/ProviderManager';

export default class ProvidersDropdown extends React.Component{

    renderOptions(providerDict){
  
      var providers = []
  
      Object.keys(providerDict).forEach(e => {
        var provider = providerDict[e];
  
        if (!ProviderManager.isProviderDisabled(provider)) {
          providers.push(providerDict[e])
        }
      })
  
      var vp = undefined
      providers.sort((a, b) => {
        return (a.firstName > b.firstName) ? 1 : -1
      })
      providers.forEach(provider => { //split this away from sort in case providers only has a length of 1
        if(provider.id === this.props.viewingId) vp = provider
      });
      if(vp === undefined){
        vp = providers[0] //should always work
        this.props.onLoadNewAgenda(vp.id)
        return null
      }
  
      var options = []
      providers.forEach((provider) => {
          options.push(<option value={provider.id}>{provider.firstName.replace('\!', '')} {provider.lastName}</option>)
      })
      return options
    }
  
    onChange = (e) => {
      this.props.onLoadNewAgenda(e.target.value)
    }
  
    render(){
      return (
        <div align={this.props.align?this.props.align:"center"}>
          <select style={{width:140, fontSize:12}} value={this.props.viewingId} onChange={this.onChange}>
              {this.renderOptions(this.props.providers)}
          </select>
        </div>
      )
    }
  
  }