import NetworkManager from './NetworkManager';
import { Endpoint } from '../util/Constants';
import CacheManager from './CacheManager';


export default class ConfigManager {
    /**
     * 
     * @param {string} facilityId
     * @param {(data) => {}} onResult 
     */
    static getHomeOptions(facilityId, role, onResult) {
        return new Promise(function(resolve) {
            var path = Endpoint.base + '/config/page'

            var params = {
                facility: facilityId,
                role: role,
                target: 'home'
            }

            NetworkManager.get(path, params, (result) => {
                if(onResult) onResult(result);
                resolve(result)
            })
        })
    }

    /**
     * 
     * @param {string} facilityId 
     * @param {string} userId
     * @param {(data) => {}} onResult 
     */
    static getFacilityScheduleOptions(facilityId, userId, onResult) {
        var path = Endpoint.base + '/config/page'

        var params = {
            facility: facilityId,
            role: userId,
            target: 'facility-schedule'
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result);
        })
    }

    /**
     * 
     * @param {string} facilityId 
     * @param {string} userId 
     * @param {Array<string>} roomIds
     * @param {(Array<string>) => {}} onResult 
     */
    static updateFacilityScheduleOptions(facilityId, userId, roomIds, onResult) {
        var path = Endpoint.base + '/config/page'

        var params = {
            facility: facilityId,
            role: userId,
            target: 'facility-schedule',
            config: roomIds
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result);
        })
    }

    /**
     * 
     * @param {string} facilityId 
     * @param {string} userId
     * @param {string} service TRUE|FALSE whether or not to fetch service prefrences
     * @param {(data) => {}} onResult 
     */
    static getProviderScheduleOptions(facilityId, userId, service, onResult) {
        let target = service ? 'facility-schedule-services' : 'provider-schedule'
        ConfigManager.getTargetedFacilityUserConfig(facilityId, userId, target, onResult)
    }

    /**
     * 
     * @param {string} facilityId 
     * @param {string} userId
     * @param {string} target
     * @param {(value) => {}} onResult 
     */
    static getTargetedFacilityUserConfig(facilityId, userId, target, onResult) {
        var path = Endpoint.base + '/config/page'

        var params = {
            facility: facilityId,
            role: userId,
            target: target
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result);
        })
    }

    /**
     * 
     * @param {string} facilityId 
     * @param {string} userId 
     * @param {Array<string>} ids
     * @param {string} service TRUE|FALSE whether or not to update service prefrences, using the IDS. Otherwise we update providers
     * @param {(Array<string>) => {}} onResult 
     */
    static updateProviderScheduleOptions(facilityId, userId, ids, service, onResult) {
        var path = Endpoint.base + '/config/page'

        var params = {
            facility: facilityId,
            role: userId,
            target: service ? 'facility-schedule-services' : 'provider-schedule',
            config: ids
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result);
        })
    }

    /**
     * User config editing. This only allows editing to configs that the user owns, and not any facility related configs that control role access
     * Please note that the usual `role` parameter has been removed, but refers to user id in the database
     * @param {string} facilityId
     * @param {string} target
     * @param {any} value
     * @param {() => {}} onResult 
     */
    static updateTargetedFacilityUserConfig(facilityId, target, value, onResult) {
        var path = Endpoint.base + '/config/page'

        var params = {
            facility: facilityId,
            target: target, //please note that setting this to `home` will not work
            config: value
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result);
        })
    }

    /**
     * Admin web call for setting configs. Only users with the ['manage-facility'] access can use this. For anything else, please use updateFacilityScheduleOptions, or an updated version of that endpoint
     * @param {string} facilityId
     * @param {string} role
     * @param {Array<string>} target
     * @param {(Array<string>) => {}} onResult 
     */
    static updateTargetedFacilityConfig(facilityId, role, target, ids, onResult, dontCache) {
        var path = Endpoint.base + '/config/page/admin'

        var params = {
            facility: facilityId,
            role: role,
            target: target,
            config: ids
        }

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, false, false)
            onResult(result);
        })
    }
}