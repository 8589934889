import React from 'react';
import {Card} from 'react-bootstrap'
import { StringResources } from '../../strings/StringManager';
import Colors from '../../util/Colors'

export default class SelectRoomPage extends React.Component{

    renderRoomList(occupied) {
      return this.props.rooms.map((room) => {
        if(room.occupied !== occupied) return null
        if(room.disabled) return null
        return (
          <row>
          <RoomButton 
            newId={this.props.newId}
            room={room}
            machine={this.props.machine}
            onSelectRoom={this.props.onRoomSelected}
          />
          </row>
        )
      })
   }
  
    render(){
      const patientLocalized = StringResources.patient();
      const showRooms = this.props.roomVisibility ? this.props.roomVisibility : ["occupied", "unoccupied"];
      let occupiedLabel = this.props.showLabels ? `Occupied Rooms. Click to manage ${patientLocalized}.` : "";
      let unoccupiedLabel = this.props.showLabels ? `Unoccupied Rooms. Click to admit ${patientLocalized}.` : "";

      if(this.props.unoccupiedLabelOverride){
        unoccupiedLabel = this.props.unoccupiedLabelOverride
      }

      if(this.props.occupiedLabelOverride){
        occupiedLabel = this.props.occupiedLabelOverride
      }

      const occupiedList = showRooms.indexOf("occupied") > -1 ? (
          <div align="center" style={{marginTop: "30px"}}>
            {occupiedLabel}
            {this.renderRoomList(true)}
          </div>) : null;
      const unoccupiedList = showRooms.indexOf("unoccupied") > -1 ? (
          <div align="center" style={{marginTop: "30px"}}>
            {unoccupiedLabel}
            {this.renderRoomList(false)}
          </div>
      ) : null;

      return(
        <>
          {occupiedList}
          {unoccupiedList}
          <div style={{height:60}}/>
        </>
      )
    }
  }
  
  class RoomButton extends React.Component{
  
    constructor(){
      super()
  
      this.state = {
        color: Colors.NavGray
      }
    }

    componentDidMount(){
      if(this.props.newId === this.props.room.id) this.onClick();
    }
  
    onClick = () => {
      this.props.onSelectRoom(this.props.room)
    }
  
    onMouseOver = () => {
      this.setState({
        color: Colors.Highlight
      })
    }
  
    onMouseLeave = () => {
      this.setState({
        color: Colors.NavGray
      }) 
     }
  
    getStyle(){
      return{
        width:"300px",
        display:"block",
        marginLeft:"auto",
        marginRight:"auto", 
        backgroundColor:this.state.color
      }
    }
  
  
    render(){
      var label = this.props.room.name
      if(this.props.room.occupied) label += " - " + this.props.room.identifier
      return(
        <div class="container">
          <div class="row">
            <div class="col text-center">
            <Card style={this.getStyle()}>
            <a style={{ cursor: "pointer" }} onClick={this.onClick} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
              <Card.Body>
                 <Card.Text variant="primary" style={{fontSize:"12px", textAlign:"center"}}>{label}</Card.Text>
              </Card.Body>
              </a>
            </Card>
            </div>
         </div>
        </div>
      )
    }
  }