import React from 'react';
import Loader from 'react-loader-spinner'
import Colors from './Colors';

export default class LoadingSpinner extends React.Component{
    render(){
        var size = this.props.size != null ? this.props.size : 80
        var marginTop = this.props.marginTop != null ? this.props.marginTop : 30
        return <div align="center" style={{marginTop:marginTop}}><Loader type="TailSpin" color={this.props.color??Colors.Primary.Main} height={size} width={size}/></div>
    }
}