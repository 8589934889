export const ScheduleForwardMachine = {
    initial: 'Load',
    states: {
        Load: {
            on: {
                NEXT: 'Options',
                CANCEL: 'stop'
            }
        },
        Options: {
            on: {
                DISCHARGE_PATIENT_SELECTED: 'DischargePatients',
                PROVIDER_AVAILABILITY_SELECTED: 'ProviderAvailability',
                TEAM_CONFERENCES_SELECTED: 'TeamConferences',
                ADMIT_PATIENTS_SELECTED: 'AdmitPatients',
                GENERATE_SCHEDULE_SELECTED: 'GenerateSchedule',
                GENERATE_2FERS_SELECTED: 'Generate2ferSchedule'
            }
        },
        DischargePatients: {
            on: {
                NEXT: 'ProviderAvailability',
                PREVIOUS: 'Options',
                CANCEL: 'Options'
            }
        },
        ProviderAvailability: {
            on: {
                CANCEL: 'Options',
                NEXT: 'TeamConferences',
                PREVIOUS: 'Options',
                EDIT_TIMES: 'EditProviderTimeBlock'
            }
        },
        EditProviderTimeBlock: {
            on: {
                POPUP: 'EditProviderTimeBlockPopup',
                CANCEL: 'ProviderAvailability',
                FINISH: 'ProviderAvailability',
            }
        },
        EditProviderTimeBlockPopup: {
            on: {
                CANCEL: 'EditProviderTimeBlock',
                FINISH: 'EditProviderTimeBlock',
            }
        },
        TeamConferences: {
            on: {
                CANCEL: 'Options',
                NEXT: 'AdmitPatients',
                PREVIOUS: 'Options'
            }
        },
        AdmitPatients: {
            on: {
                CANCEL: 'Options',
                NEXT: 'GenerateSchedule',
                PREVIOUS: 'Options'
            }
        },
        GenerateSchedule: {
            on: {
                REQUEST_CONFIRMATION: 'RequestScheduleForwardConfirmation',
                NEXT: 'Options',
                PREVIOUS: 'AdmitPatients',
                CANCEL: 'Options'
            }
        },
        RequestScheduleForwardConfirmation: {
            on: {
                CONFIRM: 'GenerateSchedule',
                REJECT: 'Options'
            }
        },
        Generate2ferSchedule: {
            on: {
                NEXT: 'Options',
                PREVIOUS: 'AdmitPatients',
                CANCEL: 'Options'
            }
        },
        stop: {}
    },
}