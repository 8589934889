import React from 'react'
import './selectServiceList.css'
import Helpers from '../../util/Helpers'
import { Button } from 'react-bootstrap'
import './selectServiceList.css'
import NavigationHeader from '../../ui/NavigationHeader'
import Colors from '../../util/Colors'

export default class SelectServiceList extends React.Component{

    CREATE_NEW_SERVICE = 'Create New Service'
    constructor(props){
        super(props)
        document.body.style = 'background:' + Colors.Secondary.Light + ';';
    }

    render(){
        var serviceEnabledButtons = []
        var serviceDisabledButtons = []
        if(Array.isArray(this.props.facility.services)){
            Helpers.sortServices(this.props.facility.services).forEach(v => {
                if(v.disabled){
                    serviceDisabledButtons.push(this.renderButton(v))
                }
                else{
                    serviceEnabledButtons.push(this.renderButton(v))
                }
            })
        }

        return <>
            <NavigationHeader navigationOptionsTitles={[this.CREATE_NEW_SERVICE]} onNewOption={this.onOptionClicked}/>
            <div className="serviceContainer">
                {serviceEnabledButtons}
            </div>
            
            <div className="serviceContainer">
                {serviceDisabledButtons}
            </div>
        </>
    }

    renderButton = (service) => {
        return (
            <Button className="selectServiceButton" 
                variant="light" 
                onClick={() => this.props.onEditService(service.id)} 
                style={{borderColor:"#000000", width: 300, marginTop: 15}}>
                    {service.name}{service.disabled?" (Disabled)":null}
            </Button>
        )
    }

    onOptionClicked = (option) => {
        switch(option){
            case this.CREATE_NEW_SERVICE:
                this.props.onNewService()
                break;
        }
    }
}