import React from 'react';
import {Button, Form} from 'react-bootstrap'
import Colors from '../../util/Colors'
import CalendarUtil from '../../util/CalendarUtil';
import App from '../../App'
import Tracker from '../../managers/Tracker'
import editIcon from '../../img/Icons/Edit.svg'
import Helpers from "../../util/Helpers";
import {AppointmentV2DBItem} from "../../models/Appointment";


export default class ReviewPage extends React.Component{

    constructor(props) {
      super(props)
      Tracker.logScreenView('schedule_appointment_review')
    }

    cancel = () => {
      this.props.cancel()
    }
  
    finish = (scheduleAnother) => {
      this.props.onFinish(document.getElementById("notes").value, scheduleAnother)
    }

    getProviderFullNames(){
        let providerNames = []
        const providers = this.props.appointment.providers //this is an id
        providers.forEach(providerId => {
        if(providerId === this.props.facility.offsiteProviderId){
          providerNames.push((<div>{"Offsite"}</div>))
        }
        else{
          let provider = this.props.facility.providers[providerId]
          providerNames.push((<div>{provider.firstName + " " + provider.lastName}</div>))
        }
      });
      if(providerNames.length === 0){
        providerNames.push('None')
      }
      return providerNames
    }

    getFullTime(){
      if(CalendarUtil.isTimeRangeFullDay(this.props.appointment.time))
        return "All Day"

      var shortStartTime = CalendarUtil.getShortTime(new Date(this.props.appointment.time.startTime))
      var shortEndTime = CalendarUtil.getShortTime(new Date(this.props.appointment.time.endTime))
      return shortStartTime + " - " + shortEndTime
    }
    
    getDate(){
      var d = new Date(this.props.appointment.time.startTime)
      var display = CalendarUtil.getDayOfWeekString(d.getDay()) + " " + CalendarUtil.getMonthString(d.getMonth()) + " " + d.getDate() + ", " + d.getFullYear()

      return display
    }

    getOccupancies(){
        /**
         * @type {AppointmentModel}
         */
        let appointment = this.props.appointment
        // appointment.occupancies is for the data that we are using before building the new appointment model, so it will not be defined
        if (appointment.occupancies) {
            return this.props.appointment.occupancies.map(occupancy => (
                <div>{occupancy.name + " - " + occupancy.identifier}</div>))
        }
        else if(appointment.occupants && appointment.occupants.length > 0){
            return appointment.occupants
                .map(occupancy => (
                    <div>
                        {Helpers.getRoomNameFromId(this.props.facility, occupancy.roomId) + " - " + occupancy.name}
                    </div>
                ))
        }
        else {
            return [(this.props.appointment.occupancy.name + " - " + this.props.appointment.occupancy.identifier)]
        }
    }

    render(){

      var scheduleAnother = this.props.editing ? null : <Button variant="primary" onClick={() => this.finish(true)} style={{backgroundColor: Colors.Primary.Main, border: "0px"}}>Submit and Schedule Another Appointment</Button>

      var dateAndTime = (
        <div>
          {this.getDate()}
          <br/>
          {this.getFullTime()}
        </div>
      )

      var serviceType = (<ReviewItem label="Service Type" value={this.props.appointment.service.name}/>)
      var serviceTitle = (<ReviewItem label="Service Title" value={this.props.appointment.title}/>)
      var providers = (<ReviewItem label="Providers" value={this.getProviderFullNames()}/>)
      var occupancy = this.props.appointment.service.providerOnly ? null : (
          <ReviewItem label="Occupancies" value={this.getOccupancies()}/>
      )
      var date = (<ReviewItem label="Date and Time" value={dateAndTime} onEdit={this.props.onEditTime}/>)
      var location = this.props.appointment.location ? (<ReviewItem label="Location" value={this.props.appointment.location.name} onEdit={this.props.onEditLocation}/>) : null
      var notes = [
        (<ReviewItem label="Notes" value={null}/>),
        (
          <div align="center">
            <Form style={{width:400, zIndex:2}}>
              <div align="left" style={{color: "#808080"}}>
                {App.placeholder}
              </div>
              <Form.Group>
                <Form.Control id="notes" as="textarea" rows="2" defaultValue={this.props.appointment.notes} />
              </Form.Group>
            </Form>
          </div>
        )
      ]

      var reviewItems = [
        serviceType,
        serviceTitle,
        providers,
        occupancy,
        date,
        location,
        notes
      ]
  
      return(
        <>
          <div style={{padding:"16px"}}>
            {reviewItems}
          </div>
        
          <div align="center" style={{margin:24}}>
            <Button variant="link" onClick={this.cancel} style={{color: Colors.Primary.Main}}>Cancel</Button>
            <Button variant="primary" onClick={() => this.finish(false)} style={{backgroundColor: Colors.Primary.Main, border: "0px", marginLeft:10, marginRight:10}}>Submit</Button>
            {scheduleAnother}
          </div>

        </>
      )
    }
  }

  class ReviewItem extends React.Component{

    labelStyle = {
      fontSize:"14px"
    }

    valueSize = {
      fontSize:"18px",
      color: Colors.Primary.Main
    }

    render(){
      var editButton = this.props.onEdit ? (
        <img style={
            {
              width: '21px',
              height: '21px',
              cursor: 'pointer'
            }
          }
          src={editIcon} 
          title="Edit" 
          onClick={this.props.onEdit}/>
      ) : null
      return(
        <div align="center" style={{padding: "16px"}}>
        {this.props.label ? <div style={this.labelStyle}>{this.props.label} {editButton}</div> : null}
        <div style={this.valueSize}>{this.props.value}</div>
        </div>
      )
    }
  }