import React from 'react';
import TimePicker from '../react-time-picker/src/entry';
import CalendarUtil from './CalendarUtil';
import TimeKeeper from 'react-timekeeper'
import {Row} from 'react-bootstrap'
import SDNavBar from '../ui/SDNavBar';

export default class HybridTimePicker extends React.Component{

    static active = null

    constructor(props){
        super(props)
        this.ref = React.createRef();
    }

    buildTimePicker(){
        return(
            <div style={{marginLeft:20}}>
                <TimePicker
                value={new Date(this.props.value)}
                clearIcon={null}
                clockIcon={null}
                disableClock={true}
                onChange={this.onChange}
                />
            </div>
        )
    }

    onChange = (value) => {
        var bt = CalendarUtil.breakTime(value)

        var d = new Date(this.props.value)
        d.setHours(bt[0])
        d.setMinutes(bt[1])
        d.setSeconds(0)
        d.setMilliseconds(0)

        this.props.onChange(d)
    }

    buildTimeKeeper(){
        if(HybridTimePicker.active != this) return null
        var openUp
        
        if(this.ref.current){
            var popupTop = this.ref.current.getBoundingClientRect().top
            var windowHeight = window.innerHeight - SDNavBar.HeaderHeight
            if(popupTop < windowHeight / 1.5) openUp = false
            else openUp = true
        }

        var style = {
            position: "absolute",
        }

        if(openUp) style.bottom = 0
        else style.top = 0
        
        return(
            <div style={style}>
            <TimeKeeper
            switchToMinuteOnHourSelect={true}
            onDoneClick={() => {
                this.hideTimeKeeper()
            }}
            onChange={(newTime) => {
                this.onChange(newTime.hour24 + ":" + newTime.minute)
            }}
            config={{
                useCoarseMinutes: true
            }}
            time={CalendarUtil.getShortTime(new Date(this.props.value))}
        />
        </div>
        )
    }

    hideTimeKeeper = () => {
        HybridTimePicker.active = null
        if(this.props.listener) this.props.listener.forceUpdate()
    }

    openTimeKeeper = () => {

        if(HybridTimePicker.active == this){
            this.hideTimeKeeper()
            return
        }

        HybridTimePicker.active = this
        if(this.props.listener) this.props.listener.forceUpdate()
    }

    render(){
        var clockIcon = <img onClick={this.openTimeKeeper} style={{position:"absolute", marginTop:6, height:16, width:16}} src={require('../img/Icons/clock.png')}/>

        return(
            <Row ref={this.ref}>
                {clockIcon}            
                {this.buildTimePicker()}
                <div style={{zIndex:102, position:"absolute"}}>
                {this.buildTimeKeeper()}
                </div>
            </Row>
        )
    }
}