import React from 'react';
import NavigationHeader from '../ui/NavigationHeader';
import DatePicker from "../react-datepicker/src";
import { Button } from 'react-bootstrap';
import "../react-datepicker/src/stylesheets/datepicker.scss";

export default class DateSelectPage extends React.Component{
    constructor(props){
        super(props)
        window.scrollTo(0, 0)
        this.state = {
            selectedDate: this.props.date ?? new Date(),

            //storing show notes in a localstorage object to remember preference per user. Default to off
            showNotes: JSON.parse(localStorage.getItem('showNotesForPrint')) ?? false,
            includeEmptyTimes: JSON.parse(localStorage.getItem('showEmptyTimesForPrint')) ?? true
        }
    }

    render(){
        const canDisplayNotesToggle = this.props.canDisplayNotes ?? true //true by default
        const canDisplayEmptyTimesToggle = this.props.canDisplayEmptyTimes ?? false // false by default
        return <>
            <NavigationHeader navigationBackTitle="Back" onNavigateBack={this.props.onCancel} title={this.props.title ?? "Select a date"}/>
            <div align="center" style={{marginTop: '16px'}}>
                <div>
                    <DatePicker 
                        inline
                        minDate={new Date()}
                        selected={this.state.selectedDate}
                        highlightSelected={true}
                        onChange={this.onDateChange}/>
                    {canDisplayNotesToggle && (
                        <div style={{marginBottom: '8px'}}>
                            <input type={"checkbox"} style={{marginRight: '8px'}} checked={this.state.showNotes} onChange={(e)=>{
                                this.setState({showNotes: e.target.checked})
                                localStorage.setItem('showNotesForPrint', e.target.checked.toString())
                            }}/>
                            Include appointment notes
                        </div>
                    )}
                    {canDisplayEmptyTimesToggle && (
                        <div style={{marginBottom: '8px'}}>
                            <input type={"checkbox"} style={{marginRight: '8px'}} checked={this.state.includeEmptyTimes} onChange={(e)=>{
                                this.setState({includeEmptyTimes: e.target.checked})
                                localStorage.setItem('showEmptyTimesForPrint', e.target.checked.toString())
                            }}/>
                            Don't exclude empty rows
                        </div>
                    )}
                </div>
                <div>
                    <Button style={{width: 300}} onClick={this.onSubmitDate}>{this.props.submitTitle ?? "Submit"}</Button>
                </div>
            </div>            
        </>
    }

    onSubmitDate = () => {
        this.props.onSubmit(this.state.selectedDate, this.state.showNotes, this.state.includeEmptyTimes)
    }

    onDateChange = (date) => {
        this.setState({
            selectedDate: date
        })
    }
}