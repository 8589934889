import React from 'react'
import './scheduleByConfigurationServiceOptions.css'
import TimeBlockPicker from '../../../ui/TimeBlockPicker'


export default class ScheduleByConfigurationServiceOptions extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            timeBlocks: {
                times: []
            }
        }
    }


    render(){
        var service = this.props.service
        var durationStatus = service.defaultDuration && service.defaultDuration > 0 ? "" : "-error"
        return (
            <div className='configuration-service-options-container'>
                <div className="durationContainer">
                    <span className='duration-title'>Default Duration</span>
                    <input className={`duration${durationStatus}`} type='number' onChange={this.onDurationChange} value={service.defaultDuration}/>
                    <span className={`duration-text${durationStatus}`}>min</span>
                </div>
                {this.drawEditTimeBlocks()}
            </div>
        )
    }

    drawEditTimeBlocks(){

        var allValid = true
        this.state.timeBlocks.times.forEach(e => {
            if(!e.valid){
                allValid = false
                return
            }
        })


        if(this.props.service.timeBlocks){
            var convertedTimes = []
            this.props.service.timeBlocks.forEach(time => {
              
                var start = time.start / 60 / 1000
                var end = time.end / 60/ 1000

                var t = {
                    valid: true,
                    start:{
                        hour24: Math.floor(start / 60),
                        minute: start % 60
                    },
                    end:{
                        hour24: Math.floor(end / 60),
                        minute: end % 60
                    }
                }

                convertedTimes.push(t);
            })
            this.state.timeBlocks.times = convertedTimes
        }

        return (
            <>
                <TimeBlockPicker facility={this.props.facility} valid={this.state.valid} timeBlocks={this.state.timeBlocks} onTimeBlockChange={this.onTimeBlockChange}></TimeBlockPicker>
                <button className="timeBlockPickerAddButton">+</button>
            </>
        )
    }

    onDurationChange = (element)=> {
        var input = element.target.value
        if(input.length > 2){
            input = input.substring(0, 2)
        }
        var value = parseInt(input)
        this.props.onServiceValueChanged('defaultDuration', value)
    }

    onTimeBlockChange = (timeBlocks) => {
        var convertedTimes = []

        timeBlocks.times.forEach(time => {
            var t = {
                start: (time.start.hour24 * 60 + time.start.minute) * 60 * 1000,
                end: (time.end.hour24 * 60 + time.end.minute) * 60 * 1000

            }
            convertedTimes.push(t);
        })

        this.props.onServiceValueChanged('timeBlocks', convertedTimes)

        this.setState({
            timeBlocks: timeBlocks
        });
    }
}