import SchedulingCalendar from "./SchedulingCalendar";
import React  from 'react';
import {Navbar} from 'react-bootstrap'
import CalendarUtil from '../util/CalendarUtil'
import Colors from '../util/Colors'
import SchedulePopup from '../pages/ManagePatients/SchedulePopup';
import Helpers from '../util/Helpers';
import {ScheduleTimeSlot} from "../models/ScheduleAppointmentHolder";

/**
 * Rendered via:
 * - WeekSchedulePage
 *
 * Provided with schedule in the form of ScheduleColumn[]
 */
export default class OnboardingCalendar extends SchedulingCalendar{
    constructor(props){
        super(props)
        if(props.service && props.service.fullDay){
            this.handleFullDayOverride()
        }
        this.state.zIndexLines = 0
        this.state.zIndexHeader = 5
        this.state.zIndexDefaultBlob = 1
        this.state.zIndexAvailable = 0
        this.state.zIndexSelected = 2

        //54 comes from a WeekSchedulePage div height that shows a white background under buttons
        //HeaderHeight is the height of the sub header
        this.state.scrollWindowAdditionalMarginBottom = 54 
        this.state.calendarType = "Onboarding"
    }

    appointmentType = {
        invalid: -1,
        unavailable: 0,
        available: 1,
        appointment: 2,
        stagedAppointment: 3,
        selected: 6,
        incompatible: 7,
    }

    drawHeaders(){
        const headers = [0,1,2,3,4,5,6].map((day) => {
          const d = new Date(this.props.anchorDate)
          d.setDate(d.getDate() + day)
  
          const dow = CalendarUtil.getDayOfWeekString(day, true).toUpperCase()
  
          return <div style={this.getHeaderStyle(day)}>{dow} <br/> {d.getDate()}</div>
        })
        return (
            <Navbar style={{position: 'sticky', top: 0, zIndex: this.state.zIndexHeader}}>
                {headers}
            </Navbar>
        )
      }
  
      drawDateAndTime(){
          return null
      }

      drawFilledTime(){
      /**
       * @type {ScheduleColumn[]}
       */
        const schedule = this.state.schedule
          const stagedDeletions = this.props.stagedDeletions
          const stagedDeletionsIds = stagedDeletions.map(e => e.appointmentId)

          for (const scheduleColumn of schedule) {
              let addedAppointments = [];
              let overlappedAppointments = [];
              //if scheduleColumn.list() is not a function, we want to log it and continue
                if(typeof scheduleColumn.list !== "function"){
                    console.error("scheduleColumn.list() is not a function!", scheduleColumn)
                    console.error(scheduleColumn)
                    continue;
                }
              scheduleColumn.list().forEach(e => {
                  if(stagedDeletionsIds.includes(e.get()?.id)){
                      overlappedAppointments.push(e)
                      return null
                  }

                  scheduleColumn.list().forEach(d => {
                      if(stagedDeletionsIds.includes(d.get()?.id)) return null
                      if(overlappedAppointments.includes(d.id)) return null
                      if(d === e || d.get()?.serviceId != null) return null
                      let eStart = e.start
                      let eEnd = e.end
                      let dStart = d.start
                      let dEnd = d.end

                      if(eStart < dStart && eStart < dEnd && eEnd > dStart && eEnd > dEnd) overlappedAppointments.push(d) //fully overlapped - remove entirely

                      if(eStart < dStart && eStart < dEnd && eEnd > dStart && eEnd < dEnd) d.start = eEnd //partial overlap

                      if(eStart > dStart && eStart < dEnd && eEnd > dStart && eEnd > dEnd) d.end = eStart //partial overlap

                      if(eStart >= dStart && eStart < dEnd && eEnd > dStart && eEnd <= dEnd) {
                          let clone = new ScheduleTimeSlot(eEnd, eStart, true, d.id, d.label)
                          clone.start = eEnd
                          d.end = eStart
                          addedAppointments.push(clone)
                      }
                  })

              })

              addedAppointments.forEach(e => {
                  scheduleColumn.list().push(e)
              })

              overlappedAppointments.forEach(e => {
                  scheduleColumn.list().splice(scheduleColumn.list().indexOf(e), 1)
              })
          }
        return this.generateBlobs(schedule)
      }

    /**
     *
     * @param scheduleItem {AppointmentModel}
     * @param props
     * @returns {boolean}
     */
    isEditable(scheduleItem, props){
        if(!scheduleItem) return false
        let providerIds = scheduleItem.providers ? scheduleItem.providers.map(p => p.id) : []
        let occupancyIds = scheduleItem.occupants ? scheduleItem.occupants.map(o => o.id) : []
        let selectedOccupancyIds = (Array.isArray(this.props.selectedRoom) ? this.props.selectedRoom : [this.props.selectedRoom]).map(room => room.occupancyId)

        if(props.service.providerOnly && Helpers.hasSomeMatching(providerIds, props.selectedProviders)){
            return true
        }
        else if(scheduleItem && props.service.providerOnly && !props.service.pickProvider){
            return scheduleItem.serviceId === props.service.id;
        }
        else if(scheduleItem && selectedOccupancyIds.length > 0){
            return Helpers.areArraysTheSame(occupancyIds, selectedOccupancyIds)
        }
        return false
    }

    /**
     *
     * @param scheduleItem {AppointmentModel}
     * @param editable {}
     * @param props
     * @returns {number}
     */
    getAppointmentType(scheduleItem, editable, props){
        let type = this.appointmentType.unavailable
        if(editable && props.service){
            let providerIds = scheduleItem.providers ? scheduleItem.providers.map(p=>p.id) : []

            if(props.service.providerOnly && props.service.pickProvider){
                type = Helpers.areArraysTheSame(providerIds, props.selectedProviders) && scheduleItem.serviceId === props.service.id
                    ? this.appointmentType.appointment 
                    : this.appointmentType.unavailable
            }
            else if(props.service.id === scheduleItem.serviceId){
                type = this.appointmentType.appointment
            }
        }
        return type
    }

    /**
     *
     * @param schedule {ScheduleColumn[]}
     * @returns {*[]}
     */
    generateBlobs(schedule){
        const blobs = []
        for (const column of schedule) {
            for(const apptHolder of column.list()){
                let stagedForDeletion = false
                this.props.stagedDeletions.forEach(u => {
                    if(u.id === apptHolder.get()?.id) {
                        stagedForDeletion = true
                        return null
                    }
                })

                if(stagedForDeletion) continue;

                let editable = this.isEditable(apptHolder.get(), this.props)

                let unstaged = false

                this.props.unstagedAppointments.forEach(appt => {
                    if(appt.start.getTime() === apptHolder.start){
                        unstaged = true
                    }
                })

                if(unstaged) continue;
                let type = this.getAppointmentType(apptHolder.get(), editable, this.props)

                blobs.push({
                    type: type,
                    appointment: apptHolder.get()
                })
            }
        }
        return blobs
    }

      drawOpenTime(){
        let appts
        let blobs = []
      
        let id = 0
      
        //fill open time one day at a time
        for(let i = 0; i < 7; i++){
      
            let today = new Date(this.props.anchorDate.valueOf())
            today.setDate(today.getDate() + i)
      
            let ft = this.state.timeRange[0]
            let lt = this.state.timeRange[this.state.timeRange.length - 1]

            let start = new Date(today.valueOf())
            start.setHours(ft.getHours())
            start.setMinutes(ft.getMinutes())
            start.setSeconds(0)
            start.setMilliseconds(0)

            let end = new Date(today.valueOf())
            end.setHours(lt.getHours())
            end.setMinutes(lt.getMinutes())
            end.setSeconds(0)
            end.setMilliseconds(0)
      
            appts = []

            /**
             * @type {ScheduleColumn[]}
             */
            let schedule = this.state.schedule
            let currentColumn = schedule[i]

            for(const apptHolder of currentColumn.list()){
                let stagedForDeletion = false
                this.props.stagedDeletions.forEach(u => {
                    if(u.appointmentId === apptHolder.get()?.id) {
                        stagedForDeletion = true
                        return null
                    }
                })

                if(stagedForDeletion) continue;

                let unstaged = false

                this.props.unstagedAppointments.forEach(appt => {
                    if(appt.start.getTime() === apptHolder.start){
                        unstaged = true
                    }
                })

                if(unstaged) continue;
                appts.push(apptHolder) // no need to time check to add
            }

            this.props.stagedAppointments.forEach(e => {
                let d = new Date(e.start.getTime())
                if(d.getDate() === today.getDate()){
                    appts.push(e)
                }
            })
      
      
            let d = start
      
            Helpers.sortObjectByTimes(appts)

            appts.forEach(e => {
                let eStart = new Date(e.start)
                let eEnd = new Date(e.end)
                if(eStart > start.getTime() && d.getTime() < end.getTime()){ //make sure this does not try to draw outside our desired range
                    this.drawOpenBlob(blobs, d, eStart)
      
                    d = eEnd
                }
                else{
                    console.error(`tried to draw outside of range! ${e.id}`)
                }
            })
      
            //draw blob from end of last appointment to end of day
            this.drawOpenBlob(blobs, d, end)
        }
      
        return blobs
      }
      
      drawOpenBlob(blobs, start, end){
          const d = new Date()
          if(this.props.service && this.props.service.fullDay)
            d.setHours(0, 0, 0, 0)
          const now = d.getTime()
          const isPast = start.getTime() < now
          const isCurrent = start.getTime() < now && end.getTime() > now
      
          //if the current time exists within this blob...
          //block all time in blob up to the next quarter hour
          if(isCurrent){
              let m = (15 * Math.ceil(d.getMinutes() / 15))
      
              d.setMinutes(m)
      
              blobs.push({
                  appointment: {
                      start: start,
                      end: d
                  },
                  type: this.appointmentType.unavailable
              })

              start = d
          }
      
          blobs.push({
              appointment: {
                  start: start,
                  end: end
              },
              type: isPast && !isCurrent? this.appointmentType.unavailable : this.appointmentType.available
          })
      }

      drawBlobs(){
        let blobs = this.drawFilledTime()
  
        blobs = blobs.concat(this.drawSelectedBlob())
        blobs = blobs.concat(this.drawStagedAppointments())
        blobs = blobs.concat(this.drawOpenTime())
  
        let i = 0
        return blobs.map((e) => {
            return this.drawBlob(e.appointment, e.type, i++)
        })
      }
  
      drawSelectedBlob(){
  
          if(this.state.clickedBlob == null || this.state.time == null) return {}
  
          if(this.state.clickedBlob.type != this.appointmentType.available) return {}
  
          return{
              type: this.appointmentType.selected,
              appointment:{
                  start: this.state.time.start,
                  end: this.state.time.end
              }
          }
      }

    /**
     *
     * @param appointment {AppointmentModel}
     * @param type
     * @param id
     */
      drawBlob(appointment, type, id){
        if(appointment == null) return
        /**
         * @type {number}
         */
        let startTime = appointment.start.getTime?.() ?? appointment.start
        /**
         * @type {number}
         */
        let endTime = appointment.end.getTime?.() ?? appointment.end
        let dow = new Date(startTime).getDay()
        let service = appointment.serviceId

        let blobStyle = this.getBlobStyle(dow, type, startTime, endTime, id, service)
  
        this.state.blobs[id] = {
            id: id,
            startTime: startTime,
            endTime: endTime,
            type: type,
            top: blobStyle.top,
            height: blobStyle.height,
            appointmentId: appointment.id,
            appointment: appointment
        }
  
        let location = this.getLocation(appointment)
        if(location)
            location = `, ${location}`
        else
            location = ""
        let providerIds = appointment.providers ? appointment.providers.map(p => p.id) : []
        let occupancies = appointment.occupants ? appointment.occupants : []

        let label = ""
        if(blobStyle.height > 20 && occupancies.length > 0){
            let notes = appointment.notes ? "\n" + appointment.notes : ""
            label = `${appointment.title}${location} - ${occupancies.map(o => o.name).join(', ')}${notes}`
        }
        else if(blobStyle.height > 20 && appointment.title && appointment.notes){
            label = `${appointment.title}${location}\n${appointment.notes}`
        }
  
        if(providerIds.includes(this.props.facility.offsiteProviderId)){
            label = appointment.notes
            if(label == null || label === "") label = "Offsite"
        }
        if(!label && appointment.title){
            label = appointment.title
        }
  
        let textStyle = Helpers.getBlobTextStyle(blobStyle)
        textStyle.fontSize = 10
        return(
          <div>
            <div onClick={this.onClickBlob} id={id} style={blobStyle}/>
                <div style={textStyle}>
                    {label}
                </div>
          </div>
        )
    }

    /**
     *
     * @param appointment {AppointmentModel}
     * @returns {*|null}
     */
    getLocation = (appointment) => {
        let service = Helpers.getService(appointment.serviceId, this.props.facility.services);
        if(Helpers.shouldShowSingleOccupantAsLocation(appointment, service)){
            return appointment.occupants[0].roomName //could be undefined, but that gets handled outside of this
        }
        else{
            let locationId = appointment.locations && appointment.locations.length > 0 ? appointment.locations[0].id : null
            if(!locationId) return null
            let location = this.props.facility.locations[locationId];
            if(!location) return null
            return location.name
        }
    }
  
    openPopup(id, offsetY, pageY){
        let blobs = this.state.blobs
        let blob = blobs[id]
        if(blob == null) return

        switch (blob.type){
            case this.appointmentType.unavailable:
            case this.appointmentType.incompatible:
                return
            default: // do nothing
        }
        this.getClickedTime(offsetY, pageY, blob)
        this.setState({
            clickedBlob: blob
        })
        this.props.machine.send("POPUP")
    }

    getMachineState = () => {
        return this.props.machine.state.value[this.props.stateMachineName ? this.props.stateMachineName : "ManagePatients"]
    }
  
    onClickBlob = (e) => {
      let id = e.target.id
        let blob = this.state.blobs[id]
      if(this.state.clickedBlob && this.state.time){
          if(blob.startTime === this.state.time.start) return
      }
  
      let offsetY = e.nativeEvent.offsetY
      let pageY = e.nativeEvent.pageY
  
      let state = this.getMachineState()
      if(state.toLowerCase().includes("popup")){
          this.props.machine.send("CLOSE")
          this.setState({clickedBlob: null},() => {
              this.openPopup(id, offsetY, pageY)
          })
      }else{
          this.openPopup(id, offsetY, pageY)
      }
  
  }
  
    getBlobStyle(day, type, startTime, endTime, id, service){
  
      const selected = this.state.clickedBlob != null && id === this.state.clickedBlob.id
  
      let border = "1px solid white"
      let boxShadow = null
      let color
      switch(type){
          case this.appointmentType.unavailable:
          case this.appointmentType.incompatible:
            color = Colors.DarkGray
            break;
  
          case this.appointmentType.available:
            color = Colors.Primary.Main
            break;
  
          case this.appointmentType.appointment:
            color = Colors.GrayGreen
            break;
  
          case this.appointmentType.stagedAppointment:
            color = Colors.Green
            break;
  
          case this.appointmentType.selected:
            color = Colors.Green
            break;
  
          default:
            color = Colors.Green
            break;
      }
  
      if(type === this.appointmentType.selected) {
          color = Colors.Primary.Dark
          border = "5px solid " + Colors.Green
      }
  
      if(selected && type !== this.appointmentType.available 
        && this.state.time 
        && this.state.clickedBlob.appointment.id == null 
        && this.state.clickedBlob.appointment.stagingId == null
      ) {
          color = Colors.Primary.Dark
          border = "5px solid " + Colors.Green
          startTime = CalendarUtil.getShortTime(new Date(this.state.time.start))
          endTime = CalendarUtil.getShortTime(new Date(this.state.time.end))

          this.state.clickedBlob.appointment = Object.assign(window.structuredClone(this.state.clickedBlob.appointment), {
            start: new Date(startTime),
            end: new Date(endTime)
          })
      } else {
          startTime = CalendarUtil.getShortTime(new Date(startTime))
          endTime = CalendarUtil.getShortTime(new Date(endTime))
      }

      if (selected && type !== this.appointmentType.available){
        // add drop shadow
        boxShadow = '4px 4px 8px 4px rgba(0,0,0,0.5)'
      }
  
      const w = this.state.columnWidth
  
      let top = this.getTop(startTime)+this.state.marginTop
      const height = this.getHeight(startTime, endTime)

      //figure out z levels
      let zIndex = this.state.zIndexDefaultBlob

      if(type === this.appointmentType.available) zIndex = this.state.zIndexAvailable
      else if(type === this.appointmentType.selected) zIndex = this.state.zIndexSelected
      else if(type === this.appointmentType.appointment && service && this.props.service && this.props.service.id === service)
        zIndex = 2
  
      return{
          borderRadius: "4px",
          position: "absolute", 
          left: window.innerWidth * 0.1 + w * day + 5, 
          top: top, 
          backgroundColor: color, 
          width: w - 10, 
          height: height,
          border: border,
          zIndex: zIndex,
          boxShadow: boxShadow
      }
  }
  
  drawStagedAppointments(){
    let week = CalendarUtil.getWeekTimeRange(this.props.anchorDate);
  
    let blobs = []
  /**
   * @type {AppointmentModel}
   */
    let appt
    for (appt of this.props.stagedAppointments) {
        if(!CalendarUtil.doTimeRangesOverlap(week.start, week.end, appt.start.getTime(), appt.end.getTime())) continue;
        let type = this.appointmentType.stagedAppointment
        blobs.push({
            type: type,
            appointment: appt
        })
    }
    return blobs
  }
  
  onClose = () => {
      this.setState({
          clickedBlob: null
      })
  }
  
  onDelete = (e) => {
      this.setState({
          clickedBlob: null
      })
      this.props.onDelete(e)
  }
  
//   editTime //super
  
  drawPopup(){
  
      let validStart = true
      let validEnd = true
      
      if(this.state.time && this.state.clickedBlob){
          let startTime = new Date(this.state.time.start)
          let endTime = new Date(this.state.time.end)

          let diffMinutes = (endTime - startTime) / 60000
          if(diffMinutes < 15) validEnd = false

          let earliest = this.state.timeRange[0].getHours()
          let latest = this.state.timeRange[this.state.timeRange.length - 1].getHours()
  
          if(startTime.getHours() < earliest) validStart = false
          if(endTime.getHours() < earliest) validEnd = false
          
          if(endTime.getHours() >= latest && endTime.getMinutes() > 0) validEnd = false
          if(startTime.getHours() >= latest && startTime.getMinutes() > 0) validStart = false

          // check if appointment is in the past
          if(startTime < new Date()) validStart = false
          
          startTime = startTime.getTime()
          endTime = endTime.getTime()

          // check against existing appointments and blocked times
          let blobs = this.drawFilledTime()
          blobs = blobs.concat(this.drawStagedAppointments())

          /**
           * @type {{appointment: AppointmentModel, [key: string]:any}}
           */
          let e
          for (e of blobs) {
              if(!e.appointment) {
                  console.error("e.appointment is null!", e)
                  continue;
              }

              // check if same appointment
              if (this.state.clickedBlob.appointment.id != null){
                if (e.appointment.id === this.state.clickedBlob.appointment.id) {
                    continue;
                }
              } else if (this.state.clickedBlob.appointment.stagingId != null) {
                if (e.appointment.stagingId === this.state.clickedBlob.appointment.stagingId) {
                    continue;
                }
              }
              
              const { start, end } = e.appointment;

              if (start.getTime() === startTime && end.getTime() === endTime) {
                  continue;
              }
              
              if(startTime >= start && startTime < end) validStart = false
              if(endTime > start && endTime <= end) validEnd = false
              if(start >= startTime && start < endTime && end >= startTime && end < endTime) validEnd = false
          }

      }
  
      let state = this.getMachineState()
  
      if(state.toLowerCase().includes('popup')){
          return this.renderPopupElement(validStart, validEnd)
      }
      else{
          return null
      }
  }

  renderPopupElement(validStart, validEnd){
    return <SchedulePopup 
        id = {this.state.clickedBlob.id}
        defaultProviders={this.props.defaultProviders ?? null}
        defaultDurationInMinutes={this.props.service.defaultDuration ? this.props.service.defaultDuration : this.state.defaultAppointmentDuration}
        validStart={validStart} 
        editTitle={this.props.service.appointmentControlsTitle}
        title={this.props.title}
        user={this.props.user} 
        validEnd={validEnd} 
        selectedProviders={this.props.selectedProviders}
        room={this.props.selectedRoom}
        blob={this.state.clickedBlob} 
        onPopupMounted={() => this.forceUpdate()}
        editTime={this.editTime} 
        machine={this.props.machine}
        providers={this.props.providers}
        validate={this.props.validate}
        onClose={this.onClose} 
        facility={this.props.facility}
        onAppointmentStaged={this.props.onAppointmentStaged}
        service={this.props.service}
        onDelete={this.onDelete}
        isGroupAppointment={this.props.isGroupAppointment}
        />
  }
  
      render(){
          return(
              <>
              {super.render(this.drawPopup())}
              </>
          )
      }

}