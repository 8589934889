import React, {useState} from "react";
import styles from './AppointmentPopup.module.css'

export default function AppointmentPopupTitleDropdown(props){
    let renderDropdownOption = (name, onChange) => {
        return <div className={styles.dropdownItem} onClick={()=>{
            setDropdown(false)
            props.onChange(name)
        }}>{name}</div>
    }

    let [dropdownActive, setDropdown] = useState(false)
    let showDropdownOptions = props.enableDropdown
    let containerStyle
    if(showDropdownOptions){
        containerStyle = dropdownActive? styles.appointmentPopupTitleDropdownOpen : styles.appointmentPopupTitleDropdown
    }
    else{
        containerStyle = styles.appointmentPopupTitle
    }
    return (
        <div className={containerStyle} onClick={()=>{
            if(!showDropdownOptions) return
            setDropdown(!dropdownActive)
        }}>
            <div>{props.title}</div>
            {dropdownActive ? (
                <div className={styles.dropdownWindow}>
                    {props.values.map((item)=>{
                        return renderDropdownOption(item)
                    })}
                </div>
            ) : null}
        </div>
    )
}