import React from 'react'
import Switch from '../../../ui/Switch';
import SDCheckBox from '../../../ui/SDCheckBox';
import SDEditTextBox from '../../../ui/SDEditTextBox';
import AssignProvidersBlock from './AssignProvidersBlock';

import './scheduleByAppointmentServiceOptions.css'
import ServiceProviderEditTextBox from './ServiceProviderEditTextBox';
import { StringResources } from '../../../strings/StringManager';



export default class ScheduleByAppointmentServiceOptions extends React.Component{
    constructor(props){
        super(props)
        
    }

    render(){
        
        var isWeek = this.props.service.scheduleStyle === "Week"
        return <div className="appointment-service-options-container">
            <div className='toggles-column'>
                {this.drawToggles()}
                {this.renderPrimaries()}
            </div>
            <div className='weekly-options-column'>
                <div className="weekly-options-switch-container">
                    <div className="weekly-options-switch-text">Use week calendar to schedule multiple appointments at once</div>
                    <div className="weekly-options-switch">
                        <Switch checked={this.props.service.scheduleStyle === "Week"} onChange={this.onSwitchChanged}/>
                    </div>
                </div>
                <div className="weekly-options">
                    <SDCheckBox disabled={!isWeek} checked={this.props.service.conflicts} onCheckChanged={(checked)=>{this.onCheckChanged('conflicts', checked)}} label={"Conflict with other appointments of this type"}/>
                    {this.drawWeeklyAppointmentRoles(isWeek)}
                </div>
            </div>
        </div>
    }

    drawProviderToggle = (index, allProviders, isWeek) => {

        var suffix = allProviders ? "unselected" : "selected"

        return (
            <div className={"attendee-roles-toggle-container-" + suffix} onClick={() => this.toggleProviderButton(index, allProviders, isWeek)}>
                <img
                className="attendee-roles-toggle"
                src={allProviders ? require('../../../img/Icons/any-provider.png') : require("../../../img/Icons/certain-providers.png")}
                />
            </div>
        )
    }

    toggleProviderButton = (index, allProviders, isWeek) => {
        if(!isWeek) return
        this.onRoleProviderCheckedChanged(index, !allProviders);
    }

    renderPrimaries = () => {
        let primaries = this.props.service.usePrimary ? this.props.service.primaries : null
        if(primaries){
            let missingProviders = false
            let renderedProviders = primaries.map((providerId)=>{
                let provider = this.props.facility.providers[providerId]
                let name = `${provider.firstName} ${provider.lastName}`
                let warning
                if(!this.props.providers[providerId]){
                    warning = true
                    missingProviders = true
                }
                return <div className="primary-provider-value" style={{color: warning ? "red" : null}}>
                    {name}
                </div>
            })
            return <>
            {missingProviders?<div className="primary-provider-error">Some providers are missing from the service, or are disabled. They will be removed when the service is saved</div>:null}
            {renderedProviders}
            </>
        }
        else{
            return <div className="primary-provider-value">
                No Primaries Selected
            </div>
        }
    }

    drawWeeklyAppointmentRoles = (isWeek)=> {
        var providers = this.props.service.providers //map of format {title: title, options: Array(0), allowedProviders: "Any"} or {title: title, options: Array(providerLength), allowedProviders: "Only"}
        var renderedRoles = []
        if(providers && Object.keys(providers).length > 0){
            renderedRoles = providers.map((providerObject, index)=>{
                var allProviders = providerObject.allowedProviders === "Any"
                return <div>
                    <div className={`role-container${!isWeek?' disabled':''}`}>
                        <ServiceProviderEditTextBox disabled={!isWeek} label="ROLE" title={providerObject.title} onChange={(textValue)=>{
                            this.onRoleTitleChanged(index, textValue)
                        }}/>
                        {this.drawProviderToggle(index, allProviders, isWeek)}
                        {/* <SDCheckBox disabled={!isWeek} label="All Providers" checked={allProviders} onCheckChanged={(checked)=>{
                            this.onRoleProviderCheckedChanged(index, checked)
                        }}/> */}
                    <img onClick={() => this.onDeleteAttendeeRole(index)} src={require('../../../img/Icons/ic_delete_forever_blue.png')} className="delete-role" alt="Delete" />
                    </div>
                    
                    {allProviders ? null : <AssignProvidersBlock disabled={!isWeek} isWeek={isWeek} activeList={providerObject.options} availableItems={this.props.providers} onActiveListUpdated={(list)=>{
                        this.onActiveProviderListUpdated(index, list)
                    }}/>}
                </div>
            })
        }
        return <div>
            <div className={`attendee-roles-title${!isWeek?' disabled':''}`}>Create Attendee Roles</div>
            <div className={`attendee-roles-description${!isWeek?' disabled':''}`}>
                Specify roles for an appointment with multiple attendees, and specify eligible providers for each role. To select providers not in the list, add them to this service under Manage Users
            </div>
            {renderedRoles}
            <button disabled={!isWeek} onClick={this.onNewAttendeeRole} className='attendee-roles-create-button'>ADD ROLE</button>
        </div>
    }

    drawGenericServiceToggle(serviceKey, label, reverseCheckbox, disabled){
        var checkState = reverseCheckbox ? !this.props.service[serviceKey] : this.props.service[serviceKey]
        return(
            <div className='checkbox-container'> 
                <SDCheckBox disabled={disabled} checked={checkState} id={serviceKey} label={label} onCheckChanged={
                    (checked)=>{
                        console.log(`Checked: ${checked}`)
                        this.onCheckChanged(serviceKey, reverseCheckbox ? !checked : checked)
                    }
                }/>
            </div>
        )
    }

    drawToggles = () => {
        var service = this.props.service
        return [
            this.drawGenericServiceToggle('fullDay', "All day appointment"),
            this.drawDurationToggle(service, service.fullDay),
            this.drawGenericServiceToggle('ignoreTimeRestrictions', "Schedulable during meal", false, service.fullDay),
            this.drawGenericServiceToggle('providerOnly', `Requires ${StringResources.patient()}`, /*reverseCheckbox*/true), //reverse the boolean, so when checked, providerOnly is false
            this.drawGenericServiceToggle('pickLocation', "Requires Location"),
            this.drawGenericServiceToggle('pickProvider', "Requires Provider"),
            this.drawGenericServiceToggle('evaluation', "Required evaluation", false, service.fullDay),
            this.drawGenericServiceToggle('scheduleForward', "Schedule forward eligible"),
            this.drawGenericServiceToggle('allowSwapping', "Allow appointment swapping"),
            this.drawGenericServiceToggle('usePrimary', "Use primary provider", false, service.providerOnly || !service.pickProvider),
            this.drawSelectPrimariesButton(service)
        ]
    }

    drawDurationToggle = (service, disabled) => {
        var durationStatus = service.defaultDuration && service.defaultDuration > 0 ? "" : "-error"
        return (
            <div className={`service-durationContainer ${disabled?"disabled":''}`}>
                <span className='service-duration-title'>Default Duration</span>
                <input className={`service-duration${durationStatus}`} disabled={disabled} type='number' onChange={this.onDurationChange} value={service.defaultDuration}/>
                <span className={`service-duration-text${durationStatus}`}>min</span>
            </div>
        )
    }
    
    drawSelectPrimariesButton = (service) => {
        let isDisabled = !service.usePrimary
        return (
            <button disabled={isDisabled} onClick={this.props.onClickAssignProviders} className={`assign-primaries-button${isDisabled?" disabled":""}`}>SELECT PRIMARIES</button>
        )
    }

    onDurationChange = (element)=> {
        var input = element.target.value
        console.log(input)
        if(input.length > 2){
            input = input.substring(0, 2)
        }
        var value = parseInt(input)
        this.props.onServiceValueChanged('defaultDuration', value)
    }

    onCheckChanged = (id, value)=> {
        this.props.onServiceValueChanged(id, value)
    }

    onSwitchChanged = () => {
        var isWeek = this.props.service.scheduleStyle === "Week"
        var newState = isWeek ? "Day" : "Week"
        this.props.onServiceValueChanged('scheduleStyle', newState)
    }

    onRoleTitleChanged = (index, title)=> {
        var providers = this.props.service.providers
        providers[index].title = title
        this.props.onServiceValueChanged('providers', providers)
    }

    onRoleProviderCheckedChanged = (index, checked)=> {
        var providers = this.props.service.providers
        providers[index].allowedProviders = checked ? "Any" : "Only"
        if(!checked)
            providers[index].options = []
        this.props.onServiceValueChanged('providers', providers)
    }

    onActiveProviderListUpdated = (index, list)=> {
        var providers = this.props.service.providers
        providers[index].options = list
        this.props.onServiceValueChanged('providers', providers)
    }

    onNewAttendeeRole = () => {
        var providers = this.props.service.providers
        if(!providers) providers = []
        providers.push({title: '', options: [], allowedProviders: "Any"})
        this.props.onServiceValueChanged('providers', providers)
    }

    onDeleteAttendeeRole = (index) => {
        var providers = this.props.service.providers
        providers.splice(index, 1)
        this.props.onServiceValueChanged('providers', providers)
    }
}