import React from 'react'
import FacilityManager from './FacilityManager'
import { Auth } from 'aws-amplify'
import ConfigManager from './ConfigManager'
import Helpers from '../util/Helpers'

export default class CacheManager{
    static instance

    initialLoadFromCache = false
    
    constructor(parent){
        this.parent = parent
        window.onbeforeunload = ()=>{ //clear cache on refresh
            CacheManager.clear()
        }
        this.cache = JSON.parse(sessionStorage.getItem("cache"))
        if(this.cache && this.getAgeMs() > 300000){ //5 minutes
            this.cache = null
            CacheManager.clear()
        }
        if(!this.cache){
            this.cache = {
                facility: {},
                user: {},
                services: {}, 
                providers: {},
                availableOptions: [],
                serviceProvidersDict: {}
            }
        }
        else{
            this.initialLoadFromCache = true
        }
        this.listeners = []
    }

    static clear(){
        if(CacheManager.instance)
            CacheManager.instance.cache = {}
        sessionStorage.removeItem("cache")
    }

    static createInstance(appInstance){
        var cache = new CacheManager(appInstance)
        CacheManager.instance = cache
        return cache
    }

    static get(){
        return CacheManager.instance
    }

    registerListener(callback){
        if(this.listeners.indexOf(callback) == -1){
            this.listeners.push(callback)
        }
    }

    unregisterListener(callback){
        var index = this.listeners.indexOf(callback)
        if(index > -1){
            this.listeners.splice(index, 1)
        }
    }

    getState(){
        return this.cache
    }

    /**
     * The goal of this method is to take any value from the cache, and update only the values that changed. 
     * In the case where specific values are not given, the old values will not update
     * 
     * ex. given `{id: e9a811, providers: [test1, test2]}` with existing cache of `{id: e9a811, providers: [test1], rooms: [tst2, tst3, tst5]}`, 
     *  the result will become `{id: e9a811, providers: [test1, test2], rooms: [tst2, tst3, tst5]}`
     * @param {*} key key that exists in cache
     * @param {*} data 
     */
    updateObjectWithOldCache(key, data){
        var maybeJson = this.cache[key]
        if(!maybeJson) return data //no point in writing only the values that changed if there were no values to begin with
        var json = Object.assign({}, maybeJson)
        var keys = Object.keys(data)
        for(var i = 0; i < keys.length; i++){ //we only want to write to the keys of the incoming data
            var innerKey = keys[i]
            var innerValue = data[innerKey]
            json[innerKey] = innerValue
        }
        return json
    }

    getAgeMs(){
        return Date.now() - new Date(this.cache['lastUpdated']).getTime()
    }

    setState(jsonData){
        this.cache['lastUpdated'] = new Date()
        var keys = Object.keys(jsonData)
        for(var i = 0; i < keys.length; i++){ //we only want to write to the keys of the incoming data
            var key = keys[i]
            var value = jsonData[key]
            this.cache[key] = value
        }
        for(const listener of this.listeners){ //notify our listeners
            listener(this.cache)
        }
        sessionStorage.setItem("cache", JSON.stringify(this.cache)) //finally write to the session storage
    }

    static async newSession(logout=true){
        CacheManager.clear()
        if(logout){
            await Auth.signOut()
        }
        else{
            window.location.reload();
        }
    }

    async fetchSkilledDayData(facilityId, getProviders, getServices, getRooms){
        var unfinishedPromises = [
            FacilityManager.getFacility(facilityId, getProviders, getServices, getRooms)
        ]
        if(facilityId !== 'Admin')
            unfinishedPromises.push(ConfigManager.getHomeOptions(facilityId))
        var promises = await Promise.all(unfinishedPromises)
        if(promises.length == 1)
            promises.push([])
        return await this.updateData(promises[0], promises[1])
    }

    async updateData(facility, homeOptions){
        if(facility.success === false){ //could be null, which means it was sucessful...
            if(facility.statusCode === 403){
                console.error("Forbidden")
                return false
            }
            else{
                console.error(`Error ${facility.statusCode} was returned from API with message of ${facility.message}`)
                return false
            }
        }
        facility = this.updateObjectWithOldCache('facility', facility) //we want to keep any data that existed already in the cache, as not all data may have been retrieved
        var data = {
            facility: facility
        }
        if(homeOptions)
            data.availableOptions = homeOptions
        data.user = await this.updateUser(facility, homeOptions)
        if(facility.autoScheduleRules == null) facility.autoScheduleRules = {}
        if(facility.services) data.services = this.updateServicesIntoFacility(facility)
        if(facility.providers) data.providers = this.updateProvidersIntoFacility(facility)
        if(facility.rooms) data.facility.rooms = this.updateRooms(facility)
        if(facility.locations) data.facility.locations = this.updateLocations(facility)
        if(facility.roles) data.facility.roles = this.updateRoles(facility)
        this.setState(data)
        return facility
    }


    /**
     * Update the user data from our token for reference
     * @param {*} facility Facility data
     * @param {*} availableOptions Role access items list
     */
    async updateUser(facility, availableOptions){
        let info = null
        var currentUser = await Auth.currentUserInfo()
        if(currentUser){
          var userTmp = await Auth.currentSession()
        
          if(userTmp)
            info = userTmp.getIdToken().decodePayload()
        }
        
        var user = {
          creatorId: info ? info["cognito:username"] : null,
        }
        
        if (info && info['facilities']) {
          let facilityRoles = JSON.parse(info['facilities'])
          user['role'] = facilityRoles[facility.id]
          user['access'] = availableOptions
        } else {
          //no info for facilities
          //treat ourselves as a guest. Could change what we send to the backend for requests, but the backend will still validate if we have access
          user['role'] = 'guest' 
        }
        return user
    }

    updateProvidersIntoFacility(facility){
        Object.keys(facility.providers).forEach(key => {
            var e = facility.providers[key]
            if(e.offsite) facility["offsiteProviderId"] = e.id
        })
        return facility.providers
    }

    updateServicesIntoFacility(facility){
        if(Array.isArray(facility.services)){
            facility.services.forEach(e => {
                if(e.name.toLowerCase() === "activity") facility["activityServiceId"] = e.id
                if(e.name.toLowerCase() === "bath") facility["bathServiceId"] = e.id
                if(e.name.toLowerCase() === "meal") facility["mealServiceId"] = e.id
                if(e.name.toLowerCase() === "other") facility["otherServiceId"] = e.id
                if(e.name.toLowerCase().indexOf("2fer") > -1) facility["service2ferId"] = e.id //TODO this should be configured different
                if(e.name.toLowerCase() === "provider unavailable") facility["unavailableServiceId"] = e.id
            })
        }
        return facility.services
    }

    updateRooms(facility){
        facility.rooms.forEach(e => {
            e.name = Helpers.getRoomName(e)
        })
        Helpers.sortRooms(facility.rooms)
        return facility.rooms
    }

    updateLocations(facility){
        return facility.locations.reduce(function(map, obj) {
            if(obj.name.toLowerCase() === "offsite") facility["offsiteLocationId"] = obj.id
            map[obj.id] = obj;
            return map;
        }, {});
    }

    updateRoles(facility){
        return facility.roles
    }
}