import React from 'react';
import {Table, Card} from 'react-bootstrap'
import Colors from '../../util/Colors'
import Tracker from '../../managers/Tracker'
import { StringResources } from '../../strings/StringManager';

export default class SelectLocationPage extends React.Component{

    constructor() {
      super()
      Tracker.logScreenView('schedule_appointment_select_location')
    }

    renderLocationsList() {

      let locationKeys = Object.keys(this.props.locations).filter(e=> !this.props.locations[e].disabled)
      if(locationKeys.length === 0){
        return <div>No locations found. Please ask an admin to add locations, or to remove the location requirement from this service</div>
      }

      return locationKeys.map(e => {
        return (
          <LocationButton 
            location={this.props.locations[e]}
            machine={this.props.machine}
            onSelectLocation={this.props.onSelectLocation}
          />
        )
      })
    }

    render(){
      if(!this.props.occupancy){
        //if multiple occupancies, this cannot take place in the occupant's room
        return (<>
          <Table borderless={true} style={{marginTop: '20px'}}>
            <div align="center">
              <tr>
                {this.renderLocationsList()}
              </tr>
            </div>
          </Table>
        </>)
      }
      else{
        return(
            <>
              <Table borderless={true} style={{marginTop: '20px'}}>
                <div align="center">
                  <tr>
                    Select {StringResources.patient()} room
                    <LocationButton
                        location={{name:this.props.occupancy.name + " - " + this.props.occupancy.identifier, id: this.props.occupancy.id}}
                        machine={this.props.machine}
                        onSelectLocation={this.props.onSelectLocation}/>
                  </tr>
                  <tr>
                    &nbsp;
                  </tr>
                  <tr>
                    Select a different location
                    {this.renderLocationsList()}
                  </tr>
                </div>
              </Table>
            </>
        )
      }
    }
  }
  
  class LocationButton extends React.Component{
  
    constructor(){
      super()
  
      this.state = {
        color: Colors.NavGray
      }
    }

    onClick = () => {
      this.props.machine.send("NEXT")
      this.props.onSelectLocation(this.props.location)
    }

    onMouseOver = () => {
      this.setState({
        color: Colors.Highlight
      })
    }
  
    onMouseLeave = () => {
      this.setState({
        color: Colors.NavGray
      }) 
     }

     getStyle(){
      return{
        width:"250px",
        display:"block",
        marginLeft:"auto",
        marginRight:"auto", 
        backgroundColor:this.state.color
      }
    }
  
    render(){
      return(
        <div class="container">
        <div class="row">
          <div class="col text-center">
          <Card>
          <a style={{ cursor: "pointer" }} onClick={this.onClick} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
          <Card.Body style={this.getStyle()}>
            <Card.Text variant="primary" style={{fontSize:"12px", textAlign:"center"}}><b>{this.props.location.name}</b></Card.Text>
          </Card.Body>
          </a>
          </Card>
          </div>
       </div>
      </div>
      )
    }
  }