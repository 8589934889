import React from 'react';
import {Button} from 'react-bootstrap'
import Colors from '../util/Colors'
import HybridTimePicker from '../util/HybridTimePicker';
import SDNavBar from './SDNavBar';

export default class SwapPopup extends React.Component{

    constructor(props){
        super(props)
        this.ref = React.createRef();

        var time = this.getTimes()

        this.state = {
            time: time
        }
    }

    componentDidMount(){
        this.props.onPopupMounted()
        this.props.editTime(this.getTimes())
    }

    componentDidUpdate(previousState){
        if(this.props.blob.timeAtClick != previousState.blob.timeAtClick) this.componentDidMount()
    }

    getTimes = () => {
        var startTime
        var endTime
        if(this.props.time){
            var {start, end} = this.props.time
            startTime = start
            endTime = end
        }
        else{
            startTime = new Date(this.props.blob.clickedTime).getTime()
            var appointment = this.props.blob.appointment
            var duration = appointment.duration ?? (appointment.end - appointment.start)
            endTime = startTime + duration
        }
        return {start: startTime, end: endTime}
    }

    drawLabel(label, fontSize, top, left){
        return <div style={{position:"absolute", fontSize:fontSize, top:top, left:left}}>{label}</div>
    }

    onChangeStartTime = (date) => {
        var time = this.getTimes()
        var duration = time.end - time.start

        time.start = date
        time.end = new Date(date).getTime() + duration

        this.setState({
            time: time
        }, () => this.props.editTime(time))
    }

    onChangeEndTime = (date) => {
        var time = this.getTimes()
        time.end = date

        this.setState({
            time: time
        }, () => this.props.editTime(time))
    }

    drawInputfield(top, left, id, enabled = true, valid = false, value){
        var invalidTimeIcon = valid ? null : <img style={{position:"absolute", top:top + 5, right: 2, height:16, width:16}} src={require('../img/Icons/alert.png')}/>
        var onChange = id == "starttime" ? this.onChangeStartTime : this.onChangeEndTime

        return <>
        {invalidTimeIcon}
        <div style={{position:"absolute", top:top, left:left}}>
        <HybridTimePicker 
        value={value}
        onChange={onChange}
        listener={this}
        />
            {/* <input onClick={this.editTime} readonly="readonly" disabled={!enabled} id={id} style={{width:140, height:18, fontSize:12}}/> */}
            </div>
            </>
    }

    discard = () => {
        //this.props.machine.send("CANCEL")
        this.props.onClose()
    }

    save = () => {
        var times = this.getTimes()
        var startTime = new Date(times.start)
        var endTime = new Date(times.end)

        var time = {
            startTime: startTime.getTime(),
            endTime: endTime.getTime(),
        }
    
        //this.props.machine.send("CLOSE")
        this.props.onSelectTime(time)
    }

    render(){
        var labelLeft = 30
        var inputLeft = 160
        var w = 325
        var h = 135

        var saveDisabled = !this.props.validStart || !this.props.validEnd
        var timeRendering = (
            <>
            {this.drawLabel("Start Time", 12, 40, labelLeft)}

            {this.drawInputfield(37.5, inputLeft, "starttime", true, this.props.validStart, this.state.time.start)}
            </>
        )
        var top
        var openUp = this.state.openUp
        if(openUp === undefined && this.ref.current){
            var popupBottom = this.ref.current.getBoundingClientRect().bottom
            var windowHeight = window.innerHeight - SDNavBar.HeaderHeight
            if(popupBottom < windowHeight) openUp = false
            else openUp = true
            this.setState({openUp: openUp})
        }

        if(openUp){ //Get page position + duration of appointment + margin. Check if part of the dialog would render below this
            top = this.props.blob.startY-h-10 //render at the top of the appointment selection
        }
        else{
            top = this.props.blob.endY //render at the bottom of the appointment selected
        }
        var eventTitle = this.props.appointment.service 
            ? this.props.appointment.service.name
            : this.props.appointment.title
        if(this.props.appointment.type === "TIME"){
            eventTitle = `Swap - ${this.props.appointment.duration / 1000 / 60} Minutes`
        }

        var left = window.innerWidth * 0.1 + this.props.columnWidth * this.props.column - 5
        if(this.props.column === 0){ //anchor to left of clicked column 
            left += 10
        }
        else if(this.props.column === this.props.columns-1){ //anchor to right of clicked column
            left = left - w + this.props.columnWidth
        }
        else{ //center to clicked column
            left = left - (w/2) + this.props.columnWidth/2
        }

        return(
            <div style={{zIndex:100, width:w, height:h, backgroundColor:"white", position:"absolute", left:left, top:top, border:"2px solid black"}} ref={this.ref}>


            {this.drawLabel("Event Type", 16, 10, labelLeft)}
            {timeRendering}

            <div style={{position:"absolute", backgroundColor: Colors.Red, top: 12.5, left: 140, height:20, width:160, textAlign:"center", fontSize:12, color:"white", borderRadius:8}}>
                {eventTitle}
            </div>

            <div style={{zoom:0.75, zIndex:100, position:"absolute", bottom:"10px", right:"10px"}}>
              <Button variant="link" disabled={this.props.timeInput != null} onClick={this.discard} style={{color:Colors.Primary.Main}}>Cancel</Button>
              <Button variant="primary" disabled={saveDisabled} onClick={this.save} style={{width:"80px", backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>Save</Button>
            </div>

            </div>
        )
    }
}