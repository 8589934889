export const ManageGroupsMachine = {
    initial: 'SelectOption',
    states: {
        SelectOption: {
            on: {
                VIEW: 'ViewOption',
                NEW: 'NewOption'
            }
        },
        ViewOption: {
            on: {
                DELETE: 'SelectOption',
                EDIT: 'EditOption',
                FINISH: 'SelectOption',
                BACK: 'SelectOption'
            }
        },
        NewOption: {
            on: {
                SAVE: 'ViewOption',
                CANCEL: 'SelectOption',
                BACK: 'SelectOption'
            }
        },
        EditOption: {
            on: {
                SAVE: 'ViewOption',
                CANCEL: 'ViewOption',
                BACK: 'ViewOption'
            }
        },
        stop: {}
    }
}