import React from 'react';

import {Button} from 'react-bootstrap'
import {Table} from 'react-bootstrap'
import ApiUtil from '../util/ApiUtil'
import Colors from '../util/Colors'
import RoomDropdown from './ManagePatients/RoomDropdown';
import FacilityManager from '../managers/FacilityManager'
import SelectRoomPage from './ScheduleAppointment/SelectRoomPage';
import EvaluationForm from './ScheduleAppointment/EvaluationForm';
import LoadingSpinner from '../util/LoadingSpinner';
import Tracker from '../managers/Tracker';
import Helpers from '../util/Helpers';
  
export default class PatientEvaluationPage extends React.Component {

  constructor(){
    super()
    Tracker.logScreenView('patient_evaluation')

    this.state = {
      rooms: {},
      selectedRoom: null
    }

    document.body.style = 'background:' + Colors.Secondary.Light + ';';

    this.onRoomSelected = this.onRoomSelected.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  getEvaluations(){
    FacilityManager.listRooms(this.props.facility.id, true, null, (data) => {
      
      Helpers.sortRooms(data)

      data.forEach(e => {
        e.name = Helpers.getRoomName(e)
      })

      this.setState({
        rooms: data
      })
      this.props.machine.send("RESOLVE")
    })
  }

  refresh(){
    this.getEvaluations()
  }

  componentDidMount(){
     this.refresh()
  }

  onRoomSelected(room){
    this.setState({
      selectedRoom: room
    })

    this.props.machine.send("ROOM_SELECTED")
  }

  render(){

    const state = this.props.machine.state.value["PatientEvaluation"]

    if(state == null) return null

    switch(state){
      case "Load":
        return <LoadingSpinner/>
      case "SelectRoom":
        return <SelectRoomPage machine={this.props.machine} showLabels={false} rooms={this.state.rooms} onRoomSelected={this.onRoomSelected}/>
      case "ShowEvaluation":
        return <EvaluationForm facility={this.props.facility} machine={this.props.machine} selectedRoom={this.state.selectedRoom} onSubmit={this.refresh}/>
    }
   }
}