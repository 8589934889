/**
 * Promisify any callback, and it will return the first callback parameter. 
 * Could be modified to return all of them later on.
 * 
 * Assumes the last parameter in the function that it calls would be the callback. 
 * Call invoke with all parameters except the callback
 * 
 * ex. 
 * ```
 * const dataPromise = new PromisifyCallback(SomeClass.getData) //can be defined above the class, or a static variable somewhere
 * let result = await dataPromise.invoke(param1, param2, ...) //Just don't include the last parameter which is the callback function
 * ```
 */
export default class PromisifyCallback {
    constructor(callFunction){
        this.call = callFunction
    }

    /**
     * Invoke the function using a set of parameters. The callback will be returned in the promise result
     * @param params
     * @returns {Promise<Object>}
     */
    async invoke(...params){ //grab all parameters
        return await new Promise((resolve)=>{
            let callback = (result)=>{ //create a callback to intercept the result
                resolve(result)
            }
            params.push(callback) //add our callback to the end of the parameters
            this.call(...params) //and call the target funtion with the parameter list
        })
    }

    /**
     * Invoke the function using a set of parameters. The callback will be returned in the promise result. Any secondary error callback will be returned as a rejection
     *
     * To use this function, pass in all variables into this function, except for the callbacks.
     *
     * The first callback must be onSuccess, and the last callback must be onError or similar
     * @param params
     * @returns {Promise<Object>}
     */
    async invokeResolveReject(...params){
        return await new Promise((resolve, reject)=>{
            let callback = (result)=>{ //create a callback to intercept the result
                resolve(result)
            }
            let errorHandler = (error)=>{ //create a callback to intercept the result
                reject(error)
            }
            params.push(callback) //add our callback to the end of the parameters
            params.push(errorHandler) //add our errorHandler to the end of the parameters
            this.call(...params) //and call the target funtion with the parameter list
        })
    }
}