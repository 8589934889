import React from 'react';
import SchedulingCalendar from './SchedulingCalendar';
import Colors from '../util/Colors'
import CalendarUtil from '../util/CalendarUtil'
import AppointmentPopup from '../pages/ScheduleAppointment/AppointmentPopup'
import DateAndTime from '../util/DateAndTime'
import Helpers from '../util/Helpers';
import {Navbar} from 'react-bootstrap'

export default class AppointmentCalendar extends SchedulingCalendar{

    /**
     *
     * @param props {{[key: string]: *, schedule: ScheduleColumn[]}}
     */
    constructor(props){
        super(props)
        if(props.appointment.service && props.appointment.service.fullDay){
            this.handleFullDayOverride()
        }
        this.state.conflict = null
        this.state.activityAppointments = this.props["activities"]
        this.state.labels = []
        this.state.columnIds = []
        this.state.zIndexLines = 0
        this.state.zIndexHeader = 10
        this.state.zIndexDefaultBlob = 1
        this.state.zIndexAvailable = 0
        this.state.zIndexSelected = 1
        this.state.staticMarginTop = 50
        this.generateLabels(this.props.schedule)
    }

    appointmentType = {
        unavailable: -1,
        available: 0, 
        selected: 1
    }

    /**
     * generate our labels before rendering them. Also populate an array of IDs that tell what column does what
     * @param schedule {ScheduleColumn[]}
     */
    generateLabels(schedule){
        const labels = this.state.labels;
        const columnIds = this.state.columnIds;
        for (let scheduleElement of schedule) {
            if(scheduleElement.label === 'activities'){
                labels.push("\!Activities")
                columnIds.push('activities')
            }
            else{
                labels.push(scheduleElement.label)
                columnIds.push(scheduleElement.id)
            }
        }
    }

    drawDateAndTime(){
        return <div align="center" style={{lineHeight: '36px'}}> <DateAndTime anchor={this.props.anchorDate}/> </div>
    }

    drawHeaders(){
        const headers = this.state.labels.map((label, i) => {
            if (label.includes('\!')) {
                return <div style={this.getHeaderStyle(i)}>{label.replace('\!', '')}</div>
            }
            return <div style={this.getHeaderStyle(i)}>{label}</div>
        });
        return (
            <Navbar style={{position: 'sticky', top: 0, zIndex: this.state.zIndexHeader}}>
                {headers}
            </Navbar>
        )
    }

    drawSelectedBlob(){

        if(this.state.clickedBlob == null) return {}

        if(this.state.clickedBlob && this.state.time){
            if(this.state.clickedBlob.type != this.appointmentType.available) return {}

            return{
                type: this.appointmentType.selected,
                start: this.state.time.start,
                end: this.state.time.end
            }
        }
        return {}
    }

    /**
     * Called from SchedulingCalendar
     * @returns {*[]}
     */
    drawBlobs(){
        let id = 0;
        let blobs = [];
        let processedBlobs = []

        /**
         * @type {ScheduleColumn[]}
         */
        let schedule = this.props.schedule
        for (const scheduleColumn of schedule) {
            let columnId = scheduleColumn.id
            if(columnId === 'activities') continue
            let label = scheduleColumn.label
            let list = scheduleColumn.list()
            blobs = blobs.concat(this.drawConflictedTimes(list, label, columnId))
            blobs = blobs.concat(this.drawOpenTime(list, label, columnId))
            while (blobs.length > 0){
                let blob = blobs.shift()
                processedBlobs.push(this.drawBlob(blob, id++, columnId, label))
            }
        }
        blobs = blobs.concat(this.drawActivities())
        if(this.state.clickedBlob)
            blobs = blobs.concat(this.drawSelectedBlob())
        while (blobs.length > 0){
            let blob = blobs.shift()
            if(blob.activity) processedBlobs.push(this.drawBlob(blob, id++, 'activities', 'Activities'))
            else if(this.state.clickedBlob) processedBlobs.push(this.drawBlob(blob, id++, this.state.columnIds[0], '', true))
        }
        return processedBlobs
    }

    /**
     *
     * @param list {ScheduleAppointmentHolder[]}
     * @param label {string}
     * @param columnId {string}
     * @returns {*[]}
     */
    drawConflictedTimes(list, label, columnId){
        const blobs = [];

        //fill open time one day at a time

        const today = this.props.anchorDate;

        const ft = this.state.timeRange[0];
        const lt = this.state.timeRange[this.state.timeRange.length - 1];

        const start = new Date(today.valueOf());
        start.setHours(ft.getHours())
        start.setMinutes(ft.getMinutes())
        start.setSeconds(0)
        start.setMilliseconds(0)

        const end = new Date(today.valueOf());
        end.setHours(lt.getHours())
        end.setMinutes(lt.getMinutes())
        end.setSeconds(0)
        end.setMilliseconds(0)
    
        Helpers.sortObjectByTimes(list)

        for (const e of list) {
            if(!e.isAppointment) continue;
            this.drawScheduledBlob(blobs, e)
        }

        return blobs
    }

    /**
     *
     * @param list {ScheduleAppointmentHolder[]}
     * @param label {string}
     * @param columnId {string}
     * @returns {*[]}
     */
    drawOpenTime(list, label, columnId){
        const blobs = [];

        //fill open time one day at a time

        const today = this.props.anchorDate;

        const ft = this.state.timeRange[0];
        const lt = this.state.timeRange[this.state.timeRange.length - 1];

        const start = new Date(today.valueOf());
        start.setHours(ft.getHours())
        start.setMinutes(ft.getMinutes())
        start.setSeconds(0)
        start.setMilliseconds(0)

        const end = new Date(today.valueOf());
        end.setHours(lt.getHours())
        end.setMinutes(lt.getMinutes())
        end.setSeconds(0)
        end.setMilliseconds(0)

        for (const scheduleAppointmentHolder of list) {
            if(!scheduleAppointmentHolder.isAppointment && scheduleAppointmentHolder.isOpenTime){
                let startTime = scheduleAppointmentHolder.start
                let endTime = scheduleAppointmentHolder.end
                this.drawOpenBlob(blobs, startTime, endTime)
            }
        }

        return blobs
      }

    drawActivities(){
        const blobs = [];
        this.state.activityAppointments.forEach(e => {
            const blob = e;
            blob.type = this.appointmentType.unavailable
            blob.activity = true
            blobs.push(blob)
        })
        return blobs
    }

    /**
     *
     * @param blobs {*[]}
     * @param appointment {ScheduleAppointmentHolder}
     */
    drawScheduledBlob(blobs, appointment){
        const blob = appointment.get();
        blob.type = this.appointmentType.unavailable
        blobs.push(blob)
    }

      drawOpenBlob(blobs, start, end){
        if(start === end) return null
        const d = new Date()
        if(this.props.service && this.props.service.fullDay)
            d.setHours(0, 0, 0, 0)
        const now = d.getTime()
        const isPast = this.props.appointment.service.fullDay ? false : start < now
        const isCurrent = this.props.appointment.service.fullDay ? false : start < now && end > now
    
        //if the current time exists within this blob...
        //block all time in blob up to the next quarter hour
        if(isCurrent){
            const m = (15 * Math.ceil(d.getMinutes() / 15))
    
            d.setMinutes(m, 0, 0)
            blobs.push({
                start: start,
                end: d.getTime(),
                type: this.appointmentType.unavailable
            })

            //if this causes the next time to have a duration of zero, just return now...
            if(end === d.getTime()){
                return
            }
            
            start = d.getTime()
        }
    
        blobs.push({
            start: start,
            end: end,
            type: isPast && !isCurrent? this.appointmentType.unavailable : this.appointmentType.available
        })
    }

    openPopup(id, offsetY, pageY){
        const blob = this.state.blobs[id];

        if(blob == null) return
        
        if(blob.type === this.appointmentType.unavailable) return
        
        this.getClickedTime(offsetY, pageY, blob)

        this.setState({
            clickedBlob: blob
        })
        
        this.props.machine.send("POPUP")
    }

      onClickBlob = (e) => {
        const id = e.target.id

        const blob = this.state.blobs[id]

        console.log(blob.type)

        if(blob.type === this.appointmentType.unavailable) return;
        if(this.state.clickedBlob && this.state.time){
            if(blob.startTime === this.state.time.start) return
        }
        
        const offsetY = e.nativeEvent.offsetY
        const pageY = e.nativeEvent.pageY

        const state = this.props.machine.state.value["ScheduleAppointment"] ?? this.props.machine.state.value["ScheduleGroupAppointment"]
        if(state.toLowerCase().includes("popup")){
            this.props.machine.send("CANCEL")
            this.setState({},() => {
                this.openPopup(id, offsetY, pageY)
            })
        }else{
            this.openPopup(id, offsetY, pageY)
        }
      }

    findArrayById(id, array) {
        if(!array) return false
        return array.find(item => item.id === id);
    }

    /**
     *
     * @param appointment {AppointmentModel}
     * @param id
     * @param columnId
     * @param columnLabel
     * @param fullSpan
     */
    drawBlob(appointment, id, columnId, columnLabel, fullSpan=false){
        if(appointment == null || appointment.start == null) return

        if(appointment.start instanceof Date){
            appointment.start = appointment.start.getTime()
        }
        if(appointment.end instanceof Date){
            appointment.end = appointment.end.getTime()
        }
        const isOffsite = appointment.isOffsite || appointment.serviceId === this.props.facility.otherServiceId
        const startTime = appointment.start
        const endTime = appointment.end
        
        const columnDetails = this.getColumnDetails(columnId, columnLabel, fullSpan)
      
        const blobStyle = this.getBlobStyle(appointment, columnDetails.columnIndex, columnDetails.fullSpan, startTime, endTime, id)
              
        this.state.blobs[id] = {
            id: id,
            startTime: startTime,
            endTime: endTime,
            top: blobStyle.top,
            height: blobStyle.height,
            appointmentId: appointment.id,
            columnId: columnId,
            type: appointment["type"],
            appointment:{
                start: startTime,
                end: endTime
            }
        }
      
        const label = blobStyle.height > 20 ? appointment.title : ""
        const foundOccupant = this.findArrayById(columnId, appointment.occupants);
        
        let body = [];
        if(appointment.title){

            const providers = this.getProviderNames(appointment)
            const locationName = this.getLocationName(appointment)
            const patientName = this.getPatientName(appointment, foundOccupant);

            //no need to show providerDiv if no provider, is offsite, or appointment belongs to provider
            const providerDiv = providers == null || isOffsite ? null : ` ${providers.join(', ')}`

            const patient = patientName == null || foundOccupant ? null : ` ${patientName}`

            const location = locationName == null || isOffsite ? null : locationName

            const thirtyMin = 30 * 60 * 1000
            const length = endTime - startTime;

            if (length < thirtyMin) {
                body = isOffsite ? [
                    <span style={{ fontSize: 12, fontWeight: "bold" }}>Offsite</span>,
                    <span style={{fontSize: 12}}>{patient?patient:""}</span>
                ] : [
                  <span className="mr-1 ml-2" style={{ fontSize: 12, fontWeight: "bold" }}>{appointment.title}, </span>,
                  <span className="mr-1 ml-2" style={{fontSize: 12}}>{providerDiv?providerDiv:""}</span>,
                  location?(
                    <img src={require('../img/Icons/location.png')} className="mr-1 ml-2" style={{height:"12px"}} alt="loc"/>
                  ):null,
                  <span style={{fontSize: 12}}>{patient?patient:""}</span>,
                  <span className="mr-1 ml-2" style={{fontSize: 12}}>{location?location:""}</span>
                ]
      
                if (appointment.notes && typeof(appointment.notes) !== 'undefined' && appointment.notes.length > 0) {
                  body.push(<img src={require('../img/Icons/note.png')} className="mr-1 ml-2" style={{height:"12px"}} alt="loc"/>)
                  body.push(<span style={{ fontSize: 12 }}>{appointment.notes}</span>)
                }
            }
            else{
                body = [
                    <div key='title-provider'>
                        <span key='title' style={{ fontSize: 12, fontWeight: "bold" }}>{appointment.title}</span>
                        <span key='provider' style={{fontSize: 12}}>{providerDiv?providerDiv:""}</span>
                    </div>,
                    location?(
                        <div key='location-patient'>
                            <img key='locationImg' src={require('../img/Icons/location.png')} className="mr-1 ml-1" style={{height:"12px"}} alt="loc"/>
                            <span key='location' style={{fontSize: 12}}>{location?location:""}</span>
                            <span key='patient' style={{fontSize: 12}}>{patient?patient:""}</span>
                        </div>
                    ):(
                        <div key='location-patient'>
                            <span key='patient' style={{fontSize: 12}}>{patient?patient:""}</span>
                        </div>
                    )
                ]
    
                if (appointment.notes && typeof(appointment.notes) === 'string' && typeof(appointment.notes) !== 'undefined' && appointment.notes.length > 1) {
                    body.push(
                        <div key='notes'>
                            <img key='notes-img' src={require('../img/Icons/note.png')} className="mr-1 ml-1" style={{height:"12px"}} alt="loc"/>
                            <span key='notes-txt' style={{fontSize:12}}>{appointment.notes}</span>
                        </div>
                    )
                }
            }
        }
        else{
            body = label
        }

        const textStyle = Helpers.getBlobTextStyle(blobStyle);
        let idLabel = 'appt-'
        switch (appointment["type"]){
            case this.appointmentType.available:
                idLabel += 'available-'
                break
            case this.appointmentType.selected:
                idLabel += 'selected-'
                break
            case this.appointmentType.unavailable:
                idLabel += 'unavailable-'
                break
        }
        idLabel += id

        return(
          <div key={idLabel} id={idLabel}>
            <div onClick={this.onClickBlob} id={id} style={blobStyle}/>
            <div style={textStyle}>{body}</div>
          </div>
        )
      }

    /**
     *
     * @param appointment {AppointmentModel}
     * @returns {*}
     */
      getLocationName(appointment){
        const service = Helpers.getService(appointment.serviceId, this.props.facility.services)
        if(service && Helpers.shouldShowSingleOccupantAsLocation(appointment, service)){ //we want to show the location when we can
            return appointment.occupants[0].name
        }
        for (let location of appointment.locations) {
            const loc = this.props.facility.locations[location.id];
            if(loc != null) return loc.name
        }
        return null
      }

    /**
     * Comma delimited list of occupants
     * @param appointment {AppointmentModel}
     * @param specificId {string}
     * @returns {string}
     */
      getPatientName(appointment, specificId){
        let occupantLength = appointment.occupants.length
        if(occupantLength === 0) return ''
        let name
        if(!specificId) name = appointment.occupants[0].name
        else if(occupantLength > 0) name = appointment.occupants.find(o => o.id === specificId).name
        if(occupantLength > 1) name += ` +${occupantLength-1} others`
        return name //multiple occupancies still will populate this as it duplicated the appt
      }

      getColumnDetails(id, label, fullSpan){
        let isProviderAppointment = false
        let isOccupancyIdAppointment = false
        let columnIndex = this.state.columnIds.indexOf(id)

        return {
            columnIndex: columnIndex,
            fullSpan: fullSpan,
            isProviderAppointment: isProviderAppointment,
            isOccupancyIdAppointment: isOccupancyIdAppointment
        }
      }

      onClosePopup = () => {
          this.setState({
              clickedBlob: undefined
          })
      }

      getBlobStyle(appointment, columnIndex, fullSpan, startTime, endTime, id){
        let selected = this.state.clickedBlob != null && this.state.clickedBlob.id == id

        let color = appointment.type === this.appointmentType.available ? Colors.Primary.Main : Colors.DarkGray
       
        let border = "1px solid " + Colors.DarkGrayBorder

        let type = appointment.type
    
        if(type === this.appointmentType.selected) {
            color = Colors.Primary.Dark
            border = "5px solid " + Colors.Green
        }
    
        if(selected && type !== 0 && this.state.time != null) {
    
                color = Colors.Primary.Dark
                border = "5px solid " + Colors.Green
                startTime = CalendarUtil.getShortTime(new Date(this.state.time.start))
                endTime = CalendarUtil.getShortTime(new Date(this.state.time.end))
    
                this.state.clickedBlob.appointment = {
                    start: startTime,
                    end: endTime
                }
        }else{
            startTime = CalendarUtil.getShortTime(new Date(startTime))
            endTime = CalendarUtil.getShortTime(new Date(endTime))
        }

        let columnsNum = this.props["columns"]
        let w = this.state.columnWidth/columnsNum
        if(fullSpan){
            if(this.props["activities"].length > 0){
                w = (this.state.columnWidth/columnsNum)*(columnsNum-1)
            }
            else{
                w = this.state.columnWidth
            }
        }
        
        const top = this.getTop(startTime)
        const height = this.getHeight(startTime, endTime)
        if(appointment.activity){
            columnIndex = this.props.columns - 1
        }
        
        let zIndex = this.state.zIndexDefaultBlob
        if(type === this.appointmentType.available) zIndex = this.state.zIndexAvailable
        if(type === this.appointmentType.selected) zIndex = this.state.zIndexSelected

        return{
            borderRadius:"4px",
            position:"absolute", 
            left: window.innerWidth * 0.1 + w * columnIndex*columnsNum + 5,
            top:top, 
            backgroundColor:color, 
            width: columnsNum * w - 10,
            height: height,
            border: border,
            zIndex: zIndex
        }
    }

    editTime(time){
        super.editTime(time)
    }
   
    drawAppointmentPopup(){

        let validStart = true
        let validEnd = true

        if(this.state.time && this.state.clickedBlob){
            let startTime = new Date(this.state.time.start)
            let endTime = new Date(this.state.time.end)
            let diffMinutes = (endTime - startTime) / 60000
            if(diffMinutes < 15) validEnd = false

            let earliest = this.state.timeRange[0].getHours()
            let latest = this.state.timeRange[this.state.timeRange.length - 1].getHours()
    
            if(startTime.getHours() < earliest) validStart = false
            if(endTime.getHours() < earliest) validEnd = false
            
            if(endTime.getHours() >= latest && endTime.getMinutes() > 0) validEnd = false
            if(startTime.getHours() >= latest && startTime.getMinutes() > 0) validStart = false
    
            startTime = startTime.getTime()
            endTime = endTime.getTime()
    
            if(startTime < new Date().getTime()) validStart = false
            if(endTime < new Date().getTime()) validEnd = false
    
            if(validStart || validEnd){
                /**
                 * @type {ScheduleColumn[]}
                 */
                let scheduleColumns = this.props.schedule
                for (const scheduleColumn of scheduleColumns) {
                    for (const e of scheduleColumn.list()) {
                        if(scheduleColumn.id === 'activities') continue //ignore these
                        if(!e.isAppointment && e.isOpenTime) continue //ignore these
                        if(startTime >= e.start && startTime < e.end) validStart = false
                        if(endTime > e.start && endTime <= e.end) validEnd = false
                        if(e.start >= startTime && e.start < endTime && e.end >= startTime && e.end < endTime) validEnd = false
                    }
                }
            }
         }

        if(this.props.machine.state.value["ScheduleAppointment"] === "AppointmentPopup" || this.props.machine.state.value["ScheduleGroupAppointment"] === "AppointmentPopup") {
            return <AppointmentPopup
                        defaultDuration={this.props.defaultDuration}
                        onPopupMounted={() => this.forceUpdate()}
                        validStart={validStart}
                        validEnd={validEnd}
                        editTime={this.editTime}
                        onSelectTime={this.props.onSelectTime}
                        appointment={this.props.appointment}
                        providers={this.props.providers}
                        machine={this.props.machine}
                        blob={this.state.clickedBlob}
                        onClose={this.onClosePopup}/>
        }
        else{
            return null
        }
    }

    render() {
        return (
            <>
                {super.render(this.drawAppointmentPopup())}
            </>
        )
    }
}