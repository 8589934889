import React from 'react';

import {Table} from 'react-bootstrap'
import LoadingSpinner from '../../util/LoadingSpinner';
import AccessOption from '../ManageRoles/Components/AccessOption';
import HomeOptions from './HomeOptions';
import './optionsList.css';

export default class OptionsList extends React.Component{

    componentDidMount(){
        window.addEventListener('resize', this.onResize)
    }
  
    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize)
    }
  
    onResize = () => this.forceUpdate()
  
    renderOptionsRow(rowItems, rowSize) {
        return rowItems.map((optionKey, index)=>{
            var option = this.props.allOptions[optionKey]
            return this.drawOption(option)
        })
    }
  
    drawOption = (option) => {
        return (
            <th>
                <AccessOption
                    selected={true}
                    imageScale={0.5}
                    icon={option.icon}
                    title={option.title}
                    onClick={()=>{
                        this.props.onOptionSelected(option)
                    }}
                />
            </th>
        )
    }
   
    render(){
        const state = this.props.machine.state.value
    
        if(state === "Init") return <LoadingSpinner/>
        return Object.keys(HomeOptions.rows).map((sectionTitle)=>{
            return this.renderSection(sectionTitle, HomeOptions.rows[sectionTitle])
        })
    }

    renderSection(title, items){
        var rowSize = 3
    
        
        // if(window.innerWidth > 2360) rowSize = 4 //TODO?
        // if(window.innerWidth > 2600) rowSize = 5
        if(window.innerWidth < 1200) rowSize = 2
        if(window.innerWidth < 900) rowSize = 1

        var allowedIds = []
        const optionIds = this.props.availableOptions;
        items.forEach((item)=>{
            if(optionIds.includes(item)){
                allowedIds.push(item)
            }
        })

        var rows = []
        for(var rowIndex = 0; rowIndex < allowedIds.length/rowSize; rowIndex++){
            rows.push([...allowedIds].splice(rowIndex*rowSize, rowSize))
        }
        if(rows.length == 0) return null

        return (
            <>
                <Table className="optionsTable" borderless={true}>
                    <div align="left">
                        <tr align="left">
                            <div className="rowTitle">
                                {title}
                            </div>
                        </tr>
                        <tr>
                            {
                                rows.map((val, index) => {
                                    return(
                                        <tr>
                                            {this.renderOptionsRow(val, rowSize)}
                                        </tr>
                                    )
                                })
                            }
                        </tr>
                    </div>
                </Table>
            </>
        );
    }
}