import React from 'react'
import './AssignProvidersBlock.css'
import DeleteIcon from '../../../img/Icons/ic_delete_forever.png'

//<AssignProvidersBlock activeList={this.props.activeProviderList} 
//availableItems={this.props.availableProviderItems} onActiveListUpdated={this.onActiveProviderListUpdated}/>
export default class AssignProvidersBlock extends React.Component{
    getProviderName(pId){
        var name = ""
        var provider = this.props.availableItems[pId]
        if(provider)
          name = provider.firstName + " " + provider.lastName
        return name
    }
    
    renderOptions(list, index, itemClassName){
        var providerOptions = {} //map with the name as the key, and the option as the value. Only good way to currently sort

        Object.keys(this.props.availableItems).forEach(option => { //all providers that we were provided by class that is using this
          providerOptions[this.getProviderName(option)] = option
        })
        var vpId = list[index]
        var vp = null;
        Object.keys(providerOptions).forEach((e)=>{
          if(providerOptions[e] == vpId){
            vp = {
              name: e,
              id: providerOptions[e]
            }
          }
        })
        var options = []
        if(!vp){
          vp = {
            name: "Select Provider",
            id: ''
          }
        }
        
        options.push(<option className={'assign-provider-dropdown-option-hide-text'} value={vp.id}>{vp.name}</option>)
        Object.keys(providerOptions).forEach((name) => {
          var id = providerOptions[name]
          if(id === vp.id) return null
          options.push(<option className={'assign-provider-dropdown-option'} value={id}>{name}</option>)
        })
    
        options.sort((a, b) => {
          return (a.props.children > b.props.children) ? 1 : -1
        })
    
        return (
          <select disabled={this.props.disabled} className={itemClassName} value={vp.id} style={{fontSize:12}} onChange={(providerId)=> {this.onChange(providerId.target.value, index)}}>
            {options}                   
          </select>
        )
    }

    renderDropdown(list, index){
        var title = "provider"
        var isEmpty = !list[index]
        var containerTag = 'assign-provider-dropdown-fallback'
        if(isEmpty) containerTag += "-empty"

        return (
          <div className="role-container">
            <div className={containerTag}>
                <div style={{marginLeft: isEmpty ? 32 : 16}} className={`${containerTag}-title`}>{title}</div>
                {this.renderOptions(list, index, `${containerTag}-dropdown`)}
            </div>
            <div className={`${containerTag}-delete-container${this.props.disabled?' disabled':''}`} onClick={()=>{
                  if(!this.props.disabled)
                    this.removeProvider(index)
                }}>
                <img className={`${containerTag}-delete`} src={DeleteIcon}/>
            </div>
        </div>
        )
    }

    render(){
        var list = Object.assign([],this.props.activeList)
        var items = list.map((value, index)=>{
            return this.renderDropdown(list, index)
        })
        var addButton = <button disabled={!this.props.isWeek} onClick={this.onNewEligibleProvider} className={`add-eligible-provider-button${!this.props.isWeek?" disabled":""}`}>ADD ELIGIBLE PROVIDER TO ROLE</button>
        items.push(addButton)
        return items
    }

    onNewEligibleProvider = () => {
      var list = Object.assign([],this.props.activeList)
      list.push(null)
      this.props.onActiveListUpdated(list)
    }

    removeProvider = (index)=> {
      var list = Object.assign([],this.props.activeList)
      list.splice(index, 1)
      this.props.onActiveListUpdated(list)
    }

    onChange = (providerId, index)=>{
      console.log(providerId)
      console.log(this.getProviderName(providerId))
      var list = Object.assign([],this.props.activeList)
      if(list.length <= index){ //adding a new provider...
        list.push(providerId)
      }
      else{
        list[index] = providerId //updating existing provider...
      }
      this.props.onActiveListUpdated(list)
    }
}