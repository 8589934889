export const PrintPatientScheduleMachine = {
    initial: 'ShowDates',
    states: {
        ShowDates: {
            on: {
                RESOLVE: 'ShowPrint',
                CANCEL: 'stop'
            }
        },
        ShowPrint: {
            on: {
                DONE: 'ShowDates'
            }
        },
        stop: {}
    },
}