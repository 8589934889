import React from 'react'
import {Button} from 'react-bootstrap'
import './itemList.css'
import NavigationHeader from './NavigationHeader'

export default class ItemList extends React.Component{

    getCreateTitle = ()=> {
        return `Create New ${this.props.title}`
    }

    render(){
        return[
            this.disableNavigation ? null : 
                <NavigationHeader navigationBackTitle={this.props.navigationBackTitle} 
                    onNavigateBack={this.props.onCancel} 
                    navigationOptionsTitles={[this.getCreateTitle()]}
                    onNewOption={this.onOption}/>,
            <div align="center">
                <br/>
                Edit an existing {this.props.title}
                <br/>
                {this.props.items.map((item)=>{
                    return(
                        <>
                            <Button variant="light" onClick={()=>{this.props.onExistingItem(item.item)}} style={item.style}>{item.title}</Button>
                            <br/>
                        </>
                    )
                })}
            </div>
        ]
    }

    onOption = (option)=> {
        if(option === this.getCreateTitle()) this.props.onNewItem()
    }
}