export const ScheduleByConfigurationMachine = {
    initial: 'Load',
    states: {
        Load: {
            on: {
                NEXT: 'ProviderAvailability',
                CANCEL: 'stop'
            }
        },
        ProviderAvailability: {
            on: {
                NEXT: 'PatientServiceConfiguration',
                PREVIOUS: 'stop'
            }
        },
        PatientServiceConfiguration: {
            on: {
                NEXT: 'GenerateSchedule',
                PREVIOUS: 'ProviderAvailability',
                CANCEL: 'stop'
            }
        },
        GenerateSchedule: {
            on: {
                FINISH: 'stop',
                PREVIOUS: 'PatientServiceConfiguration',
                CANCEL: 'stop'
            }
        },
        stop: {}
    },
}