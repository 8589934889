import React from 'react'
import '../assets/clean-switch.css'
import './Switch.css'

const Switch = (props)=> {
    return <label className="cl-switch lightishBlue">
        <input type="checkbox" disabled={props.disabled} checked={props.checked}
               onChange={props.onChange}/>
        <span className="switcher"></span>
    </label>
}

export default Switch