import ManagePatientsIcon from '../../img/Icons/patients.png'
import ScheduleAppointmentIcon from '../../img/Icons/schedule.png'
import AgendaIcon from '../../img/Icons/Agenda.png'
import PatientEvaluationIcon from '../../img/Icons/evaluation.png'
import FacilityScheduleIcon from '../../img/Icons/facility.png'
import TeamDirectoryIcon from '../../img/Icons/TeamDirectory.png'
import StringManager, { StringFlags, StringResources } from '../../strings/StringManager'

export default class HomeOptions {
    static rows = {
        'Views': ['user-schedule', 'facility-schedule', 'provider-schedule'],
        'Actions': ['export-facility-schedule', 'print-patient-schedule', 'schedule-appointment', 'schedule-appointment-v2', 'schedule-by-configuration', 'schedule-forward'],
        'Manage': ['manage-activities','manage-patients', 'manage-providers', 'manage-services', 'manage-groups', 'zero-minute-schedule', 'manage-facility', 'manage-announcements', 'billing', 'patient-evaluation']
    }

    static GetAccessList() {
        //List needs to be generated when called, since that would happen after we have localized text for StringManager
        var list = {
            'user-schedule': {
                title: "User Schedule",
                icon: 'agenda',
                legacyAccessId: "5wut82"
            },
            'facility-schedule': {
                title: "Facility Schedule",
                icon: 'facility',
                legacyAccessId: "yaz4sn"
            },
            'provider-schedule': {
                title: 'Provider Schedule',
                icon: 'provider',
                legacyAccessId: "yaz4sn"
            },
            'print-patient-schedule': {
                title: `Print ${StringResources.patient(StringFlags.Capitalize)} Schedules`,
                icon: 'agenda',
                legacyAccessId: "lw0ii6"
            },
            'schedule-appointment': {
                title: 'Schedule Appointment',
                icon: 'agenda',
                legacyAccessId: "nvjalc"
            },
            'schedule-appointment-v2': {
                title: `Schedule Group Appointment`,
                icon: 'group-agenda',
                legacyAccessId: "nvjalc"
            },
            // 'schedule-by-configuration': {
            //     title: 'Schedule By Configuration',
            //     icon: 'agenda',
            //     legacyAccessId: "co0t4g"
            // },
            'manage-activities': {
                title: `Manage Activities`,
                icon: 'agenda'
            },
            'manage-patients': {
                title: `Manage ${StringResources.patient(StringFlags.Capitalize | StringFlags.Plural)}`,
                icon: 'agenda',
                legacyAccessId: 'lw0ii6'
            },
            'manage-providers': {
                title: 'Manage Users',
                icon: 'provider',
                legacyAccessId: "co0t4g"
            },

            'manage-services': {
                title: 'Manage Services',
                icon: 'provider',
                legacyAccessId: "co0t4g"
            },
            'manage-groups': {
                title: 'Manage Groups',
                icon: 'facility'
            },

            'zero-minute-schedule': {
                title: 'Zero Minute Schedules™',
                icon: 'agenda',
                legacyAccessId: "yaz4sn"
            },
            'manage-facility': {
                title: 'Manage Facility',
                icon: 'facility',
                legacyAccessId: "co0t4g"
            },
            'billing': {
                title: 'Billing',
                icon: 'provider',
                legacyAccessId: "co0t4g"
            },
            'patient-evaluation': {
                title: `${StringResources.patient(StringFlags.Capitalize)} Evaluation`,
                icon: 'agenda',
                legacyAccessId: "gzisxs"
            },
            'manage-announcements': {
                title: 'Manage Announcements',
                icon: 'facility',
                legacyAccessId: 'co0t4g'
            },
            'schedule-forward': {
                title: 'Schedule Forward',
                icon: 'facility'
            },
            'export-facility-schedule': {
                title: 'Export Schedules',
                icon: 'agenda'
            }
        }
        return list
    }

    static stateMachineEvents = {
        'user-schedule': "SELECT_AGENDA",
        'facility-schedule': "SELECT_FACILITY_SCHEDULE",
        'provider-schedule': "SELECT_PROVIDER_SCHEDULE",
        'print-patient-schedule': "SELECT_PRINT_PATIENT_SCHEDULES",
        'schedule-appointment': "SELECT_SCHEDULE_APPOINTMENT",
        'schedule-appointment-v2': "SELECT_SCHEDULE_GROUP_APPOINTMENT",
        'schedule-by-configuration': "SELECT_SCHEDULE_BY_CONFIGURATION",
        'manage-patients': "SELECT_MANAGE_PATIENTS",
        'manage-providers': "SELECT_MANAGE_PROVIDERS",
        'manage-services': "SELECT_MANAGE_SERVICES",
        'manage-groups': "SELECT_MANAGE_GROUPS",
        'zero-minute-schedule': "SELECT_ZERO_MINUTE_SCHEDULES",
        'manage-facility': "SELECT_MANAGE_FACILITY",
        'patient-evaluation': "SELECT_PATIENT_EVALUATION",
        'billing': "SELECT_BILLING",
        'manage-announcements': "SELECT_MANAGE_ANNOUNCEMENTS",
        'manage-activities': 'SELECT_MANAGE_ACTIVITIES',
        'schedule-forward': 'SELECT_SCHEDULE_FORWARD',
        'export-facility-schedule': 'SELECT_EXPORT_SCHEDULES'
    }

    static getAccessListWithEvents() {
        var list = Object.assign({}, HomeOptions.GetAccessList())
        Object.keys(list).forEach(key => {
            list[key].event = HomeOptions.stateMachineEvents[key]
        });
        return list
    }

    static canUserAccess(accessId, userOptions) {
        for (let i = 0; i < userOptions.length; i++) {
            const userAllowedAccessOption = userOptions[i];
            //check legacy
            if (userAllowedAccessOption === accessId) {
                return true
            }

            //check new flow
            if (HomeOptions.GetAccessList()[userAllowedAccessOption]
                && HomeOptions.GetAccessList()[userAllowedAccessOption].legacyAccessId === accessId) {
                return true
            }
        }
        return false
    }
}