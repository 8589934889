import React from 'react';
import {Button, Modal} from 'react-bootstrap'
import Colors from '../../util/Colors';
import Tracker from '../../managers/Tracker';
import StringManager, { StringFlags, StringResources } from '../../strings/StringManager';
import CalendarUtil from '../../util/CalendarUtil';

export default class DischargePatientPage extends React.Component{

    constructor() {
      super()
      Tracker.logScreenView('manage_patients_discharge')
    }

    discharge = () => {
      this.props.machine.send("CONFIRM_DISCHARGE")
      this.props.onDischarge()
    }

    formatSelectedDate(){

      if(this.props.endDate == null) return null

      var chunks = this.props.endDate.toString().split(/\s+/)

      var month = CalendarUtil.getLongMonth(chunks[1])
      var day = parseInt(chunks[2])
      var year = chunks[3]

      return month + " " + day + ", " + year
    }
  
    cancel = () => {
      this.props.machine.send("CANCEL")
    }
  
    render(){
      return(
        <div>
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>Discharge this {StringResources.patient()} on {this.formatSelectedDate()}?</Modal.Title>
            </Modal.Header>
  
            <Modal.Footer>
              <Button variant="link" onClick={this.cancel}>Cancel</Button>
              <Button variant="primary" style={{backgroundColor: Colors.Primary.Main, border: "0px"}} onClick={this.discharge}>Discharge</Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )
    }
  }