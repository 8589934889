import React from 'react'
import FacilityManager from '../../managers/FacilityManager';
import Helpers from '../../util/Helpers'
import { Button, Row, Col, Container } from 'react-bootstrap'
import './Components/manageGroupsPage.css'
import NavigationHeader from '../../ui/NavigationHeader'
import Colors from '../../util/Colors'
import SDEditTextBox from '../../ui/SDEditTextBox';
import LoadingSpinner from '../../util/LoadingSpinner';

export default class ManageGroupsPage extends React.Component {
    CREATE_NEW_GROUP = 'Create New Group'
    constructor(props) {
        super(props)
        document.body.style = 'background:' + Colors.Secondary.Light + ';';
        this.state = {
            loading: true,
            groups: null,
            editingGroup: null,
            groupsDict: null,
            selectedRoomsImmutable: [],
            selectedRooms: []
        }
        this.getGroups()
    }

    render() {
        if (this.state.loading) return <LoadingSpinner />
        var state = this.props.machine.state.value["ManageGroups"]
        switch (state) {
            case "SelectOption":
                return this.renderSelectGroups()
            case "ViewOption":
            case "NewOption":
            case "EditOption":
                return this.renderViewOption(state == "EditOption" || state == "NewOption")
        }
    }

    getGroups = () => {
        return new Promise((resolve) => {
            FacilityManager.getGroups(this.props.facility.id, null, null, (result) => {
                let dict = {}
                result.forEach((item) => {
                    dict[item.groupId] = item
                })
                this.setState({ loading: false, groups: result, groupsDict: dict })
            })
        })
    }

    renderSelectGroups = () => {
        if (!Array.isArray(this.state.groups)) return null

        var itemButtons = this.state.groups.sort((a,b)=>{
            return a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1
        }).map(v => {
            return (
                <Button className="selectItemButton"
                    variant="light"
                    onClick={() => this.editGroup(v.groupId)}
                    style={
                        { borderColor: "#000000", width: 300, marginTop: 15 }
                    }>
                    {v.id}
                </Button>
            )
        })
        return <>
            <NavigationHeader navigationOptionsTitles={[this.CREATE_NEW_GROUP]} onNewOption={this.onOptionClicked} />
            <div className="selectItemContainer">
                {itemButtons}
            </div>
        </>
    }

    renderViewOption = (editing) => {
        var rowSpacing = 24
        var formWidth = 724

        var content = null
        switch (this.state.editingGroup.itemType) {
            case "occupancyId":
                content = (
                    <>
                        <Row noGutters={true}>
                            <Col className="selector-text">{editing ? "Select Occupancies" : "Occupancies"}  </Col>
                        </Row>
                        {this.drawOccupancyButtons(formWidth, editing)}
                    </>
                )
                break;
            case "roomId":
                content = (
                    <>
                        <Row noGutters={true}>
                            <Col className="selector-text">{editing ? "Select Rooms" : "Rooms"}  </Col>
                        </Row>
                        {this.drawRoomButtons(formWidth, editing)}
                    </>
                )
                break;
        }
        return <>
            <NavigationHeader navigationOptionsTitles={editing ? ["Cancel", "Save"] : ["Edit Group"]}
                onNewOption={this.onOptionClicked}
                navigationBackTitle={this.getBackButtonTitle()}
                onNavigateBack={this.onNavigateBack} />

            <Container fluid style={{ padding: 0, marginTop: 64, width: formWidth }}>
                <Row noGutters={true} style={{ marginBottom: rowSpacing }}>
                    <SDEditTextBox disabled={!editing} label="NAME" defaultValue={this.state.editingGroup.id} onChange={v => {
                        let editGroupTmp = this.state.editingGroup
                        editGroupTmp.id = v
                        this.setState({ editingGroup: editGroupTmp })
                    }} />
                </Row>

                <Row noGutters={true}>
                    <Col className="selector-text">{!editing || this.state.editingGroup.groupId ? "Group Type" : "Choose Group Type"}</Col>
                </Row>

                {this.drawTypeButtons(formWidth, editing)}

                {content}


            </Container>
        </>
    }

    drawTypeButtons(formWidth, editing) {
        var rowSpacing = 12
        var isTypeRoom = this.state.editingGroup.itemType === "roomId"
        var isTypeOccupancy = this.state.editingGroup.itemType === "occupancyId"
        return (
            <Container fluid style={{ padding: 0, width: formWidth }}>
                <Row noGutters={true} style={{ marginBottom: rowSpacing, marginLeft: 4 }}>
                    <Col></Col>
                    <Col>
                        <Button disabled={!editing || this.state.editingGroup.groupId} onClick={() => this.pickType('roomId')}
                            style={{
                                border: "1px solid " + Colors.Steel,
                                filter: isTypeRoom ? "drop-shadow(0px 3px 6px " + Colors.DropShadow + ")" : "",
                                color: isTypeRoom ? Colors.Secondary.Light : Colors.MiddleGray,
                                backgroundColor: isTypeRoom ? Colors.Primary.Main : Colors.NavGray,
                                fontSize: 14,
                                width: 169,
                                height: 48
                            }}
                        >
                            Room
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={!editing || this.state.editingGroup.groupId} onClick={() => this.pickType('occupancyId')}
                            style={{
                                border: "1px solid " + Colors.Steel,
                                filter: isTypeOccupancy ? "drop-shadow(0px 3px 6px " + Colors.DropShadow + ")" : "",
                                color: isTypeOccupancy ? Colors.Secondary.Light : Colors.MiddleGray,
                                backgroundColor: isTypeOccupancy ? Colors.Primary.Main : Colors.NavGray,
                                fontSize: 14,
                                width: 169,
                                height: 48
                            }}
                        >
                            Occupancy
                        </Button>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        )
    }

    drawRoomButtons(formWidth, editing) {
        var rooms = Helpers.sortRooms(this.props.facility.rooms, true, false)
        var rows = []
        var rowSpacing = 12
        for (var rowIndex = 0; rowIndex < rooms.length; rowIndex += 4) {
            var rowItems = []
            for (var columnIndex = 0; columnIndex < 4; columnIndex++) {
                rowItems.push(
                    <Col> {this.drawRoomButton(rooms[rowIndex + columnIndex], false, editing)} </Col>
                )
            }
            rows.push(
                <Row noGutters={true} style={{ marginBottom: rowSpacing, marginLeft: 4 }}>
                    {rowItems}
                </Row>
            )
        }

        return (
            <Container fluid style={{ padding: 0, width: formWidth }}>
                {rows}
            </Container>
        )
    }

    drawOccupancyButtons(formWidth, editing) {
        var rooms = Helpers.sortRooms(this.props.facility.rooms, true, false)
        rooms = rooms.filter((room) => {
            return room.occupied
        })
        var rows = []
        var rowSpacing = 12
        for (var rowIndex = 0; rowIndex < rooms.length; rowIndex += 4) {
            var rowItems = []
            for (var columnIndex = 0; columnIndex < 4; columnIndex++) {
                rowItems.push(
                    <Col> {this.drawRoomButton(rooms[rowIndex + columnIndex], true, editing)} </Col>
                )
            }
            rows.push(
                <Row noGutters={true} style={{ marginBottom: rowSpacing, marginLeft: 4 }}>
                    {rowItems}
                </Row>
            )
        }

        return (
            <Container fluid style={{ padding: 0, width: formWidth }}>
                {rows}
            </Container>
        )
    }

    drawRoomButton(room, useOccupancyId, editing) {

        if (room == null) return null

        var selected = this.state.selectedRooms.includes(useOccupancyId ? room.occupancyId : room.id)

        var bg = selected ? Colors.Primary.Main : Colors.NavGray
        var fc = selected ? Colors.Secondary.Light : Colors.MiddleGray
        var filter = selected ? "drop-shadow(0px 3px 6px " + Colors.DropShadow + ")" : ""

        return (
            <Button disabled={!editing} onClick={() => this.toggleButton(room, useOccupancyId)}
                style={{
                    border: "1px solid " + Colors.Steel,
                    filter: filter,
                    color: fc,
                    backgroundColor: bg,
                    fontSize: 14,
                    width: 169,
                    height: 48
                }}
            >{`${Helpers.getRoomName(room)} ${useOccupancyId ? room.identifier : ""}`}</Button>
        )
    }

    toggleButton = (room, useOccupancyId) => {
        var id = useOccupancyId ? room.occupancyId : room.id
        var rooms = this.state.selectedRooms
        if (rooms.includes(id)) rooms.splice(rooms.indexOf(id), 1)
        else rooms.push(id)
        this.setState({
            selectedRooms: rooms
        })
    }

    pickType = (type) => {
        var group = this.state.editingGroup
        group.itemType = type
        this.setState({ editingGroup: group })
    }

    onOptionClicked = (option) => {
        switch (option) {
            case this.CREATE_NEW_GROUP:
                this.newGroup().then(() => {
                    this.enableEditing()
                })
                break;
            case 'Cancel':
                this.cancel()
                break;
            case 'Save':
                this.saveGroup()
                break;
            case 'Edit Group':
                this.enableEditing()
                break;
        }
    }

    getBackButtonTitle = () => {
        var state = this.props.machine.state.value["ManageGroups"]
        switch (state) {
            case "SelectOption":
                return "Home"
            case "ViewOption":
                return "Manage Groups"
            case "EditOption":
                return "View Group"
            case "NewOption":
                return "Manage Groups"
        }
    }

    enableEditing = () => {
        this.setState({ allowEditing: true })
        this.props.machine.send("EDIT")
    }

    getGroupDetails = () => {
        if (!this.state.editingGroup.groupId) return
        this.setState({ loading: true })
        FacilityManager.getGroup(this.props.facility.id, this.state.editingGroup.groupId, null, (result) => {
            console.log(result)
            var items = result.data.filter((item) => {
                return item.idType !== "groupName"
            })
            var ids = items.map((item) => {
                return item.id
            })

            //filter out ids that do not belong to existing rooms/occupancies, but do it without modifying the old ids
            //When it gets saved again, those that do not belong will be removed from the backend.
            var validIds = items.filter((item)=>{
                let keep = false
                switch (item.idType) {
                    case "roomId":
                        this.props.facility.rooms.forEach((room)=>{
                            if(room.id === item.id) keep = true
                        })
                        break
                    case "occupancyId":
                        this.props.facility.rooms.forEach((room)=>{
                            if(room.occupancyId === item.id) keep = true
                        })
                        break;
                }
                return keep
            }).map((item)=>{
                return item.id
            })

            this.setState({ loading: false, selectedRoomsImmutable: ids, selectedRooms: validIds })
        })
    }

    editGroup = (groupId) => {
        this.setState({ editingGroup: Object.assign({}, this.state.groupsDict[groupId]), allowEditing: false }, () => {
            this.props.machine.send("VIEW")
            if (this.state.editingGroup.id) {
                this.getGroupDetails()
            }
        })
    }

    newGroup = (groupId) => {
        return new Promise((resolve) => {
            this.setState({ selectedRooms: [], editingGroup: ManageGroupsPage.generateGroup(this.props.facility.id, undefined, '', ManageGroupsPage.GROUP_TYPE_NAME), allowEditing: true }, () => {
                this.props.machine.send("NEW")
                resolve()
            })
        })
    }

    saveGroup = () => {
        var group = this.state.editingGroup
        var error
        if (!group.id) error = "Missing group title"
        if (!group.id) error = "Missing Identifier"

        if (error) {
            this.setState({ error: error })
        }
        else {
            this.setState({ error: null, loading: true })
            FacilityManager.postGroup(group, (result) => {
                if (result.statusCode === 200) {
                    this.saveSelections(result.data.groupId).then(() => {
                        this.props.machine.send("SAVE")
                        this.getGroups().then(() => {
                            this.setState({ editingGroup: Object.assign({}, this.state.groupsDict[this.state.editingGroup.groupId]), allowEditing: false, selectedRooms: [] })
                        })
                    })
                }
                else {
                    this.setState({ loading: false, error: result.error })
                }

            })
        }
    }

    saveSelections = (groupId) => {
        return new Promise((resolve) => {
            var addedItems = []
            var removedItems = []

            var type = this.state.editingGroup.itemType

            //find out what items we added
            this.state.selectedRooms.forEach((id) => {
                if (!this.state.selectedRoomsImmutable.includes(id)) {
                    addedItems.push(
                        ManageGroupsPage.generateGroup(this.props.facility.id, groupId, id, type)
                    )
                }
            })

            //find out what items we removed
            this.state.selectedRoomsImmutable.forEach((id) => {
                if (!this.state.selectedRooms.includes(id)) {
                    removedItems.push(
                        ManageGroupsPage.generateGroup(this.props.facility.id, groupId, id, type)
                    )
                }
            })

            //First delete items
            FacilityManager.deleteGroupItems(this.props.facility.id, removedItems, (result) => {
                //TODO error checking
                //Then Add items
                FacilityManager.putGroupItems(this.props.facility.id, addedItems, (result) => {
                    //TODO error checking
                    resolve()
                })
            })
        })
    }

    cancel = () => {
        var state = this.props.machine.state.value["ManageGroups"]
        switch (state) {
            case "ViewOption":
                this.setState({ editingGroup: null, error: null, allowEditing: false, selectedRooms: null })
                break;
            case "EditOption":
                this.setState({ editingGroup: Object.assign({}, this.state.groupsDict[this.state.editingGroup.groupId]), allowEditing: false, selectedRooms: [] })
                this.getGroupDetails()
                break;
            case "NewOption":
                this.setState({ editingGroup: null, error: null, allowEditing: false, selectedRooms: null })
                break;
        }
        this.props.machine.send("CANCEL")
    }

    onNavigateBack = () => {
        var state = this.props.machine.state.value["ManageGroups"]
        switch (state) {
            case "ViewOption":
                this.setState({ editingGroup: null, error: null, allowEditing: false, selectedRooms: null })
                break;
            case "EditOption":
                this.setState({ editingGroup: Object.assign({}, this.state.groupsDict[this.state.editingGroup.groupId]), allowEditing: false, selectedRooms: [] })
                this.getGroupDetails()
                break;
            case "NewOption":
                this.setState({ editingGroup: null, error: null, allowEditing: false, selectedRooms: null })
                break;
        }
        this.props.machine.send("BACK")
    }

    static generateGroup(facilityId, groupId, id, idType) {
        return {
            groupId: groupId,
            id: id,
            idType: idType,
            facilityId: facilityId
        }
    }

    static GROUP_TYPE_OCCUPANCY = 'occupancyId'
    static GROUP_TYPE_ROOM_ID = 'roomId'
    static GROUP_TYPE_NAME = 'groupName'
}