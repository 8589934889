export const ManageUsersMachine = {
    initial: 'ListUsers',
    states: {
        ListUsers: {
            on: {
                CREATE: 'CreateUser',
                EDIT: 'EditUser',
                CANCEL: 'stop'
            }
        },
        CreateUser: {
            on: {
                DELETE: 'ListUsers',
                SAVE: 'ListUsers',
                CANCEL: 'ListUsers'
            }
        },
        EditUser: {
            on: {
                DELETE: 'ListUsers',
                SAVE: 'ListUsers',
                CANCEL: 'ListUsers'
            }
        },
        stop: {}
    }
}