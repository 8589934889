import ScheduleForwardPage from "../pages/ScheduleForward/ScheduleForwardPage"
import { StringFlags, StringResources } from "../strings/StringManager"

/**
 * Get the title for a given state machine value
 * @param {*} machineState State machine value
 * @param {*} state App.js react state
 */
export function GetTitleForState(machineState, state) {
    if (machineState === "Home") return ""

    const key = Object.keys(machineState)[0]
    const value = machineState[key]
    switch (key) {
        //Views
        case "Agenda": 
            switch (value) {
                case "PrintAgenda":
                    return null
                default:
                    return "User Schedule"
            }
        case "FacilitySchedule": 
            switch (value) {
                case "PrintServiceAgenda":
                    return null
                default:
                    return "Facility Schedule"
            }
        case "FacilityProviderSchedule": return "Provider Schedule"

        //Actions
        case "ExportFacilitySchedules":
            return "Export Facility Schedules"
        case "PrintPatientSchedule":
            switch (value) {
                case "Load":
                case "ShowDates":
                    return `Print ${StringResources.patient(StringFlags.Capitalize)} schedules`
                default:
                    return null
            }
        case "ScheduleAppointment": return "Schedule an Appointment"
        case "ScheduleGroupAppointment": return "Schedule an Appointment"
        case "ScheduleByConfiguration":
            switch(value){
                case "Load": return "Schedule By Configuration"
                case "ProviderAvailability": return "Provider Availability"
                case "PatientServiceConfiguration": return `${StringResources.patient(StringFlags.Capitalize)} Schedule Configuration`
                case "GenerateSchedule": return "Generate Schedule"
            }
            break
        case "ScheduleForward": 
            if(ScheduleForwardPage.stateTitles[value]){
                return ScheduleForwardPage.stateTitles[value].TITLE.get()
            }
            return "Schedule Forward"
            break
        //Manage
        case "PatientEvaluation": return `${StringResources.patient(StringFlags.Capitalize)} Evaluation`
        case "ManageActivities": return "Manage Activities"
        case "ManagePatients":
            return `Manage ${StringResources.patient(StringFlags.Capitalize | StringFlags.Plural)}`
        case "ManageServices": 
            switch(value){
                case "Load": return "Manage Services"
                case "ListServices": return "Manage Services"
                case "CreateService": return "Create Service"
                case "EditService": return "Edit Service"
                case "PickUsers": return "Edit Service"
                default: return "Manage Services"
            }
        case "ManageUsers": 
            switch(value){
                case "ListUsers": return "Manage Users"
                case "CreateUser": return "Create User"
                case "EditUser": return "Edit User"
                default: return "Manage Users"
            }
        case "ZeroMinuteSchedule": 
            switch(value){
                case "Load": return "Zero Minute Schedule"
                case "EditSimpleSchedule": return "Zero Minute Simple Schedule"
                case "EditAutoSchedule": return "Zero Minute Auto Schedule"
                default: return "Zero Minute Schedule"
            }        
        case "Billing":
            return "Billing"
        case "AdminPanel": 
            switch(value){
                case "Rooms": return "Manage Rooms"
                case "EditRoom": return "Edit Room"
                case "NewRoom": return "Create Room"
                case "Locations": return "Manage Locations"
                case "NewLocation": return "Create Location" 
                case "EditLocation": return "Edit Location"
                case "EditRestrictionCalendar": 
                case "EditRestrictionPopup":
                    return "Manage Blocked Times"
                default: 
                    //there may be another key value pair in value
                    var valueKey = Object.keys(value)[0] 
                    switch(valueKey){
                        case "ListFacilityRoles": 
                            switch(value[valueKey]){
                                case "ListRoles": return "Manage Roles"
                                case "CreateRole": return "Create Role"
                                case "EditRoles": return "Edit Role"
                            }
                    }
                    //if not, return our generic string
                    return "Manage Facility"
            }
        case "ManageGroups": 
            switch(value){
                case "SelectOption": return "Manage Groups"
                case "ViewOption": return "View Group"
                case "EditOption": return "Edit Group"
                case "NewOption": return "New Group" 
                default: return "Manage Groups"
            }

        //default
        default: return ""
    }
}

/**
 * Get the subtitle for a given state machine value
 * @param {*} machineState State machine value
 * @param {*} state App.js react state
 */
export function GetSubTitleForState(machineState, state) {
    if (machineState === "Home") return ""

    const key = Object.keys(machineState)[0]
    const value = machineState[key]

    switch (key) {
        case "ScheduleAppointment":
        case "ScheduleGroupAppointment":
            return null
        default:
            return ""
        case "ManageActivities": return "Schedule Activities"
        case "ManagePatients":
            switch (value) {
                case "Load":
                case "ShowRooms":
                case "SelectEmptyRoom":
                case "SelectOccupiedRoom":
                    return "Room Lookup"
                case "NewPatient":
                    return "Set Occupancy"
                case "SetStartDate":
                    return "Set start date"
                case "SetEndDate":
                    return "Set discharge date"
                case "RenameOccupancy":
                    return "Rename: " + state.schedulingRoom.name + " - " + state.schedulingRoom.identifier
                case "Schedule":
                case "SchedulePopup":
                    return "Schedule appointments: " + state.schedulingRoom.name + " - " + state.schedulingRoom.identifier
                default:
                    return ""
            }

        case "Agenda":
            switch (value) {
                case "ShowAgenda":
                    return "Daily User Schedule"
                case "PrintAgenda": return null
                default:
                    return ""
            }

        case "PatientEvaluation":
            switch (value) {
                case "SelectRoom":
                case "ShowEvaluation":
                    return "Select a room and enter therapy duration"
                default:
                    return ""
            }

        case "AdminPanel":
            switch (value) {
                case "Billing":
                    return "Billing"
                case "Alerts":
                    return "Announcements"
                case "SelectProvider":
                    return "Edit Provider"
                case "Services":
                    return "Services"
                default:
                    return ""
            }
        case "FacilitySchedule":
            switch (value) {
                case "ShowServiceAgenda":
                    return "Daily Service Schedule"
                default:
                    return ""
            }
        case "FacilityProviderSchedule":
            switch (value) {
                case "ShowServiceAgenda":
                    return "Daily Service Schedule"
                default:
                    return ""
            }
    }
}