import NetworkManager from './NetworkManager';
import { Endpoint } from '../util/Constants';
import Appointment, {AppointmentAssignee, AppointmentV2, LinkedAppointment} from '../models/Appointment'
import Queries from './Queries'
import PromisifyCallback from '../util/PromisifyCallback';

/**
 * @deprecated use AppointmentRepository instead where possible
 */
export default class LegacyAppointmentManager {

    /**
     * @param {String} id appointment id
     * @param {String} facilityId facility the appointment is scheduled for. Not required but increase query performance
     * @param {(data) => {}} onResult
     */
    static getAppointment = (id, facilityId, onResult) => {
        let path = Endpoint.base + "/appointments";

        let params = {
            id: id,
            facility: facilityId
        }

        void NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * @param {String} ownerId Query by a provider, room, occupancy, location. Can be null if facilityId is provided
     * @param {String} facilityId providers can belong to multiple facilities, provide this along with an ownerId to see only appointments at that facility. If ownerId is null, this will return all appointments at a facility
     * @param {String} serviceId filter to only show a specific service
     * @param {String} dateRange filter by appointment start time. Will return all appointments that begin within the given date range. start or end can be null if desired
     * @param {(data) => {}} onResult
     */
    static listAppointments = (ownerId, facilityId, serviceId, urlCode, dateRange, onResult) => {
        let params = Queries.getAppointmentQueryParams(dateRange, facilityId, ownerId, serviceId, urlCode)
        this.listAppointmentsUsingQuery(params, onResult)
    }

    /**
     * Get the appointments based on the AppointmentQuery
     * @see src/managers/Queries.ts:AppointmentQuery
     * @param {(data) => {}} onResult
     */
    static listAppointmentsUsingQuery = (params, onResult) => {
        let path = Endpoint.base + "/appointments/list";
        void NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    static getDefaultDuration = (occupancyId, serviceId, onResult)=> {
        let path = Endpoint.base + "/appointments/default-duration"
        var params = {
            occupancyId: occupancyId,
            serviceId: serviceId
        }
        void NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    static getLastCreatedAppointment = (occupancyId, serviceId, onResult)=> {
        let path = Endpoint.base + "/appointments/last-created-appointment"
        var params = {
            occupancyId: occupancyId,
            serviceId: serviceId
        }
        void NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * @description Get info on schedule conflicts between users. It's important to include the facilityId AND a full dateRange. If not, facility-wide time restrictions will not be taken into consideration (lunch, dinner, etc)
     * @param {string[]} ownerIds who's schedules should be compared. Must provide at least 2 ids
     * @param {string} facilityId filter by facility. If provided this will also fetch the mandatory facility-wide events when looking for conflicts
     * @param {{start: number, end: number}} dateRange filter by appointment start time. Will include all appointments that begin within the given date range. start or end can be null if desired.
     * @param {boolean} appointmentData if true, this will return both the conflicts date ranges and the every appointment object that was used in the comparison. If false, only the conflicts will be return.
     * @param {(data) => {}} onResult
     */
    static compareAppointments = (ownerIds, facilityId, dateRange, appointmentData, showBlockedTime, onResult) => {
        let path = Endpoint.base + "/appointments/compare";

        if (Array.isArray(ownerIds)) { ownerIds = ownerIds.join() }

        let params = {
            'users': ownerIds,
            'facility': facilityId,
            'blockedTimes': showBlockedTime
        }

        if (dateRange) {
            params["start"] = dateRange.start
            params["end"] = dateRange.end
        }

        if (appointmentData) {
            params["appointmentData"] = appointmentData
        }
        void NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * Maybe use this verbose method to ensure we avoid typos in the dictionary when posting data
     * @param {String} facilityId
     * @param {String} serviceId
     * @param {Array<String>} providerIds
     * @param {String} locationId
     * @param {String} roomId
     * @param {String} creatorId
     * @param {Date} start
     * @param {Date} end
     * @param {String} title
     * @param {String} description
     * @param {Array<String>} notes
     * @param {number} recurrenceCount how many appointments should be scheduled in advance
     * @param {number} recurrenceFrequency 1 = everyday, 2 = every other day, 7 = once per week
     * @param {(data) => {}} onResult
     */

    static createAppointment = (facilityId, serviceId, providerIds, locationId, roomId, occupancyId, occupancyIdentifier, creatorId, start, end, title, description, notes, roomName, recurrenceCount = 0, recurrenceFrequency = 0, onResult) => {
        var appointment = new Appointment(creatorId, facilityId, serviceId, start, end, providerIds, locationId, roomId, occupancyId, occupancyIdentifier, title, description, notes, roomName, recurrenceCount, recurrenceFrequency)
        LegacyAppointmentManager.createAppointmentWithObject(appointment, onResult);
    }

    static update2ferFlag = async(facilityId, appointmentId, require2fer) => {
        let path = Endpoint.base + "/appointments/update2ferFlag";

        return NetworkManager.post(path, {
            facilityId: facilityId,
            id: appointmentId,
            require2fer: require2fer
        }).then((data) => {
            if(data.statusCode && data.statusCode !== 200) throw data
            return data
        }) //upstream will catch this
    }

    /**
     * @param {Object} params
     */
    static updateAppointment = (params, onResult) => {
        params = Appointment.convertFromObject(params) //sometimes we may attach data here that should not be saved
        let path = Endpoint.base + "/appointments/update";

        void NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * @param appointment {AppointmentV2}
     * @param assignees {AppointmentAssignee[]|undefined}
     * @param linkedAppointments {LinkedAppointment[]|undefined}
     * @param recurrence {Recurrence|undefined}
     * @param onResult
     */
    static updateAppointmentV2 = (appointment, assignees, linkedAppointments, recurrence, onResult) => {
        let path = Endpoint.base + "/appointments/updateV2";

        void NetworkManager.post(path, {
            details: appointment,
            assignees: assignees,
            linked: linkedAppointments,
            recurrence: recurrence
        }, (result) => {
            onResult(result)
        })
    }

    /**
     * @param appointment {AppointmentV2}
     * @param assignees {AppointmentAssignee[]|undefined}
     * @param linkedAppointments {LinkedAppointment[]|undefined}
     * @param recurrence {Recurrence|undefined}
     * @param onResult
     */
    static createAppointmentV2 = (appointment,
                                  assignees,
                                  linkedAppointments,
                                  recurrence,
                                  onResult) => {
        let path = Endpoint.base + "/appointments/createV2";

        void NetworkManager.post(path, {
            details: appointment,
            assignees: assignees,
            linked: linkedAppointments,
            recurrence: recurrence
        }, (result) => {
            onResult(result)
        })
    }

    static stashAppointment = (facilityId, id, onResult)=>{
        let path = Endpoint.base + "/appointments/stash";
        let params = {
            facilityId: facilityId,
            id: id
        }

        void NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static unstashAppointment = (facilityId, id, onResult)=>{
        let path = Endpoint.base + "/appointments/unstash";
        let params = {
            facilityId: facilityId,
            id: id
        }

        void NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static cancelAppointment = (id, onResult) => {
        let path = Endpoint.base + "/appointments/cancel";

        let params = {
            id: id
        }

        void NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static cancelAppointments = async(ids, onResult) => {
        var promises = []
        if(!Array.isArray(ids) || ids.length === 0) return []
        var cancelPromise = new PromisifyCallback(this.cancelAppointment)
        for(var id of ids){
            promises.push(cancelPromise.invoke(id))
        }
        let results = await Promise.all(promises)
        onResult(results)
    }

    /**
     * @param {number} start datetime in millis of the start of the first appointment
     * @param {number} end datetime in millis of the end of the first appoint
     * @param {number} frequency days between each appoinment recurrence (1 = every day, 7 = once per week)
     * @param {number} count how many appointments to schedule
     */
    static getRecurrencePreview = (start, end, frequency, count, onResult) => {
        let path = Endpoint.base + "/appointments/preview-recurrence";

        let params = {
            start: start,
            end: end,
            frequency: frequency,
            count: count
        }

        void NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }
    
    static getCronJob = (config, onResult) => {
        let path = Endpoint.base + "/appointments/cron-job/get";

        void NetworkManager.get(path, config, (result) => {
            onResult(result);
        });
    }

    static createScheduleCronJob = (facilityId, schedule, onResult) => {
        if(schedule.scheduleType != "auto"){
            onResult(null)
            return
        }
        let path = Endpoint.base + "/appointments/cron-job";

        let params = {
            facilityId: facilityId,
            schedule: schedule, 
            tzo: new Date().getTimezoneOffset()
        }

        void NetworkManager.post(path, params, (result) => {
            onResult(result);
        });
    }

    static cancelScheduleCronJob = (facilityId, schedule, onResult) => {
        if(schedule.scheduleType != "auto"){
            onResult(null)
            return
        }
        let path = Endpoint.base + "/appointments/cron-job/cancel";

        let params = {
            facilityId: facilityId,
            schedule: schedule
        }

        void NetworkManager.post(path, params, (result) => {
            onResult(result);
        });
    }

    static createAppointmentWithObject = (params, onResult) => {
        params = Appointment.convertFromObject(params) //sometimes we may attach data here that should not be saved
        let path = Endpoint.base + "/appointments";

        void NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static cancelAppointment = (id, onResult) => {
        let path = Endpoint.base + "/appointments/cancel";
        let params = {
            id: id
        }
        void NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static getNewTimeRestrictionsModel(facilityId, schedule, timeZone, title){
        return {
            facilityId: facilityId,
            // not sending an ID. That will be generated for us
            schedule: schedule,
            timeZone: timeZone, //timeZone, case sensitive
            title: title
        }
    }

    static postTimeRestrictions(params, onResult){
        let path = Endpoint.base+"/appointments/add-time-restriction"
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static updateTimeRestrictions(facilityId, schedule, remove, onResult){
        let params = {
            facilityId: facilityId,
            schedule: schedule,
            remove: remove
        }
        let path = Endpoint.base+"/appointments/update-time-restrictions"
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static getTimeRestrictions(facilityId, start, end, onResult){
        let path = Endpoint.base+"/appointments/time-restrictions"
        var params = {
            facilityId: facilityId,
            startDate: start,
            endDate: end,
        }
        void NetworkManager.get(path, params, function(response) {
            onResult(response)
        })
    }

    static runScheduleForward(facilityId, start, previousStart, onResult){
        let path = Endpoint.base+"/appointments/schedule-forward"
        let params = {
            facilityId: facilityId,
            start: start,
            previousStart: previousStart
        }
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static runScheduleForwardConfirm(confirmationData, onResult){
        let path = Endpoint.base+"/appointments/schedule-forward"
        void NetworkManager.post(path, confirmationData, function(response) {
            onResult(response)
        })
    }

    static runSchedule2fers(facilityId, start, onResult){
        let path = Endpoint.base+"/appointments/schedule-2fers"
        let params = {
            facilityId: facilityId,
            start: start
        }
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static getAvailableSlotsForSwap(facilityId, appointmentId, requirePrimary, onResult){
        let path = Endpoint.base+"/appointments/get-swaps"
        let params = {
            facilityId: facilityId,
            appointmentId: appointmentId,
            requirePrimary: requirePrimary
        }
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static unassignAppointmentProvider(facilityId, appointmentId, providers, onResult){
        let path = Endpoint.base + "/appointments/unassign-provider"
        let params = {
            facilityId: facilityId,
            id: appointmentId,
            providers: providers
        }
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static swapAppointment(facilityId, from, to, onResult){
        let path = Endpoint.base+"/appointments/swap"
        let params = {
            facilityId: facilityId,
            from: from,
            to: to
        }
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static swapColumns(facilityId, dateRange, providersToFill, providersToPull, additionalInfo, filteredServices, onResult){
        let path = Endpoint.base+"/appointments/swap-columns"
        let params = {
            facilityId: facilityId,
            start: dateRange.start,
            end: dateRange.end,
            fillProviders: providersToFill,
            pullProviders: providersToPull,
            additionalInfo: additionalInfo,
            filteredServices: filteredServices
        }
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static runZeroMinuteSchedule(facilityId, scheduleId, date, onResult){
        let path = Endpoint.base + "/appointments/zero-minute-schedule"
        let params = {
            facilityId: facilityId,
            scheduleId: scheduleId,
            date: date
        }
        void NetworkManager.post(path, params, function(response) {
            onResult(response)
        })
    }

    static async getNewFacilityAppointments(facilityId, lastUpdatedTime, onResult, onError){
        return new Promise((resolve, reject)=>{
            let path = Endpoint.base + "/appointments/new-facility-appointments"
            let params = {
                facilityId: facilityId,
                lastUpdated: lastUpdatedTime
            }
            NetworkManager.get(path, params, (result)=>{
                resolve(result)
                if(onResult) onResult(result)
            }, (error)=>{
                reject(error)
                if(onError) onError(error)
                else onResult(error)
            })
        })
    }
}