import React from 'react';

import Colors from '../../util/Colors'
import Agenda from '../../util/Agenda'
import CalendarUtil from '../../util/CalendarUtil'
import ProviderIcon from '../../img/Icons/ProviderNew.png'
import LoadingSpinner from '../../util/LoadingSpinner'
import DateAndTime from '../../util/DateAndTime';
import FacilityManager from '../../managers/FacilityManager';
import Tracker from '../../managers/Tracker';
import AlertsPage from '../AlertsPage';
import Helpers from '../../util/Helpers';
import DateSwitcher from '../../ui/DateSwitcher';
import PrintedAgendaPage from './PrintedAgendaPage';
import agendaPageStyles from "./AgendaPage.module.css"
import SelectProviderPage from '../ScheduleAppointment/SelectProviderPage';
import {toast} from "react-toastify";
import PromisifyCallback from "../../util/PromisifyCallback";
import LegacyAppointmentManager from "../../managers/LegacyAppointmentManager";
import {AppointmentRepository} from "../../repositories/AppointmentRepository";

const getNewFacilityAppointmentsCall = new PromisifyCallback(LegacyAppointmentManager.getNewFacilityAppointments)

export default class AgendaPage extends React.Component {

  constructor(props){
    super(props)
    Tracker.logScreenView('agenda')

    this.state={
      loading: true,
      anchorDate: this.props.machine.state.event.anchorDate ?? new Date(),
      patient: null,
      viewingId: this.props.machine.state.event.viewingId,
      deletedCache: [],
    }
    this.appointmentRepo = new AppointmentRepository({ facilityId: this.props.facility.id })

    document.body.style = 'background:' + Colors.Secondary.Light + ';';
  }

  componentWillMount(){
    window.addEventListener('beforeprint', this.onBeforePrint)
    window.addEventListener('afterprint', this.onAfterPrint)
    this.queueTimer()
  }

  componentWillUnmount(){
    this.props.clearUrlCode()
    window.removeEventListener('beforeprint', this.onBeforePrint)
    window.removeEventListener('afterprint', this.onAfterPrint)
    clearTimeout(this.timeout)
  }

  onBeforePrint = () => {
    window.scrollTo(0, 0)
    this.setState({
      printMode: true
    })
    this.props.machine.send("PRINT")
  }

  onAfterPrint = () => {
    this.setState({
      printMode: false
    }, ()=>{
      this.props.machine.send("FINISH")
    })
  }

  handleTimer = ()=> {
    let lastUpdatedTime = this.state.lastUpdatedTime
    let timeWhenChecked = Date.now()
    let promptForUpdate = false
    getNewFacilityAppointmentsCall.invokeResolveReject(this.props.facility.id, lastUpdatedTime).then((result)=>{
      if(result && result.length > 0){
        for(let appointment of result){
          if(!this.isAppointmentRelated(appointment)){
            continue
          }
          promptForUpdate = true //otherwise we need to prompt for an update
        }
      }

      if(promptForUpdate){
        this.setState({lastUpdatedTime: lastUpdatedTime}) //it is safe to change the last updated time now. Prevents buildup if other appointments changed
        toast(
            (
                <div>
                  New appointment data is available. Click to reload
                </div>
            ),
            {
              onClick: () => {
                this.loadAnchorDate(this.state.anchorDate)
              },
              autoClose: false,
              draggable: false,
              closeButton: true
            }
        )
      }
      else{
        this.queueTimer()
        this.setState({lastUpdatedTime: timeWhenChecked}) //it is safe to change the last updated time now. Prevents buildup if other appointments changed
      }
    }).catch(()=>{
      toast(
          (
              <div>
                <div>
                  Unable to check for updates. Click to try again.
                </div>
                <div>
                  If the problem persists, please check your internet
                </div>
              </div>
          ),
          {
            onClick: () => {
              this.handleTimer()
            },
            onClose: ()=> {
              this.queueTimer()
            },
            autoClose: false,
            draggable: false,
            closeButton: true
          }
      )
    })
  }

  /**
   * Checks an appointment for any relation to current page
   *
   * Useful for checking if an appointment update should prompt for an update or not
   *
   * @param appointment The appointment to check. This object is the appointment history object
   * @returns true if related, false otherwise
   */
  isAppointmentRelated = (appointment) => {
    if(!CalendarUtil.areDatesTheSameDay(new Date(appointment.start), new Date(this.state.anchorDate))){
      return false
    }
    if(appointment.serviceId === this.props.facility.activityServiceId) return true

    if(this.state.deletedCache.includes(appointment.id)){
      return false
    }

    if(this.state.urlCode){
      let occupancy = this.props.facility.rooms.find((a)=>{return a.urlCode === this.state.urlCode})
      return appointment.occupancyId === occupancy.occupancyId
    }
    if(appointment.providers.length > 0 && appointment.providers.includes(this.state.viewingId)){
      return true
    }
    return false
  }

  queueTimer = ()=> {
    if(this.props.user.role === "guest") return //Don't enable update checking if guest. They don't have access to check for new appointments
    if(this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(this.handleTimer, 60*1000) // 1 minute
  }

  async moveAnchorDate(days){
    var newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() + days))
    await this.loadAnchorDate(newAnchor)
  }

  loadAnchorDate = async(newAnchor) => {
  
    this.setState({
      facility: this.props.facility,
      user: this.props.user,
      urlCode: this.props.urlCode,
      anchorDate: newAnchor,
      loading: true
    }, ()=> {
      this.getAgenda()
    })
  }
  
  prevDay = () => {
    this.moveAnchorDate(-1)
  }
  nextDay = () => {
    this.moveAnchorDate(1)
  }

  async getAgenda(){
    this.setState({lastUpdatedTime: Date.now()})
    //activities
    const dateRange = CalendarUtil.getDayTimeRange(this.state.anchorDate)

    /**
     *
     * @type {AppointmentQuery}
     */
    let activityQuery = {
      service: this.props.facility.activityServiceId,
      start: dateRange.start,
      end: dateRange.end
    }

    let activityAppointments = await this.appointmentRepo.getAppointments(activityQuery)

    /**
     *
     * @type {AppointmentQuery}
     */
    let appointmentQuery = {
      owner: this.state.viewingId,
      urlCode: this.props.urlCode,
      start: dateRange.start,
      end: dateRange.end,
      stashed: false,
    }

    //regular appointments
    const appointments = await this.appointmentRepo.getAppointments(appointmentQuery);
    if(!appointments.appointments && !activityAppointments.appointments){
      toast(`Unable to fetch appointments: ${appointments.error}`)
      return
    }

    let data
    try {
      //combine them
      data = appointments.appointments && activityAppointments.appointments
              ? appointments.appointments.concat(activityAppointments.appointments)
              : appointments.appointments;
    }
    catch (e){
      toast("Unable to load agenda. An error occurred. Navigating home in 5 seconds")
      setTimeout(_=> {window.location = "/"}, 5000)
      console.error(e)
    }


    if(data === {} || data == null || Object.keys(data).length === 0){
      data = []
    }

    if(data.statusCode != null) {
      window.location = "/"
      return
    }

    Helpers.sortObjectByTimes(data)
    data = data.filter((agendaItem)=>{ //filter out 2fers if agenda is patient
      return !(this.state.patient && agendaItem.serviceId && agendaItem.serviceId === this.props.facility.service2ferId);
    })

    this.queueTimer()
    this.setState({
      agendaItems: data,
      facility: this.props.facility,
      user: this.props.user,
      urlCode: this.props.urlCode,
      loading: false
    })
    this.props.machine.send("RESOLVE")
  }

  componentDidMount(){
    
    if(this.props.urlCode) {
      FacilityManager.getRoom(null, null, this.props.urlCode, (data) => {
        this.setState({
          patient:{
            name: data.name,
            identifier: data.identifier
          }
        })

        if (this.props.user.creatorId) {
          this.onLoadNewAgenda(this.props.user.creatorId)
        }
        else {
          this.onLoadNewAgenda(this.props.urlCode)
        }
      })
    } else {
      this.onLoadNewAgenda(this.state.viewingId ?? this.props.user.creatorId)
    }
  }

  onLoadNewAgenda = (id) => {
    this.props.machine.send("NEXT")
    this.setState({
      agendaItems:{},
      loading: true,
      printMode: false,
      viewingId: id
    }, () => this.getAgenda() )
  }

  onDeleteAppointment = (id) => {
    const agendaItems = this.state.agendaItems;
    agendaItems.forEach(e => {
      if(e.id === id) agendaItems.splice(agendaItems.indexOf(e), 1)
    })
    let deleteCache = this.state.deletedCache
    deleteCache.push(id)
    
    this.setState({
      deletedCache: deleteCache,
      agendaItems: agendaItems
    }, () => this.forceUpdate())

    this.appointmentRepo.cancelAppointment(id).then(message=> {
      this.setState({
        loading: false
      })
      this.forceUpdate();
    })
  }

  onRefresh = () => {
    this.forceUpdate()
  }
  onEditAppointment = (appointment) => {
    if(!this.props.patient && !this.props.urlCode){ //if not patient agenda, we want to bring the page to the last state found
      appointment.previousState = {
        name: 'SELECT_AGENDA',
        data: {
            anchorDate: this.state.anchorDate,
            viewingId: this.state.viewingId
        }
      }
    }
    this.setState({
      loading: true
    }, () => window.scrollTo(0, 0))
    this.props.onEditAppointment(appointment)
  }

  onClickSelectProvider= () => {
    this.props.machine.send("SELECT_PROVIDER")
  }

  showLoadingSpinner = () => {
    this.setState({
      loading: true
    })
  }

  render(){
    const state = this.props.machine.state.value["Agenda"]
    
    if(state == null) return null
    if(this.state.loading) return <LoadingSpinner/>

    switch(state){

      case "Load":
        return <LoadingSpinner/>

      case "PrintAgenda":
        let type = this.state.patient ? PrintedAgendaPage.Type.PATIENT : PrintedAgendaPage.Type.PROVIDER
        return <PrintedAgendaPage 
          type={type}
          viewingId={this.state.viewingId}
          anchor={this.state.anchorDate}
          roomData={this.state.patient} 
          urlCode={this.state.patient?.urlCode ?? null} 
          facility={this.props.facility} 
          agendaItems={this.state.agendaItems} 
          services={this.props.services}/>
      case "ShowAgenda":
        var patientLabel = this.renderPatientLabel()
        var agenda = (
          <div align="center" zIndex="1"> 
            <Agenda 
              viewingId={this.state.viewingId} 
              anchor={this.state.anchorDate} 
              patient={this.state.patient} 
              onDeleteAppointment={this.onDeleteAppointment} 
              showLoadingSpinner={this.showLoadingSpinner} 
              showDropDown={this.props.urlCode == null} 
              printMode={this.state.printMode} 
              roomData={this.state.roomData} 
              onEditAppointment={this.onEditAppointment} 
              machine={this.props.machine} 
              user={this.props.user} 
              urlCode={this.props.urlCode} 
              facility={this.props.facility} 
              agendaItems={this.state.agendaItems} 
              services={this.props.services}/>
          </div>
        )
        var userSelection = this.renderUserSelection()

        return (
          <>
          
            <DateSwitcher
                onChange={this.loadAnchorDate}
                label={<DateAndTime anchor={this.state.anchorDate}/>}
                anchorDate={this.state.anchorDate}
                onNext={this.nextDay}
                onPrevious={this.prevDay}
            />

          {patientLabel}
          {userSelection}
          {agenda}
          {this.props.urlCode != null ? <AlertsPage facilityId={this.props.facility.id} isEdit={false}/> : null}
          </>
        );
      case "SelectProvider": 
          return <SelectProviderPage 
            singleSelection={true}
            isEdit={true}
            machine={this.props.machine} 
            facility={this.props.facility} 
            providers={Object.values(this.props.facility.providers)} 
            onSelectProvider={(data)=>{
              this.onLoadNewAgenda(data[0].id)
            }} 
            selection={[this.state.viewingId]} 
            user={this.props.user}
            title={`Select Provider`}/>
      default:
        return null
    }
   }

  renderUserSelection(){
    if(this.props.urlCode) return
    var providerName = Helpers.getProviderNameFromId(this.state.viewingId, this.props.facility)
    return <div align='center' className={agendaPageStyles.agendaTitleContainer}>
      <div className={agendaPageStyles.agendaTitle}>
        {providerName}
      </div>
      <img src={ProviderIcon} className={agendaPageStyles.selectProviderButton} onClick={this.onClickSelectProvider}/>
    </div>
  }

  renderPatientLabel(){
    var patient = this.state.patient
    if(!patient || !patient.identifier || !patient.name) return null
    return <div align="center" style={{marginTop:8, fontSize:24}}>Room {patient.name} - {patient.identifier}</div>
  }
}