import React  from 'react';
import ConfigManager from '../managers/ConfigManager';
import { Button } from 'react-bootstrap';
import Helpers from '../util/Helpers';
import FacilityCalendar from './FacilityCalendar';
import SDMultiSelect from '../util/SDMultiSelect';
import ProviderManager from '../managers/ProviderManager';
import FacilityUtil from '../util/FacilityUtil';
import SDCheckBox from '../ui/SDCheckBox';
import { toast } from 'react-toastify';
import FacilityManager from '../managers/FacilityManager';
import PromisifyCallback from '../util/PromisifyCallback';
import LoadingSpinner from '../util/LoadingSpinner';
import ColumnSwapperUI from '../ui/columnSwapperUI';
import {ScheduleColumnImpl} from "../models/ScheduleColumn";
import {ScheduleAppointmentHolderImpl} from "../models/ScheduleAppointmentHolder";

/**
 * Rendered via FacilitySchedulePage
 */
export default class ProviderFacilityCalendar extends FacilityCalendar{

  constructor(props){
    const sortedProviders = Helpers.sortProviders(Object.values(props.facility.providers));
    super(props, sortedProviders)
    
    this.state.hideOccupancyNames = false //using occupancy name display
    this.state.hideProviderNames = true 
    this.state.fullWidth2fer = true
    this.state.staticMarginTop = 150
    this.state.stateName = "FacilityProviderSchedule"
    this.state.calendarType = "ProviderFacility"
  }

  componentDidMount(){
    super.componentDidMount()
    Helpers.fixMultiSelects()
  }

  componentWillUnmount(){
    super.componentWillUnmount()
    if(this.toastId){
      toast.dismiss(this.toastId)
    }
  }

  /**
   *
   * @param schedule {ScheduleColumn}
   * @returns {string}
   */
  getFilterKey(schedule){
    return schedule.id //TODO check to make sure this works
  }

  /**
   * @param schedule {ScheduleColumn}
   * @returns {string}
   */
  getHeaderName(schedule){
    let name = schedule.label
    name = name.replace('\!', '')
    return `${name}` //already stored for us
  }

  getMachineState = ()=> {
    return this.props.machine.state.value[this.state.stateName]
  }

  drawProviderMultiSelect(){
    return (
      <SDMultiSelect 
            style={{width: '300px'}}
            defaultValue="Select Providers..."
            allItems={this.state.allItems}
            labelRenderer={(provider)=>`${provider.firstName} ${provider.lastName}`}
            valueRenderer={(provider)=>provider.id}
            filteredItems={this.state.filteredItems}
            onSelectionsChanged={this.onProviderSelectionsChanged}/>
    )
  }

  onProviderSelectionsChanged = (filteredItems) => {
    this.setState({
      filteredItems: filteredItems
    })

    //Update provider preferences (false)
    ConfigManager.updateProviderScheduleOptions(this.props.facility.id, this.props.user.creatorId, filteredItems, false, (data) => {
      
    })

    super.onResize()
    //TODO fix filters, or validate that they work
  }

  drawServiceMultiSelect(){
    let services = Helpers.sortServices(
      this.props.facility.services, 
      /*Copy*/true, 
      /*showDisabled*/false, 
      /**sortDisabledAtBottom */false, 
      /*filterSchedulable*/ false
    ).filter((service)=>{
      return service.schedulable || service.id === this.props.facility.activityServiceId
    })

    return (
      <SDMultiSelect 
            style={{width: '300px'}}
            defaultValue="Select Services..."
            allItems={services}
            labelRenderer={(service)=>service.name}
            valueRenderer={(service)=>service.id}
            filteredItems={this.state.filteredServices}
            onSelectionsChanged={this.onServiceSelectionsChanged}/>
    )
  }

  onServiceSelectionsChanged = (filteredItems) => {
    this.setState({
      filteredServices: filteredItems
    })

    this.props.onServiceSelectionsChanged(filteredItems)

    //Update service preferences (true)
    ConfigManager.updateProviderScheduleOptions(this.props.facility.id, this.props.user.creatorId, filteredItems, true, (data) => {
      
    })
    //TODO fix filters, or validate that they work
    super.onResize()
  }

  /**
   * Span dropdowns horizontally if possible, but if not, center them
   */
  drawMultiSelect(){
    const spacing = {margin: '8px'};
    const swapColumnsButton = this.renderColumnSwapButton();
    return <div style={{zIndex: 12, position: 'relative', width: '100%', marginTop: '8px', marginBottom: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
      <div style={spacing}>
        {this.drawProviderMultiSelect()}
      </div>
      <div style={spacing}>
        {this.drawServiceMultiSelect()}
      </div>
      {swapColumnsButton}
    </div>
  }

  /**
   * @param scheduleColumn {ScheduleColumn}
   * @param headerStyle
   * @param bgColor
   * @param borderColor
   * @param columnIndex
   * @returns {JSX.Element}
   */
  drawHeader(scheduleColumn, headerStyle, bgColor, borderColor, columnIndex){
    const columnId = this.props.columnIds[columnIndex] ?? scheduleColumn.id;
    const selectedColumns = this.state.selectedColumnsToSwap ?? [];
    const currentColumnSelected = selectedColumns.length > 0 && selectedColumns.includes(columnId);
    const unavailable = this.getHeaderProviderUnavailable(columnIndex, scheduleColumn);
    const label = this.state.columnSwapping === true && this.canShowSwappableColumn(columnId) ? (
        <SDCheckBox label={this.getHeaderName(scheduleColumn)} onCheckChanged={(value) => {
          if (value) {
            if (!currentColumnSelected) {
              selectedColumns.push(columnId)
            }
          } else {
            if (currentColumnSelected) {
              selectedColumns.splice(selectedColumns.indexOf(columnId), 1)
            }
          }
          this.setState({selectedColumnsToSwap: selectedColumns})
        }} disabled={!this.isSwapSelectable(columnId)} checked={currentColumnSelected}/>
    ) : this.getHeaderName(scheduleColumn);
    return (
      <div style={headerStyle}>
        <div style={{
          backgroundColor: bgColor,
        }}>{label}</div>
        <div style={{position: 'relative'}}>
          <div style={{
            margin: '4px',
            borderBottom: borderColor ? `8px solid ${borderColor}` : (unavailable ? '8px solid transparent' : null),
          }}/>
          {unavailable ? (
            <div style={{
              width: 'fit-content',
              position: 'relative',
              fontSize: '10px',
              fontStyle: 'italic',
              top: '-12px',
              lineHeight: '8px',
              margin: 'auto',
              paddingLeft: '2px',
              paddingRight: '2px',
              backgroundColor: 'white'
            }}>{" Provider Unavailable "}</div>
          ) : null}
        </div>
      </div>
    )
  }
  
  canShowSwappableColumn = (columnId)=>{
    if(columnId === "activities") return false
    if(this.state.columnFill){
      return this.props.facility.providers[columnId]
    }
    return true
  }

  isSwapSelectable = (columnId) => {
    if(this.state.columnFill){
      //return selectable if not selected by the fill providers, and is an actual provider (don't allow unassigned or stashed)
      return !this.state.columnsToPull.includes(columnId)
    }
    return true
  }

  renderColumnSwapButton(){
    if(
      this.props.columns === 0 
      || !FacilityUtil.canEditAppointments(this.props.user, this.props.facility)
    ) return null
    var buttonStyle = {"background-color": "rgb(0, 196, 216)", "border-color": "rgb(0, 196, 216)", height: '42px', margin: '8px'}
    if(this.state.columnSwapping === 'loading'){
      return <LoadingSpinner size={42} marginTop={2}/>
    }
    else{
      return (
        <Button 
          disabled={this.state.columnSwapping || this.state.swapMode} 
          size="sm" style={buttonStyle} 
          onClick={this.startColumnSwap}>
            Reassign Columns
          </Button>
      )
    }
  }

  //step 1 - Select columns to move appointments from
  startColumnSwap = async() => {
    
    this.setState({
      columnSwapping: 'loading',
      columnFill: false,
      columnPull: true,
      selectedColumnsToSwap: [],
    })
    let timeSlots = this.getTimeSlots();
    /**
     * @type {ScheduleColumn[]}
     */
    let schedule = Object.assign([], this.state.schedule)
    /**
     * @type {ScheduleColumn[]}
     */
    let newSchedules = []
    let providersWithServiceInfo = await this.getProvidersWithServicesForSwap()

    Object.keys(providersWithServiceInfo).forEach((providerId)=>{
      //Let's lookup this provider to see if we should render them. Only check if they are disabled
      const facilityProvider = this.props.facility.providers[providerId];
      if(ProviderManager.isProviderDisabled(facilityProvider)) return

      let providerSchedule
      let providerFullName = Helpers.getProviderFullName(facilityProvider)
      if(this.props.columnIds.includes(providerId)){
        providerSchedule = schedule[this.props.columnIds.indexOf(providerId)]
      }
      else{
        providerSchedule = new ScheduleColumnImpl(providerId, providerFullName)
        newSchedules.push(providerSchedule)
      }
      
      //create an empty appointment for each service
      /**
       * @type {AppointmentModel}
       */
      let baseAppointment = {
        providers: [{
          id: providerId,
          name: providerFullName
        }],
        locations: [],
        occupants: [],
        linkedAppointments: [],
        start: new Date(timeSlots[0]-3*60*1000), //but make it display outside of the view. This will be used for sorting/getting provider info
        end: new Date(timeSlots[0]-2*60*1000)
      }
      
      providersWithServiceInfo[providerId].forEach((serviceId)=>{
        let serviceAppointment = window.structuredClone(baseAppointment)
        serviceAppointment.serviceId = serviceId
        serviceAppointment.title = "Open - " + Helpers.getService(serviceId, this.props.services).name
        /**
         * @type {ScheduleAppointmentHolder}
         */
        let holder = new ScheduleAppointmentHolderImpl(serviceAppointment, providerId, providerFullName)
        holder.providerIndex = 0
        providerSchedule.push(holder)
      })
    })
    newSchedules.sort((a, b)=>{
      let apptA = a.list()[0].get()
        let apptB = b.list()[0].get()
      let providerA = this.props.facility.providers[apptA.providers[0].id]
      let providerB = this.props.facility.providers[apptB.providers[0].id]
      if(providerA.title === providerB.title){
        return apptA.providers[0].name > apptB.providers[0].name ? 1 : -1
      }
      return providerA.title > providerB.title ? 1 : -1
    })
    schedule = schedule.concat(newSchedules)
    this.setState(
      {
        columnSwapping: true,
        newSchedule: schedule, 
        columns: schedule.length,
        allColumns: schedule.length
      },
      ()=>{
        this.setState({columnWidth: this.getColumnWidth()}) //getColumnWidth uses state, so wait a frame...
      }
    )

    this.toastId = toast(<ColumnSwapperUI step={ColumnSwapperUI.STEP_FROM} onCancel={()=>{
      toast.dismiss(this.toastId)
    }} onNext={()=>{
      if(this.state.selectedColumnsToSwap.length === 0) return
      toast.dismiss(this.toastId)
      this.setState({queueNextSwapStep: true, columnsToPull: this.state.selectedColumnsToSwap})
    }}/>, {
      onClose: () => {
        if(this.state.queueNextSwapStep){
          this.startColumnSwapFill()
        }
        else {
          this.dismissColumnSwap()
        }
      },
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      closeButton: false,
    })
  }

  //step 2 - Select columns to move appointments to
  startColumnSwapFill = ()=> {
    this.setState({
      queueNextSwapStep: false,
      columnSwapping: true,
      columnFill: true,
      columnPull: false,
      columnsToPull: this.state.selectedColumnsToSwap,
      selectedColumnsToSwap: [],
    })
    this.toastId = toast(<ColumnSwapperUI step={ColumnSwapperUI.STEP_TO} onCancel={()=>{
      toast.dismiss(this.toastId)
    }} onNext={()=>{
      if(this.state.selectedColumnsToSwap.length === 0) return
      toast.dismiss(this.toastId)
      this.setState({queueNextSwapStep: true, columnsToFill: this.state.selectedColumnsToSwap})
    }}/>, {
      onClose: () => {
        if(this.state.queueNextSwapStep){
          this.finalizeColumnSwap()
        }
        else {
          this.dismissColumnSwap()
        }
      },
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      closeButton: false,
    })
  }

  finalizeColumnSwap = () => {
    this.setState({
      loading: true
    }, ()=>{
      let additionalInfo = {} //store any appointment ids on columns not tied to a provider (unassigned/stashed columns)
      let columnIds = Object.assign([], this.state.columnsToFill, this.state.columnsToPull)
      for(let columnId of columnIds){
        if(!this.props.facility.providers[columnId]){ //columnId is NOT a provider
          let index = this.props.columnIds.indexOf(columnId)
          /**
           * @type {ScheduleColumn[]}
           */
          let scheduleColumns = this.state.schedule
          let appointments = scheduleColumns[index].list()
          additionalInfo[columnId] = appointments.reduce((list, apptHolder)=>{
            let appt = apptHolder.get()
            if(!appt.providers) return list
            if(this.state.filteredServices.includes(appt.serviceId)) return list
            list.push(appt.id)
            return list
          }, [])
        }
      }

      this.props.swapColumns(this.state.columnsToFill,this.state.columnsToPull, additionalInfo)
      this.dismissColumnSwap()
    })
    
  }

  dismissColumnSwap = () => {
    this.toastId = null
    this.setState({
      columnSwapping: false,
      columnFill: false,
      columnPull: false,
      selectedColumnsToSwap: null,
      columnsToFill: null,
      columnsToPull: null,
      newSchedule: null, 
      columns: this.state.schedule.length,
      allColumns: this.state.schedule.length
    },()=>{
      this.setState({columnWidth: this.getColumnWidth()}) //getColumnWidth uses state, so wait a frame...
    })
  }

  getProvidersWithServicesForSwap = async() => {
    let swappableServices = []
    this.props.facility.services.forEach((service)=>{
      if(!service.disabled && service.allowSwapping){
        swappableServices.push(service.id)
      }
    })
    let promisifiedGetProviders = new PromisifyCallback(FacilityManager.getServiceProviders)
    let result = await promisifiedGetProviders.invoke(this.props.facility.id, swappableServices)

    let providerMap = {}
    console.log(result)
    if(result.success){
      Object.keys(result.data).forEach((serviceId) => {
        let providerIds = result.data[serviceId]
        console.log(providerIds)
        providerIds.forEach((providerId)=>{
          if(!providerMap[providerId]) providerMap[providerId] = []
          if(!providerMap[providerId].includes(serviceId))
            providerMap[providerId].push(serviceId)
        })
      })
    }
    return providerMap
  }
}