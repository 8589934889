import React from 'react';

import Colors from '../util/Colors'
import {Navbar, Nav, Button} from 'react-bootstrap'
import { Auth } from 'aws-amplify';
import App from '../App'
import NavDate from './NavDate';
import CacheManager from "../managers/CacheManager";

export default class SDNavBar extends React.Component {

  static HeaderHeight = 60 //px
  getTitleStyle(){

    var fontSize = 20
    
    if (window.innerWidth < 1000) fontSize = 17
    if (window.innerWidth < 600) fontSize = 14

    return {
      headerTitleStyle: { verticalAlign: 'middle', textAlign: 'center' },
      textAlign:"center",
      flex:1,
      position:"absolute",
      left:0, 
      bottom:8,
      width:"100%",
      color:"#ffffff",
      fontWeight: "bold",
      fontSize: fontSize
    }
  }

  signOut = () => {
    sessionStorage.removeItem("cache")
    Auth.signOut()
    .then(data => {
      window.location.reload();
    })
  };

  onClickBrand = async() => {
    if(this.props.machine.state.value === "Home") {
      //clear cache if already home
      sessionStorage.removeItem("cache")
      window.location.reload()
    }
    else{
      App.transition("HOME")
      if(CacheManager.instance.getAgeMs() > 300000){ //if older than 5 minutes
        //clear cache
        sessionStorage.removeItem("cache")
        window.location.reload()
      }
    }
  }

  render() {
    if(this.props.title == null) return null
    const height = SDNavBar.HeaderHeight
    return (
      <>
      <div style={{position: "relative"}}>  
    <Navbar style={{backgroundColor: Colors.Primary.Main, height:{height}, width:"100%", top:0, left:this.props.scrollX}}>
      <Navbar.Brand onClick={this.onClickBrand} style={{cursor:"pointer", zIndex: 1}}>
      <img
        src={require('../img/logo/64h/logo.png')}
        width="50%"
        height="auto"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav">
      <Navbar.Text style={this.getTitleStyle()}>
       {this.props.title}
       </Navbar.Text>
        <Nav className="mr-auto">
        </Nav>
          <NavDate/>
          {this.drawLogOutButton()}
      </Navbar.Collapse>
    </Navbar>
    </div>
    </>
    );
  }

  drawLogOutButton(){
    return this.props.user.creatorId == null ? null : <Button style={{zIndex: 1, color:"#ffffff"}} variant="link" onClick={this.signOut}>Log Out</Button>
  }
}