export const FacilityScheduleMachine = {
    initial: 'load',
    states: {
      load: {
        on: {
          RESOLVE_SCHEDULE: 'ShowSchedule',
          RESOLVE_SERVICE_AGENDA: 'ShowServiceAgenda',
        }
      },
      ShowSchedule: {
        on: {
          SERVICE_AGENDA_SELECTED: 'load',
          SWAP: 'RunSwapCalculations'
        }
      },
      RunSwapCalculations: {
        on: {
          CANCEL: 'ShowSchedule',
          NEXT: 'ShowSwapView'
        }
      },
      ShowSwapView: {
        on: {
          CANCEL: 'ShowSchedule',
          FINISH: 'ShowSchedule'
        }
      },
      ShowServiceAgenda: {
        on: {
          FACILITY_SCHEDULE_SELECTED: 'load',
          PRINT: 'PrintServiceAgenda'
        }
      },
      PrintServiceAgenda: {
        on: {
          FINISH: 'ShowServiceAgenda'
        }
      },
      stop: {}
    }
  }