import React from 'react';
import LoadingSpinner from '../../util/LoadingSpinner';
import FacilityManager from '../../managers/FacilityManager'
import CacheManager from '../../managers/CacheManager';
import Helpers from '../../util/Helpers';
import ItemList from '../../ui/ItemList';
import EditItem from '../../ui/EditItem';
import '../../ui/editItem.css'
import StringManager, { StringFlags, StringResources } from '../../strings/StringManager';

export default class RoomsPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            items: []
        }
        this.refreshData(true)
    }

    async refreshData(force){ //called by super class
        this.setState({loading: true})
        if(force){
            await CacheManager.get().fetchSkilledDayData(this.props.facility.id, false, false, true)
        }
        var rooms = JSON.parse(JSON.stringify(this.props.facility.rooms))
        rooms.forEach(room => {
            room.name = Helpers.getRoomNumber(room) //strip the beginning off of it. Not used for displaying, and should not be saved like that
        });
        rooms = Helpers.sortRooms(rooms, false, true)
        this.setState({loading: false, items: rooms})
    }

    createItemAndEdit = ()=> {
        this.setState({
            editingItem: {
                name: '',
                occupied: false,
                facilityId: this.props.facility.id
            }
        })
        this.props.machine.send("NEW_ROOM")
    }

    editExisting = (item) => {
        this.setState({
            editingItem: item
        })
        this.props.machine.send("EDIT_ROOM")
    }

    cancelEdit = ()=> {
        this.setState({loading: false})
        this.props.machine.send("FINISH")
        this.refreshData()
    }

    submitEdit = (item)=> {
        this.setState({loading: true})
        FacilityManager.updateRoom(item, (result)=>{
            if(result.error)
                this.setState({loading: false, errors: [result.error]})
            else{
                this.refreshData()
                this.props.machine.send("FINISH")
            }
        })
    }

    renderButtons(){
        return this.state.items.map(v => {
            var extraText = v.disabled ? "(Disabled)" 
                : v.occupied ? "(Occupied)"
                : ""
            return {
                disabled: v.occupied,
                item: v,
                style: {borderColor:"#000000", width: 300, marginTop: 15},
                title: `${v.name} ${extraText}`
            }
        })
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>
        const state = this.props.machine.state.value["AdminPanel"]
        var items = this.renderButtons()
        var newItem = false
        switch(state){
            case "Rooms": 
                return <ItemList navigationBackTitle="Manage Facility" items={items} title="Room" onNewItem={this.createItemAndEdit} onExistingItem={this.editExisting} onCancel={this.backPressed}/>
            case "NewRoom":
            case "EditRoom": 
                return <EditItem title='room' navigationBackTitle="Manage Rooms" items={this.state.items} canDisable={!this.state.editingItem.occupied} item={this.state.editingItem} onSubmit={this.submitEdit} onCancel={this.cancelEdit} title="Room" new={newItem}>
                    <div className="itemDescriptionText">Turn this off to prevent {StringResources.patient(StringFlags.Plural)} from being assigned to this room.</div>
                    <div className="itemNoteText">Note: You can't disable an occupied room</div>
                </EditItem>
        }
    }

    backPressed =  () => {
        this.props.machine.send("FINISH")
    }
}