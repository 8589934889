import React from 'react'
import NavigationHeader from '../../ui/NavigationHeader'
import LoadingSpinner from '../../util/LoadingSpinner'
import SDEditTextBox from '../../ui/SDEditTextBox'
import { PhotoshopPicker } from 'react-color'

import './editServiceUI.css'
import ScheduleByConfigurationServiceOptions from './Components/ScheduleByConfigurationServiceOptions'
import ScheduleByAppointmentServiceOptions from './Components/ScheduleByAppointmentServiceOptions'
import FacilityManager from '../../managers/FacilityManager'
import Switch from '../../ui/Switch'

export default class EditServiceUI extends React.Component{
    constructor(props){
        super(props)
        var service = JSON.parse(JSON.stringify(this.props.editingService)) //just to make sure we don't modify the source
        if(service.scheduleStyle && service.scheduleStyle === "Day"){
            service.providers = []
            service.conflicts = false
        }
        if(service.fullDay){
            service.ignoreTimeRestrictions = false
            service.evaluation = false
        }

        if(!service.defaultDuration) service.defaultDuration = 30
        
        this.state={
            allowSave: true,
            saving: false,
            errorMessage: null,
            colorPickerValue: null,
            service: service 
        }
    }

    render(){        
        if(this.state.saving) return <LoadingSpinner/>
        return <>
            {this.getNavigationHeader()}
            <div className="editServiceContainer">
                <div className="errorMessage">{this.state.errorMessage}</div>
                {this.renderEditTitle()}
                {this.renderEditAlternateTitles()}
                {this.renderServiceColorPickerComponents()}
                {this.renderEnableButton()}
                {this.renderServiceOptions()}
            </div>
        </>
    }

    //#region Navigation...

    getNavigationHeader = () => {
        var saveOption = this.getSaveOptionTitle()
        return <NavigationHeader navigationBackTitle="Manage Services" 
                navigationOptionsTitles={[this.CANCEL, saveOption]} 
                onNewOption={this.onNewOption} 
                onNavigateBack={this.onBackPressed} disableOptions={!this.state.allowSave?[saveOption]:null}/>
    }

    getSaveOptionTitle = () => {
        var state = this.props.machine.state.value["ManageServices"]
        return state === "CreateService" ? "Create Service" : "Save"
    }

    CANCEL = "Cancel"
    
    onNewOption = (option)=> {
        switch(option){
            case this.getSaveOptionTitle():
                this.saveService()
                break;
            case this.CANCEL:
                this.onBackPressed()
                break;
        }
    }

    onBackPressed = ()=> {
        this.props.machine.send("CANCEL")
    }
    //#endregion

    //#region UI

    renderEditTitle = () => {
        return <div className="editTitleContainer">
            <SDEditTextBox label="Name" title={this.state.service.name} onChange={this.onServiceNameChanged}/>
        </div>
    }

    renderServiceColorPickerComponents = () => {
        var colorPicker = this.state.colorPickerValue ? (
            <div  className="colorPicker">
                <PhotoshopPicker align='center'
                    color={this.state.colorPickerValue}
                    onChange={(newColor) => {
                        this.setState({colorPickerValue: newColor.hex})
                    }}
                    onCancel={()=>{
                        this.setState({colorPickerValue: null})
                    }}
                    onAccept={this.onServiceColorChanged}/>
            </div>
        ) : null
        var color = this.state.service.color
        if(this.state.service.disabled){
            color += "bf"
        }
        var button = <div className='colorPickerButtonContainer'>
                        <button disabled={this.state.service.disabled} className='colorPickerButton' 
                            style={{backgroundColor: color}} 
                            onClick={this.onColorPickerButtonClicked}>Service Color</button>
                    </div>
        return [button, colorPicker]
    }

    renderServiceOptions = () => {
        var configurationTabClassName = "scheduleByConfigurationTab"
        var appointmentTabClassName = "scheduleByAppointmentTab"
        var scheduleBy = this.state.service.scheduleBy
        if(!scheduleBy)
            scheduleBy = 'appointment'
        if(scheduleBy === "configuration")
            configurationTabClassName += " selected"
        else
            appointmentTabClassName += " selected"

        var className = "serviceOptionsContainer"
        var clickListener = undefined
        
        if(this.state.service.disabled){ //check if disabled
            className += " disabled"
            clickListener = ()=> {} //block all clicks for this container
        }
        
        
        return (
            <div align="center" className={className} onClick={clickListener}>
                <div className="serviceOptionsTabSwitcher">
                    <div className={configurationTabClassName} 
                        onClick={()=>{this.updateServiceValue('scheduleBy', 'configuration')}}>SCHEDULE BY CONFIGURATION</div>
                    <div className={appointmentTabClassName} 
                        onClick={()=>{this.updateServiceValue('scheduleBy', 'appointment')}}>SCHEDULE BY APPOINTMENT</div>
                </div>
                <div className="serviceOptionsContent">
                    {this.renderServiceOption(scheduleBy)}
                </div>
            </div>
        )
    }

    renderServiceOption = (scheduleBy) => {
        switch(scheduleBy){
            case 'configuration':
                return <ScheduleByConfigurationServiceOptions onServiceValueChanged={this.updateServiceValue} 
                            service={this.state.service} 
                            facility={this.props.facility}/>
            case 'appointment':
                return <ScheduleByAppointmentServiceOptions onServiceValueChanged={this.updateServiceValue} 
                            onClickAssignProviders={this.props.onAssignPrimaries}
                            service={this.state.service} 
                            facility={this.props.facility}
                            providers={this.props.providers}/>
            default:
                return null
        }
    }

    renderEnableButton = ()=> {
        return [
            <div className="edit-service-switch-container">
                <div className="edit-service-switch-text">Enable Service</div>
                <Switch checked={!this.state.service.disabled} onChange={()=>{
                    var item = this.state.service
                    item.disabled = !item.disabled
                    this.setState({service: item, colorPickerValue: null})
                }}/>
            </div>,
            <div className='edit-service-enable-description-container'>
                <div className="edit-service-enable-description-text">Turn this off to prevent service from being schedulable for new appointments.</div>
            </div>
        ]
    }

    //#endregion

    //#region Service Item Changed functions

    saveService = ()=> {
        if(!FacilityManager.validateObject(this.state.service, ["name"]).success){
            this.setState({errorMessage: "name must not be empty!", allowSave: false})
            return
        }
        var hasError = false
        if(this.state.service.providers){
            this.state.service.providers.forEach(p => {
                if(p.title == "") {
                    hasError = true
                }
            })
        }
        if(hasError){
            this.setState({errorMessage: "Please add role names to all attendee roles"})
            return
        }
        this.setState({saving: true, errorMessage: null})
        this.props.onSaveService(this.state.service).then((result)=>{
            if(!result.message){
                this.props.machine.send("SAVE")
            }
            else{
                this.setState({saving: false, errorMessage: result.message})
            }
        })
    }

    onColorPickerButtonClicked = ()=> {
        this.setState({colorPickerValue: this.state.service.color})
    }

    onServiceNameChanged = (name) => {
        if(this.validateServiceNameUnique(this.state.service, name)){
            this.setState({errorMessage: null, allowSave: true})
            this.updateServiceValue('name', name)
        }
        else{
            this.updateServiceValue('name', name)
            this.setState({errorMessage: "A service with this name already exists", allowSave: false})
        }
    }

    onServiceColorChanged = ()=> {
        this.updateServiceValue('color', this.state.colorPickerValue)
        this.setState({colorPickerValue: null})
    }

    updateServiceValue = (key, value) => {
        console.log(`updateServiceValue ${key}`)
        var service = this.state.service
        service[key] = value
        if(key === 'fullDay' && value){
            service.ignoreTimeRestrictions = false
            service.evaluation = false
        }
        if(key === "scheduleStyle" && value === "Day"){
            service.providers = []
            service.conflicts = false
        }
        this.setState({service: service})
    }

    validateServiceNameUnique(editService, newName){
        var nameUnique = true
        if(!this.props.facility.services || !Array.isArray(this.props.facility.services)) return true
        this.props.facility.services.forEach((otherService)=>{
            if(otherService.id === editService.id) return//don't process this one. No need to
            if(otherService.name === newName){
                nameUnique = false
            }
        })
        return nameUnique
    }

    //#endregion
    renderEditAlternateTitles() {
        let alternateTitles = this.state.service.alternateTitles
        if(!alternateTitles) alternateTitles = []
        return <div className="editTitleAlternates">
            Alternate titles: {alternateTitles.join(", ")}
            <span className="editTitleAlternatesEditButton" onClick={()=>{
                this.props.machine.send("EDIT_ALTERNATE_TITLES")
            }}>EDIT</span>
        </div>
    }
}