export default class Validator{
    validationList = Array<CallableFunction>();

    addValidationCheck(callbackCheck: () => string){
        this.validationList.push(callbackCheck);
    }

    removeValidationCheck(callbackCheck: () => string){
        var list = this.validationList;
        var index = list.indexOf(callbackCheck);
        delete list[index];
    }

    runValidation(data : Array<object>) : Array<Array<string>>{
        var results = []
        for(var i = 0; i < data.length; i++){
            results.push(this.runSingleValidation(data[i], data, i))
        }
        return results
    }

    runSingleValidation(item : object, array : Array<object>, index : number) : Array<string>{
        let errors = []
        for(var validationCheck of this.validationList){
            let error = validationCheck(item, array, index)
            if(error) errors.push(error)
        }
        return errors
    }
}