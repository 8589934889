import React from 'react';
import { Row, Col, Form, Button, Card, Alert, Modal, Container } from 'react-bootstrap';
import Moment from 'moment';
import MultiSelect from "@khanacademy/react-multi-select";
import FacilityManager from '../managers/FacilityManager';
import Colors from '../util/Colors';
import LoadingSpinner from '../util/LoadingSpinner';
import DateAndTime from '../util/DateAndTime';
import CalendarUtil from '../util/CalendarUtil';
import CloseImage from '../img/Icons/cross.png'
import './alertsPage.css'

export default class AlertsPage extends React.Component {

    constructor(props){
        
        super(props)

        var date = new Date()

        this.state = {
            message: "",
            anchorDate: date,
            email: "placeholder",
            loading: false,
            data: []
        }

        this.createAlert = this.createAlert.bind(this);
    }

    componentDidMount(){
        this.getAlerts()
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>

        var uiAlertsArray = this.renderAlerts()
        return (uiAlertsArray.length ==  0 && !this.props.isEdit) ? null : ( //only show this UI if we actually have alerts, or if we are editing
            <div className="rootContainer">
                {this.props.isEdit ? null : (
                    <div className="announcementTitle">Announcements</div>
                )}
                {this.props.isEdit ? this.buildSubmissionUI() : null}
                {uiAlertsArray}
            </div>
        );
    }

    buildSubmissionUI(){
        return [
            <Row>
                <Col>
                    <Form.Group className="mb-2">
                        <Form.Control rows="4" as="textarea" placeholder="Type announcement" id="message" onChange={ () => {
                            this.setState({message: document.getElementById("message").value})
                        } }/>
                    </Form.Group>
                </Col>
            </Row>,
            <Row className="d-flex justify-content-between">
                <Col md="auto"/>
                <Col md="auto">
                    <Button variant="primary" disabled={this.state.message === ""} onClick={this.createAlert} style={{backgroundColor: Colors.Primary.Main, width: 125, border: "0px"}}>Submit</Button>
                </Col>
            </Row>
        ]
    }

    renderAlerts(){
        var alertUI = []
        var alerts = this.state.data;
        if(alerts){
            alerts.slice().reverse().forEach(alert => { // We want newest to oldest, so reverse order
                alertUI.push(this.buildAlert(alert))
            })
        }
        return alertUI
    }

    getAlertStyle(){
        return {
            color: Colors.Primary.Dark,
            backgroundColor: Colors.Secondary.Light,
            marginTop: '10px',
            marginBottom: '10px'
        }
    }

    buildAlert(alert) {
        return (
            <div className="messageContainer" key={alert.id} onClose={()=> {this.cancelAlert(alert)}} dismissible={this.props.isEdit}>
                {
                    !this.props.isEdit ? null : (
                        <img className="messageDeleteIcon" src={CloseImage} onClick={()=> {this.cancelAlert(alert)}}/>
                    )
                }
                <div className="messageAge">{this.getTimeDescription(alert.createdAt)}</div>
                <div className="messageBody">{alert.message}</div>
            </div>
        );
    }

    cancelAlert(alert){
        this.setState({ loading: true });
        FacilityManager.cancelAlert(this.props.facilityId, alert.id, (result) => {
            this.getAlerts()
        });
    }

    getTimeDescription(createdAt){
        return Moment(createdAt).fromNow()
    }

    createAlert() {
        this.setState({ loading: true });

        FacilityManager.createAlert(this.props.facilityId, this.state.message, (result) => {
            this.getAlerts()
        });
    }

    getAlerts() {
        this.setState({ loading: true });

        FacilityManager.getAlerts(this.props.facilityId, (result) => {
            this.setState({ loading: false, data: result });
        });
    }
}