import React from 'react'
import ConfigManager from '../managers/ConfigManager';
import FacilityManager from '../managers/FacilityManager';
import PromisifyCallback from '../util/PromisifyCallback';
import SDMultiSelect from '../util/SDMultiSelect';

/**
 * Groups dropdown. This will return a list of roomIds via onGroupUpdate that should be visible.
 * 
 * Either pass in just the facilityId and user, or pass in the groups info to prevent it from fetching it itself
 */
export default class GroupsDropdown extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            groups: {},
            groupRoomOccupancyLookup: {},
            filteredItems: []
        }
    }

    async componentDidMount(){
        if(this.props.prefetchedGroupsData){
            console.log(this.props.prefetchedGroupsData)
            this.setState({
                loading: false,
                disabled: false,
                ...this.props.prefetchedGroupsData.groupResult
            }, ()=>{
                this.onRoomSelectionsChanged(this.props.prefetchedGroupsData.filteredItems, false)
            })
        }
        else{
            this.setState({loading: true, disabled: false})
            let data = await GroupsDropdown.getGroupsData(this.props.facility.id, this.props.user.creatorId)
            this.setState({loading: false, ...data.groupResult}, ()=>{
                this.onRoomSelectionsChanged(data.filteredItems, false)
            })
        }
    }

    render(){
        return <SDMultiSelect 
            style={Object.assign({width: '300px', margin: '8px'})}
            defaultValue="Select Groups..."
            allItems={Object.values(this.state.groups)}
            isLoading={this.state.loading}
            labelRenderer={(room)=>room.id}
            valueRenderer={(room)=>room.groupId}
            filteredItems={this.state.filteredItems}
            onSelectionsChanged={this.onRoomSelectionsChanged}/>
    }

    getRooms = (filteredItems) => {
        //Determine the visible keys
        let keys = Object.keys(this.state.groups).filter((key)=>{
            return !filteredItems.includes(key) //filter out anything that exists in filteredItems
        })
        //Now get the rooms for each key. Some may be roomId, while others may be occupancyId, so will need to convert some of them
        let occupancyIdRoomsLookup = {}
        this.props.facility.rooms.forEach((room)=>{
            if(room.occupancyId)
                occupancyIdRoomsLookup[room.occupancyId] = room
        })
        let roomIds = []
        keys.forEach((key)=>{
            let items = this.state.groupRoomOccupancyLookup[key]
            let group = this.state.groups[key]
            if(!items || !group) return
            if(group.itemType === "occupancyId"){
                items.forEach((item)=>{
                    let room = occupancyIdRoomsLookup[item]
                    if(room && !roomIds.includes(room.id)){
                        roomIds.push(room.id)
                    }
                })
            }
            else{
                roomIds = roomIds.concat(items)
            }
        })
        return roomIds
    }

    onRoomSelectionsChanged = (filteredItems, userAction = true)=>{
        console.log(filteredItems)
        this.setState({
          filteredItems: filteredItems
        })
        ConfigManager.updateTargetedFacilityUserConfig(this.props.facility.id, "facilityScheduleConfigFilter", filteredItems, (data) => {})
    
        this.props.onGroupUpdate(this.getRooms(filteredItems), userAction)
    }

    static async getGroupsData(facilityId, creatorId){
        console.log(facilityId)
        console.log(creatorId)
        var getGroupsPromise = this.getGroups(facilityId);
        let getConfigPromisified = new PromisifyCallback(ConfigManager.getTargetedFacilityUserConfig)
        let getConfigPromise = getConfigPromisified.invoke(facilityId, creatorId, "facilityScheduleConfigFilter")
        let groupData = await getGroupsPromise
        let filteredItems = await getConfigPromise
        if(!filteredItems || filteredItems.statusCode) filteredItems = []
        return {groupResult: groupData, filteredItems: filteredItems}
    }

    static getGroups = (facilityId) => {
        return new Promise((resolve)=>{
            FacilityManager.getAllGroups(facilityId, (result) => {
                if(result.statusCode === 200){
                    let groups = result.data.groups
                    let items = result.data.groupData
                    resolve({groups: groups, groupRoomOccupancyLookup: items})
                }
                else{
                    resolve({groups: [], groupRoomOccupancyLookup: []})
                }
            })
        })
    }
}