import React from 'react';
import { Row, Col, Form, Button, Card, Alert, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Colors from '../../util/Colors';
import Tracker from '../../managers/Tracker';
import LoadingSpinner from '../../util/LoadingSpinner';
import FacilityManager from '../../managers/FacilityManager';
import ProviderManager from '../../managers/ProviderManager';
import Helpers from '../../util/Helpers';

export default class PrimaryProviderSelection extends React.Component{

  constructor(props) {
    super(props)
    var primaries = {}

    //Remove any providers that were marked as fallbacks previously, in a way that does not modify the rooms object directly
    if(this.props.selectedRoom.providers){
      Object.keys(this.props.selectedRoom.providers).forEach((key)=>{
        var primariesForService = Object.assign([], this.props.selectedRoom.providers[key])
        if(primariesForService.length > 1){
          primariesForService.splice(1) //remove all but the first one
        }
        primaries[key] = primariesForService
      }) 
    }

    this.state = {
      loading: true,
      service: null,
      roomProviders: primaries, 
      providerServiceLookup: {}
    }
    this.getProvidersForPrimaryServices()
    Tracker.logScreenView('manage_patients_select_provider')
  }

  getProvidersForPrimaryServices = async() => {
    //This will contain our allowed primaries. Not guaranteed to contain all providers for a service.
    var defaultPrimariesLookup = {} 
    //This will contain all providers for a service, in format provider = serviceProviderLookup[serviceId][providerId]
    var serviceProviderIdLookup = {} 
    
    let primaryServices = this.props.facility.services.reduce((array, service)=>{
      if(!service.disabled && service.usePrimary && service.primaries && service.primaries.length > 0){
        array.push(service)
      }
      return array
    }, [])

    let result = await FacilityManager.getServiceProviders(
      this.props.facility.id, 
      primaryServices.map((service)=>{
        return service.id
      })
    )
    if(result.success){
      serviceProviderIdLookup = result.data
    }
    defaultPrimariesLookup = primaryServices.reduce((map, service)=>{
      if(service.primaries) map[service.id] = service.primaries
      else map[service.id] = serviceProviderIdLookup[service.id]
      return map
    }, {})
    console.log({defaultPrimariesLookup: defaultPrimariesLookup, serviceProviderLookup: serviceProviderIdLookup, loading: false})
    this.setState({defaultPrimariesLookup: defaultPrimariesLookup, serviceProviderLookup: serviceProviderIdLookup, loading: false})
  }

  cancel = () => {
    this.props.machine.send("CANCEL")
  }

  submit = () => {
    this.setState({loading: true})
    var room = this.props.selectedRoom
    if(!room.providers)
      room.providers = {}
    room.providers = this.state.roomProviders
    FacilityManager.updateOccupancyProviders(this.props.facility.id, room.id, room.providers, (result)=>{
      if(!result.error){
        this.props.machine.send("CONFIRM")
      }
      this.setState({loading: false})
    })
  }

  onServiceSelected = (service) => {
    this.setState({service: service})
  }

  onProvidersChanged = (providersArray, serviceId) => {
    if(!this.state.roomProviders) 
      this.state.roomProviders = {}
    this.state.roomProviders[serviceId] = providersArray
  }

  render(){
    if(this.state.loading || !this.props.services){
      return (<LoadingSpinner/>)
    }
    var services = this.props.services
    services.sort((a, b)=>{
      return (a.name > b.name) ? 1 : -1
    })
    let allowedServiceIds = Object.keys(this.state.defaultPrimariesLookup)
    let servicesUI = services.filter((item)=>{
      return allowedServiceIds.includes(item.id)
    }).map((item, index)=>{
      return (
        <Card key={item.id} style={{backgroundColor: Colors.Primary, margin: 10, width: 300, marginLeft: "auto", marginRight: "auto"}}>
          <Card.Header>
            {item.name}
          </Card.Header>
          <Card.Body>
              <ChooseProvidersForService key={item.id} selectedRoom={this.props.selectedRoom} service={item} facility={this.props.facility} defaults={this.state.defaultPrimariesLookup[item.id]} providers={this.state.serviceProviderLookup[item.id]} onProvidersChanged={(providerArray)=>{this.onProvidersChanged(providerArray, item.id)}}/>
          </Card.Body>
        </Card>
      )
    })
    return (
      <>
        {servicesUI.length>0? servicesUI : "No services with primaries found. If some were expected, ensure required services have primaries assigned to them"}
        <div style={{position:"fixed", bottom:"80px", right:"80px"}}>
          <Button variant="link" onClick={this.cancel} style={{color:Colors.Primary.Main}}>Cancel</Button>
          <Button variant="primary" onClick={this.submit} style={{width:"80px", backgroundColor:Colors.Primary.Main, borderColor:Colors.Primary.Main}}>Save</Button>
        </div>
      </>
    ) 
  }
}

class ChooseProvidersForService extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      options: {},
      assignedProviders: [
        null
      ] //map of providers. Key is order of fallback. 0 is the primary provider
    }
    if(this.props.selectedRoom.providers && this.props.selectedRoom.providers[this.props.service.id]){
      this.state.assignedProviders = this.props.selectedRoom.providers[this.props.service.id]
    }
    this.setupProviders()
  }

  setupProviders = () => {
    var options = {} //map with the name as the key, and the option as the value. Only good way to currently sort
    this.props.defaults.forEach(option => { //all providers that we were provided by class that is using this
      let provider = this.props.facility.providers[option]
      options[this.getProviderName(provider)] = option
    })
    if(this.state.assignedProviders && this.state.assignedProviders[0] && !this.props.defaults.includes(this.state.assignedProviders[0])){
      let providerId = this.state.assignedProviders[0]
      //check to see if this provider is in the list. If not, add it. 
      let provider = this.getMissingProvider(providerId)
      if(provider){
        let name = this.getProviderName(provider)
        options[name] = provider.id
        this.state.providerNotInDefaults = name
      }
      else{
        let provider = this.props.facility.providers[providerId]
        if(provider){
          this.state.providerMissing = this.getProviderName(provider)
        }
        else{
          this.state.providerMissing = true
        }
      }
    }
    
    this.state.options = options
  }

  removeProvider = (index) => {
    var assignedProviders = this.state.assignedProviders
    assignedProviders.splice(index, 1)
    this.setState({assignedProviders: assignedProviders})
    this.props.onProvidersChanged(assignedProviders, this.props.service.id)
  }

  setProvider = (provider, index) => {
    var assignedProviders = this.state.assignedProviders
    assignedProviders[index] = provider
    this.setState({assignedProviders: assignedProviders})
    this.props.onProvidersChanged(assignedProviders, this.props.service.id)
  }

  getProviderName(provider){
      var name = ""
      if(provider)
        name = provider.firstName + " " + provider.lastName
      return name
  }

  /**
   * grab from serviceProviders lookup. 
   * If this returns null, then that's fine. Means that the provider was removed from the service
   * @param {*} pId providerId
   */
  getMissingProvider(pId){
      if(this.props.providers.includes(pId))
        return this.props.facility.providers[pId] 
  }

  renderOptions(index){
    var providerOptions = this.state.options
    var vp = this.state.assignedProviders[index]
    Object.keys(providerOptions).forEach((e)=>{
      if(providerOptions[e] == vp){
        vp = {
          name: e,
          id: providerOptions[e]
        }
      }
    })
    if(this.state.providerMissing && this.state.providerMissing !== true){
      vp = null
    }
    if(!vp){
      vp = {
        name: "",
        id: null
      }
    }
    var options = []
    options.push(<option key={vp.id} value={vp.id}>{vp.name}</option>)
    Object.keys(providerOptions).forEach((name) => {
      var id = providerOptions[name]
      if(id === vp.id) return null
      options.push(<option key={id} value={id}>{name}</option>)
    })

    options.sort((a, b) => {
      return (a.props.children > b.props.children) ? 1 : -1
    })

    return (
      <select value={vp.id} style={{width:140, fontSize:12}} onChange={(providerId)=> {this.onChange(providerId, index)}}>
        {options}                   
      </select>
    )
  }

  onChange = (e, index) => {
    this.setProvider(e.target.value, index)
  }

  render(){
    return this.renderProviderRow(0)
  }

  renderProviderRow = (index)=>{
    let warning
    if(this.state.providerMissing){
      warning = this.state.providerMissing === true 
        ? "Unable to find provider that was initially set" 
        : `${this.state.providerMissing} was set as the primary, but no longer is assigned to the service, or was disabled`
    }
    else if(this.state.providerNotInDefaults){
      warning = `${this.state.providerNotInDefaults} is not in the list of service primaries.`
    }
    return (
      <Row>
        <Col>
          <Row>{index == 0 ? "Primary Provider": `Fallback ${index}`}</Row>
          {warning ? <Row style={{color: 'red', fontSize: 12}}>{warning}</Row> : null}
          <Row>
            {this.renderOptions(index)}
            {index == 0 ? null : (<Button size="sm" onClick={()=>{this.removeProvider(index)}}>X</Button>) /*Only have option of removal if not primary*/}
          </Row>
        </Col>
      </Row>
    )
  }
}