import ProviderManager from "../managers/ProviderManager"

export default class FacilityUtil{
    
    /**
     * Retrieve an array of providers from the back-end that has what providers belong to a given service ID
     * @param {*} facilityId Facility ID
     * @param {*} serviceId service ID
     * @param {*} onlyProviderIds whether or not it is just the provider ID returned or not.
     * @returns A promise that when resolved, will return an array of providers in the format {serviceId: '', providers: []}
     */
    static getProviderServices = async(facilityId, serviceId, onlyProviderIds) => {
        return new Promise(function(resolve, reject) {
            ProviderManager.listProviders(facilityId, serviceId, true, false, (data) => {
                var dict = {
                    "serviceId": serviceId,
                    "providers": []
                }
                if(onlyProviderIds){
                    var ids = []
                    data.forEach(e => {
                        ids.push(e.id)
                    })
                    dict.providers = ids
                }
                else{
                    dict.providers = data
                }
                resolve(dict)
            })
        })
    }

    //TODO we should make a custom backend call for this. This takes the most time when loading the site
    /**
     * Build a service provider lookup, where the key is the serviceId, and the value is an array of providerId
     * @param {*} facilityId Facility Id
     * @param {*} services array of services
     * @param {*} onlyProviderIds whether or not the request should only return the provider id from the back-end
     * @returns A promise that when complete, will return the map, or a resolved error formatted as {error: "message"}
     */
    static buildServiceProviderMap = async(facilityId, services, onlyProviderIds) => {
        return new Promise(function(resolve, reject){
            var promises = []
            if(Array.isArray(services)){
              services.forEach(e => {
                promises.push(FacilityUtil.getProviderServices(facilityId, e.id, onlyProviderIds))
              })
            }
            Promise.all(promises).then(
              (data) => {
        
                var dict = {}
        
                data.forEach(e => {
                  dict[e.serviceId] = e.providers
                })
        
                resolve(dict)
              }
            ).catch((error)=>{
              console.error(error)
              resolve({error: error})
            })
        })
    }

    /**
     *
     * @param user
     * @param facility
     * @param appointment {AppointmentModel}
     * @returns {boolean}
     */
    static canEditAppointments = (user, facility, appointment)=>{
      if(!user && !facility) throw "Missing variable"
      //These items are allowed to edit appointments in the backend. If the backend changes, these will also need updated
      let validAccess = ['schedule-appointment', 'schedule-by-configuration','manage-patients', 'zero-minute-schedule']
      if(appointment && facility.activityServiceId && appointment.serviceId === facility.activityServiceId){ //special check for manage activities...
        validAccess = ['manage-activities'] //in this case, this is the only role that matters
      }
      //iterate through our access array in the user to see if we have valid access
      if(user.access){
        for(let accessIndex = 0; accessIndex < user.access.length; accessIndex++){
          if(validAccess.includes(user.access[accessIndex])) return true
        }
      }
      return false
    }
}