import {AppointmentQuery, CompareAppointmentQuery} from "./Queries";
import {Endpoint} from "../util/Constants";
import NetworkManager from "./NetworkManager";
import {AppointmentSaveError, AppointmentSaveResult, SwapItem} from "../types/AppointmentRepository";
import AppointmentModel, {NewAppointmentModel} from "../models/AppointmentModel";

export class AppointmentFetchManager {
    async getAppointments(query: AppointmentQuery) {
        let endpoint = Endpoint.base + "/appointments/v2-list";
        return NetworkManager.get(endpoint, {
            start: query.start,
            end: query.end,
            owner: query.owner,
            facility: query.facility,
            service: query.service,
            urlCode: query.urlCode,
            stashed: query.stashed,
            showBlockedTimes: query.showBlockedTimes
        })
            .then((data: {
                error: string,
                result: any
            }) => {
                if (data.error) throw new Error(data.error)
                else return data.result ?? []
            })
    }

    async getAvailableSlotsForSwap(
        facilityId: string,
        appointmentId: string,
        requirePrimary: boolean
    ): Promise<SwapItem[]>{
        let endpoint = Endpoint.base + "/appointments/v2-get-swaps";
        //params expected: let {facilityId, appointmentId, requirePrimary} = req.body
        return NetworkManager.post(endpoint, {
            facilityId: facilityId,
            appointmentId: appointmentId,
            requirePrimary: requirePrimary
        })
            .then((data: {
                error: any,
                result: any
            }) => {
                if (data.error) {
                    const err = new Error(data.error.message)
                    throw err
                }
                
                else return data.result
            })
    }

    async getAppointment(facilityId: string, id: string) {
        let path = Endpoint.base + "/appointments/v2";

        let params = {
            id: id,
            facility: facilityId
        }

        return NetworkManager.get(path, params)
            .then((data: {
                error: string,
                result: any
            }) => {
                if (data.error) throw new Error(data.error)
                else return data.result
            })
    }

    async saveAppointment(appointment: AppointmentModel | NewAppointmentModel): Promise<AppointmentSaveResult> {
        let path = Endpoint.base + "/appointments/v2";
        const data: { [key: string]: any } = Object.assign({} as AppointmentModel, appointment)

        return NetworkManager.post(path, data)
            .then((data: {
                error?: any,
                result?: any,
            }) => {
                if (data.error || (data.result?.errors && data.result?.errors.length > 0)){
                    //if error.message is valid, lets use the statusCode from that, and the message
                    if(data.error?.message){
                        // eslint-disable-next-line no-throw-literal
                        throw {
                            message: data.error.message,
                            statusCode: data.error.statusCode
                        } as AppointmentSaveError
                    }
                    else throw new Error(`${data.result?.errorId}: ` + (data.error ?? data.result?.errors?.join(', ')))
                }
                else return data.result
            })
    }

    async cancelAppointment(appointmentId: string) {
        let path = Endpoint.base + "/appointments/cancel";

        return NetworkManager.post(path, {id: appointmentId})
            .then((data: {
                error: string,
                result: any
            }) => {
                if (data.error) throw new Error(data.error)
                else return data.result
            })
    }

    async compareAppointments(query: CompareAppointmentQuery) {
        let path = Endpoint.base + "/appointments/v2-compare";

        let ownerIds = query.owners?.join() ?? null


        let params = {
            'users': ownerIds,
            'facility': query.facility,
            'blockedTimes': query.showBlockedTimes,
            'start': query.start,
            'end': query.end,
            'appointmentData': query.appointmentData
        }

        return NetworkManager.get(path, params)
            .then((data: {
                error: string,
                result: any
            }) => {
                if (data.error) throw new Error(data.error)
                else return data.result
            })
    }
}