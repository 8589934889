import React, {RefObject} from 'react'
import multiselectIcon from '../img/Icons/LibraryAddGoogleMaterial.svg'
import swapIcon from '../img/Icons/Swap.svg'
import deleteIcon from '../img/Icons/Delete.svg'
import twoFerIcon from '../img/Icons/2fer.svg'
import editIcon from '../img/Icons/Edit.svg'
import stashIcon from '../img/Icons/Stash.svg'
import unstashIcon from '../img/Icons/ic_appt_unstash.svg'
import unassignIcon from '../img/Icons/Unassign.svg'
import './OptionsMenuItem.css'
import ReactDOM from 'react-dom'
import AppointmentUtil, {getLinkedAppointmentInfoReturn} from "../util/AppointmentUtil";
import {Service} from "../types/Service";

const {default: Switch} = require('./Switch.jsx'); //ts hack to ignore compile issues
const {default: LoadingSpinner} = require('../util/LoadingSpinner'); //ts hack to ignore compile issues

interface FacilityCalendarProps extends React.ComponentProps<any> {
    onCancel: () => void
    columnWidth: number
}

export default class FacilityCalendarOptions extends React.Component<FacilityCalendarProps> { //TODO appointmentModel
    paddingTop = 8
    paddingBottom = 8

    ref: RefObject<any>
    state: {
        [key: string]: any,
        checked: {[key: string]: boolean},
        serviceLookup: {[key: string]: Service},
        linkedApptInfo: getLinkedAppointmentInfoReturn|null,
    }

    constructor(props: FacilityCalendarProps) {
        super(props)
        this.ref = React.createRef();
        const serviceLookup: {[key: string]: Service} = {}
        this.props.services?.forEach((service: Service) => {
            serviceLookup[service.id] = service
        })
        const linkedApptInfo = this.props.appointment ? AppointmentUtil.getLinkedAppointmentInfo(this.props.appointment, serviceLookup) : null
        this.state = {
            checked: {},
            serviceLookup: serviceLookup,
            linkedApptInfo: linkedApptInfo,
        }
    }

    optionType = {
        switch: 0,
        button: 1
    }

    componentDidMount() {
        document.addEventListener('click', this.clickListener, true)
        this.setState({}) //trigger an update right away so user does not see any jump upwards from the popup if it needs it
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickListener, true)
    }

    clickListener = (event: any) => {
        const domNode = ReactDOM.findDOMNode(this)
        if (!domNode || !domNode.contains(event.target)) {
            this.props.onCancel()
        }
    }

    render() {
        let refStyle = this.props.refStyle
        let style = this.getStyle(refStyle)
        let itemStyle = this.getItemStyle(style)
        const options: any[] = [];
        if (this.props.allowProviderSwap) {
            this.addButton(options, "Swap", swapIcon, this.props.onSwapAppointment)
        }
        if (this.props.allowProviderUnassign) {
            this.addButton(options, "Unassign", unassignIcon, this.props.onUnassignProvider)
        }
        if (this.props.allowStash) {
            this.addButton(options, "Stash", stashIcon, this.props.onStash)
        }
        if (this.props.allowUnstash) {
            this.addButton(options, "Unstash", unstashIcon, this.props.onUnstash)
        }
        this.addButton(options, "Delete", deleteIcon, this.props.onDelete)
        if (this.props.allowEdit) {
            this.addButton(options, "Edit", editIcon, this.props.onEdit)
        }

        if (this.props.allow2fer) {
            this.addSwitch(options, "2fer", twoFerIcon, this.props.appointment?.require2fer ?? this.props.require2fer, this.props.on2ferSelected)
        }
        if (this.props.selectOthers) {
            this.addButton(options, "Select Multiple Appointments", multiselectIcon, this.props.onSelectOthers)
        }

        var boundingClientRect = this.ref.current ? this.ref.current.getBoundingClientRect() : 0
        var height = boundingClientRect.bottom - boundingClientRect.top
        var renderingHorizontal = this.state.renderingHorizontal ?? true
        var optionsFinal = this.renderOptions(options, itemStyle)
        if (this.ref.current && this.state.openUp === undefined) {
            var extraMargin = this.props.staticMarginTop + this.props.marginTop
            var topPositionOnPage = style.top - this.props.scrollY + extraMargin
            var bottomPositionOnPage = (topPositionOnPage) + height
            //Not sure why we have to add 81, but makes the popup stay within the window if possible.
            //Works on different window sizes. Don't go upwards though if not enough room at top
            var openUp = bottomPositionOnPage + 81 + height > window.innerHeight && topPositionOnPage - height > extraMargin + 24
            var renderHorizontal = boundingClientRect.right < window.innerWidth && (boundingClientRect.right - boundingClientRect.left) * 2 < window.innerWidth
            this.setState({openUp: openUp, renderingHorizontal: renderHorizontal}, () => {
                //trigger an update immediately after the first since it may may need it to prevent jumping
                this.setState({})
            })
        }

        if (this.state.openUp) {
            style.top = style.top - height
        }
        if(!renderingHorizontal && this.props.columns !== 1){
            style.left -= this.props.columnWidth
        }

        var renderedOptions = optionsFinal && !this.props.hideControls ? (
            <div style={
                {
                    width: refStyle.width * .8, //80% width of blob
                    minWidth: '305px',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                }
            }>
                {this.props.loading ? <LoadingSpinner size={50}/> : optionsFinal}
            </div>
        ) : null
        var notes = (this.props.appointment?.notes || this.state.linkedApptInfo) && (
            <div>
                {this.props.appointment?.notes && (
                    <div style={{
                        alignItems: 'top',
                        width: refStyle.width * .8,
                        padding: '8px'
                    }}>
                        <div style={{fontSize: 14}}>Appointment Notes</div>
                        <div style={{fontSize: 12}}>
                            {this.props.appointment.notes}
                        </div>
                    </div>
                )}
                {this.props.appointment?.notes && this.state.linkedApptInfo &&
                    <div style={{height: 'auto', margin: '8px', borderTop: '1px solid #808080'}}/>
                }
                {this.state.linkedApptInfo && (
                    <div style={{
                        alignItems: 'top',
                        width: refStyle.width * .8,
                        padding: '8px'
                    }}>
                        {this.state.linkedApptInfo.parentService && (
                            <>
                                <div style={{fontSize: 14}}>Parent Service</div>
                                <div style={{fontSize: 12}}>{this.state.linkedApptInfo.parentService}</div>
                            </>
                        )}
                        {this.state.linkedApptInfo.appointments.length > 0 && (
                            <>
                                <div style={{fontSize: 14}}>Linked Appointments</div>
                                <div style={{fontSize: 12}}>
                                    {this.state.linkedApptInfo.services.map((service: string, index: number) => {
                                        return <div>{service}</div>
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        )

        if (!renderingHorizontal) {
            style.flexDirection = "column"
        }

        const element = <div style={style} ref={this.ref}>
            {renderedOptions}
            {renderedOptions && notes && renderingHorizontal ?
                <div style={{height: 'auto', margin: '8px', borderLeft: '1px solid #808080'}}/> : null}
            {notes}
        </div>
        return element
    }

    renderOptions(options: any[], itemStyle: any): any[] {
        return options.map((option) => {
            return this.renderRow(option, itemStyle)
        })
    }

    renderRow(option: any, itemStyle: object) {
        var buttonStyle = {marginTop: 'auto', marginBottom: 'auto', height: 24, width: 24, display: 'flex', justifyContent: 'center'}
        var isSwitch = option.type === this.optionType.switch
        var child = isSwitch ? (
            <Switch checked={this.state.checked[option.title] ?? option.checked} onChange={() => {
                this.onCheckChanged(option)
            }}/>
        ) : null
        var element = (
            <div className='optionMenuItem' style={itemStyle} onClick={isSwitch ? () => {
                this.onCheckChanged(option)
            } : option.callback}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <img src={option.icon} style={buttonStyle}/>
                    <div style={{marginLeft: 20}}>{option.title}</div>
                </div>
                {child}
            </div>
        )
        return element
    }


    addButton(optionsArray: any[], title: string, icon: string | undefined, callback: (...params: any[]) => any) {
        optionsArray.push({
            type: this.optionType.button,
            title: title,
            icon: icon,
            callback: callback
        })
    }

    addSwitch(optionsArray: any[], title: string, icon: string | undefined, checked: boolean, callback: (...params: any[]) => any) {
        optionsArray.push({
            type: this.optionType.switch,
            title: title,
            checked: checked,
            icon: icon,
            callback: callback
        })
    }

    onCheckChanged = (option: any) => {
        var checkedDict = this.state.checked
        checkedDict[option.title] = !option.checked
        this.setState({checked: checkedDict}, () => {
            option.callback(!option.checked)
        })
    }

    /**
     * Modify our refStyle to fit popout style
     * ```
     {
        borderRadius: string;
        position: string;
        left: number;
        top: number;
        backgroundColor: any;
        width: number;
        height: number;
        border: string;
        zIndex: number;
    }
     * ```
     * @param {*} refStyle
     */
    getStyle(refStyle: any) {
        var overlayStyle = {
            width: 'auto',
            height: 'auto',
            left: refStyle.left + refStyle.width * .1, //have to offset margin by removed ammount to center it
            top: refStyle.top + refStyle.height * .25,
            border: 'none',
            backgroundColor: 'white',
            zIndex: 10,
            color: '#808080',
            display: 'flex',
            flexDirection: 'row',
            margin: 'auto',
            paddingTop: this.paddingTop,
            paddingBottom: this.paddingBottom,
            filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))'
        }
        return Object.assign({}, refStyle, overlayStyle)
    }

    getItemStyle(style: any) {
        return {
            cursor: 'pointer',
            height: 48,
            width: style.width,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '7px',
            paddingRight: '24px',
            paddingLeft: '24px',
            paddingBottom: '7px'
        }
    }
}