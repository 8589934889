export default class CalendarUtil{

    static shortTimeCache = {}

    static getDayOfWeekString(dow, shorten){
        var dows = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dow]
        if(shorten) dows = dows.substring(0, 3)
        return dows
    }

    static getMonthString(month, shorten){
        var ms = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][month]
        if(shorten) ms = ms.substring(0, 3)
        return ms
    }

    static getLongMonth(month){
        switch(month){
            case "Jan": return "January"
            case "Feb": return "February"
            case "Mar": return "March"
            case "Apr": return "April"
            case "May": return "May"
            case "Jun": return "June"
            case "Jul": return "July"
            case "Aug": return "August"
            case "Sep": return "September"
            case "Oct": return "October"
            case "Nov": return "November"
            case "Dec": return "December"
            default: return ""
        }
    }

    //Convert any time to the number of milliseconds that have passed since midnight of that day
    //eg 4:30PM = 16 hours * 3600000 milliseconds/hour + 30 minutes * 60000 milliseconds/minute = 59400000 milliseconds
    static getMillisecondsAfterMidnight(date){

        var h = date.getHours()
        var m = date.getMinutes()
        var s = date.getSeconds()
        var ms = date.getMilliseconds()

        return h * 3600000 + m * 60000 + s * 1000 + ms
    }

    static getDatesForThisWeek(anchor){
        var date = new Date(anchor.getTime())
        let week = []

        for (let i = 0; i < 7; i++) {
            let first = date.getDate() - date.getDay() + i 
            let day = new Date(date.setDate(first))
            week.push(day)
        }
        return week
    }

    /**
     *
     * @param date {number}
     * @param out {AppointmentModel}
     * @returns {{start: number, end: number}}
     */
    static getFullDayAppointmentTimes(date, out){
            let dayStart = new Date(date)
            dayStart.setHours(0,0,0,0) //midnight
            let dayEnd = new Date(date)
            dayEnd.setHours(0,0,1,0) //midnight and 1 second
            if(out){
                out.start = dayStart
                out.end = dayEnd
            }
            return {
                start: dayStart.getTime(),
                end: dayEnd.getTime()
            }
    }

    static isTimeRangeFullDay(timeRange){
        var start
        if(timeRange.start) start = new Date(timeRange.start) 
        else if(timeRange.startTime) start = new Date(timeRange.startTime)
        else return false

        var end
        if(timeRange.end) end = new Date(timeRange.end) 
        else if(timeRange.endTime) end = new Date(timeRange.endTime)
        else return false
        
        var validStart = start.getHours() == 0 && start.getMinutes() == 0 && start.getSeconds() == 0 //midnight
        var validEnd = end.getHours() == 0 && end.getMinutes() == 0 && end.getSeconds() == 1 //midnight and 1 second
        return validStart && validEnd
    }

    static doTimeRangesOverlap(start1, end1, start2, end2)
    {
        return (start1 >= start2 && start1 < end2 || start2 >= start1 && start2 < end1)
    }

    /**
     *
     * @param obj {{start: any, end: any, [key: string]: any}}
     */
    static getTimeRange(obj){
        if (typeof obj.start === 'number' && typeof obj.end === 'number') {
            return {start: obj.start, end: obj.end}
        } else if (obj.start instanceof Date && obj.end instanceof Date) {
            return {start: obj.start.getTime(), end: obj.end.getTime()}
        }
        else{
            // not sure if this will bite us at some point, but it is a check that we need to know about if it happens
            throw new Error('time is not a date or number!')
        }
    }

    static doAppointmentsOverlap(appointment1, appointment2)
    {
        let dateRange1 = this.getTimeRange(appointment1);
        let dateRange2 = this.getTimeRange(appointment2);
        return CalendarUtil.doTimeRangesOverlap(dateRange1.start, dateRange1.end, dateRange2.start, dateRange2.end)
    }

    static isTimeRangeFullyInside(start1, end1, start2, end2)
    {
        return (start1 >= start2 && end1 <= end2)
    }

    static getDate(date, shorten)
    {
        return{
            dayOfWeekString: CalendarUtil.getDayOfWeekString(date.getDay(), shorten), 
            dayOfMonth: date.getDate(),
            month: date.getMonth() + 1,
            monthString: CalendarUtil.getMonthString(date.getMonth(), shorten),
            year: date.getFullYear()
        }
    }

    static getReadableDateAndTime(input, shorten){
        let date = new Date(input)
        let dateData = CalendarUtil.getDate(date, shorten)
        let currentTimeData = CalendarUtil.getTime(date)
        return currentTimeData.hour + ":" + currentTimeData.minutes + " " + currentTimeData.suffix + " " + dateData.monthString + " " + dateData.dayOfMonth 
    }

    static getCurrentDate(shorten){
        const now = new Date()
        return CalendarUtil.getDate(now, shorten)
    }

    static getCurrentTime(){
        const now = new Date()
        return CalendarUtil.getTime(now)
    }

    static getTime(input){
        const date = new Date(input)

        var hours = date.getHours()
        var suffix = (hours >= 12) ? 'pm' : 'am';

        if(hours > 12) hours -= 12
        if(hours == 0) hours = 12

        var minutes = date.getMinutes()
        if(minutes < 10) minutes = "0" + minutes

        return{
            hour: hours,
            minutes: minutes,
            seconds: date.getSeconds(), 
            suffix: suffix
        }
    }

    static getCurrentTimeString(){
        const now = this.getCurrentTime()

        return now.hour + ":" + now.minutes + " " + now.suffix
    }

    static breakTimeCache = {}

    static breakTime(time) {
        if (!CalendarUtil.breakTimeCache[time]) {
            const [hm, amPm] = time.split(/\s+/)
            console.log("hm", hm, 'amPm', amPm)
            let [hours, minutes] = hm.split(':').map((v) => parseInt(v))
            if (amPm != null && amPm.toLowerCase() === "pm" && hours !== 12) hours += 12
            CalendarUtil.breakTimeCache[time] = [hours, minutes, amPm]
        }
        return CalendarUtil.breakTimeCache[time]
      }

    static stringToMinutes(s){
        var [hours, minutes, suffix] = CalendarUtil.breakTime(s)
        return parseInt(hours * 60) + parseInt(minutes)
    }

    static minutesToString(m){
        var hours = Math.floor(m / 60);
        var suffix = "AM"
        if(hours >= 12) suffix = "PM"
        if(hours > 12) hours -= 12
        var minutes = m % 60;
        if(minutes < 10) minutes = "0" + minutes.toString()
        return hours + ":" + minutes + " " + suffix;  
    }

    static getShortTime(time){
        var date = new Date(time)
        var timeMs = date.getTime()
        if(!CalendarUtil.shortTimeCache[timeMs]){
            CalendarUtil.shortTimeCache[timeMs] = date.toLocaleTimeString(undefined,
                {
                    hour: 'numeric',
                    minute: '2-digit'
                })
        }
        return CalendarUtil.shortTimeCache[timeMs]
    }

    static areDatesTheSameDay(d1, d2){
        return d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear()
    }

    //pass any date in
    //returns the first second of the Sunday of that week
    //and the last second of the Saturday of that week
    static getWeekTimeRange(date){
        var tmpDate = new Date(date)
        var sun = new Date(tmpDate.setDate(tmpDate.getDate() - tmpDate.getDay()))
        sun.setHours(0)
        sun.setMinutes(0)
        sun.setSeconds(0)
        sun.setMilliseconds(0)
    
        var sat = new Date(sun.valueOf())
        sat.setDate(sat.getDate() + 6)
        sat.setHours(23)
        sat.setMinutes(59)
        sat.setSeconds(59)
        sat.setMilliseconds(999)

        return {
            start: sun.getTime(),
            end: sat.getTime()
        }
    }

    static getTimezoneOffset(tz) {
        var hereDate = new Date(Date.now());
        hereDate.setMilliseconds(0);
    
        const hereOffsetHrs = hereDate.getTimezoneOffset() / 60 * -1,
        thereLocaleStr = hereDate.toLocaleString('en-US', {timeZone: tz}),
        thereDate = new Date(thereLocaleStr),
        diffHrs = (thereDate.getTime() - hereDate.getTime()) / 1000 / 60 / 60,
        thereOffsetHrs = hereOffsetHrs + diffHrs;
    
        return thereOffsetHrs;
    }


    //returns the first and last second this facility is open
    //TODO - these should probably be defined on the backend
    //TODO REMOVE
    static getOpenTimeRange(date){
        var start = new Date(date.valueOf())
        start.setHours(6)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)

        var end = new Date(date.valueOf())
        end.setHours(19)
        end.setMinutes(59)
        end.setSeconds(59)
        end.setMilliseconds(999)

        return {
            start: start.getTime(),
            end: end.getTime()
        }
    }

    //pass in date
    //returns the first second of that day
    //and the last second of that day
    static getDayTimeRange(date){
        var start = new Date(date.valueOf())
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)

        var end = new Date(date.valueOf())
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(59)
        end.setMilliseconds(999)

        return {
            start: start.getTime(),
            end: end.getTime()
        }
    }

    //pass in date
    //returns the first second of the allowed morning
    //and the last second of the allowed morning
    static getMorningTimeRange(date){
        var start = new Date(date.valueOf())
        start.setHours(6)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)

        var end = new Date(date.valueOf())
        end.setHours(11)
        end.setMinutes(59)
        end.setSeconds(59)
        end.setMilliseconds(999)

        return {
            start: start.getTime(),
            end: end.getTime()
        }
    }

    //pass in date
    //returns the first second of the allowed morning
    //and the last second of the allowed morning
    static getAfternoonTimeRange(date){
        var start = new Date(date.valueOf())
        start.setHours(13)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)

        var end = new Date(date.valueOf())
        end.setHours(18)
        end.setMinutes(59)
        end.setSeconds(59)
        end.setMilliseconds(999)

        return {
            start: start.getTime(),
            end: end.getTime()
        }
    }

    static roundTime(time, inc) {
        var rounded = new Date(time)
        rounded.setMinutes(Math.floor(rounded.getMinutes() / inc) * inc);
        return rounded.getTime();
    }
}