import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Tracker from '../../managers/Tracker';
import {Button} from 'react-bootstrap'
import LoadingSpinner from '../../util/LoadingSpinner'
import Colors from '../../util/Colors';
import FacilityManager from '../../managers/FacilityManager';
import TimeRestrictionsPage from './TimeRestrictionsPage'
import LocationsPage from './LocationsPage'
import RoomsPage from './RoomsPage';
import AutoSchedulerPage from './AutoSchedulerPage'
import FacilityUtil from '../../util/FacilityUtil';
import ManageRoles from '../ManageRoles/ManageRoles';
import SDEditTextBox from '../../ui/SDEditTextBox';
import { StringResources } from '../../strings/StringManager';
import { toast } from 'react-toastify';

export default class AdminPanel extends React.Component {
    
    constructor(props) {
        super(props)
        
        this.state = {
            editingProvider: null
        }

        document.body.style = 'background:' + Colors.Secondary.Light + ';';

        Tracker.logScreenView("admin_panel")
    }

    reloadServices = () => {
        this.setState({serviceProvidersDict: null})
        return new Promise((resolve, reject)=>{
            FacilityUtil.buildServiceProviderMap(this.props.facility.id, this.props.facility.services, true).then((data)=>{
                this.setState({serviceProvidersDict: data})
                resolve()
            })
        })
    }

    clickedManageRestrictions = () => {
        this.props.machine.send("TIME_RESTRICTIONS_SELECTED");
    }
    
    clickedManageLocations = () => {
        this.props.machine.send("LOCATIONS_SELECTED");
    }

    clickedManageRooms = () => {
        this.props.machine.send("ROOMS_SELECTED");
    }

    clickedManageScheduler = () => {
        this.props.machine.send("EDIT_SCHEDULER")
    }

    clickedManageRoles = () => {
        this.props.machine.send("EDIT_ROLES")
    }

    render() {
        const state = this.props.machine.state.value["AdminPanel"]

        if(this.state.loading) return <LoadingSpinner/>

        switch(state){
            case "SelectOption": return this.drawOptionsList()
            case "TimeRestrictions": case "EditRestrictionCalendar": case "EditRestrictionPopup": return this.drawTimeRestrictionsPage()
            case "Locations": case "EditLocation": case "NewLocation": return this.drawLocationsPage()
            case "Rooms": case "EditRoom": case "NewRoom": return this.drawRoomsPage()
            case "EditScheduler": 
                return this.drawSchedulerPage()
            default:
                if(state.ListFacilityRoles){
                    return this.drawFacilityRolesPage()
                }
        }

    }

    drawFacilityRolesPage(){
        return <ManageRoles machine={this.props.machine} facility={this.props.facility}/>
    }

    drawTimeRestrictionsPage(){
        return <TimeRestrictionsPage onRestrictionsUpdated={this.props.onServicesUpdated} machine={this.props.machine} facility={this.props.facility}/>
    }

    drawLocationsPage(){
        return(
            <LocationsPage onLocationsUpdated={this.props.onLocationsUpdated} machine={this.props.machine} facility={this.props.facility}></LocationsPage>
        )
    }

    drawRoomsPage(){
        return(
            <RoomsPage onLocationsUpdated={this.props.onRoomsUpdated} machine={this.props.machine} facility={this.props.facility}></RoomsPage>
        )
    }

    addButton(list, title, callback, style, enabled = true, loading = false){
        list.push({title: title, callback: callback, style: style, enabled: enabled, loading: loading})
    }

    addEditTextReference(list, title, stringKey, style, enabled = true){
        list.push({title: title, stringKey: stringKey, style: style, enabled: enabled})
    }

    drawOptionsList(){
        var w = 300
        var m = 15
        var buttons = []
        var style = {borderColor:"#000000", width: w, marginTop: m}
        this.addButton(buttons, "Manage Locations", this.clickedManageLocations, style)
        this.addButton(buttons, "Manage Rooms", this.clickedManageRooms, style)
        this.addButton(buttons, "Manage Blocked Times", this.clickedManageRestrictions, style)
        
        this.addButton(buttons, "Manage Roles", this.clickedManageRoles, style)
        
        var buttonElements = buttons.sort((a, b)=>{
            return a.title > b.title ? 1 : -1
        }).map((button)=>{
            return [
                <Button 
                    disabled={!button.enabled} 
                    variant="light" 
                    onClick={button.callback} 
                    style={button.style}>
                        {button.loading ? <LoadingSpinner size={20} marginTop={0}/> : button.title}
                </Button>,
                <br/>
            ]
        })

        let editReferenceList = this.drawEditStringReferences(style)
        return(
            <div align="center">
                <div style={{width: w}}>
                    {buttonElements}
                    {editReferenceList}
                </div>
            </div>
        )
    }

    drawEditStringReferences = (style)=> {
        let editTextFields = []
        this.addEditTextReference(editTextFields, StringResources.keys.patient.toUpperCase(), StringResources.keys.patient, style)
        let editTextElements = this.drawEditTexts(editTextFields)
        let saveButton = (
            <Button variant="light" onClick={this.onSaveStringReferences} style={{width: style.width, borderColor: style.borderColor}}>Save</Button>
        )
        return [
            <br style={style}/>,
            <div style={style}>String references</div>,
            <div style={{width: style.width, fontSize: 12}}>Any changes done here may require the already open tab to be closed and reopened for changes to take effect for other users, or click the skilled day logo twice</div>,
            editTextElements,
            saveButton
        ]
    }

    drawEditTexts = (editTextFields) => {
        return editTextFields.sort((a, b)=>{
            return a.title > b.title ? 1 : -1
        }).map((editText)=>{
            let stringValue = this.state.stringReferences ? this.state.stringReferences[editText.stringKey] : null
            if(!stringValue) stringValue = StringResources.get(editText.stringKey)
            console.log(editText.stringKey,StringResources.get(editText.stringKey))
            return [
                <SDEditTextBox 
                    disabled={!editText.enabled} 
                    label={editText.title} 
                    title={stringValue}
                    onChange={(value)=>{
                        let stringReferences = this.state.stringReferences
                        if(!stringReferences)
                            stringReferences = {}
                        stringReferences[editText.stringKey] = value
                        this.setState({
                            stringReferences: stringReferences
                        })
                    }}
                    style={editText.style}/>,
                <br/>
            ]
        })
    }

    drawSchedulerPage() {
        return (
            <Container className="mt-3">
                <Row className="justify-content-md-center">
                    <Col sm="12" md="6" md-offset="4">
                        <AutoSchedulerPage onScheduleFinished={this.props.onScheduleFinished} serviceProvidersDict={this.props.serviceProvidersDict} machine={this.props.machine} facility={this.props.facility} onChangeRule={this.props.onChangeRule}/>
                    </Col>
                </Row>
            </Container>
        )
    }

    onSaveStringReferences = ()=> {
        this.setState({loading: true})
        FacilityManager.updateStringReferences(this.props.facility.id, this.state.stringReferences, (result)=>{
            if(result.statusCode !== 200){
                toast(result.message)
            }
            this.setState({loading: false, stringReferences: {}})
        })
    }
}