import { ManageUserRolesMachine } from './ManageUserRolesMachine'
export const AdminPanelMachine = {
    initial: 'SelectOption',
    states: {
        SelectOption: {
            on: {
                EDIT_ROLES: 'ListFacilityRoles',
                TIME_RESTRICTIONS_SELECTED: 'EditRestrictionCalendar',
                LOCATIONS_SELECTED: 'Locations',
                ROOMS_SELECTED: 'Rooms',
                EDIT_SCHEDULER: 'EditScheduler'
            }
        },
        ListFacilityRoles: {
            on: {
                FINISH: 'SelectOption',
                REJECT: 'SelectOption'
            },
            ...ManageUserRolesMachine
        },
        EditRestrictionCalendar: {
            on: {
                CANCEL: 'SelectOption',
                NEXT: 'SelectOption',
                POPUP: 'EditRestrictionPopup'
            }
        },
        EditRestrictionPopup: {
            on: {
                CLOSE: "EditRestrictionCalendar",
                CANCEL: "EditRestrictionCalendar"
            }
        },
        Locations: {
            on: {
                EDIT_LOCATION: "EditLocation",
                NEW_LOCATION: "NewLocation",
                FINISH: 'SelectOption'
            }
        },
        EditLocation: {
            on: {
                FINISH: 'Locations',
                CANCEL: 'Locations'
            }
        },
        NewLocation: {
            on: {
                FINISH: 'Locations',
                CANCEL: 'Locations'
            }
        },
        Rooms: {
            on: {
                NEW_ROOM: "NewRoom",
                EDIT_ROOM: "EditRoom",
                FINISH: 'SelectOption'
            }
        },
        EditRoom: {
            on: {
                FINISH: 'Rooms',
                CANCEL: 'Rooms'
            }
        },
        NewRoom: {
            on: {
                FINISH: 'Rooms',
                CANCEL: 'Rooms'
            }
        },
        EditScheduler: {
            on: {
                FINISH: 'SelectOption',
                CANCEL: 'SelectOption'
            }
        },
        stop: {}
    }
}