import OnboardingCalendar from "./OnboardingCalendar";
import React from "react";
import CalendarUtil from "../util/CalendarUtil";
import {Navbar} from 'react-bootstrap'
import RestrictionsPopup from "../ui/RestrictionsPopup";

export default class TimeRestrictionCalendar extends OnboardingCalendar{
    constructor(props){
        super(props)
        this.state.scrollWindowAdditionalMarginBottom = 0
        this.state.calendarType = "TimeRestriction"
    }

    drawHeaders(){ //replaces super
        var headers = [0,1,2,3,4,5,6].map((day) => {
            var d = new Date(this.props.anchorDate)
            d.setDate(d.getDate() + day)
            
            var dow = CalendarUtil.getDayOfWeekString(day, true).toUpperCase()
    
            return <div style={this.getHeaderStyle(day)}>{dow}</div>
        })
        return (
            <Navbar style={{position: 'sticky', top: 0, zIndex: this.state.zIndexHeader}}>
                {headers}
            </Navbar>
        )
    }

    renderPopupElement(validStart, validEnd){ //override super
        return <RestrictionsPopup 
            hideName={this.props.hideName}
            defaultDurationInMinutes={this.state.defaultAppointmentDuration}
            validStart={validStart} 
            user={this.props.user} 
            validEnd={validEnd} 
            room={this.props.selectedRoom}
            blob={this.state.clickedBlob} 
            onPopupMounted={() => this.forceUpdate()}
            editTime={this.editTime} 
            machine={this.props.machine}
            onClose={this.onClose} 
            facility={this.props.facility}
            user={this.props.user}
            onAppointmentStaged={this.props.onAppointmentStaged}
            service={this.props.service}
            onDelete={this.onDelete} 
            />
    }

    getBlobStyle(day, type, startTime, endTime, id){
        var blobStyle = super.getBlobStyle(day, type, startTime, endTime, id)
        if(type == this.appointmentType.selected){ //if selected, span the entire calendar width
            blobStyle.left = window.innerWidth * 0.1 /*(+0) Day 0*/ + 5 //start at day 0
            blobStyle.width = this.state.columnWidth*this.state.columns - 10 //full width
        }
        return blobStyle
    }

    isEditable(scheduleItem, props){
        return true
    }

    getAppointmentType(scheduleItem, editable, props){
        return this.appointmentType.appointment
    }
}