import AppointmentModel from "../models/AppointmentModel";
import {Service} from "../types/Service";

export interface getLinkedAppointmentInfoReturn {
    parentService: string|null,
    parentAppointment: string|null,
    services: string[],
    appointments: string[],
}

export default class AppointmentUtil{
    static getLinkedAppointmentInfo(appt: AppointmentModel, serviceLookup: {[key: string]: Service}): getLinkedAppointmentInfoReturn|null {
        if (!appt.linkedAppointments || appt.linkedAppointments.length === 0) return null
        const services: string[] = [];
        const appointments: string[] = [];
        let parentService: string|null = null;
        let parentAppointment: string|null = null;
        for (const {AppointmentId, ServiceId, isParent} of Object.values(appt.linkedAppointments)) {
            if(isParent){
                parentService = serviceLookup[ServiceId].name
                parentAppointment = AppointmentId
                continue
            }
            services.push(serviceLookup[ServiceId].name)
            appointments.push(AppointmentId)
        }
        return {
            parentService: parentService,
            parentAppointment: parentAppointment,
            services: services,
            appointments: appointments,
        }
    }
}