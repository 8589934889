import NetworkManager from './NetworkManager';
import { Endpoint } from '../util/Constants';
import CacheManager from './CacheManager';

export default class FacilityManager {

    id

    constructor(id) {
        this.id = id
    }

    /**
     * @param {String} id 
     * @param {Boolean} providers include provider data
     * @param {Boolean} services include service data
     * @param {Boolean} rooms include room data
     * @param {(data) => {}} onResult 
     */
    static getFacility(id, providers, services, rooms, onResult) {
        return new Promise(function(resolve){
            let path = Endpoint.base + '/facilities';

            let params = {
                id: id,
            }

            if (providers) params['providers'] = true
            if (services) params['services'] = true
            if (rooms) params['rooms'] = true
            NetworkManager.get(path, params, (result) => {
                if(onResult) onResult(result)
                resolve(result)
            })
        })
    }

    /**
     * @param {String} id 
     * @param {Boolean} providers include provider data
     * @param {Boolean} services include service data
     * @param {Boolean} rooms include room data
     * @param {(data) => {}} onResult 
     */
    static getFacilityId(name, onResult) {
        return new Promise(function(resolve){
            let path = Endpoint.base + '/facilities/lookup';

            let params = {
                name: name,
            }
            
            NetworkManager.get(path, params, (result) => {
                if(onResult) onResult(result)
                resolve(result)
            })
        })
    }

    /**
     * @param {(data) => {}} onResult 
     */
    static listFacilities(onResult) {
        let path = Endpoint.base + '/facilities/list'

        NetworkManager.get(path, null, (result) => {
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} name Facility name
     * @param {String} phone 10 digits unformatted
     * @param {String} address Format -> {Building Number} {Street Name}
     * @param {String} city 
     * @param {String} state 2 character abreviation
     * @param {String} zip 5 digit code
     * @param {(data) => {}} onResult 
     */
    static createFacility(name, phone, address, city, state, zip, onResult) {
        let path = Endpoint.base + '/facilities'

        let params = {
            name: name, 
            phone: phone,
            address: address,
            city: city,
            state: state,
            zip: zip,

            roles: ['admin', 'provider', 'read_only'],
            version: 3
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static addRole(facilityId, role, onResult){
        let path = Endpoint.base + '/facilities/roles'
        let params = {
            facilityId: facilityId,
            role: role
        }
        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static removeRole(facilityId, role, onResult){
        let path = Endpoint.base + '/facilities/delete-role'
        let params = {
            facilityId: facilityId,
            role: role
        }
        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static getRoles(facilityId, onResult){ //same as getFacility, then getting result.roles
        let path = Endpoint.base + '/facilities/roles'
        let params = {
            facilityId: facilityId
        }
        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }
    
    static setAutoScheduleRule(facilityId, serviceId, onResult){
        let path = Endpoint.base + '/facilities/set-rule'

        let params = {
            facilityId: facilityId,
            serviceId: serviceId
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static deleteAutoScheduleRule(facilityId, serviceId, onResult){
        let path = Endpoint.base + '/facilities/delete-rule'

        let params = {
            facilityId: facilityId,
            serviceId: serviceId
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * Adds a provider as a member of a facility. This does NOT create a new provider. Providers can can exist in multiple facilities.
     * @param {String} facilityId id of an existing facility
     * @param {String} providerId id of an existing provider
     * @param {(data) => {}} onResult 
     */
    static addProvider(facilityId, role, color, providerId, onResult) {
        let path = Endpoint.base + '/facilities/add-provider'

        let params = {
            facilityId: facilityId,
            providerId: providerId,
            role: role,
            color: color
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * @param {String} facilityId id of an existing facility
     * @param {String} providerId id of an existing provider
     * @param {(data) => {}} onResult 
     */
    static removeProvider(facilityId, providerId, onResult) {
        let path = Endpoint.base + '/facilities/remove-provider'

        let params = {
            facilityId: facilityId,
            providerId: providerId
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * @param {String} facilityId id of an existing facility
     * @param {String} serviceId id of an existing service
     * @param {(data) => {}} onResult 
     */
    static addService(facilityId, serviceId, onResult, dontCache) {
        let path = Endpoint.base + '/facilities/add-service'

        let params = {
            facilityId: facilityId,
            serviceId: serviceId
        }

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, true, false)
            onResult(result)
        })
    }

    /**
     * @param {String} facilityId id of an existing facility
     * @param {String} serviceId id of an existing service
     * @param {(data) => {}} onResult 
     */
    static removeService(facilityId, serviceId, onResult, dontCache) {
        let path = Endpoint.base + '/facilities/remove-service'

        let params = {
            facilityId: facilityId,
            serviceId: serviceId
        }

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, true, false)
            onResult(result)
        })
    }

    static getServiceProviders(facilityId, serviceIds, onResult){
        return new Promise((resolve, reject)=>{
            let path = Endpoint.base + '/facilities/service-providers'
            let params = {
                facilityId: facilityId,
                serviceIds: JSON.stringify(serviceIds)
            }
            NetworkManager.get(path, params, (result) => {
                if(onResult) onResult(result)
                resolve(result)
            }, (error)=>{
                resolve({})
            })
        })
    }

     /**
     * @param {String} facilityId
     * @param {String} locationId
     * @param {(data) => {}} onResult
     */
    static getLocation(facilityId, locationId, onResult) {
        let path = Endpoint.base + "/locations"
        
        let params = {
            facility: facilityId,
            id: locationId
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

     /**
     * @param {String} facilityId
     * @param {(data) => {}} onResult
     */
    static listLocations(facilityId, onResult) {
        let path = Endpoint.base + "/locations/list"

        let params = {
            facility: facilityId
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

     /**
     * 
     * @param {String} facilityId
     * @param {String} name
     * @param {String} description
     * @param {Array<String>} services
     * @param {(data) => {}} onResult
     */
    static createLocation(facilityId, name, description, services, disabled, onResult, dontCache) {
        let path = Endpoint.base + '/locations'

        let params = {
            facilityId: facilityId,
            name: name,
            description: description,
            services: services,
            disabled: disabled
        }

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(params.facilityId, false, false, false)
            onResult(result)
        })
    }

    /**
     * 
     * @param {Object} params an existing location object with modified data attached
     * @param {(data) => {}} onResult
     */
    static updateLocation(params, onResult, dontCache) {
        let path = Endpoint.base + '/locations/update'

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(params.facilityId, false, false, false)
            onResult(result)
        })
    }

    static validateObject = (item, fields) => {
        var validation = {
            success: true,
            errors: []
        }
        fields.forEach(field => {
            var variable = item[field]
            if(!variable || variable === ''){
                validation.success = false
                validation.errors.push(`${field} must not be empty!`)
            }
        });
        return validation
    }

    /**
     * 
     * @param {String} facilityId 
     * @param {String} roomId
     * @param {(data) => {}} onResult 
     */
    static getRoom(facilityId, roomId, urlCode, onResult) {
        let path = Endpoint.base + "/rooms"

        let params = {
            facility: facilityId,
            id: roomId, 
            urlCode: urlCode
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * @param {String} facilityId 
     * @param {Boolean} occupied 
     * @param {Array<String>} services TODO Not Implemented
     * @param {(data) => {}} onResult 
     */
    static listRooms(facilityId, occupied, services, onResult) {
        let path = Endpoint.base + "/rooms/list"

        let params = {
            facility: facilityId
        }

        if (typeof occupied != 'undefined' && occupied != null) {
            params['occupied'] = occupied
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} facilityId required
     * @param {String} roomId include to get occupancy history for only a single room
     * @param {(data) => {}} onResult 
     */
    static listOccupancyHistory(facilityId, roomId, onResult) {
        let path = Endpoint.base + "/rooms/history"

        let params = {
            facility: facilityId,
            id: roomId
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} facilityId
     * @param {String} name
     * @param {(data) => {}} onResult 
     */
    static createRoom(facilityId, name, onResult, dontCache) {
        let params = {
            facilityId: facilityId,
            name: name,
            occupied:false
        }
        this.updateRoom(params, onResult, dontCache)
    }

    static updateRoom(room, onResult, dontCache){
        let path = Endpoint.base + '/rooms'
        NetworkManager.post(path, room, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(room.facilityId, false, false, true)
            onResult(result)
        })
    }

    /**
     * Rename an occupancy to a new identifier by calling /v1/rooms/rename-occupancy with NetworkManager.post
     * In the body of the post request, pass the following:
     * room id, new identifier, and facility id
     * @param room The room object
     * @param newIdentifier The new identifier for the room
     * @param onResult
     * @param onError
     * @param dontCache
     */
    static renameOccupant(room, newIdentifier, onResult, onError, dontCache){
        let path = Endpoint.base + '/rooms/rename-occupancy'
        let params = {
            roomId: room.id,
            newIdentifier: newIdentifier,
            facilityId: room.facilityId
        }
        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(room.facilityId, false, false, true)
            onResult(result)
        }).catch(error => {
            onError(error)
        })
    }

    /**
     * 
     * @param {String} facilityId 
     * @param {String} roomId 
     * @param {String} identifier 
     * @param {(data) => {}} onResult 
     */
    static addRoomOccupancy(facilityId, roomId, identifier, services, startDate, onResult, dontCache) {
        let path = Endpoint.base + '/rooms/add-occupancy'

        let params = {
            facilityId: facilityId,
            roomId: roomId,
            identifier: identifier,
            services: services,
            startDate: startDate
        }

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, false, true)
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} facilityId 
     * @param {String} roomId
     * @param {(data) => {}} onResult 
     */
    static removeRoomOccupancy(facilityId, roomId, endDate, onResult, dontCache) {
        if(endDate) {
            endDate = endDate.getTime()
        }
        else{
            endDate = new Date().getTime()
        }
        let path = Endpoint.base + '/rooms/remove-occupancy'

        let params = {
            facilityId: facilityId,
            roomId: roomId,
            endDate: endDate //already in UTC
        }
        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, false, true)
            onResult(result)
        })
    }

    /**
     * 
     * @param {String} facilityId 
     * @param {String} roomId
     * @param {String} newRoomId
     * @param {(data) => {}} onResult 
     */
    static moveRoomOccupancy(facilityId, roomId, newRoomId, onResult, dontCache) {
        let path = Endpoint.base + '/rooms/move-occupancy'

        let params = {
            facilityId: facilityId,
            roomId: roomId,
            newRoomId: newRoomId
        }

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, false, true)
            onResult(result)
        })
    }

    static updateOccupancyEvaluation(facilityId, roomId, services, onResult) {
        let path = Endpoint.base + '/rooms/update-evaluation'

        let params = {
            facilityId: facilityId,
            roomId: roomId,
            services: services
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        });
    }

    /**
     * @param {(data) => {}} onResult 
     */
    static updateOccupancyProviders(facilityId, roomId, providers, onResult, dontCache) {
        let path = Endpoint.base + '/rooms/update-providers'

        let params = {
            facilityId: facilityId,
            roomId: roomId,
            providers: providers
        }

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, false, true)
            onResult(result);
        });
    }

    static addOccupancyServiceConfig(params, onResult){
        let path = Endpoint.base + "/rooms/add-service-config";
        NetworkManager.post(path, params, (result) => {
            onResult(result);
        });
    }

    static removeOccupancyServiceConfig(facilityId, occupancyId, configId, onResult){
        let path = Endpoint.base + "/rooms/remove-service-config";
        let params = {
            facilityId: facilityId,
            occupancyId: occupancyId,
            configId: configId
        }
        NetworkManager.post(path, params, (result) => {
            onResult(result);
        });
    }

    static getOccupancyServiceConfigs(facilityId, occupancyId, onResult){
        let path = Endpoint.base + "/rooms/service-config/";
        let params = {
            facilityId: facilityId,
            occupancyId: occupancyId
        }
        NetworkManager.get(path, params, (result) => {
            onResult(result);
        });
    }

    static listOccupancyServiceConfigs(facilityId, onResult){
        let path = Endpoint.base + "/rooms/service-config/list";
        let params = {
            facilityId: facilityId,
        }
        NetworkManager.get(path, params, (result) => {
            onResult(result);
        });
    }

    static setProviderRestrictions(facilityId, providerId, isAvailable, onResult, dontCache){
        let path = Endpoint.base + "/facilities/set-provider-restrictions";
        let params = {
            facilityId: facilityId,
            providerId: providerId,
            available: isAvailable
        }
        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, false, true)
            onResult(result);
        });
    }

    static setServiceProviderFallback(facilityId, providerId, serviceId, fallbackProviderId, onResult){
        let path = Endpoint.base + "/facilities/service-provider-fallback";
        let params = {
            facilityId: facilityId,
            providerId: providerId,
            serviceId: serviceId,
            fallbackProviderId: fallbackProviderId
        }
        NetworkManager.post(path, params, async(result) => {
            onResult(result);
        });
    }

    static getProviderServices(facilityId, providerId, onResult){
        let path = Endpoint.base + "/facilities/provider-services"
        let params = {
            facilityId: facilityId,
            providerId: providerId
        }
        NetworkManager.get(path, params, (result) => {
            onResult(result);
        });
    }

    static createAlert(facilityId, message, onResult) {
        let path = Endpoint.base + "/facilities/alert";

        let params = {
            facilityId: facilityId,
            message: message
        };

        NetworkManager.post(path, params, (result) => {
            onResult(result);
        });
    }

    static getAlerts(facilityId, onResult) {
        let path = Endpoint.base + "/facilities/alert";

        let params = {
            facilityId: facilityId
        };

        NetworkManager.get(path, params, (result) => {
            onResult(result);
        });
    }

    static cancelAlert(facilityId, id, onResult) {
        let path = Endpoint.base + "/facilities/alert/cancel";

        let params = {
            facilityId: facilityId,
            id: id
        };

        NetworkManager.post(path, params, (result) => {
            onResult(result);
        })
    }

    static updateStringReferences(facilityId, dictionary, onResult, dontCache) {
        let path = Endpoint.base + "/facilities/update-string-reference";

        let params = {
            facilityId: facilityId,
            dictionary: JSON.stringify(dictionary)
        };

        NetworkManager.post(path, params, async(result) => {
            if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, false, false, false)
            onResult(result);
        })
    }
    
    static getSchedules(facilityId, onResult){
        let path = Endpoint.base + "/facilities/schedules"

        let params = {
            facilityId: facilityId
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })
     }

    static getGroups(facilityId, occupancyId, roomId, onResult){
        let path = Endpoint.base + "/rooms/groups"

        let params = {
            facilityId: facilityId
        }
        if(occupancyId)
            params.occupancyId = occupancyId
        if(roomId)
            params.roomId = roomId

        NetworkManager.get(path, params, (result) => {
            onResult(result);
        })
    }

    static getAllGroups(facilityId, onResult){
        let path = Endpoint.base + "/rooms/groups/all"
        let params = {
            facilityId: facilityId
        }
        NetworkManager.get(path, params, (result) => {
            onResult(result);
        })
    }

    static postGroup(group, onResult){
        let path = Endpoint.base + "/rooms/group"
        NetworkManager.post(path, group, (result) => {
            onResult(result)
        })
    }

    static getGroup(facilityId, id, idType, onResult){
        let path = Endpoint.base + "/rooms/group"
        let params = {
            facilityId: facilityId,
            groupId: id, 
            idType: idType
        }
        NetworkManager.get(path, params, (result) => {
            onResult(result)
        })  
    }

    static putGroupItems(facilityId, groupItems, onResult){
        let path = Endpoint.base + "/rooms/group/items"
        let params = {
            facilityId: facilityId,
            items: groupItems
        }
        NetworkManager.put(path, params, (result) => {
            onResult(result)
        })  
    }

    static deleteGroupItems(facilityId, groupItems, onResult){
        let path = Endpoint.base + "/rooms/group/items"
        let params = {
            facilityId: facilityId,
            items: groupItems
        }
        NetworkManager.delete(path, params, (result) => {
            onResult(result)
        })  
    }

    static postSchedule(facilityId, schedule, onResult){
        let path = Endpoint.base + "/facilities/schedule"

        let params = {
            facilityId: facilityId,
            schedule: schedule
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

    static deleteSchedule(facilityId, schedule, onResult){
        let path = Endpoint.base + "/facilities/delete-schedule"

        let params = {
            facilityId: facilityId,
            schedule: schedule
        }

        NetworkManager.post(path, params, (result) => {
            onResult(result)
        })
    }

}