import React from 'react';

import Colors from '../../util/Colors'
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager'
import LoadingSpinner from '../../util/LoadingSpinner';
import Tracker from '../../managers/Tracker';
import WeekSchedulePage from '../ManagePatients/WeekSchedulePage';
import { toast } from 'react-toastify';
import {AppointmentRepository} from "../../repositories/AppointmentRepository";

export default class ManageActivitiesPage extends React.Component {

    /**
     * @type {AppointmentRepository}
     */
    repo
    constructor(props) {
        Tracker.logScreenView('manage_activities')
        super(props)
        this.repo = new AppointmentRepository(this.props.facility.id)
        this.state = {
            ready: false,
            startDate: null,
            stagedAppointments: [],
            unstagedAppointments: [],
            stagedDeletions: []
        }
        this.reload = this.reload.bind(this)

        if(this.props.editingAppointment){
            this.state.startDate = this.props.editingAppointment.start
        }

        document.body.style = 'background:' + Colors.Secondary.Light + ';';
    }

    getActivities = () => {
        this.setState({loading: true})
        var activitiesService
        this.props.facility.services.forEach((service)=>{
            if(this.props.facility.activityServiceId === service.id){
                activitiesService = service
            }
        })
        if(!activitiesService){
            console.error("Activity Service does not exist!")
            toast("Unable to load manage activities page")
            this.props.machine.send("REJECT")
            this.props.machine.send("HOME")
        }
        this.setState({service: activitiesService, loading: false})
        this.props.machine.send("RESOLVE")
    }

    componentDidMount() {
        this.reload()
    }

    reload() {
        this.getActivities()
    }

    cancel = () => {
        this.props.machine.send("HOME")
        if(this.props.editingAppointment){
            this.props.clearEditAppointment(true)
        }
    }

    scheduleAppointments = (stagedAppointments, stagedDeletions) => {

        window.scrollTo(0, 0)

        this.setState({
            loading: true
        })

        let cancelPromises = stagedDeletions.map(e=>this.repo.cancelAppointment(e.appointmentId))

        Promise.all(cancelPromises)
            .then(_ => {
                let savePromises = stagedAppointments.map(appt => {
                    console.log(`scheduleAppointments : stagedAppointment : ${appt.id}...`, appt)
                    /**
                     * @type {AppointmentModel}
                     */
                    const appointmentToSave = window.structuredClone(appt)
                    appointmentToSave.providers = []
                    appointmentToSave.linkedAppointments = []
                    appointmentToSave.occupants = []
                    appointmentToSave.locations = [
                        {
                            id: appointmentToSave.locationId,
                            name: this.props.facility.locations[appointmentToSave.locationId].name
                        }
                    ]
                    console.log("Saving appointment", appointmentToSave)
                    this.repo.saveAppointment(appointmentToSave)
                })
                return Promise.all(savePromises)
            })
            .then(_ => {
                this.setState({
                    loading: false
                })
                this.props.machine.send("FINISH")
                this.props.machine.send("HOME")
                if(this.props.editingAppointment){
                    this.props.clearEditAppointment(true)
                }
            })
            .catch(err => {
                console.error("Error scheduling appointments", err)
                console.log("Error scheduling appointments", stagedAppointments, stagedDeletions)
                toast("Error scheduling appointments")
                this.setState({
                    loading: false
                })
                this.props.machine.send("REJECT")
                this.props.machine.send("HOME")
                if(this.props.editingAppointment){
                    this.props.clearEditAppointment(true)
                }
            })
    }

    render() {
        const state = this.props.machine.state.value["ManageActivities"]

        if (this.state.loading) return <LoadingSpinner />

        switch (state) {
            case "Load":
                return <LoadingSpinner />

            case "Schedule":
            case "SchedulePopup":
                return (
                    <div>
                        <WeekSchedulePage 
                            anchorDate={this.state.startDate}
                            stateMachineName="ManageActivities"
                            service={this.state.service} 
                            machine={this.props.machine} 
                            selectedRoom={
                                {
                                    facilityId: this.props.facility.id
                                }
                            }
                            facility={this.props.facility} 
                            user={this.props.user} 
                            onFinish={this.scheduleAppointments}
                            onCancel={this.cancel} />
                    </div>
                )
        }
        return null
    }
}