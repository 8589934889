import React from 'react'
import './ServiceProviderEditTextBox.css'
import {InputGroup, FormControl} from 'react-bootstrap'

export default class ServiceProviderEditTextBox extends React.Component{
    render(){
        return (
            <div className='provider-edit-container'>
                <InputGroup className="provider-edit-input-group">
                    <InputGroup.Prepend className="provider-prepend">
                    <InputGroup.Text className="provider-prepend-label">{this.props.label}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl className="provider-form-control" disabled={this.props.disabled === true} value={this.props.title} onChange={this.onChange}/>
                </InputGroup>
            </div>
        )
    }

    onChange = (element) => {
        this.props.onChange(element.target.value)
    }
}