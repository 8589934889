import React from 'react';

import AgendaCard from './AgendaCard'

export default class Agenda extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pendingCancellations: [],
            serviceLookup: this.getServiceLookup()
        }
    }

    componentDidMount() {
        this.forceUpdate()
    }

    onDeleteClicked = (id) => {
        this.setState({
            pendingCancellation: this.state.pendingCancellations.push(id)
        }, () => this.forceUpdate())
    }

    onCancelDelete = (id) => {
        this.setState({
            pendingCancellation: this.state.pendingCancellations.splice(this.state.pendingCancellations.indexOf(id), 1)
        }, () => this.forceUpdate())
    }

    onConfirmDelete = (id) => {
        this.props.onDeleteAppointment(id)
    }

    renderAgendaItems() {
        const itemList = this.props.agendaItems

        if (!itemList || itemList.length === 0) {
            return (
                <center>Schedule is Empty</center>
            )
        }

        var index = -1
        var spacing = 16
        var yCoord = 40
        var yOffset = 0

        return itemList.map((agendaItem) => {
            index++

            var e1 = document.getElementById((index - 1).toString())
            if (e1 != null) {
                yCoord += e1.clientHeight + spacing
            }

            var e2 = document.getElementById(index.toString())
            if (e2 != null) {
                yOffset = e2.clientHeight + spacing
            }

            return (
                <div style={{ pageBreakInside: "avoid" }}>
                    <AgendaCard anchor={this.props.anchor}
                                viewingId={this.props.viewingId}
                                overrideStyle={this.props.overrideStyle}
                                patient={this.props.patient}
                                showLoadingSpinner={this.props.showLoadingSpinner}
                                pendingCancellation={this.state.pendingCancellations.includes(agendaItem.id)}
                                onDeleteClicked={this.onDeleteClicked}
                                onCancelDelete={this.onCancelDelete}
                                onConfirmDelete={this.onConfirmDelete}
                                showDropDown={this.props.showDropDown}
                                printMode={this.props.printMode}
                                roomData={this.props.roomData}
                                yOffset={yOffset}
                                yCoord={yCoord}
                                onEditAppointment={this.props.onEditAppointment}
                                machine={this.props.machine}
                                user={this.props.user}
                                urlCode={this.props.urlCode}
                                facility={this.props.facility}
                                item={agendaItem}
                                index={index}
                                size={itemList.length}
                                services={this.props.services}
                                serviceLookup={this.state.serviceLookup}/>
                </div>
            )
        })
    }

    getServiceLookup = ()=> {
        var services = {}
        this.props.facility.services.forEach(service => {
            services[service.id] = service
        });
        return services
    }

    render() {
        return (
            <div style={{ marginTop: this.props.printMode ? 10 : 50, zIndex: 4 }}>
                {this.renderAgendaItems()}
            </div>
        )
    }
}