
// Available variables:
// - Machine
// - interpret
// - assign
// - send
// - sendParent
// - spawn
// - raise
// - actions
// - XState (all XState exports)

import { Machine, assign } from 'xstate';

import { AgendaMachine } from './Views/AgendaMachine'
import { FacilityScheduleMachine } from './Views/FacilityScheduleMachine'

import { PrintPatientScheduleMachine } from './Actions/PrintPatientScheduleMachine'
import { ScheduleAppointmentMachine } from './Actions/ScheduleAppointmentMachine'
import { ScheduleByConfigurationMachine } from './Actions/ScheduleByConfigurationMachine'
import { ScheduleForwardMachine } from './Actions/ScheduleForwardMachine'

import { AdminPanelMachine } from './Manage/AdminPanelMachine'
import { ZeroMinuteSchedulesMachine } from './Manage/ZeroMinuteSchedulesMachine'
import { ManagePatientsMachine } from './Manage/ManagePatientsMachine'
import { ManageUsersMachine } from './Manage/ManageUsersMachine'
import { ManageServicesMachine } from './Manage/ManageServicesMachine'
import { ManageUserRolesMachine } from './Manage/ManageUserRolesMachine'
import { PatientEvaluationMachine } from './Manage/PatientEvaluationMachine'
import { ManageBillingMachine } from './Manage/ManageBillingMachine'
import { ManageAnnouncementsMachine } from './Manage/ManageAnnouncementsMachine';
import { ManageActivitiesMachine } from './Manage/ManageActivitiesMachine';

import { ManageGroupsMachine } from './Manage/ManageGroupsMachine';
import { LoginMachine } from './LoginMachine';

const HomeMachine = Machine({
    id: 'home',
    initial: 'CheckAuthStatus',
    context: {
        retries: 0
    },
    states: {
        CheckAuthStatus: {
            on: {
                AUTHORIZED: 'Init',
                UNAUTHORIZED: 'Login',
                AGENDA_404: 'Agenda404',
                AGENDA: 'Agenda',
                ANNOUNCEMENTS: 'Announcements'
            }
        },
        Login: {
            on: {
                LOGIN: 'Init'
            },
            ...LoginMachine
        },
        Init: {
            on: {
                RESOLVE: 'Home',
                REJECT: 'failure'
            }
        },
        Agenda404: {
            //do nothing. Last stop
        },
        Home: {
            on: {
                SELECT_SCHEDULE_APPOINTMENT: 'ScheduleAppointment',
                SELECT_SCHEDULE_GROUP_APPOINTMENT: 'ScheduleGroupAppointment',
                SELECT_SCHEDULE_BY_CONFIGURATION: 'ScheduleByConfiguration',
                SELECT_AGENDA: "Agenda", //user schedule
                SELECT_PRINT_PATIENT_SCHEDULES: 'PrintPatientSchedule',
                SELECT_EXPORT_SCHEDULES: 'ExportFacilitySchedules',
                SELECT_PATIENT_EVALUATION: "PatientEvaluation",
                SELECT_FACILITY_SCHEDULE: "FacilitySchedule",
                SELECT_PROVIDER_SCHEDULE: "FacilityProviderSchedule",
                SELECT_MANAGE_PATIENTS: 'ManagePatients',
                SELECT_MANAGE_SERVICES: "ManageServices",
                SELECT_MANAGE_PROVIDERS: "ManageUsers",
                SELECT_MANAGE_FACILITY: "AdminPanel",
                SELECT_MANAGE_ANNOUNCEMENTS: "ManageAnnouncements",
                SELECT_ZERO_MINUTE_SCHEDULES: "ZeroMinuteSchedule", //Bath Scheduling, etc.
                SELECT_BILLING: "Billing",
                SELECT_MANAGE_GROUPS: "ManageGroups",
                SELECT_MANAGE_ACTIVITIES: "ManageActivities",
                SELECT_SCHEDULE_FORWARD: "ScheduleForward"
            }
        },
        Announcements: {
            on: {
                HOME: 'Home',
                REJECT: 'failure' //not really a way to escape from this page
            }
        },
        ScheduleAppointment: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ScheduleAppointmentMachine
        },
        ScheduleGroupAppointment: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ScheduleAppointmentMachine
        },
        ScheduleByConfiguration: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ScheduleByConfigurationMachine
        },
        ScheduleForward: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ScheduleForwardMachine
        },
        Agenda: { //user schedule
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...AgendaMachine
        },
        PrintPatientSchedule : {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...PrintPatientScheduleMachine
        },
        ExportFacilitySchedules: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...PrintPatientScheduleMachine
        },
        PatientEvaluation: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...PatientEvaluationMachine
        },
        FacilitySchedule: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...FacilityScheduleMachine
        },
        FacilityProviderSchedule: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...FacilityScheduleMachine
        },
        ManagePatients: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ManagePatientsMachine
        },
        ManageServices: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ManageServicesMachine
        },
        ManageUsers: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ManageUsersMachine
        },
        ManageAnnouncements: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ManageAnnouncementsMachine
        },
        ManageActivities: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ManageActivitiesMachine
        },
        ZeroMinuteSchedule: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ZeroMinuteSchedulesMachine
        },
        ManageGroups: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ManageGroupsMachine
        },
        Billing: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...ManageBillingMachine
        },
        AdminPanel: {
            on: {
                HOME: 'Home',
                REJECT: 'failure'
            },
            ...AdminPanelMachine
        },
        failure: {
            on: {
                RETRY: {
                    target: 'Home',
                    actions: assign({
                        retries: (context, event) => context.retries + 1
                    })
                }
            }
        },
    }
});

export default HomeMachine