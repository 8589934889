import React from 'react'

import dictionary from './en-us/values.json'


export class StringFlags { //flags, values must increment by powers of 2
    static Plural = 1
    static Capitalize = 2
};

export class StringResources {
    static patient(flags){
        return StringManager.getTitle(this.keys.patient, flags)
    }

    static get(key, flags){
        return StringManager.getTitle(key, flags)
    }
    
    static keys = {
        patient: 'patient'
    }
}

/**
 * Manages default definitions of strings, and gets the string from the backend data if supplied
 */
export default class StringManager{
    //dictionary from backend
    static titleReferences = null

    static injectBackendDictionary(facility){
        StringManager.titleReferences = facility.titleReferences
    }

    static getTitle(identifier, flags){
        let title = StringManager.titleReferences ? StringManager.titleReferences[identifier] : undefined
        if(!title) title = StringManager.getDefault(identifier)
        if(flags & StringFlags.Plural){ //Make it plural
            if(title.charAt(title.length - 1) == "y"){
                title = title.slice(0, title.length - 1)
                title += "ies" //ex. occupancy becomes occupancies
            }
            else{
                title+="s" //patient becomes patients
            }
        }
        if(flags & StringFlags.Capitalize) //caplitalize it
            title = StringManager.capitalize(title)
        return title
    }

    static getDefault(title){
        return dictionary[title]
    }

    static makePlural(title){
        if(title.charAt(title.length - 1) == "y"){
            title = title.splice(title.length - 1, 1)
            title += "ies" //ex. occupancy becomes occupancies
        }
        else{
            title+="s" //patient becomes patients
        }
        return title
    }

    static capitalize(title){
        return title.charAt(0).toUpperCase() + title.slice(1)
    }   
}