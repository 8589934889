import React from "react";
import NavigationHeader from "../../ui/NavigationHeader";
import "./Components/scheduleByAppointmentServiceOptions.css"
import SDEditTextBox from "../../ui/SDEditTextBox";
import errorIndicatorStyle from './Components/ErrorIndicatorAlternateTitles.module.css'
import ErrorIndicator from "../../ui/ErrorIndicator";

export default class EditAlternateTitlesUI extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            titles: Object.assign([],props.editingService.alternateTitles),
            errors: [] //index of which title has an error. Usually an issue where the title is a duplicate
        }
    }

    render(){
        return <>
            <NavigationHeader navigationBackTitle={`Edit Services`}
                              navigationOptionsTitles={["Cancel", "Apply"]}
                              onNewOption={this.onNewOption}
                              onNavigateBack={this.onBackPressed}
                              disableOptions={this.state.errors.length > 0 ? ["Apply"] : null}
                              title={`Edit Alternate Titles for ${this.props.editingService.name}`}/>
            <div align="center" style={{marginTop: '8px', marginBottom: '8px'}}>
                {this.renderTitles()}
                <button onClick={this.onNewTitle} className='attendee-roles-create-button'>ADD TITLE</button>
            </div>
        </>
    }

    renderTitles(){
        return this.state.titles.map((item, index)=>{
            return <div className="role-container" style={{justifyContent: 'center'}}>
                <SDEditTextBox label="TITLE" title={item} onChange={(textValue)=>{
                    let titles = this.state.titles
                    titles[index] = textValue
                    this.setState({titles: titles}, ()=>{
                        this.validateTitles()
                    })
                }}/>
                <img onClick={() => this.onDeleteTitle(index)} src={require('../../img/Icons/ic_delete_forever_blue.png')} className="delete-role" alt="Delete" />
                {this.state.errors.includes(index) ? (
                    <ErrorIndicator styles={errorIndicatorStyle} visible={true} reason="Title already exists. Please choose different title"/>
                ) : null}
            </div>
        })
    }

    validateTitles = () => {
        let errors = this.state.errors
        this.state.titles.forEach((title, index)=>{
            let error = !this.validateTitle(title, index)
            if(error){
                if(errors.includes(index)) return
                errors.push(index)
            }
            else{
                let errorIndex = errors.indexOf(index)
                if(errorIndex >= 0)
                    errors.splice(errors.indexOf(index))
            }
        })
        this.setState({errors: errors})
    }

    /**
     * Validate that this title is a unique name. This checks service name and alternate titles
     * @param title title to validate
     * @param index index of array being validated
     * @returns {boolean} true if unique, false otherwise
     */
    validateTitle = (title, index) => {
        //Check each service
        for(let service of this.props.facility.services){
            if(service.name.toLowerCase() === title.toLowerCase()){
                return false
            }

            //in each service, check its alternate titles
            if(service.alternateTitles){
                if(this.props.editingService.id === service.id) continue //but skip if we are currently editing it
                for(let alternateTitle of service.alternateTitles){
                    if(alternateTitle.toLowerCase() === title.toLowerCase()){
                        return false
                    }
                }
            }
        }
        //now let's check the list of titles we are currently editing
        for(let [i, alternateTitle] of this.state.titles.entries()){
            if(i === index) continue //no need to check itself for duplicating...
            if(alternateTitle.toLowerCase() === title.toLowerCase()){
                return false
            }
        }
        return true
    }

    onDeleteTitle = (index)=> {
        let titles = this.state.titles
        titles.splice(index, 1)
        this.setState({titles: titles}, ()=> {
            this.validateTitles()
        })
    }

    onNewTitle = () => {
        let titles = this.state.titles
        titles.push("")
        this.setState({titles: titles})
    }

    onBackPressed = ()=>{
        this.props.machine.send("CANCEL")
    }

    onNewOption = (option)=>{
        // eslint-disable-next-line default-case
        switch (option) {
            case "Cancel":
                this.props.machine.send("CANCEL")
                break;
            case "Apply":
                let titles = this.getTitles()
                if(this.props.onTitlesUpdated) this.props.onTitlesUpdated(titles)
                this.props.machine.send("NEXT")
                break;
        }
    }

    /**
     * Return only the valid titles. Any empty fields will be ignored
     * @returns {*}
     */
    getTitles = ()=> {
        let titles = this.state.titles.filter((title)=>{
            return title
        })
        return titles
    }
}

