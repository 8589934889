import React from 'react';
import LoadingSpinner from '../../util/LoadingSpinner'
import NavigationHeader from '../../ui/NavigationHeader';
import SDEditTextBox from '../../ui/SDEditTextBox';
import { Row, Col, Container, Button, Alert } from '../../../node_modules/react-bootstrap';
import Colors from '../../util/Colors';
import AdminManager from '../../managers/AdminManager';
import RolesDropdown from './Components/RolesDropdown';
import FacilityUtil from '../../util/FacilityUtil'
import Switch from '../../ui/Switch';
import { toast } from 'react-toastify';
import Helpers from '../../util/Helpers';
import { PhotoshopPicker } from 'react-color'
import './EditUserPage.css'


export default class EditUserPage extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      isNew: props.user == null,
      user: props.user != null ? JSON.parse(JSON.stringify(props.user)) : {
        schedulable: true
      },
      alert: {
        show: false,
        message: "",
        error: false
      },
      oldServices: [],
      selectedServices: [],
    }
  }

  componentWillMount(){
    if(this.props.user == null) return
    this.setState({
      loading: true
    })

    var services = []

    FacilityUtil.buildServiceProviderMap(this.props.facility.id, this.props.facility.services, true).then((data) => {

      Object.keys(data).forEach(k => {
        var list = data[k]
        list.forEach(p => {
          if(p == this.props.user.id) services.push(k)
        })
      })

      this.setState({
        loading: false,
        selectedServices: JSON.parse(JSON.stringify(services)),
        oldServices: JSON.parse(JSON.stringify(services))
      })
    })

  }

  render(){
    if(this.state.loading) return <LoadingSpinner/>
    var positiveOption = this.state.isNew ? "Create User" : "Save"
    return (
      <>
       <NavigationHeader navigationOptionsTitles={['Cancel', positiveOption]} navigationBackTitle={'Manage Users'} onNewOption={this.onNewAction} onNavigateBack={this.onNavigateBack}/>
       {this.drawForm()}
      </>
    )
   }

   updateField(field, v){
     var user = this.state.user
     user[field] = v
     this.setState({
       user: user
     })
  }

  onRoleChanged = (role) => {
    var user = this.state.user
    user.role = role
    this.setState({
      user: user
    })
  }

  updatePassword(v){
    this.setState({
      password: v
    })
  }

   drawForm(){
    var rowSpacing = 24
    var formWidth = 724

    var emailField = <Col><SDEditTextBox label="EMAIL" placeholder="Required Field" defaultValue={this.state.user.email} onChange={v => this.updateField("email", v)}/></Col>
    var passwordField = <Col><SDEditTextBox label="PASSWORD" placeholder="Required Field" hideText onChange={v => this.updatePassword(v)}/></Col>
  
    var firstNameField = <Col><SDEditTextBox label="FIRST NAME" defaultValue={this.state.user.firstName} placeholder="Required Field" onChange={v => this.updateField("firstName", v)}/></Col>
    var lastNameField = <Col><SDEditTextBox label="LAST NAME" defaultValue={this.state.user.lastName} placeholder="Required Field" onChange={v => this.updateField("lastName", v)}/></Col>

    var titleField = <Col><SDEditTextBox label="TITLE" defaultValue={this.state.user.title} placeholder="Required Field" onChange={v => this.updateField("title", v)}/></Col>

    var containerTag = 'role-dropdown-fallback'

    var role = this.state.user.role
    if(!role)
      containerTag+='-empty'
    var roleField = (
      <Col>
      <div className={containerTag}>
        <span className={`${containerTag}-title`}>Role</span>
        <RolesDropdown role={role} onRoleChanged={this.onRoleChanged} items={this.props.facility.roles} label="ROLE" defaultValue={this.state.user.role}/>
      </div>
      </Col>
    )

    var enableField = (
      <Col>
      <div className={containerTag}>
        <span className={`${containerTag}-title`}>Enable User</span>
        <div className="enable-user-switch">
            <Switch checked={this.props.facility.roles.includes(this.state.user.role)} onChange={this.onSwitchChanged}/>
          </div>
      </div>
      </Col>
    )

    var roleChangesText = (
      <Row>
        <Col>
        <div className="roles-text">
        Role changes may take up to 1 hour to take effect. This process can be sped up by asking users to log out and back in.
        </div>
        </Col>
      </Row>
    )


     return(
       <>
       <Container fluid style={{padding:0, marginTop: 80, width: formWidth}}>
        <Row noGutters={true} style={{marginBottom: rowSpacing}}>
        {this.state.isNew ? emailField : firstNameField}
        {this.state.isNew ? passwordField : emailField}
        </Row>

        <Row noGutters={true} style={{marginBottom: rowSpacing}}>
          {this.state.isNew ? firstNameField : lastNameField}
          {this.state.isNew ? lastNameField : titleField}
        </Row>
          
        <Row noGutters={true}>
          {this.state.isNew ? titleField : roleField}
          {this.state.isNew ? roleField : enableField}
        </Row>

        <Row noGutters={true}>
        <Col> {this.state.isNew ? null : roleChangesText} </Col>
        </Row>

        <Row noGutters={true}>
        {this.renderServiceColorPickerComponents()}
        </Row>

        <Row noGutters={true}>
          <Col className="services-text"> Select Services </Col>
        </Row>

        {this.drawServiceButtons(formWidth)}


       <Row><Col>{this.buildAlert()}</Col></Row>

        </Container>

       </>
     )
   }

   onSwitchChanged = (val) => {
     var user = this.state.user
     if(val.target.checked){
      if(!this.props.facility.roles.includes(user.role)) {
        toast("To re-enable user, please select a role")
      }
     }
     else{
      user.role = 'unused'
     }
     this.setState({
       user: user
     })
   }

   buildAlert() {
    var alert = this.state.alert
    var title = alert.error ? "Error" : "Success"
    var variant = alert.error ? "danger" : "success"

    if (alert.show) {
        var onDismiss = () => { this.setAlert(false, false, "") }

        return (
            <Alert variant={variant} onClose={onDismiss} dismissible>
                {<Alert.Heading>{title}</Alert.Heading> }
                <p>
                {alert.message}
                </p>
            </Alert>
        )
    }
    
    return null
}

   drawServiceButtons(formWidth){

    var rows = []
    var rowSpacing = 12
    var services = Helpers.sortServices(this.props.services, true, false)

    for(var i = 0; i < services.length; i += 4){
      rows.push(
        <Row noGutters={true} align='center' style={{marginBottom: rowSpacing}}>
          <Col> {this.drawServiceButton(services[i])} </Col>
          <Col> {this.drawServiceButton(services[i + 1])} </Col>
          <Col> {this.drawServiceButton(services[i + 2])} </Col>
          <Col> {this.drawServiceButton(services[i + 3])} </Col>
        </Row>
      )
    }

     return (
      <Container fluid style={{padding: 0, width: formWidth}}>
        {rows}
      </Container>
     )
   }

   drawServiceButton(service){

    if(service == null) return null

     var selected = this.state.selectedServices.includes(service.id)

     var bg = selected ? Colors.Primary.Main : Colors.NavGray
     var fc = selected ? Colors.Secondary.Light : Colors.MiddleGray
     var filter = selected ? "drop-shadow(0px 3px 6px " + Colors.DropShadow + ")" : ""

     return (
       <Button onClick={() => this.toggleButton(service)} 
       style={{
         border: "1px solid " + Colors.Steel, 
         filter: filter,
         color: fc, 
         backgroundColor: bg, 
         fontSize: 14, 
         width:169, 
         height:48}}
         >{service.name}</Button>
     )
   }

  renderServiceColorPickerComponents = () => {
    var colorPicker = this.state.colorPickerValue ? (
      <div className="colorPickerUser">
        <PhotoshopPicker
          color = {this.state.colorPickerValue}
          onChange={(newColor) => {
            this.setState({colorPickerValue: newColor.hex})
          }}
          onCancel={()=>{
            this.setState({colorPickerValue: null})
          }}
          onAccept={this.onUserColorChanged}
          direction={ 'horizontal' } />
      </div>
    ) : null
    var color = this.state.user.color
    var button = <div className='colorPickerUserButtonContainer'>
      <button className='colorPickerUserButton'
        style={{ backgroundColor: this.state.colorPickerValue ?? color ?? Colors.Black }}
        onClick={this.onColorPickerButtonClicked}>User Color</button>
    </div>
    return [button, colorPicker]
  }

  onColorPickerButtonClicked = ()=> {
    this.setState({colorPickerValue: this.state.user.color ?? Colors.Primary.Main})
  }

  onUserColorChanged = () => {
    this.state.user.color = this.state.colorPickerValue
    this.setState({user: this.state.user, colorPickerValue: null})
  }

   toggleButton = (service) => {
     var services = this.state.selectedServices
     if(services.includes(service.id)) services.splice(services.indexOf(service.id), 1)
     else services.push(service.id)
     this.setState({
       services: services
     })

   }

   onNewAction = (action) => {
     switch(action){
       case 'Cancel':
        this.onNavigateBack()
       return

       case 'Save':
       this.updateUser(this.state.user.id)
       return

       case 'Create User':
        this.createUserAccount()
       return
     }
   }

   setAlert(show, error, message) {
    this.setState({
        alert: {
            show: show,
            error: error,
            message: message
        },
        buttonEnabled: true,
        loading: false
    });
}

async createUserAccount() {
  this.setState({buttonEnabled: false, loading: true})
  
  // Form validation
  if (!this.state.user.email) return this.setAlert(true, true, "Email address required.")
  if (!this.state.password) return this.setAlert(true, true, "Password required.")
  if (this.state.password.length < 8) return this.setAlert(true, true, "Password must contain at least 8 characters.")
  if (!this.state.user.firstName) return this.setAlert(true, true, "First name required.")  
  if (!this.state.user.lastName) return this.setAlert(true, true, "Last name required.")  
  if (!this.state.user.title) return this.setAlert(true, true, "Title required.")  
  if (!this.state.user.role) return this.setAlert(true, true, "Role required.")  


  var existingUserResponse = await new Promise((resolve, _)=>{
      AdminManager.getExistingUser(this.state.user.email, (result)=>{
          resolve(result)
      })
  })
  console.log(existingUserResponse)
  if(existingUserResponse.statusCode === 200){
      //user already has an email in this user pool. Update the user, then add them to the facility
      console.log("update user")
      this.updateUser(existingUserResponse.data.Username)
  }
  else{
      //user does not already exist
      AdminManager.registerUser(
          this.state.user.email,
          this.state.password,
          this.state.user.firstName,
          this.state.user.lastName,
          this.state.user.title,
          this.state.user.role,
          this.state.user.color,
          true, //schedulable
          this.state.selectedServices,
          this.props.facility.id,
          result => {
            console.log(JSON.stringify(result))
            this.props.machine.send("SAVE");
            this.props.reload()
          }
      )
  }
}

   updateUser(userSub){
    this.setState({buttonEnabled: false, loading: true})

    // Form validation
    if (!this.state.user.email) return this.setAlert(true, true, "Email address required.")
    if (!this.state.user.firstName) return this.setAlert(true, true, "First name required.")  
    if (!this.state.user.lastName) return this.setAlert(true, true, "Last name required.")  
    if (!this.state.user.title) return this.setAlert(true, true, "Title required.")  

    AdminManager.updateUser(
        userSub,
        this.state.user.email,
        this.state.user.firstName,
        this.state.user.lastName,
        this.state.user.title,
        this.state.user.role,
        this.state.user.color ?? null,
        true, //schedulable
        this.state.oldServices,
        this.state.selectedServices,
        this.props.facility.id,
        result => {
          this.props.machine.send("SAVE");
          this.props.reload()
        }
    )
}

   onNavigateBack = () => {
     this.props.machine.send("CANCEL")
   }


}