import React from 'react';
import { Row, Col, Container, Form, Button, Card, Alert, Modal, ToggleButton, ToggleButtonGroup, FormControl, InputGroup } from 'react-bootstrap';
import FacilityManager from '../../managers/FacilityManager';
import LoadingSpinner from '../../util/LoadingSpinner'
import TimeRestrictionCalendar from '../../Calendars/TimeRestrictionCalendar';
import CacheManager from '../../managers/CacheManager';
import { toast } from 'react-toastify';
import ProvidersDropdown from '../../util/ProvidersDropdown';
import FacilityUtil from '../../util/FacilityUtil';
import CalendarUtil from '../../util/CalendarUtil';
import Helpers from '../../util/Helpers';
import SDMultiSelect from '../../util/SDMultiSelect';

//TODO page is resource intensive. 10FPS when scrolling on 4c/8t 4.2-4.5Ggz, Should we paginate/break up by service?
export default class ManageProviderRestrictionsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true, 
            providers: {},
            sortedProviderIds: [],
            providerSaving: {},
            filteredServices: [],
            query: null,
            visibleProviders: [],
            serviceProvidersMap: {},
            serviceMap: {},
            blockedTimes: {} //map of providers, where the key is providerId, and the value is an array of times
        }
        this.getProviders()
    }

    getProviders = async() => {
        this.setState({loading: true})
        await CacheManager.get().fetchSkilledDayData(this.props.facility.id, true, false, false) //updates the props.facility if something changed
        let serviceProvidersMap = await FacilityUtil.buildServiceProviderMap(this.props.facility.id, this.props.facility.services, false) //grab our providers from here for our fallback providers
        var providers = {}
        Object.values(this.props.facility.providers).forEach(provider => {
            provider = JSON.parse(JSON.stringify(provider)) //create a copy of the object
            if(provider.schedulable && provider.role !== "unused"){
                providers[provider.id] = provider
            }
        });
        this.state.providerServiceMap = this.mapServicesForProviders(providers, serviceProvidersMap)
        this.state.providers = providers
        this.state.serviceProvidersMap = serviceProvidersMap
        this.state.serviceMap = this.getServicesMap(this.props.facility.services)
        var sortedIds = Object.keys(providers)
        sortedIds.sort((a, b) => {
            return (this.getProviderName(a) > this.getProviderName(b)) ? 1 : -1
        })
        this.setState({loading: false, providers: providers, sortedProviderIds: sortedIds, visibleProviders: Object.keys(providers)})
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>
        
        var state = this.props.machine.state.value['ScheduleForward']
        switch(state){
            case 'ProviderAvailability':
                return(
                    <Container className="mt-3">
                        <Row className="justify-content-md-center">
                            <Col sm="12" md="6" md-offset="4">
                                NOTE: Available check does not reset each day, and only applies to schedule forward
                                <br/>
                                Changes will save automatically
                                {this.drawEditText()}
                                {this.drawServiceMultiSelect()}
                                {this.renderCards()}
                            </Col>
                        </Row>
                    </Container>
                )
        }
        return [
            <LoadingSpinner/>,
            <LoadingSpinner/>,
            <LoadingSpinner/>
        ]
    }

    getProviderName = (providerId) => {
        var provider = this.state.providers[providerId]
        return `${provider.firstName} ${provider.lastName}`
    }

    renderCards() {
        return (
            this.state.sortedProviderIds.map((providerId, index)=>{
                return this.renderProvider(this.state.providers[providerId])
            })
        )
    }

    updateProviderAvailable = (providerId, available)=> {
        var providerSaving = this.state.providerSaving
        providerSaving[providerId] = true
        this.setState({providerSaving: providerSaving})
        FacilityManager.setProviderRestrictions(this.props.facility.id, providerId, available, (result)=>{
            if(result.statusCode !== 200){
                toast("Error saving provider")
            }
            var providerSaving = this.state.providerSaving
            providerSaving[providerId] = false
            this.setState({providerSaving: providerSaving})
        })
    }

    updateProviderFallback = (provider, serviceId, fallbackProviderId) => {
        var providerId = provider.id
        var providerSaving = this.state.providerSaving
        providerSaving[providerId] = true
        this.setState({providerSaving: providerSaving})
        FacilityManager.setServiceProviderFallback(this.props.facility.id, providerId, serviceId, fallbackProviderId, (result)=>{
            if(result.statusCode !== 200){
                provider.fallbackProviderId = null
                toast("Error saving provider")
            }
            var providerSaving = this.state.providerSaving
            providerSaving[providerId] = false
            this.setState({providerSaving: providerSaving})
        })
    }

    renderProvider(provider){
        var providerAvailable = true
        if(provider.available === false) //explicitly check for false, as we want undefined and null to make this value true
            providerAvailable = false

        var providerServiceIds = this.state.providerServiceMap[provider.id]
        if(this.shouldFilterOut(provider, providerServiceIds)) return null

        var checkBox = (
            <Form.Check style={{width: 140}} checked={providerAvailable} type="checkbox" label="Available" onChange={()=>{
                provider.available = !providerAvailable
                this.state.providers[provider.id] = provider
                this.setState({providers: this.state.providers})
                this.updateProviderAvailable(provider.id, provider.available)
            }}/>
        )
        var button = (
            <Button variant='light' style={{borderColor:'#000000', width: 140}} onClick={()=>{
                // put provider info into format expected by ScheduleAppointmentPage
                const formattedProvider = {
                    id : provider.id,
                    name: `${provider.firstName} ${provider.lastName}`
                }
                this.scheduleBlockingAppointment(formattedProvider, this.props.anchorDate)
            }}>
                Schedule Blocking
            </Button>
        )
        var dropdown = this.renderDropdown(provider, providerServiceIds)
        var service = null //TODO?? Get Color for background.
        var bgColor = service ? service.color : "#ffffff" //for now give it a blank color
        var loadingSpinner = this.state.providerSaving[provider.id] ? <LoadingSpinner size={30} marginTop={8}/> : null
        return (
            <Card style={{backgroundColor: bgColor, margin: 10}}>
                <Card.Body style={{marginLeft: 10, marginRight: 10}}>
                    <Col>
                        <Row>
                            <Col>
                                <Row className="justify-content-md-start">
                                    {this.getProviderName(provider.id)}
                                </Row>
                                <Row style={{fontSize: 12}} className="justify-content-md-start">
                                    {provider.title}
                                </Row>
                                <Row className="justify-content-md-start">
                                    {checkBox}
                                </Row>
                            </Col>
                            <Col>
                                <Row className="justify-content-md-end">
                                    {button}
                                </Row>
                                <Row style={{height: 38}} className="justify-content-md-end">
                                    {loadingSpinner}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {dropdown}
                </Card.Body>
            </Card>
        )
    }

    drawServiceMultiSelect(){
        let services = Helpers.sortServices(
          this.props.facility.services, 
          /*Copy*/true, 
          /*showDisabled*/false, 
          /**sortDisabledAtBottom */false, 
          /*filterSchedulable*/ true
        ).filter((service)=>{
            return service.pickProvider && !service.providerOnly //require service to contain a provider AND an occupancy
        })
    
        return (
          <>
            <SDMultiSelect
                style={{marginBottom: `1rem`, marginRight: 0, marginLeft: 0}}
                defaultValue="Select Services..."
                allItems={services}
                labelRenderer={(service)=>service.name}
                valueRenderer={(service)=>service.id}
                filteredItems={this.state.filteredServices}
                onSelectionsChanged={this.onServiceSelectionsChanged}/>
          </>
        )
    }

    drawEditText(){
        return (
            <Row style={{marginTop: 8, marginRight: 0, marginLeft: 0}}>
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">Search</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                        type='text'
                        name='provider'
                        defaultValue={this.state.query}
                        onChange={this.handleFilterChange}
                    />
                </InputGroup>
              </Col>
            </Row>
        )
    }

    shouldFilterOut = (provider, serviceIds) => {
        //check services first to see if they are in the list...
        var matches = false
        if(Array.isArray(serviceIds) && serviceIds.length > 0){
            serviceIds.forEach((serviceId)=>{
                if(!this.state.serviceMap[serviceId]) return //This service was not processed
                matches = matches || !this.state.filteredServices.includes(serviceId)
            })
        }
        var visible = matches && this.state.visibleProviders.includes(provider.id)
        return !visible
    }

    handleFilterChange = (event) => {
        var filter = event.target.value.toLowerCase();
        var visibleProviders = []
  
        if (filter.length === 0) {
            this.setState({
                query: filter,
                visibleProviders: Object.keys(this.state.providers)
            })
        }
        else {  
            var providers = Object.values(this.state.providers)
            for (var i = 0; i < providers.length; i++) {
                var provider = providers[i];
    
                if (provider.firstName.toLowerCase().startsWith(filter) || 
                    provider.lastName.toLowerCase().startsWith(filter) || 
                    Helpers.getProviderFullName(provider).toLowerCase().startsWith(filter) ||
                    (provider.title && provider.title.toLowerCase().startsWith(filter))) {
                        visibleProviders.push(provider.id);
                }
            }
            this.setState({
                query: filter,
                visibleProviders: visibleProviders
            })
        }
    }
    
    onServiceSelectionsChanged = (filteredItems) => {
        this.setState({
            filteredServices: filteredItems
        })
    }

    scheduleBlockingAppointment = (provider, anchorDate)=> {
        var dateRange = CalendarUtil.getDayTimeRange(new Date(anchorDate))
        var appointment = {
            providers: [provider],
            serviceId: this.props.facility.unavailableServiceId,
            title: "Provider Unavailable",
            start: dateRange.start,
            end: dateRange.end,
            previousState: {
                name: 'SELECT_SCHEDULE_FORWARD.PROVIDER_AVAILABILITY_SELECTED',
                data: {}
            }
        }
        this.props.machine.send("HOME")
        this.props.onEditAppointment(appointment)
    }

    renderDropdown = (provider, serviceIds)=> {
        var providerId = provider.id
        //get a list of services for the provider
        if(!serviceIds || serviceIds.length === 0) return null
        //return a dropdown for each service... 
        //Can't really do one, because there may be no providers that are shared between multiple services this one may have
        return serviceIds.map((serviceId)=>{
            let service = this.state.serviceMap[serviceId]
            if(!service || service.disabled || !service.schedulable || !service.scheduleForward) return null
            //get a list of providers for a given service, and pass it to the dropdown
            let providers = [{
                firstName: "\!None",
                lastName: "",
                id: null
            }]
            providers = providers.concat(this.state.serviceProvidersMap[serviceId])
            //need to use our provider from this list specifically
            let serviceProvider
            providers.forEach((theirProvider)=>{
                if(theirProvider.id === provider.id){
                    serviceProvider = theirProvider
                }
            })
            //and then remove our provider from said list
            providers = providers.filter((a)=>{
                return a.id !== serviceProvider.id
            })
            return this.renderSingleProviderDropdown(serviceProvider, service, providers)
        })
    }

    renderSingleProviderDropdown = (provider, service, providers) => {
        return (
            <div>
                <div align="end">Assign {service.name} Appointments to</div>
                <ProvidersDropdown id={provider.id} align="end" viewingId={provider.fallbackProviderId} providers={providers} onLoadNewAgenda={(fallbackProvider)=>{
                    this.onSelectFallbackProvider(provider, service.id, fallbackProvider)
                }}/>
            </div>
        )
    }

    onSelectFallbackProvider = (provider, serviceId, fallbackProviderId) => {
        provider.fallbackProviderId = fallbackProviderId
        this.setState({providerServiceMap: this.state.providerServiceMap})
        this.updateProviderFallback(provider, serviceId, fallbackProviderId)
    }

    mapServicesForProviders = (providers, serviceProvidersMap)=> {
        //desired output - Map<providerId, serviceId[]> from serviceProviders, which is Map<serviceId, provider[]>
        let providerServicesMap = {} //init
        let providerIds = Object.keys(providers)
        Object.keys(serviceProvidersMap).forEach((serviceId)=>{ //iterate through the serviceProvidersMap
            let serviceProviderList = serviceProvidersMap[serviceId]
            serviceProviderList.forEach((serviceProvider) => { //iterate through its list of providers.
                let providerId = serviceProvider.id

                //filter out any providers we don't already have locally
                if(!providerIds.includes(providerId))
                    return

                //init array if needed
                if(!providerServicesMap[providerId])
                    providerServicesMap[providerId] = [] 

                //push to array, only if it does not already exist in it
                if(!providerServicesMap[providerId].includes(serviceId))
                    providerServicesMap[providerId].push(serviceId) 
            })
        })
        console.log(providerServicesMap)
        return providerServicesMap
    }

    getServicesMap = (services) => {
        let servicesFiltered = Helpers.sortServices(
            services, 
            /*Copy*/true, 
            /*showDisabled*/false, 
            /**sortDisabledAtBottom */false, 
            /*filterSchedulable*/ true
        ).filter((service)=>{
            return service.pickProvider && !service.providerOnly //require service to contain a provider AND an occupancy
        })
        let serviceMap = {} //Map<serviceId, service>
        servicesFiltered.forEach((service)=>{
            serviceMap[service.id] = service
        })
        return serviceMap
    }
}
