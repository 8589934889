import React from 'react';
import { Row, Col, Container, Form, Button, Card, Alert, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Colors from '../../util/Colors';
import NetworkManager from '../../managers/NetworkManager';
import { Endpoint } from '../../util/Constants';
import FacilityManager from '../../managers/FacilityManager';
import LoadingSpinner from '../../util/LoadingSpinner'
import RoomOccupancyDropdown from '../../util/RoomOccupancyDropdown';
import CalendarUtil from '../../util/CalendarUtil'
import AutoScheduler from '../../managers/AutoScheduler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateAndTime from '../../util/DateAndTime';

export default class AutoSchedulerPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            config: [],
            loadingArr: {},
            loading: true, 
            providers: [],
            rooms: [],
            services: [],
            selectedOccupancy: null,
            anchorDate: new Date()
        }
        this.getFacilityData()
    }

    addConfig = () => {
        this.state.config.push(
            {
                "requirePrimary": true,
                "require2fer": false,
                "duration2fer": null,
                "facilityId": this.props.facility.id,
                "occupancyId": this.state.selectedOccupancy,
                "duration": 60,
                "serviceId": null
            }
        )
        this.setState({config: this.state.config})
    }
    
    getConfig(facilityId, occupancyId){
        FacilityManager.getOccupancyServiceConfigs(facilityId, occupancyId, (serviceConfig) => {
            serviceConfig.sort((a, b)=> {
                var serviceA = this.state.services[a.serviceId]
                var serviceB = this.state.services[b.serviceId]
                if(serviceA.name === serviceB.name){ //if name matches
                    if(a.duration === b.duration){ //if duration matches
                        return a.configId > b.configId ? 1 : -1 //sort by configId, which is random letters and numbers, but will remain constant while editing
                    }
                    return a.duration > b.duration ? 1 : -1 //else sort by config duration
                }
                return serviceA.name > serviceB.name ? 1 : -1 //else sort by name
            })
            this.setState({
                loading: false,
                config: serviceConfig
            })
        })
    }

    getProviderOptions(){
        return(Object.keys(this.state.providers).map((k) => {
            var v = this.state.providers[k]
            return <option value={k}>{v}</option>
        }))
    }

    getFacilityData() {
        this.setState({loading: true})
        FacilityManager.getFacility(this.props.facility.id, true, true, true, (data)=>{
            var servicesLookup = {}
            this.props.facility.services.forEach((service)=>{
                servicesLookup[service.id] = service
            })
            this.setState({loading: false, facility: data, services: servicesLookup})
        })
    }

    getServiceOptions(){
        var services = []
        this.state.facility.services.forEach(service => {
            if(service.scheduleByConfiguration && service.pickProvider && !service.disabled)
                services.push(service)
        });
        services.sort((a, b)=>{
            return (a>b)?1:-1
        })
        return (
            <>
                <option value={null}></option>
                {services.map((service)=>{
                    return (<option value={service.id}>{service.name}</option>)
                })}
            </>
        )
    }

    onLoadNewConfig = (occupancyId) => {
        this.setState({selectedOccupancy: occupancyId, loading: true})
        this.getConfig(this.props.facility.id, occupancyId)
    }

    clickedDeleteAllAppointments = () => {

        var d = CalendarUtil.getDate(this.state.anchorDate)

        if(!window.confirm("Delete all appointments for " + d.monthString + " " + d.dayOfMonth + "?")) return

        this.setState({loading: true})

        let path = Endpoint.base + "/appointments/cancel-range";
        let dayTimeRange = CalendarUtil.getDayTimeRange(this.state.anchorDate)
        let params = {
            facilityId: this.props.facility.id,
            start: dayTimeRange.start.toString(),
            end: dayTimeRange.end.toString(),
            configIdsOnly: true
        }

        NetworkManager.post(path, params, (result) => {
            if(Array.isArray(result)){
                console.log(result)
                this.setState({loading: false})
                toast("Today's autoscheduler appointments have been deleted")
            }
            else{
                toast('Taking longer than usual. Will be done in at least 30 seconds')
                this.setState({loading: false})
            }
        })
    }

    moveAnchorDate(days){
        var newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() + days))
      
        this.setState({
            anchorDate: newAnchor,
        })
    }

      drawPreviousDayArrow(){

        if(CalendarUtil.areDatesTheSameDay(this.state.anchorDate, new Date())) return null

        return(
            <div style={{position:"absolute", top:-6, left:"10px", fontSize:24, color: Colors.Primary.Main}}>
            <a onClick={() => this.moveAnchorDate(-1)} style={{cursor:"pointer", userSelect:"none"}}>
              &lt;
            </a>
            </div>
        )
      }

      drawNextDayArrow(){
          return(
            <div style={{position:"absolute", top:-6, right:"10px", fontSize:24, color: Colors.Primary.Main}}>
            <a onClick={() => this.moveAnchorDate(1)} style={{cursor:"pointer", userSelect:"none"}}>
              &gt;
            </a>
            </div>
          )
      }

    render(){
        if(this.state.loading) return <LoadingSpinner/>
        return (
            <>
                <div style={{textAlign:"center"}}> 
                <DateAndTime anchor={this.state.anchorDate} dateOnly={true}/> 
                </div>

                {this.drawPreviousDayArrow()}
                {this.drawNextDayArrow()}

                {/* TODO - REMOVE BEFORE MERGING INTO PROD */}
                <Button variant="light" onClick={this.runAutoSchedulerTest} style={{borderColor:"#000000", width: 300, marginTop: 15}}>Run Auto Scheduler</Button><br/>
                <Button variant="danger" onClick={this.clickedDeleteAllAppointments} style={{borderColor:"#000000", width: 300, marginTop: 15}}>Delete today's appointments</Button><br/>
                {/* TODO - REMOVE BEFORE MERGING INTO PROD */}

                <Container style={{margin: 10}}>
                    <Row>
                        <Col>
                            <RoomOccupancyDropdown viewingId={this.state.selectedOccupancy} rooms={this.state.facility.rooms} onLoadNewConfig={this.onLoadNewConfig}/>
                        </Col>
                    </Row>
                </Container>
                {this.renderCards()}
            </>
        )
    }

    runAutoSchedulerTest = () => {
        this.setState({
            loading: true
        })

        //returns final configs
        //TODO: check if any config has scheduled == false
        AutoScheduler.run(this.state.facility, this.state.anchorDate, (configs) => {
            this.props.onScheduleFinished(this.state.anchorDate)
        }, (error)=>{
            toast(error, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
            })
        })
    }


    renderCards() {
        if(this.state.loading) return <LoadingSpinner/>
        return (
            <Card>
                <Card.Header>
                    Configure Auto Scheduling
                </Card.Header>
                <Card.Body>
                    {this.state.config.map((item, index)=>{
                        if(!item.serviceId){
                            return this.renderServicePicker(index)
                        }
                        return this.renderConfig(item, index)
                    })}
                    <Button onClick={this.addConfig}>+</Button>
                </Card.Body>
          </Card>
        )
    }

    renderServicePicker(index){
        return (
            <Card>
                <Card.Header>
                    Add a service
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} className="mb-2">
                                <Form.Label>Service</Form.Label>
                                <Form.Control as="select" value={null} onChange={(serviceId)=>{ this.onServiceChange(serviceId, index) }}>
                                    {this.getServiceOptions()}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Card.Body>
            </Card>
        )
    }

    getErrorMessage = (status, item) => {
        if(status.error.expected && status.error.actual){
            //something is wrong with the format. Assume probably checked 2fer with no duration, but check for it anyways
            if(item.require2fer && !item.duration2fer){
                return "Please set a 2fer duration to save changes"
            }
            if(isNaN(item.duration)){
                return "Please set a duration"
            }
            console.error(JSON.stringify(status.error))
            return "Unknown validation error"
        }
        return JSON.stringify(status.error)
    }

    renderConfig(item, index){
        var saveConfig = () => {
            this.saveConfig(index)
        }
        var onRequirePrimaryProviderChange = () => {
            this.onRequirePrimaryProviderChange(!item.requirePrimary, index)
            saveConfig()
        }
        var onDurationChange = (provider) => {
            this.onDurationChange(provider, index)
            saveConfig()
        }
        var onRequire2ferChange = () => {
            this.onRequire2ferChange(!item.require2fer, index)
            saveConfig()
        }
        var on2ferDurationChange = (provider) => {
            this.on2ferDurationChange(provider, index)
            saveConfig()
        }

        var onConfigRemove = () => {
            this.onConfigRemove(index)
        }

        var status = this.state.loadingArr[index]
        var indicator = null
        if(status){
            indicator = (status.loading) ? (<LoadingSpinner size="20" marginTop="0"/>) : 
                (status.error) ? (this.getErrorMessage(status, item)) : null
        }
        var body = (
            <Form style={{padding: 10}}>
                <Form.Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Duration (minutes)</Form.Label>
                        <Form.Control type="number" value={item.duration} onChange={onDurationChange}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Check checked={item.requirePrimary} type="checkbox" label="Schedule primary if possible" onChange={onRequirePrimaryProviderChange}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>2fer</Form.Label>
                        <Form.Check checked={item.require2fer} type="checkbox" label="2fer" onChange={onRequire2ferChange}/>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>2fer Duration (minutes)</Form.Label>
                        <Form.Control enabled={item.require2fer} type="number" value={item.duration2fer} onChange={on2ferDurationChange}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row style={{height:20}}>
                    {indicator}
                </Form.Row>
            </Form>
        )
        var service = this.state.services[item.serviceId]
        var bgColor = service ? service.color : "#ffffff"
        return (
            <Card style={{backgroundColor: bgColor, margin: 10}}>
                <Card.Body>
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            {this.state.services[item.serviceId].name}
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            
                        </Form.Group>
                    </Row>
                    <Button style={{"background-color": "Transparent", "border":"none", position:"absolute", right: 5, top: 5}} variant="outline-light" onClick={onConfigRemove}><span style={{color: "white"}} className="oi oi-x"/></Button>
                    {body}
                </Card.Body>
            </Card>
        )
    }

    onRequirePrimaryProviderChange = (requirePrimary, index) => {
        console.log(requirePrimary)
        var config = this.state.config[index]
        config.requirePrimary = requirePrimary
        this.state.config[index] = config

        this.setState({
            config: this.state.config
        })
    }

    onDurationChange = (duration, index) =>{
        var config = this.state.config[index]
        config.duration = parseInt(duration.target.value)
        if(config.duration < 0) config.duration = 0
        this.state.config[index] = config

        this.setState({
            config: this.state.config
        }, () => this.forceUpdate())
    }

    onRequire2ferChange = (require2fer, index) => {
        var config = this.state.config[index]
        config.require2fer = require2fer
        this.state.config[index] = config

        this.setState({
            config: this.state.config
        })
    }

    on2ferDurationChange = (duration, index) =>{
        var config = this.state.config[index]
        config.duration2fer = parseInt(duration.target.value)
        if(config.duration2fer < 0) config.duration2fer = 0
        this.state.config[index] = config

        this.setState({
            config: this.state.config
        }, () => this.forceUpdate())
    }

    onServiceChange = (serviceId, index) => {
        var config = this.state.config[index]
        config.serviceId = serviceId.target.value;
        this.state.config[index] = config
        this.saveConfig(index)
    }

    onConfigRemove = (index) => {
        var config = this.state.config[index]
        if(config.configId){ //from the back-end
            this.setState({loading: true})
            FacilityManager.removeOccupancyServiceConfig(this.props.facility.id, this.state.selectedOccupancy, config.configId, (result)=>{
                if(!result.error){
                    this.getConfig(this.props.facility.id, this.state.selectedOccupancy)
                }
                else{
                    console.error(result.error)
                    this.setState({loading: false})
                }
            })
        }
        this.state.config.splice(index, 1)
        this.setState({config: this.state.config})
    }

    saveConfig = (index) => {
        this.state.loadingArr[index] = {loading: true, savedTime: null, error: null}
        this.setState({loadingArr: this.state.loadingArr})
        var config = this.state.config[index]
        if(config.facilityOccupancyId){
            var ids = config.facilityOccupancyId.split('-')
            config.facilityId = ids[0]
            config.occupancyId = ids[1]
            delete config.facilityOccupancyId
        }
        FacilityManager.addOccupancyServiceConfig(config, (result)=>{
            if(!result.error){
                this.state.config[index].configId = result.configId //We do this so we don't have to refresh the whole page, and this won't duplicate on next save
                this.state.loadingArr[index] = {loading: false, savedTime: new Date().getTime()}
                this.setState({loadingArr: this.state.loadingArr, config: this.state.config})
            }
            else{
                this.state.loadingArr[index] = {loading: false, savedTime: null, error: result.error}
                console.error(result.error)
                this.setState({loadingArr: this.state.loadingArr})
            }
        })
    }
}
