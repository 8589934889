import React from 'react';
import SelectProviderPage from '../ScheduleAppointment/SelectProviderPage';
import LoadingSpinner from '../../util/LoadingSpinner'
import Tracker from '../../managers/Tracker'
import EditUserPage from './EditUserPage'
import Colors from '../../util/Colors';
import ProviderManager from '../../managers/ProviderManager';
import { toast } from 'react-toastify';
import ConfigManager from '../../managers/ConfigManager';
import PromisifyCallback from '../../util/PromisifyCallback';

const showDisabledPrefKey = 'manage-providers.show-disabled'
const listProvidersPromise = new PromisifyCallback(ProviderManager.listProviders)
const getShowDisabledPref = new PromisifyCallback(ConfigManager.getTargetedFacilityUserConfig)
export default class ManageUsersPage extends React.Component {

  constructor(props){
    Tracker.logScreenView('manage_providers_list')

    super(props)
    this.state = {
      loading: true,
      provider: null,
      providers: null
    }

    document.body.style = 'background:' + Colors.Secondary.Light + ';';
    window.scrollTo(0, 0)
    this.getManageUsersData()
  }

  reload = () => {
    this.setState(
      {
        loading: true
      }
    )
    this.getManageUsersData()
  }

  render(){
    const state = this.props.machine.state.value["ManageUsers"]
    
    if(this.state.loading) return <LoadingSpinner/>

    switch(state){
      case "Load": 
      return <LoadingSpinner/>

      case "ListUsers":
      return <SelectProviderPage showNewProviderOption={true} onSelectProvider={this.onProviderSelected} machine={this.props.machine} isEdit={true} user={this.props.user} providers={Object.values(this.state.providers)} reload={this.reload} showDisabledToggle={true} showDisabled={this.state.showDisabled} onShowDisabledToggle={this.onShowDisabledToggle}/>
      
      case "CreateUser":
      case "EditUser":
      return <EditUserPage facility={this.props.facility} serviceProvidersDict={this.props.serviceProvidersDict} services={this.props.services} machine={this.props.machine} user={this.state.provider} reload={this.reload}/>

    }
    return null
  }

  onShowDisabledToggle = (newState) => {
    this.setState({showDisabled: newState})
    ConfigManager.updateTargetedFacilityUserConfig(this.props.facility.id, showDisabledPrefKey, newState, (result)=>{
      if(result.statusCode !== 200){
        console.error("Unable to save preference")
      }
    })
  }

  onProviderSelected = (providers) => {
    this.setState({
      provider: providers[0]
    })
    if(providers[0] != null) this.props.machine.send("EDIT")
    else this.props.machine.send("CREATE")
  }

  getManageUsersData = async() => {
    var result = await listProvidersPromise.invoke(this.props.facility.id, null, null, null)
    if(result.statusCode && result.statusCode !== 200){
      toast("Unable to fetch users")
      this.props.machine.send("HOME")
      return
    }
    var showDisabled = false
    var disabledPref = await getShowDisabledPref.invoke(this.props.facility.id, this.props.user.creatorId, showDisabledPrefKey)
    console.log(disabledPref)
    if(disabledPref === true){
      showDisabled = true
    } //else could have failed, or value is false

    this.setState({
      showDisabled: showDisabled,
      providers: result,
      loading: false
    })
  }
}