import React from "react";
import Colors from "../util/Colors";
import {Card} from "react-bootstrap";

export default class SDButton extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            color: Colors.NavGray
        }
    }

    onClick = () => {
        this.props.onSelectItem(this.props.item)
    }

    onMouseOver = () => {
        this.setState({
            color: Colors.Highlight
        })
    }

    onMouseLeave = () => {
        this.setState({
            color: Colors.NavGray
        })
    }

    getStyle(){
        return{
            width:"300px",
            display:"block",
            marginLeft:"auto",
            marginRight:"auto",
            backgroundColor:this.state.color
        }
    }


    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <Card style={this.getStyle()}>
                            <a style={{ cursor: "pointer" }} onClick={this.onClick} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                                <Card.Body>
                                    <Card.Title variant="primary" style={{fontSize:"12px", textAlign:"center"}}>{this.props.title}</Card.Title>
                                    {this.props.subTitle ? (
                                        <footer style={{fontSize:"10px", textAlign:"center"}}>{this.props.subTitle}</footer>
                                    ) : null}
                                </Card.Body>
                            </a>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}