import React from 'react'
import LoadingSpinner from '../../util/LoadingSpinner';
import SelectServiceList from './SelectServiceList';

import './manageServices.css'
import EditServiceUI from './EditServiceUI';
import FacilityManager from '../../managers/FacilityManager';
import ServiceManager from '../../managers/ServiceManager';
import Colors from '../../util/Colors';
import FacilityUtil from '../../util/FacilityUtil';
import SelectProviderPage from '../ScheduleAppointment/SelectProviderPage';
import EditAlternateTitlesUI from './EditAlternateTitlesUI'

export default class ManageServices extends React.Component{
    constructor(props){
        super(props)
        this.state = {}
        props.machine.send("RESOLVE")
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>
        var state = this.props.machine.state.value["ManageServices"]
        switch(state){
            case "Load":
            case "LoadUsers":
                return <LoadingSpinner/>
            case "ListServices":
                return <SelectServiceList facility={this.props.facility} onNewService={this.createServiceAndEdit} onEditService={this.editService}/>
            case "CreateService":
            case "EditService":
                return <EditServiceUI machine={this.props.machine} facility={this.props.facility} editingService={this.state.editingService} onSaveService={this.saveService} providers={this.state.providers} onAssignPrimaries={this.onAssignPrimaries}/>
            case "EditTitles":
                return <EditAlternateTitlesUI machine={this.props.machine} facility={this.props.facility} editingService={this.state.editingService} onTitlesUpdated={this.onEditAlternativeTitles}/>
            case "PickUsers":
                var providers = this.state.showAllProviders ? this.props.facility.providers : this.state.providers        
                var filteredProviders = []
                Object.values(providers).forEach((provider)=>{
                    if(provider.role === 'unused') return
                    filteredProviders.push(provider)
                })
                return [
                    <SelectProviderPage 
                        bypassLengthCheck={true} 
                        editing={false} 
                        machine={this.props.machine} 
                        facility={this.props.facility} 
                        providers={Object.values(providers)} 
                        onSelectProvider={this.onPrimariesUpdated} 
                        selection={this.state.editingService.primaries} 
                        user={this.props.user}
                        nextTitle={"Apply"}
                        title={`Select ${this.state.pickProviderType} for ${this.state.editingService.name??"Service"}`}/>
                ]
        }
    }

    createServiceAndEdit = ()=> {
        var defaultColor = Colors.Primary.Main
        var service = {
            scheduleBy: 'appointment',
            scheduleStyle: "Day",
            pickProvider: true,
            pickLocation: true,
            ignoreTimeRestrictions: false,
            therapy: false,
            schedulable: true,
            color: defaultColor,
            providers: [],
            conflicts: false
        }
        this.setState({editingService: service, providers: []})
        this.props.machine.send("CREATE")
    }

    editService = (serviceId) => {
        let service = this.props.facility.services.find((s) => s.id === serviceId)
        let editService = JSON.parse(JSON.stringify(service))
        this.setState({editingService: editService, providers: []})
        this.getProvidersForService(editService.id)
        this.props.machine.send("EDIT")
    }

    getProvidersForService = (serviceId)=> {
        this.setState({loading: true, providers: []})
        FacilityUtil.getProviderServices(this.props.facility.id, serviceId, false).then((data)=>{
            let providersMap = {}
            data.providers.forEach((provider)=>{
                if(provider.role === "unused") return
                providersMap[provider.id] = provider
            })
            this.setState({loading: false, providers: providersMap})
        })
    }

    onAssignPrimaries = () => {
        this.props.machine.send("EDIT_PRIMARIES")
        this.setState({showAllProviders: false, pickProviderType: "primaries"})
    }

    onPrimariesUpdated = (providers) => {
        this.state.editingService.primaries = providers
        this.state.editingService.usePrimary = providers.length > 0
        this.setState({editingService: this.state.editingService})
    }

    onCancelSelectProviders = () => {
        this.props.machine.send("CANCEL")
        this.setState({showAllProviders: undefined})
    }

    onEditAlternativeTitles = (titles)=> {
        this.state.editingService.alternateTitles = titles
        this.setState({editingService: this.state.editingService})
    }

    onProviderAction = (option)=> {
        switch (option) {
            case "Apply":
                this.props.machine.send("NEXT")
                break;
            case "Reset":
                
                break;
        }
    }

    saveService = async(service) => {
        return new Promise(async(resolve, reject)=>{
            console.log(service)

            //Do validation of the primaries if they exist
            if(service.usePrimary){
                if(service.primaries && service.primaries.length > 0){
                    //filter out any providerIds that do not exist in the service. 
                    //We do this since we cannot choose those providers in the list that displays, so we need some way to remove them
                    let providerIdList = Object.keys(this.state.providers)
                    service.primaries = service.primaries.filter((primaryProviderId)=>{
                        return providerIdList.includes(primaryProviderId)
                    })
                }
                else{
                    //Can't enable the use primaries checkbox, then not assign primaries to the service...
                    resolve({message: "Unable to save. Service requires primaries to be set"})
                    return
                }
            }
            else{
                //set primaries to null if we are not using them
                service.primaries = null
            }
            ServiceManager.createService(service, (data) => {
                FacilityManager.addService(this.props.facility.id, data.id, (data2) => {
                    resolve(data)
                })
            })
        })
    }
}