import { Analytics } from 'aws-amplify'
import Timer from '../util/Timer';

export default class Tracker {

    static dryrun = true //TODO skd-792 get pinpoint working again

    static Event = {
        NETWORK_REQUEST: 'network_request',
        NETWORK_REQUEST_ERROR: 'network_request_error',
        ERROR: "error",
        SCREEN_VIEW: "screen_view",
        SCREEN_TIME: "screen_time",
        CREATE_APPOINTMENT: "create_appointment",
        UPDATE_APPOINTMENT: "update_appointment",
        SWAP_COLUMNS: "swap_columns",
        SWAP_APPOINTMENTS: "swap_appointment",
        GET_SWAPS: "get_swaps",
        PATIENT_LINK: "patient_link"
    }

    static logRequest(method: string, path: string, time: number) {
        const event = {
            name: Tracker.Event.NETWORK_REQUEST,
            attributes: {
                method: method,
                path: path
            },
            metrics: {
                time: time,
            }
        };

        if (Tracker.dryrun) { 
            console.log(`Analytics : ${event.name} : method=${method} : path==${path} : time=${event.metrics.time}`) 
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }

    static logRequestError(method: string, path: string, time: number, message: any) {
        const event = {
            name: Tracker.Event.NETWORK_REQUEST_ERROR,
            attributes: {
                method: method,
                path: path,
                message: message
            },
            metrics: {
                time: time,
            }
        };

        if (Tracker.dryrun) {
            console.log(`Analytics : ${event.name} : method=${method} : path==${path} : time=${event.metrics.time}`) 
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }
    
    static logScreenView(screen: string) {
        if (Tracker.screenTimer) { Tracker.logScreenTimeSpent() }
        Tracker.screenTimer = { screen: screen, timer: Timer.start() }

        const event = {
            name: Tracker.Event.SCREEN_VIEW,
            attributes: {
                screen: screen
            }
        };

        if (Tracker.dryrun) {
            console.log(`Analytics : ${event.name} : screen==${screen}`)
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }

    static logCreateAppointment(time: number, additional: boolean, update: boolean) {
        const event: string | {[key: string] : any} = {
            metrics: {
                time: time
            }
        };

        if (update) {
            event['name'] = Tracker.Event.UPDATE_APPOINTMENT
        }
        else {
            event['name'] = Tracker.Event.CREATE_APPOINTMENT
            event['attributes'] = {
                isAdditional: JSON.stringify(additional)
            }
        }

        if (Tracker.dryrun) {
            console.log(`Analytics : ${event.name} : time=${event.metrics.time} : additional=${additional} : update=${update}`)
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }

    static logSwapColumnAppointments(time: number, appointmentsSwappped: number, success: boolean) {
        const event = {
            name: Tracker.Event.SWAP_COLUMNS,
            metrics: {
                time: time,
                count: appointmentsSwappped,
                success: success ? 1 : 0
            }
        };

        if (Tracker.dryrun) {
            console.log(`Analytics : ${event.name} : time=${event.metrics.time} : count=${event.metrics.count} : success=${event.metrics.success}`)
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }

    static logAppointmentSwap(time: number, success: boolean, openTime: boolean) {
        const event = {
            name: Tracker.Event.SWAP_APPOINTMENTS,
            metrics: {
                time: time,
                openTime: openTime,
                success: success ? 1 : 0
            }
        };

        if (Tracker.dryrun) {
            console.log(`Analytics : ${event.name} : time=${event.metrics.time} : openTime=${event.metrics.openTime} : success=${event.metrics.success}`)
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }

    static logGetAppointmentSwaps(time: number, count: number, success: boolean) {
        const event = {
            name: Tracker.Event.GET_SWAPS,
            metrics: {
                time: time,
                count: count,
                success: success ? 1 : 0
            }
        };

        if (Tracker.dryrun) {
            console.log(`Analytics : ${event.name} : time=${event.metrics.time} : count=${event.metrics.count} : success=${event.metrics.success}`)
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }    

    static screenTimer: {[key: string]: any}

    static logScreenTimeSpent() {
        const event = {
            name: Tracker.Event.SCREEN_TIME,
            attributes: {
                screen: Tracker.screenTimer.screen
            },
            metrics: {
                time: Tracker.screenTimer.timer.getElapsed()
            }
        };

        Tracker.screenTimer = {}

        if (Tracker.dryrun) {
            console.log(`Analytics : ${event.name} : screen==${event.attributes.screen} : time==${event.metrics.time}`)
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }

    static logPatientLink(identifier: any | boolean) {
        const event = {
            name: Tracker.Event.PATIENT_LINK,
            attributes: {
                identifier: identifier
            }
        };

        if (Tracker.dryrun) {
            console.log(`Analytics : ${event.name} : identifier==${event.attributes.identifier}`)
        }
        else {
            Analytics.record(event).catch(error => { 
                Tracker.logError(error.message)
            });
        }
    }

    static logError(error: any) {
        const event = {
            name: Tracker.Event.ERROR,
            attributes: {
                message: JSON.stringify(error)
            }
        };

        if (Tracker.dryrun) {
            console.error(`Analytics : ${event.name} : message==${event.attributes.message}`)
        }
        else {
            void Analytics.record(event)
        }
    }
}