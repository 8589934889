import SchedulingCalendar from './SchedulingCalendar'
import React  from 'react';
import Colors from '../util/Colors'
import CalendarUtil from '../util/CalendarUtil'
import { Navbar } from 'react-bootstrap';
import Helpers from '../util/Helpers';
import LegacyAppointmentManager from '../managers/LegacyAppointmentManager';
import ProviderManager from '../managers/ProviderManager';
import FacilityUtil from '../util/FacilityUtil';
import closeIcon from '../img/Icons/cross.png'
import confirmIcon from '../img/Icons/material-done-green-18dp.png'
import twoFerIconWhite from '../img/Icons/2fer-white.svg'
import groupApptIconWhite from '../img/Icons/group-appt-white.svg'
import stashIcon from '../img/Icons/Stash-white.svg'
import LoadingSpinner from '../util/LoadingSpinner';
import { toast } from 'react-toastify';
import FacilityManager from '../managers/FacilityManager';
import FacilitySchedulePage from '../pages/FacilitySchedulePage';
import SwapPopup from '../ui/SwapPopup';
import FacilityCalendarOptions from '../ui/FacilityCalendarOptions';
import Timer from '../util/Timer';
import Tracker from '../managers/Tracker';
import PromisifyCallback from "../util/PromisifyCallback";
import {MultiSelectAppointmentFixedToast} from "../ui/MultiSelectAppointmentFixedToast";
import {AppointmentV2DBItem} from "../models/Appointment";
import {AppointmentRepository} from "../repositories/AppointmentRepository";
import {ScheduleDictionaryHelpers} from "../models/ScheduleDictionary";
import {ScheduleColumnImpl} from "../models/ScheduleColumn";
import {ScheduleAppointmentHolderImpl} from "../models/ScheduleAppointmentHolder";

const getNewFacilityAppointmentsCall = new PromisifyCallback(LegacyAppointmentManager.getNewFacilityAppointments)

/**
 * Rendered via FacilitySchedulePage
 *
 * via the following:
 * - OccupancyFacilityCalendar.js
 * - ProviderFacilityCalendar.js
 */
export default class FacilityCalendar extends SchedulingCalendar{
  /**
   * {{
   *     [key: string]: any,
   *     schedule: ScheduleColumn[]
   * }}
   */
  props
  /**
   * {{
   *     [key: string]: any,
   *     newSchedule: ScheduleColumn[]|null
   * }}
   */
  state

  /**
   * @type {AppointmentRepository}
   */
  repo

  constructor(props, allItems){
    super(props)

    this.repo = new AppointmentRepository(this.props.facility.id)

    this.state.fullWidth2fer = false
    this.state.selected = []
    this.state.selectedService = []
    this.state.filteredItems = this.props.filteredItems
    this.state.filteredServices = this.props.filteredServices ?? []
    this.state.clickedBlob = null
    
    this.state.allItems = allItems //rooms

    this.state.staticMarginTop = 100
    this.state.zIndexLines = -5
    this.state.cached2fers = {}
    var serviceLookup = {}
    this.props.services.forEach((service)=>{
      serviceLookup[service.id] = service
    })
    this.state.lastUpdatedTime = Date.now()
    this.queueTimer()
    
    this.state.serviceLookup = serviceLookup

    const roomLookup = {};
    this.props.facility.rooms.forEach((room)=>{
      roomLookup[room.occupancyId] = room
    })
    this.state.roomLookup = roomLookup
  }

  options = [];

  //abstract drawMultiSelect()...
  //abstract getFilterKey(this.props.schedule[key])...
  //abstract getHeaderName(this.props.schedule[key])...

  queueTimer = ()=> {
    if(this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(this.handleTimer, 60*1000) // 1 minute
  }

  handleTimer = () => {
    let lastUpdatedTime = this.state.lastUpdatedTime
    let currentTime = Date.now()
    let promptForUpdate = false
    getNewFacilityAppointmentsCall.invokeResolveReject(this.props.facility.id, lastUpdatedTime).then((result)=>{
      if(result && result.length > 0){
        //check to see if any are cached 2fers. If so, update those silently.
        for(let appointment of result){
          if(this.state.cached2fers && Object.keys(this.state.cached2fers).includes(appointment.id)){ //if appointmentId is in cached 2fer list
            if(appointment.modifiedBy === this.props.user['creatorId']){ //if current user made the change...
              console.log("Skip check, appointment is fine")
              continue; //go ahead and skip over it
            }
          }
          if(!CalendarUtil.areDatesTheSameDay(new Date(appointment.start), new Date(this.props.anchorDate)))
            continue //we really only care if the updated appointment falls on the day we are looking at
          promptForUpdate = true //otherwise we need to prompt for an update
        }
      }

      if(promptForUpdate){
        toast(
            (
                <div>
                  New appointment data is available. Click to reload
                </div>
            ),
            {
              onClick: () => {
                this.props.refresh()
              },
              autoClose: false,
              draggable: false,
              closeButton: true
            }
        )
      }
      else{
        //since we found no needed updates, let's adjust the last updated time to avoid ignored appointments from piling up
        this.setState({
          lastUpdatedTime: currentTime
        })
        this.queueTimer()
      }
    }).catch(()=>{
      toast(
          (
              <div>
                <div>
                  Unable to check for updates. Click to try again.
                </div>
                <div>
                  If the problem persists, please check your internet
                </div>
              </div>
          ),
          {
            onClick: () => {
              this.handleTimer()
            },
            onClose: ()=> {
              this.queueTimer()
            },
            autoClose: false,
            draggable: false,
            closeButton: true
          }
      )
    })
  }

  componentDidMount(){
    super.componentDidMount()
    super.onResize()
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.timeout)
  }

  /**
   *
   * @returns {ScheduleColumn[]}
   */
  getSchedule = () => {
    return this.state.newSchedule ? this.state.newSchedule : this.props.schedule
  }

  render(){
    this.id = 0
    const popup = this.renderPopup(this.state.swapSelectedBlob); //may return null
    let staticPopup
    const safelyInvokeEach = (func, getParamsFunc) => {
      this.setState({loading: true})
      let promises = []
      for (const clickedBlob of this.state.clickedBlobs) {
        promises.push(func(...getParamsFunc(clickedBlob), false))
      }
      Promise.all(promises)
          .then(()=>this.props.refresh())
          .catch((e)=>{
            console.error(e)
            toast('Some or all appointments failed to save')
          })
    }
    if(this.state.clickedBlobs && this.state.multiSelectMode){
      staticPopup = <MultiSelectAppointmentFixedToast
          services={this.props.facility.services}
          isProviderView={this.state.stateName === "FacilityProviderSchedule"}
          clickedBlobs={this.state.clickedBlobs}
          onShowOptions={()=>{
            this.setState({showMultiSelectOptions: !this.state.showMultiSelectOptions})
          }}
          on2ferChecked={false}
          onDelete={()=>safelyInvokeEach(this.onDelete, (clickedBlob)=>[clickedBlob])}
          onStash={()=>safelyInvokeEach(this.onStash, (clickedBlob)=>[clickedBlob])}
          onReload={()=>{
            this.setState({
              multiSelectMode: false,
              clickedBlobs: []
            })
          }}
          onUnassign={()=>safelyInvokeEach(this.onUnassignProvider, (clickedBlob)=>[clickedBlob.index, clickedBlob.appointment])}
          onUnStash={()=>safelyInvokeEach(this.onUnstash, (clickedBlob)=>[clickedBlob])}
      />
    }
    return super.render(popup ?? <>
      {staticPopup}
    </>, this.drawMultiSelect())
  }

  editSwapTime = (time) => {
    const clickedBlob = this.state.swapSelectedBlob;
    const appointmentDuration = clickedBlob.appointment.duration;
    if(this.state.time){
      if(time.start === this.state.time.start && time.end !== this.state.time.end){
        //end time changed, adjust start time to match old duration
        time.start = time.end - appointmentDuration
      }
      else if(time.end === this.state.time.end && time.start !== this.state.time.start){
        //start time changed, adjust end time to match old duration
        time.end = time.start + appointmentDuration
      }
    }
    this.setState(
      {
        time: time,
        validStart: new Date(time.start).getTime() >= clickedBlob.appointment.start,
        validEnd: new Date(time.end).getTime() <= clickedBlob.appointment.end
      }
    )
  }

  cancelSwap = ()=> {
    this.setState(
      {
        time: null, 
        swapSelectedBlob: null
      } 
    )
  }

  onSelectSwapTime = (time)=> {
    const swapSelectedAppointment = Object.assign({}, this.state.swapSelectedBlob.appointment);
    swapSelectedAppointment.start = time.startTime
    swapSelectedAppointment.end = time.endTime
    this.submitSwap(this.state.clickedBlob.appointment.id, swapSelectedAppointment)
  }

    drawHeaders(){
      let i = 0;
      let unfilteredColumnId = 0;
      const bgColor = this.state.scrollY > 110 ? '#fff' : null;
      const headers = [];
      const allDayEvents = [];

      /**
       * @type {ScheduleColumn[]}
       */
      const schedule = this.getSchedule();
      Object.keys(schedule).forEach((key) => {
        const unfilteredId = unfilteredColumnId++;
        const scheduleColumn = schedule[key];
        const scheduleColumnAppointmentHolders = scheduleColumn.list()
        const scheduleColumnId = this.props.columnIds[key];
        if(this.state.filteredAllItems && !this.state.filteredAllItems.includes(scheduleColumnId)) return null
        if(this.state.filteredItems.includes(scheduleColumnId)) return null

        //scan for services that are filtered. If at least one service is in there that was not filtered, the header is OK to show
        let filtered = true;
        scheduleColumnAppointmentHolders.forEach((holder)=>{
          let appt = holder.get()
          if(appt.serviceId === this.props.facility.mealServiceId) return //ignore this service
          if(!this.state.filteredServices.includes(appt.serviceId)) filtered = false
        })
        if(filtered) return null
        //
        const column = i++;
        const headerStyle = this.getHeaderStyle(column, -10);
        const borderColor = this.getHeaderUnderlineColor(unfilteredId, scheduleColumn)
        headerStyle.height = 'auto'
        headers.push(this.drawHeader(scheduleColumn, headerStyle, bgColor, borderColor, unfilteredId))

        const allDayEventStyle = this.getHeaderStyle(column, -10);
        allDayEventStyle.backgroundColor = null
        allDayEventStyle.zIndex = 'auto'
        allDayEvents.push(
          <div style={headerStyle}>
            {/*Set our all day events*/}
            <div style={{display: "table-cell", verticalAlign: "bottom" }}>
              {this.drawFullDayBlobs(scheduleColumn)}
            </div>
          </div>
        )
      })
      return (
        <>
          <Navbar style={{position: 'sticky', top: 0, zIndex: this.state.zIndexHeader}}>
            {headers}
          </Navbar>
          <div style={{position: 'relative', top: 50, zIndex: -1}}>
            {allDayEvents}
          </div>
        </>
      )
    }

    drawHeader(scheduleColumn, headerStyle, bgColor, borderColor){
      return (
        <div style={headerStyle}>
          <div style={{
            backgroundColor: bgColor,
            
            }}>{this.getHeaderName(scheduleColumn)}</div> {/*Room title*/}
            <div style={{
              margin: '4px',
              borderBottom: borderColor ? `8px solid ${borderColor}` : null,
            }}></div>
        </div>
      )
    }

  /**
   *
   * @param column
   * @param scheduleColumn {ScheduleColumn}
   * @returns {*|null}
   */
    getHeaderUnderlineColor = (column, scheduleColumn) => {
      if(this.state.stateName === "FacilityProviderSchedule"){
        let providerId = this.props.columnIds[column];
        if(!providerId){
          providerId = scheduleColumn.id
        }
        const provider = this.props.facility.providers[providerId];
        //we only want to show the provider color if they are in a service that uses primaries, 
        //and also only if they show up as a default primary if possible
        //To do so, we need to check to see if any services include that provider
        let providerHasPrimary
        const services = this.props.facility.services;
        services.forEach((service)=>{
          if(service.usePrimary && service.primaries && service.primaries.length > 0){
            if(service.primaries.includes(providerId)){
              providerHasPrimary = service.id
            }
          }
        })
        let color
        if(provider && providerHasPrimary){
          color = provider.color
        }
        return color
      }
      return null
    }

  /**
   *
   * @param column {number}
   * @param scheduleColumn {ScheduleColumn}
   * @returns {boolean|*}
   */
    getHeaderProviderUnavailable = (column, scheduleColumn) => {
      if(this.state.stateName === "FacilityProviderSchedule"){
        let providerId = this.props.columnIds[column];
        if(!providerId){
          providerId = scheduleColumn.id
        }
        const provider = this.props.facility.providers[providerId];
        if(!provider) return false
        return ProviderManager.isProviderUnavailable(provider)
      }
      return false
    }

    /**
     *
     * @param roomSchedule {ScheduleColumn}
     * @returns {*}
     */
    drawFullDayBlobs(roomSchedule){
      //store a local count of how many all day appointments in this day. Used to push everything else down
      let allDayCount = 0;

      //grab the current static margin, so we don't keep building up mergin each time
      const marginTop = 40;
      return roomSchedule.list().map((holder, index)=>{
        let appt = holder.get()
        //midnight to 12:45. Used to set cell to 45 minutes, and for size calculations
        const startTime = appt.start.getTime();
        const midnightDate = new Date(startTime);
        const timeEnd = new Date(startTime);
        midnightDate.setHours(0,0,0,0);
        timeEnd.setHours(0,30,0,0)

        const isFullDay = new Date(startTime).getTime() === midnightDate.getTime();
        if(isFullDay){ //we only want to use this when all day appointment
          allDayCount++ // Up the day counter
          //calculate cell height and the new margin
          const cellHeight = this.getHeight(CalendarUtil.getShortTime(midnightDate), CalendarUtil.getShortTime(timeEnd));
          const calculatedMargin = cellHeight * allDayCount + marginTop;
          if(calculatedMargin > this.state.marginTop){ 
            //set the new margin if larger than previous. This will push the calendar down, since it is absolute positioned from top of page
            this.state.marginTop = calculatedMargin
          }
          return this.drawFullDayBlob(appt, index, this.id++, midnightDate, timeEnd)
        }
      })
    }

  /**
   * draw a full day blob above the agenda
   * @param appointment {AppointmentModel}
   * @param index {number}
   * @param id {number}
   * @param midnightDate {Date}
   * @param endTime {Date}
   * @returns {*}
   */
    drawFullDayBlob(appointment, index, id, midnightDate, endTime){
      //override blob style
      const blobStyle = this.getBlobStyle(appointment, index, midnightDate, endTime, id);
      blobStyle.position = "relative" //relative instead of absolute
      blobStyle.left = 0 //no longer need it
      blobStyle.top = 0 //no longer need it
      
      //a little bit of margin
      blobStyle.marginTop = 10 
      blobStyle.marginBottom = 10
      
      //override blob text styles
      const blobTextStyle = Helpers.getBlobTextStyle(blobStyle)
      blobTextStyle.position = "relative" //relative instead of absolute
      blobTextStyle.top = 0
      blobTextStyle.textAlign = "left"
      //run drawBlob with our overrides. Set textInsideBlob to true
      return this.drawBlob(appointment, index, id, blobStyle, blobTextStyle, true)
    }

    static midnightLookup = {}

    drawBlobs(){
    
        let index = -1
        let schedule = this.getSchedule()
        let foundClickedBlobId = false
        let pendingClickedBlobId = null
        console.log(schedule)
        const blobs = Object.keys(schedule).map((key) => {
          console.log(key)
          const roomSchedule = schedule[key]
          const scheduleColumnId = this.props.columnIds[key]
          if(this.state.filteredAllItems && !this.state.filteredAllItems.includes(scheduleColumnId)) return null
          if(this.state.filteredItems.includes(scheduleColumnId)) return null

          //scan for services that are filtered. If at least one service is in there that was not filtered, the blob column is OK to show
          let filtered = true
          console.log(roomSchedule)
          roomSchedule.list().forEach((apptHolder)=>{
            let appt = apptHolder.get()
            if(appt.type === "TIME") {
              filtered = false
              return
            }
            if(appt.serviceId === this.props.facility.mealServiceId) return //ignore this service
            if(!this.state.filteredServices.includes(appt.serviceId)) filtered = false
          })
          if(filtered) return null
          //
          index++
          
          return roomSchedule.list().map((apptHolder) => {
            let id = this.id++
            let appt = apptHolder.get()
            if(this.state.stateName ===  "FacilitySchedule" && appt.serviceId === this.props.facility.service2ferId) return null //filter out 2fers from facility calendar
            if(this.state.filteredServices.includes(appt.serviceId) && appt.type !== "TIME") return null
            const clickedBlob = this.state.clickedBlob
            if(clickedBlob && clickedBlob.appointmentId === appt.id){
              // console.log(JSON.stringify(appt))
              // console.log(JSON.stringify(this.state.clickedBlob.appointment))
              appt = Object.assign({},clickedBlob.appointment)
              appt.start = new Date(clickedBlob.startTime)
              appt.end = new Date(clickedBlob.endTime)
              if(clickedBlob.id !== id && !foundClickedBlobId){
                //need to fix the ID of the clicked blob to match with our new value...
                pendingClickedBlobId = id //we will save it after generating all blobs, in case it is actually valid on a different one
              }
              else{
                foundClickedBlobId = true
                pendingClickedBlobId = null
              }
              // console.log("AFTER")
              // console.log(JSON.stringify(appt))
            }
            const startTime = appt.start.getTime()
            const is2fer = appt.serviceId === this.props.facility.service2ferId && appt.type !== "TIME"
            let midnightDate;
            if(!FacilityCalendar.midnightLookup[startTime]){
              midnightDate = new Date(startTime);
              midnightDate.setHours(0,0,0,0);
              FacilityCalendar.midnightLookup[startTime] = midnightDate
            }
            midnightDate = FacilityCalendar.midnightLookup[startTime]
            const isFullDay = new Date(startTime).getTime() === midnightDate.getTime()
            if(!isFullDay && (!is2fer || this.state.fullWidth2fer)){
              return this.drawBlob(appt, index, id)
            }
            else if(is2fer){
              return this.drawBlob(appt, index, id, this.getBlobStyle(appt, index, appt.start, appt.end, id, false, true))
            }
          })
        })
        if(this.state.clickedBlob && pendingClickedBlobId !== null){
          const clickedBlobTemp = this.state.clickedBlob
          clickedBlobTemp.id = pendingClickedBlobId
          this.setState({clickedBlob: clickedBlobTemp})
        }
        return blobs
    }

  /**
   *
   * @param appointment {AppointmentModel}
   * @param index {number}
   * @param id {number}
   * @param [blobStyleParam] {CSSProperties}
   * @param [blobTextStyle] {CSSProperties}
   * @param [textInsideBlob] {boolean}
   * @returns {(JSX.Element|*)[]|JSX.Element}
   */
    drawBlob(appointment, index, id, blobStyleParam, blobTextStyle, textInsideBlob){ //add overrides to the styles methods
        
        const startTime = appointment.start.getTime()
        const endTime = appointment.end.getTime()
      
        const blobStyle = blobStyleParam ? blobStyleParam : this.getBlobStyle(appointment, index, startTime, endTime, id)
              
        this.state.blobs[id] = {
            id: id,
            startTime: startTime,
            starttime: startTime, //must be a fallback?
            endTime: endTime,
            endtime: endTime, //must be a fallback?
            top: blobStyle.top,
            height: blobStyle.height,
            type: 2,
            appointmentId: appointment.id,
            appointment: appointment
        }
      
        // var label = blobStyle.height > 20 ? appointment.title + ", " + appointment.roomName + " - " + appointment.occupancyIdentifier : ""
      
        // title, firstName lastName[0] [room icon] roomName [notes icon] notes
        
        let locationName;

        let occupancies = appointment.occupants

        const service = Helpers.getService(appointment.serviceId, this.props.facility.services)
        if(service){
          if (Helpers.shouldShowSingleOccupantAsLocation(appointment, service) && occupancies.length === 1) {
            locationName = occupancies[0].roomName
          }
          else {
            let locationId = appointment.locations.length > 0 ? appointment.locations[0].id : ''
            const loc = this.props.facility.locations[locationId]
            if(loc != null) locationName = loc.name
          }
        }        

        let body = [];

        // 30 minutes * 60 seconds * 1000

        const thirtyMin = 30 * 60 * 1000
        const length = endTime - startTime;

        let isOffsite = appointment.isOffsite || (appointment.serviceId === this.props.facility.otherServiceId && appointment.type !== "TIME")

        let providerNames = null
        
        if(!this.state.hideProviderNames && appointment.providers != null && appointment.providers.length > 0){
          isOffsite = isOffsite && appointment.providers[0] === this.props.facility.offsiteProviderId
          if(!isOffsite){
            providerNames = []
            appointment.providers.forEach(p => {
              let providerId = p.id
              if(!providerId || (providerId && providerId.includes("null"))) return
              if(providerId === this.props.facility.offsiteProviderId){
                providerNames.push("Offsite")
              }
              else{
                const provider = this.props.facility.providers[providerId];
                if(provider)
                  providerNames.push(`${provider.firstName} ${provider.lastName[0]}`)
                //else what do we do?
              }
            });
          }
        }

        if(!this.state.hideOccupancyNames){ //if we want to show occupancy names instead
          providerNames = [] //overwrite provider names
          if(appointment.type === "TIME"){
            providerNames.push(`${CalendarUtil.getShortTime(appointment.start)} - ${CalendarUtil.getShortTime(appointment.end)}`)
          }
          else{
            occupancies.forEach(occupancy => {
              if(occupancy.roomName && occupancy.name && appointment.type !== "CONFLICT"){ //could be a meal...
                providerNames.push(occupancy.name) //add occupancy info to provider list
              }
            })
          }
        }

        if (length < thirtyMin) {
          const names = providerNames ? providerNames.join(', ') : ""
          body = isOffsite ? [
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Offsite</span>
          ] : [
            <span style={{ fontSize: 12, fontWeight: "bold" }}>{appointment.title}</span>,
            names ? <span style={{ fontSize: 12 }}>, {names}</span> : null
          ]

          if(!isOffsite && locationName){
            body.push(<img src={require('../img/Icons/location.png')} className="mr-1 ml-2" style={{height:"12px"}} alt="loc"/>)
            body.push(<span style={{ fontSize: 12 }}>{locationName}</span>)
          }

          if (appointment.notes && typeof(appointment.notes) !== 'undefined' && appointment.notes.length > 0) {
            body.push(<img src={require('../img/Icons/note.png')} className="mr-1 ml-2" style={{height:"12px"}} alt="loc"/>)
            body.push(<span style={{ fontSize: 12 }}>{appointment.notes}</span>)
          }
        }
        else {

          const locImg = locationName == null || isOffsite ? null : (
            <div>
              <img src={require('../img/Icons/location.png')} className="mr-1 ml-1" style={{height:"12px"}} alt="loc"/>
              <span style={{fontSize:12}}>{locationName}</span>
            </div>
          )

          const providerDiv = providerNames == null || providerNames.length === 0 || isOffsite ? null : (
            <span style={{ fontSize: 12 }}>, {providerNames.join(", ")}</span>
          )
     
          body = [
            <div>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>{appointment.title}</span>
              {providerDiv}
            </div>,
            locImg
          ]

          if (appointment.notes && typeof(appointment.notes) === 'string' && typeof(appointment.notes) !== 'undefined' && appointment.notes.length > 1) {
            body.push(
              <div>
                <img src={require('../img/Icons/note.png')} className="mr-1 ml-1" style={{height:"12px"}} alt="loc"/>
                <span style={{fontSize:12}}>{appointment.notes}</span>
              </div>
            )
          }
        }

        const textStyle = blobTextStyle ? blobTextStyle : Helpers.getBlobTextStyle(blobStyle)
        if(appointment.incomplete)
          blobStyle.border = '3px solid red'
      
        //when textInsideBlob is true, the body is put inside of the blob opposed to over it. 
        //This helps with relative layouts, but breaks clicks
        if(textInsideBlob){
          return (
            <div id={id} style={blobStyle}>
              <div style={textStyle}>{body}</div>
            </div>
          )
        }
        else{
          const clickListener = (e)=>{this.onClickBlob(e,id,appointment)}
          let overlay
          let timeSelectionOverlay
          const clickedBlob = this.state.clickedBlob
          if(clickedBlob && clickedBlob.id === id){
            overlay = this.drawAppointmentOverlay(appointment, index, appointment.start, appointment.end, id)
          }
          else if(clickListener){
            blobStyle.cursor = 'pointer'
          }
          if(this.state.swapSelectedBlob && this.state.swapSelectedBlob.id === id){
            const swapSelectedBlob = this.state.swapSelectedBlob
            if(swapSelectedBlob.columnIndex !== index){
              swapSelectedBlob.columnIndex = index
              this.setState({swapSelectedBlob: swapSelectedBlob})
            }
            timeSelectionOverlay = this.drawTimeOverlay(appointment, index, startTime, endTime, id)
          }
          const {element: indicators, count: indicatorCount} = this.renderIndicators(appointment, index, appointment.start, appointment.end, id, blobStyle.cursor)
          if(indicatorCount > 0){
            //modify the textStyle width to account for icons on the side, assuming a gap of 8px, and 24px per icon
            textStyle.width = blobStyle.width - (30 + 8 + 24 * indicatorCount)
          }
          return [
            <div appointmentId={appointment.id} onClick={overlay||this.state.columnSwapping?null:clickListener}>
              <div id={id} style={blobStyle}/>
              <div style={textStyle}>{body}</div>
              {indicators}
              {overlay}
            </div>,
            timeSelectionOverlay
          ]
        }
      }

      renderPopup(blob){
        if(!blob) return null
        if(blob.columnIndex === undefined) return null //return for now. Next frame we will have our data though
        return (
          <SwapPopup 
            column={blob.columnIndex}
            columnWidth={this.state.columnWidth}
            columns={this.state.columns}
            blob={blob} 
            appointment={blob.appointment}
            editTime={this.editSwapTime} 
            onSelectTime={this.onSelectSwapTime}
            onClose={this.cancelSwap}
            onPopupMounted={()=>{/*Leave empty for now...*/}}
            validStart={this.state.validStart}
            validEnd={this.state.validEnd}
            time={this.state.time}/>
        )
      }

    /**
     *
     * @param appointment {AppointmentModel}
     * @param index {number}
     * @param startTime {Date}
     * @param endTime {Date}
     * @param id {number}
     * @param cursor
     * @returns {{element: JSX.Element?, count: number}}
     */
      renderIndicators(appointment, index, startTime, endTime, id, cursor){
        const thirtyMin = 30 * 60 * 1000;
        const length = endTime - startTime;
        let require2fer = appointment.require2fer
        if(this.state.cached2fers[appointment.id] !== undefined)
          require2fer = this.state.cached2fers[appointment.id]

        if(!require2fer && !appointment.stashed && !appointment.isGroupAppointment) return {element: null, count: 0}
        //render 2fer icon in bottom right
        const styleOverlay = this.getBlobStyle(appointment, index, startTime, endTime, id);
        styleOverlay.backgroundColor = 'transparent'
        styleOverlay.border = null
        styleOverlay.borderRadius = null
        styleOverlay.cursor = cursor
        const containerStyle = {
          position: 'absolute',
          height: length < thirtyMin ? 14 : undefined,
          bottom: (length < thirtyMin ? 7 : 24) - (appointment.isGroupAppointment ? 4 : 0),
          right: length < thirtyMin ? 30 : 24
        }
        containerStyle.display = 'flex'
        containerStyle.flexDirection = 'row'
        containerStyle.justifyContent = 'center'
        containerStyle.gap = '8px'
        let icon = undefined
        if(require2fer)
          icon = twoFerIconWhite
        if(appointment.stashed)
          icon = stashIcon
        let iconCount = 0
        if(icon) iconCount++
        if(appointment.isGroupAppointment) iconCount++
        return {
          element: (
              <div style={styleOverlay}>
                <div style={containerStyle}>
                  {icon && <img src={icon}/>}
                  {appointment.isGroupAppointment && <img src={groupApptIconWhite} style={{paddingBottom: -2}}/>}
                </div>
              </div>
          ),
          count: iconCount
        }
      }

      /**
       *
       * @param appointment {AppointmentModel}
       * @param index {number}
       * @param startTime {Date}
       * @param endTime {Date}
       * @param id {number}
       * @returns {JSX.Element[]|*}
       */
      drawAppointmentOverlay = (appointment, index, startTime, endTime, id)=> {
        const styleOverlay = this.getBlobStyle(appointment, index, startTime, endTime, id)
        const buttonOverlay = this.getOptionsOverlay(appointment, index, startTime, endTime, id)
        styleOverlay.backgroundColor = "#00000080"
        /**
         * @type {CSSProperties}
         */
        const buttonStyle = {marginTop: 2, height: 24, width: 24, cursor: 'pointer'}
        let body
        if(this.state.loadingBlob){
          body = [
            <LoadingSpinner size={50}/>
          ]
        }
        else if(this.state.swapMode){
          body = this.renderSwapMode()
        }
        else if(this.state.renderConfirmPrompt){
          body = this.renderConfirmPrompt(buttonStyle)
        }
        else{
          return this.renderDefaultAppointmentOverlay(appointment, index, startTime, endTime, id, buttonStyle)
        }
        return [
          <div style={styleOverlay}/>,
          <div style={buttonOverlay}>
            {body}
          </div>
        ]
      }

      drawTimeOverlay = (appointment, index, startTime, endTime, id) => {
        if(!this.state.time) return null
        var styleOverlay = this.getBlobStyle(appointment, index, this.state.time.start, this.state.time.end, id)
        styleOverlay.backgroundColor = Colors.Primary.Dark
        styleOverlay.border = "5px solid " + Colors.Green
        return (
          <div style={styleOverlay}/>
        )
      }

    /**
     *
     * @param appointment {AppointmentModel}
     * @param index {number}
     * @param startTime {Date}
     * @param endTime {Date}
     * @param id {number}
     * @param buttonStyle {CSSProperties}
     * @returns {JSX.Element}
     */
      renderDefaultAppointmentOverlay(appointment, index, startTime, endTime, id, buttonStyle){
        const styleOverlay = this.getBlobStyle(appointment, index, startTime, endTime, id)
        const isProviderView = this.state.stateName === "FacilityProviderSchedule"
        
        let require2fer = this.state.cached2fers[appointment.id]
        if(require2fer !== undefined){
          appointment.require2fer = require2fer
        }
        let service = Helpers.getService(appointment.serviceId, this.props.facility.services)
        let isScheduleForward = service.scheduleForward
        let allowSwapping = service.allowSwapping && !appointment?.isGroupAppointment //allow only if not group appointment for now //TODO skd-795 add support for group appointment swapping
        let isAfterNow = appointment.start > Date.now()
        let allow2ferSwitch = this.has2ferService() && appointment.serviceId !== this.props.facility.service2ferId && isScheduleForward
        let allowProviderUnassign = isAfterNow && isProviderView && isScheduleForward
        let allowProviderSwap = isAfterNow && isProviderView && allowSwapping && appointment.providers?.length === 1
        let allowStash = isAfterNow && isScheduleForward && (appointment.serviceId !== this.props.facility.service2ferId)
        return (
          <FacilityCalendarOptions
            columnWidth={this.state.columnWidth}
            columns={this.state.columns}
            blob={this.state.clickedBlob}
            scrollY={this.state.scrollY}
            staticMarginTop={this.state.staticMarginTop}
            marginTop={this.state.marginTop}
            appointment={appointment} 
            onCancel={this.onClosePopup}
            refStyle={styleOverlay} 
            loading={this.state.loadingBlob}
            onSwapAppointment={this.onSwapAppointment}
            onDelete={()=>{
              this.handlePrompt("Delete?", ()=>{
                this.onDelete(this.state.clickedBlob)
              })
            }}
            onStash={()=>{
              this.handlePrompt("Stash?", ()=>{
                this.onStash(this.state.clickedBlob)
              })
            }}
            onUnstash={()=>{
              this.handlePrompt("Unstash?", ()=>{
                this.onUnstash(this.state.clickedBlob)
              })
            }}
            onUnassignProvider={()=>{
              this.handlePrompt("Unassign?", ()=>{
                this.onUnassignProvider(index, appointment)
              })
            }}
            onEdit={this.onRescheduleAppointment}
            on2ferSelected={this.on2ferChecked}
            onSelectOthers={async ()=>{
              this.setState((prevState, props)=>{
                prevState.clickedBlobs.push(prevState.clickedBlob)
                prevState.multiSelectMode = true
                return prevState
              }, ()=>this.onClosePopup())
            }}
            hideControls={!FacilityUtil.canEditAppointments(this.props.user, this.props.facility, appointment)}
            allowEdit={!appointment.stashed}
            allow2fer={!appointment.stashed && allow2ferSwitch}
            allowProviderUnassign={!appointment.stashed && allowProviderUnassign}
            allowProviderSwap={!appointment.stashed && allowProviderSwap}
            allowStash={!appointment.stashed && allowStash}
            allowUnstash={appointment.stashed}
            selectOthers={true}
            services={this.props.facility.services}/>
        )
      }

    /**
     *
     * @param appointment {AppointmentModel}
     * @param index {number}
     * @param startTime {Date}
     * @param endTime {Date}
     * @param id {number}
     * @returns {CSSProperties}
     */
      getOptionsOverlay(appointment, index, startTime, endTime, id){
        const buttonOverlay = this.getBlobStyle(appointment, index, startTime, endTime, id)
        const overlay = {
          backgroundColor: null,
          display: 'flex',
          flexDirection: 'horizontal',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          margin: 'auto',
          paddingRight: '16px'
        }
        return Object.assign({}, buttonOverlay, overlay)
      }

      renderConfirmPrompt(buttonStyle){
        let confirmStyle = Object.assign({},buttonStyle)
        confirmStyle.width = 26
        return [
          <span style={{color: 'white'}}>{this.state.renderPromptTitle}</span>,
          <img src={closeIcon} style={buttonStyle} onClick={this.handlePromptCancelled}/>,
          <img src={confirmIcon} style={confirmStyle} onClick={this.handlePromptConfirmed}/>
        ]
      }

      renderSwapMode(appointment, index, startTime, endTime, id, buttonStyle){
        return [
          <img src={closeIcon} style={{position: 'absolute', right: 8, top: 8, cursor: 'pointer'}} onClick={this.onClosePopup}/>,
          <span style={{color: 'white'}}>Swap</span>
        ]
      }

      on2ferChecked = async (checked)=> {
        const appointment = this.state.clickedBlob.appointment;

        this.setState({loadingBlob: true})
        try {
          const {result} = await LegacyAppointmentManager.update2ferFlag(appointment.facilityId, appointment.id, checked)
          //easy way to update the appointment without a full refresh...
          //TODO skd-799 maybe we should change this to update appointments using updateAppointment result, but seems like it would take a while to do without breaking states
          const cached2fers = this.state.cached2fers;
          cached2fers[appointment.id] = result.require2fer
          this.setState({loadingBlob: false, cached2fers: cached2fers})
        } catch (e) {
          toast("Failed to save 2fer request")
          this.setState({loadingBlob: false})
          return
        }
      }

      onSwapAppointment = ()=>{
        let timer = Timer.start();
        let appointment = this.state.appointment
        let duration = appointment.end.getTime() - appointment.start.getTime()
        this.setState({swapMode: true, loadingBlob: true})
        return this.repo.getAvailableSlotsForSwap(appointment.id, appointment.requirePrimary)
            .then((result) => {
              if(result.error || !result.appointments){
                toast(result.error ?? "Failed to get appointments available to swap with")
                Tracker.logGetAppointmentSwaps(timer.getElapsed(), 0, false)
                this.setState({loadingBlob: false, swapMode: false})
                return
              }
              Tracker.logGetAppointmentSwaps(timer.getElapsed(), result.appointments.length, false)
              /**
               * @type {ScheduleDictionary}
               */
              let thisSchedule = {}
              result.appointments.forEach((appointment)=>{
                if(appointment.type === "CONFLICT"){
                  let title = "Conflict: "
                  switch (appointment.conflictType) {
                    case "PROVIDER":
                      title += this.getProviderNames(appointment, "conflictingProviders")
                      title += " - "
                      break;
                    case "OCCUPANCY":
                      title += appointment.occupants?.length > 0 ? appointment.occupants[0].name : '?'
                      title += " - "
                      break;
                    //default: do nothing
                  }
                  title += appointment.title
                  appointment.title = title //jeffry P has service with provider name.
                  appointment.locationId = "TIME"
                  let providerNames = this.getProviderNames(appointment, "conflictingProviders")
                  if(providerNames)
                    appointment.notes = ""
                }
                if(appointment.type === "TIME"){
                  appointment.duration = duration
                  appointment.title = "Open"
                  appointment.locationId = "TIME"
                  appointment.locations = [{
                    id: "TIME",
                    name: "Open"
                  }]
                }
              })

              /**
               * @type {ScheduleColumn[]}
               */
              let existingSchedule = this.props.schedule

              //add our initial appointments...
              Object.keys(existingSchedule).forEach((key)=> {
                thisSchedule[existingSchedule[key].id] = new ScheduleColumnImpl(existingSchedule[key].id, existingSchedule[key].label)
                existingSchedule[key].list().forEach((holder)=> {
                    let appt = holder.get()
                    thisSchedule[existingSchedule[key].id].push(new ScheduleAppointmentHolderImpl(window.structuredClone(appt), existingSchedule[key].id, existingSchedule[key].label))
                })
                result.appointments.sort((a,b)=>{
                  if(a.start.getTime() === b.start.getTime()) return a.end.getTime() - a.start.getTime() > b.end.getTime() - b.start.getTime() ? 1 : -1 //sort it so smaller duration comes first if both have the same time
                  return a.start.getTime() > b.start.getTime() ? 1 : -1
                })
                result.appointments.forEach((appointment)=>{ //add our swappable times to the old column data
                  if(appointment.providers[0]?.id === this.props.columnIds[key]){
                    thisSchedule[existingSchedule[key].id].pushAppointmentModel(appointment)
                  }
                })
              })

              //now add more columns to this if needed
              let existingIds = [] //get existing columns worth of providers
              Object.keys(existingSchedule).forEach((key)=>{
                let id = this.props.columnIds[key]
                if(!existingIds.includes(id))
                  existingIds.push(id)
              })
              /**
               * @type {ScheduleDictionary}
               */
              let newData = {}
              //Now create more columns
              result.appointments.forEach((newAppointment)=> {
                if(newAppointment.incomplete) return
                if(!Array.isArray(newAppointment.providers)) return
                let keys = newAppointment.providers.map(p=>p.id)
                keys.forEach((id)=>{
                  if(existingIds.includes(id)) return //already processed
                  let providerName = FacilitySchedulePage.getProviderName(id, this.props.facility)
                  if(!newData[id]) newData[id] = new ScheduleColumnImpl(id, providerName)
                  let newAppointmentObject = Object.assign({}, newAppointment)
                  newAppointmentObject.providerName = providerName
                  newData[id].pushAppointmentModel(newAppointmentObject)
                })
              })

              let sortedSchedule = Object.keys(newData)
              sortedSchedule.forEach(e => {
                thisSchedule[e] = newData[e]
              })

              let existingColumns = Object.keys(thisSchedule)
              //now lets sort the columns, from existing on left, and new on right
              let sortedColumns = []
              existingColumns.forEach((column)=>{
                  sortedColumns.push(thisSchedule[column])
              })
              console.log(existingColumns)

              this.setState( //TODO editing an appointment does not delete the old time oops
                  {
                    loadingBlob: false,
                    openAppointments: result.appointments,
                    newSchedule: sortedColumns,
                    columns: sortedColumns.length,
                    allColumns: sortedColumns.length
                  },
                  ()=>{
                    this.setState({columnWidth: this.getColumnWidth()}) //getColumnWidth uses state, so wait a frame...
                  }
              )
            })
      }

      onUnassignProvider = async(index, appointment, refresh=true) => {
        return new Promise((resolve, reject)=>{
          this.setState({
            loadingBlob: true
          }, () => window.scrollTo(0, 0))
          //TODO get a single provider based on the column.
          //The best we can do for now is do unassign all providers on the appointment (assuming multiple)
          let providerIds = appointment.providers.map(p => p.id)
          void LegacyAppointmentManager.unassignAppointmentProvider(this.props.facility.id, appointment.id, providerIds, (result)=>{
            if(result.statusCode !== 200){
              this.setState({loadingBlob: false})
              console.error(result)
              toast('Failed to unassign provider from appointment')
              if(refresh && (result.statusCode !== 406 || result.statusCode !== 500)){
                this.props.refresh();
              }
              resolve()
            }
            else{
              if(refresh) this.props.refresh();
              resolve()
            }
          })
        })
      }

      onRescheduleAppointment = () => {
        /**
         * @type {AppointmentModel}
         */
        const appointment = this.state.appointment
        appointment.previousState = {
          name: this.state.stateName === "FacilitySchedule" ? "SELECT_FACILITY_SCHEDULE" : "SELECT_PROVIDER_SCHEDULE",
          data: {
              anchorDate: this.props.anchorDate
          }
        }
        this.setState({
          loading: true
        }, () => window.scrollTo(0, 0))

        this.props.machine.send("HOME")
        this.props.onEditAppointment(appointment)
      }

      handlePrompt = (prompt, callback) => {
        this.setState({renderConfirmPrompt: true, confirmCallback: callback, renderPromptTitle: prompt})
      }

      handlePromptConfirmed = () => {
        this.state.confirmCallback()
        this.handlePromptCancelled()
      }

      handlePromptCancelled = () => {
        this.setState({renderConfirmPrompt: false, confirmCallback: null, renderPromptTitle: null})
      }

      has2ferService = () => {
        return this.props.facility.service2ferId
      }

      /**
       *
       * @param service
       * @param appointment {AppointmentModel}
       * @returns {boolean}
       */
      getServiceProviders = (service, appointment) => {
        //TODO handle getting only providers that are available for the given time (needs a backend call to figure that out)
        if(service.pickProvider){
          ProviderManager.listProviders(this.props.facility.id, service.id, true, false, (result)=>{
            service.providers = []
            const data = {
              title: service.name, 
              options: result.map((providerSlot)=>{
                //TODO just use providerSlot.id. Same as what we are doing here...
                return providerSlot.serviceProvider.replace(`${service.id}-`, '')
              }), 
              allowedProviders: "Only"
            }
            for(let i = 0; i < appointment.providers.length; i++){
              service.providers.push(data)
            }
            this.setState({service: service, popupLoading: false})
          })
          return true
        }
        else{
          return false
        }
      }

    /**
     *
     * @param appointment {AppointmentModel}
     * @param day {number}
     * @param startTime {Date}
     * @param endTime {Date}
     * @param id {number}
     * @param [isFullDay] {boolean|undefined}
     * @param [is2fer] {boolean|undefined}
     * @returns {CSSProperties}
     */
      getBlobStyle(appointment, day, startTime, endTime, id, isFullDay, is2fer = false){
        let service = this.state.serviceLookup[appointment.serviceId];
        if(!service) service = {}
      
        const selected = this.state.clickedBlob != null && appointment.id === this.state.clickedBlob.appointmentId
        let multiSelected = false
        for (const blobElement of this.state.clickedBlobs) {
          if(appointment.id !== blobElement.appointmentId) continue
          multiSelected = true
        }
      
        this.state.onlyHightlightPrimaries = false
        let color = this.maybeGetPrimaryProviderColor(appointment);
        if(!color && service.color){
          color = this.state.onlyHightlightPrimaries && service.usePrimary ? Colors.MiddleGray : service.color
        }

        const startTimeShort = CalendarUtil.getShortTime(startTime);
        const endTimeShort = CalendarUtil.getShortTime(endTime);

        //If 2fer, we want a blob to take up half of the column
        const w = is2fer ? this.state.columnWidth / 2 : this.state.columnWidth
        if(is2fer){
          day *= 2
          day++ //on the left of it
        }
      
        let top = undefined
        if(isFullDay){
          const firstDateSlot = new Date(this.state.timeRange[0])
          firstDateSlot.setMinutes(-SchedulingCalendar.defaultFallbackDuration)
          const start = CalendarUtil.getShortTime(firstDateSlot)
          top = this.getTop(start)
        }
        else{
          top = this.getTop(startTimeShort)
        }
        const height = this.getHeight(startTimeShort, endTimeShort)
      
        let border = selected
            ? "3px solid #fffa"
            : ""

        let zIndex
        
        if(appointment.serviceId === this.props.facility.mealServiceId){
          zIndex = -3
        }
        else if(appointment.incomplete){
          zIndex = 3 //draw incomplete appointments on top of everything
        }
        else if(appointment.serviceId === this.props.facility.service2ferId){
          zIndex = 2 //2fer needs to draw on top
        }
        else{
          zIndex = 1
        }

        //Check to see if this blob needs to be highlighted, since it could be a viable swap
        let tmpColor
        if(this.state.swapMode && this.state.openAppointments){
          tmpColor = Colors.DarkGray //set all to black...
          this.state.openAppointments.forEach((openAppointment)=>{
            //...unless the appointment matches an open appointment
            if(appointment.id === openAppointment.id && openAppointment.type !== "CONFLICT"){
              tmpColor = null //then remove the color
            }
          })
        }
        //if it has a tmpColor, lets assign that in place of the service color
        color = tmpColor ?? color

        let style = {
          borderRadius: "4px",
          position: "absolute",
          left: window.innerWidth * 0.1 + w * day + 5, 
          top: top + this.state.marginTop, 
          backgroundColor: color,
          width: w - 10,
          height: height,
          border: border,
          zIndex: zIndex
        }
        if(multiSelected){
          style.boxShadow = '5px 5px 10px 5px rgba(0,0,0,0.75)'
        }
        if(selected){
          style.boxShadow = '4px 4px 8px 4px rgba(0,0,0,0.5)'
        }
        // noinspection JSUnresolvedReference
        if(appointment.type === "TIME"){
          style.zIndex = 0 //draw above meals, but not above appointments
          color = Colors.Primary.Main;
          style.backgroundColor = color
        }
        else { // noinspection JSUnresolvedReference
          if(appointment.type === "CONFLICT"){
                    style.background = `repeating-linear-gradient(
                      45deg,
                      #4d4d4d,
                      #4d4d4d 10px,
                      #595959 10px,
                      #595959 20px
                    )`;
                    style.border = "2px solid #404040"
                    style.zIndex = 0 //draw above meals, but not above appointments
                    style.backgroundColor = undefined
                  }
        }
        return style
      }

      /**
       *
       * @param appointment {AppointmentModel}
       * @returns {*|null}
       */
      maybeGetPrimaryProviderColor = (appointment) => {
        const service = this.state.serviceLookup[appointment.serviceId];
        if(!service) return null
        if(service && !service.usePrimary) return null
        if(appointment.occupants.length === 0 || appointment.occupants.length > 1) return null
        const occupancyRoomData = this.state.roomLookup[appointment.occupants[0].id]
        if(!occupancyRoomData) return null //patient is probably discharged

        //now find the patient's primary for this service type
        const primaryProvidersForService = occupancyRoomData.providers
            ? occupancyRoomData.providers[service.id]
            : null;
        const primaryProviderId = primaryProvidersForService && primaryProvidersForService.length > 0 ? primaryProvidersForService[0] : null;
        const primaryProvider = this.props.facility.providers[primaryProviderId];

        return primaryProvider ? primaryProvider.color : null
      }

      submitSwap = (appointmentId, appointmentDestination) => {
        console.log({
          swapFrom: appointmentId,
          swapTo: appointmentDestination
        })
        this.props.swapAppointmentWith(appointmentId, appointmentDestination)
      }

    /**
     *
     * @param e
     * @param id
     * @param appointment {AppointmentModel}
     * @returns {Promise<void>}
     */
      onClickBlob = async(e, id, appointment)=> {
        if(appointment.type==="CONFLICT") return
        if(this.state.swapMode){
          if(this.state.loadingBlob) return //suppress the click if we are currently loading the data
          let validSwap
          this.state.openAppointments.forEach((openAppointment)=>{
            if(openAppointment.id === appointment.id && openAppointment.type !== "CONFLICT"){
              validSwap = true
            }
          })
          if(validSwap){
            if(appointment.type === "TIME"){
              const clickedBlob = this.createClickedBlob(e, id);
              this.setState({swapSelectedBlob: clickedBlob, time: null})
            }
            else{
              this.submitSwap(this.state.clickedBlob.appointment.id, appointment)
            }
          }//else do nothing
        }
        else{
          //find service and store it
          let clickedService = Object.assign({},Helpers.getService(appointment.serviceId, this.props.facility.services))
          //modify the service slightly
          clickedService.pickLocation = false
          clickedService.providers = []

          const data = {title: appointment.title, options: [], allowedProviders: "Any"};
          for(let i = 0; i < (appointment.providers).length; i++){
            clickedService.providers.push(data)
          }

          //find occupancy(room) and store it
          let clickedOccupancies
          this.props.facility.rooms.forEach((room)=>{
            clickedOccupancies = []
            let found = !!appointment.occupants.find(occupant => room.occupancyId === occupant.id)
            if(!found) return
            clickedOccupancies.push(room)
          })

          const blob = this.createClickedBlob(e, id);
          if(e.ctrlKey || this.state.multiSelectMode){
            this.setState((prevState) => {
              let index
              for (let i = 0; i < prevState.clickedBlobs.length; i++) {
                if(appointment.id === prevState.clickedBlobs[i].appointmentId){
                  index = i
                }
              }
              if(index === undefined){
                prevState.clickedBlobs.push(blob)
                prevState.multiSelectMode = true
              }
              else{
                prevState.clickedBlobs.splice(index, 1)
              }
              return prevState
            })
          }
          else{
            //add stored data to state
            if(this.state.clickedBlob){
              await this.onClosePopup()
            }
            const loading = this.getServiceProviders(clickedService, appointment);
            this.setState(
                {
                  renderConfirmPrompt: false,
                  popupLoading: loading,
                  clickedBlob: blob,
                  appointment: window.structuredClone(appointment),
                  service: clickedService,
                  selectedRoom: clickedOccupancies.length > 0 ? clickedOccupancies[0] : null,
                  selectedRooms: clickedOccupancies
                }
            )
          }
        }
      }

      createClickedBlob = (e, id) => {
        console.log(this.state.blobs[id])
        const blob = window.structuredClone(this.state.blobs[id]);
        blob.clickedTime = blob.startTime
        const offsetY = e.nativeEvent.offsetY;
        const pageY = e.nativeEvent.pageY;
        this.getClickedTime(offsetY, pageY, blob)
        blob.startY = this.getTop(CalendarUtil.getShortTime(blob.clickedTime))
        const endTime = blob.appointment.duration ? blob.clickedTime + blob.appointment.duration : blob.endTime;
        blob.endY = this.getTop(CalendarUtil.getShortTime(endTime))
        blob.pageY = pageY
        blob.offsetY = offsetY
        return blob
      }

      onClosePopup = ()=>{
        return new Promise((resolve)=>{
          this.setState(
            {
              swapSelectedBlob: null,
              newSchedule: null,
              swapMode: false,
              openAppointments: null,
              clickedBlob: null, 
              appointment: null, 
              schedule: this.state.schedule, 
              time: null, 
              service: null,
              selectedRoom: null,
              blobs: {},
              columns: this.props.columns,
              allColumns: this.props.columns,
            }
            ,()=>{ //callback
              this.setState(
                {
                   //getColumnWidth uses state, so wait a frame...
                  columnWidth: this.getColumnWidth()
                },
                ()=>{
                  resolve()
                }
              )
            }
          ) 
        })
      }

      editTime = (time)=> {
        var clickedBlob = this.state.clickedBlob
        clickedBlob.startTime = time.start
        clickedBlob.endTime = time.end
        this.setState({time: time, clickedBlob: clickedBlob})
      }

      editedProviders = (providers)=> {
        this.setState({editingProviders: providers})
      }

      onSelectTime = ()=> {

      }

      onDelete = async(blob, refresh=true)=> {
        return new Promise((resolve)=>{
          this.setState({loadingBlob: true})
          LegacyAppointmentManager.cancelAppointment(blob.appointment.id, ()=>{
            if(refresh) this.props.refresh();
            resolve()
          })
        })
      }

      onStash = async(blob, refresh=true)=> {
        return new Promise((resolve)=>{
          this.setState({loadingBlob: true})
          LegacyAppointmentManager.stashAppointment(this.props.facility.id, blob.appointment.id, ()=>{
            if(refresh) this.props.refresh();
            resolve()
          })
        })
      }

      onUnstash = async(blob, refresh=true)=> {
        return new Promise((resolve, reject)=>{
          this.setState({loadingBlob: true})
          console.log(`unstashing appointment ${blob.appointment.id}`)
          LegacyAppointmentManager.unstashAppointment(this.props.facility.id, blob.appointment.id, (result)=>{
            console.log(JSON.stringify(result, undefined, 2))
            console.log(JSON.stringify(blob, undefined, 2))
            if(result.id && result.incomplete && !blob.appointment.incomplete){
              console.log("Unable to schedule unstashed appointment. Appointment has been marked as unassigned")
              //if we went from assigned and stashed, to unassigned and unstashed, show a toast about this event that occurred
              toast("Unable to schedule unstashed appointment. Appointment has been marked as unassigned")
              resolve()
            }
            else{
              console.log("Unstashed appointment")
              resolve()
            }
            if(refresh) this.props.refresh();
          })
        })
      }

      onAppointmentStaged = (appointment, previousAppointment)=> {
        LegacyAppointmentManager.updateAppointment(appointment, data => {
          this.props.refresh();
        })
      }
}