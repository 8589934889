import React from 'react';
import logo from '../../img/Icons/logo-dark.svg'
import CalendarUtil from '../../util/CalendarUtil';
import Helpers from '../../util/Helpers';
import { Table, Container, Row, Col, Button, Dropdown } from 'react-bootstrap'
import './PrintedAgendaPage.css'
import AgendaCard from '../../util/AgendaCard';
import { StringFlags, StringResources } from '../../strings/StringManager';

export default class PrintedAgendaPage extends React.Component {

    static Type = {
        SERVICE: 0,
        PATIENT: 1,
        PROVIDER: 2
    }

    constructor(props) {
        super(props)
        this.state = this.buildItems()
    }

    componentDidMount(){
        //need to trigger updates again, which will trigger times to redraw depending on the height of the appointments on the page
        this.setState({
            updated: new Date()
        })
    }

    buildItems() {
        var items = []

        var date = new Date(this.props.anchor)
        //outputs Day of week, Month Day 
        var readableDate = `${CalendarUtil.getDayOfWeekString(date.getDay(), false)}, ${CalendarUtil.getMonthString(date.getMonth(), true)} ${date.getDate()}`
        var identifier
        switch (this.props.type) {
            case PrintedAgendaPage.Type.PATIENT:
                var occupancyName = this.props.roomData.identifier
                if(occupancyName){
                    identifier = `${Helpers.getRoomName(this.props.roomData)} - ${occupancyName}`
                }
                else{ //user is guest, so we don't actually have room info to display
                    //make it say `Patient Schedule`, where patient is set to whatever the facility has set (patient/resident/occupant)
                    identifier = `${StringResources.patient(StringFlags.Capitalize)} Schedule`
                }
                break;
            case PrintedAgendaPage.Type.SERVICE:
                identifier = Helpers.getService(this.props.viewingId, this.props.services).name
                break;
            case PrintedAgendaPage.Type.PROVIDER:
                let provider = this.props.facility.providers[this.props.viewingId]
                identifier = this.getProviderName(provider)
                break;
            default:
                break;
        }
        var title = `${identifier} - ${readableDate}`
        /**
         * @type {AppointmentModel[]}
         */
        const agendaItems = this.props.agendaItems
        items = agendaItems.map((item) => {
            const linkedAppointmentsLabel = this.getLinkedAppointmentsInfo(item);
            let itemData = {}
            itemData.id = item.id
            itemData.title = item.title
            itemData.notes = item.notes
            itemData.linkedServices = linkedAppointmentsLabel
            itemData.duration = (item.end - item.start) / (1000 * 60) // ms to minutes
            itemData.time = CalendarUtil.getShortTime(item.start)
            let providerIds = item.providers?.map(p => p.id)
            let providerNames = ""
            if (providerIds && providerIds.length > 0) {
                let providerLookup = this.props.facility.providers
                providerNames = providerIds.reduce((array, providerId) => {
                    let provider = providerLookup[providerId]
                    if(provider){
                        array.push(this.getProviderName(provider))
                    }
                    return array
                }, [])
            }

            let subTitle = null
            if (item.serviceId) {
                let service = Helpers.getService(item.serviceId, this.props.facility.services)
                let locationName = this.getLocationName(item, this.props.facility.locations, service)
                let patientNames
                if(this.props.type !== PrintedAgendaPage.Type.PATIENT && item.occupants.length > 0){
                    patientNames = AgendaCard.getRoomName(item, true, true)
                }
                let durationMs = item.end - item.start
                let durationMinutes = durationMs / (60 * 1000)
                subTitle = service.fullDay ? "All Day" : `${durationMinutes} min`
                if(patientNames) {
                    subTitle += ` - ${patientNames}`
                }
                if (locationName || providerNames) subTitle += " - "
                if (locationName) {
                    subTitle += locationName
                }
                if (providerNames) {
                    if (locationName) subTitle += ", "
                    providerNames.forEach((providerName, index) => {
                        subTitle += providerName
                        if (index + 1 < providerNames.length) {
                            subTitle += ", "
                        }
                    });
                }
            }
            itemData.subTitle = subTitle
            return itemData
        })

        return {
            title: title,
            items: items
        }
    }

    getProviderName(provider) {
        return `${provider.firstName} ${provider.lastName[0]}.`
    }

    /**
     *
     * @param item {AppointmentModel}
     * @param locations {*}
     * @param service {*}
     * @returns {string|null|*}
     */
    getLocationName(item, locations, service){
        let locationId
        if(item.locations && item.locations.length > 0)
            locationId = item.locations[0].id
        return Helpers.shouldShowSingleOccupantAsLocation(item, service)
            ? (this.props.type !== PrintedAgendaPage.Type.PATIENT ? `${StringResources.patient(StringFlags.Capitalize)} Room` : null)
            : this.props.facility.locations[locationId]?.name ?? null
    }

    /**
     * Get linked appointment service names, as a comma delimited string
     * @param {AppointmentModel} appointment
     */
    getLinkedAppointmentsInfo(appointment){
        let label = null;
        if(appointment.linkedAppointments && appointment.linkedAppointments.length > 0){
            let serviceIds = []
            appointment.linkedAppointments.forEach((linkedAppt)=>{
                if(!serviceIds.includes(linkedAppt.ServiceId)) serviceIds.push(linkedAppt.ServiceId)
            })
            let serviceNames = serviceIds.map((serviceId)=>{
                const service = Helpers.getService(serviceId, this.props.facility.services);
                return service ? service.name : null
            })
            label = serviceNames.join(", ")
        }
        return label
    }

    render() {
        const title = this.state.title;
        const items = this.state.items;
        const tableContents = [];
        items.forEach((item) => {
            let delimiter;
            let subTitle = item.subTitle ? item.subTitle : '';
            if(item.linkedServices){
                delimiter = subTitle ? ' - ' : '';
                subTitle += `${delimiter}${item.linkedServices}`
            }
            if(item.notes){
                delimiter = subTitle ? ' - ' : '';
                subTitle += `${delimiter}${item.notes}`
            }
            const time = (<div className="printedAgendaItemTime">{item.time}</div>);
            const appointment = (
                <div className="printedAgendaItemContents printedAgendaDivider">
                    <div className="printedAgendaItemTitle">
                        {item.title}
                    </div>
                    <div className="printedAgendaItemDescription">
                        {subTitle}
                    </div>
                </div>
            );
            tableContents.push(
                <tr>
                    <td align="start">{time}</td>
                    <td>{appointment}</td>
                </tr>
            )
        })
        return (
            <div style={{pageBreakAfter: 'always'}} align="center" zIndex="1">
                <div className="printedAgendaContainer">
                    <div className="printedAgendaHeader">
                        <img 
                            src={logo} 
                            className="printedAgendaLogo" 
                            onLoad={()=>{
                                if(this.props.onReady) this.props.onReady()
                            }} 
                            onError={()=>{
                                if(this.props.onReady) this.props.onReady()
                            }}/>
                        <div className="printedAgendaTitle">{title}</div>
                    </div>
                    <Table borderless className="printedAgendaBody">
                        {tableContents}
                    </Table>
                </div>
            </div>
        )
    }
}