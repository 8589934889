import {ScheduleColumn, ScheduleColumnImpl} from "./ScheduleColumn";
import {AppointmentRepository} from "../repositories/AppointmentRepository";

export interface ScheduleDictionary{
    activities: ScheduleColumn|undefined
    [key: string]: ScheduleColumn|undefined
}

export class ScheduleDictionaryHelpers{
    static clone(obj: ScheduleDictionary): ScheduleDictionary{
        const newObj = {} as ScheduleDictionary
        for(let [key, scheduleCol] of Object.entries(obj)){
            if(!scheduleCol) continue
            let newScheduleCol = new ScheduleColumnImpl(scheduleCol.id, scheduleCol.label)
            let scheduleAppointmentHolders = scheduleCol.list()
            for(let scheduleAppointmentHolder of scheduleAppointmentHolders){
                let newAppt = window.structuredClone(scheduleAppointmentHolder.get())
                AppointmentRepository.convertFields(newAppt)
                newScheduleCol.pushAppointmentModel(newAppt)
            }
            newObj[key] = newScheduleCol
        }
        return newObj
    }

    static getSortedScheduleColumns(obj: ScheduleDictionary): ScheduleColumn[]{
        let keys = Object.keys(obj)
        let scheduleColumns = keys.map((key) => obj[key])
        scheduleColumns = scheduleColumns.filter((col) => col !== undefined) as ScheduleColumn[]
        return scheduleColumns as ScheduleColumn[]
    }
}