import React, {ChangeEvent} from "react";

class Props {
    startAt: number = 0
    maxSelectableDay: number = 30
    disabled: boolean = false
    value: number = 0
    onFrequencyChange: (value: number) => void = () => {}
    className?: string
}

const DayDropdown = ({disabled, startAt, maxSelectableDay, onFrequencyChange, value, className}: Props) => {
    return <div className={className}>
        <select
            disabled={disabled}
            value={value}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                const optionSelected = event.target.selectedIndex + startAt;
                onFrequencyChange(optionSelected)
            }}
            id={'count'}
            style={{width: 140, fontSize: 12}}>
            {Array.apply(null, Array(maxSelectableDay - startAt + 1))
                .map((_, i) => i + startAt)
                .map((value) => (<option value={value}>{value}</option>))}
        </select>
    </div>
}

export {DayDropdown}