export const ZeroMinuteSchedulesMachine = {
    initial: 'SelectSchedule',
    states: {
        SelectSchedule: {
            on: {
                EDIT_AUTO_SCHEDULE: 'EditAutoSchedule',
                EDIT_SIMPLE_SCHEDULE: 'EditSimpleSchedule'
            }
        },
        EditAutoSchedule:{
            on:{
                CANCEL: 'SelectSchedule'
            }  
        },
        EditSimpleSchedule:{
            on:{
                CANCEL: 'SelectSchedule'
            }
        },
        stop: {

        }
    }
}