import {API, Auth} from 'aws-amplify';
import Tracker from './Tracker';
import Timer from '../util/Timer';
import {toast} from 'react-toastify';

/**
 * Making a network call
 * 2 methods:
 * 
 * 1. call the function, and use onResult, and onError callbacks. If onError is not used, the error will be returned using onResult
 * 2. call the function, and await for result. Returns an object that contains a JSON of {error, result}, where only one is valid
 * 
 * 4 different calls:
 * - get: puts params in queryStringParameters
 * - post: puts params in body
 * - put: puts params in body
 * - delete: puts params in body
 */
export default class NetworkManager {
    static API_KEY = 'Api'

    static get = async(
        path: string,
        params: {[key: string]: any},
        onResult?: ((result : any) => void)|undefined,
        onError?: ((result : any) => void)|undefined
    ) => {
        const timer = Timer.start();
        let query = {
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: params
        }
        await NetworkManager.maybeAttachAuthorizationHeaders(query)
        let pendingRequest = API.get(NetworkManager.API_KEY, path, query) //don't await here
        return await NetworkManager.handleResponse('GET', path, pendingRequest, timer, onResult, onError)
    }

    static post = async(
        path: string,
        params: {[key: string]: any},
        onResult?: ((result : any) => void)|undefined,
        onError?: ((result : any) => void)|undefined
    ) => {
        const timer = Timer.start();
        let query = {
            body: params
        }
        await NetworkManager.maybeAttachAuthorizationHeaders(query)
        let pendingRequest = API.post(NetworkManager.API_KEY, path, query) //don't await here
        return await NetworkManager.handleResponse('POST', path, pendingRequest, timer, onResult, onError)
    }

    static put = async(
        path: string,
        params: {[key: string]: any},
        onResult?: ((result : any) => void)|undefined,
        onError?: ((result : any) => void)|undefined
    ) => {
        const timer = Timer.start();
        let query = {
            body: params
        }
        await NetworkManager.maybeAttachAuthorizationHeaders(query)
        let pendingRequest = API.put(NetworkManager.API_KEY, path, query) //don't await here
        return await NetworkManager.handleResponse('PUT', path, pendingRequest, timer, onResult, onError)
    }

    static delete = async(
        path: string,
        params: {[key: string]: any},
        onResult: (result : any) => void,
        onError: (result : any) => void
    ) => {
        const timer = Timer.start();
        let query = {
            body: params
        }
        await NetworkManager.maybeAttachAuthorizationHeaders(query)
        let pendingRequest = API.del(NetworkManager.API_KEY, path, query) //don't await here
        return await NetworkManager.handleResponse('DEL', path, pendingRequest, timer, onResult, onError)
    }

    static convertTime = function(time: number){
        const d = new Date(time);
        const offsetMs = (d.getTimezoneOffset() * 60 * 1000); //remove an offset (which is in minutes) and convert it to milliseconds
        return d.getTime() + offsetMs
    }

    static maybeAttachAuthorizationHeaders = async function(query: any){
        let auth = undefined
        try{
            auth = await Auth.currentSession() as any
        }
        catch{
            console.log("No Auth detected, must be guest")
        }
        if(auth){
            if(!query.headers) query.headers = {}
            query.headers.Authorization = auth.idToken.jwtToken
        }
    }

    static handleResponse = async function(
        label: string,
        path: string,
        apiPromise : any,
        timer: any,
        onResult?: ((result : any) => void)|undefined,
        onError?: ((result : any) => void)|undefined
    ){
        let result
        let error
        try{
            let response = await apiPromise
            if (response && response.statusCode) { 
                Tracker.logRequestError(label, path, timer.getElapsed(), response.message) 
                if(response.statusCode === 403) toast(response.message)
            }
            else { Tracker.logRequest(label, path, timer.getElapsed()) }
            result = response
        }
        catch(e: any){
            Tracker.logRequestError(label, path, timer.getElapsed(), e.message)
            console.error(`${label} - ${path}: error ${e.message}`);
            console.error(e.response?.data)
            error = e.response?.data ?? e
        }
        if(error && onError) onError(error)
        else if(onResult){
            if(error){
                result = {
                    statusCode: 500,
                    message: error
                }
            }
            onResult(result)
        }
        return {error: error, result: result}
    }
}