import React from 'react'
import { Row, Col } from 'react-bootstrap';
import MultiSelect from "@khanacademy/react-multi-select";

export default class SDMultiSelect extends React.Component{
    render(){
        this.options = []
        this.selected = []
        this.props.allItems.forEach(item => {
            var option = {
                'label': this.props.labelRenderer(item),
                'value': this.props.valueRenderer(item)
            }
    
            this.options.push(option)
            if(!this.props.filteredItems.includes(option.value)) this.selected.push(option.value)
        })

        return (
            <Row style={this.props.style}>
                <Col>
                    <MultiSelect
                        valueRenderer={this.props.visibleValueRenderer??(()=>this.props.defaultValue)}
                        isLoading={this.props.isLoading}
                        options={this.options}
                        selected={this.selected}
                        onSelectedChanged={this.onSelectedChanged}
                        disableSearch={true}
                        overrideStrings={{
                            selectSomeItems: this.props.selectSomeItemsTitle ?? this.props.defaultValue,
                            allItemsAreSelected: this.props.allItemsSelectedTitle ?? this.props.defaultValue,
                            selectAll: this.props.selectAllTitle ?? "Select All"
                        }}
                    />
                </Col>
                {this.props.children}
            </Row>
        )
    }

    onSelectedChanged = (selected) => {
        var prevSelected = this.selected
        var newSelected = selected

        var removed = prevSelected.filter(x => !newSelected.includes(x))
        var added = newSelected.filter(x => !prevSelected.includes(x))

        var filteredItems = Object.assign([],this.props.filteredItems)

        removed.forEach(e => {
            filteredItems.push(e)
        })

        added.forEach(e => {
            filteredItems.splice(filteredItems.indexOf(e), 1)
        })

        this.props.onSelectionsChanged(filteredItems)
    }
}