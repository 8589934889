import React from "react"
import WindowUtil from "../../util/WindowUtil";
import styles from "./CircleBackground.module.css";
import mobileStyles from "./CircleBackground-mobile.module.css";

export default class CircleBackground extends React.Component{
    render(){
        let style = WindowUtil.isMobile() ? mobileStyles : styles
        return <div class={style.background}>
            <div class={style.overlay}/>
            {this.props.children}
        </div>
    }
}