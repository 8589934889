import React from 'react';
import {Navbar} from 'react-bootstrap'
import Colors from '../util/Colors'

export default class SDSubNavBar extends React.Component{
    static HeaderHeight = 40

    static navigationOptions = {
      headerTitleStyle: { alignSelf: 'center' },
      textAlign:"center",
      verticalAlign:"center",
      flex:1,
      padding:0
    }
  
    render(){
  
      if(this.props.title === "" || !this.props.title) return null
  
      return(
        <Navbar style={{backgroundColor: Colors.NavGray, left:this.props.scrollX}}>
        <Navbar.Text style={SDSubNavBar.navigationOptions}>
         {this.props.title}
         </Navbar.Text>
      </Navbar>
      )
    }
  }