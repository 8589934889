import NetworkManager from "./NetworkManager";
import ProviderManager from "./ProviderManager";
import FacilityManager from "./FacilityManager";
import { Endpoint } from '../util/Constants';
import Helpers from "../util/Helpers";
import CacheManager from "./CacheManager";

export default class AdminManager {

    static getBilling(facilityId, start, end, onResult) {
        var path = Endpoint.base + '/billing'

        var params = {
            facility: facilityId,
            start: start, 
            end: end
        }

        NetworkManager.get(path, params, (result) => {
            onResult(result);
        })
    }

    static getExistingUser(email, onComplete) {
        var path = Endpoint.base + "/admin/check-email";
        var params = {
            email: email
        };
        NetworkManager.get(path, params, (result) => {
            onComplete(result)
        })
    }

    static registerUser(email, password, firstName, lastName, title, role, color, schedulable, services, facilityId, onComplete, dontCache) {
        var path = Endpoint.base + "/admin/register-user";

        var params = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            title: title,
            schedulable: schedulable,
            facility: facilityId,
        };

        if (password) {
            params['password'] = password
        }

        NetworkManager.post(path, params, (result) => {
            let userId = result.UserSub;

            if (userId) {
                new AdminManager()._updateUserServices(userId, facilityId, role, color, null, services, (result) => {
                    if (result.error) { onComplete(result); }
                    else {
                        onComplete({
                            error: false,
                            message: "User created!"
                        });
                    }
                }, dontCache);
            }
            else {
                onComplete({
                    error: true,
                    message: result.message
                });
            }
        });
    }

    static updateUser(id, email, firstName, lastName, title, role, color, schedulable, oldServices, services, facilityId, onComplete, dontCache) {
        var path = "/v1/admin/update-user";

        var params = {
            "id": id,
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "title": title,
            "schedulable": schedulable,
            "facility": facilityId
        };

        NetworkManager.post(path, params, (result) => {
            new AdminManager()._updateUserServices(id, facilityId, role, color, oldServices, services, (result) => {
                if (result.error) { onComplete(result); }
                else {
                    onComplete({
                        error: false,
                        message: "User updated!"
                    });
                }
            }, dontCache);
        });
    }

    static disableUser(id, facilityId, services, onComplete, dontCache) {
        var path = "/v1/admin/delete-user";

        var params = {
            "id": id
        }

        NetworkManager.post(path, params, (result) => {
            new AdminManager()._updateUserServices(id, facilityId, "disabled", null, null, services, result => {
                onComplete(result);
            }, dontCache);
        });
    }

    _updateUserServices(providerId, facilityId, role, color, oldServices, services, onComplete, dontCache) {
        if (services) {
            var servicePromises = [];

            //find services that are not in new, then remove them
            if(oldServices){
                Helpers.findNotMatching(services, oldServices).forEach((serviceId)=>{ 
                    servicePromises.push(
                        new Promise((resolve, reject) => {
                            ProviderManager.removeService(providerId, serviceId, result => resolve(result));
                        })    
                    );
                })
            }
            
            //find services that are not in old, then add them
            var newServices = oldServices ? Helpers.findNotMatching(oldServices, services) : services
            newServices.forEach((serviceId)=>{
                servicePromises.push(
                    new Promise((resolve, reject) => {
                        ProviderManager.addService(providerId, serviceId, result => resolve(result));
                    })    
                );
            })

            //wait for all to be done, then add provider to facility
            Promise.all(servicePromises).then(() => {
                FacilityManager.addProvider(facilityId, role, color, providerId, result => {
                    CacheManager.get().fetchSkilledDayData(facilityId, true, false, false).then(()=>{
                        onComplete(result);
                    })
                });
            });
        }
        else {
            FacilityManager.addProvider(facilityId, role, color, providerId, async(result) => {
                if(!dontCache) await CacheManager.get().fetchSkilledDayData(facilityId, true, false, false)
                onComplete(result);
            });
        }
    }
}