import React from 'react';

import Colors from '../../util/Colors'
import Tracker from '../../managers/Tracker';
import AlertsPage from '../AlertsPage';

import OptionsList from './OptionsList'
import HomeOptions from './HomeOptions';
import './homePage.css'
import {VersionInfoUI} from "../../ui/VersionInfo";

export default class HomePage extends React.Component {

  constructor(props){
    super(props)

    Tracker.logScreenView('home_page')

    document.body.style = 'background:' + Colors.NavGray + ';';

    var allOptions = HomeOptions.getAccessListWithEvents()

    this.state={
      allOptions: allOptions
    }
  }

  render(){
    if(!this.props.availableOptions || Object.keys(this.props.availableOptions).length == 0) return <div></div> //could be guest
    //init our available options. Normally this would be done in the constructor, but apparently props can be dynamic...
    var renderOptions = []
    Object.keys(this.state.allOptions).forEach((optionKey)=>{ //get our options from state, defined from constructor
      var option = this.state.allOptions[optionKey]
      if(HomeOptions.canUserAccess(optionKey, this.props.availableOptions)){ //find the accessId in the availableOptions array in props
        renderOptions.push(optionKey) //push it to renderOptions
      }
    })

    return(
      <div className={`homeContainer-${window.innerWidth>900?"wide":"skinny"}`}>
        <div className='optionsContainer'>
          <OptionsList allOptions={this.state.allOptions} availableOptions={renderOptions} machine={this.props.machine} onOptionSelected={this.props.onOptionSelected}/>
          <div className='versionContainer'>
            <VersionInfoUI/>
          </div>
        </div>
        <div className='alertContainer'>
          {this.props.machine.state.value === "Init" ? null : (
            <AlertsPage isEdit={false} facilityId={this.props.facility.id}/>
          )}
        </div>
      </div>
    )
  }
}