import React from 'react'
import './RolesDropdown.css'
import Helpers from '../../../util/Helpers'

export default class RolesDropdown extends React.Component{
    
    renderOptions(items, itemClassName){
        var role = null
        items.forEach((e)=>{
          if(e == this.props.role){
            role = {
              name: Helpers.getRoleNameFromId(e),
              id: e
            }
          }
        })
        var options = []
        if(!role){
          itemClassName+="-empty"
          role = {
            name: "Select Role ​", //space and a zero width space to force text over to prevent cutoff. Just a space appears to be ignored
            id: ''
          }
        }
        
        options.push(<option className={'role-dropdown-option-hide-text'} value={role.id}>{role.name}</option>)
        items.forEach((id) => {
          var name = Helpers.getRoleNameFromId(id)
          options.push(<option className={'role-dropdown-option'} value={id}>{name}</option>)
        })
    
        options.sort((a, b) => {
          return (a.props.children > b.props.children) ? 1 : -1
        })
    
        return (
          <select disabled={this.props.disabled} className={`${itemClassName}-dropdown`} value={role.id} onChange={(e)=> {this.onChange(e.target.value)}}>
            {options}                   
          </select>
        )
    }

    renderDropdown(items){
        return (
            <div>
                {this.renderOptions(items, `role-dropdown-fallback`)}
            </div>
        )
    }

    render(){
      return this.renderDropdown(this.props.items)
    }

    onChange = (providerId)=>{
      this.props.onRoleChanged(providerId)
    }
}