/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "54fdba6a73c04b57a5ebb4a7e2270af2",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "54fdba6a73c04b57a5ebb4a7e2270af2",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "Api",
            "endpoint": "https://i5ydy5undc.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f852975f-cd62-4635-a80c-77a3069b9575",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mwePFtzWR",
    "aws_user_pools_web_client_id": "1phpco5teuuode3d7j41ltnb2c",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "skilleddayapp-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://skilleddayapp-production.s3-website-us-east-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AppointmentRecurrenceTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "AppointmentsTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "ConfigTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "facilities-production",
            "region": "us-east-1"
        },
        {
            "tableName": "FacilityAlerts-production",
            "region": "us-east-1"
        },
        {
            "tableName": "FacilityProviderAuxTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "FacilityRoomOccupancy-production",
            "region": "us-east-1"
        },
        {
            "tableName": "FacilityServiceAuxTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "FacilityServiceProvider-production",
            "region": "us-east-1"
        },
        {
            "tableName": "FacilityTimeRestrictionsTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "LocationsTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "ProviderServiceTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "ProvidersTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "RoomFacilityAuxTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "ServicesTable-production",
            "region": "us-east-1"
        },
        {
            "tableName": "rooms-production",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
