export const ManageActivitiesMachine = {
    initial: 'Load',
    states: {
        Load: {
            on: {
                RESOLVE: 'Schedule',
                REJECT: 'stop'
            }
        },
        Schedule: {
            on: {
                FINISH: 'stop',
                CANCEL: 'stop',
                POPUP: 'SchedulePopup'
            }
        },
        SchedulePopup: {
            on: {
                CLOSE: 'Schedule'
            }
        },
        stop: {}
    }
}