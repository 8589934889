class Path {
    static HOME = "/";
    static MANAGE_PATIENTS = "/managepatients/";
    static SCHEDULE_APPOINTMENT = "/scheduleappointment/";
    static AGENDA = "/agenda/";
    static PATIENT_EVALUATION = "/patientevaluation/";
    static FACILITY_SCHEDULE ="/facilityschedule/";
}

export class Endpoint {
    static base = "/v1";

    static setAuthenticated(authenticated: boolean) {
        Endpoint.base = authenticated ? "/v1" : "/v1/guest"
    }
}

export default Path;