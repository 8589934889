import React from 'react'
import './columnsRenderer.css'

export default class ColumnsRenderer extends React.Component {

    render() { 
        var columns = this.props.columns
        var title = this.props.titleElement
        var titleRowPlaceholder = this.props.titlePlaceholderElement
        
        var initialArray = [] //might need elements placed before content in array
        if(title){
            // Create an array of elements that include the title element.
            // To make it align with the first column, we will treat it as its own row
            // Push the title, then push the amount remaining in a row as placeholders, based on columns
            initialArray.push(title)
            for(var titleIndex = 1; titleIndex < columns; titleIndex++){
                initialArray.push(titleRowPlaceholder)
            }
        }
        var items = initialArray.concat(this.props.children)
        
        var columnRender = []
        for(var i = 0; i < columns; i++){
            var column = []
            for(var j = 0; j < items.length; j++){
                if(j % columns == i){
                    column.push(items[j])
                }
            }
            columnRender.push(column)
        }
        return (
            <div className='columns-container'>
                {columnRender.map((column)=>{
                    return <div className='single-column-list'>
                        {column}
                    </div>
                })}
            </div>
        )
    }
}