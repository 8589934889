import React from 'react';
import LoadingSpinner from '../../util/LoadingSpinner';
import FacilityManager from '../../managers/FacilityManager'
import CacheManager from '../../managers/CacheManager';
import Helpers from '../../util/Helpers';
import ItemList from '../../ui/ItemList';
import EditItem from '../../ui/EditItem';
import '../../ui/editItem.css'
import StringManager, { StringFlags, StringResources } from '../../strings/StringManager';

export default class LocationsPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            items: []
        }
        this.refreshData(true)
    }

    async refreshData(force){ //called by super class
        this.setState({loading: true})
        if(force){
            await CacheManager.get().fetchSkilledDayData(this.props.facility.id, false, false, false)
        }
        var locations = JSON.parse(JSON.stringify(Object.values(this.props.facility.locations)))
        locations = Helpers.sortLocations(locations, false, true)
        this.setState({loading: false, items: locations})
    }

    createItemAndEdit = ()=> {
        this.setState({
            editingItem: {
                name: '',
                facilityId: this.props.facility.id
            }
        })
        this.props.machine.send("NEW_LOCATION")
    }

    editExisting = (item) => {
        this.setState({
            editingItem: item
        })
        this.props.machine.send("EDIT_LOCATION")
    }

    cancelEdit = ()=> {
        this.setState({loading: false})
        this.props.machine.send("FINISH")
        this.refreshData()
    }

    submitEdit = (item)=> {
        this.setState({loading: true})
        if(item.id){
            FacilityManager.updateLocation(item, (result)=>{
                if(result.error)
                    this.setState({loading: false, errors: [result.error]})
                else{
                    this.refreshData()
                    this.props.machine.send("FINISH")
                }
            })
        }
        else{
            FacilityManager.createLocation(this.props.facility.id, item.name, " ", [], item.disabled, (result)=>{
                if(result.error)
                    this.setState({loading: false, errors: [result.error]})
                else{
                    this.refreshData()
                    this.props.machine.send("FINISH")
                }
            })
        }
        
    }

    renderButtons(){
        return this.state.items.map(v => {
            var extraText = v.disabled ? "(Disabled)"
                : ""
            return {
                disabled: false,
                item: v,
                style: {borderColor:"#000000", width: 300, marginTop: 15},
                title: `${v.name} ${extraText}`
            }
        })
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>
        const state = this.props.machine.state.value["AdminPanel"]
        var items = this.renderButtons()
        switch(state){
            case "Locations": 
                return <ItemList navigationBackTitle="Manage Facility" items={items} title="Location" onNewItem={this.createItemAndEdit} onExistingItem={this.editExisting} onCancel={this.backPressed}/>
            case "NewLocation": 
            case "EditLocation": 
                return <EditItem title='Location' navigationBackTitle="Manage Location" items={this.state.items} canDisable={true} item={this.state.editingItem} onSubmit={this.submitEdit} onCancel={this.cancelEdit}>
                    <div className="itemDescriptionText">Turn this off to prevent {StringResources.patient(StringFlags.Plural)} from being scheduled at this location.</div>
                </EditItem>
        }
    }

    backPressed =  () => {
        this.props.machine.send("FINISH")
    }
}