import React  from 'react';
import Colors from '../util/Colors'
import CalendarUtil from '../util/CalendarUtil'
import LoadingSpinner from '../util/LoadingSpinner'
import OnboardingCalendar from './OnboardingCalendar';
import DateSwitcher from '../ui/DateSwitcher';
import DateAndTime from '../util/DateAndTime';

export default class SchedulingCalendar extends React.Component{
    static defaultFallbackDuration = 30 //minutes
    constructor(props){
        super(props)
        this.timelineIndicatorRef = React.createRef();
        const dates = this.getTimeSlots(props);

        let columns = props.columns;
        if(columns === 0) columns = 1

        this.state={
            defaultAppointmentDuration: props.defaultDuration ?? SchedulingCalendar.defaultFallbackDuration, //determines the default selection size in minutes when clicked.
            allColumns: columns,
            columns: columns,
            timeRange: dates,
            blobs: {}, 
            clickedBlob: {},
            multiSelectMode: false,
            clickedBlobs: [],
            schedule: props.schedule,
            time: null,
            scrollX: 0,
            scrollY: 0,
            restoreScroll: false,
            marginTop: 0,
            staticMarginTop: 0, //used for elements where we should not have a dynamic margin
            scrollWindowAdditionalMarginBottom: 0,
            filteredRooms: [],
            zIndexLines: -1,
            zIndexBaseBlob: 1,
            zIndexHeader: 10
        }

        //Check for a parent element, and check if it has old scroll values
        if(props.parent && (props.parent.scrollY != undefined || props.parent.scrollX != undefined)){
            this.state.restoreScroll = true //if so, we will use them to restore the layout. 
            //Without this, our layout will snap back to the top whenever an onboarding appointment is added
        }

        this.state.columnWidth = this.getColumnWidth()
        this.state.rowHeight = this.getRowHeight()

        this.onResize = this.onResize.bind(this)
    }

    getTimeSlots(){
        //fallback open/close time range of 6AM - 8PM
        let startTotal = 6 * 60 * 60 * 1000;
        let endTotal = 20 * 60 * 60 * 1000;

        if(this.props.facility.operatingTimes){
            startTotal = this.props.facility.operatingTimes.start
            endTotal = this.props.facility.operatingTimes.end
        }

        startTotal = startTotal / 60 / 1000
        const startHours = Math.floor(startTotal / 60);
        const startMinutes = startTotal % 60;

        endTotal = endTotal / 60 / 1000
        const endHours = Math.floor(endTotal / 60);
        const endMinutes = endTotal % 60;

        const start = new Date();
        start.setHours(startHours)
        start.setMinutes(startMinutes)
        start.setSeconds(0)
        start.setMilliseconds(0)

        const end = new Date();
        end.setHours(endHours)
        end.setMinutes(endMinutes)
        end.setSeconds(0)
        end.setMilliseconds(0)

        let ms = start.getTime();

        const dates = [];

        for(let i = start.getHours(); i < end.getHours() + 1; i++){
            const newDate = new Date(ms);
            dates.push(newDate)
            ms += 60 * 60 * 1000
        }

        return dates
    }

    getClickedTime(offsetY, pageY, blob){
        console.log("getClickedTime", offsetY, pageY, blob)
        /**
         * @type {AppointmentModel}
         */
        const appointment = blob.appointment;
        const h = blob.height;
        const st = appointment.start.getTime?.() ?? appointment.start;
        const et = appointment.end.getTime?.() ?? appointment.end;

        const interval = 15;
        const durationMs = appointment.duration ??
            (appointment.hasOwnProperty("id") // if existing appointment
                ? et - st                     // calculate duration
                : (this.state.defaultAppointmentDuration ?? SchedulingCalendar.defaultFallbackDuration) * 60 * 1000
            )
        
        //round to nearest default duration (defined by this.state.defaultAppointmentDuration)
        const d = new Date(st + (offsetY / h) * (et - st));
        d.setSeconds(0)
        d.setMilliseconds(0)
        let r;
        if(blob.type === 0 || blob.type === 1 || blob.type === 2){
            r = CalendarUtil.roundTime(d.getTime(), interval)
            if(r < appointment.start) r = appointment.start
            if(r >= appointment.end || r+durationMs >= appointment.end) r = appointment.end - durationMs
        }else{
            r = appointment.start
        }

        blob.timeAtClick = Date.now()
        blob.pageY = pageY
        blob.clickedTime = r
        blob.clickedY = pageY+this.state.scrollY
    }

    getRowHeight(){
        return Math.max(150, 2 * (window.innerHeight - 100) / this.state.timeRange.length)
    }

    getColumnWidth(){
        let columns = this.state.allColumns;
        /**
         * @type {ScheduleColumn[]}
         */
        let schedule = this.state.newSchedule ?? this.props.schedule

        schedule.forEach(e => {
          if(this.props.filteredRooms != null && this.props.filteredRooms.includes(e.id)) columns--
        })
  
        if(columns < 1) columns = 1

        this.state.columns = columns

        return Math.max(100, window.innerWidth * 0.8 / Math.min((this instanceof OnboardingCalendar) ? 7 : this.state.displayColumns, columns))
    }

    componentDidMount(){
        window.addEventListener('resize', this.onResize)
        this.onResize().then(()=>{
            if(this.timelineIndicatorRef.current){
                const top = this.timelineIndicatorRef.current.getBoundingClientRect().top;
                const height = this.getRowHeight();
                this.scrollElement.scrollTo(0, top-height*2.3)
            }
        })
    }
    
    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize)
    }

    async onResize() {    
        return new Promise((resolve)=>{
            this.state.displayColumns = window.innerWidth / 300
            this.setState({
                columnWidth: this.getColumnWidth(),
                rowHeight: this.getRowHeight()
            },resolve)
            if(this.scrollListener)
                this.scrollListener()
        })
    }

    //override default values for the full day code
    handleFullDayOverride(){
        this.state.defaultAppointmentDuration = SchedulingCalendar.defaultFallbackDuration
        //override the time slots to 2 times. 1 time, and then another for the same duration as the first
        this.getTimeSlots = () => {
            const dayStart = new Date();
            dayStart.setHours(6,0,0,0)
            dayStart.setDate(dayStart.getDate())

            const dayEnd = new Date();
            dayEnd.setHours(6,SchedulingCalendar.defaultFallbackDuration,0,0)
            dayEnd.setDate(dayEnd.getDate())
            return [dayStart, dayEnd]
        }
        const nullCallback = () => {
            return null
        };
        //Disable horizontal lines
        this.drawHorizontalLines = nullCallback
        //Disable vertical lines
        this.drawVerticalLines = nullCallback
        //Disable draw times
        this.drawTimes = nullCallback
        this.drawCurrentTimeLabel = nullCallback
        this.drawCurrentTimeLine = nullCallback

        this.super = {} //stores super functions

        //Add a super method for row height to preserve the original this.getRowHeight
        this.super.getRowHeight = this.getRowHeight.bind(this) //also bind `this` to it

        //override this.getRowHeight. Multiplies original function by a value
        this.getRowHeight = () => {
            return this.super.getRowHeight()*3 //expand the height for the row, defined by the original function
        }

        //set our times and row height, have to put here to override anything already set
        this.state.rowHeight = this.getRowHeight()
        this.state.timeRange = this.getTimeSlots()
    }

    getHeaderStyle(day, customMargin, customWidth){
        const w = this.state.columnWidth
        if(!customMargin)
            customMargin = 0
      
        return {
            zIndex: this.state.zIndexHeader,
            backgroundColor: '#fff',
            textAlign: "center",
            color: Colors.Primary.Main, 
            position:"absolute", 
            left: window.innerWidth * 0.1 + w * day,
            top:0, 
            width: customWidth ?? w,
            height: 60
        }
      }

      editTime = (time) => {
        this.setState({
            time: time
        })
    }

      render(scrollViewChild, optionsUI){
        if(this.scrollElement && this.scrollListener)
            this.scrollElement.removeEventListener('scroll', this.scrollListener)

        if(this.props.loading || this.state.loading) return <LoadingSpinner/>

        const now = new Date()
        let hours = now.getHours() + now.getMinutes() / 60
        hours -= (this.state.timeRange[0].getHours() - 1)

        const nowHeight = hours * this.state.rowHeight - 100
        const isToday = CalendarUtil.areDatesTheSameDay(this.props.anchorDate, new Date())
        const scrollRef = (element)=>{
            if(element){
                this.scrollElement = element
                if(this.state.restoreScroll){ //If set to restore
                    //Grab our scroll values from the parent. We need this in case the parent forced a redraw
                    if(this.props.parent){
                        element.scrollLeft = this.props.parent.scrollX
                        element.scrollTop = this.props.parent.scrollY
                    }
                    else{
                        element.scrollLeft = this.state.scrollX
                        element.scrollTop = this.state.scrollY
                    }
                    this.setState({
                        restoreScroll: false,
                        scrollX: element.scrollLeft,
                        scrollY: element.scrollTop
                    })
                    return
                }
                this.scrollListener = ()=> {
                    const { scrollLeft, scrollTop } = element
                    this.scrollElement.removeEventListener('scroll', this.scrollListener)

                    this.setState({
                        scrollX: scrollLeft,
                        scrollY: scrollTop
                    })
                    if(this.props.parent){ //Store our scroll values inside the parent so we can retrieve the scroll values after we were destroyed
                        this.props.parent.scrollY = scrollTop
                        this.props.parent.scrollX = scrollLeft
                    }
                }
                this.scrollElement.addEventListener('scroll', this.scrollListener)
            }
        }

        const offset = this.scrollElement ? this.scrollElement.getBoundingClientRect().top : this.state.staticMarginTop

        const calendar = (
            <div ref={scrollRef} style={{position: 'relative', overflow:'auto', left: 0, right: 0, marginTop: 8, height: window.innerHeight-offset-this.state.scrollWindowAdditionalMarginBottom}}>
                {this.drawCurrentTimeLine(nowHeight, isToday)}
                {this.drawCurrentTimeLabel(nowHeight, isToday)}

                {this.drawTimes()}
                {this.drawBlobs()}
                {this.drawVerticalLines()}
                {this.drawHorizontalLines()}
                {this.drawHeaders()}
                {scrollViewChild}
            </div>
        )

        let isWeekView = false
        let label = <DateAndTime anchor={this.props.anchorDate}/>
        let arrowSide = null
        let months = 1
        let hideDateSwitcher = false
        let weekDates = []

        switch(this.state.calendarType){
            case "Onboarding":
                isWeekView = true
                label = this.drawWeekLabel()
                arrowSide = 400
                months = 3
                weekDates = CalendarUtil.getDatesForThisWeek(this.props.anchorDate)
            break;
            case "TimeRestriction":
                 hideDateSwitcher = true
            break;
        }

        const dateSwitcher = hideDateSwitcher
            ? null
            : <DateSwitcher
                highlightSelected={!isWeekView}
                highlightDates={weekDates}
                arrowSide={arrowSide}
                onChange={this.props.loadDate}
                label={label}
                anchorDate={this.props.anchorDate}
                onNext={this.props.loadNext}
                onPrevious={this.props.loadPrevious}
                nextEnabled={!this.props.hideForwardArrow}
                previousEnabled={!this.props.hideBackArrow}
                monthsShown={months}
            />
        return(
            <>
            {dateSwitcher}
            {optionsUI}
            {calendar}
            </>
        )
    }

    drawWeekLabel = () => {
        const week = CalendarUtil.getWeekTimeRange(this.props.anchorDate)
        const sun = new Date(week.start)
        const sat = new Date(week.end)

        const sunString = CalendarUtil.getDayOfWeekString(sun.getDay()) + " " + sun.getDate() + ", " + CalendarUtil.getMonthString(sun.getMonth())
        const satString = CalendarUtil.getDayOfWeekString(sat.getDay()) + " " + sat.getDate() + ", " + CalendarUtil.getMonthString(sat.getMonth())

        return(
            <>
            {sunString} - {satString}
            </>
        )
    }

    drawCornerBlock = () => {
        return (<div style={{
            position: 'absolute',
            top: 140+this.state.marginTop,
            left: window.innerWidth * 0.1 - this.state.columnWidth - 10,
            backgroundColor: '#fff',
            zIndex: 2, 
            height: this.state.rowHeight/2,
            width: this.state.columnWidth
        }}/>)
    }

    drawCurrentTimeLine(nowHeight, isToday){
 
        if(!isToday) return null

        const w = this.state.columnWidth
        const offset = window.innerWidth * 0.05
        return <div key={'currentTimeLine'} id={'currentTimeLine'} ref={this.timelineIndicatorRef} style={{zIndex:1, width: w * this.state.columns + offset, height:5, backgroundColor:Colors.Primary.Dark, position:"absolute", top: nowHeight + this.state.marginTop, left: offset}}> </div>
    }

    drawCurrentTimeLabel(nowHeight, isToday){
        if(!isToday) return null
        var style = {
            position:"absolute", 
            left: 0,
            textAlign:"right", 
            top: nowHeight - 12 + this.state.marginTop, 
            height:0,
            width: '5%',
            zIndex: this.state.zIndexHeader
        }

        return <div key={'currentTimeLabel'} id={'currentTimeLabel'} style={style}>Now</div>
    }

    drawHorizontalLines(){
        const w = this.state.columnWidth
        return(
            this.state.timeRange.map((hour, index)=>{
                const h = 63 + index * this.state.rowHeight + this.state.marginTop;
                return [
                [0, 0.25, 0.5, 0.75].map((val, i)=>{
                    const color = val === 0 ? "#888888" : "#dddddd"; //draw dark hours lines and lighter quarter-hour lines
                    return <div key={`horizontal-line-${hour}-${val}`} id={`horizontal-line-${hour}-${val}-label`} style={{zIndex:this.state.zIndexLines, pointerEvents:"none", width:w*this.state.columns, height:1, backgroundColor: color, position:"absolute", top:h + this.state.rowHeight * val, left:"10%"}}> </div>
                })
                ]
            })
        )
    }
    
    drawVerticalLines(){
        const w = this.state.columnWidth
        const l = window.innerWidth * 0.1

        return(
            Array(this.state.columns - 1).fill().map((day, index)=>{
                return <div key={`vertical-line-${index}`} id={`vertical-line-${index}-label`} style={{zIndex: this.state.zIndexLines, width:1, height:140 + (this.state.timeRange.length - 1) * this.state.rowHeight, backgroundColor:"#000000", position:"absolute", top:63 + this.state.marginTop, left: l + (w * (index + 1)) }}> </div>
            })
        )
    }

    static topTime = null
      
      getTop(t){
        if(!SchedulingCalendar.topTime){
            SchedulingCalendar.topTime = CalendarUtil.getShortTime(new Date(this.state.timeRange[0]))
        }
        const bt = CalendarUtil.breakTime(t)
        const startTime = parseInt(bt[0]) + parseInt(bt[1]) / 60
        const ft = CalendarUtil.breakTime(SchedulingCalendar.topTime)
        const firstTime = parseInt(ft[0]) + parseInt(ft[1]) / 60
        return 68 + (startTime - firstTime) * this.state.rowHeight
      }
      
      getHeight(s, e){
        const bts = CalendarUtil.breakTime(s)
        const bte = CalendarUtil.breakTime(e)

        const startTime = parseInt(bts[0]) + parseInt(bts[1]) / 60
        const endTime = parseInt(bte[0]) + parseInt(bte[1]) / 60

        return (endTime - startTime) * this.state.rowHeight - 10
      }

    getTimeStyle(i){
        //max width of 10%, min width of 80px, with a 10px margin between calendar and time, if possible
        let width = (window.innerWidth * .1 - 10)-this.state.scrollX //scrollX will reduce the size
        if(width < 80)
            width = 80 //bring size back to 80px if below
        return{
            position:"absolute", 
            left: 0,
            textAlign:"right", 
            top:50 + i * this.state.rowHeight + this.state.marginTop, 
            height:this.state.rowHeight,
            width: width, 
            backgroundColor: "#fff", 
            zIndex: this.state.zIndexHeader-1
        }
      }
    
      drawTimes(){
        const times = this.state.timeRange.map((time, index)=>{
            const shortTime = CalendarUtil.getShortTime(new Date(time))
            return <div key={`time-${shortTime}`} id={`time-label-${shortTime}`} style={this.getTimeStyle(index)}>{shortTime}</div>
        })
        return <div key={'times'} id={'times-container'} style={{position:'sticky', left: 0, zIndex: this.state.zIndexHeader-1}}>{times}</div>
    }

    /**
     *
     * @param appointment {AppointmentModel}
     * @param field
     * @returns {null|*[]}
     */
    getProviderNames(appointment, field="providers"){ 
        const isOffsite = appointment.isOffsite || appointment.serviceId === this.props.facility.otherServiceId
        if(!isOffsite && appointment[field] !== undefined && appointment[field] !== null){
            const providerNames = []
            appointment[field].forEach(assignee => {
                if(assignee.id === this.props.facility.offsiteProviderId){
                    providerNames.push("Offsite")
                }
                else{
                    const provider = this.props.facility.providers[assignee.id]
                    if(provider)
                        providerNames.push(`${provider.firstName} ${provider.lastName[0]}`)
                    //else what do we do?
                }
            });
            return providerNames;
        }
        return null
    }
}