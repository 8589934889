import React from 'react'
import './sdEditTextBox.css'
import {InputGroup, FormControl} from 'react-bootstrap'

export default class SDEditTextBox extends React.Component{
    render(){
        let inputGroupName = this.props.rounded ? "input-group-prepend-rounded" : "input-group-prepend"
        let formControlName = this.props.rounded ? "form-control-rounded" : "form-control"
        return (
            <div style={this.props.style} className={(this.props.className??'edit-container')+(this.props.invisible?" invisible":'')}>
                <InputGroup className={'edit-input-group'+(this.props.invisible?" invisible":'')}>
                    <InputGroup.Prepend className={inputGroupName} style={this.props.prependStyle}>
                    <InputGroup.Text>{this.props.label}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl id={this.props.inputId} className={formControlName} autocomplete={this.props.autoComplete} style={this.props.formStyle} type={this.props.type ?? (this.props.hideText ? "password" : "")} disabled={this.props.disabled === true} defaultValue={this.props.defaultValue} placeholder={this.props.placeholder} value={this.props.title} onChange={this.onChange}/>
                </InputGroup>
            </div>
        )
    }

    onChange = (element) => {
        if(this.props.onChange) this.props.onChange(element.target.value)
    }
}