import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ConfigureSchedulePage from './ConfigureSchedulePage';
import LoadingSpinner from '../../util/LoadingSpinner';
import FacilityManager from '../../managers/FacilityManager';
import FacilityUtil from '../../util/FacilityUtil';
import NavigationHeader from '../../ui/NavigationHeader';
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager';
import PromisifyCallback from '../../util/PromisifyCallback';

const getSchedulePromise = new PromisifyCallback(FacilityManager.getSchedules)
const getGroupsPromise = new PromisifyCallback(FacilityManager.getGroups)
const getTimeRestrictionsPromise = new PromisifyCallback(LegacyAppointmentManager.getTimeRestrictions)

export default class ZeroMinuteSchedules extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            loading: true,
            schedule: null
        }
        document.body.style.backgroundColor = null
    }

    componentDidMount(){
        this.reload();
    }

    reload = async() => {
        this.setState({
            loading: true
        })
        let facilityId = this.props.facility.id
        let schedules = await getSchedulePromise.invoke(facilityId)
        let serviceProviderMap = await FacilityUtil.buildServiceProviderMap(facilityId, this.props.services, true)
        let groups = await getGroupsPromise.invoke(facilityId, null, null)
        let timeRestrictions = await getTimeRestrictionsPromise.invoke(facilityId, undefined, undefined)
        this.setState(
            { 
                groups: groups, 
                loading: false,
                serviceProvidersDict: serviceProviderMap,
                schedules: schedules,
                timeRestrictions: timeRestrictions
            }
        )
        this.props.machine.send("SELECT_SCHEDULE")
    }

    onScheduleSelected = (type, schedule) => {
        this.setState({
            scheduleType: type,
            isNew: schedule == null
        })

        if(schedule == null) this.createNewSchedule(type)
        else this.loadSchedule(schedule)
    }

    loadSchedule(schedule){
        if(schedule.scheduleType == "auto") this.props.machine.send("EDIT_AUTO_SCHEDULE")
        else if(schedule.scheduleType == "simple") this.props.machine.send("EDIT_SIMPLE_SCHEDULE")
        this.setState({
            schedule: schedule,
            loading: false
        })
    }

    createNewSchedule(type){
        this.setState({
            loading: true
        })

        var schedule = {
            name: "New " + type + " schedule",
            scheduleType: type,
            providerId: null,
            duration: 15, // 15 minutes
            timeBlocks: {
                times: []
            },
            groups: [],
            execution: {
                frequency: "daily",
                days: [],
                time: { formatted: "4:00 am", hour: 4, minute: 0, meridiem: 'am', hour24: 4}
            },
            isAutomatic: true,
            selectedPatients: []
        }
        FacilityManager.postSchedule(this.props.facility.id, schedule, (result) => {
            LegacyAppointmentManager.createScheduleCronJob(this.props.facility.id, result, (res) => {
                this.loadSchedule(result)
            })
        })
    }

    render(){
        if(this.state.loading) return <LoadingSpinner/>;

        var state = this.props.machine.state.value["ZeroMinuteSchedule"]
        switch(state){
            case "SelectSchedule":
                return this.drawScheduleList()
            case "EditAutoSchedule":
            case "EditSimpleSchedule":
                return <ConfigureSchedulePage onReload={this.reload} providers={this.props.providers} serviceProvidersDict={this.state.serviceProvidersDict} machine={this.props.machine} rooms={this.props.rooms} groups={this.state.groups} services={this.props.services} facility={this.props.facility} schedule={this.state.schedule} timeRestrictions={this.state.timeRestrictions}/>

        }
        return null
    }

    drawScheduleList(){
        return(
            <>
            <NavigationHeader/>
            <Container>
            <Row className="justify-content-md-center">
                <Col className="schedule-column">
                    {this.simpleSchedulesColumn()}
                </Col>
                <Col className="schedule-column">
                    {this.autoSchedulesColumn()}
                </Col>
            </Row>
            </Container>
            </>
        )
    }

    simpleSchedulesColumn(){
        return(
            <Col>
                <Row className="justify-content-md-center">
                    <div className="schedule-title">Simple Schedules</div>
                </Row>
                <Row className="justify-content-md-center">
                    <div className="schedule-subtitle"> Select Patients and available times to run schedule</div>
                </Row>
                {/* Hack to fix offset with other column's longer text */}
                <Row style={{height: 18}}></Row>
                {this.drawScheduleOptions("simple")}
            </Col>
        )
    }

    autoSchedulesColumn(){
        return(
            <Col md="auto" style={{width: 275}}>
                <Row className="justify-content-md-center">
                    <div className="schedule-title">Auto Schedules</div>
                </Row>
                <Row className="justify-content-md-center">
                    <div className="schedule-subtitle">Automatically run schedule at specified time for a group of patients</div>
                </Row>
            {this.drawScheduleOptions("auto")}
            </Col>
        )
    }

    drawScheduleOptions(type){
        var buttons = []

        buttons.push(<div style={{height: 8}}/>)

        this.state.schedules.forEach(schedule => {
            if(schedule.scheduleType == type){
                buttons.push(
                    <Row className="justify-content-md-center">
                        <button onClick={() => this.loadSchedule(schedule)} className="schedule-button">{schedule.name}</button>
                    </Row>
                )
            }
        });

        buttons.push(
            <Row className="justify-content-md-center">
                <button onClick={() => this.createNewSchedule(type)} className="add-button">+</button>
            </Row>
        )
        
        return (
            buttons
        )
    }

}