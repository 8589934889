import React  from 'react';
import ConfigManager from '../managers/ConfigManager';
import { Button } from 'react-bootstrap';
import Helpers from '../util/Helpers';
import FacilityCalendar from './FacilityCalendar';
import SDMultiSelect from '../util/SDMultiSelect';
import GroupsDropdown from '../ui/GroupsDropdown';

/**
 * Rendered via FacilitySchedulePage
 */
export default class OccupancyFacilityCalendar extends FacilityCalendar{

  constructor(props){
    super(props, props.facility.rooms)
    
    this.state.hideOccupancyNames = true
    this.state.hideProviderNames = false //using provider name display
    this.state.stateName = "FacilitySchedule"
    this.state.loading = true
    this.state.filteredAllItems = []
  }

  async componentDidMount(){
    await this.fetchGroupsInfo()
    super.componentDidMount()
    Helpers.fixMultiSelects()
  }

  fetchGroupsInfo = async() => {
    let data = await GroupsDropdown.getGroupsData(this.props.facility.id, this.props.user.creatorId)
    this.setState({prefetchedGroupsData: data, loading: false})
  }

  /**
   *
   * @param schedule {ScheduleColumn}
   * @returns {string}
   */
  getFilterKey(schedule){
    return schedule.id //TODO check to make sure this works
  }

  /**
   * @param schedule {ScheduleColumn}
   * @returns {string}
   */
  getHeaderName(schedule){
    let name = schedule.label
    name = name.replace('\!', '')
    return `${name}` //already stored for us
  }

  getMachineState = () => {
    return this.props.machine.state.value["FacilitySchedule"]
  }

  drawMultiSelect(){
    var spacing = {margin: '8px'}
    var buttonStyle = Object.assign({"background-color": "rgb(0, 196, 216)", "border-color": "rgb(0, 196, 216)", height: '42px'}, spacing)
    return (
      <>
      <div style={{zIndex: 12, position: 'relative', width: '100%', marginTop: '8px', marginBottom: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
        {this.renderGroupMultiSelect()}
        <SDMultiSelect 
            style={{width: '300px', margin: '8px', marginLeft: "-7px"}}
            defaultValue="Select Rooms..."
            allItems={this.state.allItems.filter((item)=>{
              return this.state.filteredAllItems.includes(item.id)
            })}
            labelRenderer={(room)=>room.occupied ? `${room.name} - ${room.identifier}` : room.name}
            valueRenderer={(room)=>room.id}
            filteredItems={this.state.filteredItems}
            onSelectionsChanged={this.onRoomSelectionsChanged}/>
        <div>
          <Button size="sm" style={buttonStyle} onClick={this.props.onSelectServiceAgenda}>View Service Schedule</Button>
        </div>
      </div>
      </>
    )
  }

  renderGroupMultiSelect = ()=> {
    return <GroupsDropdown prefetchedGroupsData={this.state.prefetchedGroupsData} user={this.props.user} facility={this.props.facility} onGroupUpdate={this.onGroupFilterChanged}/>
  }

  onGroupFilterChanged = (groupList, userAction)=> {
    groupList.push('activities')
    this.setState({filteredAllItems: groupList, loading: false}, ()=>{
      this.onRoomSelectionsChanged(userAction ? [] : this.state.filteredItems)
    })
  }

  getRoomIdFromParameter = (parameterType, value)=> {
    let result = null
    this.props.facility.rooms.forEach((room)=>{
      if(room[parameterType] === value)
        result = room.id
    })
    return result
  }

  onRoomSelectionsChanged = (filteredItems)=>{
    console.log(JSON.stringify(filteredItems))
    filteredItems = filteredItems.filter((item)=>{
      return this.state.filteredAllItems.includes(item)
    })
    console.log(JSON.stringify(filteredItems))
    this.setState({
      filteredItems: filteredItems
    })

    ConfigManager.updateFacilityScheduleOptions(this.props.facility.id, this.props.user.creatorId, filteredItems, (data) => {})

    super.onResize()
  }
}