import React from 'react';
import HybridTimePicker from '../util/HybridTimePicker';
import CalendarUtil from '../util/CalendarUtil';
import './TimeBlockPicker.css'
import ErrorIndicator from './ErrorIndicator';
import Validator from '../util/Validator';

export default class TimeBlockPicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            days: this.props.days,
            times: this.props.timeBlocks.times,
        };

        this.addTimeBlock = this.addTimeBlock.bind(this);
        this.removeTimeBlock = this.removeTimeBlock.bind(this);
        this.updateTimeBlock = this.updateTimeBlock.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    render() {
        var borderBottomRadius = this.props.roundBottom ? 4 : 0

        return (
            <div className="time-blocks-window" style={{borderBottomLeftRadius: borderBottomRadius, borderBottomRightRadius: borderBottomRadius}}>
                <div className="time-blocks-title">
                    Available Time Blocks
                </div>
                {this.drawContent()}

            </div>
        )
    }

    drawContent() {
        if (this.props.service == null){
            return <div className='info-text'>Please select a service</div>
        } else {
            var html = []

            for (var i = 0; i < this.state.times.length; i++) {
                var start = this.state.times[i].start
                var end = this.state.times[i].end
                html.push(this.drawTimeBlock(start, end, i))
            }

            html.push(
                <button className="time-blocks-add-button" onClick={this.addTimeBlock}>+</button>
            );
            return html
        }
    }

    drawTimeBlock(start, end, index) {
        var invalidTimeIcon = <ErrorIndicator visible={!this.state.times[index].valid} reason={this.state.times[index].invalidReason}/>

        var sd = new Date()
        sd.setHours(start.hour24)
        sd.setMinutes(start.minute)
        sd.setSeconds(0)
        sd.setMilliseconds(0)

        var ed = new Date()
        ed.setHours(end.hour24)
        ed.setMinutes(end.minute)
        ed.setSeconds(0)
        ed.setMilliseconds(0)

        return (
            <div className="time-picker-row">
                {invalidTimeIcon}
                <div style={{float: "right"}}>
                    {this.drawHybridTimePicker(sd, index, "start")}
                </div>
                <div style={{marginLeft: 48, marginRight: 24}}> to </div>
                {this.drawHybridTimePicker(ed, index, "end")}
                <img className="time-picker-delete" onClick={() => this.removeTimeBlock(index)} src={require('../img/Icons/delete_forever-24px.svg')}alt="Delete" />
            </div>
        );
    }

    drawHybridTimePicker(date, index, mode){
        return(
            <HybridTimePicker 
            value={date}
            onChange={(time => {
                this.updateTimeBlock(time, index, mode)
            })}
            listener={this}
            />
        )
    }

    addTimeBlock() {
        var times = this.state.times;

        times.push({
            valid: true,
            invalidReason: null,
            "start": { formatted: "12:00 pm", hour: 12, minute: 0, meridiem: 'pm', hour24: 12 }, 
            "end": { formatted: "1:00 pm", hour: 1, minute: 0, meridiem: 'pm', hour24: 13 }
        });

        this.setState({
            times: times
        }, () => this.validateTimes())

        this.props.onTimeBlockChange({
            days: this.state.days,
            times: this.state.times
        })
    }

    removeTimeBlock(index) {
        var times = this.state.times;
        times.splice(index, 1);

        this.setState({
            times: times
        }, () => this.validateTimes());

        this.props.onTimeBlockChange({
            days: this.state.days,
            times: this.state.times
        })
    }

    validateTimes(){
        var times = this.state.times;
        var validator = this.props.validator ?? TimeBlockPicker.getValidator(this.props.facility)
        for(var index = 0; index < times.length; index++){
            let time = times[index]
            let results = validator.runSingleValidation(time, times, index)
            if(results.length > 0){
                time.valid = false
                time.invalidReason = results[0]
            }
            else{
                time.valid = true
                time.invalidReason = undefined
            }
        }

        this.setState({
            times: times
        }, () => this.onChange())
    }

    componentDidMount(){
        this.validateTimes()
    }

    updateTimeBlock(time, index, mode) {

        var times = this.state.times;

        time = {
            hour24: time.getHours(),
            minute: time.getMinutes()
        }

        times[index][mode] = time;

        this.setState({
            times: times,
        }, () => { this.validateTimes()});
    }

    getDate(t){
        var d = new Date()
        d.setHours(t.hour24)
        d.setMinutes(t.minute)
        d.setSeconds(0)
        d.setMilliseconds(0)

        return d
    }

    onChange() {
        this.props.onTimeBlockChange({
            days: this.state.days,
            times: this.state.times
        })
    }

    static getValidator(facility){
        var validator = new Validator()
        validator.addValidationCheck((time, times)=>{ //validate start comes before end
            let convertedTime = TimeBlockPicker.getTimes(time)
            return convertedTime.end < convertedTime.start ? "Start time should come before end time" : null
        })
        validator.addValidationCheck((time, times)=>{ //validate time is within facility operating times
            let convertedTime = TimeBlockPicker.getTimes(time)
            let openMs
            let closeMs

            if(facility && facility.operatingTimes){
                openMs = facility.operatingTimes.start
                closeMs = facility.operatingTimes.end
            }
            //If operating times are not set, default to 6AM - 8PM
            else{
                openMs = 6 * 60 * 60 * 1000
                closeMs = 20 * 60 * 60 * 1000
            }
            let startMs = CalendarUtil.getMillisecondsAfterMidnight(convertedTime.start)
            let endMs = CalendarUtil.getMillisecondsAfterMidnight(convertedTime.end)

            let withinOperatingTimes = startMs >= openMs && startMs <= closeMs && endMs >= openMs && endMs <= closeMs
            return withinOperatingTimes ? null : "Start or end time is outside of facility time range"
        })
        validator.addValidationCheck((time, times, index)=>{ //validate that times do not overlap
            let convertedTime = TimeBlockPicker.getTimes(time)
            for(var timeIndex = 0; timeIndex < times.length; timeIndex++){
                if(index === timeIndex) continue //skip over it if index matches
                let otherTime = times[timeIndex]
                let convertedOtherTime = TimeBlockPicker.getTimes(otherTime)
                let overlaps = CalendarUtil.doTimeRangesOverlap(convertedTime.start, convertedTime.end, convertedOtherTime.start, convertedOtherTime.end)
                if(overlaps) return "Time overlaps with another time"
            }
            return null
        })
        return validator
    }

    static getTimes = (time)=>{
        var s = time.start
        var e = time.end

        var sd = new Date()
        sd.setHours(s.hour24)
        sd.setMinutes(s.minute)
        sd.setSeconds(0)
        sd.setMilliseconds(0)

        var ed = new Date()
        ed.setHours(e.hour24)
        ed.setMinutes(e.minute)
        ed.setSeconds(0)
        ed.setMilliseconds(0)
        return {start: sd, end: ed}
    }
}