import React from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import LegacyAppointmentManager from '../../managers/LegacyAppointmentManager'
import { StringFlags, StringResources } from '../../strings/StringManager'
import NavigationHeader from '../../ui/NavigationHeader'
import CalendarUtil from '../../util/CalendarUtil'
import Colors from '../../util/Colors'
import DateAndTime from '../../util/DateAndTime'
import Helpers from '../../util/Helpers'
import LoadingSpinner from '../../util/LoadingSpinner'
import ManageProviderRestrictionsPage from './ManageProviderRestrictionsPage'
import DateSwitcher from '../../ui/DateSwitcher';
import moment from "moment";
import styles from "./ScheduleForwardPage.module.css"
import {AppointmentAssignee, AppointmentV2} from "../../models/Appointment";

export default class ScheduleForwardPage extends React.Component {

    //static titles that can be used by state title manager.
    //We need getters here since we dont have the StringResources info until later on, and it may change later
    static DISCHARGE_PATIENTS_TITLE = {
        get: () => {
            return `Discharge ${StringResources.patient(StringFlags.Capitalize | StringFlags.Plural)}`
        }
    }
    static ADMIT_PATIENTS_TITLE = {
        get: () => {
            return `Admit ${StringResources.patient(StringFlags.Capitalize | StringFlags.Plural)}`
        }
    }
    static OPTIONS_TITLE = {
        get: () => { return "Options" }
    }
    static PROVIDER_AVAILABILITY_TITLE = {
        get: () => { return "Provider Availability" }
    }
    static TEAM_CONFERENCES_TITLE = {
        get: ()=> { return "Schedule Team Conferences" }
    }
    static GENERATE_SCHEDULE_TITLE = {
        get: () => { return "Generate Schedule" }
    }
    static GENERATE_2FER_TITLE = {
        get: () => { return "Generate 2fer Schedules" }
    }

    static NONE = {
        get: ()=> {return null}
    }

    static stateTitles = {
        DischargePatients: {
            TITLE: ScheduleForwardPage.DISCHARGE_PATIENTS_TITLE,
            NEXT: ScheduleForwardPage.NONE,
            PREVIOUS: ScheduleForwardPage.OPTIONS_TITLE,
            CANCEL: ScheduleForwardPage.OPTIONS_TITLE
        },
        ProviderAvailability: {
            TITLE: ScheduleForwardPage.PROVIDER_AVAILABILITY_TITLE,
            NEXT: ScheduleForwardPage.NONE,
            CANCEL: ScheduleForwardPage.OPTIONS_TITLE,
            PREVIOUS: ScheduleForwardPage.OPTIONS_TITLE
        },
        TeamConferences: {
            TITLE: ScheduleForwardPage.TEAM_CONFERENCES_TITLE,
            NEXT: ScheduleForwardPage.NONE,
            CANCEL: ScheduleForwardPage.OPTIONS_TITLE,
            PREVIOUS: ScheduleForwardPage.OPTIONS_TITLE
        },
        AdmitPatients: {
            TITLE: ScheduleForwardPage.ADMIT_PATIENTS_TITLE,
            NEXT: ScheduleForwardPage.NONE,
            CANCEL: ScheduleForwardPage.OPTIONS_TITLE,
            PREVIOUS: ScheduleForwardPage.OPTIONS_TITLE
        },
        GenerateSchedule: {
            TITLE: ScheduleForwardPage.GENERATE_SCHEDULE_TITLE,
            NEXT: ScheduleForwardPage.NONE,
            PREVIOUS: ScheduleForwardPage.NONE,
            CANCEL: ScheduleForwardPage.OPTIONS_TITLE
        },
    }


    constructor(props) {
        super(props)
        document.body.style = 'background:' + Colors.Secondary.Light + ';';

        var date = new Date()
        var previousDate = new Date()
        var nextDate = new Date()
        var todayDOW = date.getDay() //SUN = 0

        nextDate.setDate(nextDate.getDate()+1)

        this.props.machine.send("NEXT")
        
        this.state = {
            anchorDatePrevious: new Date(CalendarUtil.getDayTimeRange(previousDate).start),
            anchorDateNext: new Date(CalendarUtil.getDayTimeRange(nextDate).start)
        }
    }

    render() {
        return [
            this.renderNavigation(),
            this.renderBody()
        ]
    }

    renderNavigation = () => {
        var navigationBackTitle = this.getBackNavigationTitle()
        var navigationForwardTitle = this.getForwardNavigationTitle()
        if (!navigationBackTitle && !navigationForwardTitle) return null
        return <NavigationHeader
            navigationBackTitle={navigationBackTitle}
            navigationForwardTitle={navigationForwardTitle}
            onNavigateBack={this.onNavigateBack}
            onNavigateForward={this.onNavigateForward}
        />
    }

    getBackNavigationTitle = () => {
        var state = this.props.machine.state.value["ScheduleForward"]
        return ScheduleForwardPage.stateTitles[state] ? ScheduleForwardPage.stateTitles[state].PREVIOUS.get() : null
    }

    getForwardNavigationTitle = () => {
        var state = this.props.machine.state.value["ScheduleForward"]
        return ScheduleForwardPage.stateTitles[state] ? ScheduleForwardPage.stateTitles[state].NEXT.get() : null
    }

    onNavigateBack = () => {
        this.props.machine.send("PREVIOUS")
    }

    onNavigateForward = () => {
        this.props.machine.send("NEXT")
    }

    renderBody = () => {
        var state = this.props.machine.state.value["ScheduleForward"]
        switch (state) {
            case "Options":
                return this.renderOptions()
            case "DischargePatients":
                return null //this state is not currently used
            case "ProviderAvailability":
                return this.renderProviderAvailability()
            case "TeamConferences":
                return null //this state is not currently used
            case "AdmitPatients":
                return null //this state is not currently used
            case "GenerateSchedule":
                return this.renderGenerateSchedule()
            case "RequestScheduleForwardConfirmation":
                return this.renderConfirmation(this.props.machine.state.event)
            default:
                return <LoadingSpinner />
        }
    }

    renderOptions = () => {
        var dates = this.renderDatePickers()
        var buttons = [
            this.renderButton("DISCHARGE_PATIENT_SELECTED", ScheduleForwardPage.DISCHARGE_PATIENTS_TITLE.get()),
            this.renderButton("PROVIDER_AVAILABILITY_SELECTED", ScheduleForwardPage.PROVIDER_AVAILABILITY_TITLE.get()),
            this.renderButton("TEAM_CONFERENCES_SELECTED", ScheduleForwardPage.TEAM_CONFERENCES_TITLE.get()),
            this.renderButton("ADMIT_PATIENTS_SELECTED", ScheduleForwardPage.ADMIT_PATIENTS_TITLE.get()),
            this.renderButton("GENERATE_SCHEDULE_SELECTED", ScheduleForwardPage.GENERATE_SCHEDULE_TITLE.get()),
            this.renderButton("GENERATE_2FERS_SELECTED", ScheduleForwardPage.GENERATE_2FER_TITLE.get())
        ]
        return (
            <div align="center" style={{ marginTop: 24 }}>
                {dates}
                {buttons}
            </div>
        )
    }

    renderButton = (stateName, title) => {
        return <>
            <Button
                disabled={this.state.errorText}
                variant="light"
                style={{ borderColor: "#000000", marginTop: 8, width: 300 }}
                onClick={() => { this.onOptionClicked(stateName) }}>
                {title}
            </Button>
            <br />
        </>
    }

    renderFromDateSwitcher = () => {
        return(
            <div style={{width: 300}}>
            <DateSwitcher
                arrowSide={240}
                label={<DateAndTime anchor={this.state.anchorDatePrevious} dateOnly={true}/>}
                anchorDate={this.state.anchorDatePrevious}
                onNext={() => this.onNewAnchorPreviousDate(1)}
                onPrevious={() => this.onNewAnchorPreviousDate(-1)}
                onChange={this.onChangeAnchorPreviousDate}
            />
            </div>
        )
    }

    renderToDateSwitcher = () => {
        return(
            <div style={{width: 300}}>
            <DateSwitcher
                arrowSide={240}
                label={<DateAndTime anchor={this.state.anchorDateNext} dateOnly={true}/>}
                anchorDate={this.state.anchorDateNext}
                onNext={() => this.onNewAnchorNextDate(1)}
                onPrevious={() => this.onNewAnchorNextDate(-1)}
                previousEnabled={!CalendarUtil.areDatesTheSameDay(new Date(), this.state.anchorDateNext)}
                onChange={this.onChangeAnchorNextDate}
                minDate={new Date()}
            />
            </div>
        )
    }

    renderDatePickers = () => {
        return [
            <div>Schedule appointments from</div>,
            this.renderFromDateSwitcher(),
            <div>to</div>,
            this.renderToDateSwitcher(),
            <div style={{color: 'red'}}>{this.state.errorText}</div>,
        ]
    }

    onChangeAnchorPreviousDate = (newAnchor) => {
        this.setState({anchorDatePrevious: newAnchor, errorText: this.evaluateDates(newAnchor, this.state.anchorDateNext)})
    }

    onNewAnchorPreviousDate = (i) => {
        var newAnchor = new Date(this.state.anchorDatePrevious)
        newAnchor.setDate(this.state.anchorDatePrevious.getDate() + i)
        this.onChangeAnchorPreviousDate(newAnchor)
    }

    onChangeAnchorNextDate = (date) => {
        this.setState({anchorDateNext: date, errorText: this.evaluateDates(this.state.anchorDatePrevious, date)})
    }

    onNewAnchorNextDate = (i) => {
        var newAnchor = new Date(this.state.anchorDateNext)
        newAnchor.setDate(this.state.anchorDateNext.getDate() + i)
        this.onChangeAnchorNextDate(newAnchor)
    }

    evaluateDates = (previousDate, nextDate) => {
        var error = null
        if(previousDate.getTime() > nextDate.getTime())
            error = "Date to schedule to must come after first date"
        if(previousDate.getDate() === nextDate.getDate())
            error = "Dates need to be different"
        return error
    }

    onOptionClicked = (stateName) => {
        switch (stateName) {
            case "DISCHARGE_PATIENT_SELECTED":
                this.props.navigateToState({
                    name: 'SELECT_MANAGE_PATIENTS',
                    data: {
                        previousState: {
                            name: 'SELECT_SCHEDULE_FORWARD',
                            data: {}
                        }
                    }
                })
                break
            case "PROVIDER_AVAILABILITY_SELECTED":
                this.props.machine.send(stateName)
                break
            case "TEAM_CONFERENCES_SELECTED":
                let service = this.findTeamConferencesService()
                if(!service){
                    toast('Unable to find service. Please name service "Team Conferences"')
                    break
                }
                this.props.navigateToState({
                    name: 'SELECT_SCHEDULE_APPOINTMENT',
                    data: {
                        anchorDate: this.state.anchorDateNext,
                        serviceId: service.id,
                        previousState: {
                            name: 'SELECT_SCHEDULE_FORWARD',
                            data: {}
                        }
                    }
                })
                break
            case "ADMIT_PATIENTS_SELECTED":
                this.props.navigateToState({
                    name: 'SELECT_MANAGE_PATIENTS',
                    data: {
                        previousState: {
                            name: 'SELECT_SCHEDULE_FORWARD',
                            data: {}
                        }
                    }
                })
                break
            case "GENERATE_SCHEDULE_SELECTED":
                this.props.machine.send(stateName)
                this.clickedScheduleForward()
                break
            case "GENERATE_2FERS_SELECTED":
                this.props.machine.send(stateName)
                this.clickedSchedule2fers()
                break;
        }
    }

    findTeamConferencesService = () => {
        var services = this.props.services
        for (var i = 0; i < services.length; i++){
            let service = services[i]
            if(service.name === 'Team Conferences')
                return service
        }
        return null
    }

    renderProviderAvailability = () => {
        return <ManageProviderRestrictionsPage
                anchorDate={this.state.anchorDateNext}
                providers={this.props.providers}
                machine={this.props.machine} 
                facility={this.props.facility}
                navigateToState={this.props.navigateToState}
                onEditAppointment={this.props.onEditAppointment}/>
    }

    renderGenerateSchedule = () => {
        return <LoadingSpinner/>
    }

    renderConfirmation = (context)=>{
        let warningTitle = `Schedule Forward has already been run for ${moment(context.confirmation.start).format('dddd, MMMM Do YYYY')}. \n Do you wish to run it again?`
        let lastRunTime = `Last run was ${moment(context.createdAt).format('dddd, MMMM Do YYYY [at] h:mm:ss a')} (${moment(context.createdAt).fromNow()})`
        let buttons = [
            {text: 'Yes', color: 'danger', action: ()=>{
                    this.props.machine.send("CONFIRM")
                    LegacyAppointmentManager.runScheduleForwardConfirm(context.confirmation, (result)=>this.processScheduleForwardResult(result, true))
                }},
            {text: 'Cancel', color: 'secondary', action: ()=>{
                    this.props.machine.send("REJECT")
                }}]
        return <div className={styles.confirmationContainer}>
            <div className={styles.confirmationWarning}>
                {warningTitle}
            </div>
            <div className={styles.confirmationInfoRuntime}>
                {lastRunTime}
            </div>
            <div className={styles.confirmationButtonContainer}>
                {buttons.map((buttonData)=>
                    <Button className={styles.confirmationButton} variant={buttonData.color} onClick={buttonData.action} type={"submit"}>
                        {buttonData.text}
                    </Button>
                )}
            </div>
        </div>
    }

    clickedScheduleForward = () => {
        const anchorDateNext = this.state.anchorDateNext;
        const anchorDatePrevious = this.state.anchorDatePrevious;
        LegacyAppointmentManager.runScheduleForward(this.props.facility.id, anchorDateNext, anchorDatePrevious, (result)=>{
            this.processScheduleForwardResult(result, false)
        })
    }

    processScheduleForwardResult = (result, isConfirmationRetry)=>{
        console.log(`Process Schedule Forward Result: ${JSON.stringify(result)} ${isConfirmationRetry}`)
        const anchorDateNext = this.state.anchorDateNext;
        const anchorDatePrevious = this.state.anchorDatePrevious;
        if(result.data && result.data.Payload){
            let response = JSON.parse(result.data.Payload)
            const success = response.statusCode === 200;
            let message = `Schedule forward ${success ? "success" : "failed"}.`;

            const stashed = response.stashedAppointments;
            if(stashed){
                stashed.forEach(e => {
                    const stashedMessage = "Stashed appointment " + e.title + " for patient " + e.occupancyIdentifier;
                    toast(stashedMessage, {
                        autoClose: false
                    })
                })
            }

            if(response.message)
                message += ` ${response.message}`
            if(response.failedAppointments && response.failedAppointments.length > 0){
                let messageLines = [`The following appointments failed to be scheduled forward:`]
                let appointmentIds = []
                response.failedAppointments.forEach((failedData)=>{
                    console.log(failedData)
                    if(appointmentIds.includes(failedData.id)){
                        return
                    }
                    appointmentIds.push(failedData.id)
                    let name = ''
                    if (failedData._assignees != null){
                        let assignees = failedData._assignees?.filter(a => a.assigneeType === 'occupant' || a.assigneeType === 'provider')
                        if (assignees != null) {
                            assignees.forEach((assignee, index)=>{
                                console.log(assignee)
                                if(index !== 0)
                                    name += ', '
                                if(assignee.assigneeType === 'provider') name += Helpers.getProviderNameFromId(assignee.assigneeId, this.props.facility)
                                    else name += assignee.assigneeName
                            })
                        }
                    } else if (failedData.occupants != null) {
                        failedData.occupants.forEach((occupant, index)=>{
                            if(index !== 0)
                                name += ', '
                            name += occupant.name
                        })
                    }
                    if(!name) {
                        let provider = failedData.providers && failedData.providers[0] ? Helpers.getProviderNameFromId(failedData.providers[0], this.props.facility) : null
                        name = failedData.occupancyIdentifier ?? provider ?? "Unknown"
                    }
                    messageLines.push(<br/>)
                    messageLines.push(`${failedData.title} for ${name} at ${CalendarUtil.getReadableDateAndTime(failedData.start)}`)
                })
                toast(<div>{messageLines}</div>, {
                    autoClose: false,
                    closeOnClick: false,
                    draggable: false,
                })
            }
            else{
                toast(message)
            }
            if(success){
                this.props.onScheduleFinished(new Date(anchorDateNext))
            }
            else{
                this.props.machine.send("CANCEL")
            }
        }
        else{
            if(!isConfirmationRetry && result.statusCode === 409 && result['confirmation-body-to-send']){
                this.props.machine.send("REQUEST_CONFIRMATION", {
                    createdAt: result['last-createdAt'],
                    runIteration: result['last-runIteration'],
                    confirmation: result['confirmation-body-to-send']
                })
                return
            }
            toast("Unable to schedule forward")
            this.props.machine.send("CANCEL")
        }
    }

    clickedSchedule2fers = () => {
        var anchorDateNext = this.state.anchorDateNext
        this.setState({loading: true})
        LegacyAppointmentManager.runSchedule2fers(this.props.facility.id, anchorDateNext, (result)=>{
            this.setState({loading: false})
            console.log(result)
            let response = JSON.parse(result.data.Payload)
            var success = response.statusCode === 200
            var message = `Schedule forward ${success ? "success" : "failed"}`
            if(response.message){
                message += `. ${response.message}`
            }
            toast(message)
            if(success){
                this.props.onScheduleFinished(new Date(anchorDateNext))
            }
            else{
                this.props.machine.send("CANCEL")
            }
        })
    }
}