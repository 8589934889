import React from 'react';

import {Table, Container, Row, Col, Button, Dropdown} from 'react-bootstrap'
import ApiUtil from '../util/ApiUtil'
import Agenda from '../util/Agenda'
import Colors from '../util/Colors'
import CalendarUtil from '../util/CalendarUtil'
import UserImg from '../img/Icons/no-pic.png'
import LegacyAppointmentManager from '../managers/LegacyAppointmentManager'
import LoadingSpinner from '../util/LoadingSpinner'
import DateAndTime from '../util/DateAndTime';
import { toast } from 'react-toastify';
import Image from 'react';
import FacilityManager from '../managers/FacilityManager';
import Tracker from '../managers/Tracker';
import Helpers from '../util/Helpers';
import DateSwitcher from '../ui/DateSwitcher';
import AgendaPage from './Agenda/AgendaPage';
import PrintedAgendaPage from './Agenda/PrintedAgendaPage';
import Queries from "../managers/Queries";
import {AppointmentRepository} from "../repositories/AppointmentRepository";

export default class FacilityServiceAgendaPage extends React.Component {

  /**
   * @type {AppointmentRepository}
   */
  repo
  constructor(props){
    super(props)
    Tracker.logScreenView('service_agenda')
    this.repo = new AppointmentRepository({facilityId: props.facility.id})
    this.state = { 
      loading: true,
      anchorDate: this.props.machine.state.event.anchorDate ?? new Date(),
      patient: null,
      viewingId: this.props.machine.state.event.viewingId??Helpers.sortServices(props.services, true, false)[0].id
    }

    document.body.style = 'background:' + Colors.Secondary.Light + ';';
  }

  componentWillMount(){
    window.addEventListener('beforeprint', this.onBeforePrint)
    window.addEventListener('afterprint', this.onAfterPrint)
  }

  componentWillUnmount(){
    window.removeEventListener('beforeprint', this.onBeforePrint)
    window.removeEventListener('afterprint', this.onAfterPrint)
  }

  onBeforePrint = () => {
    window.scrollTo(0, 0)
    this.setState({
      printMode: true
    })
    this.props.machine.send("PRINT")
  }

  onAfterPrint = () => {
    this.setState({
      printMode: false
    }, ()=> {
      this.props.machine.send("FINISH")
    })
  }

  moveAnchorDate(days) {
    var newAnchor = new Date(this.state.anchorDate.setDate(this.state.anchorDate.getDate() + days))
    this.loadAnchorDate(newAnchor)
  }

  loadAnchorDate = (newAnchor) => {
    this.setState({
      facility: this.props.facility,
      user: this.props.user,
      urlCode: this.props.urlCode,
      loading: true
    })
    let query = Queries.getAppointmentQueryParams(CalendarUtil.getDayTimeRange(newAnchor), this.props.facility.id, null, this.state.viewingId)
    this.repo.getAppointments(query)
      .then(appointmentData => {
        if(appointmentData.appointments){
          let appts = appointmentData.appointments
          Helpers.sortObjectByTimes(appts)
          this.setState({
            agendaItems: appts,
            anchorDate: newAnchor,
            loading: false
          });

          this.props.onAnchorDateChanged(newAnchor);
        }
        else{
          console.log(appointmentData.error)
          toast('Unable to load service appointments')
          this.setState({
            agendaItems: [],
            anchorDate: newAnchor,
            loading: false
          });

          this.props.onAnchorDateChanged(newAnchor);
        }
      })
  }
  
  prevDay = () => {
    this.moveAnchorDate(-1)
  }
  nextDay = () => {
    this.moveAnchorDate(1)
  }

  getAgenda(){
    let query = Queries.getAppointmentQueryParams(CalendarUtil.getDayTimeRange(this.state.anchorDate), this.props.facility.id, null, this.state.viewingId)
    this.repo.getAppointments(query)
        .then(appointmentData => {
          if(appointmentData.error != null) {
            toast('Unable to load service appointments')
            window.location = "/"
            return
          }
          console.log(appointmentData)
          let data = Helpers.sortObjectByTimes([].slice.call(appointmentData.appointments))
          this.setState({
            agendaItems: data,
            facility: this.props.facility,
            user: this.props.user,
            urlCode: this.props.urlCode,
            loading: false
          })
        })
  }

  componentDidMount(){
    this.onLoadNewAgenda(this.state.viewingId)

    this.setState({})
  }

  onLoadNewAgenda = (id) => {
    this.setState({
      agendaItems:{},
      anchorDate: this.props.anchorDate,
      loading: true,
      printMode: false,
      viewingId: id
    }, () => this.getAgenda() )

  }

  onDeleteAppointment = (id) => {
    var ai = this.state.agendaItems
    ai.forEach(e => {
      if(e.id == id) ai.splice(ai.indexOf(e), 1)
    })
    
    this.setState({
      agendaItems: ai
    }, () => this.forceUpdate())

    LegacyAppointmentManager.cancelAppointment(id, result => {
      this.setState({
        loading: false
      })
      this.forceUpdate();
    })
  }

  onRefresh = () => {
    this.forceUpdate()
  }
  onEditAppointment = (appointment) => {
    appointment.previousState = {
      name: 'SELECT_FACILITY_SCHEDULE.RESOLVE_SERVICE_AGENDA',
      data: {
          anchorDate: this.state.anchorDate,
          viewingId: this.state.viewingId,
      }
    }
    this.setState({
      loading: true
    }, () => window.scrollTo(0, 0))
    this.props.onEditAppointment(appointment)
  }

  showLoadingSpinner = () => {
    this.setState({
      loading: true
    })
  }

  render(){

    if (this.state.loading) return <LoadingSpinner/>

    var showDropDown = this.props.urlCode == null


    var patientLabel = this.state.patient == null ? null : <div align="center" style={{marginTop:8, fontSize:24}}>Room {this.state.patient.name} - {this.state.patient.identifier}</div>
    var agenda = <div align="center" zIndex="1"> <Agenda anchor={this.state.anchorDate} patient={this.state.patient} onDeleteAppointment={this.onDeleteAppointment} showLoadingSpinner={this.showLoadingSpinner} showDropDown={showDropDown} printMode={this.state.printMode} roomData={this.state.roomData} onEditAppointment={this.onEditAppointment} machine={this.props.machine} user={this.props.user} urlCode={this.props.urlCode} facility={this.props.facility} agendaItems={this.state.agendaItems} services={this.props.services}/></div>

    if(this.state.printMode){
      return <PrintedAgendaPage 
        type={PrintedAgendaPage.Type.SERVICE}
        viewingId={this.state.viewingId}
        anchor={this.state.anchorDate}
        roomData={this.state.patient} 
        urlCode={this.state.patient?.urlCode ?? null} 
        facility={this.props.facility} 
        agendaItems={this.state.agendaItems} 
        services={this.props.services}/>
    }
    else {
      var dropDown = showDropDown ? <ServicesDropdown facility={this.props.facility} onSelectFacilitySchedule={this.props.onSelectFacilitySchedule} viewingId={this.state.viewingId} onLoadNewAgenda={this.onLoadNewAgenda} services={this.props.services}/> : null



      return (
          <>
            <DateSwitcher 
            onChange={this.loadAnchorDate}
            label={<DateAndTime anchor={this.state.anchorDate}/>}
            anchorDate={this.state.anchorDate}
            onNext={this.nextDay}
            onPrevious={this.prevDay}
        />

        {patientLabel}
        {dropDown}
        {agenda}
        </>
      );
    }
  }
}

class ServicesDropdown extends React.Component{

  renderOptions(services){
    var sortedServices = Helpers.sortServices(
      services, 
      /*Copy*/true, 
      /*showDisabled*/false, 
      /**sortDisabledAtBottom */false, 
      /*filterSchedulable*/ false
    ).filter((service)=>{
      return service.schedulable || service.id === this.props.facility.activityServiceId
    })
    
    var options = []
    sortedServices.forEach((service)=>{
      if(service.id === this.props.viewingId)
        options.push(<option value={service.id}>{service.name}</option>)
    })
    sortedServices.forEach((service) => {
      if(service.id === this.props.viewingId) return null
        options.push(<option value={service.id}>{service.name}</option>)
    })
    return options
  }

  onChange = (e) => {
    this.props.onLoadNewAgenda(e.target.value)
  }

  render(){
    return (
      <div align="center" style={{marginTop: 16}}>
        <select style={{width:140, fontSize:12}} onChange={this.onChange}>
            {this.renderOptions(this.props.services)}
        </select>
        <Button size="sm" className={"ml-2"} style={{"background-color": "rgb(0, 196, 216)", "border-color": "rgb(0, 196, 216)"}} onClick={this.props.onSelectFacilitySchedule}>View Facility Schedule</Button>
      </div>
    )
  }

}